import React, {Component}    from "react";
import PropTypes             from "prop-types";

import Autosuggest           from "react-autosuggest";
import T                     from "qidigo-i18n/messages";
import Fetch from "qidigo-fetch";

import {
	FormattedMessage,
} from "react-intl";

/**
 * Retourne la string pour un résultat de ville.
 */
const show = (v) => v.name || "";

/**
 * Input spécifique aux villes.
 * Effectue la recherche et retourne le bon type d'object.
 * S'assure aussi de rendre possible une entrée manuelle au besoin.
 */
class CityInput extends Component {
	constructor() {
		super();
		this.state = {
			// La *query* qui filtre la liste des villes. Utilisées par le Autosuggest
			query: "",

			// Suggestions
			results: {},
		};
	}

	componentWillMount() {
		this.clear();
	}

	/**
	 * Appelée par <Form>
	 */
	validate(errors) {
		const {formatMessage} = this.context.intl;

		if (this.props.required && this.isEmpty()) {
			return [formatMessage(T["errors.messages.blank"])];
		}

		return [];
	}

	isEmpty() {
		const {value} = this.props;
		if (value && value.id) {
			return false;
		}

		return true;
	}

	handleChange(event, {method, suggestion}) {
		let value = null;

		if (method === "enter") {
			event.preventDefault();
		}

		if (suggestion) {
			const {id, name} = suggestion;
			value = {
				id, name
			};
			const query = show(suggestion);

			this.setState({
				query,
			});
		}

		if (this.props.onChange) {
			this.props.onChange(event, value);
		}
	}

	suggestInputChange(event, {newValue, method}) {
		this.setState({query: newValue});

		// Quand tout est supprimé du champ.
		if (newValue.trim() === "") {
			this.handleChange(event, {suggestion: null});
		}
	}

	/**
	 * Quand on quitte le champ.
	 */
	clear(...e) {
		this.setState({results: {}});
		let query = "";
		if (this.props.value) {
			query = show(this.props.value);
		}
		this.setState({query});
	}

	componentDidUpdate(prevProps) {
		// On reçoit une nouvelle value, on update le champ.
		if (this.props.value !== prevProps.value) {
			if (this.props.value) {
				const query = show(this.props.value);
				this.setState({query});
			}
		}
		if (this.props.state !== prevProps.state || this.props.country !== prevProps.country) {
			this.handleChange({}, {});
		}
	}

	/**
	 * Quand on cherche.
	 */
	simpleAutocomplete(query) {
		const {country, state} = this.props;

		if (!query || query.trim() === "") {
			this.setState({results: null});

			return;
		}

		// S'assure qu'on recherche uniquement quand on cherche filtré par pays et état.
		if (!country || !state) {
			return;
		}

		Fetch.get(`cities?state=${state}&country=${country}&query=${query.trim()}&limit=15`)
			.then(cities => {
				this.setState({error: null, results: cities});

				return cities
			})
			.catch((err) => this.setState({error: err}));
	}

	renderInputComponent(props) {
		const {label, error} = this.props;

		let errorMessage = null;
		if (error) {
			errorMessage =
				<div className="input--error-message">
					<FormattedMessage {...T["errors.format"]} values={{
						message: error,
						attribute: label,
					}} />
				</div>
			;
		}

		const outerProps = {};

		return (
			<div {...outerProps}>
				<label>
					<span className="input--label-text">{label}</span>
					<input
						placeholder=""
						{...props}
						className={"input--input"}
					/>
				</label>
				{errorMessage}
			</div>
		);
	}

	render() {
		const {
			error,
			country, // eslint-disable-line
			state, // eslint-disable-line
			inputRef, // eslint-disable-line
			...props
		} = this.props;

		const {query, results} = this.state;
		const containerClasses = ["input", "input-city", "input-complex"];
		if (query.trim() === "") {
			containerClasses.push("is-empty");
		}
		else {
			containerClasses.push("is-not-empty");
		}
		if (error) {
			containerClasses.push("with-error-message");
			containerClasses.push("is-invalid");
		}

		// HACK pour toujours afficher la liste...
		let suggestions = [];
		if (results) {
			suggestions = [].concat(results);
		}
		// L'array doit avoir une longueur de 1 au minimum.
		suggestions = [""].concat(suggestions);
		// On veut afficher la liste même si vide pour afficher un état vide.

		return (
			<Autosuggest
				suggestions={suggestions}
				focusInputOnSuggestionClick={false}
				onSuggestionsFetchRequested={({value}) => this.simpleAutocomplete(value)}
				onSuggestionsClearRequested={() => this.clear()}
				getSuggestionValue={(sugg) => show(sugg)}
				renderSuggestion={(result) => show(result)}
				onSuggestionSelected={(...e) => this.handleChange(...e)}
				inputProps={{
					...props,
					value: query,
					placeholder: this.props.label,
					onChange: (...e) => this.suggestInputChange(...e),
				}}
				renderInputComponent={(props) => this.renderInputComponent(props)}
				renderSuggestionsContainer={({containerProps, children}) =>
					<div {...containerProps}>
						{
							suggestions.length > 1 ?
							children :
							null
						}
					</div>
				}
				theme={{
					container: [containerClasses.join(" "), "react-autosuggest__container"].join(" "),
					containerOpen: "react-autosuggest__container--open",
					input: "react-autosuggest__input",
					inputFocused: "react-autosuggest__input--focused",
					inputOpen: "react-autosuggest__input--open",
					sectionContainer: "react-autosuggest__section-container",
					sectionContainerFirst: "react-autosuggest__section-container--first",
					sectionTitle: "react-autosuggest__section-title",
					suggestion: "react-autosuggest__suggestion",
					suggestionFirst: "react-autosuggest__suggestion--first",
					suggestionHighlighted: "react-autosuggest__suggestion--highlighted is-highlighted",
					suggestionsContainer: "react-autosuggest__suggestions-container input-city--suggestions",
					suggestionsContainerOpen: "react-autosuggest__suggestions-container input-city--suggestions is-open",
					suggestionsList: "react-autosuggest__suggestions-list",
				}}
			/>
		);
	}
}

CityInput.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.object,
	name: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	country: PropTypes.string,
	state: PropTypes.string,
	required: PropTypes.bool,
};

CityInput.defaultProps = {
	value: {},
};

CityInput.contextTypes = {
	intl: PropTypes.object,
};

export default CityInput;
