import React                 from "react";
import Helmet                from "react-helmet";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import snakeCase             from "lodash/snakeCase";
import Button                from "qidigo-components/button.js";
import ButtonsBar            from "qidigo-form/buttons_bar.js";
import AddressPartial        from "@app/views/shared/form/address.js";
import LocalModal            from "@app/components/local_modal.js";

const actions = defineMessages({
	"delete": {id: "qidigo.dashboard.addresses.delete", defaultMessage: "Supprimer"},
	"cancel": {id: "qidigo.dashboard.addresses.cancel", defaultMessage: "Annuler"},
});

const translations = defineMessages({
	editAddressTitle: {id: "qidigo.dashboard.addresses.edit_address_title", defaultMessage: "Modifier une adresse"},
	newAddressTitle: {id: "qidigo.dashboard.addresses.new_address_title", defaultMessage: "Nouvelle adresse"},
	deleteAddressTitle: {id: "qidigo.dashboard.addresses.delete_address_title", defaultMessage: "Supprimer l'adresse?"},
	deleteAddressMessage: {id: "qidigo.dashboard.addresses.delete_address_message", defaultMessage: "Une fois supprimée, l'adresse devra être entrée à nouveau pour être utilisée."},
});

const UserAddressForm = (props, context) => {
	const {
		confirmDeletion,
		action,
		onSubmit,
		onChange,
		onValidationError,
		handleCancelAction,
		handleDeleteAction,
		errors,
		address,
		saving,
	} = props;

	const {formatMessage} = context.intl;

	return (
		<section className="dashboard-addressbook">
			<h1><FormattedMessage {...translations[`${action}AddressTitle`]} /></h1>
			<Helmet
				title={formatMessage(translations[`${action}AddressTitle`])}
			/>
			<div
				className={`address-card type-${snakeCase(address.type_address)} address_id-${address.id}`}
			>
				<AddressPartial
					className="dashboard-useraddress"
					onSubmit={(...e) => onSubmit(...e)}
					onChange={(...e) => onChange(...e)}
					onValidationError={(...e) => onValidationError(...e)}
					errors={errors}
					value={address}
					disabled={saving}
					autoFocus={true}
				>
					<ButtonsBar loading={saving}>
						{action === "new" ? null :
							<Button onClick={(e) => handleDeleteAction(e)}><FormattedMessage {...actions["delete"]} /></Button>
						}
						<Button onClick={(e) => handleCancelAction(e)}><FormattedMessage {...actions["cancel"]} /></Button>
					</ButtonsBar>
				</AddressPartial>

				{confirmDeletion ?
					<LocalModal>
						<h2><FormattedMessage {...translations.deleteAddressTitle} /></h2>
						<p>
							<FormattedMessage {...translations.deleteAddressMessage} />
						</p>
						<ButtonsBar
							onSubmit={(e) => handleDeleteAction(e, "continue")}
							submitLabel={<FormattedMessage {...actions["delete"]} />}
						>
							<Button onClick={(e) => handleDeleteAction(e, "cancel")}><FormattedMessage {...actions["cancel"]} /></Button>
						</ButtonsBar>
					</LocalModal> :
					null
				}
			</div>
		</section>
	);
};

UserAddressForm.propTypes = {
	// FIXME : Shape it
	address: PropTypes.object,

	action: PropTypes.string.isRequired,
	saving: PropTypes.bool,
	confirmDeletion: PropTypes.bool,

	errors: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),

	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	onValidationError: PropTypes.func,
	handleCancelAction: PropTypes.func,
	handleDeleteAction: PropTypes.func,
};

UserAddressForm.contextTypes = {
	intl: PropTypes.object,
};

export default UserAddressForm;
