// THIS FILE IS GENERATED BY import_rails_translations.rb
// ANY MANUAL CHANGES WILL BE LOST.
import {
	defineMessages,
} from "react-intl";

const translations = defineMessages({
	"activerecord.attributes.base_user.NAS": {"id":"rails.activerecord.attributes.base_user.NAS","defaultMessage":"NAS"},
	"activerecord.attributes.family_member.birthday": {"id":"rails.activerecord.attributes.family_member.birthday","defaultMessage":"Date de naissance"},
	"activerecord.attributes.family_member.email": {"id":"rails.activerecord.attributes.family_member.email","defaultMessage":"Adresse courriel"},
	"activerecord.attributes.family_member.first_name": {"id":"rails.activerecord.attributes.family_member.first_name","defaultMessage":"Prénom"},
	"activerecord.attributes.family_member.last_name": {"id":"rails.activerecord.attributes.family_member.last_name","defaultMessage":"Nom de famille"},
	"activerecord.attributes.family_member.sex": {"id":"rails.activerecord.attributes.family_member.sex","defaultMessage":"Sexe"},
	"activerecord.attributes.message.body": {"id":"rails.activerecord.attributes.message.body","defaultMessage":"Contenu"},
	"activerecord.attributes.message.recipients": {"id":"rails.activerecord.attributes.message.recipients","defaultMessage":"Destinataires"},
	"activerecord.attributes.message.subject": {"id":"rails.activerecord.attributes.message.subject","defaultMessage":"Sujet"},
	"activerecord.attributes.user.birthday": {"id":"rails.activerecord.attributes.user.birthday","defaultMessage":"Date de naissance"},
	"activerecord.attributes.user.email": {"id":"rails.activerecord.attributes.user.email","defaultMessage":"Adresse courriel"},
	"activerecord.attributes.user.first_name": {"id":"rails.activerecord.attributes.user.first_name","defaultMessage":"Prénom"},
	"activerecord.attributes.user.last_name": {"id":"rails.activerecord.attributes.user.last_name","defaultMessage":"Nom de famille"},
	"activerecord.attributes.user.old_password": {"id":"rails.activerecord.attributes.user.old_password","defaultMessage":"Mot de passe actuel"},
	"activerecord.attributes.user.password_string": {"id":"rails.activerecord.attributes.user.password_string","defaultMessage":"Mot de passe"},
	"activerecord.attributes.user.password_string_confirmation": {"id":"rails.activerecord.attributes.user.password_string_confirmation","defaultMessage":"Mot de passe (confirmation)"},
	"activerecord.attributes.user.sex": {"id":"rails.activerecord.attributes.user.sex","defaultMessage":"Sexe"},
	"activerecord.errors.messages.record_invalid": {"id":"rails.activerecord.errors.messages.record_invalid","defaultMessage":"La validation a échoué : {errors}"},
	"activerecord.errors.messages.restrict_dependent_destroy.has_many": {"id":"rails.activerecord.errors.messages.restrict_dependent_destroy.has_many","defaultMessage":"Vous ne pouvez pas supprimer l'enregistrement parce que les {record} dépendants existent"},
	"activerecord.errors.messages.restrict_dependent_destroy.has_one": {"id":"rails.activerecord.errors.messages.restrict_dependent_destroy.has_one","defaultMessage":"Vous ne pouvez pas supprimer l'enregistrement car un(e) {record} dépendant(e) existe"},
	"activerecord.errors.models.base_user.attributes.email.taken": {"id":"rails.activerecord.errors.models.base_user.attributes.email.taken","defaultMessage":"est déjà utilisée par un utilisateur"},
	"errors.field_for_subject": {"id":"rails.errors.field_for_subject","defaultMessage":"{field} de {subject}"},
	"errors.format": {"id":"rails.errors.format","defaultMessage":"{attribute} {message}"},
	"errors.messages.NAS.invalid_format": {"id":"rails.errors.messages.NAS.invalid_format","defaultMessage":"est invalide"},
	"errors.messages.accepted": {"id":"rails.errors.messages.accepted","defaultMessage":"doit être accepté(e)"},
	"errors.messages.after": {"id":"rails.errors.messages.after","defaultMessage":"doit être après le {restriction}"},
	"errors.messages.before": {"id":"rails.errors.messages.before","defaultMessage":"doit être avant le {restriction}"},
	"errors.messages.blank": {"id":"rails.errors.messages.blank","defaultMessage":"est obligatoire"},
	"errors.messages.carrierwave_download_error": {"id":"rails.errors.messages.carrierwave_download_error","defaultMessage":"Impossible de télécharger l'image."},
	"errors.messages.carrierwave_integrity_error": {"id":"rails.errors.messages.carrierwave_integrity_error","defaultMessage":"N'est pas une image."},
	"errors.messages.carrierwave_processing_error": {"id":"rails.errors.messages.carrierwave_processing_error","defaultMessage":"Impossible de traiter l'image."},
	"errors.messages.confirmation": {"id":"rails.errors.messages.confirmation","defaultMessage":"ne concorde pas avec {attribute}"},
	"errors.messages.empty": {"id":"rails.errors.messages.empty","defaultMessage":"doit être rempli(e)"},
	"errors.messages.equal_to": {"id":"rails.errors.messages.equal_to","defaultMessage":"doit être égal à {count}"},
	"errors.messages.even": {"id":"rails.errors.messages.even","defaultMessage":"doit être pair"},
	"errors.messages.exclusion": {"id":"rails.errors.messages.exclusion","defaultMessage":"n'est pas disponible"},
	"errors.messages.extension_blacklist_error": {"id":"rails.errors.messages.extension_blacklist_error","defaultMessage":"Vous ne pouvez pas téléverser des fichiers de type {extension}. Ces types sont interdits: {prohibited_types}."},
	"errors.messages.extension_whitelist_error": {"id":"rails.errors.messages.extension_whitelist_error","defaultMessage":"Vous ne pouvez pas téléverser des fichiers de type {extension}. Ces types sont permis: {allowed_types}."},
	"errors.messages.greater_than": {"id":"rails.errors.messages.greater_than","defaultMessage":"doit être supérieur à {count}"},
	"errors.messages.greater_than_or_equal_to": {"id":"rails.errors.messages.greater_than_or_equal_to","defaultMessage":"doit être supérieur ou égal à {count}"},
	"errors.messages.inclusion": {"id":"rails.errors.messages.inclusion","defaultMessage":"n'est pas inclus(e) dans la liste"},
	"errors.messages.invalid": {"id":"rails.errors.messages.invalid","defaultMessage":"n'est pas valide"},
	"errors.messages.invalid_date": {"id":"rails.errors.messages.invalid_date","defaultMessage":"n'est pas une date valide"},
	"errors.messages.invalid_datetime": {"id":"rails.errors.messages.invalid_datetime","defaultMessage":"n'est pas un moment valide"},
	"errors.messages.invalid_time": {"id":"rails.errors.messages.invalid_time","defaultMessage":"n'est pas un moment valide"},
	"errors.messages.is_at": {"id":"rails.errors.messages.is_at","defaultMessage":"doit être le {restriction}"},
	"errors.messages.less_than": {"id":"rails.errors.messages.less_than","defaultMessage":"doit être inférieur à {count}"},
	"errors.messages.less_than_or_equal_to": {"id":"rails.errors.messages.less_than_or_equal_to","defaultMessage":"doit être inférieur ou égal à {count}"},
	"errors.messages.mime_type_pattern_black_list_error": {"id":"rails.errors.messages.mime_type_pattern_black_list_error","defaultMessage":"Vous ne pouvez pas téléverser des fichiers du type {content_type}."},
	"errors.messages.model_invalid": {"id":"rails.errors.messages.model_invalid","defaultMessage":"Validation échouée : {errors}"},
	"errors.messages.not_a_number": {"id":"rails.errors.messages.not_a_number","defaultMessage":"n'est pas un nombre"},
	"errors.messages.not_an_integer": {"id":"rails.errors.messages.not_an_integer","defaultMessage":"doit être un nombre entier"},
	"errors.messages.odd": {"id":"rails.errors.messages.odd","defaultMessage":"doit être impair"},
	"errors.messages.old_password.is_not_right": {"id":"rails.errors.messages.old_password.is_not_right","defaultMessage":"est incorrect"},
	"errors.messages.on_or_after": {"id":"rails.errors.messages.on_or_after","defaultMessage":"doit être le ou après le {restriction}"},
	"errors.messages.on_or_before": {"id":"rails.errors.messages.on_or_before","defaultMessage":"doit être le ou avant le {restriction}"},
	"errors.messages.other_than": {"id":"rails.errors.messages.other_than","defaultMessage":"doit être différent de {count}"},
	"errors.messages.present": {"id":"rails.errors.messages.present","defaultMessage":"doit être vide"},
	"errors.messages.required": {"id":"rails.errors.messages.required","defaultMessage":"doit exister"},
	"errors.messages.taken": {"id":"rails.errors.messages.taken","defaultMessage":"n'est pas disponible"},
	"errors.messages.too_long.one": {"id":"rails.errors.messages.too_long.one","defaultMessage":"est trop long (pas plus d'un caractère)"},
	"errors.messages.too_long.other": {"id":"rails.errors.messages.too_long.other","defaultMessage":"est trop long (pas plus de {count} caractères)"},
	"errors.messages.too_short.one": {"id":"rails.errors.messages.too_short.one","defaultMessage":"est trop court (au moins un caractère)"},
	"errors.messages.too_short.other": {"id":"rails.errors.messages.too_short.other","defaultMessage":"est trop court (au moins {count} caractères)"},
	"errors.messages.wrong_length.one": {"id":"rails.errors.messages.wrong_length.one","defaultMessage":"ne fait pas la bonne longueur (doit comporter un seul caractère)"},
	"errors.messages.wrong_length.other": {"id":"rails.errors.messages.wrong_length.other","defaultMessage":"ne fait pas la bonne longueur (doit comporter {count} caractères)"},
	"errors.template.body": {"id":"rails.errors.template.body","defaultMessage":"Veuillez vérifier les champs suivants : "},
	"errors.template.header.one": {"id":"rails.errors.template.header.one","defaultMessage":"Impossible d'enregistrer ce(tte) {model} : 1 erreur"},
	"errors.template.header.other": {"id":"rails.errors.template.header.other","defaultMessage":"Impossible d'enregistrer ce(tte) {model} : {count} erreurs"},
});

export default translations;
