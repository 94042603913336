import React                 from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";

const defaultMessages = defineMessages({
	title: {
		id: "qidigo.error.default.title",
		defaultMessage: "Erreur inattendue",
	},
	message: {
		id: "qidigo.error.default_error_message",
		defaultMessage: "Une erreur est survenue lors de l'opération... ({code})",
	},
});

/**
 * Component utilisé pour afficher une erreur localisée.
 *
 * C'est pour une erreur du style "500", ou plutôt, quand quelque chose est
 * brisé et NON PAS pour une erreur provenant d'une utilisation erronnée.
 */
const ErrorMessage = (props, context) => {
	const {formatMessage} = context.intl;
	let title = formatMessage(defaultMessages.title);
	let message = formatMessage(defaultMessages.message, {code: props.code});
	let className = [props.className, "applicationerror"].join(" ");

	if (props.title)   { title = props.title; }
	if (props.message) { message = props.message; }

	return (
		<div className={className}>
			<div className="applicationerror--wrapper">
				<div className="applicationerror--title">{title}</div>
				<div className="applicationerror--message">{message}</div>
			</div>
		</div>
	);
};

ErrorMessage.propTypes = {
	className: PropTypes.string,
	// Code à afficher avec le message par défaut.
	code:      PropTypes.string,
	// Titre à afficher, si nécessaire
	title:     PropTypes.string,
	// Message à afficher, si nécessaire
	message:   PropTypes.string,
};

ErrorMessage.defaultProps = {
	// Numéro bidon indiquant "erreur inconnue".
	code: "#555-0000"
};

ErrorMessage.contextTypes = {
	intl: PropTypes.object,
};

export default ErrorMessage;

