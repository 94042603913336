import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";
import Form                  from "qidigo-form/form";
import Fetch                 from "qidigo-fetch";
import Loading               from "qidigo-components/loading.js";
import CityInput             from "@app/components/city_input";

import COUNTRIES_TRANSLATIONS             from "qidigo-data/countries";
import STATES_TRANSLATIONS                from "qidigo-data/states";

const DEFAULT_COUNTRY = "CA";
const DEFAULT_STATE = 5;

const FIELDS = {
	CIVIC_NUMBER_AND_STREET: 'civic_number_and_street',
	CIVIC_NUMBER_EXT: 'civic_number_ext',
	POSTAL_CODE: 'postal_code',
	CITY: 'city',
	COUNTRY: 'country',
	STATE: 'state'
}

const fieldNames = defineMessages({
	[FIELDS.CIVIC_NUMBER_AND_STREET]:     {id: "qidigo.address_form.label.civic_number_and_street", defaultMessage: "Numéro civique et rue"},
	[FIELDS.CIVIC_NUMBER_EXT]:      {id: "qidigo.address_form.label.civic_number_ext", defaultMessage: "N° app."},
	[FIELDS.POSTAL_CODE]: {id: "qidigo.address_form.label.postal_code", defaultMessage: "Code postal"},
	[FIELDS.CITY]:        {id: "qidigo.address_form.label.city", defaultMessage: "Ville"},
	[FIELDS.STATE]:       {id: "qidigo.address_form.label.state", defaultMessage: "Province"},
	[FIELDS.COUNTRY]:     {id: "qidigo.address_form.label.country", defaultMessage: "Pays"},
});

class AddressPartial extends Component {
	componentDidMount() {
		this.fetchCountriesAndStates();

		const {onChange, value} = this.props;
		if (!value || !value.country) { onChange(FIELDS.COUNTRY, {}, DEFAULT_COUNTRY); }
		if (!value || !value.state)   { onChange(FIELDS.STATE, {}, DEFAULT_STATE); }
	}

	fetchCountriesAndStates() {
		Fetch.get(`countries`)
			.then(countries => {
				this.setState({
					countries: countries
				});

				return countries
			});
	}

	handleChange = (field, e, value) => {
		const {onChange} = this.props;
		onChange(field, e, value);
	}

	values() {
		const values = Object.assign({}, this.props.value);
		if (values.state && values.state["id"]) {
			values.state = values.state["id"];
		}

		if (values.country && values.country["id"]) {
			values.country = values.country["id"];
		}

		return values;
	}

	getCountriesOptions = (jsonArray) => {
		const {formatMessage} = this.context.intl;
		return jsonArray.map(({ id, name, slug }) => ({
			key: id,
			value: COUNTRIES_TRANSLATIONS[slug]
				? formatMessage(COUNTRIES_TRANSLATIONS[slug])
				: name,
		})).sort((countryA, countryB) => countryA.value.localeCompare(countryB.value));
	};

	getStatesOptions = (jsonArray) => {
		const {formatMessage} = this.context.intl;
		return jsonArray.map(({ name, slug, id }) => ({
			key: id,
			value: STATES_TRANSLATIONS[slug]
				? formatMessage(STATES_TRANSLATIONS[slug])
				: name,
		})).sort((stateA, stateB) => stateA.value.localeCompare(stateB.value));
	};

	render() {
		const {formatMessage} = this.context.intl;
		const {
			autoFocus,
			value,
			childrenBefore,
			children,
			className,
			// Keep from going in leftOverProps
			onChange, // eslint-disable-line
			...leftOverProps
		} = this.props;

		if (!this.state) {
			return <Loading />;
		}

		const classPrefix = "form-partial-address";
		const classNames = [classPrefix, className];

		const values = this.values();
		const country = values && values.country ? values.country : DEFAULT_COUNTRY;
		const state = values && values.state ? values.state : DEFAULT_STATE;

		const countriesOptions = this.getCountriesOptions(this.state.countries);
		const statesOptions = this.getStatesOptions(
			this.state.countries.find((countryFromState) => {
				return countryFromState.id === country
			}
			).states
		);

		return (
			<Form
				className={classNames.join(" ")}
				values={values}
				{...leftOverProps}
				onChange={this.handleChange}
			>
				{childrenBefore}
				<Form.Row
					className={`${classPrefix}--first_row`}
				>
					<Form.Input
						name={FIELDS.CIVIC_NUMBER_AND_STREET}
						value={values[FIELDS.CIVIC_NUMBER_AND_STREET]}
						autoComplete="street-address"
						required={true}
						label={formatMessage(fieldNames.civic_number_and_street)}
						autoFocus={autoFocus}
						className={`${classPrefix}--address`}
					/>
					<Form.Input
						name={FIELDS.CIVIC_NUMBER_EXT}
						autoComplete="foo"
						label={formatMessage(fieldNames.civic_number_ext)}
						className={`${classPrefix}--civic_number_ext`}
					/>
				</Form.Row>
				<Form.Row
					className={`${classPrefix}--second_row`}
				>
					<Form.Select
						name={FIELDS.COUNTRY}
						required={true}
						autoComplete="country"
						label={formatMessage(fieldNames.country)}
						externalLabel={formatMessage(fieldNames.country)}
						options={countriesOptions}
					/>
					<Form.Select
						name={FIELDS.STATE}
						required={true}
						autoComplete="address-level1"
						label={formatMessage(fieldNames.state)}
						externalLabel={formatMessage(fieldNames.state)}
						options={statesOptions}
					/>
				</Form.Row>
				<Form.Row
					className={`${classPrefix}--last_row`}
				>
					<CityInput
						name={FIELDS.CITY}
						autoComplete="foo"
						required={true}
						label={formatMessage(fieldNames.city)}
						inputRef={(node) => this.cityNode = node}
						country={country}
						state={state}
					/>
					<Form.Input
						name={FIELDS.POSTAL_CODE}
						autoComplete="postal-code"
						required={true}
						label={formatMessage(fieldNames.postal_code)}
					/>
				</Form.Row>
				{children}
			</Form>
		);
	}
}

AddressPartial.contextTypes = {
	intl: PropTypes.object,
};

AddressPartial.propTypes = {
	// FIXME : shape it
	value: PropTypes.object,
	className: PropTypes.string,
	childrenBefore: PropTypes.node,
	onChange: PropTypes.func,
	autoFocus: PropTypes.bool,
};

AddressPartial.defaultProps = {
	autoFocus: false,
};

export default AddressPartial;
