import React, {Component} from "react";
import PropTypes             from "prop-types";

/**
 * Component générique de checkbox.
 *
 * > **Note importante**
 * >
 * > L'API utilise `value` au lieu de `checked` comme propriété pour l'état de la
 * > *checkbox* pour simplifier l'API et harmoniser avec les autres *components*.
 *
 * `props.onChange`
 * ----------------
 *
 *     function (event, checked) {}
 *
 *   * Où `checked` est la valeur du champ, nouvellement assignée.
 *   * Où `event` est un *Event* où `target` est `this` (cette instance).
 *
 * **À noter**: [Malgré le comportement de React](https://facebook.github.io/react/docs/forms.html#potential-issues-with-checkboxes-and-radio-buttons),
 * on utilise onChange sur notre élément.
 *
 * @extends {Component}
 */
class Checkbox extends Component {
	handleChange(event) {
		const {value, onChange} = this.props;
		const originalTarget = event.target;

		// Temporairement, pour le handler.
		// Pourrait être un Event custom aussi.
		event.target = this;
		if (onChange) { onChange(event, !value); }
		event.target = originalTarget;
	}

	render() {
		let {
			/* eslint-disable */
			// Remove from inherited props
			presentation,
			onChange,
			/* eslint-enable */
			disabled,
			value,
			children,
			baseClass,
			labelFirst,
			className, label, role, ...leftoverProps
		} = this.props;

		// Classes appliquées au wrapper.
		const wrapperClasses = [
			baseClass, className,
			value ? "is-checked" : "is-not-checked",
			disabled ? "is-disabled" : "",
		];
		const labelDOM = <span className="checkbox--label-text">{label}</span>

		return (
			<div className={wrapperClasses.join(" ")}>
				<label>
					{labelFirst && labelDOM}
					<input
						placeholder=""
						{...leftoverProps}
						ref="checkbox"
						type="checkbox"
						role={role}
						className="checkbox--input"
						disabled={disabled}
						checked={value}
						onChange={(e)=>this.handleChange(e)}
					/>
					<span className="checkbox--checkbox checkbox--fake" />
					{labelFirst || labelDOM}
					{children}
				</label>
			</div>
		);
	}
}

Checkbox.propTypes = {
	// Pour simplifier l'API.
	value:              PropTypes.bool,
	onChange:           PropTypes.func,
	baseClass:          PropTypes.string,
	className:          PropTypes.string,
	label:              PropTypes.string,
	role:               PropTypes.string,
	disabled:           PropTypes.bool,
	presentation:       PropTypes.string,
	labelFirst:         PropTypes.bool,
};

Checkbox.defaultProps = {
	baseClass:          "checkbox",
	role:               "checkbox",
	labelFirst:         false,
};

Checkbox.defaultValue = false;

export default Checkbox;


