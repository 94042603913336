import React                 from "react";
import PropTypes             from "prop-types";
import {
	FormattedMessage,
} from "react-intl";

import COUNTRIES_TRANSLATIONS             from "qidigo-data/countries.js";
import STATES_TRANSLATIONS                from "qidigo-data/states.js";

export const UserAddress = (props) => {
	const {address} = props;
	// Codes pour pays et région.
	/* eslint-disable camelcase */
	const {civic_number_ext, country, state} = address;
	/* eslint-enable */

	let tag = null;
	if (props.tag) {
		tag = <span className="address--tag">
			{props.tag}
		</span>;
	}

	return (
		<address className="p-adr h-adr">
			<div className="address--line1">
				<div className="p-street-address">{address.civic_number_and_street}
					{/* eslint-disable camelcase */}
					{civic_number_ext && civic_number_ext !== "" ? ", " : ""}
					<span className="address--civic_number_ext">{civic_number_ext}</span>
					{/* eslint-enable */}
					{" "}{tag}
				</div>
			</div>
			<div className="address--line2">
				<span className="p-locality">{address.city["name"]}</span>
				{", "}
				<span className="p-postal-code">{address.postal_code}</span>
			</div>
			<div>
				<span className="p-region">
					{
						STATES_TRANSLATIONS[state["slug"]]
							? <FormattedMessage {...STATES_TRANSLATIONS[state["slug"]]} />
							: state["name"]
					}
				</span>
				{", "}
				<span className="p-country-name">
					{
						COUNTRIES_TRANSLATIONS[country["slug"]]
							? <FormattedMessage {...COUNTRIES_TRANSLATIONS[country["slug"]]} />
							: country["name"]
					}
				</span>
			</div>
		</address>
	);
};

UserAddress.propTypes = {
	address: PropTypes.object,
	tag: PropTypes.string,
};

export default UserAddress;
