import Money from "../../../app/components/money";
import AgeRestriction from "../../../app/views/shared/age_restriction";
import DurationFragment from "../../../app/views/shared/groups/duration";
import RemainingPlacesWidget from "../../../app/views/shared/groups/remaining_places";
import ScheduleFragment from "../../../app/views/shared/groups/schedule";
import OfferSelector from "../../../app/views/shared/offers/selector";
import { translations as messsageofferForms } from "../../../app/views/shared/offers/_offerform.js";
import WithEditor from "../../../app/components/with_editor";
// @ts-ignore
import {every, find, findLast } from "lodash";
import Button from "../../../../modules/qidigo-components/button";
import Image from "../../../../modules/qidigo-components/image.js";
// @ts-ignore
import GENDERS from "qidigo-data/GenderRestrictions.tsx";
import LocalizedDate         from "../../../../modules/qidigo-i18n/date";
// @ts-ignore
import { groupRestrictions, RestrictionsFragment } from "qidigo-data/restrictions";
import * as React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Group, IOffer, FamilyMember } from '../../models'
import { useEffect } from "react";
import {getOnWaitingList, getOption, getPlan, getRestrictions, getSelectedPlanOption, IRestrictionParameters} from "./utils";
import { GROUP_IS_FULL } from './constants'
// @ts-ignore
import { IRestriction } from "./types.js";
import GenderRestrictions from "../GenderRestrictions";

const translations = defineMessages({
  "already_on_waiting_list": {
    id: "qidigo.activity.groups.page.title.is.in.pending.list",
    defaultMessage: "Déjà dans liste d'attente"
  },
  "register": {
    id: "qidigo.activity.groups.page.register",
    defaultMessage: "Inscrire à la liste d'attente"
  },
  "page.title.pending.list": {
    id: "qidigo.activity.groups.page.title.pending.list",
    defaultMessage: "Le membre sera inscrit sur les listes d'attente suivantes"
  },
  "page.title.plan.list": {
    id: "qidigo.activity.groups.page.title.liste.offers",
    defaultMessage: "Choix des offres"
  },
  "page.sammary": {
    id: "qidigo.activity.groups.page.sammary",
    defaultMessage: "Récapitulatif pour "
  },
  view_more: {
    id: "qidigo.activity.groups.view_more",
    defaultMessage: "En savoir plus"
  },
  view: {
    id: "qidigo.activity.groups.view",
    defaultMessage: "Voir le groupe",
    description: "Message pour l'accessibilité du lien"
  },
  "selectionner.offer": {
    id: "qidigo.activity.groups.selectionner.offer",
    defaultMessage: "Veuillez sélectionner une offre"
  },
  "informations.activity": {
    id: "qidigo.activity.groups.informations.activity",
    defaultMessage: "Activité"
  },
  "informations.schedule": {
    id: "qidigo.activity.groups.informations.schedule",
    defaultMessage: "Plage horaire\u00A0:\u00A0"
  },
  "informations.duration": {
    id: "qidigo.activity.groups.informations.duration",
    defaultMessage: "Durée\u00A0:\u00A0"
  },
  adding: {
    id: "qidigo.activity.groups.offers.adding",
    defaultMessage: "Ajout en cours..."
  },
  "informations.remaining_places": {
    id: "qidigo.activity.groups.informations.remaining_places",
    defaultMessage: "Places restantes\u00A0:\u00A0"
  },
  "restriction.gender": {
    id: "qidigo.activity.groups.restriction.gender",
    defaultMessage: "Genre\u00A0:\u00A0"
  },
  "restriction.age": {
    id: "qidigo.activity.groups.restriction.age",
    defaultMessage: "Âge\u00A0:\u00A0"
  },
  "btn.back": {
    id: "qidigo.activity.groups.btn.back",
    defaultMessage: "Étape précédente"
  },
  "msg.future": {
    id: "qidigo.group.offerform.plan_start_date",
    defaultMessage: "Début des inscriptions\u00A0: {date}"
  },
  "btn.addPanier": {
    id: "qidigo.group.offerform.add",
    defaultMessage: "Ajouter au panier"
  },
  "btn.addToWaitingList": {
    id: "qidigo.group.offerform.addToWaitingList",
    defaultMessage: "Ajouter à la liste d'attente"
  },
  remove: {
    id: "qidigo.activity.groups.remove",
    defaultMessage: "Retirer l'article"
  },
  "select.plan": {
    id: "qidigo.activity.select.plan",
    defaultMessage: "Choisir une offre..."
  },
  "now_on_waiting_list": {
    id: "qidigo.activity.groups.page.now_on_waiting_list",
    defaultMessage: "Vous êtes inscrit sur la liste!"
  },
  "error": {
    id: "qidigo.activity.groups.page.error",
    defaultMessage: "Une erreur s'est produite et nous ne pouvons pas ajouter cet article au panier pour le moment"
  },
  all_items_added: {
    id: "qidigo.activity.groups.page.all_items_added",
    defaultMessage: "Tous les articles ont été ajouté avec succès à votre panier"
  },
});

interface IGroupFragmentProps {
  group: Group,
  offers: IOffer[][],
  handleSelectPlan: (id: string, shouldUpdatePrice: boolean, memberId: number) => void,
  selectedOffer: Record<string, number>,
  onDelete: (groupId: number, memberId: number) => void
  members: Record<string, FamilyMember>,
  newStatus: Record<number, string>,
  onAddToWaitingList: (planId: number, memberId: number) => void
  type: "pendingList" | "offerList",
  member: number,
  isLoading: boolean,
  cartItems,
  refreshOptions: () => void
}

export const GroupFragment: React.FC<IGroupFragmentProps> = (props, context) => {
  const {
      group,
      offers,
      handleSelectPlan,
      selectedOffer,
      onDelete,
      members,
      newStatus,
      type,
      member,
      isLoading,
      cartItems,
      refreshOptions
  } = props;

  const selectedOfferGroupKey = group.id;

  const classes = ["activity-group-fragment", "activity-group-fragment_no-pointer"];

  if (group["type_status_id"] !== "OK") {
    classes.push("is-preview");
  }

  const state = {
    offers: [] as IOffer[],
    member: null,
    offerSelect: "select.plan"
  };
  const options: any[] = [];

  // NOTE: preselect from only available option && from cart selections
  useEffect(() => {
    if (!selectedOffer[selectedOfferGroupKey]) {
      if (options.length === 1) {
            handleSelectPlan(`${group.id}:${options[0].key}`, false, member);
      } else {

        const cartItemForGroup = cartItems
            .filter(i => i.subscriber.id === member)
            .filter(i => i.plan.groups.find(g => g.id === group.id))[0];

        if (cartItemForGroup) {
            handleSelectPlan(`${group.id}:${options.find(o => o.key === cartItemForGroup.plan.id).key}`, false, member);
        }
      }
    }
  }, [options, selectedOffer, handleSelectPlan, group])


  let price_label: React.ReactNode = (
    <div className="group-activity--offer-warning">
      <FormattedMessage {...translations["selectionner.offer"]} />
    </div>
  );
  let description: string[] = [];

  offers.forEach(parentOffer => {
    parentOffer.forEach(offer => {
      if (find(offer.plan.groups, x => x.id === group.id)) {
        if (!find(options, x => x.key === offer.plan.id)) {
          options.push({
            key: offer.plan.id,
            value: offer.plan.label || offer.plan.name
          })
        }

        if (offer.plan.id == selectedOffer[selectedOfferGroupKey]) {
          const optionKey = offer.options.findIndex((option) => {
            return option.subscriber === member
          })

          price_label = (
            <div className="group-activity--price-label">
              <Money value={offer.options[optionKey].price} /> (<FormattedMessage {...messsageofferForms["txt.fees"]} />)
            </div>
          );
          description.splice(0, description.length);
          description.push(offer.plan.description || '');
        }

      }
      state.offers.push(offer);
    });
  });

  let addFragment = null as React.ReactNode;

  const selectedOfferByGroup = selectedOffer[group.id];
  const selectedPlanOption = getSelectedPlanOption(group, offers, selectedOfferByGroup);
  const restrictionsParameters: IRestrictionParameters = {
      group: group,
      offers : offers,
      members: members,
      member: member,
      selectedOffer: selectedOfferByGroup
  }
  const restrictions: IRestriction | null = getRestrictions(restrictionsParameters);
  const onWaitingList = getOnWaitingList(restrictionsParameters);

  if (selectedPlanOption) {
    const option = getOption(restrictionsParameters);
    if (option) {
      const isProductRestricted = restrictions ? restrictions.product_restrictions.length > 0 : false;
      const hasOtherRestrictionsBesideProductRestrictions = restrictions && 
        (restrictions.groups_restrictions.length > 0 ||
        restrictions.membership_restrictions.length > 0 ||
        restrictions.organization_restrictions.length > 0 ||
        restrictions.registration_restrictions.length > 0);
      const isRestricted = isProductRestricted || hasOtherRestrictionsBesideProductRestrictions;

      let frag = null as React.ReactNode;

    if (isRestricted) {
        const allGroupsHaveWaitingList = every(selectedPlanOption.plan.groups, g => g.waiting_list);
        const onlyHasGroupIsFullProductRestriction = restrictions
            && restrictions.product_restrictions.length === 1
            && restrictions.product_restrictions[0].code === GROUP_IS_FULL;
        const displayWaitingListFragement = allGroupsHaveWaitingList && onlyHasGroupIsFullProductRestriction;
        const hasProductRestrictionBesideGroupIsFull = restrictions &&
            restrictions.product_restrictions.filter(x => x.code !== GROUP_IS_FULL).length > 0;
        let restrictionSectionFragment = null as React.ReactNode;
        if (hasProductRestrictionBesideGroupIsFull || hasOtherRestrictionsBesideProductRestrictions || !displayWaitingListFragement) {

            let restrictionWithoutProductGroupIsFull = restrictions;
            if (displayWaitingListFragement)
            {
                restrictionWithoutProductGroupIsFull = {
                groups_restrictions: restrictions ? restrictions.groups_restrictions : [],
                organization_restrictions: restrictions ? restrictions.organization_restrictions : [],
                product_restrictions: restrictions ? restrictions.product_restrictions.filter(x => x.code !== GROUP_IS_FULL) : [],
                registration_restrictions: restrictions ? restrictions.registration_restrictions : [],
                membership_restrictions: restrictions ? restrictions.membership_restrictions : []
                }
            }
          const restrictionFragment =
            <div className="group-fragment--restrictions">
              <WithEditor then={refreshOptions}>
                <RestrictionsFragment
                    restrictions={restrictionWithoutProductGroupIsFull}
                    groupsCount={selectedPlanOption.plan.groups.length}
                    groupRestrictions={groupRestrictions(group)}
                />
              </WithEditor>
            </div>

          const futureFragment =
            <div className="group-fragment--sub-future">
              <FormattedMessage
                  {...translations["msg.future"]}
                  values={{
                    date: <LocalizedDate date={option.start_date} withTime={true} />,
                  }}
              />
            </div>

          if (restrictions && restrictions.product_restrictions.find(
                (restriction) => restriction.code ==='PLAN_NOT_YET_ACTIVE'
            ) !== undefined) {
            if (restrictions.product_restrictions.length === 1) {
              restrictionSectionFragment = futureFragment;
            } else {
              restrictionSectionFragment =
                <div>
                  {restrictionFragment}
                  {futureFragment}
                </div>
            }
          } else {
            restrictionSectionFragment = restrictionFragment;
          }
        }

        if (displayWaitingListFragement) {
          if (newStatus[selectedOffer[selectedOfferGroupKey]] === "WAITING_LIST") {
            frag = (
              <FormattedMessage {...translations["now_on_waiting_list"]} />
            );
          } else if (onWaitingList) {
            frag = (
              <FormattedMessage
                {...translations["already_on_waiting_list"]}
              />
            );
          } else {
            frag = (
              <Button
                onClick={e => props.onAddToWaitingList(selectedOffer[selectedOfferGroupKey], member)}
                disabled={!selectedOffer[selectedOfferGroupKey]}
              >
                <FormattedMessage
                  {...translations["register"]}
                />
              </Button>
            )
          }

          addFragment = (
            <div className="group-fragment--waiting-list">
              {frag}
              <br />
              {restrictionSectionFragment}
            </div>
          );

        } else {
          addFragment = restrictionSectionFragment
        }
      }
    }
  }

  if (onWaitingList) {
    addFragment =
     <div className="group-fragment--waiting-list">
        <FormattedMessage
            {...translations["already_on_waiting_list"]}
        />
     </div>
  }

  if (selectedOffer[selectedOfferGroupKey] == 0) {
    description = [];
  }

  if (addFragment != null) {
    description = [];
    price_label = addFragment;
  }

  let content: React.ReactNode = "";

  switch (type) {
    case "pendingList": {
      classes.push("offer-pending-fragment");
      content = (
        <div>
          {addFragment}
          <br />
          {
            !onWaitingList &&
            <OfferSelector
              group={group}
              // @ts-ignore
              options={Object.values(options)}
              disabled={newStatus[selectedOffer[selectedOfferGroupKey]] === "WAITING_LIST"}
              selected={group.id + ":" + selectedOffer[selectedOfferGroupKey]}
              handleChange={(_, id) => handleSelectPlan(id, true, member)}
            />
          }
        </div>
      )
      break;
    }

    case "offerList": {
      content = (
        <div key={group.id + "2"}>
          {
            options.length > 1 &&
            <OfferSelector
              group={group}
              // @ts-ignore
              options={Object.values(options)}
              selected={group.id + ":" + selectedOffer[selectedOfferGroupKey]}
              handleChange={(_, id) => handleSelectPlan(id, true, member)}
            />
          }
          <ul>
            <li className="activity-group-selected--price">{price_label}</li>
          </ul>
          <p className="padding-top-bottom" dangerouslySetInnerHTML={{__html: description.join('')}}></p>
        </div>
      );
      break
    }
  }

  return (
    <li className={classes.join(" ") + " " + group.id} key={group.id}>
      <div key={group.id + "1"}>
        <div className="activity-group-fragment--title">
          <h3>{group.name}</h3>
          {
            newStatus[selectedOffer[selectedOfferGroupKey]] === "ERROR" &&
            <div className="activity-group-fragment--error-message">
                <FormattedMessage
                  {...translations["error"]}
                />
            </div>
          }
          {
            newStatus[selectedOffer[selectedOfferGroupKey]] !== "WAITING_LIST" &&
            <a
              className={`activity-group-fragment--remove-link ${isLoading ? 'activity-group-fragment--remove-link_disabled' : ''}`}
              onClick={() => !isLoading && onDelete(group.id, member)}
            >
              <FormattedMessage {...translations["remove"]} />
            </a>
          }
        </div>
        <div className="activity-group-fragment--image">
          <Image src={group.media.path} alt={group.media.text_alt} />
        </div>
        <ul className="activity-group-fragment--informations">
          <li>
            <h4>
              <FormattedMessage {...translations["informations.duration"]} />
            </h4>
            <DurationFragment duration={group.duration} capitalized={false} />
          </li>
          <li>
            <h4>
              <FormattedMessage {...translations["informations.schedule"]} />
            </h4>
            <ScheduleFragment times={group.schedule} />
          </li>
          <li>
            <h4>
              <FormattedMessage
                {...translations["informations.remaining_places"]}
              />
            </h4>
            <RemainingPlacesWidget num={group.nbr_places_remaining} />
          </li>
          <li>
            <h4>
              <FormattedMessage {...translations["restriction.gender"]} />
            </h4>
            <span>
              <GenderRestrictions
                  genders={group.restrictions_gender}
                  targetMemberMessage={""}
                  forPassModal={false}
              />
            </span>
          </li>
          <li>
            <h4>
              <FormattedMessage {...translations["restriction.age"]} />
            </h4>
            <span>
              <AgeRestriction
                min={group.restriction_age_min}
                max={group.restriction_age_max}
              />
            </span>
          </li>
        </ul>
      </div>
      {content}
    </li>
  );
};

export default GroupFragment;
