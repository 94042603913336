import * as React from "react";
import {Component} from "react";
import {IPaymentMethod} from "../types";
import EmptyList from "../../../components/empty_list";
// @ts-ignore
import HTML                  from "@app/components/html";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import Button                from "qidigo-components/button.js";
// @ts-ignore
import AMEX_ICON from "../../../../design/assets/payment_methods_icons/AMEX.svg";
// @ts-ignore
import BANK_ICON from "../../../../design/assets/payment_methods_icons/BANK.svg";
// @ts-ignore
import CARD_WITH_MONEY_SIGN_ICON from "../../../../design/assets/payment_methods_icons/CARD_WITH_MONEY_SIGN.svg";
// @ts-ignore
import interactIcon from "../assets/icon/payment-method/interact.svg";
// @ts-ignore
import CARD_ICON from "../../../../design/assets/payment_methods_icons/CARD.svg";
// @ts-ignore
import CASH_ICON from "../../../../design/assets/payment_methods_icons/CASH.svg";
// @ts-ignore
import DISCOVER_ICON from "../../../../design/assets/payment_methods_icons/DISCOVER.svg";
// @ts-ignore
import INTERAC_ICON from "../../../../design/assets/payment_methods_icons/INTERAC.svg";
// @ts-ignore
import MASTERCARD_ICON from "../../../../design/assets/payment_methods_icons/MASTERCARD.svg";
// @ts-ignore
import MONEY_TRANSFER_ICON from "../../../../design/assets/payment_methods_icons/MONEY_TRANSFER.svg";
// @ts-ignore
import PHONE_WAVE_ICON from "../../../../design/assets/payment_methods_icons/PHONE_WAVE.svg";
// @ts-ignore
import VISA_ICON from "../../../../design/assets/payment_methods_icons/VISA.svg";

interface IPaymentMethodListProps {
    payment_methods: Array<IPaymentMethod>,
    selectPaymentMethod: (paymentMethod: IPaymentMethod) => void,
    selectedPaymentMethod: IPaymentMethod|null,
}

const translations = defineMessages({
    "no_item": {
        id: "qidigo.billing.payment_methods.no_item",
        defaultMessage: "Aucune méthode de paiement disponible pour ces articles"
    },
    "PAYSAFE_CAD_DD": {
        id: "qidigo.billing.payment_methods.direct_debit",
        defaultMessage: "Débit préautorisé"
    },
    "PAYSAFE_CAD_CC": {
        id: "qidigo.billing.payment_methods.credit_card",
        defaultMessage: "Carte de crédit"
    },
    "STRIPE": {
        id: "qidigo.billing.payment_methods.stripe",
        defaultMessage: "Paiement en ligne (Stripe)"
    },
});

const ONLINE_PAYMENT_METHOD_BASIC_NAMES = [
    'Paiement en ligne (Stripe)',
    'Carte de crédit',
    'Débit préautorisé'
]

class PaymentMethodList extends Component<IPaymentMethodListProps> {
    getIcon(icon) {
        switch (icon) {
            case "INTERAC":
                return INTERAC_ICON;
            case "VISA":
                return VISA_ICON;
            case "MASTERCARD":
                return MASTERCARD_ICON;
            case "AMEX":
                return AMEX_ICON;
            case "CARD":
                return CARD_ICON;
            case "CARD_WITH_MONEY_SIGN":
                return CARD_WITH_MONEY_SIGN_ICON;
            case "CASH":
                return CASH_ICON;
            case "MONEY_TRANSFER":
                return MONEY_TRANSFER_ICON;
            case "BANK":
                return BANK_ICON;
            case "PHONE":
                return PHONE_WAVE_ICON;
            case "DISCOVER":
                return DISCOVER_ICON;
            default:
                return CARD_ICON;
        }
    }

    makeButton(paymentMethod: IPaymentMethod) {
        const isSelected = this.props.selectedPaymentMethod
            && this.props.selectedPaymentMethod.id === paymentMethod.id;

        return (
            <li key={paymentMethod.id} className={"payment-methods-list--item"}>
                <Button
                    className={"payment-methods-list--item-button " + (isSelected ? "selected" : "unselected")}
                    onClick={() => this.props.selectPaymentMethod(paymentMethod)}
                >
                    <img
                        className={"payment-methods-list--icon"}
                        src={this.getIcon(paymentMethod.icon)}
                        alt={paymentMethod.icon}
                    />
                    {
                        paymentMethod.type !== 'CUSTOM'
                        && ONLINE_PAYMENT_METHOD_BASIC_NAMES.find((name) => name === paymentMethod.name) !== undefined
                            ? <FormattedMessage {...translations[paymentMethod.type]}/>
                            : <span>{ paymentMethod.name}</span>
                    }
                </Button>
            </li>
        );
    }

    render() {
        return (
            <div>
                <div>
                    <EmptyList
                        className={
                            this.props.payment_methods.length > 0
                                ? "payment-methods--list"
                                : "payment-methods--empty-list"
                        }
                        text={<FormattedMessage {...translations["no_item"]} />}
                    >
                       {
                            this.props.payment_methods.map(
                                (paymentMethod: IPaymentMethod) => this.makeButton(paymentMethod)
                            )
                        }
                    </EmptyList>
                </div>
                <div className={"payment-method--message-to-payer"}>
                    {
                        this.props.payment_methods.length > 0 &&
                        this.props.selectedPaymentMethod
                        && this.props.selectedPaymentMethod.message_to_payer &&
                        <HTML contents={this.props.selectedPaymentMethod.message_to_payer} />
                    }
                </div>
            </div>
        );
    }
}

export default PaymentMethodList;
