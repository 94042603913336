import React                 from "react";
import PropTypes             from "prop-types";
import Title                 from "./title";
import OrganizationInformations from "@app/views/shared/organization/organization_informations";

import {
	defineMessages,
	FormattedMessage
} from "react-intl";

const translations = defineMessages({
	"page.title":   {id: "qidigo.organization.contact.title", defaultMessage: "Contact"},
});

/**
 */
const OrganizationContactView = (props, context) => {
	const {organization} = props;
	const {formatMessage} = context.intl;

	return (
		<div className="organization-contact">
			<Title organization={organization} title={formatMessage(translations["page.title"])} />
			<section>
				<h1><FormattedMessage {...translations["page.title"]} /></h1>
				<OrganizationInformations
					className="organization--infopanel"
					organization={organization}
				/>
			</section>
		</div>
	);
};

OrganizationContactView.propTypes = {
	organization: PropTypes.object.isRequired,
};

OrganizationContactView.contextTypes = {
	intl: PropTypes.object,
};

export default OrganizationContactView;
