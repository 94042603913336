import EmptyList from "@app/components/empty_list";
import Money from "@app/components/money.js";
import { path } from "@app/lib/routes";
import AgeRestriction from "@app/views/shared/age_restriction";
import DurationFragment from "@app/views/shared/groups/duration";
import RemainingPlacesWidget from "@app/views/shared/groups/remaining_places";
import ScheduleFragment from "@app/views/shared/groups/schedule";
import PropTypes from "prop-types";
import Image from "qidigo-components/image.js";
import GENDERS from "qidigo-data/genders.js";
import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router/es";
import Title from "./Title";
import GenderRestrictions from "../GenderRestrictions";


const translations = defineMessages({
	"page.title": {id: "qidigo.activity.groups.page.title", defaultMessage: "Session"},
	"view_more": {id: "qidigo.activity.groups.view_more", defaultMessage: "En savoir plus"},
	"view":      {id: "qidigo.activity.groups.view", defaultMessage: "Voir le groupe", description: "Message pour l'accessibilité du lien"},
	"price_from": {id: "qidigo.activity.groups.price_from", defaultMessage: "À partir de"},

	"informations.remaining_places": {id: "qidigo.activity.groups.informations.remaining_places", defaultMessage: "Places restantes\u00A0:\u00A0"},
	"restriction.gender": {id: "qidigo.activity.groups.restriction.gender", defaultMessage: "Genre\u00A0:\u00A0"},
	"restriction.age": {id: "qidigo.activity.groups.restriction.age", defaultMessage: "Âge\u00A0:\u00A0"},
	"informations.activity": {id: "qidigo.activity.groups.informations.activity", defaultMessage: "Activité"},
	"informations.schedule": {id: "qidigo.activity.groups.informations.schedule", defaultMessage: "Plage horaire\u00A0:\u00A0"},
	"informations.duration": {id: "qidigo.activity.groups.informations.duration", defaultMessage: "Durée\u00A0:\u00A0"},
	"adding": {id: "qidigo.activity.groups.offers.adding", defaultMessage: "Ajout en cours..."},
});

const GroupsFragment = (props) => {
	const {group, activity} = props;

	const classes = ["activity-group-fragment"];

	if (group["type_status_id"] !== "OK") {
		classes.push("is-preview");
	}

	const params = {
		orgID: activity.organization["slug"],
		activityID: activity["id"],
		groupID: group["id"],
	}

	return (
		<li className={classes.join(" ")}>
			<Link to={path("group", params)}
				className="activity-group-fragment--infos"
			>
				<div>
					<h3>{group.name}</h3>
					<div className="activity-group-fragment--image">
						<Image src={group.media.path} alt={group.media.text_alt} />
					</div>
					<ul className="activity-group-fragment--informations">
						<li>
							<h4><FormattedMessage {...translations["informations.duration"]} /></h4>
							<DurationFragment
								duration={group.duration}
								capitalized={false}
							/>
						</li>
						<li>
							<h4><FormattedMessage {...translations["informations.schedule"]} /></h4>
							<ScheduleFragment times={group.schedule} />
						</li>
						<li>
							<h4><FormattedMessage {...translations["informations.remaining_places"]} /></h4>
							<RemainingPlacesWidget
								num={group.nbr_places_remaining}
							/>
						</li>
						<li>
							<h4><FormattedMessage {...translations["restriction.gender"]} /></h4>
							<GenderRestrictions
								genders={group.restrictions_gender}
								targetMemberMessage={""}
								forPassModal={false}
							/>
						</li>
						<li>
							<h4><FormattedMessage {...translations["restriction.age"]} /></h4>
							<span>
								<AgeRestriction
									min={group.restriction_age_min}
									max={group.restriction_age_max}
								/>
							</span>
						</li>
					</ul>
					<div className="activity-group-fragment--price">
						<strong><FormattedMessage {...translations["price_from"]} /></strong>
						<Money value={group["lowest_price"]} />
					</div>
					<div className="activity-group-fragment--view">
						<span><FormattedMessage {...translations["view"]} /></span>
					</div>
				</div>
			</Link>
		</li>
	);
};

GroupsFragment.propTypes = {
	group: PropTypes.object,
	activity: PropTypes.object,
};

const GroupsView = (props, context) => {
	const {
		activity,
		organization,
		groups,
	} = props;

	const {formatMessage} = context.intl;

// TODO : Ajouter un collapse avec bouton [Acheter] qui affiche les offres.

	return (
		<div className="activity-groups-list">
			<Title
				title={formatMessage(translations["page.title"])}
				activity={activity}
				organization={organization}
			/>
			<section>
				<h2><FormattedMessage {...translations["page.title"]} /></h2>
				<EmptyList>
					{groups.map((group, key) => <GroupsFragment key={key} group={group} activity={activity} />)}
				</EmptyList>
			</section>
		</div>
	);
};

GroupsView.propTypes = {
	activity: PropTypes.object,
	organization: PropTypes.object,
	groups: PropTypes.arrayOf(PropTypes.object),
};

GroupsView.defaultProps = {
};

GroupsView.contextTypes = {
	intl: PropTypes.object,
};

export default GroupsView;
