import React, {Component}  from "react";
import PropTypes             from "prop-types";
import Radio               from "./radio.js";
import T                     from "qidigo-i18n/messages";
import {FormattedMessage}    from "react-intl";

/**
 * Component d'un regroupement de `<Radio>`.
 *
 * C'est pour les collections simples.
 *
 * Quand on veut plus de contrôle, on peut utiliser directement `<Radio>`.
 *
 * @extends {Component}
 */
class RadioGroup extends Component {
	constructor() {
		super();
		this.state = {
			valid: true,
			value: null,
		};
	}

	validate(softValidate) {
		const {formatMessage} = this.context.intl;

		if (softValidate) {
			return [];
		}

		const errors = [];

		if (this.props.required &&
			(!this.props.value || this.props.value === "")
		) {
			return [formatMessage(T["errors.messages.blank"])];
		}

		return errors;
	}

	handleChange(event, value) {
		this.setState({valid: true});
		if (!value) { return; }
		let originalTarget = event.target;

		// Temporairement, pour le handler.
		// Pourrait être un Event custom aussi.
		event.target = this;
		if (this.props.onChange) {
			this.props.onChange(event, value);
		}

		// On remet le bon target.
		event.target = originalTarget;

		if (!event.defaultPrevented) {
			this.setState({value: value});
		}
	}

	componentWillReceiveProps(newProps) {
		if (this.props.valid !== newProps.valid) {
			this.setState({valid: newProps.valid});
		}
		if (this.props.value !== newProps.value) {
			this.setState({value: newProps.value});
		}
		if (this.props.error !== newProps.error) {
			this.setState({error: newProps.error});
		}
	}

	componentWillMount() {
		this.setState({value: this.props.value || this.state.value || ""});
	}

	render() {
		const {
			onChange, // eslint-disable-line
			valid,    // eslint-disable-line
			options,
			error,
			label,
			className,
			...leftoverProps
		} = this.props;

		// Classes appliquées au wrapper.
		let wrapperClasses = ["radiogroup", className];

		if (!this.state.valid || this.state.error) {
			wrapperClasses.push("is-invalid");
		}

		const optionElements = Object.keys(options).map((k) => {
			return <Radio
				name={name}
				key={k}
				value={k}
				label={options[k]}
				checked={this.state.value.toString() === k.toString()}
				onChange={(...rest)=>{ return this.handleChange(...rest); }}
				disabled={this.props.disabled}
			/>;
		});

		let errorMessage = null;
		if (error) {
			wrapperClasses.push("with-error-message");
			errorMessage =
				<div className="input--error-message">
					<FormattedMessage {...T["errors.format"]} values={{
						message: error,
						attribute: label,
					}} />
				</div>
			;
		}

		return (
			<div className={wrapperClasses.join(" ")}
				{...leftoverProps}
			>
				{optionElements}
				{errorMessage}
			</div>
		);
	}
}

RadioGroup.propTypes = {
	onChange:           PropTypes.func,
	className:          PropTypes.string,
	value:              Radio.propTypes.value,
	options:            PropTypes.object.isRequired,
	name:               PropTypes.string.isRequired,
	valid:              PropTypes.bool,
	error:              PropTypes.string,
	disabled:           PropTypes.bool,
	required:           PropTypes.bool,
};

RadioGroup.defaultProps = {
};

RadioGroup.contextTypes = {
	intl: PropTypes.object,
};

export default RadioGroup;

