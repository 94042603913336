import * as React from "react";
import {Component} from "react";
import {IBillingInstallment, IPaymentMethod} from "../../types";
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Loading from 'qidigo-components/loading';
// @ts-ignore
import Date    from "qidigo-i18n/date";
// @ts-ignore
import Money   from "@app/components/money";
import InstallmentWarning from "../../installmentWarning";

interface ICustomFormProps {
    paymentMethods: IPaymentMethod,
    isInstallmentMissing: boolean,
    handlePayment: () => void,
    processingPayment: boolean,
    disabled: boolean,
    billingInstallments: Array<IBillingInstallment>,
}

const translations = defineMessages({
    "confirm": {
        id: "qidigo.billing.custom_form.confirm",
        defaultMessage: "Confirmer",
    }
});
class CustomForm extends Component<ICustomFormProps> {
    pay = () => {
        if (this.props.isInstallmentMissing) {

            return;
        }

        this.props.handlePayment();
    }

    render() {
        return (this.props.processingPayment ? <Loading/> :
            <div className={"custom-form--button-container"}>
                {
                    this.props.billingInstallments.length > 0 &&
                    <InstallmentWarning installments={this.props.billingInstallments}/>
                }
                <Button
                    className={"custom-form--button primary"}
                    onClick={this.pay}
                    disabled={this.props.disabled}
                >
                    <FormattedMessage {...translations["confirm"]} />
                </Button>
            </div> 
        );
    }
}

export default CustomForm;
