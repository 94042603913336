import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import capitalize            from "lodash/capitalize";
import moment                from "moment";
import upperFirst from "lodash/upperFirst";

const translations = defineMessages({
	"from":       {id: "qidigo.groups.schedule.from",    defaultMessage: "du {date}"},
	"to":         {id: "qidigo.groups.schedule.to",      defaultMessage: "au {date}"},
	"unqualified.from":       {id: "qidigo.groups.schedule.unqualified.from",    defaultMessage: "{date}", description: "Only 'from' is unqualified (in french)"},
	"unqualified.to":         {id: "qidigo.groups.schedule.unqualified.to",      defaultMessage: "au {date}", description: "Only 'from' is unqualified (in french)"},
	"concise.sameday": {id: "qidigo.groups.schedule.concise.sameday", defaultMessage: "{date} de {start} à {end}", description: "start/end are time only."},
});

/**
*/
const DurationFragment = (props, context) => {
	// FIXME : Utiliser <Date> au lieu de moment.format();
	const {
		className,
		duration,
		capitalized,
		unqualified,
		concise,
		withTime,
	} = props;
	const {formatMessage} = context.intl;
	const classes = [className, "durationfragment"];

	if (!duration || duration.length === 0) {
		if (process.env.NODE_ENV === "development") {
			console.warn("No duration given"); // eslint-disable-line
		}

		return null;
	}

	let durationFragment = null;
	if (duration.length === 1) {
		durationFragment =
			<li>
				{moment(duration[0]).format(withTime ? "LL LT" : "LL")}
			</li>
			;
	}
	else if (duration.length === 2) {
		const sameDate = moment(duration[0]).isSame(moment(duration[1]), "day")
		if (sameDate && concise) {
			const start = moment(duration[0]);
			const end = moment(duration[1]);
			if (withTime) {
				durationFragment =
					<li>
						<span>
							<FormattedMessage {...translations["concise.sameday"]} values={{
								date: start.format("LL"),
								start: start.format("LT"),
								end: end.format("LT"),
							}} />
						</span>
					</li>
				;
			} else {
				durationFragment =
					<li>
						<span>
							{start.format("LL")}
						</span>
					</li>
			}
		}
		else {
			durationFragment =
				["from", "to"].map((id) => {
					let msg = formatMessage(
						{...translations[unqualified ? `unqualified.${id}` : id]},
						{date: moment(duration[id==="from"?0:1]).format(withTime ? "LL LT" : "LL")}
					);
					if (capitalized) { msg = upperFirst(msg); }
					return (
						<li key={id}>
							<span>{msg}{" "}</span>
						</li>
					);
				})
			;
		}
	}

	return (
		<ul className={classes.join(" ")}>
			{durationFragment}
		</ul>
	);
};

DurationFragment.defaultProps = {
	capitalized: true,
	unqualified: false,
	withTime: false,
	concise: false,
};

DurationFragment.contextTypes = {
	intl: PropTypes.object,
};

DurationFragment.propTypes = {
	className: PropTypes.string,
	duration: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		])
	),
	withTime: PropTypes.bool,
	unqualified: PropTypes.bool,
	capitalized: PropTypes.bool,
	concise: PropTypes.bool,
};

export default DurationFragment;
