import * as React from "react";
import { AlertContext } from "../views/AlertContextProvider";

export function withAlerts(WrappedComponent: any) {
    return class extends React.Component {
        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return (
                <AlertContext.Consumer>
                    {
                        ({ setAlertMessage }) => <WrappedComponent setAlertMessage={setAlertMessage} {...this.props} />
                    }
                </AlertContext.Consumer>
            );
        }
    };
}
