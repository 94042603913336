import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Title                 from "./Title";
import {
	Link,
	IndexLink,
} from "react-router/es";
import OrganizationInformations from "@app/views/shared/organization/organization_informations.js";

import {
	defineMessages,
	FormattedMessage
} from "react-intl";

const translations = defineMessages({
	"title.contact":   {id: "qidigo.activity.contact.title", defaultMessage: "Contact"},
});

/**
 */
const ActivityContactView = (props, context) => {
	const {organization, activity} = props;
	const {formatMessage} = context.intl;

	return (
		<div className="organization-contact">
			<Title
				title={formatMessage(translations["title.contact"])}
				organization={organization}
				activity={activity}
			/>
			<section>
				<h1><FormattedMessage {...translations["title.contact"]} /></h1>
				<OrganizationInformations
					className="organization--infopanel"
					organization={organization}
				/>
			</section>
		</div>
	);
};

ActivityContactView.propTypes = {
	organization: PropTypes.object.isRequired,
	activity: PropTypes.object.isRequired,
};

ActivityContactView.contextTypes = {
	intl: PropTypes.object,
};

export default ActivityContactView;
