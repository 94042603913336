import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import {Link}                from "react-router/es";
import Collapse              from "react-collapse";

import ButtonsBar            from "qidigo-form/buttons_bar";
import Separator             from "qidigo-components/separator";
import Checkbox              from "qidigo-form/checkbox";
import {errorMessagesType}   from "qidigo-form/proptypes";
import SocialLogin           from "@app/components/social_login";
import EmailPassword         from "@app/views/shared/email_password";

const messages = defineMessages({
	"title":     {id: "qidigo.login.partial.title", defaultMessage: "Compte existant"},
	"forgot":    {id: "qidigo.login.partial.forgot", defaultMessage: "Mot de passe oublié"},
	"remember":  {id: "qidigo.login.partial.remember", defaultMessage: "Se souvenir de moi"},

	"login.action": {id: "qidigo.login.partial.login.action", defaultMessage: "Me connecter", description: "Button that opens the section."},
	"login.blurb":  {id: "qidigo.login.partial.login.blurb", defaultMessage: "Déjà un compte? Bienvenue à nouveau chez nous!"},
	"or": {id: "qidigo.login.partial.or", defaultMessage: "Ou"}
});

const LoginPartial = (props, context) => {
	const {formatMessage} = context.intl;
	const {shown, unfold} = props;

	return (
		<section className="login">
			<div className="login--login-box">
				<h1><FormattedMessage {...messages.title} /></h1>
				<p>
					<FormattedMessage {...messages["login.blurb"]} />
				</p>
				<Collapse className="collapse" isOpened={shown}>
					<SocialLogin action="login" />
					<Separator><FormattedMessage {...messages.or}/></Separator>
					<EmailPassword
						onSubmit={(...e)=>props.onSubmit(...e)}
						onChange={(...e)=>props.onChange(...e)}
						onValidationError={(errors)=>props.onValidationError(errors)}
						disabled={props.loading}
						values={props.values}
						errors={props.errors}
					>
						<div className="login--forgot-password">
							<Link to="/forgot/password"><FormattedMessage {...messages.forgot} /></Link>
						</div>
						<Checkbox name="remember"
							label={formatMessage(messages.remember)}
						/>
					</EmailPassword>
				</Collapse>
				<Collapse className="collapse" isOpened={!shown}>
					<ButtonsBar
						submitLabel={formatMessage(messages["login.action"])}
						onSubmit={(...e) => unfold("login")}
					/>
				</Collapse>
			</div>
		</section>
	);
};

LoginPartial.propTypes = {
	shown: PropTypes.bool,
	unfold: PropTypes.func,
	errors:    errorMessagesType,
	values:    PropTypes.object,
	loading:   PropTypes.bool,
	onChange:  PropTypes.func,
	onSubmit:  PropTypes.func,
	onValidationError: PropTypes.func,
};

LoginPartial.contextTypes = {
	intl: PropTypes.object,
};

export default LoginPartial;
