import React                 from "react";
import PropTypes             from "prop-types";
import Button                from "qidigo-components/button.js";
import Loading               from "qidigo-components/loading.js";
import {
	FormattedMessage,
	defineMessages,
} from "react-intl";

const messages = defineMessages({
	save: {
		id:"qidigo-form.buttons-bar.save",
		defaultMessage:"Enregistrer",
	},
});

/**
 * Présente une barre réutilisable pour compléter les formulaires.
 * Le bouton (Enregistrer) a un label régulier généralement bon.
 * Il peut présenter un *widget* de load.
 * Il se désactive au *load*.
 * Fonctionne implicitement avec `<Form>`.
 * Les `children` sont placés entre le loading et le bouton enregistrer.
 * Le bouton enregistrer est toujours en dernier.
 * Il est possible d'ajouter des éléments à gauche (avant le loading)
 * avec `props.before`.
 */
const ButtonsBar = (props) => {
	const {before, after} = props;

	let loading = null;
	if (props.loading) {
		loading = <Loading compact={true} />;
	}
	let submitLabel = props.submitLabel;
	if (!submitLabel) {
		submitLabel = <FormattedMessage {...messages.save} />
		;
	}

	// Choisit le type de bouton.
	const submitType = props.onSubmit ? "button" : "submit";
	const submitProps = Object.assign({}, props.submitProps);

	// Attache l'action *submit*.
	if (props.onSubmit) {
		submitProps.onClick = props.onSubmit;
	}

	const {Before} = props;

	return (
		<div
			className={`form-buttons-bar ${props.className}`}
		>
			{before && <Before>{before}</Before>}
			{loading}
			{props.children}
			{props.withoutSubmit ? null :
				<Button
					type={submitType}
					disabled={props.loading || props.disabled}
					className={"primary"}
					{...submitProps}
				>
				{submitLabel}
			</Button>
			}
			{
				after &&
					<div className="form-buttons-bar__after">
						{after}
					</div>
			}
		</div>
	);
};

ButtonsBar.propTypes = {
	children: PropTypes.any,
	/**
	 * En état de chargement
	 * Implicitement désactive l'envoi
	 */
	loading: PropTypes.bool,
	/**
	 * Désactive l'envoi. Il est possible qu'on veuille désactiver
	 * l'envoi séparément de l'état d'enregistrement.
	 */
	disabled: PropTypes.bool,
	/**
	 * Place des `children` à "gauche" de l'élément (loading).
	 */
	before: PropTypes.node,
	/**
	 * Wrapper node for the `before` prop.
	 */
	Before: PropTypes.oneOfType([
		PropTypes.symbol,
		PropTypes.node,
		PropTypes.func,
	]),
	/**
	 * Place des `children` après le bouton submit.
	 *
	 * Généralement utilisé pour donner un "footer" à la barre.
	 */
	after: PropTypes.node,
	/**
	 * Label utilisé sur le bouton submit.
	 */
	submitLabel: PropTypes.node,
	/**
	 * Retire la présence du bouton, primary, par défaut.
	 * (Sans submit est une utilisation plus rare qu'avec submit)
	 */
	withoutSubmit: PropTypes.bool,
	/**
	 * Action pour le bouton. Remplace le type pour button.
	 */
	onSubmit: PropTypes.func,
	/**
	 *
	 */
	submitProps: PropTypes.object,
	className: PropTypes.string,
};

/**
 * Wrapper for the the elements in `before`.
 */
const DefaultBeforeWrapper = ({children}) => <div className="form-buttons-bar__before">{children}</div>;

ButtonsBar.defaultProps = {
	submitProps: {},
	Before: DefaultBeforeWrapper,
};

export default ButtonsBar;
