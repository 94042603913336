import Fetch                 from "qidigo-fetch";
import {sendError}           from "../router";

export const REQUEST_MEMBERSHIP        = "SEARCH:REQUEST_MEMBERSHIP";
export const RECEIVE_MEMBERSHIP        = "SEARCH:RECEIVE_MEMBERSHIP";
export const RECEIVE_MEMBERSHIP_FAILED = "SEARCH:RECEIVE_MEMBERSHIP_FAILED";
export const INVALIDATE_MEMBERSHIP     = "SEARCH:INVALIDATE_MEMBERSHIP";

/**
 * Notifie qu'on effectue une requête.
 */
const requestMembership = (membershipId) => ({
	type: REQUEST_MEMBERSHIP,
	membershipId,
});

/**
 * Notifie de la réception des données.
 */
const receiveMembership = (membershipId, membership, offers, familyMembers) => ({
	type: RECEIVE_MEMBERSHIP,
	membershipId,
	membership,
	offers,
	familyMembers,
	at: Date.now(),
});

/**
 * Force un refresh pour un membership.
 */
export const invalidateMembership = () => ({
	type: INVALIDATE_MEMBERSHIP,
});

/**
 * Charge les informations de vente d'un membership_template.
 *
 * ### Paramètres
 *
 *   * membershipId : ID du membership_template à charger
 *
 */
export const fetchMembership = (membershipId, force = false) =>
	(dispatch, getState) => {
		// Convertis l'ID en string, pour stabilité entre les types.
		const id = membershipId.toString();
		const {search: {membership: {membership}}} = getState();

		// Court-circuite si c'est déjà en cours ou récupéré.
		if (!force && membership && membership["id"].toString() === id) { return null; }

		// Indique qu'on commence à récupérer les infos.
		dispatch(requestMembership(id));

		// Dispatch
		return dispatch((dispatch, getState) => {
			return Promise.all([
				Fetch.get(`membership_templates/${id}/offers`),
			])
				.then(([{
					membership_template: membership,
					offers,
					_bundled,
				}]) => {
					const {search} = getState();

					const familyMembers = _bundled ? _bundled["family_members"] : {};

					// N'est plus l'objet recherché?
					if (id !== search.membership.membershipId) { return null; }

					// Aucun résultat correspond à un "soft-404"
					if (!membership) { return Promise.reject({status: 404}); }

					// Ressource chargée pour un mauvais organisme?
					if (membership.organization_id !== search.organization.organization.id) {
						return Promise.reject({status: 404});
					}

					// FIXME : dispatch family members to another store.
					// Réceptionne.
					dispatch(receiveMembership(id, membership, offers, familyMembers));

					return membership;
				})
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: RECEIVE_MEMBERSHIP_FAILED, error});
				});
		});
	};

/**
 * Ughhh, this is used to "notify" a membership page to refresh its offers when
 * a cart action has happened, since offer prices could be affected.
 */
export const maybeRefreshMembership = () =>
	(dispatch, getState) => {
		/* This so not "the redux way", but I find that redux doesn't play
		 * well within a "routed" app.
		 * This acts as a global state in the app, while in reality this is a
		 * local state for the membership offers listing page.
		 */
		const {search: {membership: {membershipId}}} = getState();

		if (membershipId) {
			return dispatch(fetchMembership(membershipId, true));
		}

		return null;
	};
