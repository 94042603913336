import React                 from "react";
import PropTypes             from "prop-types";
import {connect}             from "@app/lib/redux";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import GeminiScrollbar from "react-gemini-scrollbar";
import Loading               from "qidigo-components/loading";
import EmptyList             from "@app/components/empty_list";
import ButtonsBar            from "qidigo-form/buttons_bar";
import {Button}              from "qidigo-form";
import CartItem              from "./_item";
import Money                 from "@app/components/money.js";
import {
	closeCart,
	removeItem,
	removeReservation,
	fetchCart
} from "@app/store/actions/cart";
import { trackEvent } from "../../tracker";
import AlertBox from "../../components/alertBox";
import qidigoFetch from "../../modules/qidigo-fetch";
import {navigate}            from "qidigo-router";
import Auth                  from "qidigo-auth";
import QidigoSessionStore from "../../../../modules/qidigo-sessionstore";



const messages = defineMessages({
	title: {id: "qidigo.cart.title", defaultMessage: "Panier"},
	subtitle: {id: "qidigo.cart.subtitle", defaultMessage: "Ma sélection"},
	nothing:  {id: "qidigo.cart.nothing", defaultMessage: "Le panier est vide"},
	next_step: {id: "qidigo.cart.next_step", defaultMessage: "Étape suivante"},
	continue_shopping: {id: "qidigo.cart.continue_shopping", defaultMessage: "Continuer mes achats"},
});

// This is a fix for Safari browser to re-enable the "next-step" button
// See this for more info : https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
(function () {
	window.onpageshow = function(event) {
		if (event.persisted) {
			window.location.reload();
		}
	};
})();

class CartView extends React.Component {

	constructor(props) {
		super(props);

		this.handleCartClose = this.handleCartClose.bind(this);
		this.handleCheckout = this.handleCheckout.bind(this);
		this.handleDeleteReservation = this.handleDeleteReservation.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

		// Handling button enabled state
		this.state = { disabled: false };
	}

	async handleCheckout() {
		this.setState({ disabled: true });
		const slug = this.props.items[0].organization.slug
		const contact = await qidigoFetch
			.get(`order/customers?organization=${slug}`)
			.then()
			.catch(() => {
				return
			});
		const forms =  await qidigoFetch.get(`order/customers/${contact.id}/pending-order/forms`)
		const nextLocation = forms.length === 0 ? 'checkout' : 'forms'
		window.location = '/u/'+ slug +'/pending-order/' + nextLocation
	}

	handleCartClose() {
		this.props.dispatch(closeCart());
	}

	handleDelete(id) {
		this.props.dispatch(removeItem(id));
	}

	handleDeleteReservation(id) {
		this.props.dispatch(removeReservation(id));
	}

	render() {
		const {items, total, loading, alertMessages} = this.props;
		const {formatMessage} = this.context.intl;

		return (
			<div id="cart--holder">{/* Used to do a dirty trick in the page. */}
				<div className="cart panel">
					<div>
						{loading && <Loading className="style-overlay" />}
						<div className="cart-header">
							<Button onClick={this.handleCartClose}>
								<FormattedMessage {...messages["continue_shopping"]} />
							</Button>
							<h2><FormattedMessage {...messages.title} /></h2>
						</div>

						<GeminiScrollbar className="panel--cart-items">
							<AlertBox messages={ alertMessages } />
							<EmptyList className="cart-items" text={formatMessage(messages["nothing"])}>
								{
									items.length > 0 &&
									<li>
										{items.map((item) =>
											<CartItem
												key={item.id}
												item={item}
												onDelete={this.handleDelete}
												handleDeleteReservation={this.handleDeleteReservation}
											/>
										)}
									</li>
								}
							</EmptyList>
						</GeminiScrollbar>
						<ButtonsBar
							loading={loading}
							submitLabel={formatMessage(messages.next_step)}
							onSubmit={this.handleCheckout}
							disabled={this.state.disabled}
							withoutSubmit={items.length === 0}
                            				className={"cart-button-bar"}
						>
							<Button onClick={this.handleCartClose}>
								<FormattedMessage {...messages["continue_shopping"]} />
							</Button>
						</ButtonsBar>
					</div>
				</div>
			</div>
		);
	}
}

CartView.propTypes = {
	dispatch: PropTypes.func,
	items: PropTypes.arrayOf(CartItem.propTypes.item),
	loading: PropTypes.bool,
};

CartView.defaultProps = {
	items: [],
};

CartView.contextTypes = {
	intl: PropTypes.object,
};


const withProperties = ({cart:{open:cartOpen, items, loading}}) => ({cartOpen, items, loading});
export default connect(withProperties)(CartView);
