import * as React from "react";
import {Component} from "react";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import Input from "qidigo-form/input";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import PropTypes from "prop-types";

const translations = defineMessages({
    "apply": {
        id: "qidigo.billing.coupon_adjustment.apply",
        defaultMessage: "Appliquer"
    },
    "promotion_code": {
        id: "qidigo.billing.coupon_adjustment.promotion_code",
        defaultMessage: "Code promotionnel"
    },
    "title": {
        id: "qidigo.billing.coupon_adjustment.title",
        defaultMessage: "Vous avez un code promotionnel?"
    },
    "coupon_error": {
        id: "qidigo.billing.coupon_adjustment.coupon_error",
        defaultMessage: "Ce code promotionnel n'est pas valide."
    },
    "coupon_limit_error": {
        id: "qidigo.billing.coupon_adjustment.coupon_limit_error",
        defaultMessage: "Ce code promotionnel ne peut plus être utilisé."
    },
});

interface ICouponAdjustmentProps {
    reload: (coupon: string|null, showCouponApplication: boolean) => void,
    appliedCoupon: string|null,
    hasCouponErrorOnApplication: boolean,
    hasCouponLimitErrorOnApplication: boolean
}

interface ICouponAdjustmentState {
    isFormOpen: boolean
    coupon: string|null,
    applyingCoupon: boolean,
    errorCouponChanged: boolean
}

class CouponAdjustment extends Component<ICouponAdjustmentProps, ICouponAdjustmentState> {
    constructor(props) {
        super(props);
        this.state = {
            isFormOpen: props.hasCouponLimitErrorOnApplication,
            coupon: props.appliedCoupon,
            applyingCoupon: false,
            errorCouponChanged: true
        };
    }

    openForm() {
        this.setState({
            isFormOpen: true
        });
    }

    handleChange = (event) => {
        this.setState({
            coupon: event.currentTarget.value,
            errorCouponChanged: this.state.errorCouponChanged
                || this.props.hasCouponErrorOnApplication
                || this.props.hasCouponLimitErrorOnApplication
        });
    }

    componentDidUpdate(prevProps: Readonly<ICouponAdjustmentProps>): void {
        if (
            prevProps.hasCouponErrorOnApplication !== this.props.hasCouponErrorOnApplication
            || prevProps.hasCouponLimitErrorOnApplication !== this.props.hasCouponLimitErrorOnApplication
        ) {
            if (this.props.hasCouponErrorOnApplication || this.props.hasCouponLimitErrorOnApplication) {
                this.setState({
                    isFormOpen: true,
                });

                return;
            }

            this.setState({
                coupon: null,
                isFormOpen: false,
            });
        }
    }

    async handleCouponApply() {
        if (this.state.applyingCoupon) {
            return
        }

        this.setState({
            applyingCoupon: true
        })

        await this.props.reload(this.state.coupon, true);

        const hasError = this.props.hasCouponErrorOnApplication || this.props.hasCouponLimitErrorOnApplication;
        const coupon = hasError ? this.state.coupon : null

        this.setState({
            applyingCoupon: false,
            errorCouponChanged: !hasError,
            isFormOpen: hasError,
            coupon: coupon
        })
    }

    render() {
        const couponText = this.state.coupon ? this.state.coupon : "";
        // @ts-ignore
        const {formatMessage} = this.context.intl;

        return (
            <div className={"coupon-adjustment"}>
                {
                    !this.state.isFormOpen && !this.props.appliedCoupon &&
                    <button
                        className={"coupon-adjustment-button button-link"}
                        onClick={() => this.openForm()}
                    >
                        <FormattedMessage {...translations["title"]} />
                    </button>
                }
                {
                    this.state.isFormOpen &&
                    <div className={"coupon-adjustment--container"}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Input
                                id="promotion_code"
                                label={formatMessage(translations["promotion_code"])}
                                type="text"
                                className={"coupon-adjustment--input"}
                                value={couponText}
                                onChange={this.handleChange}
                            />
                            <Button
                                disabled={
                                    this.state.applyingCoupon
                                    || this.state.coupon === null
                                    || this.state.coupon === ''
                                    || !this.state.errorCouponChanged
                                }
                                className={"coupon-adjustment--button"}
                                onClick={() => this.handleCouponApply()}
                            >
                                <FormattedMessage {...translations["apply"]} />
                            </Button>
                        </form>
                        {
                            this.props.hasCouponErrorOnApplication &&
                            <div>
                                <span className={"span--coupon-error"}>
                                    <FormattedMessage {...translations["coupon_error"]} />
                                </span>
                            </div>
                        }
                        {
                            this.props.hasCouponLimitErrorOnApplication && (
                                <div>
                                    <span className={"span--coupon-error"}>
                                        <FormattedMessage {...translations["coupon_limit_error"]} />
                                    </span>
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        )
    }
}

// @ts-ignore
CouponAdjustment.contextTypes = {
    intl: PropTypes.object,
};

export default CouponAdjustment;
