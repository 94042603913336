import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import {Link}                from "react-router/es";
import Collapse              from "react-collapse";

import ButtonsBar            from "qidigo-form/buttons_bar";
import Separator             from "qidigo-components/separator";
import {errorMessagesType}   from "qidigo-form/proptypes";
import SocialLogin           from "@app/components/social_login";
import EmailPassword         from "@app/views/shared/email_password";

const messages = defineMessages({
	"title":     {id: "qidigo.register.title", defaultMessage: "Nouveau compte"},
	"privacy":   {id: "qidigo.register.privacy", defaultMessage: "Politique de confidentialité"},
	"terms":     {id: "qidigo.register.terms",  defaultMessage: "Conditions d'utilisation"},
	"legaleses": {
		id: "qidigo.register.legaleses",
		defaultMessage: "En vous inscrivant, vous acceptez les {terms} et la {privacy}."
	},

	"register.action": {id: "qidigo.register.partial.register.action", defaultMessage: "M'inscrire", description: "Button that opens the section."},
	"register.blurb":  {id: "qidigo.register.partial.register.blurb", defaultMessage: "Nouveau sur Qidigo? Commencez par vous créer un compte juste ici."},
});

const RegisterPartial = (props, context) => {
	const {formatMessage} = context.intl;
	const {shown, unfold} = props;

	return (
		<section className="register">
			<div className="register--register-box">
				<h1><FormattedMessage {...messages["title"]} /></h1>
				<p>
					<FormattedMessage {...messages["register.blurb"]} />
				</p>
				<Collapse className="collapse" isOpened={shown}>
					<SocialLogin action="register" />
					<Separator>Ou</Separator>
					<EmailPassword
						onSubmit={(...e)=>props.onSubmit(...e)}
						onChange={(...e)=>props.onChange(...e)}
						onValidationError={(errors)=>props.onValidationError(errors)}
						disabled={props.loading}
						values={props.values}
						errors={props.errors}
						withConfirmation={true}
					/>
					<footer>
						<Separator />
						<FormattedMessage {...messages.legaleses}
							values={{
								terms: <Link to="/terms"><FormattedMessage {...messages["terms"]} /></Link>,
								privacy: <Link to="/privacy"><FormattedMessage {...messages["privacy"]} /></Link>,
							}}
						/>
					</footer>
				</Collapse>
				<Collapse className="collapse" isOpened={!shown}>
					<ButtonsBar
						submitLabel={formatMessage(messages["register.action"])}
						onSubmit={(...e) => unfold("register")}
					/>
				</Collapse>
			</div>
		</section>
	);
};

RegisterPartial.propTypes = {
	shown: PropTypes.bool,
	unfold: PropTypes.func,
	errors:    errorMessagesType,
	values:    PropTypes.object,
	loading:   PropTypes.bool,
	onChange:  PropTypes.func,
	onSubmit:  PropTypes.func,
	onValidationError: PropTypes.func,
};

RegisterPartial.contextTypes = {
	intl: PropTypes.object,
};

export default RegisterPartial;
