import {combineReducers} from "redux";
import * as SEARCH from "../actions/search";

const memberships = (state = {}, action) => {
	switch (action.type) {
		case SEARCH.INVALIDATE_MEMBERSHIPS:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				organizationId: null,
				memberships: null,
				at: null,
			});

		case SEARCH.RECEIVE_MEMBERSHIPS_FAILED:
			return Object.assign({}, state, {
				fetching: false,
				error: action.error,
				organizationId: null,
				memberships: null,
				at: null,
			});

		case SEARCH.REQUEST_MEMBERSHIPS:
			return Object.assign({}, state, {
				fetching: true,
				error: null,
				organizationId: action.organizationId,
				memberships: null,
				at: null,
			});

		case SEARCH.RECEIVE_MEMBERSHIPS:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				organizationId: action.organizationId,
				memberships: action.memberships,
				at: action.at,
			});

	}
	return state;
};

const membership = (state = {}, action) => {
	switch (action.type) {
		case SEARCH.INVALIDATE_MEMBERSHIP:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				membershipId: null,
				membership: null,
				familyMembers: null,
				offers: null,
				at: null,
			});

		case SEARCH.RECEIVE_MEMBERSHIP_FAILED:
			return Object.assign({}, state, {
				fetching: false,
				error: action.error,
				membershipId: null,
				membership: null,
				familyMembers: null,
				offers: null,
				at: null,
			});

		case SEARCH.REQUEST_MEMBERSHIP:
			return Object.assign({}, state, {
				fetching: true,
				error: null,
				membershipId: action.membershipId,
				membership: null,
				familyMembers: null,
				offers: null,
				at: null,
			});

		case SEARCH.RECEIVE_MEMBERSHIP:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				membershipId: action.membershipId,
				membership: action.membership,
				familyMembers: action.familyMembers,
				offers: action.offers,
				at: action.at,
			});

	}
	return state;
};

/**
 */
const group = (state = {}, action) => {
	switch (action.type) {
		case SEARCH.INVALIDATE_GROUP:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				groupId: null,
				group: null,
				sessions: null,
				at: null,
			});

		case SEARCH.RECEIVE_GROUP_FAILED:
			return Object.assign({}, state, {
				fetching: false,
				error: action.error,
				groupId: null,
				group: null,
				sessions: null,
				at: null,
			});

		case SEARCH.REQUEST_GROUP:
			return Object.assign({}, state, {
				fetching: true,
				error: null,
				groupId: action.groupId,
				group: null,
				sessions: null,
				at: null,
			});

		case SEARCH.RECEIVE_GROUP:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				groupId: action.groupId,
				group: action.group,
				sessions: action.sessions,
				at: action.at,
			});

	}
	return state;
};

/**
 */
const activity = (state = {}, action) => {
	switch (action.type) {
		case SEARCH.INVALIDATE_ACTIVITY:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				activityId: null,
				activity: null,
				groups: null,
				at: null,
			});

		case SEARCH.RECEIVE_ACTIVITY_FAILED:
			return Object.assign({}, state, {
				fetching: false,
				error: action.error,
				activityId: null,
				activity: null,
				groups: null,
				at: null,
			});

		case SEARCH.REQUEST_ACTIVITY:
			return Object.assign({}, state, {
				fetching: true,
				error: null,
				activityId: action.activityId,
				activity: null,
				groups: null,
				at: null,
			});

		case SEARCH.RECEIVE_ACTIVITY:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				activityId: action.activityId,
				activity: action.activity,
				groups: action.groups,
				at: action.at,
			});

	}
	return state;
};

/**
 */
const organization = (state = {}, action) => {
	switch (action.type) {
		case SEARCH.INVALIDATE_ORGANIZATION:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				organizationId: null,
				organization: null,
				at: null,
			});

		case SEARCH.RECEIVE_ORGANIZATION_FAILED:
			return Object.assign({}, state, {
				fetching: false,
				error: action.error,
				organizationId: null,
				organization: null,
				at: null,
			});

		case SEARCH.REQUEST_ORGANIZATION:
			return Object.assign({}, state, {
				fetching: true,
				error: null,
				organizationId: action.organizationId,
				organization: null,
				at: null,
			});

		case SEARCH.RECEIVE_ORGANIZATION:
			return Object.assign({}, state, {
				fetching: false,
				error: null,
				organizationId: action.organizationId,
				organization: action.organization,
				at: action.at,
			});

	}

	return state;
};

export default combineReducers({organization, activity, group, membership, memberships});
