import React, {Component} from "react";
import PropTypes from "prop-types";
import isNaN from "lodash/isNaN";
import Input from "qidigo-form/input";

const EXCEPT_NUMBERS_AND_DECIMAL = /[^0-9,.]/g;

const LOCALE_DECIMAL = {
	fr: ",",
	en: ".",
};
LOCALE_DECIMAL["_default"] = LOCALE_DECIMAL["fr"];

/**
 * Component acting *mostly* like an input type number.
 *
 * It's missing the feature of proposing the number keyboard
 * for mobile devices.
 *
 * The `input[type=number]` component is a pure and complete shit-show.
 * It won't pass invalid inputs, negating the possibility to handle different
 * decimal separators than expected.
 * It will not properly handle the whole lifecycle of change events.
 * It is impossible to format the shown number.
 *
 * This component:
 *
 *   * Skips invalid inputs.
 *   * Handles most invalid inputs gracefully.
 *   * Formats the value as expected for monetary inputs on blur.
 *
 * This component does not:
 *
 *   * Show virtual numeric keyboard. (Probably won't happen, see `https://www.caniuse.com/#search=inputmode`)
 *   * Handle negative amounts (yet).
 */
class MoneyInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Value used in the input, possibly invalid, as the user wrote.
			inputValue: parseFloat(props.value).toFixed(2),
		};

		// This can't use the state.
		// Otherwise events may fire from elsewhere and the state did not transition.
		this.focus = false;
	}

	/**
	 * Rounds value given; This MUTATES the given props
	 * by telling, through onChange, the rounded value.
	 *
	 * This is done so a 199.99999 value is rounded to the
	 * expected 200.
	 */
	roundValue(props) {
		if (!this.focus) {
			// Handles rounding.
			const value = parseFloat(props.value).toFixed(2);
			if (parseFloat(value) === parseFloat(props.value)) {
				this.setState({inputValue: value});
			}
			else {
				this.handleChange(null, value);
			}
		}
	}

	componentWillMount() {
		this.roundValue(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.value !== nextProps.value) {
			if (isNaN(nextProps.value)) {
				this.handleChange(null, "0");
			}
			else {
				this.roundValue(nextProps);
			}
		}
	}

	handleChange(e, value) {
		// Replaces implicitly most invalid inputs.
		value = value
			.replace(EXCEPT_NUMBERS_AND_DECIMAL, "")
			.replace(/[,.]+/g, ".")
		;

		// When multiple decimal separators are present
		if (value.split(".").length > 2) {
			let values = value.split(".");
			const decimal = values.pop();
			value = values.join("") + "." + decimal;
		}

		// Checks for decimal-only
		if (value === ".") {
			value = "0.";
		}

		// Sets the value as inputted...
		this.setState({inputValue: value});

		// Notifies owner.
		if (this.props.onChange) {
			this.props.onChange(e, parseFloat(value).toFixed(2));
		}
	}

	handleFocus(e) {
		this.focus = true;
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	}

	handleBlur(e) {
		// Resets to the value available in props.
		// It is implied to be a valid value.
		let value = this.props.value;
		const {inputValue} = this.state;
		if (inputValue.trim() === "") {
			value = "0";
		}
		this.setState({inputValue: parseFloat(value).toFixed(2)});
		this.focus = false;
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	render() {
		const {className, ...props} = this.props;
		const {inputValue} = this.state;
		const {intl: {locale}} = this.context;

		return <Input
			{...props}
			className={["input-type--money", className].join(" ")}
			onChange={(...e) => this.handleChange(...e)}
			value={inputValue.toString().replace(".", LOCALE_DECIMAL[locale] || LOCALE_DECIMAL["_default"])}
			onBlur={(e) => this.handleBlur(e)}
			onFocus={(e) => this.handleFocus(e)}
		/>;
	}
}

MoneyInput.propTypes = {
	className: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
};

MoneyInput.contextTypes = {
	intl: PropTypes.object,
};

MoneyInput.defaultProps = {
	className: "",
	value: 0,
};

export default MoneyInput;
