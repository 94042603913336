import * as React from "react";
import {Component} from "react";
import {IBillingAddress} from "../../../types";
import PaysafeCardInput from './paysafeCardInput'

const OPTIONS = {
    environment: process.env.NODE_ENV === "development" ? "TEST" : "LIVE",
    fields: {
        cardNumber: {
            selector: "#card-number",
            placeholder: "1234 1234 1234 1234"
        },
        expiryDate: {
            selector: "#expiration-date",
            placeholder: "MM / YY"
        },
        cvv: {
            selector: "#cvv",
            placeholder: "123"
        }
    },
    style: {
        input: {
            "font-family": "robotoregular,Helvetica,Arial,sans-serif",
            "font-weight": "normal",
            "font-size": "14px"
        },
        "#card-number.valid": {
            "color": "green"
        }
    }
};

let instance = null;

interface PaySafeCreditCardFormProps {
    apiKey: string,
    userName: string,
    billingAddress: IBillingAddress|null,
    isInstallmentMissing: boolean,
    handlePayment: (token: string) => void,
    handleTokenBadRequest: (code: string) => void,
    hasAddressError: boolean,
    processingPayment: boolean,
    disabled: boolean
}

interface PaySafeCreditCardFormState {
    error: boolean,
    unexpectedAddressError: boolean,
    unexpectedInstallmentError: boolean,
    cardNumberValid: boolean,
    expirationDateValid: boolean
    cvvValid: boolean,
    paysafeLoaded: boolean,
    hasInstantiatedField: boolean,
    isRefreshingComponent: boolean,
    amountOfTryOnRefreshing: number
}

class PaySafeCreditCardForm extends Component<PaySafeCreditCardFormProps, PaySafeCreditCardFormState> {
    TOKEN_ERROR_CODE = 'PAYSAFE_BAD_REQUEST';
    constructor(props: PaySafeCreditCardFormProps) {
        super(props);
        this.state = {
            error: false,
            unexpectedAddressError: false,
            unexpectedInstallmentError: false,
            cardNumberValid: false,
            expirationDateValid: false,
            cvvValid:false,
             // @ts-ignore
            paysafeLoaded: typeof paysafe !== 'undefined',
            hasInstantiatedField: false,
            isRefreshingComponent: false,
            amountOfTryOnRefreshing: 0
        };
    }
    componentDidMount() {
        
        if(this.state.paysafeLoaded) {
            this.instanciatePaysafeField();
        }
    }

    componentDidUpdate(prevProps: Readonly<PaySafeCreditCardFormProps>, 
                       prevState: Readonly<PaySafeCreditCardFormState>): void {
        if(prevState.paysafeLoaded !== this.state.paysafeLoaded) {
            this.instanciatePaysafeField();
        }
    }

    paysafeHasLoaded = () => {
        this.setState({
            paysafeLoaded: true
        })
    }

    instanciatePaysafeField = () => {
        // @ts-ignore
        paysafe.fields.setup(this.props.apiKey, OPTIONS, (paysafeInstance, error) => {
            if (error) {
                this.handleError(); //FIXME: more detail?
            } else {
                instance = paysafeInstance;

                paysafeInstance.fields.cardNumber.valid(() => {
                    this.setState({cardNumberValid: true});
                })

                paysafeInstance.fields.cardNumber.invalid(() => {
                    this.setState({cardNumberValid: false});
                })

                paysafeInstance.fields.expiryDate.valid(() => {
                    this.setState({expirationDateValid: true});
                })

                paysafeInstance.fields.expiryDate.invalid(() => {
                    this.setState({expirationDateValid: false});
                })

                paysafeInstance.fields.cvv.valid(() => {
                    this.setState({cvvValid: true});
                })

                paysafeInstance.fields.cvv.invalid(() => {
                    this.setState({cvvValid: false});
                })

                this.setState({
                    hasInstantiatedField: true,
                    error: false,
                    isRefreshingComponent: false
                });

                paysafeInstance.fields("cardNumber expiryDate cvv").on("Focus Blur", function(instance, event) {
                    this.style.border = event.type === "Focus" ? "1px solid #27708e" : "1px solid #d2d3d5",
                        this.style.boxShadow = event.type === "Focus" ? "0 0 0.57142857rem #27708e" : "none";
                });

                this.paysafeHasLoaded();
            }
        });
    }

    handleError = () => {
        this.setState({
            error: true,
        })
    }

    pay = () => {
        if (!instance) {
            this.handleError(); //FIXME: more detail?

            return;
        }

        if (this.props.isInstallmentMissing) {
            this.setState({
                unexpectedInstallmentError: true
            });

            return;
        }

        if (this.props.billingAddress === null) {
            this.setState({
                unexpectedAddressError: true
            });

            return;
        }

        const vault = {
            holderName: this.props.userName,
            billingAddress: {
                country: this.props.billingAddress.country,
                zip: this.props.billingAddress.postal_code,
                state: this.props.billingAddress.state,
                city: this.props.billingAddress.city,
                street: this.props.billingAddress.civic_number_and_street,
                street2: this.props.billingAddress.civic_number_ext
            }
        };

        // @ts-ignore
        instance.tokenize({vault: vault}, (paysafeInstance, error, result) => {
            if (error) {
                this.props.handleTokenBadRequest(this.TOKEN_ERROR_CODE)
            } else {
                this.setState({
                    error: false,
                });
                this.props.handlePayment(result.token);
            }
        });
    }

    refreshComponent = () => {
        const newAmountOfTry: number = this.state.amountOfTryOnRefreshing;
        this.setState({
            paysafeLoaded: false,
            hasInstantiatedField: false,
            isRefreshingComponent: true,
            amountOfTryOnRefreshing: newAmountOfTry + 1
        });
    }

    render() {
        return (
            <PaysafeCardInput 
                unexpectedAddressError={this.state.unexpectedAddressError}
                paymentPlatformError={this.state.error}
                unexpectedInstallmentError={this.state.unexpectedInstallmentError}
                payAction={this.pay.bind(this)}
                paysafeHasLoaded={this.paysafeHasLoaded.bind(this)}
                paysafeAlreadyLoaded={this.state.paysafeLoaded}
                hasInstantiatedField={this.state.hasInstantiatedField && !this.props.processingPayment}
                refreshComponent={this.refreshComponent.bind(this)}
                isRefreshingComponent={this.state.isRefreshingComponent}
                amountOfTryOnRefreshing={this.state.amountOfTryOnRefreshing}
                processingPayment={this.props.processingPayment}
                disabled={
                    this.props.processingPayment
                    || this.props.disabled
                    || this.props.hasAddressError
                    || this.state.error
                    || this.state.isRefreshingComponent
                    || this.state.unexpectedInstallmentError
                    || !this.state.cardNumberValid
                    || !this.state.expirationDateValid
                    || !this.state.cvvValid
                }
            />
        );
    }
}

export default PaySafeCreditCardForm;
