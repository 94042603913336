import FamilyMemberPartial from "@app/views/shared/form/family_member";
import ButtonsBar from "qidigo-form/buttons_bar.js";
import Button from "qidigo-components/button.js";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage } from "react-intl";
import Fetch from "qidigo-fetch";
import Logger from "qidigo-logger";
import pick from "lodash/pick";
import each from "lodash/each";
import noop from "lodash/noop";

import React, { useState, useCallback, useEffect } from 'react'

const messages = defineMessages({
  "add": { id: "qidigo.registration_family.add", defaultMessage: "Ajouter" },
  "cancel": {id: "qidigo.dashboard.family_members.cancel", defaultMessage: "Annuler"},
});

const AddMemberModal = ({ onNewMemberAdded = noop, onClose = noop }, context) => {
  const { formatMessage } = context.intl
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState(false)
  const [saving, setSaving] = useState(false)
  const [familyMember, setFamilyMember] = useState({})
  const [familyMembers, setFamilyMembers] = useState([])

  const fetchUserFamilyMembers = useCallback(() => {
    return Fetch.get(`users/${context.loggedUser.id}/family_members`)
      .then((response) => {
        const familyMembers = response;
        setFamilyMembers(familyMembers)
        setLoading(false)
        return response;
      })
      .catch(Logger.catcher)
      .catch((err) => {
        setErrors(err)
        setLoading(false)
      });
  }, [familyMembers, setFamilyMembers, setLoading, setErrors])

  useEffect(() => {
    fetchUserFamilyMembers()
  }, [])

  const saveUserFamilyMember = useCallback(() => {
    const data = pick(familyMember, [
      "first_name", "last_name", "sex",
      "birthday",
    ]);

    const familyMemberFormData = new FormData();
    each(data, (v, k) => {
      familyMemberFormData.append(`family_member[${k}]`, v);
    });
    setSaving(true)
    // (
    // current === true ?
    Fetch.post(`users/${context.loggedUser.id}/family_members`, familyMemberFormData)// :
      // Fetch.patch(`family_members/${current}`, familyMember)
      // )
      .then((response) => {
        setSaving(false)
        // FIXME : Add flash message and make recently saved family_member more obvious.
        setErrors(false)
        setFamilyMember({})
        fetchUserFamilyMembers()
          .then(() => onNewMemberAdded(response));

        return null;
      })
      .catch((errors) => {
        setSaving(false)
        setErrors(errors)
        return null;
      })
      ;
  }, [familyMember, setErrors, fetchUserFamilyMembers, setSaving, setErrors, setFamilyMember, onNewMemberAdded])

  const onValueChange = useCallback((field, e, value) => {
    setFamilyMember(familyMember => ({
      ...familyMember,
      [field]: value
    }))
  }, [setFamilyMember])


  const onSubmit = useCallback(() => {
    setErrors(false)
    setDisabled(true)
    saveUserFamilyMember()
  }, [setErrors, setDisabled, saveUserFamilyMember])

  return (
    <FamilyMemberPartial
      className="dashboard-user-family-member"
      disabled={saving}
      onChange={onValueChange}
      onValidationError={setErrors}
      onSubmit={onSubmit}
      familyMember={familyMember}
      errors={errors}
    >
      <ButtonsBar
        loading={saving}
        submitLabel={formatMessage(messages.add)}
      >
        <Button onClick={onClose}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </ButtonsBar>
    </FamilyMemberPartial>
  )
}

AddMemberModal.propTypes = {
  onClose: PropTypes.func,
  onNewMemberAdded: PropTypes.func,
};

AddMemberModal.contextTypes = {
  intl: PropTypes.object,
  loggedUser: PropTypes.object,
};

export default AddMemberModal;
