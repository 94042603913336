import React, {Component} from "react";
import PropTypes from "prop-types";
import ContactConfirmationPage from "@app/views/contact/contact_confirmation";
import qidigoFetch from "../../modules/qidigo-fetch";
import Loading from 'qidigo-components/loading';

class ContactConfirmationController extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            contactAdded: false,
            message: null,
            organizationName: null,
            organizationId: null
        };
    }

    componentDidMount() {
        const url = 'contacts/confirm-contact-request/' + this.props.params.token;
        const body = {
            'token': this.props.params.token
        }

        const init = {
            'method': 'POST',
            'body': JSON.stringify(body)
        };

        qidigoFetch.fetch(url, init)
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        contactAdded: true,
                        loading: false,
                        message: 'SUCCESS',
                        organizationName: response.organization_name,
                        organizationId: response.organization_id
                    })

                    return;
                }

                if (response.error_code) {
                    this.setState({
                        contactAdded: false,
                        loading: false,
                        message: response.error_code,
                        organizationName: response.organization_name,
                    })

                    return;
                }

                this.setState({
                    contactAdded: false,
                    loading: false,
                    message: 'INTERNAL_SERVER_ERROR'
                })

            })
            .catch((error) => {
                this.setState({
                    contactAdded: false,
                    loading: false,
                    message: 'ERROR'
                })
            });

    }

    render() {
        return (
            this.state.loading
                ? <Loading className={"contact-confirmation-loading"}/>
                : <ContactConfirmationPage
                    token={this.props.params.token}
                    contactAdded={this.state.contactAdded}
                    message={this.state.message}
                    loading={this.state.loading}
                    organizationName={this.state.organizationName}
                    organizationSlug={this.props.params.organization_slug}
                />
        );
    }
}

ContactConfirmationController.propTypes = {
    params: PropTypes.shape({
        organization_slug: PropTypes.string,
        token: PropTypes.string,
    }).isRequired,
    location: PropTypes.object,
};

export default ContactConfirmationController;
