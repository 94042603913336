import React from "react";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

const translations = defineMessages({
	"warning.title": {id: "qidigo.dashboard.invoice.installment.warning.title", defaultMessage: "Configuration par versements"},
	"warning.text": {id: "qidigo.dashboard.invoice.installment.warning.text", defaultMessage: "La facture est actuellement configurée pour être payée par versements. Vous pouvez en tout temps l'acquitter entièrement à l'aide du bouton « Payer »."},
});

const InstallmentWarning = () =>
	<div className="checkout-installment-warning">
		<div className="checkout-installment-warning--blurb">
			<h3>
				<FormattedMessage
					{...translations["warning.title"]}
				/>
			</h3>
			<p>
				<FormattedMessage
					{...translations["warning.text"]}
				/>
			</p>
		</div>
	</div>
	;

export default InstallmentWarning;
