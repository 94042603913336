import {browserHistory} from "react-router/es";
import Fetch from "qidigo-fetch";
import Auth from "qidigo-auth";
import {maybeRefreshMembership} from "./search/membership";
import { trackEvent } from "../../tracker";
import * as Sentry from "@sentry/react";

export const CLOSE_CART = "CLOSE_CART";
export const REFRESH_CART = "REFRESH_CART";
export const RECEIVE_CART = "RECEIVE_CART";

export const openCart = () => (dispatch) => {
	browserHistory.push(window.location.pathname + window.location.search + "#cart");
	dispatch(fetchCart());
};

export const closeCart = () => (dispatch, getState) => {
	const {cart: {open}} = getState();
	if (!open) {
		return;
	}

	if (window.history.length > 1) {
		window.history.back();
	} else {
		browserHistory.push(window.location.pathname + window.location.search);
	}

	return {type: CLOSE_CART};
};

export const toggleCart = () => (dispatch, getState) => {
	const {cart: {open}} = getState();
	if (open) {
		dispatch(closeCart());
	} else {
		dispatch(openCart());
	}
};

export const removeItem = (id) => (dispatch) => {
	trackEvent('remove_from_cart', {
		label: id
	})

	return Fetch.delete(`cart/${id}`)
		.catch((response) => {
			// On essaie de retirer un item déjà retiré?
			// On ignore l'erreur!
			if (response && response.status === 404) {
				return false;
			}

			Sentry.addBreadcrumb({
				category: 'cart_delete',
				level: Sentry.Severity.Error,
				message: JSON.stringify(response)
			})

			return Promise.reject(response);
		})
		.then((response) => dispatch(fetchCart()));
}


export const removeReservation = (id) => (dispatch) => {
	trackEvent('remove_from_cart', {
		label: id
	})
	return Fetch.delete(`cart/reservations/${id}`)
		.then((response) => dispatch(fetchCart()));
}

export const fetchCart = () => (dispatch) => {
	dispatch(refreshCart());
	dispatch(maybeRefreshMembership());
	Fetch.get("cart")
		.then((response) => {
			return dispatch(receiveCart(response));
		})
		.catch(Fetch.handleErrors(this))
};

export const refreshCart = () => ({
	type: REFRESH_CART,
});

export const receiveCart = (response) => ({
	type: RECEIVE_CART,
	response,
});
