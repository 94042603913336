import * as GROUPS from "../actions/groups";

/**
 * Reducer pour les actions relative au panier.
 */
const groups = (state = { shouldShowCompleteGroups: false }, action) => {
	switch (action.type) {
		case GROUPS.SHOW_COMPLETE_GROUPS:
			return Object.assign({}, state, {
				shouldShowCompleteGroups: action.payload.shouldShowCompleteGroups,
			});
	}

	return state;
};

export default groups;
