import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import SocialLinks           from "@app/components/sociallinks.js";
import MapColumns            from "@app/views/shared/_map_and_columns.js";

const translations = defineMessages({
	social: {id: "qidigo.organization.social", defaultMessage: "Réseaux sociaux"},
});

const OrganizationInformationsFragment = ({organization, TitleTag, ...props}, context) => {
	const {address, phone_number} = organization;
	const urls = organization["urls"] || [];

	return <MapColumns
		phone={phone_number}
		address={address}
		label={organization["name"]}
		extra={(
			<div className="organization-informations--social">
				<TitleTag>
					<FormattedMessage {...translations["social"]} />
				</TitleTag>
				<div>
					<SocialLinks links={urls} />
				</div>
			</div>
		)}
		TitleTag={TitleTag}
		{...props}
	/>;
};

OrganizationInformationsFragment.contextTypes = {
	intl: PropTypes.object,
};

OrganizationInformationsFragment.propTypes = {
	organization: PropTypes.object,
	TitleTag: PropTypes.node,
};

OrganizationInformationsFragment.defaultProps = {
	TitleTag: "h2",
}

export default OrganizationInformationsFragment;
