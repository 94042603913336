const loadStripe = (callback: () => void) => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.id = 'src--stripe';
    document.head.appendChild(script);

    script.onload = () => {
        if (callback) callback();
    };
};

export default loadStripe;
