import React, {Component} from "react";
import PropTypes from "prop-types";
import TextTypeForm from "./text-type-form";
import DateTypeForm from "./date-type-form";
import YesNoTypeForm from "./yes-no-type-form";
import LongTextTypeForm from "./long-text-type-form";
import SelectTypeForm from "./select-type-form";
import NumberTypeForm from "./number-type-form";
import PhoneTypeForm from "./phone-type-form";
import EmailTypeForm from "./email-type-form";
import YesNoJustifyTypeForm from "./yes-no-justify-type-form";
import HealthInsuranceTypeForm from "./health-insurance-type-form";
import ContactTypeForm from "./contact-type-form";
import CheckboxTypeForm from "./checkbox-type-form";
import FileTypeForm from "./file-type-form";
import HTML from "@app/components/html.js";
import {defineMessages} from "react-intl";

/**
 * Represent a question from a qidigo form. It's also unsed to switch
 * on the type of the question to render the right template
 */
const translations = defineMessages({
	optional: {id: "qidigo.dashboard.form.question.optional", defaultMessage: "Facultative"},
});

const types = {
	1: TextTypeForm,
	2: DateTypeForm,
	3: YesNoTypeForm,
	4: LongTextTypeForm,
	5: SelectTypeForm,
	6: NumberTypeForm,
	7: PhoneTypeForm,
	8: EmailTypeForm,
	10: YesNoJustifyTypeForm,
	11: HealthInsuranceTypeForm,
	12: ContactTypeForm,
	14: CheckboxTypeForm,
	18: FileTypeForm
};

class Question extends Component {

	render() {
		const {question,
			answer,
			error,
			formId,
			...props
		} = this.props;
		const {formatMessage} = this.context.intl;

		const Template = types[question.type_id];
		const questionName = <h4 className="question-title"><HTML contents={question.question} /> {question.optional && <span>({formatMessage(translations["optional"])})</span>}</h4>;
		const {global = [], messages= [], fields = {}} = error;

		let errorMessage = null;
		if (global.length > 0) {
			errorMessage =  <div className="error-message">{global}</div>;
		} else if (messages.length > 0) {
			errorMessage = <div className="error-message">{messages.join(", ")}</div>;
		}

		return (
			<Template
				className="question"
				questionName={questionName}
				question={question}
				errorMessage={errorMessage}
				errors={fields}
				answer={answer}
				formId={formId}
				{...props}
			/>
		);
	}

	/**
	 * Given a question an an answer, prepares the value according to the question's type.
	 */
	static prepareValue(question, answer) {
		// Ugh, this sucks to tack on a data manipulation strategy into a view thing,
		// but the structure of this section makes it non-obvious to do otherwise.

		const Template = types[question.type_id];
		
		if (!Template.prepareValue) {
			return answer;
		}

		return Template.prepareValue(question, answer);
	}
}

Question.defaultProps = {
	className: "",
	answer : {},
	error : {},
};

Question.contextTypes = {
	intl: PropTypes.object,
};

Question.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	answer: PropTypes.object,
	error: PropTypes.object,
	onChange: PropTypes.func,
	formId: PropTypes.string
};

export default Question;
