// Enlevons la détection de mutation UNIQUEMENT pour permettre
// la mise à jour en batch avec les signaux.
// C'est à vérifier aussi si une autre méthode ne serait pas plus sécuritaire.
/* eslint react/no-direct-mutation-state: [0] */

import React, {Component} from "react";
import PropTypes             from "prop-types";
import reactTimeout       from "react-timeout";

import {loadingSignals} from "@app/signals/loading.js";

// Maximum de pourcentage
const MAX_PERCENT = 100;

// Pour montrer le "load initial". Il y a un minimum de 10%.
const INITIAL_PERCENT = 20;

// Délais avant de disparaître.
const DELAY = 500;

// Compteur global.
let COUNTER = 0;

/**
 * LoadingBar du site.
 *
 * La fonctionnalité présente est un jeu de compteurs numériques impliquand un "push" de load
 * avec `addLoading` et un "pop" via `reportFinished`.
 *
 * Notes:
 *
 *   * Un `add` qui n'est pas rapporté comme fini donnera un loading "infini".
 *   * Un `report` de plus que de nombre de load ne brise pas.
 *   * Les *tokens* permettent d'enregistrer plusieurs `report` sans risquer
 *     de faire un `report` de trop qui retire un autre loading.
 *
 * @extends {Component}
 */
class LoadingBar extends Component {
	constructor() {
		super();
		this.state = {
			currentLoading:   [],
			reportedFinished: [],
			currentMin:       0,
			done:             true,
		};
	}

	componentDidMount() {
		this._mounted = true;
		//
		// Ajoute les listeners.
		//
		this.bindings = {
			addLoading:     loadingSignals.addLoading.add((baseToken, fn) => this.addLoading(baseToken, fn)),
			reportFinished: loadingSignals.reportFinished.add((token) => this.reportFinished(token)),
		};
	}
	componentWillUnmount() {
		this._mounted = false;
	}

	_reset() {
		this.setState({
			reportedFinished: [],
			currentLoading:   [],
			currentMin:       0,
			done:             true,
		});
	}

	/**
	 * Ajoute un chargement au "compteur interne".
	 *
	 * Le nom de base du token permet d'avoir une petite idée à qui est
	 * la faute d'un chargement "infini" (un chargement qui n'est pas dé-stacké).
	 *
	 * @param baseToken {String} Nom de base pour le token, helper de debug.
	 * @param fn {function(token)} Callback qui reçoit le token final.
	 */
	addLoading(baseToken = "", fn) {
		if (!this._mounted) { return false; }

		COUNTER   = COUNTER + 1;
		let token = `${baseToken}${COUNTER}`;
		// Re-normalise les loadings pour éviter de décrémenter la barre.
		// Autrement, un loading ajouté après que certains soient complets auraient
		// donné un *jank* infernal à la barre.
		if (this.state.reportedFinished.length >= 1) {
			let newMin = this.state.reportedFinished.length / this.state.currentLoading.length;
			// Ajoute uniquement dans le *restant* de la barre.
			if (this.state.currentMin > 0) {
				newMin = newMin * (1-this.state.currentMin) + this.state.currentMin;
			}
			// Dans l'éventualité où nous sommes dans "l'animation de fin".
			// C'est techniquement un edge case, mais un edge case qui *va* arriver
			// et pourrait être difficile à débugger.
			//
			// Le comportement avec le width fait que la loading bar stoppe, puis le reste
			// est considéré comme le restant.
			//
			// Solution alternative si c'est jugé *trop glitchy*:
			//     1) Sentinelle (flag) pour indiquer qu'on est en animation de fin de load.
			//     2) Placer les loads sans se soucier, jusqu'à la fin de l'animation. À sa fin
			//        l'animation s'occupe, juste magiquement via le state, de refaire le nouveau load.
			if (this.valueNode && this.node) {
				newMin = Math.min(newMin,
					this.valueNode.offsetWidth / this.node.offsetWidth
				);
			}
			// Mutation du state étant donné qu'on réutilise le state pour concaténer
			// un peu plus loin.
			this.state.currentLoading = this.state.currentLoading.filter((token) => {
				return this.state.reportedFinished.indexOf(token) === -1;
			});
			this.setState({
				currentMin:       newMin,
				currentLoading:   this.state.currentLoading,
				reportedFinished: [],
			});
		}

		// Mutation du state pour éviter un problème sur de multiples add sur un seul loading.
		this.state.currentLoading = this.state.currentLoading.concat(token);
		this.setState({
			currentLoading: this.state.currentLoading,
			done:           false,
		});

		if (fn) {
			fn(token);
		}

		return token;
	}

	/**
	 * Indique un chargement complet au "compteur interne".
	 *
	 * @param token {String} Token à marquer comme fini.
	 * @return {Boolean} False si le chargement reporté était déjà reporté.
	 */
	reportFinished(token) {
		if (this.state.currentLoading.indexOf(token) === -1) {
			return false;
		}
		if (!this._mounted) { return false; }

		// Étant-donné qu'une mise à jour du component peut se faire plusieurs
		// fois avant un refresh du state, on mutate le state *et* on le setState.
		this.state.reportedFinished = this.state.reportedFinished.concat(token);
		this.setState({
			reportedFinished: this.state.reportedFinished,
			done:             false,
		}, () => {
			this.props.clearTimeout(this.cleanupTimer);
			this.cleanupTimer = this.props.setTimeout(() => this.delayDisappearance(), DELAY);
		});

		return true;
	}

	/**
	 * Callback utilisé pour réinitialiser.
	 */
	delayDisappearance() {
		if (this.state.reportedFinished.length >= this.state.currentLoading.length) {
			this._reset();
		}
	}

	render() {
		// Collection de classes.
		let classNames = ["loading-bar"];
		// On prend aussi celles données au widget.
		classNames.push(this.props.className);

		// Classes stateful du chargement.
		if (this.state.currentLoading.length >= this.state.reportedFinished.length && this.state.done) {
			classNames.push("is-done-loading");
		}
		else {
			classNames.push("is-loading");
		}

		// Pourcentage "CSS"
		let percent = 0;
		if (!this.state.done) {
			// [0, 1]
			let progress = this.state.reportedFinished.length / this.state.currentLoading.length;
			// Normalisé avec le "current" des loads complets précédents.
			progress *= 1 - this.state.currentMin;
			// Ne pas oublier de le rajouter.
			progress += this.state.currentMin;

			// Pourcentage calculé avec le petit bout omniprésent. Jamais plus que MAX_PERCENT
			percent = Math.min(Math.ceil(INITIAL_PERCENT + progress * (MAX_PERCENT-INITIAL_PERCENT)), MAX_PERCENT);
		}

		// Les styles appliqués inline.
		let styles = {
			width: percent + "%",
		};

		return (
			<div
				ref={ node => this.node = node }
				id={this.props.id}
				className={classNames.join(" ")}
			>
				<div
					ref={ node => this.valueNode = node }
					className="loading-bar--value"
					style={styles}
				/>
			</div>
		);
	}
}

LoadingBar.propTypes = {
	id:        PropTypes.string,
	className: PropTypes.string,
};

export default reactTimeout(LoadingBar);

