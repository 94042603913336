import * as ROUTER from "../actions/router";

/**
 * Enregistre les états du routeur pour le SSR.
 * C'est utilisé du côté serveur pour générer
 * les 301 et les 404.
 *
 * Avoir plusieurs intentions à la fois est un
 * undefined behaviour.
 */
const router = (state = {}, action) => {
	switch (action.type) {
		case ROUTER.REDIRECT:
			return Object.assign({}, state, {
				location: action.path,
				options: action.options,
				// Ne retire pas l'erreur s'il y en a une.
			});
		case ROUTER.ERROR:
			return Object.assign({}, state, {
				location: null,
				options: null,
				error: action.error,
			});
		case "@@router/LOCATION_CHANGE":
			return Object.assign({}, state, {
				error: null,
				route: action.location,
				action: action.action,
			});
	}

	return state;
};

export default router;
