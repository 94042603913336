import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Fetch                 from "qidigo-fetch";
import {FormattedMessage}    from "react-intl";
import {Link}                from "react-router/es";
import View, {translations}  from "@app/views/forgot/token.js";
import Auth                  from "qidigo-auth";
import {navigate}            from "qidigo-router";
import T                     from "qidigo-i18n/messages";

/**
 * @extends {Component}
 */
class ForgotTokenController extends Component {
	constructor() {
		super();
		this.state = {
			// True whilst the request is pending.
			saving: false,
			// True when a request was sent entirely.
			success: false,
			errors: null,
			// Form
			infos: {
				/* eslint-disable camelcase */
				new_password: "",
				new_password_confirmation: "",
				/* eslint-enable */
			},
		};
	}

	validate(errors) {
		const {formatMessage} = this.context.intl;
		const {infos} = this.state;
		if (infos.new_password !== infos.new_password_confirmation) {
			errors.fields["new_password_confirmation"] =
				[formatMessage(T["errors.messages.confirmation"], {
					attribute: formatMessage(translations.newPassword),
				})]
			;
		}

		return errors;
	}

	onValidationError(errors) {
		this.setState({errors});
	}

	onChange(field, e, value) {
		const infos = this.state.infos;
		infos[field] = value;
		this.setState({infos});
	}

	onSubmit(e) {
		this.setState({success: false});
		this.setState({saving: true, errors: false});

		const {token} = this.props.params;
		const {
			email,
			new_password, // eslint-disable-line
		} = this.state.infos;

		const password = new_password; // eslint-disable-line
		const user = {email, token, new_password}; // eslint-disable-line

		Fetch.post("authenticate/password_reset", {user})
		.then((result) => {
			this.setState({saving: false});
			this.setState({success: true});
			return Auth.login(email, password, {remember: false})
				.then((token) => {
					this.setState({loading: false});
					navigate("/dashboard");

					return token;
				});
		})
		.catch((errors) => {
			const {formatMessage} = this.context.intl;
			const values = {
				retry: <FormattedMessage {...translations["retry"]} values={{link: <Link to="/forgot/password"><FormattedMessage {...translations["retry.link"]} /></Link>}} />,
			};

			if (errors.error_code === 'EMAIL_DOESNT_MATCH_THE_VALIDATION_CODE_OR_VALIDATION_CODE_IS_EXPIRED') {
				errors = {
					message: <FormattedMessage
						{...translations["expired_or_bad_token"]}
					/>
				};

				this.setState({saving: false});
				if (errors["message"]) {
					this.setState({errors});
				}

				return null;
			}

			const fields = errors['invalid-params'];

			// Backend should send translations key and not english text to avoid code above.
			const passwordMinKey = 'Password has to be at least 8 characters.';
			if (fields.new_password && fields.new_password.includes(passwordMinKey)) {
				fields.new_password.splice(fields.new_password.indexOf(passwordMinKey), 1);
				fields.new_password.push(formatMessage(translations["passwordMin"]));
			}

			// 404 is for a token not found.
			if (errors.status && errors.status === 404) {
				errors = {
					message: <FormattedMessage {...translations["used"]} values={values} />,
					fields
				};
			}

			this.setState({saving: false});
			if (errors["message"]) {
				this.setState({errors, fields});
			}
			else {
				this.setState({errors: {
					message: <FormattedMessage {...translations["unexpected"]} values={values} />,
					fields,
				}});
			}

			return null;
		})
		;
	}

	render() {
		const {
			errors,
			saving,
			infos,
			success,
		} = this.state;

		return <View
			onSubmit={(...e)=>this.onSubmit(...e)}
			onChange={(...e)=>this.onChange(...e)}
			onValidate={(...e)=>this.validate(...e)}
			onValidationError={(...e)=>this.onValidationError(...e)}
			values={infos}
			saving={saving}
			errors={errors}
			success={success}
		/>;
	}
}

ForgotTokenController.contextTypes = {
	intl: PropTypes.object,
};

ForgotTokenController.propTypes = {
	params: PropTypes.object,
};

export default ForgotTokenController;
