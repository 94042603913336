import * as React from "react";
import {Component} from "react";

import {defineMessages, FormattedMessage} from "react-intl";

const translations = defineMessages({
    "or": {
        id: "qidigo.activity.pass_selection_modal.separation_line.or",
        defaultMessage: "OU"
    },
})

interface ISeparationLineProps {
    isVertical: boolean,
}

interface ISeparationLineState {}

class SeparationLine extends Component<ISeparationLineProps, ISeparationLineState> {
    constructor(props: ISeparationLineProps) {
        super(props);
    }

    render() {
        return (
            <div className={"pass-selection-separation-container" + (this.props.isVertical ? "-vertical" : "")}>
                <div className={"pass-selection-separation-line"}/>
                <p className={"pass-selection-modal--or"}><FormattedMessage {...translations['or']}/></p>
                <div className={"pass-selection-separation-line"}/>
            </div>
        );
    }
}

export default SeparationLine;
