import React, {Component}    from "react";
import PropTypes             from "prop-types";

import View                  from "@app/views/activity/GroupsFragment.js";
import Loading               from "qidigo-components/loading.js";
import {navigate}            from "qidigo-router";
import {path}                from "@app/lib/routes";
/**
 * Contrôleur pour la page d'une activité.
 *
 * @extends {Component}
 */
class ActivityController extends Component {
	render() {

		const {
			/* eslint-disable */
			route,
			params,
			/* eslint-enable */
			activity,
			groups,
			...props
		} = this.props;
		if (!activity || !groups) { return <Loading />; }
		if(this.props.activity.registration_template=="multiple")
			navigate(path("activity.groups", this.props.params), { replace: true });
		return <View
			{...props}
			activity={activity}
			groups={groups}
		/>;
	}
}

ActivityController.propTypes = {
	route: PropTypes.object,
	location: PropTypes.object,
	activity: PropTypes.object,
	groups: PropTypes.arrayOf(PropTypes.object),
};

export default ActivityController;
