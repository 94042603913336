import React                 from "react";
import PropTypes             from "prop-types";
import ActivityLink          from "@app/components/activity_link";
import Image                 from "qidigo-components/image";
import Page                  from "@app/components/page.js";
import {GatewayDest}         from "qidigo-components/gateway";

//import {
//	defineMessages,
//	FormattedMessage
//} from "react-intl";

//const translations = defineMessages({
//});

/**
 * Présente une page dans la hiérarchie de l'activité, avec un bandeau
 * omniprésent pour l'activité.
 */
const ActivityChildLayout = (props, context) => {
	const {
		children,
		activity,
		...leftOverProps
	} = props;
	const childProps = Object.assign({activity}, leftOverProps);
	const img = activity.sharing_media;

	return (
		<Page className="activity-child-page">
			<header>
				<div className="activity-header--image">
					<Image
						withSpinner={false}
						key={img.id}
						className="activity-header--image-image"
						src={img.path}
					/>
				</div>
				<div className="activity-header--description">
					<GatewayDest name="activity-header" />
					<h1>
						<ActivityLink activity={activity} />
					</h1>
				</div>
			</header>

			<div className="activity-child">
				{children && React.cloneElement(children, childProps)}
			</div>
		</Page>
	);
};

ActivityChildLayout.propTypes = {
	activity: PropTypes.object,
};


export default ActivityChildLayout;
