/* global module require */
import {
	createStore,
	combineReducers,
	applyMiddleware
} from "redux";
import thunkMiddleware       from "redux-thunk";
import rootReducer           from "./reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import {syncHistoryWithStore, routerReducer} from "react-router-redux";
import {fetchCart} from "./actions/cart";

export default function configureStore(initialState) {
	const store = createStore(
		combineReducers({
			...rootReducer,
			routing: routerReducer,
		}),
		composeWithDevTools(
			applyMiddleware(thunkMiddleware)
		)
	);

	// TODO:: do better and scallable
	// Dispatch action in the store creation to initialize the cart with data
	// My suggestion is to make an init action that include all action
	// we need to init the store
	store.dispatch(fetchCart());

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("./reducers", () => {
			const nextRootReducer = require("./reducers").default;
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
