import React from 'react';
import Toolbar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarDays from "./CalendarDays";
import {locale} from "moment";
import {defineMessages, FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

const translations = defineMessages({
    "today": {
        id: "qidigo.calendar.labels.today",
        defaultMessage: "Aujourd'hui"
    },
    "week": {
        id: "qidigo.calendar.labels.week",
        defaultMessage: "Semaine"
    },
    "month": {
        id: "qidigo.calendar.labels.month",
        defaultMessage: "Mois"
    },
    "list": {
        id: "qidigo.calendar.labels.list",
        defaultMessage: "Liste"
    },
    "available": {
        id: "qidigo.calendar.legend.available",
        defaultMessage: "Disponible"
    },
    "available_soon": {
        id: "qidigo.calendar.legend.available_soon",
        defaultMessage: "Pas encore disponible"
    },
    "not_available": {
        id: "qidigo.calendar.legend.not_available",
        defaultMessage: "Non disponible"
    },
    "full": {
        id: "qidigo.calendar.legend.full",
        defaultMessage: "Complet"
    },
})

const Legend = () => (
    <div className={'calendar-toolbar--legend-container'}>
        <div className={"calendar-toolbar--legend-box"}>
            <div>
                <span className={'calendar-toolbar--legend-available'}></span>
                <span>
                <FormattedMessage {...translations['available']}/>
            </span>
            </div>
            <div>
                <span className={'calendar-toolbar--legend-available-soon'}></span>
                <span>
                <FormattedMessage {...translations['available_soon']}/>
            </span>
            </div>
            <div>
                <span className={'calendar-toolbar--legend-not-available'}></span>
                <span>
                <FormattedMessage {...translations['not_available']}/>
            </span>
            </div>
            <div>
                <span className={'calendar-toolbar--legend-full'}></span>
                <span>
                <FormattedMessage {...translations['full']}/>
            </span>
            </div>
        </div>
    </div>
)

export default class CustomToolbar extends Toolbar {
    setCalendarLabelFromDate = () => {
        let startDate = new Date(this.props.date)

        if (this.props.view === 'week') {
            const daysUntilFirst = locale() === 'en' ? (7 + startDate.getDay()) % 7 : (6 + startDate.getDay()) % 7;
            startDate.setDate(startDate.getDate() - daysUntilFirst);
        }

        const lastDate = new Date(startDate.toDateString())
        lastDate.setDate(startDate.getDate() + 6);

        const showBothMonths = startDate.getMonth() !== lastDate.getMonth();

        const formattedStartDate = startDate
            .toLocaleDateString(locale(), {
                    day: '2-digit',
                    month: locale() === 'en' || showBothMonths ? 'short' : undefined,
                }
            );

        const formattedLastDate = lastDate
            .toLocaleDateString(locale(), {
                    day: '2-digit',
                    month: locale() === 'fr' || showBothMonths ? 'short' : undefined,
                }
            );

        return formattedStartDate + ' - ' + formattedLastDate;
    }

    render() {
        return (
            <div>
                {
                    this.props.view === 'week' &&
                    <Legend/>
                }
                <div className='calendar-toolbar'>
                    <div className={"calendar-toolbar--invisible-section"}></div>
                    <div className={"calendar-toolbar--day-container"}>
                        <button className="calendar-toolbar--btn-today" type="button"
                                onClick={() => this.navigate('TODAY')}>
                            <FormattedMessage {...translations['today']}/>
                        </button>
                        <span className="rbc-btn-group calendar-toolbar--btn-next ">
                            <span onClick={() => this.navigate('PREV')} className="chevron-left"></span>
                            <span
                                className="span--toolbar-date rbc-toolbar-label">{this.setCalendarLabelFromDate()}</span>
                            <span onClick={() => this.navigate('NEXT')} className="chevron-right"></span></span>
                    </div>
                    <span className="rbc-btn-group calendar-toolbar--view-btn-group-container">
                        {
                            this.props.fromSchedule
                                ? <button className={this.props.view === "month" ? "calendar-toolbar--view-btn-selected" : ""}
                                        type="button" onClick={() => this.changeView('month')}>
                                    <FormattedMessage {...translations['month']}/>
                                </button>
                                : <button className={this.props.view === "week" ? "calendar-toolbar--view-btn-selected" : ""}
                                          type="button" onClick={() => this.changeView('week')}>
                                    <FormattedMessage {...translations['week']}/>
                                </button>
                        }
                    <button className={this.props.view === "agenda" ? "calendar-toolbar--view-btn-selected" : ""}
                            type="button" onClick={() => this.changeView('agenda')}>
                        <FormattedMessage {...translations['list']}/>
                    </button></span>
                </div>
                {
                    this.props.view === "week" &&
                    <CalendarDays
                        startDate={this.props.date}
                    />
                }
            </div>
        );
    }

    navigate = (action) => {
        this.props.onNavigate(action);
    }

    changeView = (view) => {
        this.props.setCurrentView(view);
        this.props.onViewChange(view);
    }
}

CustomToolbar.propTypes = {
    onView: PropTypes.func,
    onNavigate: PropTypes.func,
    fromSchedule: PropTypes.bool
};
