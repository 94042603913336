import React, {Component}    from "react";
import PropTypes             from "prop-types";
import moment                from "moment";
import capitalize            from "lodash/capitalize";
import T                     from "qidigo-i18n/messages";

import {
	defineMessages,
	FormattedMessage
} from "react-intl";

import Select                from "./select.js";

const translations = defineMessages({
	year:   {id: "qidigo.dateinput.label.year",   defaultMessage: "Année"},
	month:  {id: "qidigo.dateinput.label.month",  defaultMessage: "Mois"},
	day:    {id: "qidigo.dateinput.label.day",    defaultMessage: "Jour"},
});

/**
 * Who needs leftpad?
 */
const pad = (x) => parseInt(x) < 10 ? `0${x}` : `${x}`;

/**
 * Contrôle permettant la sélection d'une date.
 *
 * Utilise moment.js à l'interne, mais s'attend à un input/output YYYY-MM-DD
 * via une string value et dans son onChange.
 */
// FIXME : Handler l'ordre localisé du contrôle (US M/D/Y)
class DateInput extends Component {
	currentDay() {
		const val = parseInt(this.props.value.split("-")[2]);

		return Number.isNaN(val) ? 0 : val;
	}

	currentMonth() {
		const val = parseInt(this.props.value.split("-")[1]);

		return Number.isNaN(val) ? 0 : val;
	}

	currentYear() {
		const val = parseInt(this.props.value.split("-")[0]);

		return Number.isNaN(val) ? 0 : val;
	}

	validate(softValidate) {
		const {formatMessage} = this.context.intl;

		if (softValidate) {
			return [];
		}
		const {withDay} = this.props;

		const errors = [];

		// FIXME : Handle invalid dates.
		if (
			this.props.required && (
				this.currentDay() === 0 && withDay ||
				this.currentMonth() === 0 ||
				this.currentYear() === 0
			)
		) {
			errors.push(formatMessage(T["errors.messages.invalid"]));
		}

		return errors;
	}

	handleChange(field, event, value) {
		// Get the current informations.
		const newDate = {
			day: this.currentDay(),
			month: this.currentMonth(),
			year: this.currentYear(),
		};

		// On renseigne la nouvelle valeur modifiée.
		newDate[field] = value;
		const str = [];
		str.push(newDate.year);
		str.push(pad(newDate.month));
		if (this.props.withDay) {
			str.push(pad(newDate.day));
		}
		// Et on rappelle le parent!
		if (this.props.onChange) {
			this.props.onChange(event, str.join("-"));
		}
	}

	render() {
		const {formatMessage} = this.context.intl;

		let {
			intl, // eslint-disable-line
			label,
			value,
			minDate,
			maxDate,
			withDay,
			className,
			onChange, // eslint-disable-line
			error,
			...leftoverProps
		} = this.props;

		if (!moment.isMoment(minDate)) { minDate = moment(minDate, "YYYY-MM-DD"); }
		if (!moment.isMoment(maxDate)) { maxDate = moment(maxDate, "YYYY-MM-DD"); }

		let classes = ["input", "input-dateinput", "dateinput"];
		classes.push(className);

		// Liste d'années pour le select.
		const years = [];
		for (let i = maxDate.year(); i >= minDate.year(); i--) {
			years.push({key: i, value: i});
		}

		// Liste de mois pour le select.
		const months = [];
		for (let i = 1; i <= 12; i++) {
			// Découvrons le nom du mois!
			let monthName = moment.months()[i-1];
			// Uppercase it.
			monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
			months.push({key: i, value: monthName});
		}

		// Liste de jours pour le select.
		const days = [];

		// Pas de valeur? 31!
		const protoDate = moment(value, "YYYY-MM");
		const monthLength = protoDate.daysInMonth() ? protoDate.daysInMonth() : 31;

		for (let i = 1; i <= monthLength; i++) {
			days.push({key: i, value: i});
		}

		// Les listes, avec la key du field pour auto-gérer l'ordre.
		const options = {
			day: days,
			month: months,
			year: years,
		};

		let errorMessage = null;
		if (error) {
			classes.push("is-invalid");
			classes.push("with-error-message");
			errorMessage =
				<div className="input--error-message">
					<FormattedMessage {...T["errors.format"]} values={{
						message: error.join?error.join(", "):error,
						attribute: label,
					}} />
				</div>
			;
		}

		// Ordre de la date, selon la locale actuel.
		// Il serait possible de cacher et changer à l'update de la locale,
		// mais ce serait plus risqué de faire des effets weird.
		const localeOrder = moment("2000-11-30", "YYYY-MM-DD")
			.format("L")
			.replace(/-/g, "/")
			.replace("2000", "year")
			.replace("11", "month")
			.replace("30", "day")
			.split("/")
		;
		if (!withDay) {
			localeOrder.splice(localeOrder.indexOf("day"), 1);
		}

		return (
			<div className={classes.join(" ")} {...leftoverProps}>
				<label>
					<span className="input--label-text">{label}</span>
				</label>
				<div className="dateinput--selects">
					{localeOrder.map((field) => {
						const currentField = `current${capitalize(field)}`;
						
						return (
							<Select
								key={field}
								className={`dateinput--${field} ${error ? "is-invalid" : ""}`}
								options={options[field]}
								label={formatMessage(translations[field])}
								value={this[currentField]().toString()}
								onChange={(...e) => this.handleChange(field, ...e)}
							/>
						);
					})}
				</div>
				{errorMessage}
			</div>
		);
	}
}

DateInput.defaultProps = {
	maxDate: moment(), // Gives out "today" with moment.js
	minDate: moment("1900-01-01"),
	value: "0000-00-00",
	withDay: true
};

DateInput.propTypes = {
	// Format: YYYY-MM-DD
	value:       PropTypes.string,
	withDay:     PropTypes.bool,
	label:       PropTypes.string,
	valid:       PropTypes.bool,
	error:              PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]),
	className:   PropTypes.string,
	onChange:    PropTypes.func,
	disabled:    PropTypes.bool,
	required:    PropTypes.bool,
	maxDate:     PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]),
	minDate:     PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]),
};

DateInput.contextTypes = {
	intl: PropTypes.object,
};

DateInput.contextTypes = {
	intl: PropTypes.object,
};

export default DateInput;
