import React                 from "react";
import PropTypes             from "prop-types";
import Helmet                from "react-helmet";
import {defineMessages}      from "react-intl";
import {Link}                from "react-router/es";

import Form                  from "qidigo-form";
import ButtonsBar            from "qidigo-form/buttons_bar.js";
import {errorMessagesType}   from "qidigo-form/proptypes.js";
import Page                  from "@app/components/page.js";

const translations = defineMessages({
	"title":   {id: "qidigo.forgot.title",   defaultMessage: "Mot de passe oublié"},
	"email":   {id: "qidigo.forgot.email",   defaultMessage: "Courriel"},
	"success": {id: "qidigo.forgot.success", defaultMessage: "Nous vous avons envoyé par courriel le lien de réinitialisation du mot de passe. Si vous ne l'avez pas reçu, veuillez vérifier vos courriels indésirables."},
	"return":  {id: "qidigo.forgot.return",  defaultMessage: "Retourner à l'accueil."},
	"submit":  {id: "qidigo.forgot.submit",  defaultMessage: "Go!"},
});

const ForgotView = (props, context) => {
	const {formatMessage} = context.intl;

	let widget = null;

	if (props.success) {
		widget =
			<div className="forgot--result">
				<p>{formatMessage(translations.success)}</p>
				<p><Link to="/">{formatMessage(translations.return)}</Link></p>
			</div>
		;
	}
	else {
		widget = <Form
			onSubmit={(...e)=>props.onSubmit(...e)}
			onChange={(...e)=>props.onChange(...e)}
			onValidationError={(errors)=>props.onValidationError(errors)}
			disabled={props.saving}
			values={props.values}
			errors={props.errors}
			presentation="simple"
		>
			<Form.Input name="email"
				icon="email"
				type="email"
				required={true}
				label={formatMessage(translations.email)}
				autoFocus={true}
			/>
			<ButtonsBar
				loading={props.saving}
				submitLabel={formatMessage(translations.submit)}
			/>
		</Form>
			;
	}

	return (
		<Page name="forgot">
			<Helmet
				title={formatMessage(translations.title)}
			/>
			<section className="forgot"><div className="forgot--forgot-box">
				<h1>{formatMessage(translations.title)}</h1>
				{widget}
			</div></section>
		</Page>
	);
};

ForgotView.propTypes = {
	errors:    errorMessagesType,
	values:    PropTypes.object,
	success:   PropTypes.bool,
	saving:    PropTypes.bool,
	onChange:  PropTypes.func,
	onSubmit:  PropTypes.func,
	onValidationError: PropTypes.func,
};

ForgotView.contextTypes = {
	intl: PropTypes.object,
};

export default ForgotView;

