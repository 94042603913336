import * as React from "react";
import {Component} from "react";

import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import MoneyInput from "@app/components/money-input";

const translations = defineMessages({
    "adjust": {
        id: "qidigo.billing.contact_credit_popup.adjust",
        defaultMessage: "Ajuster"
    },
    "adjust_title": {
        id: "qidigo.billing.contact_credit_popup.adjust_title",
        defaultMessage: "Ajuster le crédit à appliquer"
    },
    "applied_credit": {
        id: "qidigo.billing.contact_credit_popup.applied_credit",
        defaultMessage: "Crédit à appliquer"
    },
    "close": {
        id: "qidigo.billing.contact_credit_popup.close",
        defaultMessage: "Fermer"
    },
    "maximum": {
        id: "qidigo.billing.contact_credit_popup.maximum",
        defaultMessage: "Maximum"
    },
    "remove": {
        id: "qidigo.billing.contact_credit_popup.remove",
        defaultMessage: "Retirer"
    },
    "maximum_credit_warning": {
        id: "qidigo.billing.contact_credit_popup.maximum_credit_warning",
        defaultMessage: "Maximum {value} $ disponible"
    }
});

interface IContactCreditPopupProps {
    creditTotal: number,
    appliedCredit: number,
    closePopup: () => void,
    applyCredit: (applyingCredit: number) => void
}

interface IContactCreditPopupState {
    appliedCredit: number,
    shouldDisplayWarning: boolean,
    maxButtonDisabled: boolean,
    removeButtonDisabled: boolean
}

class ContactCreditPopup extends Component<IContactCreditPopupProps, IContactCreditPopupState> {
    constructor(props: IContactCreditPopupProps) {
        super(props);
        this.state = {
            appliedCredit: this.props.appliedCredit,
            shouldDisplayWarning: false,
            maxButtonDisabled: props.appliedCredit >= props.creditTotal,
            removeButtonDisabled: false,
        };
    }

    handleInput = (event, value) => {
        this.updateAppliedCredit(value);
    }

    updateAppliedCredit = (applyingCredit: number) => {
        let creditToApply: number = Number(applyingCredit);
        let shouldDisplayWarning: boolean = false;
        let maxButtonEnabled: boolean = creditToApply === this.props.creditTotal ? true : false
        let removeButtonEnabled: boolean = creditToApply === 0 ? true : false

        if (creditToApply > this.props.creditTotal) {
            creditToApply = this.props.creditTotal;
            shouldDisplayWarning = true;
        }

        this.setState({
            appliedCredit: creditToApply,
            shouldDisplayWarning: shouldDisplayWarning,
            maxButtonDisabled: maxButtonEnabled,
            removeButtonDisabled: removeButtonEnabled
        });
    }

    render() {
        return (
            <div className="contact-credit-popup">
                <div className={"contact-credit-popup--content"}>
                    <div className={"contact-credit-popup--title"}>
                        <FormattedMessage {...translations["adjust_title"]} />
                        <a
                            className={"contact-credit-popup--x-button"}
                            onClick={() => this.props.closePopup()}
                        ></a>
                    </div>
                    <div className="contact-credit-popup--form">
                        <div className={"contact-credit-popup--row"}>
                            <div className={"contact-credit-popup--input-container"}>
                                <div>
                                    <div>
                                        <label
                                            htmlFor={"applied-credit"}
                                        >
                                            <FormattedMessage {...translations["applied_credit"]} />
                                        </label>
                                    </div>
                                    <MoneyInput
                                        id="applied-credit"
                                        className={"contact-credit-popup--input"}
                                        step={0.50}
                                        min={0}
                                        max={this.props.creditTotal}
                                        value={this.state.appliedCredit}
                                        onChange={(e, v) => this.handleInput(e, v)}
                                    />
                                    {
                                        this.state.shouldDisplayWarning &&
                                        <div className={"contact-credit-popup--warning-div"}>
                                            <span className={"contact-credit-popup--warning"}>
                                                <FormattedMessage {...translations["maximum_credit_warning"]}
                                                                  values={{value: this.props.creditTotal.toFixed(2)}}
                                                />
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"contact-credit-popup--max-button-container"}>
                                <Button
                                    disabled={this.state.maxButtonDisabled}
                                    className="contact-credit-popup--button"
                                    onClick={() => this.updateAppliedCredit(this.props.creditTotal)}
                                >
                                    <FormattedMessage {...translations["maximum"]} />
                                </Button>
                            </div>
                            <div className={"contact-credit-popup--remove-button-container"}>
                                <Button
                                    disabled={this.state.removeButtonDisabled}
                                    className="contact-credit-popup--button"
                                    onClick={() => this.updateAppliedCredit(0)}
                                >
                                    <FormattedMessage {...translations["remove"]} />
                                </Button>
                            </div>
                        </div>
                        <div className={"contact-credit-popup--submit-row"}>
                            <Button
                                className="contact-credit-popup--close-button"
                                onClick={() => this.props.closePopup()}
                            >
                                <FormattedMessage {...translations["close"]} />
                            </Button>
                            <Button
                                className="contact-credit-popup--adjust-button primary"
                                onClick={() => this.props.applyCredit(this.state.appliedCredit)}
                            >
                                <FormattedMessage {...translations["adjust"]} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactCreditPopup;
