import React, {Component} from "react";
import FormListView from "@app/views/cart/form/FormListView";
import PropTypes from "prop-types";
import qidigoFetch from "qidigo-fetch";


class FormCartListController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            forms: [],
            userValidatedForms: false,
            allFormsAnswered: true
        }
    }

    async componentDidMount() {
        this.setState({ disabled: true });
        const slug = window.location.pathname.split('/', 3)[2];
        await qidigoFetch
            .get(`order/customers?organization=${slug}`)
            .then(async (contact) => {
                this.setState({
                    forms: await qidigoFetch.get(`order/customers/${contact.id}/pending-order/forms`)
                });
            })

        if (this.state.forms.length === 0) {
            window.location = '/u/' + slug
        }

        this.state.forms.forEach((form) => {
            if (!form["is_complete"]) {
                this.setState({
                    allFormsAnswered: false
                });
            }
        });
    }

    async handleFormValidation(e, confirmed) {
        await this.setState({
            userValidatedForms: confirmed
        });
    }

    handleAnswerForm(e, formId) {
        const slug = window.location.pathname.split('/', 3)[2];
        window.location = `/u/${slug}/pending-order/form/${formId}`;
    }

    handleCheckout(e) {
        const data = window.location.pathname.split('/', 3);

        if (this.state.userValidatedForms && this.state.allFormsAnswered) {
            window.location = '/u/'+ data[2] +'/pending-order/checkout'
        }
    }

    render() {
        return (
            <FormListView
                {...this.props}
                forms={this.state.forms}
                userValidatedForms={this.state.userValidatedForms}
                allFormsAnswered={this.state.allFormsAnswered}
                handleAnswerForm={(...e) => this.handleAnswerForm(...e)}
                handleCheckout={(...e) => this.handleCheckout(...e)}
                handleFormValidation={(...e) => this.handleFormValidation(...e)}
            />
        );
    }
}

FormCartListController.propTypes = {
    route: PropTypes.object,
};

export default FormCartListController;
