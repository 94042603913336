import * as React from "react";
import {Component, useContext, useState} from "react";
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import Money from "@app/components/money";
// @ts-ignore
import PropTypes, {bool} from "prop-types";
// @ts-ignore
import Loading from "qidigo-components/loading.js";
import {FamilyMember, Group, IActivity, IOffer, IOrganization, SelectedMember} from "../../models";
import ActivityGroupsList from "./ActivityGroupsList";
import {AlertContext} from "../AlertContextProvider";
import SubscribeToOrganizationConfirmationModal from "../organization/_personal_information_consent";


interface IButtonsProps {
    handlePreviousStep: () => void;
    handleAddToCartForAll: () => void;
    hasConsent: boolean;
    handleAddToCartForAllWithoutConsent: () => void;
    groupsOffers: unknown;
    disabled: boolean;
    context: any
    organizationId: number
}

const Buttons: React.FC<IButtonsProps> = ({
                                              handlePreviousStep,
                                              handleAddToCartForAll,
                                              hasConsent,
                                              handleAddToCartForAllWithoutConsent,
                                              groupsOffers,
                                              context,
                                              disabled,
                                              organizationId
                                          }) => {
    const {hasContact} = context;
    const [hasConsentState, setHasConsentState] = useState(hasContact(organizationId));

    return (
        <AlertContext.Consumer>
            {({setAlertMessage}) => (
                <ButtonsBar withoutSubmit={true} className={"member-offers-selection--buttons-bar"}>
                    <Button onClick={e => handlePreviousStep()} className="ButtonsBar-left form-ButtonsBar-sm">
                        <FormattedMessage {...translations["btn.back"]} />
                    </Button>
                    {groupsOffers && (
                        <Button
                            onClick={hasConsentState ? handleAddToCartForAll : handleAddToCartForAllWithoutConsent}
                            disabled={disabled}
                            className="member-offers-selection--buttons-bar--button-add-to-cart"
                        >
                            <FormattedMessage {...translations["btn.addPanier"]} />
                        </Button>
                    )}
                </ButtonsBar>
            )}
        </AlertContext.Consumer>
    );
};

Buttons.contextTypes = {
    intl: PropTypes.object,
    hasContact: PropTypes.func,
};

const translations = defineMessages({
    "money": {
        id: "qidigo.billing.line.money",
        defaultMessage: "{money} $"
    },
    "btn.back": {
        id: "qidigo.activity.groups.btn.back",
        defaultMessage: "Étape précédente"
    },
    "btn.addPanier": {
        id: "qidigo.group.offerform.add",
        defaultMessage: "Ajouter au panier"
    },
    "page.title.plan.list": {
        id: "qidigo.activity.groups.page.title.liste.offers",
        defaultMessage: "Choix des offres "
    },
    "selected_offer_restrictions_warning": {
        id: "qidigo.activity.groups.page.selected_offer_restrictions_warning",
        defaultMessage: "Certaines offres sélectionnées ne sont pas valides et ne seront pas ajoutées au panier"
    },
});
import Button from "../../../../modules/qidigo-components/button";
// @ts-ignore
import ButtonsBar from "../../../../modules/qidigo-form/buttons_bar";

const MAX_ITEMS_DEFAULT_COLLAPSE = 3

interface IMemberOffersSelectionProps {
    activity: IActivity
    organization: IOrganization
    groups: Group[]
    offers: IOffer[][]
    members: Record<string, FamilyMember>
    handleSelectPlan: (id: string, shouldUpdatePrice: boolean) => void,
    handleAddToCartForAll: () => void
    disabled: boolean
    onDelete: (groupId: number, memberId: number) => void
    newStatus: Record<number, string>
    handlePreviousStep: () => void
    groupsOffers: unknown
    refreshOptions: () => void
    isLoading: boolean
    hasRestrictedSelection: boolean
    onAddToWaitingList: (planId: number, memberId: number) => void
    openCart: () => void
    cartItems: { plan: { id: number }, subscriber: { id: number } }[] | undefined
    changeAlertMessage: (message: string) => void
    selectedMembers: Array<SelectedMember>

}

interface IMemberOffersSelectionState {
    isModalOpen: boolean,
    nextProcess: any,
    hasConsent: boolean,
}

class MemberOffersSelection extends Component<IMemberOffersSelectionProps, IMemberOffersSelectionState> {
    constructor(props: IMemberOffersSelectionProps) {
        super(props);

        this.state = {
            isModalOpen: false,
            nextProcess: null,
            hasConsent: false,
        };
    }

    handleAddToCartForAllWithoutConsent = () => {
        this.setState({
            isModalOpen: true,
            nextProcess: this.props.handleAddToCartForAll,
        })
    }

    onConsent = () => {
        const {hasContact} = this.context;

        this.setState({
            isModalOpen: false,
            hasConsent: hasContact(this.props.organization.id)
        })
    }

    onAddToWaitingListWithoutConsent = (planId, memberId) => {
        this.setState({
            isModalOpen: true,
            nextProcess: () => this.props.onAddToWaitingList(planId, memberId),
        })
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    render() {
        return (
            this.props.offers.length === 0 ?
                <Loading/> :
                <div className={'member-offers-selection'}>
                    {
                        !this.state.hasConsent
                        && <SubscribeToOrganizationConfirmationModal
                            isOpen={this.state.isModalOpen}
                            onClose={this.closeModal}
                            onConfirm={this.state.nextProcess}
                            onConsent={this.onConsent}
                            organization={this.props.organization}
                        />
                    }
                    <section className={'member-offers-selection--action-bar-container'}>
                        <h2>
                            <FormattedMessage {...translations["page.title.plan.list"]} />
                        </h2>
                        <Buttons
                            context={this.context}
                            disabled={this.props.disabled}
                            groupsOffers={this.props.groupsOffers}
                            handleAddToCartForAll={this.props.handleAddToCartForAll}
                            handleAddToCartForAllWithoutConsent={this.handleAddToCartForAllWithoutConsent}
                            handlePreviousStep={this.props.handlePreviousStep}
                            hasConsent
                            organizationId={this.props.organization.id}
                        />
                        {
                            this.props.hasRestrictedSelection &&
                            <div className="selected-offer-restrictions-warning">
                                <p>
                                    <FormattedMessage
                                        {...translations["selected_offer_restrictions_warning"]}
                                    />
                                </p>
                            </div>
                        }
                    </section>
                    {
                        this.props.selectedMembers.length > 0 &&
                        this.props.selectedMembers.map((member, key) => {
                            return <ActivityGroupsList
                                handlePreviousStep={this.props.handlePreviousStep}
                                handleAddToCartForAll={this.props.handleAddToCartForAll}
                                handleSelectPlan={this.props.handleSelectPlan}
                                onDelete={this.props.onDelete}
                                onAddToWaitingList={this.props.onAddToWaitingList}
                                onAddToWaitingListWithoutConsent={this.onAddToWaitingListWithoutConsent}
                                activity={this.props.activity}
                                selectedOffer={member.selectedOffers}
                                groups={this.props.groups}
                                offers={this.props.offers}
                                selectedGroups={member.selectedGroups}
                                groupsOffers={this.props.groupsOffers}
                                newStatus={member.newStatus}
                                disabled={this.props.disabled || this.props.isLoading}
                                isLoading={this.props.isLoading}
                                members={this.props.members}
                                member={member.id}
                                refreshOptions={this.props.refreshOptions}
                                hasRestrictedSelection={this.props.hasRestrictedSelection}
                                changeAlertMessage={this.props.changeAlertMessage}
                                openCart={this.props.openCart}
                                organization={this.props.organization}
                            />
                        })}
                    <section className={'member-offers-selection--action-bar-container'}>
                        <Buttons
                            context={this.context}
                            disabled={this.props.disabled}
                            groupsOffers={this.props.groupsOffers}
                            handleAddToCartForAll={this.props.handleAddToCartForAll}
                            handleAddToCartForAllWithoutConsent={this.handleAddToCartForAllWithoutConsent}
                            handlePreviousStep={this.props.handlePreviousStep}
                            hasConsent
                            organizationId={this.props.organization.id}
                        />
                    </section>
                </div>
        )
            ;
    }
}

// @ts-ignore
MemberOffersSelection.contextTypes = {
    intl: PropTypes.object,
    hasContact: PropTypes.func,
};

export default MemberOffersSelection;
