import React, {Component} from "react";
import PropTypes from "prop-types";
import Fetch from "qidigo-fetch";
import FormView from "@app/views/dashboard/forms/show.js";
import Loading from "qidigo-components/loading.js";
import sortBy from "lodash/sortBy";
import each from "lodash/each";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import {navigate} from "@app/modules/qidigo-router";
import Question from "@app/views/dashboard/forms/questions";

/**
 * Qidigo form controller for listing and updating answers
 *
 * @extends {Component}
 */
class FormController extends Component {
	constructor() {
		super();
		this.state = {
			form: null,
			loading: true,
			sent: false,
			answers : {}
		};
	}

	fetchForm() {
		Fetch.get(`forms/${this.props.params.id}`)
		.then((response) => {
			const {form} = response;
			const {_bundled} = response;
			const questions = keyBy(form.questions, (question)=>question.id);
			const result = Object.keys(questions).map((key) => questions[key]);
			const answers = mapValues(form.answers, ({answer}, id) => {
				const question = questions[id];

				return Question.prepareValue(question, JSON.parse(answer));
			});

			this.setState({form, answers, informations: _bundled, loading:false, questions: result});

			return response;
		});
	}

	handleChange(field, e, value) {
		const answers =  Object.assign({}, this.state.answers);
		answers[field] = value;
		this.setState({answers});
	}

	handleSubmit() {
		this.setState({sent: true});
		// FormData since we upload a file.
		const userAnswers = this.state.answers;
		const answers = new FormData();
		const questions = this.state.questions;

		questions.forEach( q => {
			if(q.type_id === 18 && userAnswers[q.id] !== undefined){
				answers.append(`answers[${q.id}][file]`, userAnswers[q.id].file);
				answers.append(`answers[${q.id}][file_name]`, userAnswers[q.id].file_name);
			}
			else if (userAnswers[q.id] !== undefined){
				answers.append(`answers[${q.id}]`, JSON.stringify(userAnswers[q.id]));
			}
		});

		Fetch.patch(`forms/${this.props.params.id}`, answers)
		.then((response)=> this.goBack()
		).catch((errors) => {
			console.log("Handle Submit Errors: ", errors);
			window.scroll(0, 0);
			this.setState({errors});
		});
	}

	handleCancel(e) {
		const slug = window.location.pathname.split('/', 3)[2];
		window.location = '/u/'+ slug +'/pending-order/forms'
	}

	goBack() {
		const slug = window.location.pathname.split('/', 3)[2];
		const url = this.props.backLocationOut
			? '/u/'+ slug +'/pending-order/forms'
			: this.props.backLocation

		navigate(url)
	}


	componentWillMount() {
		this.fetchForm();
	}

	render() {
		const {
			...props
		} = this.props;

		const {
			form,
			loading,
			answers,
			sent,
		} = this.state;

		if (loading) { return <Loading />; }
		const questionOrder = map(sortBy(form.questions, (o)=>o.order), (o)=>o.id);
		const questions = keyBy(form.questions, (question)=>question.id);

		// Order must be converted to int.
		// Lodash sortBy method cannot deal correctly with strings.
		// Resulting as 1-10-2-3 instead of 1-2-3-10
		// But it can correctly order numbers.
		each(form.sections, function(section) {
			section.order = parseInt(section.order);
		});

		const sectionOrder = sortBy(form.sections, "order");
	
		return <FormView
			form={form}
			sections={sectionOrder}
			errors={this.state.errors}
			sent={this.state.sent}
			questionOrder={questionOrder}
			questions={questions}
			answers={answers}
			onChange={(...e)=>this.handleChange(...e)}
			onSubmit={(...e)=>this.handleSubmit(...e)}
			onCancel={(...e)=>this.handleCancel(...e)}
			{...props}
		/>;
	}
}

FormController.propTypes = {
	params: PropTypes.shape({
		id: PropTypes.string,
	}).isRequired,
};

FormController.defaultProps = {
	backLocation: "dashboard/forms",
	backLocationOut: false,
}

export default FormController;

