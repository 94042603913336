// @ts-ignore
import {find, findLast} from "lodash";
import { Group, IOffer, FamilyMember } from '../../models'
import { IRestriction } from "./types";

export function getPlan(group: Group, offers: IOffer[][]) {
  const plan: IOffer[] = [];
  offers.forEach(parentOffer => {
    parentOffer.forEach(offer => {
      if (offer.offer_for_group_id !== group.id) {
          return;
      }
      
      if (find(offer.plan.groups, (x: { id: number; }) => x.id === group.id)) {
        plan.push(offer)
      }
    })
  })

  return plan;
}

export function getSelectedPlanOption(group: Group, offers: IOffer[][], selectedOffer: number) {
  if (selectedOffer) {
    return getPlan(group, offers).find(x => {
      return x.plan.id === Number(selectedOffer)
    });
  }

  return null;
}

export interface IRestrictionParameters {
    group: Group,
    offers: IOffer[][],
    members: Record<string, FamilyMember>,
    member: number,
    selectedOffer: number
}

export function getOption(params: IRestrictionParameters) {
  const selectedPlanOption = getSelectedPlanOption(params.group, params.offers, params.selectedOffer);

  return findLast(
    (selectedPlanOption && selectedPlanOption.options) || [],
    (x: { subscriber }) => params.members[params.member] && params.members[params.member].id === x.subscriber
  );
}

export function getRestrictions(params: IRestrictionParameters):IRestriction|null {
  const option = getOption(params);

  if (option) {
    return option.restrictions
  }

  return null;
}

export function getOnWaitingList(params: IRestrictionParameters) {
  const option = getOption(params);

  if (option) {
    return option.on_waiting_list
  }

  const plansForGroup = getPlan(params.group, params.offers);
  return plansForGroup.some(plan => {
    return plan.options.some(o => Number(o.subscriber) === Number(params.member) && Boolean(o.on_waiting_list))
  });
}
