/**
 * Analogue to PHP's strip_tags.
 * Do not rely on it for safety.
 * Rely on it to strip tags for textual representation.
 */
function strip_tags (input, allowed) { // eslint-disable-line camelcase
	// discuss at: http://locutus.io/php/strip_tags/
	allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('')
	var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
	var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi
	return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
		return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
	})
}

/**
 * Turns HTML to its textual representation.
 *
 * Allows passing `null` and such non-strings.
 */
const htmlToText = (str) => {
	if (!str) { return ""; }

	return strip_tags(str).replace(/&nbsp;/g, " ").trim();
};
export default htmlToText;
