import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Fetch                 from "qidigo-fetch";
import Logger                from "qidigo-logger";

import Loading               from "qidigo-components/loading";
import UserPhoneNumberPage   from "@app/views/dashboard/phone_number";
import pick                  from "lodash/pick";

/**
 * Ajout ou modification d'un numéro de téléphone.
 *
 * @extends {Component}
 */
class UserPhoneNumberController extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			errors:  false,
			saving:  false,
			confirmDeletion: false,
			phoneNumber: null,
		};
	}

	//
	// Fetch the infos on mount.
	//
	componentWillMount() {
		// (The context is not available before mount.)
		this.fetchUserPhoneNumber();
	}

	fetchUserPhoneNumber() {
		const current = this.getCurrentlyEditing();
		if (current === true) {
			this.setState({loading: false, phoneNumber: {}});

			return;
		}
		const id = parseInt(current);
		
		Fetch.get(`phone_numbers/${id}`)
		.then((response) => {
			const phoneNumber = response;
			this.setState({loading: false, phoneNumber});

			return response;
		})
		.catch(Logger.catcher)
		.catch((err) => this.goToListing());
	}

	/**
	 * From the parameters, get what is currently edited.
	 *
	 * `true` meaning a new phone number.
	 */
	getCurrentlyEditing() {
		if (this.props.id) {
			return parseInt(this.props.id);
		}

		return true;
	}

	saveUserPhoneNumber() {
		const data = pick(this.state.phoneNumber, [
			"country_id",
			"ext",
			"number",
		]);

		if (!data["country_id"]) {
			data["country_id"] = "CA";
		}

		const phoneNumber = {
			["phone_number"]: data,
		};

		this.setState({saving: true});
		const current = this.getCurrentlyEditing();

		(
			current === true ?
			Fetch.post(`users/${this.context.loggedUser.id}/phone_numbers`, phoneNumber) :
			Fetch.patch(`phone_numbers/${current}`, phoneNumber)
		)
		.then((response) => {
			this.setState({saving: false});
			this.goToListing();

			return null;
		})
		.catch(Fetch.handleErrors(this))
		;
	}

	onValueChange(field, e, phoneNumber) {
		this.setState({phoneNumber});
	}
	onValidationError(errors) {
		this.setState({errors});
	}
	onSubmit() {
		this.setState({errors: false});
		this.setState({disabled: true});
		this.saveUserPhoneNumber();
	}

	handleCancelAction(e) {
		e.preventDefault();
		this.goToListing();
	}

	/**
	 * Call it once with `confirmation` falsey, use the set state
	 * to ask for confirmation, then re-call with confirmation truthy.
	 */
	handleDeleteAction(e, confirmation = false) {
		e.preventDefault();

		if (!confirmation) {
			this.setState({saving: true});
			this.setState({confirmDeletion: true});

			return;
		}

		if (confirmation === "cancel") {
			this.setState({saving: false});
			this.setState({confirmDeletion: false});

			return;
		}

		const current = this.getCurrentlyEditing();
		Fetch["delete"](`phone_numbers/${current}`)
		.then((response) => {
			this.setState({saving: false});
			this.goToListing();

			return null;
		})
		.catch((errors) => {
			this.setState({confirmDeletion: false});

			return Promise.reject(errors);
		})
		.catch(Fetch.handleErrors(this))
		;
	}

	goToListing() {
		this.props.goToListing();
	}

	render() {
		const {
			...props
		} = this.props;
		if (this.state.loading) { return <Loading />; }

		const action = this.props.id ? "edit" : "new";

		return <UserPhoneNumberPage
			action={action}
			saving={this.state.saving}
			confirmDeletion={this.state.confirmDeletion}
			phoneNumber={this.state.phoneNumber}
			errors={this.state.errors}
			onChange={(...e)=>this.onValueChange(...e)}
			handleCancelAction={(...e)=>this.handleCancelAction(...e)}
			onSubmit={(...e)=>this.onSubmit(...e)}
			onValidationError={(errors)=>this.onValidationError(errors)}
			handleDeleteAction={(...e)=>this.handleDeleteAction(...e)}
			editedPhoneNumber={this.state.editedPhoneNumber}
			{...props}
		/>;
	}
}

UserPhoneNumberController.contextTypes = {
	loggedUser: PropTypes.object,
};

UserPhoneNumberController.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	goToListing: PropTypes.func.isRequired,
};

export default UserPhoneNumberController;
