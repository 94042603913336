import React, {useState, useEffect, useRef, useCallback}    from "react";
import PropTypes             from "prop-types";
import fromPairs             from "lodash/fromPairs";
import OrganizationLink      from "@app/components/organization_link";
import Carousel              from "qidigo-fancy/carousel";
import HTML                  from "@app/components/html";
import Image                 from "qidigo-components/image";
import SideNav               from "@app/components/side-nav.js";
import Page                  from "@app/components/page.js";
import {path}                from "@app/lib/routes";
import ShareMenu             from "@app/components/share_menu";
import Title                 from "./title";
import Fetch                 from "qidigo-fetch";
import Auth                  from "qidigo-auth";
import SubscribeToOrganizationConfirmationModal from "./_personal_information_consent.js";

import {
    defineMessages,
    FormattedMessage
} from "react-intl";

const translations = defineMessages({
    "submenu.message":     {id: "qidigo.organization.layout.submenu.message", defaultMessage: "Message"},
    "submenu.website":     {id: "qidigo.organization.layout.submenu.website", defaultMessage: "Site web"},
    "submenu.subscribe":   {id: "qidigo.organization.layout.submenu.subscribe", defaultMessage: "M'abonner"},
    "submenu.unsubscribe": {id: "qidigo.organization.layout.submenu.unsubscribe", defaultMessage: "Désabonner"},
    "submenu.share":       {id: "qidigo.organization.layout.submenu.share", defaultMessage: "Partager"},
});
const menuTranslations = defineMessages({
    "memberships":        {id: "qidigo.organization.layout.menu.memberships", defaultMessage: "Abonnements"},
    "activities":         {id: "qidigo.organization.layout.menu.activities", defaultMessage: "Activités"},
    "activities.session": {id: "qidigo.organization.layout.menu.activities.session", defaultMessage: "À la session"},
    "activities.pass":    {id: "qidigo.organization.layout.menu.activities.pass", defaultMessage: "À la carte"},
    "contact":            {id: "qidigo.organization.layout.menu.contact", defaultMessage: "Contact"},
});

/**
 * Présente une page dans la hiérarchie de l'organisme, avec un bandeau
 * omniprésent pour l'organisme.
 */
const OrganizationLayout = ({
    children,
    organization,
    onNewMessage,
    onShare,
    showShare,
    subscribed,
    ...leftOverProps
}, {loggedUser, intl}) => {
    const childProps = Object.assign({organization}, leftOverProps);
    const {formatMessage} = intl;

    // Collection de <img /> utilisée comme un trucage à bas prix
    // permettant de pré-charger les images utilisées dans le carousel.
    const preloadImages = organization.carousel_images.map((img) => {
        return <img
            key={img.id}
            src={img.path}
        />;
    });
    const carouselImages = organization.carousel_images.map((img) => {
        return <Image
            withSpinner={false}
            key={img.id}
            className="organization--carousel--image"
            src={img.path}
        />;
    });

    // Params for URL building.
    const params = {orgID: organization.slug};
    const activitiesMenu = [];
    if (organization["enabled_activities_view"] === "session" || organization["enabled_activities_view"] === "both") {
        const url = path("organization.session", params);
        activitiesMenu.push({key: "activities.session", link: url, translation: menuTranslations["activities.session"]});
    }
    if (organization["enabled_activities_view"] === "pass" || organization["enabled_activities_view"] === "both") {
        const url = path("organization.schedule", params);
        activitiesMenu.push({key: "activities.pass", link: url, otherLinks: [path("organization.calendar", params)], translation: menuTranslations["activities.pass"]});
    }

    // Configuration de l'organisme pour le menu.
    const {menu_options: menuOptions} = organization;

    // Options du menu. L'ordre est conservé.
    const menu = [];
    menu.push({
        key: "activities",
        translation: menuTranslations["activities"],
        sub: activitiesMenu,
    });
    if (menuOptions["enabled"]["memberships"]) {
        menu.push({key: "memberships", link: path("organization.memberships", params), translation: menuTranslations["memberships"]});
    }
    menu.push({key: "contact", link: path("organization.contact", params), translation: menuTranslations["contact"]});

    const nav = <SideNav className="organization-navigation" {...{menu}} />;

    const website = organization["url_website"];

    let subUnsub = null;

    const [subscribed, setSubscribed] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [fetchingSubscriptions, setFetchingSubscriptions] = useState(false)

    const fetchSubscription = useCallback(
        () => {
            // FIXME : trouver un moyen de paralléliser avec le fetch d'organisme.
            if (loggedUser && organization) {
                if (fetchingSubscriptions) { return; }
                setFetchingSubscriptions(true)
                Fetch.get(`users/${loggedUser.id}/email_subscriptions`)
                    .then((response) => {
                        const {subscribed} = response;
                        setSubscribed(subscribed.includes(organization["id"]))
                        setFetchingSubscriptions(false)

                        return response;
                    })
                ;
            }
        },
        [organization, loggedUser, fetchingSubscriptions, setSubscribed, setFetchingSubscriptions],
    )

    const handleSubscription = useCallback(
        () => {
            if (subscribed !== null) {
                setSubscribed(null)
                if (subscribed) {
                    Fetch.delete(`users/${loggedUser.id}/email_subscriptions`, {id: organization["id"]})
                        .then((response) => {
                            setSubscribed(false)
                            return response;
                        })
                        .catch((e) => e.status === 404 ? fetchSubscription(organization) : Promise.reject(e))
                    ;
                }
                else {
                    closeModal();
                    Fetch.post(`users/${loggedUser.id}/email_subscriptions`, {id: organization["id"]})
                        .then((response) => {
                            setSubscribed(true)
                            Auth.refreshUser();

                            return response;
                        })
                        .catch((e) => e.status === 404 ? fetchSubscription(organization) : Promise.reject(e))
                    ;
                }
            }
        },
        [organization, loggedUser, subscribed, fetchSubscription],
    )

    const prevOrganization = useRef(organization)

    useEffect(() => {
        if (loggedUser) {
            if (prevOrganization.current !== organization || subscribed === null) {
                fetchSubscription();
            }
        }
    }, [fetchSubscription, loggedUser, subscribed])

    if (loggedUser) {
        const action = subscribed === null ? "loading" : (subscribed ? "unsubscribe" : "subscribe"); // eslint-disable-line
        const userIsAContactOfThisOrganization = loggedUser.contacts && loggedUser.contacts.some((contact) => {
            return contact.organization_id === organization.id;
        });

        subUnsub =
            <li className={`organization-header--link-${action}`}>
                <a onClick={subscribed || userIsAContactOfThisOrganization ? handleSubscription: openModal}>
                    {action === "loading" ? null : <FormattedMessage {...translations[`submenu.${action}`]} /> }
                </a>
            </li>
            ;
    }

    const url = path("organization", params, {full: true});
    const main_img = organization.carousel_images && organization.carousel_images.length > 0 ? organization.carousel_images[0] : null;
    const {address} = organization;

    const social = fromPairs(organization["urls"].map((url) => [url["type_url"]["name"].toLowerCase(), url]));
    const twitter_creator = social["twitter"] ? ("@" + social["twitter"]["url"].replace(/^https?:\/\/[a-zA-Z0-9.]*twitter.com\//, "")) : null;

    return (
        <Page name="organization">
            <Title organization={organization} />
            <header>
                <div className="organization--cheap-preload">
                    {preloadImages}
                </div>
                <Carousel className="organization--carousel">
                    {carouselImages}
                </Carousel>
                <div className="organization-header--description">
                    { showShare ? <ShareMenu url={url} /> : null }
                    <ul className="organization-header--links">
                        {
                            loggedUser ?
                                <li className="organization-header--link-message">
                                    <a onClick={onNewMessage}><FormattedMessage {...translations["submenu.message"]} /></a>
                                </li> : null
                        }
                        {website ? <li className="organization-header--link-website"><a href={website} target="_blank"><FormattedMessage {...translations["submenu.website"]} /></a></li> : null}
                        {subUnsub}
                        <SubscribeToOrganizationConfirmationModal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            onConfirm={handleSubscription}
                            organization={organization}
                        />
                        <li className="organization-header--link-share">
                            <a onClick={onShare} className={`${showShare ? "active" : ""}`}>
                                <FormattedMessage {...translations["submenu.share"]} />
                            </a>
                        </li>
                    </ul>
                    <h1>
                        <OrganizationLink organization={organization} withLogo={true} />
                    </h1>
                    <HTML contents={organization.description} />
                </div>
            </header>
            <div className="organization-page--layout">
                {nav}
                <section>
                    <div className="organization-page-child">
                        {children && React.cloneElement(children, childProps)}
                    </div>
                </section>
            </div>
        </Page>
    );
};

OrganizationLayout.contextTypes = {
    intl: PropTypes.object,
    loggedUser: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.bool.isRequired]),
};

OrganizationLayout.propTypes = {
    organization: PropTypes.object,
    onNewMessage: PropTypes.func,
    showShare: PropTypes.bool,
    onShare: PropTypes.func,
    subscribed: PropTypes.bool,
};

OrganizationLayout.defaultProps = {
    showShare: false,
    subscribed: null,
};


export default OrganizationLayout;
