import * as React from "react";
import {Component} from "react";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
// @ts-ignore
import {navigate} from "qidigo-router";
// @ts-ignore
import Loading from 'qidigo-components/loading';
import {
    IBillingControllerProps,
    IBillingControllerState,
    ISettlement
} from "../../../views/billing/types";
import qidigoAuth from "../../../modules/qidigo-auth";
// @ts-ignore
import {connect}             from "@app/lib/redux";
// @ts-ignore
import {removeItem, fetchCart}   from "@app/store/actions/cart";
//@ts-ignore
import PropTypes from "prop-types";
//@ts-ignore
import Logger from "qidigo-logger";
import SettlementView from "../../../views/billing/settlement/settlementView";

const PAYMENT_METHOD_IS_INVALID = 'PAYMENT_METHOD_IS_INVALID';
const INSTALLMENT_IS_INVALID = 'INSTALLMENT_IS_INVALID';

export interface ISettlementControllerState extends IBillingControllerState {
    settlement: ISettlement
}

class SettlementController extends Component<IBillingControllerProps, ISettlementControllerState> {
    async componentDidMount() {
        const slug = this.props.organization.slug

        let hasContactError = false
        const contact = await qidigoFetch
            .get(`order/customers?organization=${slug}`)
            .catch((e) => {
                hasContactError = true
            });

        if (hasContactError) {
            this.goBackToInvoices();

            return;
        }

        const userId = qidigoAuth.getUserID();
        let hasSettlementError = false
        let settlement = await qidigoFetch
            .get(`order/customers/${contact.id}/invoices/${this.props.params.invoiceId}`)
            .catch(()=> {
                hasSettlementError = true;
            });

        if (hasSettlementError) {
            this.goBackToInvoices();

            return;
        }

        if (settlement.price_detail.balance === 0) {
            this.goBackToInvoices();

            return;
        }

        const paymentMethods = await qidigoFetch.get(`settlement/customers/${contact.id}/invoices/${settlement.id}/payment-options`);

        let installmentOptionList = [];

        if (settlement.installments.length === 0) {
            installmentOptionList = await qidigoFetch
            .get(`settlement/customers/${contact.id}/invoices/${settlement.id}/installments?total=${settlement.price_detail.balance}`);
        }

        const contactCredit = settlement.company
            ? await this.fetchCompanyCredit(settlement.company.id)
            : await qidigoFetch.get(`customers/${contact.id}/credit`);

        const contactAddressList = await qidigoFetch.get(`users/${userId}/addresses`);

        this.setState({
            contactUuid: contact.id,
            contactSubscribedToMailingList: contact.is_subscribed_to_mailing_list,
            settlement: settlement,
            contactCredit: contactCredit,
            paymentMethods: paymentMethods,
            installmentList: installmentOptionList,
            addressList: contactAddressList,
            isFree: settlement.price_detail.balance === 0,
            creditApplied: 0,
            processingPayment: false,
            shouldSaveMailingList: true,
            contactMailingId: contact.id_for_mail_subscription,
            redirectionTimer: 5,
            selectedInstallmentId: null,
            selectedPaymentMethodId: null
        })
    }

    fetchCompanyCredit = async (companyId: string) => {
        const url = `companies/${companyId}/credit`;
        const init = {
            'method': 'GET',
            'headers': {
                'Qidigo-Organization-Id': this.props.organization.future_id
            }
        };

        let companyCredit = {
            can_use_credit: false,
            credit: 0
        }

        await qidigoFetch.fetch(url, init)
            .then(response => {
                if (response.status === 200) {
                    return response.json().then((body) => {
                        companyCredit = body
                    })
                }
            })

        return companyCredit;
    }

    handleTokenBadRequest = (code: string) => {
        this.setState({
            paymentErrors: code,
            processingPayment: false
        });
    }

    reloadInstallment = async (total: number) => {

        if (this.state.settlement.installments.length > 0) {
            return;
        }

        const installmentList = await qidigoFetch
            .get(`settlement/customers/${this.state.contactUuid}/invoices/${this.state.settlement.id}/installments?total=${total}`);
        this.setState({
            installmentList: installmentList,
        });
    }
    reloadPaymentMethods = async () => {
        const { settlement, contactUuid } = this.state;
        const paymentMethods = await qidigoFetch.get(`settlement/customers/${contactUuid}/invoices/${settlement.id}/payment-options`);
        this.setState({
            paymentMethods: paymentMethods,
        });
    }

    handlePayment = (
        credit: number,
        version: number,
        paymentMethodId: number | null,
        token: string | null,
        installmentId: string | null,
    ) => {
        this.setState({
            processingPayment: true
        })
        //@ts-ignore
        const {dispatch} = this.props;

        const url = `contacts/${this.state.contactUuid}/invoices/${this.state.settlement.id}/settlements`;

        if (token === 'PAYSAFE_BAD_REQUEST') {
            this.setState({
                paymentErrors: token,
                processingPayment: false
            })

            return
        }

        const body = {
                "payment_method_id": paymentMethodId,
                "company_id": this.state.settlement.company ? this.state.settlement.company.id : null,
                "token": token,
                'installment_id': installmentId,
                "credit": credit,
                "invoice_version": version
            }

        const init = {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers' : {
                'Qidigo-Organization-Id': this.props.organization.future_id
            }
        };

        qidigoFetch.fetch(url, init)
            .then(response => {
                if (response.status === 422) {
                    return response.json()
                        .then((body) => {
                            const code = body.error_code === 'INVALID_PARAMETERS'
                                && body['invalid-params']['invoice_version'] !== undefined
                                    ? 'INVALID_VERSION'
                                    : body.error_code;

                            let settlement = Object.assign({}, this.state.settlement);
                            settlement.version = code !== 'INVALID_VERSION'
                                && body.does_payment_passed === false
                                ? this.state.settlement.version + 1
                                : this.state.settlement.version;

                            if (code === PAYMENT_METHOD_IS_INVALID) {
                                this.reloadPaymentMethods();
                            }

                            if (code === INSTALLMENT_IS_INVALID) {
                                const balance = this.state.settlement.price_detail.balance - this.state.creditApplied;
                                this.reloadInstallment(balance);
                            }

                            this.setState({
                                paymentErrors: code,
                                processingPayment: false,
                                settlement: settlement,
                                selectedInstallmentId: installmentId,
                                selectedPaymentMethodId: paymentMethodId
                            })

                            if (body.error_code === 'CART_IS_EMPTY') {
                                this.setState({
                                    processingPayment: true
                                })
                                window.scrollTo(0, 0);
                                dispatch(fetchCart());
                                const interval = setInterval(() => this.updateRedirectionTimer(interval), 1000)
                                return;
                            }
                        });
                }
                if (!response.ok) {
                    return response.json()
                        .then(() => {
                            Logger.catcher;
                            let settlement = Object.assign({}, this.state.settlement);
                            this.setState({
                                paymentErrors: 'UNEXPECTED_ERROR',
                                processingPayment: false,
                                settlement: settlement
                            })
                        });
                }

                if (!this.state.contactSubscribedToMailingList 
                    && this.state.shouldSaveMailingList)
                {
                    qidigoFetch.post(`users/${this.state.contactMailingId}/email_subscriptions`,
                     {id: this.props.organization.id});
                }

                const slug = window.location.pathname.split('/', 3)[2];
                window.location.href = `/u/${slug}/invoices/${this.props.params.invoiceId}/show?from=settlement`;
        });
    }

    updateRedirectionTimer(interval) {
        if (this.state.redirectionTimer <= 1) {
            clearInterval(interval)
            this.goBack()
        }

        this.setState({
            redirectionTimer: this.state.redirectionTimer - 1
        })
    }

    goBack = () => {
        const slug = window.location.pathname.split('/', 3)[2];
        navigate("/u/" + slug);
    }

    goBackToInvoices = () => {
        navigate("/dashboard/invoices");
    }

    applyCredit = async (creditApplied: number) => {
        const balance = this.state.settlement.price_detail.balance - creditApplied;
        const isFree = balance === 0;
        isFree ? this.setState({installmentList: []}) : await this.reloadInstallment(balance);

        this.setState({
            isFree: isFree,
            creditApplied: creditApplied
        });
    }

    render() {
        if (!this.state) {
            return <Loading/>;
        }

        return (
            <SettlementView
                settlement={this.state.settlement}
                contactCredit={this.state.contactCredit}
                paymentMethods={this.state.paymentMethods}
                installmentList={this.state.installmentList}
                addressList={this.state.addressList}
                handleTokenBadRequest={this.handleTokenBadRequest}
                handlePayment={this.handlePayment}
                isFree={this.state.isFree}
                applyCredit={this.applyCredit.bind(this)}
                paymentErrors={this.state.paymentErrors}
                processingPayment={this.state.processingPayment}
                organizationName={this.props.organization.name}
                organizationUuid={this.props.organization.future_id}
                redirectionTimer={this.state.redirectionTimer}
                invoiceId={this.props.params.invoiceId}
                selectedInstallmentId={this.state.selectedInstallmentId}
                selectedPaymentMethodId={this.state.selectedPaymentMethodId}
            />
        )
    }
}

//@ts-ignore
SettlementController.propTypes = {
    dispatch: PropTypes.func,
};

export default connect()(SettlementController);
