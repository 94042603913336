import React, {Component}    from "react";
import PropTypes             from "prop-types";
import debounce              from "lodash/debounce";
import {
	FormattedMessage,
	defineMessages,
} from "react-intl";
import shallowEqual          from "shallowequal";
import Loading               from "qidigo-components/loading.js";
import AppError              from "@app/components/applicationerror.js";
import ActivitiesList        from "./activitieslist";
import Logger from "qidigo-logger";
import Fetch from "qidigo-fetch";

const messages = defineMessages({
	noResults: {
		id: "qidigo.activities.no_results",
		defaultMessage: "Aucun résultat."
	},
	noResultsQuery: {
		id: "qidigo.activities.no_results_with_query",
		defaultMessage: "Aucun résultat pour « {q} »."
	},
});

// Utilisé pour métaprogrammer la synchronisation de props to state.
// Ce sont généralement les termes ou paramètres qui affectent la recherche.
const SEARCH_PARAMS = [
	"page",
	"organization",
	"q",
	"selectedCategories",
];

/**
 * Component de recherche d'activités.
 *
 * @extends {Component}
 */
class Activities extends Component {
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		const {currentLocale} = this.context;
		if (currentLocale !== nextContext.currentLocale) {
			return true;
		}

		if (!shallowEqual(this.props, nextProps)) {
			return true;
		}
		if (!shallowEqual(this.state, nextState)) {
			return true;
		}

		return false;
		
	}
	constructor() {
		super();
		// Accès direct à doFetchActivities, sans debounce.
		this.directDoFetchActivities = this.doFetchActivities;
		// Debounce pour éviter d'envoyer chaque lettre à l'API
		this.doFetchActivities = debounce(this.doFetchActivities, 200);
		this.state = {
			loading: true,
			error:   false,

			// Paramètres de recherche par défaut.
			page:         1,
			q:            "",
			organization: null,
			selectedCategories: [],
		};
	}

	/**
	 * Demande un fetch d'activités (API).
	 * Met aussi l'état de chargement.
	 */
	fetchActivities() {
		this.setState({loading: true});
		this.doFetchActivities();
	}

	/**
	 * Effectue un requête pour trouver les activités
	 * d'un organisme, c'est la partie interne de l'action de chargement.
	 *
	 * fetchActivities est directe, mais celle-ci est débouncée.
	 */
	doFetchActivities() {
		const params = {
			organizationId: this.state.organization,
			selectedCategories: this.state.selectedCategories,
			page: this.state.page,
			query: this.state.q,
			activitiesPerPage: 10,
		};

		const {categories} = this.props;

		if (params.page < 1) {
			params.page = 1;
		}

		let additionalParams = [];
		additionalParams.push(`activities_per_page=${params.activitiesPerPage}`);
		additionalParams.push(`page=${params.page}`);

		if (params.query.length > 0 ) {
			additionalParams.push(`query=${params.query}`);
		}

		if (params.selectedCategories && params.selectedCategories.length > 0) {
			Object.values(params.selectedCategories).forEach(function(category) {
				if (categories[category]) {
					additionalParams.push(`categories[]=${categories[category]}`);
				}
			});
		}

		Fetch.get(`organizations/${params.organizationId}/activities` + (additionalParams.length === 0 ? '' : `?${additionalParams.join('&')}`))
			.then(response => {
				const activities =  response.items;
				const categories = response.categories;
				const nbPages = Math.ceil(response.total_size / params.activitiesPerPage);
				this.props.onNumberOfPagesChanged(nbPages);

				if (categories && this.props.onCategoriesChanged) {
					this.props.onCategoriesChanged(categories);
				}

				this.setState({loading: false, activities: activities});
			})
			.catch((err) => {
				Logger.error(err);
				this.setState({loading: false, error: err});

				return Promise.reject(err);
			});
	}

	/**
	 * Copie les propriétés dans le state.
	 * Fetch les activités.
	 */
	componentWillMount() {
		for (let param of SEARCH_PARAMS) {
			if (this.props[param]) {
				this.setState({[param]: this.props[param]});
				// Doit muter le state parce qu'il ne sera pas frais pour le doFetchActivities() qui suit.
				this.state[param] = this.props[param]; // eslint-disable-line
			}
		}
		this.directDoFetchActivities();
	}

	/**
	 * Copie les propriétés dans le state.
	 * Fetch les activités au besoin.
	 */
	componentDidUpdate(prevProps, prevState) {
		let fetch = false;
		for (let param of SEARCH_PARAMS) {
			if (Array.isArray(prevState[param]) &&
				Array.isArray(this.state[param]) &&
				(prevState[param].length !== this.state[param].length ||
				!prevState[param].every((val, index) => val === this.state[param][index]))) {
				fetch = true;
			} else if ((!Array.isArray(prevState[param]) || !Array.isArray(prevState[param])) && prevState[param] !== this.state[param]) {
				fetch = true;
			}
		}

		if (fetch) {
			this.fetchActivities();
		}
	}

	/**
	 * Synchronise l'état avec les propriétés.
	 */
	componentWillReceiveProps(newProps) {
		for (let param of SEARCH_PARAMS) {
			if (newProps[param] !== undefined && this.props[param] !== newProps[param]) {
				this.setState({[param]: newProps[param]});
			}
		}
	}

	render() {
		if (this.state.error) {
			if (process.env.NODE_ENV === "development") {
				return <AppError message={this.state.error.toString()} />;
			}
			else {
				// 0167 à cause du numéro de ligne à la conception.
				return <AppError code="#AL-0167" />;
			}
		}

		let child = null;
		let loading = null;

		// Si c'est le chargement initial, loading régulier, autrement un loading
		// en overlay par-dessus les résultats déjà existants.
		if (this.state.loading) {
			loading = <Loading className={this.state.activities?"style-overlay":""} />;
		}

		let className = [this.props.className, "activitieslist-shared"].join(" ");

		// Quand on a des résultats, on doit les placer dans le tree
		if (this.state.activities) {
			// Il y a des activités
			if (this.state.activities.length > 0) {
				child = <ActivitiesList
					activities={this.state.activities}
				/>;
			}
			// Aucune activité.
			else {
				let msg = messages.noResults;
				if (this.state.q && this.state.q !== "") {
					msg = messages.noResultsQuery;
				}
				child =
					<div className="activities is-empty">
						<FormattedMessage {...msg}
							values={this.state}
						/>
					</div>
				;
			}
		}

		return (
			<div className={className}>
				{loading}
				{child}
			</div>
		);
	}
}

Activities.propTypes = {
	className:    PropTypes.string,
	organization: PropTypes.number,
	q:            PropTypes.string,
	attributes:   PropTypes.object,
	page:         PropTypes.number.isRequired,
	onNumberOfPagesChanged: PropTypes.func.isRequired,
	onCategoriesChanged:    PropTypes.func,
};

Activities.defaultProps = {
	attributes: {},
};

Activities.contextTypes = {
	currentLocale: PropTypes.string,
};

export default Activities;
