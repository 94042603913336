import React, {Component}    from "react";
import View                  from "@app/views/activity/ActivityContactView.js";

class ActivityContactController extends Component {
	render() {
		const {organization, activity} = this.props;

		return (
			<View organization={organization} activity={activity} />
		);
	}
}

export default ActivityContactController;
