/* global require */
/* eslint no-console: [0] */
import "./polyfills.js";
import "./modernizr-config.js";
import React from "react";
import ReactDom from "react-dom";
import {Provider} from "react-redux";
import * as Sentry           from "@sentry/browser";
import Internationalizable from "qidigo-i18n";

console.log("→ qidigo-boot/index.js");
if (process.env.NODE_ENV === "development") {
	console.info("[Qidigo] Démarrage...");
	console.log(`[Qidigo] en mode: ${process.env.NODE_ENV}`);
	// On peut ajouter des choses particulières ici si nécessaire.
}

export default function boot({element, container, ErrorsController}) {
	console.timeStamp("qidigo-boot, start");
	console.info("[Qidigo] DOM prêt...");
	console.info("[Qidigo] Cramponnage de React...");

	// Flag pour locker la gestion d'erreurs.
	let handlingError = false;

	// Handler qui "brise" l'app complètement lorsqu'il y a une erreur critique.
	const handleError = (error, where) => {
		// Pour ne gérer que la première erreur.
		// Les suivantes sont *probablement* causée par la première.
		if (!handlingError) {
			handlingError = true;
			if (process.env.NODE_ENV === "development") {
				// Print des infos dans la console.
				console.log("--------------------------------------------------");
				console.group("%c💣 Uncaught error", "color: #990000; font-size: 1.8em;");
				console.log(`Error type (caught where?): ${where}`);
				console.log("Raw error print follows");
				console.error(error);
				console.groupEnd();
				console.log("--------------------------------------------------");

				// Démarre un groupe pour toutes les erreurs suivantes...
				console.info("Following errors are probably irrelevant.");
				console.groupCollapsed("Further following errors...");
			}
			else {
				// TODO : Logger les erreurs dans un service quelconque.
				// Lorsque ce sera fait, utiliser aussi les infos de hash/version
				// `@revision: ${env.GIT_COMMIT} / ${env.GIT_REVCOUNT}`
			}
			// Render avec le ErrorsController passé
			try {
				ReactDom.render(<Provider><Internationalizable><ErrorsController error={error} /></Internationalizable></Provider>, container);
			}
			catch (e) {
				console.groupEnd()
				console.log("--------------------------------------------------");
				console.log("Unexpected error in the handler:");
				throw e;
			}
		}
	};

	// Écoutons les erreurs qui ne sont pas gérées.
	window.onerror = (messageOrEvent, source, lineno, colno, error) => {
		if (error) {
			handleError(error, "onerror");
		}
	};

	// Et les promises qui failent sans être gérées.
	window.addEventListener("unhandledrejection", (rejection) => {
		if (!handlingError) {
			// Lock la gestion d'erreurs.
			// Autrement, par async, on pourrait avoir une autre
			// erreur qui se glisse avant de la traiter.
			handlingError = true;

			// Log sur sentry.
			Sentry.captureException(rejection.reason);

			// Allons handler la promise...
			rejection.promise.catch((error) => {
				// Unlock pour la fonction.
				handlingError = false;
				handleError(error, "unhandledrejection");
			});
		}
	});


	// Effectue le render initial.
	ReactDom.render(element, container);

	const el = document.body;
	[`env-${process.env.NODE_ENV}`, "is-booted"].map((className) => {
		if (el.classList) { el.classList.add(className); }
		else { el.className += " " + className; }
	});

	console.info("[Qidigo] Fin du démarrage.");
	console.timeStamp("qidigo-boot, end");
}
