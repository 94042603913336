import React                 from "react";
import PropTypes             from "prop-types";

const LocalModal = (props) =>
	<section className={`local-modal ${props.className}`}>
		<div className="local-modal--outer">
			<div className="local-modal--inner">
				{props.children}
			</div>
		</div>
	</section>
	;

LocalModal.propTypes = {
	className: PropTypes.string,
};

export default LocalModal;
