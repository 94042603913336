import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "qidigo-form/input";
import FetchFile from "qidigo-fetch-file";

/**
 * Template for a question of type file.
 */

class FileTypeForm extends Component {

  handleChange(field, event, value) {
    const returnValue = Object.assign({}, this.props.answer);
    returnValue[field] = value;
    if (this.props.onChange) {
      this.props.onChange(event, returnValue);
    }
  }

  download_file = () => {
    var newWindow = window.open(); 
    const init = {
      headers:  {
        "Accept": this.props.answer.mime_type,
      },
      responseType: 'arraybuffer'
    }
    FetchFile.get(`forms/${this.props.formId}/download_file?qId=${this.props.question.id}`, init)
    .then(response => {
      if (response.ok) {
          return response.blob();
      }
    })
    .then(blob => {
      const data = window.URL.createObjectURL(blob);
      newWindow.location = data;
    })
  }

  render() {
    const {
      className,
      questionName,
      answer,
      errorMessage,
      errors,
    } = this.props;

    return (
      <div className={className}>
        {questionName}
        {errorMessage}
        <div>
          <Input
            accept="image/*,.pdf,.docx,.doc,.xls,.xlsx" 
            error={errors["file"]}
            onChange={(...e) => this.handleChange("file", ...e)}
            type="file"
          />
          <p>Les fichiers téléchargés sont limités aux formats <b>Images, PDF, Word, Excel </b> et de taille maximale de <b>25mb</b></p>
          <a href="javascript:;" onClick={this.download_file}>{answer.file_name}</a>
        </div>
      </div>
    );
  }
}

FileTypeForm.defaultProps = {
  className: "",
};

FileTypeForm.propTypes = {
  className: PropTypes.string,
  question: PropTypes.object,
  questionName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  answer: PropTypes.object,
  errors: PropTypes.object,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formId: PropTypes.string
};

export default FileTypeForm;
