/**
 * Fake dummy event.
 *
 * Some features:
 *
 *   * It *can* be `defaultPrevented` via `preventDefault()`.
 *   * A target can be attached to it.
 *
 */
class QEvent {
	/**
	 * @param {} target Target of the event.
	 */
	constructor(target) {
		this.defaultPrevented = false;
		this.target = target;
	}
	/**
	 * Prevents the default action from executing, assuming
	 * the other part of the code verifies `e.defaultPrevented`.
	 */
	preventDefault() {
		this.defaultPrevented = true;
	}
}

export default QEvent;
