import React, {Component} from "react";
import PropTypes from "prop-types";
import Button from "qidigo-components/button";
import {defineMessages} from "react-intl";
import {addClass, removeClass} from "@app/lib/class";

const messages = defineMessages({
	close: {id: "qidigo.overlay.close", defaultMessage: "Fermer"},
});

class OverlayLayout extends Component {
	toggleClass(withOverlay) {
		const {body} = document;
		if (withOverlay) {
			addClass(body, "with-overlay");
		}
		else {
			removeClass(body, "with-overlay");
		}
	}

	componentDidMount() {
		this.toggleClass(true);
	}

	componentWillUnmount() {
		this.toggleClass(false);
	}

	render() {
		const {children, close} = this.props;
		const {intl: {formatMessage}} = this.context;

		//
		// There is one additional wrapper around background/wrapper since we need to return
		// *only one* element, and cannot return multiple elements.
		//
		//    overlay
		//    |
		//    +-> background
		//    +-> wrapper
		//        |
		//        +-> layout
		//            |
		//            +-> button
		//            +-> contents
		//
		return (
			<div className="overlay">
				<div className="overlay-layout__background" onClick={() => close()}></div>
				<div className="overlay-wrapper">
					<div className="overlay-layout">
						<Button
							className="button-unstyled button-close overlay-close"
							onClick={() => close()}
							role="button"
							title={formatMessage(messages.close)}
							aria-label={formatMessage(messages.close)}
						>
							×
						</Button>
						<section className="overlay-contents">
							{children}
						</section>
					</div>
				</div>
			</div>
		);
	}
}

OverlayLayout.propTypes = {
	close: PropTypes.func.isRequired,
};

OverlayLayout.contextTypes = {
	intl: PropTypes.object,
};

export default OverlayLayout;
