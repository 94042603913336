import MiniSignal            from "mini-signals"; // eslint-disable-line
import * as Sentry           from "@sentry/browser";
import Cookies               from "js-cookie";
import {navigate}            from "qidigo-router";
import BackLocation          from "qidigo-router/back_location";
import Fetch                 from "qidigo-fetch";
import moment                from "moment";
import env                   from "qidigo-env";
import {setLocale}           from "qidigo-i18n";

const TOKEN_COOKIE = "qidigo-auth-token";
const REMEMBER_ME_DAYS = 1;

// Constants to use to represent those states.
export const INVALID_PASSWORD = "invalid_password";
export const INVALID_USER     = "invalid_user";
export const OTHER_ERROR      = "other_error";

/**
 * Gère l'authentification et les callbacks lors de certains événements.
 */
class QidigoAuth {
	_onChangeSignal = new MiniSignal();
	// Cache of the current user.
	_currentUser = null;

	currentUserLoggedInCall = null

	constructor() {
		// Au boot de l'application aussi il faut idéalement forcer
		// le login legacy. Autrement il pourrait y avoir une différence
		// sur les valeurs valables pour la durée de l'identification.
		// window.setTimeout() est utilisé pour *sortir* du constructeur.
		// Autrement Fetch() n'aura pas la référence à Auth pour getToken().
		// (Messy, mais safe)
		if (this.getToken()) {
			window.setTimeout(() => {
				this.userLoggedIn()
					.then((user) => {
						// Call temporaire pour le bridge legacy
						this.legacyLogin();
						if (user) {
							this.setupSentry({
								id: user.id,
								email: user.email
							});
						}
					});
			}, 100);
		}
	}

	/**
	 * Effectue un login sur l'application *legacy*.
	 *
	 * Il est possible de le faire quand on a un JWT uniquement, donc avant même
	 * d'avoir les informations de `/whoami`.
	 *
	 * (Ce code est un tiers du protocole de login new → legacy.)
	 */
	legacyLogin() {
		Fetch.post("legacy_auth")
		.then((response) => {
			const {nonce} = response;

			const {body} = window.document;

			// Crée un iframe où on fera un post à partir d'un form.
			const iframe = document.createElement("iframe");
			iframe.setAttribute("style", "display:none");
			iframe.setAttribute("id", "legacy-login-frame");
			iframe.setAttribute("name", "legacy-login-frame");
			body.appendChild(iframe);
			if (navigator.appVersion.indexOf("MSIE")!=-1) {
				// https://www.kochan.io/javascript/how-to-dynamically-create-an-iframe.html
				iframe.src = 'javascript:void((function(){var script = document.createElement(\'script\');' +
					'script.innerHTML = "(function() {' +
					'document.open();document.domain=\'' + document.domain +
					'\';document.close();})();";' +
					'document.write("<head>" + script.outerHTML + "</head><body></body>");console.log("IEok")})())';
			}

			// Crée un formulaire qui sera POSTé dans le iframe.
			const form = document.createElement("form");
			const host = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "";
			form.setAttribute("method", "post");
			form.setAttribute("action", host + "/from-api/login");
			form.setAttribute("style", "display:none");
			form.setAttribute("id", "legacy-login-form");
			form.setAttribute("target", "legacy-login-frame");
			body.appendChild(form);

			const opts = {};
			opts["user_id"] = this.getUserID();
			opts["nonce"] = nonce;
			Object.keys(opts).map((id) => {
				const input = document.createElement("input");
				input.setAttribute("type", "hidden");
				input.setAttribute("id", id);
				input.setAttribute("name", id);
				input.setAttribute("value", opts[id]);
				form.appendChild(input);
			});

			// Prépare l'event de chargement pour le post prochain...
			iframe.addEventListener("load", function() {
				try {
					// On lit le frame.
					const response = this.contentDocument || this.contentWindow.document;
					// Parse le "textContent" de l'élément DOM (interprété) de la réponse.
					const content = JSON.parse(response.getElementsByTagName("body")[0].textContent);
					if (!content || !content["status"] || content["status"] !== "OK") {
						/* eslint-disable */
						console.warn("--------------------------------------------------");
						console.warn("Issue with new → legacy login bridge...");
						console.warn("response: ", response);
						console.warn("content: ", content);
						console.warn("--------------------------------------------------");
						/* eslint-enable */
					}
				}
				catch (e) {
					console.warn("--------------------------------------------------");
					console.warn("Issue with new → legacy login bridge...");
					console.warn(e);
					console.warn("--------------------------------------------------");
				}

				// Finalement, un ménage.
				body.removeChild(iframe);
				body.removeChild(form);
			});
			iframe.addEventListener("error", function() {
				/* eslint-disable */
				console.warn("--------------------------------------------------");
				console.warn("Issue with new → legacy login bridge...");
				console.warn("Error event listener triggered.");
				console.warn("--------------------------------------------------");
				/* eslint-enable */
			});

			// Finalement, POST!
			form.submit();
		})
		.catch((...err) => console.error(err))
		;
	}

	legacyLogout() {
		const {body} = window.document;

		// Crée un iframe où on fera un post à partir d'un form.
		const iframe = document.createElement("iframe");
		iframe.setAttribute("style", "display:none");
		iframe.setAttribute("id", "legacy-logout-frame");
		iframe.setAttribute("name", "legacy-logout-frame");
		body.appendChild(iframe);
		if (navigator.appVersion.indexOf("MSIE")!=-1) {
			// https://www.kochan.io/javascript/how-to-dynamically-create-an-iframe.html
			iframe.src = 'javascript:void((function(){var script = document.createElement(\'script\');' +
				'script.innerHTML = "(function() {' +
				'document.open();document.domain=\'' + document.domain +
				'\';document.close();})();";' +
				'document.write("<head>" + script.outerHTML + "</head><body></body>");console.log("IEok")})())';
		}

		// Crée un formulaire qui sera POSTé dans le iframe.
		const form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", "/from-api/logout");
		form.setAttribute("style", "display:none");
		form.setAttribute("id", "legacy-logout-form");
		form.setAttribute("target", "legacy-logout-frame");
		body.appendChild(form);

		// Prépare l'event de chargement pour le post prochain...
		iframe.addEventListener("load", function() {
			// Pas de réponse dans le logout...
			// Donc, un ménage.
			body.removeChild(iframe);
			body.removeChild(form);
		});
		iframe.addEventListener("error", function() {
			/* eslint-disable */
			console.warn("--------------------------------------------------");
			console.warn("Issue with new → legacy logout bridge...");
			console.warn("Error event listener triggered.");
			console.warn("--------------------------------------------------");
			/* eslint-enable */
		});

		// Finalement, POST!
		form.submit();
	}

	/**
	 * Ajoute un listener sur le changement d'état logged-in/logged-out.
	 */
	onChange(cb) {
		return this._onChangeSignal.add(cb);
	}

	/**
	 * Tente un login.
	 *
	 * Donne une promise qui retourne le token JWT lorsque correct, en notifian
	 * correctement les listeners.
	 *
	 * Le reject du promise donne un code d'erreur en string pour le type d'erreur.
	 */
	login(email, password, {remember = true}) {
		return Fetch.post("authenticate", {auth: {email, password}})
		.then((response) => {
			const {jwt} = response;
			this.setToken(jwt, remember);
			this.legacyLogin();

			return jwt;
		})
		.catch((response) => {
			let error = OTHER_ERROR;
			if      (response.status === 404) { error = INVALID_USER; }
			else if (response.status === 403) { error = INVALID_PASSWORD; }

			return Promise.reject(error);
		})
		;
	}

	/**
	 * Shim that ensures housekeeping is done on social login.
	 */
	socialLogin() {
		// This ensures legacyLogin is an implementation detail that doesn't leak
		// in the frontend code.
		return this.legacyLogin();
	}

	/**
	 * Efface les informations de session.
	 *
	 * **À noter**: Le token reste techniquement valide.
	 * S'il est jugé nécessaire de l'invalider, il faudra ajouter une requête
	 * au serveur qui sert à effectuer un *logout*. (`DELETE /sessions`)
	 *
	 * Il s'agit d'un promise pour assurer un *logout* complet, cas échéant.
	 */
	logout() {
		return new Promise((resolve, reject) => {
			this.currentUserLoggedInCall = null
			this.setToken(null);
			this.legacyLogout();

			return resolve(true);
		});
	}

	setupSentry({
		id,
		email
	}) {
		Sentry.setUser({
			id: id,
			email: email,
		})
	}

	/**
	 * Force un refresh de l'utilisateur.
	 *
	 * Cette fonction cause des effets réactifs; c'est-à-dire que l'application
	 * rafraîchira automatiquement les informations "globales".
	 */
	refreshUser() {
		return this.userLoggedIn(true).then((user) => {
			this._onChangeSignal.dispatch(user);
			if (user) {
				this.setupSentry({
					id: user.id,
					email: user.email
				});
			}

			return user;
		});
	}

	/**
	 * Donne l'utilisateur connecté ou false.
	 *
	 * L'utilisateur connecté est un objet minimal des informations
	 * globales nécessaires pour l'utilisateur. Voir `whoami` de l'API.
	 */
	userLoggedIn(refresh = false) {
		refresh = !!refresh; // Valeur par défaut à false.

		if (this.currentUserLoggedInCall) {
			return this.currentUserLoggedInCall
		}

		const promise = new Promise((resolve, reject) => {
			// Pour vérifier une invalidation de session.
			// (Réduit les effets néfastes d'un logout)
			var origToken = this.getToken();

			// Si un token de session existe,
			if (origToken) {
				// On vérifie d'abord le cache
				if (!refresh && this._currentUser) {
					return resolve(this._currentUser);
				}

				// Sinon on demande qui on est.
				return Fetch.get("whoami")
				.then((response) => {
					// On a une réponse provenant d'un token différent?
					if (origToken !== this.getToken()) {
						// On recommence!
						return resolve(this.userLoggedIn());
					}

					if (response["incomplete"]) {
						navigate("/registration/info", {
							state: {backLocation: BackLocation.getLastLocation()},
						});
					}

					const {new_token, webapp_git_revcount: revCount, ...user} = response;

					// Le serveur nous conseille de refresher l'app?
					// FIXME : déplacer ailleurs que dans auth...
					// FIXME : Ajouter un check pour éviter de refresher en loop infinie si y'a de quoi qui fuck.
					if (process.env.NODE_ENV !== "development") {
						if (revCount && env.GIT_REVCOUNT < revCount) {
							console.info("→ webapp refresh..."); // eslint-disable-line
							window.location.reload(true);
						}
					}

					// Le serveur nous conseille de refresher le token?
					if (new_token) {
						this.setToken(new_token);
					}

					// Ajoute un delta entre "user time" et "server time".
					// Ce delta EST affecté par les délais divers entre la génération et la consommation.
					user.server_delta = moment().diff(moment(user.server_time));
					if (user && user.id) {
						this._currentUser = user;

						return resolve(user);
					}
					else {
						this._currentUser = false;

						return resolve(false);
					}
				})
				.catch((err) => {
					return reject(err);
				})
				;
			}
			else {
				return resolve(false);
			}
		})
			.finally(() => {
				this.currentUserLoggedInCall = null
			})

		this.currentUserLoggedInCall = promise
		return promise
	}

	/**
	 * Place le token dans le stockage.
	 *
	 * (Le token est retiré avec une valeur *falsey*.)
	 */
	setToken(token, remember = false) {
		// Remove cache, it will be refreshed on login.
		this._currentUser = null;
		if (token) {
			const opts = {};
			if (remember) {
				opts.expires = REMEMBER_ME_DAYS;
			}
			opts.sameSite = process.env.NODE_ENV ? 'Lax' : 'none';
			opts.secure = process.env.NODE_ENV !== "development";
			Cookies.set(TOKEN_COOKIE, token, opts);
		}
		else {
			Cookies.remove(TOKEN_COOKIE);
		}

		return this.refreshUser()
			.then((result) => {
				if (result && result["lang_id"]) {
					setLocale(result["lang_id"].toLowerCase());
				}
			})
		;
	}

	/**
	 * Donne le token de l'utilisateur actif, s'il y en a un.
	 */
	getToken() {
		const token = Cookies.get(TOKEN_COOKIE);
		if (token) {
			const encoded = token.split(".")[1].replace("-", "+").replace("_", "/");
			const {exp} = JSON.parse(window.atob(encoded));
			if (exp * 1000 < Date.now() ) {
				this.setToken(null);

				return null;
			}
		}

		return token;
	}

	/**
	 * Recherche l'identifiant utilisateur dans le token JWT.
	 *
	 * (Ne fait aucune validation.)
	 */
	getUserID() {
		const token = this.getToken();
		const encoded = token.split(".")[1].replace("-", "+").replace("_", "/");
		const {sub} = JSON.parse(window.atob(encoded));

		return sub;
	}

	/**
	 * Gère l'authentification avant d'accéder à une route.
	 *
	 * S'utilise avec `react-router` comme suit:
	 *
	 *     <Route path="profil"
	 *         component={DashboardBase}
	 *         onEnter={QidigoAuth.handleAuth}
	 *     >
	 *
	 * (Cette fonction est bindée à l'instance de QidigoAuth)
	 */
	handleAuth = (nextState, replace, next) => {
		// 3 params → react-router va attendre qu'on appelle `next()`

		// Commence avec la promise,
		this.userLoggedIn().then((result) => {
			// Si on est loggé in, continuons.
			if (result) { next(); }
			else {
				// Autrement, on redirige au login avec l'URL de retour
				replace({
					pathname: "/login",
					state: {backLocation: nextState.location}
				});
				// N'oublions pas de débloquer!
				next();
			}

			return result;
		});
	}
}

if (process.env.NODE_ENV === "development") {
	/* eslint-disable */
	console.log("Adding authentication development helpers...");
	if (typeof window !== "undefined") {
		if (!window.qidigo) { window.qidigo = {}; }
		if (!window.qidigo.auth) { window.qidigo.auth = {}; }
		window.qidigo.auth.instance = instance;

		window.qidigo.auth.impersonate = (id) => {
			console.log(`> Will try impersonating ID ${id}...`);
			Fetch.post("authenticate/impersonate", {auth: {id}})
				.then((response) => {
					console.log(`> ... Successfully impersonated user ID ${id}.`);
					const {jwt} = response;
					instance.setToken(jwt);
					instance.legacyLogin();

					return jwt;
				});
		};
	}
	/* eslint-enable */
}

const instance = new QidigoAuth();
export default instance;
