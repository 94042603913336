import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import isEqual               from "lodash/isEqual";
import last                  from "lodash/last";
import enumerate             from "@app/lib/enumerate";
import moment                from "moment";
import upperFirst from "lodash/upperFirst";

const translations = defineMessages({
	timespan: {id: "qidigo.groups.schedule.timespan", defaultMessage: "{from} à {to}", description: "9:00 à 11:00"},
	variable: {id: "qidigo.groups.schedule.variable", defaultMessage: "horaire variable"},
	all_day: {id: "qidigo.groups.schedule.all_day", defaultMessage: "toute la journée"},

});

/**
*/
const ScheduleFragment = (props, context) => {
	const {
		className,
		times,
		variableLabel,
		isAllDay,
		firstToUpper = false
	} = props;
	const classes = [className, "schedulefragment"];
	const {formatMessage} = context.intl;

	if (!times || Object.keys(times).length === 0) {
		return (
			<ul className={classes.join(" ")}>
				<li >
					{
						variableLabel ? variableLabel :
						<FormattedMessage {...translations["variable"]} />
					}
				</li>
			</ul>
		);
	}

	// Groups similar time slots. 
	const groupedTimeSlots = Object.values(times).reduce(
		(accumulator, current)=>
		{
		  if (accumulator.length === 0) {
			return accumulator.concat([[current]]);
		  }
		  if (isEqual(last(last(accumulator))["hours"], current["hours"]) &&
		  current["day"] -1 === last(last(accumulator))["day"]) {
			return accumulator.slice(0, -1).concat([last(accumulator).concat([current])]);
		  } else {
			return accumulator.concat([[current]]);
		  }
		}, []);

	return (
		<ul className={classes.join(" ")}>
			{groupedTimeSlots.map((group,index)=>
				<li key={index}>
					{
						firstToUpper
							? upperFirst(moment.weekdays(group[0].day))
							: moment.weekdays(group[0].day)
					}
					{group.length > 1 ? " — " + moment.weekdays(last(group).day) : null}
					{" "}
					{
						isAllDay
							? <FormattedMessage {...translations["all_day"]} />
							: enumerate(
									group[0].hours.map((arr) => {
									const from = moment(arr[0], "HH:mm").format(moment.locale() === 'fr' ? "HH[h]mm" : "LT");
									const to   = moment(arr[1], "HH:mm").format(moment.locale() === 'fr' ? "HH[h]mm" : "LT");

									return (
										formatMessage(
											{...translations["timespan"]},
											{
												from, to
											}
										)
									);
								}), formatMessage)
					}
				</li>
			)}
		</ul>
	);
};

ScheduleFragment.contextTypes = {
	intl: PropTypes.object,
};

ScheduleFragment.propTypes = {
	className: PropTypes.string,
	times: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
	variableLabel: PropTypes.node,
	isAllDay: PropTypes.bool,
	firstToUpper: PropTypes.bool
};

export default ScheduleFragment;
