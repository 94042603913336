/*
 ███████████████████████████████████████████████████████████████████████████
 █
 █ ░░░░░░░░░░░░░░░░░
 █ ░█▀█░█▀█░█░█░█▀▀░
 █ ░█▀▀░█░█░█▀▄░█▀▀░
 █ ░▀░░░▀▀▀░▀░▀░▀▀▀░
 █ ░░░░░░░░░░░░░░░░░
 █
 █ *Poke your way through the layers and grab whatever you feel is needed.*
 █
 █
 ███████████████████████████████████████████████████████████████████████████
 █
 █ Introduction
 █ ¯¯¯¯¯¯¯¯¯¯¯¯
 █
 █ Poke is library which sole purpose is to remove the need to program
 █ the whole chain of props from container to presentational components.
 █
 █ Container components publish themselves and a collection of props.
 █
 █ Presentational components can poke through the multiple layers and
 █ grab the props needed, wherever they are used.
 █
 █ This removes the need to pass through a metrics ass-load of props
 █ deeply into the presentational components. This brings abstraction
 █ of the props waterfall to partials.
 █
 █
 ███████████████████████████████████████████████████████████████████████████
 █
 █ Usage
 █ ¯¯¯¯¯
 █
 █ Using `pokable(Component, "identifier");` will wrap into a pre-made HOC
 █ allowing easy access through a defined API to their properties.
 █
 █ It is also possible to directly respond to the same internal context
 █ API instead of using `pokable` but it is advised against.
 █
 █ To make use of the exported props, use `poke` to "poke-through" to the
 █ props held by the given identifier.
 █
 █     poke(Component, "identifier", ["props"])
 █
 █
 ███████████████████████████████████████████████████████████████████████████
 */

import React from "react";
import PropTypes from "prop-types";

/**
 * Common wrapper function to get a good name for the wrapped component.
 *
 *  * https://reactjs.org/docs/higher-order-components.html
 *
 */
const getDisplayName = function(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

/**
 * Given an identifier, returns an internal identifier.
 */
const to_id = (identifier) => `_poke__${identifier}`;

/**
 * Creates a wrapper HOC which transmits the wanted properties to a prop
 * named after the identifier.
 */
const poke = function(BaseComponent, identifier) {
	if (!identifier) {
		console.error("`poke` needs an identifier.");
	}
	const id = to_id(identifier);
	const HOC = (props, context) => {
		const additional = {};
		additional[identifier] = context[id];

		return (
			<BaseComponent
				{...props}
				{...additional}
			/>
		);
	};

	HOC.contextTypes = {
		[id]: PropTypes.object,
	};

	HOC.displayName = `poke(${getDisplayName(BaseComponent)}, ${identifier})`;

	return HOC;
};

/**
 * Creates a derived class of `BaseComponent` which reponds to the pokable
 * internal API.
 *
 * It will have to expose exported properties through `get poked()`.
 */
const pokable = function(BaseComponent, identifier) {
	if (!identifier) {
		console.error("`pokable` needs an identifier.");
	}
	const id = to_id(identifier);
	const WrappedComponent = class extends BaseComponent {
		constructor(...args) {
			super(...args);
			if (!this.state) {
				this.state = {};
			}
		}

		getChildContext() {
			const context = super.getChildContext ? super.getChildContext() : {};

			return Object.assign(
				{},
				context,
				{[id]: this.poked}
			);
		}
	};

	WrappedComponent.childContextTypes = Object.assign(
		BaseComponent.childContextTypes ? BaseComponent.childContextTypes : {},
		{[id]: PropTypes.object}
	);

	WrappedComponent.displayName = `pokable(${getDisplayName(BaseComponent)}, ${identifier})`;

	return WrappedComponent;
};

export {
	poke,
	pokable
};
