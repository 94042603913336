import Fetch from "qidigo-fetch";
import {trackEvent} from "../../tracker";
import {sendError} from "./router";

export const ADDING_TO_CART = "BUY:ADDING_TO_CART";
export const ADDING_TO_CART_FAILED = "BUY:ADDING_TO_CART_FAILED";
export const ADDED_TO_CART = "BUY:ADDED_TO_CART";

const adding = () => ({
    type: ADDING_TO_CART,
});

export const addToCart = (item) =>
    (dispatch, getState) => {
        const {buy: {cart}} = getState();

        // Bypass, même si c'est jamais sensé arriver.
        // TODO : Permettre des ajouts concurrents.
        // (Faire un array d'ajouts en attente, avec ticket ou w/e)
        if (cart.adding) {
            return null;
        }

        // Notifie qu'on est en train d'ajouter...
        dispatch(adding());

        // Effectue l'ajout.
        return dispatch((dispatch, getState) => {
            trackEvent(
                'add_to_cart',
                {
                    label: item.cart_item.plan_id
                }
            )
            return Fetch.post("cart", item)
                .catch((error) => {
                    dispatch(sendError(error));

                    return dispatch({type: ADDING_TO_CART_FAILED, error});
                })
                .then((response) => {
                    if (response && ["OK", "WAITING_LIST", "RESTRICTED"].includes(response["status"])) {
                        dispatch({type: ADDED_TO_CART, status: response["status"]});

                        return response;
                    }

                    console.warn("Unhandled status for response in addToCart");
                    console.log(response);

                    // FIXME : Gérer l'erreur.
                    // C'est un "cas d'exception"...
                    // Ce sont les cas comme, par exemple, où une restriction a été ajoutée
                    // pendant la consultation de l'item, ou alors, le groupe a été complété
                    // pendant la consultation de l'item.
                    // C'est donc à considérer d'afficher une alert modale pour l'erreur et ne
                    // pas considérer une erreur en contexte.
                    return Promise.reject({message: "Unhandled error...", response})
                })
                ;
        });
    };
