import React, {Component} from "react";
import queryString from "query-string";
import debounce from "lodash/debounce";
import isArray from "lodash/isArray";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";

const {history} = window;

/**
 * Common wrapper function to get a good name for the wrapped component.
 *
 *  * https://reactjs.org/docs/higher-order-components.html
 *
 */
const getDisplayName = function(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

/**
 * Class implementing query string state synchronization.
 *
 * This component will control only the *given* query string keys, letting
 * everything else the way it was, though likely re-ordering them.
 */
class QueryStringState extends Component {
	constructor() {
		this.setQueryStringDefaults = this.setQueryStringDefaults.bind(this);
		this.setQueryStringState = this.setQueryStringState.bind(this);
		this.updateQS = debounce(this.updateQS.bind(this), 750, {
			leading: true,
			trailing: true,
		});
		this.state = {
			$defaultState: {},
		};
	}

	componentWillMount() {
		// Load the query string arguments
		const query = queryString.parse(location.search);
		const state = this.onlyOwned(query);
		this.setState(state);
	}

	/**
	 * This is a cheezy way to allow default values to be given, so
	 * that when they are equal, they are removed from the query string,
	 * and when they are not given (in the query string) they are
	 * used.
	 */
	setQueryStringDefaults(defaultState) {
		const $defaultState = this.onlyOwned(defaultState);

		this.setState({$defaultState});
	}

	/**
	 * Given to the wrapped component.
	 */
	get exportedProps() {
		const {
			setQueryStringState,
			setQueryStringDefaults,
			exportedState
		} = this;

		return {
			setQueryStringState,
			setQueryStringDefaults,
			// Default values
			...this.state.$defaultState,
			// Will end up overriden as needed.
			...exportedState,
		};
	}

	/**
	 * State for the owned values. Uses the internal state since the query
	 * string might not have been updated yet due to debouncing.
	 */
	get exportedState() {
		const {$defaultState} = this.state;
		// Here we typecast values that are defaulting to arrays into arrays.
		// This is because a 1-length array is not represented nicely into
		// the query string by `query-string`.
		return mapValues(
			this.onlyOwned(this.state),
			(v, k) => isArray($defaultState[k]) && !isArray(v) ? [v] : v
		);
	}

	/**
	 * Equivalent to `setState`, but will
	 *  (1) only save the owned keys
	 *  (2) update the query string (respecting debounce)
	 */
	setQueryStringState(nextState) {
		const state = this.onlyOwned(nextState);
		this.setState(state);
		this.updateQS(state);
	}

	/**
	 * Updates the query string, respecting the debounce.
	 * It will only touch the owned keys, any other keys
	 * are left mostly untouched.
	 *
	 * The query string will get normalized via `queryString.stringify`.
	 */
	updateQS(nextState) {
		// Merge query string with the current state and the next state.
		const state = {
			...this.removeOwned(queryString.parse(location.search)),
			...this.filterDefaults({
				...this.exportedState,
				...nextState,
			})
		};

		const search = queryString.stringify(state);
		const url = [location.pathname, search].filter((s) => s !== "").join("?");
		history.replaceState({}, null, url);
	}

	/**
	 * Keeps elements from `obj` that are *owned* by this state.
	 */
	onlyOwned(objs) {
		return pick(objs, this.constructor.keys);
	};

	/*
	 * Removes elements from `obj` that are *owned* by this state.
	 */
	removeOwned(obj) {
		return pickBy(obj, (v, k) =>
			this.constructor.keys.indexOf(k) === -1
		);
	}

	/**
	 * Removes elements from `obj` when their queryString stringified value
	 * is identical to the default
	 */
	filterDefaults(obj) {
		return pickBy(obj, (v, k) =>
			queryString.stringify({[k]: v}) !== queryString.stringify({[k]: this.state.$defaultState[k]})
		);
	}
}


/**
 * Creates a class wrapping `BaseComponent` which has the given `keys` state
 * synchronized in the query string.
 */
const queryStringSynchronized = function(BaseComponent, keys) {
	const WrappedComponent = class extends QueryStringState {
		static keys = keys;
		
		render() {
			return <BaseComponent {...this.props} {...this.exportedProps} />
		}
	};

	WrappedComponent.displayName = `queryStringSynchronized(${getDisplayName(BaseComponent)})`;

	return WrappedComponent;
};

export {
	queryStringSynchronized,
};
