import * as React from "react";
import {Component} from "react";
// @ts-ignore
import Money from "@app/components/money";
import {IInstallmentPayment} from "../../types";
// @ts-ignore
import Button from "qidigo-components/button.js";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import DateLabel from "qidigo-i18n/date";

interface IInstallmentPaymentsPopupProps {
    name: string,
    initialPayment: IInstallmentPayment|null,
    restPayments: Array<IInstallmentPayment>,
    closePopup: () => void
}

const translations = defineMessages({
    "close": {
        id: "qidigo.billing.installment_payments_popup.close",
        defaultMessage: "Fermer"
    },
    "initial_payment": {
        id: "qidigo.billing.installment_payments_popup.initial_payment",
        defaultMessage: "Paiement initial"
    },
    "installment_price_display": {
        id: "qidigo.billing.installment_payments_popup.installment_price_display",
        defaultMessage: "{value} $"
    },
    "upcoming_payments": {
        id: "qidigo.billing.installment_payments_popup.upcoming_payments",
        defaultMessage: "Paiements à venir"
    },
});

class InstallmentPaymentsPopup extends Component<IInstallmentPaymentsPopupProps> {
    installmentPayment(payment:IInstallmentPayment) {
        return (
            <li className={"installment-payments-popup--upcoming-payments-item"} key={payment.date}>
                <span className={"installment-payments-popup--upcoming-payments-item-date"}>
                    <DateLabel date={payment.date} withTime={false} />
                </span>
                <span className={"installment-payments-popup--upcoming-payments-item-amount"}>
                    <FormattedMessage {...translations["installment_price_display"]} 
                                values={{value: payment.amount.toFixed(2)}}/>
                </span>
            </li>
        );
    }

    render() {
        return (
            <div className={"installment-payments-popup"}>
                <div className={"installment-payments-popup--container"}>
                    <div className={"installment-payments-popup--name"}>
                        {this.props.name}
                        <a
                            className={"installment-payments-popup--x-button"}
                            onClick={() => this.props.closePopup()}
                        ></a>
                    </div>
                    {
                        this.props.initialPayment &&
                        <div className={"installment-payments-popup--initial-payment-title"}>
                            <span>
                                <FormattedMessage {...translations["initial_payment"]} />:&nbsp;
                            </span>
                            <span>
                                <FormattedMessage {...translations["installment_price_display"]} 
                                values={{value: this.props.initialPayment.amount.toFixed(2)}}/>
                            </span>
                        </div>
                    }
                    <div>
                        <div className={"installment-payments-popup--upcoming-payments-title"}>
                            <FormattedMessage {...translations["upcoming_payments"]} />
                        </div>
                        <ul className={"installment-payments-popup--upcoming-payments-grid"}>
                            {this.props.restPayments.map((payment) => this.installmentPayment(payment))}
                        </ul>
                    </div>
                    <div className={"installment-payments-popup--close-button"}>
                        <Button
                            onClick={() => this.props.closePopup()}
                        >
                            <FormattedMessage {...translations["close"]} />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstallmentPaymentsPopup;
