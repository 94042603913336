import {path} from "@app/lib/routes";
import GroupsView from "@app/views/activity/GroupsView";
import PropTypes, {array} from "prop-types";
import Loading from "qidigo-components/loading.js";
import Fetch from "qidigo-fetch";
import Logger from "qidigo-logger";
import {navigate} from "qidigo-router";
import React, {Component} from "react";
import isEmpty from 'lodash/isEmpty';
import {changeShouldShowCompleteGroups} from "../../store/actions/groups";
import {connect} from "react-redux";

class GroupsController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            offers: [],
            sessions: null,
            selected: null,
            selectedMembers: [],
            passes: null,
            members: [],
            errors: false,
            adding: false,
            disabled: true,
            session: false,
            activateForm: true,
            isLoggedIn: undefined,
            groupsWaitingList: [],
            selectAll: true,
            hideSelectAllDefault: false,
            showFullGroupsDefault: false,
            cachedSelectedGroups: {},
            groupsSelected: [],
            disabledFullGroupsCheckbox: false,
            user: null
        };
    }

    componentDidMount() {
        this.props.changeShouldShowCompleteGroups(this.props.location.query.shouldShowCompleteGroups === "true")
        let selectedMembers = [];
        const selectedMemberIds = this.props.location.query.selectedMembers ? this.props.location.query.selectedMembers.split(",") : [];

        this.setState(
            {
                groupsSelected: this.props.location.query.groups ? this.props.location.query.groups.split(",") : [],
                activateForm: !this.props.location.query.selectedMember,
                groups: this.props.groups || []
            },
            async () => {
                await Fetch.get("whoami")
                    .then(async user => {
                        if (isEmpty(user)) {
                            this.setState({
                                isLoggedIn: false
                            })
                        } else {
                            this.setState({
                                user
                            });

                            await this.fetchFamilyMembers()
                            let filteredMembers = [];
                            selectedMemberIds.forEach((memberId) => {
                                const member = this.state.members.find((member) => {
                                    return member.id === Number(memberId)
                                });

                                filteredMembers.push(member)
                            })

                            if (filteredMembers.length > 0) {
                                selectedMembers = filteredMembers.map((member) => {
                                    return {
                                        value: member.id,
                                        label: member.full_name
                                    }
                                })
                            }
                        }
                    })

                this.updateGroups(selectedMembers || [], true)
                this.handleActivateNextStep();
            }
        );
    }

    fetchFamilyMembers = async () => {
        await Fetch.get(`users/${this.state.user.id}/family_members`)
            .then(members => {
                this.setState({
                    members: [this.state.user, ...members],
                    isLoggedIn: true
                });
            })
    }

    autoCatch(promise, fn = null) {
        return promise
            .then(response => {
                return response;
            })
            .catch(Logger.catcher);
    }

    handleSelectMember = (event) => {
        let selectedMembers = event;

        if (selectedMembers.length === 0) {
            this.setState({
                selectedMembers: []
            })
        }

        this.updateGroups(selectedMembers, false)
    }

    updateGroups = (selectedMembers, isInit = false) => {
        let search = []
        const selectedMemberIds = selectedMembers.map((member) => {
            return Number(member.value)
        })

        if (selectedMemberIds && selectedMemberIds.length > 0) {
            search.push(`subscriber_ids=${JSON.stringify(selectedMemberIds)}`);
        }

        search.push(`with_waiting_list=${this.props.shouldShowCompleteGroups ? '1' : '0'}`)
        search.push(`with_default=${isInit && !this.props.location.query.shouldShowCompleteGroups ? '1' : '0'}`)

        Fetch.get(`activities/${this.props.activity.id}/groups` + (search.length === 0 ? '' : `?${search.join('&')}`))
            .then(({groups, shouldShowCompleteGroups, isAllGroupsAreFull, hideSelectAllDefault}) => {
                this.props.changeShouldShowCompleteGroups(shouldShowCompleteGroups)
                this.setState({
                    groups,
                    disabledFullGroupsCheckbox: isAllGroupsAreFull,
                    hideSelectAllDefault: hideSelectAllDefault,
                    selectedMembers: selectedMembers,
                    activateForm: false,
                    groupsSelected: this.state.groupsSelected.filter(x => Boolean(groups.find(g => g.id === Number(x))))
                }, this.handleActivateNextStep);
            })
    }

    handleActivateNextStep() {
        this.setState(
            {
                disabled:
                    this.state.groupsSelected.length <= 0 || !this.state.selectedMembers
            },
            () => {
            }
        );
    }

    handleAddToCart(params) {
        const selectedMemberIds = this.state.selectedMembers.map((member) => {
            return Number(member.value)
        })
        navigate(path(`groups.selected`, params), {
                search: "?groups=" + this.state.groupsSelected
                    + "&selectedMembers=" + selectedMemberIds
                    + "&shouldShowCompleteGroups=" + this.props.shouldShowCompleteGroups
            }
        );
    }

    handleCompleteGroups = (event) => {
        this.props.changeShouldShowCompleteGroups(event.target.checked)
        // NOTE: trick to move update call to the next event loop tick, so new state is received
        setTimeout(() => this.updateGroups(this.state.selectedMembers, false), 0)
    }

    handleSelectAllGroups = (event) => {
        let groupsSelected = [];

        if (this.state.groups.length != this.state.groupsSelected.length) {
            this.state.groups.forEach(element => {
                groupsSelected.push(Number(element.id));
            });
        }

        this.setState(
            {
                selectAll: !event,
                groupsSelected: groupsSelected
            },
            () => this.handleActivateNextStep()
        );
    }

    handleSelectGroups = (event) => {
        let valid = true;

        let groupsSelected = this.state.groupsSelected;
        groupsSelected.forEach((element, i) => {

            if (element == Number(event.target.value)) {
                groupsSelected.splice(i, 1);
                valid = false;
            }

        });

        if (valid) groupsSelected.push(Number(event.target.value));
        this.setState(
            {
                groupsSelected: groupsSelected
            },
            () => {
                this.handleActivateNextStep();
            }
        );
    }

    render() {
        const {
            /* eslint-disable */
            route,
            params,
            /* eslint-enable */
            activity,
            members,
            ...props
        } = this.props;

        const {selected, selectedMembers} = this.state;

        if (!activity || !this.state.groups || this.state.isLoggedIn === undefined) {
            return <Loading/>;
        }

        if (activity.registration_template === "simple") {
            navigate(path("activity.session", {orgID: activity.organization["slug"], activityID: activity.id}));
        }

        return (
            <GroupsView
                {...props}
                selectAll={this.state.selectAll}
                hideSelectAllDefault={this.state.hideSelectAllDefault}
                showFullGroupsDefault={this.state.showFullGroupsDefault}
                activateForm={this.state.activateForm}
                groupsWaitingList={this.state.groupsWaitingList}
                completeGroups={this.state.disabledFullGroupsCheckbox ? this.state.disabledFullGroupsCheckbox : this.props.shouldShowCompleteGroups}
                disabledFullGroupsCheckbox={this.state.disabledFullGroupsCheckbox}
                activity={activity}
                groups={this.state.groups}
                members={this.state.members}
                refreshFamilyMembers={this.fetchFamilyMembers}
                selected={selected}
                isLoggedIn={this.state.isLoggedIn}
                disabled={this.state.disabled}
                selectedMembers={selectedMembers}
                groupsSelected={this.state.groupsSelected}
                handleAddToCart={(...e) => this.handleAddToCart({
                    orgID: activity.organization["slug"],
                    activityID: activity.id
                })}
                handleSelectGroups={this.handleSelectGroups}
                handleSelectMember={this.handleSelectMember}
                handleSelectAllGroups={this.handleSelectAllGroups}
                handleCompleteGroups={this.handleCompleteGroups}
            />
        );
    }
}

GroupsController.propTypes = {
    route: PropTypes.object,
    location: PropTypes.object,
    activity: PropTypes.object,
    groups: PropTypes.arrayOf(PropTypes.object)
};

function mapStateToProps(state) {
    return {
        shouldShowCompleteGroups: state.groups.shouldShowCompleteGroups
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeShouldShowCompleteGroups(shouldShowCompleteGroups) {
            dispatch(changeShouldShowCompleteGroups(shouldShowCompleteGroups))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsController);
