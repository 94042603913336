import * as React from "react";

export const AlertContext = React.createContext<IAlertContext>({
  setAlertMessage: (message: string) => {},
});

interface IAlertContext {
  setAlertMessage: (message: string) => void,
}

interface IAlertContextProviderProps {
  setAlertMessage: (message: string) => void,
}

const AlertContextProvider = ({children, setAlertMessage}: React.PropsWithChildren<IAlertContextProviderProps>) => {
  return (
        <>
            <AlertContext.Provider value={
                {
                  setAlertMessage: setAlertMessage,
                }
            }>
              {children}
            </AlertContext.Provider>
        </>
    )
};

export default AlertContextProvider;
