import React from "react";
import PropTypes from "prop-types";
import htmlToText from "@app/lib/html_to_text";
import HTML from "@app/components/html.js";

/**
 * Represent a section in a qidigo form for users to seperate
 * and organize questions. Users are able to put a title and description
 * as well.
 */
const TitledSection = ({children, className, title, description}) =>
	<div className={className}>
		{title? <div className={`${className}-title`}><h4>{title}</h4></div>:null}
		{htmlToText(description)? <div className={`${className}-description`}><HTML contents={description} /></div>:null}
		<div className={`${className}-content`}>{children}</div>
	</div>;

TitledSection.defaultProps = {
	className: "",
};

TitledSection.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	content: PropTypes.array,
};

export default TitledSection;
