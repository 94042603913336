import React, {Component} from "react";
import PropTypes from "prop-types";
import scroll from "@app/lib/scroll";
import View from "@app/views/organization/session_activities";
import {queryStringSynchronized} from "qidigo-querystring-state";

const SEARCH_PARAMS = [
	"page",
	"q",
	"categories",
];

class SessionActivitiesPage extends Component {
	constructor() {
		super();
		this.state = {
			numberOfPages: 1,
			categories: [],
		};
	}

	componentWillMount() {
		this.props.setQueryStringDefaults({
			page: 1,
			categories: [],
			q: "",
		});
	}

	/**
	 * When given new categories.
	 */
	handleCategoriesChange(categories) {
		this.setState({categories});
	}

	/**
	 * When given a new total number of pages.
	 */
	handleNumberOfPagesChanged(numberOfPages) {
		this.setState({numberOfPages});
	}

	/**
	 * Page selection change.
	 */
	handlePageChange(page) {
		scroll(this.activitiesNode);
		this.props.setQueryStringState({page});
	}

	/**
	 * Search query change.
	 */
	handleSearchChange(event, value) {
		this.props.setQueryStringState({q: value});
	}

	/**
	 * Categories checkboxes change.
	 */
	handleCategorySelection(categories) {
		this.props.setQueryStringState({categories});
	}

	render() {
		const organization = this.props.organization;

		const events = {};
		[
			"handlePageChange",
			"handleNumberOfPagesChanged",
			"handleCategorySelection",
			"handleCategoriesChange",
			"handleSearchChange",
		].map((k) => { events[k] = (...e) => this[k](...e); });

		return (
			<div ref={ node => this.activitiesNode = node }>
				<View
					organization={organization}
					categories={this.state.categories}
					numberOfPages={this.state.numberOfPages}
					page={this.props.page}
					q={this.props.q}
					selectedCategories={this.props.categories}

					{...events}
				/>
			</div>
		);
	}
}

SessionActivitiesPage.propTypes = {
	searchResults: PropTypes.object,
	organization:  PropTypes.object,
};

SessionActivitiesPage.contextTypes = {
	intl: PropTypes.object,
};

export default queryStringSynchronized(SessionActivitiesPage, SEARCH_PARAMS);
