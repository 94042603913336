import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
} from "react-intl";
import upperFirst from "lodash/upperFirst";
import ErrorPage from "../../../pages/error";
const translations = defineMessages({
	"places.unlimited": {id: "qidigo.group.places.unlimited", defaultMessage: "aucune limite"},
	"places.complete": {id: "qidigo.group.places.complete", defaultMessage: "complet"},
});

const LIMIT_NUM = 5;

const RemainingPlacesWidget = (props, context) => {
	const {
		firstToUpper = false,
		num
	} = props;

	const {formatMessage} = context.intl;

	const unlimitedText = formatMessage(translations["places.unlimited"]);
	const completeText = formatMessage(translations["places.complete"]);

	if (num === null) {
		return firstToUpper ? upperFirst(unlimitedText) : unlimitedText;
	}

	if (num > LIMIT_NUM) {
		return <span>{LIMIT_NUM}+</span>;
	}

	if (num > 0) {
		return <span>{num}</span>;
	}

	return firstToUpper ? upperFirst(completeText) : completeText;
};

RemainingPlacesWidget.propTypes = {
	num: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool,
	]),
};

RemainingPlacesWidget.contextTypes = {
	intl: PropTypes.object,
};
export default RemainingPlacesWidget;
