import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Auth                  from "qidigo-auth";
import QidigoLayout          from "@app/views/layout";
import Internationalizable   from "qidigo-i18n";
import GatewayProvider       from "qidigo-components/gateway/provider";
import reduce                from "lodash/reduce";
import {Link}                from "react-router/es";
import {
    routeToClassName,
    pathToClassName
} from "qidigo-router";
import Fetch                 from "qidigo-fetch";
import FatalErrorHandler from "@app/components/fatal_error_handler";
import GoogleTagManager from '../GoogleTagManager'

// Ne pas oublier les backgrounds dans design/qidigo.styles.less.
const MAX_BG = 7;

const COMPONENTS_CONFIGURATION = {
    linkType: Link,
};

/**
 * Représente la structure de base d'une page de l'application.
 *
 * ### Child Context
 *
 *    * loggedUser : Réponse de /whoami de l'API
 *    * lastLocation : Object history du dernier emplacement.
 *
 */
class QidigoApp extends Component {
    constructor() {
        this.state = {
            loggedUser: null,
            error: false,
            bgselect: 1,
        };
    }

    setRandomBackground() {
        const bgselect = Math.floor(Math.random() * MAX_BG) + 1;
        this.setState({bgselect});
    }

    componentWillReceiveProps(nextProps) {
        this.setRandomBackground();
    }

    getChildContext() {
        return {
            loggedUser: this.state.loggedUser,
            hasContact: this.hasContact,
            lastLocation: this.state.lastLocation,
            componentsConfiguration: COMPONENTS_CONFIGURATION,
        };
    }

    /**
     * Gère le changement d'état *connecté*.
     */
    onSessionChange(loggedUser) {
        this.setState({ loggedUser });
    }

    componentDidMount() {
        // Écoute les changements de session.
        this.onSessionChangeBinding = Auth.onChange(
            (status)=>this.onSessionChange(status)
        );
        // Et récupère un état initial.
        Auth.userLoggedIn()
            .then((result)=> {
                // Retire l'état d'erreur quand ça fonctionne.
                // (S'il y a eu une erreur transiente sur whoami)
                this.setState({error: null});

                return this.onSessionChange(result);
            })
            // Ne pas .catch() ici parce qu'autrement on brise
            // la récupération des erreurs *au load*...
            // Solution à trouver si on veut catcher ici.
        ;

        // Écoute les changements dans le routeur...
        const {router} = this.context;
        this.historyUnlisten = router.listen((location, action) => {
            // Pour récupérer l'emplacement *avant le changement*
            // et le conserver.
            const lastLocation = this.props.location;
            this.setState({lastLocation});

            // TODO : Remplacer par ActionCable?
            // (On refresh les *badges* et infos globales avec ce refresh)
            // On veut refresher uniquement sur une "vrai" navigation...
            // PROBLÈME : Le refresh se produit *avant* que certaines actions aient été traités.
            if (location.action !== "REPLACE" && lastLocation.pathname !== location.pathname) {
                Auth.refreshUser();
            }
        });

        this.setRandomBackground();
    }

    componentWillUnmount() {
        this.onSessionChangeBinding.detach();
        this.historyUnlisten();
    }

    onChangeLanguage(e, value) {
        const {setLocale} = this.context;
        // When there's a logged user
        if (this.state.loggedUser) {
            const user = {};
            user["lang_id"] = value.toUpperCase();
            setLocale(value);
            Fetch.patch(`users/${this.state.loggedUser.id}`, user)
                .then((response) => {
                    Auth.refreshUser();

                    return null;
                });
        }
        // When there's no logged-in user
        else {
            setLocale(value);
        }
    }

    hasContact = (organizationId) => {
        if (!this.state.loggedUser) {
            return false;
        }

        return undefined !== Array
            .from(this.state.loggedUser.contacts)
            .find((contact) => contact.organization_id === organizationId);
    }
    render() {
        const classNames = [];

        // Classe automatiques pour filtrer des sélecteurs sur les différents
        // niveaux de routes et chemins.
        const routes = this.props.routes;
        classNames.push(routeToClassName(routes));
        classNames.push(pathToClassName(this.props.location.pathname));
        reduce(this.props.location.pathname.split("/"), (arr, el) => {
            if (el !== "") {
                arr.push(el);
                const cls = `path-prefix--${arr.join("-")}`;
                classNames.push(cls);
            }

            return arr;
        }, []);

        // Selects a background for the render in the pool of backgrounds.
        classNames.push(`bgselect${this.state.bgselect}`);

        return (
            <FatalErrorHandler>
                <GoogleTagManager
                    isLogged={!!this.state.loggedUser}
                />
                <GatewayProvider
                    portals={{
                        "overlay": "body",
                        "isomorphic-script": "body",
                    }}
                >
                    <QidigoLayout
                        className={classNames.join(" ")}
                        loggedUser={this.state.loggedUser}
                        onChange={(...e) => this.onChangeLanguage(...e)}
                    >
                        {this.props.children}
                    </QidigoLayout>
                </GatewayProvider>
            </FatalErrorHandler>
        );
    }
}

QidigoApp.contextTypes = {
    router: PropTypes.object,
    setLocale : PropTypes.func,
};

QidigoApp.childContextTypes = {
    loggedUser: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]),
    hasContact: PropTypes.func,
    lastLocation: PropTypes.object,
    componentsConfiguration: PropTypes.shape({
        linkType: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    }),
};

QidigoApp.propTypes = {
    location: PropTypes.object.isRequired,
    route:    PropTypes.object.isRequired,
    routes:   PropTypes.array.isRequired,
};

const InternationalizedApp = (props) => <Internationalizable><QidigoApp {...props} /></Internationalizable>;

export default InternationalizedApp;
