import React                 from "react";
import PropTypes             from "prop-types";
import ActivityLink          from "@app/components/activity_link";
import HTML                  from "@app/components/html";
import Image                 from "qidigo-components/image";
import SideNav               from "@app/components/side-nav.js";
import Page                  from "@app/components/page.js";
import {path}                from "@app/lib/routes";
import htmlToText            from "@app/lib/html_to_text";
import ShareMenu             from "@app/components/share_menu";
import Title                 from "./Title";

import {
	defineMessages,
	FormattedMessage
} from "react-intl";

const translations = defineMessages({
	"submenu.share":     {id: "qidigo.activity.layout.submenu.share", defaultMessage: "Partager"},
});
const menuTranslations = defineMessages({
	"groups":         {id: "qidigo.activity.layout.menu.groups", defaultMessage: "Groupes"},
	"groups.pass":    {id: "qidigo.activity.layout.menu.groups.pass", defaultMessage: "Séance à la carte"},
	"groups.session": {id: "qidigo.activity.layout.menu.groups.session", defaultMessage: "Session"},
	"contact": {id: "qidigo.activity.layout.menu.contact", defaultMessage: "Contact"},
});

/**
 * Présente une page dans la hiérarchie de l'activité, avec un bandeau
 * omniprésent pour l'activité.
 */
const ActivityLayout = (props, context) => {
	const {
		children,
		activity,
		organization,
		onShare,
		showShare,
		...leftOverProps
	} = props;
	const childProps = Object.assign({activity, organization}, leftOverProps);
	const {name} = activity;

	// Params for URL building.
	const params = {orgID: organization.slug, activityID: activity.id};
	const groupsMenu = [];
	if (organization["enabled_activities_view"] === "session" || organization["enabled_activities_view"] === "both") {
		groupsMenu.push({key: "group.session", link: path("activity.session", params), translation: menuTranslations["groups.session"]});
	}
	if (organization["enabled_activities_view"] === "pass" || organization["enabled_activities_view"] === "both") {
		groupsMenu.push({key: "group.pass", link: path("activity.pass", params), otherLinks: [path("activity.calendar", params)], translation: menuTranslations["groups.pass"]});
	}
	const nav =
		<SideNav className="activity-navigation"
			menu={[
				{
					key: "groups",
					translation: menuTranslations["groups"],
					sub: groupsMenu,
				},
				{key: "contact", link: path("activity.contact", params), translation: menuTranslations["contact"]},
			]}
		/>
		;

	const img = activity.sharing_media;
	const url = path("activity", params, {full: true});

	return (
		<Page name="activity">
			<Title activity={activity} organization={organization} />
			<header>
				<div className="activity-header--image">
					<Image
						withSpinner={false}
						key={img.id}
						className="activity-header--image-image"
						src={img.path}
					/>
				</div>
				<div className="activity-header--description">
					{ showShare ? <ShareMenu url={url} /> : null }
					<ul className="activity-header--links">
						<li className="activity-header--link-share">
							<a onClick={onShare} className={`${showShare ? "active" : ""}`}>
								<FormattedMessage {...translations["submenu.share"]} />
							</a>
						</li>
					</ul>
					<h1>
						<ActivityLink activity={activity} />
					</h1>
					<HTML contents={activity.description} />
				</div>
			</header>
			<div className="activity-page--layout">
				{nav}
				<section>
					<div className="activity-page-child">
						{children && React.cloneElement(children, childProps)}
					</div>
				</section>
			</div>
		</Page>
	);
};

ActivityLayout.propTypes = {
	organization: PropTypes.object,
	activity: PropTypes.object,
};


export default ActivityLayout;
