import React, {Component} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

/**
 * Renders its contents or what is to be rendered if something is
 * to be rendered into that gateway destination.
 *
 * This behaviour differs from `react-gateway`.
 *
 * This allows replacing a whole node with a custom element.
 *
 * DO NOTE that the replaced element is in the DOM, with `display: none` applied.
 */
class GatewayDest extends Component {
	constructor(props, context) {
		super(props, context);
		this.registry = context.qGatewayRegistry;

		this.state = {
			children: null
		};
	}

	componentWillMount() {
		this.registry.addContainer(this.props.name, this);
	}

	componentWillUnmount() {
		this.registry.removeContainer(this.props.name, this);
	}

	render() {
		const {name, children} = this.props;

		if (this.state.children && this.state.children.length > 0) {
			if (children) {
				return [
					this.state.children,
					ReactDOM.createPortal(
						<div
							key="hidden"
							style={{display: "none"}}
							data-gateway-child-of={name}
						>
							{children}
						</div>,
						window.document.body
					),
				];
			}
			else {
				return this.state.children;
			}
		}

		return children || null;
	}
}

GatewayDest.contextTypes = {
	qGatewayRegistry: PropTypes.object.isRequired,
};

GatewayDest.propTypes = {
	name: PropTypes.string.isRequired,
};

export default GatewayDest;
