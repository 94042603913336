import React                 from "react";
import PropTypes             from "prop-types";
import moment                from "moment";
import Date                  from "./date";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

const translations = defineMessages({
	"allday": {id: "qidigo-i18n.datespan.allday", defaultMessage: "Toute la journée"},
	"days.format": {id: "qidigo-i18n.datespan.days.format", defaultMessage: "{start} au {end}"},
});

/**
 * Component pour afficher une étendue de temps formattée.
 *
 * Props:
 *   * `startDate` : "momentable".
 *   * `endDate` : "momentable".
 *   * `allDay` : bool.
 */
const QidigoLocalizedDateSpan = ({className, startDate, endDate, allDay, withoutDate, Tag}, context) => {
	// FIXME : Review localization.
	startDate = moment(startDate);
	endDate = moment(endDate);

	if (startDate.isSame(endDate, "day")) {
		if (allDay) {
			return (
				<Tag className={`${className} is-same-day is-all-day`}>
					{withoutDate ? null :
					<span className="date-span--date">
							<Date date={startDate} withDate={true} withTime={false} />
						</span>
					}
					<span className="date-span--times">
						<FormattedMessage {...translations["allday"]} />
					</span>
				</Tag>
			);
		}
		else {
			return (
				<Tag className={`${className} is-same-day is-not-all-day`}>
					{withoutDate ? null :
					<span className="date-span--date">
							<Date date={startDate} withDate={true} withTime={false} />
						</span>
					}
					<span className="date-span--times">
						<Date date={startDate} withDate={false} withTime={true} />{" - "}<Date date={endDate} withDate={false} withTime={true}  />
					</span>
				</Tag>
			);
		}
	}
	else if (allDay) {
		return (
			<Tag className={`${className} is-not-same-day is-all-day`}>
				<FormattedMessage
					{...translations["days.format"]}
					values={{
						start: <span className="date-span--start">
							<span className="date-span--date">
								<Date date={startDate} withDate={true} withTime={false} />
							</span>{" "}
						</span>,
						end: <span className="date-span--end">
							<span className="date-span--date">
								<Date date={endDate} withDate={true} withTime={false} />
							</span>{" "}
						</span>,
					}}
				/>
			</Tag>
		);
	}
	else {
		return (
			<Tag className={`${className} is-not-same-day is-not-all-day`}>
				<Date date={startDate} withDate={true} withTime={true} />
				<Date date={endDate} withDate={true} withTime={true}  />
			</Tag>
		);
	}
};

QidigoLocalizedDateSpan.propTypes = {
	withoutDate: PropTypes.bool,
	startDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
	endDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
	allDay: PropTypes.bool,
	Tag: PropTypes.node,
	className: PropTypes.string,
};

QidigoLocalizedDateSpan.defaultProps = {
	withoutDate: false,
	allDay: false,
	Tag: "div",
};

export default QidigoLocalizedDateSpan;
