import React, {Component} from "react";
import PropTypes from "prop-types";
import Input from "qidigo-form/input";
import PhoneInput from "qidigo-form/phoneinput";

/**
 * Template for a question of type phone.
 */

class PhoneTypeForm extends Component {

	static prepareValue(question, answer) {
		let {phone = {}} = answer || {};

		if (typeof phone === "string") {
			phone = phone.replace(/^1+/, "");
			phone = {
				number: phone.substr(0, 10),
				ext: phone.substr(10),
			};
		}

		return Object.assign({}, answer, {phone});
	}

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}

	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;

		let {phone={}} = answer || {};

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<PhoneInput error={errors["phone"]} value={phone} onChange={(...e)=>this.handleChange("phone", ...e)} />
				</div>
			</div>
		);
	}
}

PhoneTypeForm.defaultProps = {
	className: "",
	errorMessage: "",
};

PhoneTypeForm.propTypes = {
	className   : PropTypes.string,
	question    : PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange    : PropTypes.func,
	answer       : PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	errors: PropTypes.object,
};

export default PhoneTypeForm;

