import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Helmet                from "react-helmet";
import {defineMessages}      from "react-intl";
import Page                  from "@app/components/page.js";
import ENV                   from "qidigo-env";
import Gateway               from "qidigo-components/gateway";

const defaultTitle = defineMessages({message: {id: "qidigo.qidioups.generic.title", defaultMessage: "Erreur {code}"}}).message;

// Pour ajouter des messages par défaut spécifiques, c'est ici.
const messages = {
	// Sera utilisé si aucun autre message n'est trouvé.
	500: defineMessages({
		explanation: {
			id:             "qidigo.qidioups.500.explanation",
			defaultMessage: "Il semblerait que notre site éprouve des difficultés. Veuillez réessayer sous peu. Si le problème persiste, contactez notre support technique.",
		},
	}),
	404: defineMessages({
		explanation: {
			id:             "qidigo.qidioups.404.explanation",
			defaultMessage: "Il semblerait que la page que vous recherchez n'existe pas. Veuillez réessayer, et si le problème persiste, contactez notre support technique.",
		},
	}),
	403: defineMessages({
		explanation: {
			id:             "qidigo.qidioups.403.explanation",
			defaultMessage: "Il semblerait que vous n'avez pas accès à cette page. Veuillez réessayer, et si le problème persiste, contactez notre support technique.",
		},
	}),
	// On peut ajouter des mesages particuliers aux erreurs ici.
};

/**
 * Page générique d'erreur du site.
 *
 * ### Propriétés
 *
 *   * `code` Code numérique de l'erreur, permet d'utiliser des messages génériques.
 *   * `title` Titre à utiliser, utilise un titre avec le code passé par défaut.
 *   * `explanation` Explication à utiliser, utilise un message selon le code si manquant.
 *
 * @extends {Component}
 */
class ErrorPage extends Component {
	render() {
		const {children} = this.props;
		const {formatMessage} = this.context.intl;

		// On ne peut pas utiliser le defaultProperties à cause de l'utilisation de formatMessage.
		let title = formatMessage(defaultTitle, {code: this.props.code});

		let code = this.props.code;
		if (!messages[code]) { code = "500"; }
		let explanation = formatMessage(messages[code].explanation);

		// Utilisons les props si on en a!
		if (this.props.title) { title = this.props.title; }
		if (this.props.explanation) { explanation = this.props.explanation; }

		// FIXME : <Error> ne fonctionne pas en SSR avec react-gateway?
		// Pour contourner un problème(?) avec react-gateway en SSR.
		// On va au moins présenter du contenu.
		const contents =
				<Page name="qidioups">
					<Helmet
						title={title}
					/>
					<h1>{title}</h1>
					<p>
						{explanation}
					</p>
					{ /* TODO : Component de navigation pour lien « nous joindre » et « retour à l'accueil » */ }
					<div>
						{children}
					</div>
				</Page>
		;

		if (ENV["server"]) {
			return contents;
		}
		else {
			return (
				<Gateway into="page">
					{contents}
				</Gateway>
			);
		}
	}
}

ErrorPage.propTypes = {
	title:       PropTypes.string,
	explanation: PropTypes.string,
	code:        PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

// Par défaut le code est 500, mais c'est utilisé uniquement pour
// l'utilisation des messages par défaut.
ErrorPage.defaultProps = {
	code: "500",
};

ErrorPage.contextTypes = {
	intl: PropTypes.object,
};

export default ErrorPage;
