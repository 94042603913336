import React, {Component} from "react";
import PropTypes from "prop-types";
import DateInput from "qidigo-form/dateinput";
import moment from "moment";
import Input from "qidigo-form/input";
import {defineMessages} from "react-intl";

/**
 * Template for a question of type health insurance.
 */
const translations = defineMessages({
	"question.health_insurance":  {id: "qidigo.dashboard.questions.health_insurance", defaultMessage: "Numéro"},
	"question.expiration_date":  {id: "qidigo.dashboard.questions.expiration_date", defaultMessage: "Date d'expiration"},
});

class HealthInsuranceTypeForm  extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}

	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;
		const {formatMessage} = this.context.intl;

		const {healthInsurance=""} = answer || {};
		let {date=""} = answer || {};
		date = date.replace(/\//g, "-");

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Input
						error={errors["healthInsurance"]}
						value={healthInsurance}
						onChange={(...e)=>this.handleChange("healthInsurance", ...e)}
						label={formatMessage(translations["question.health_insurance"])}
					/>
					<DateInput
						error={errors["date"]}
						withDay={false}
						maxDate={moment().add(10, "years")}
						minDate={moment().subtract(10, "years")}
						value={date}
						onChange={(...e)=>this.handleChange("date", ...e)}
						label={formatMessage(translations["question.expiration_date"])}
					/>
				</div>
			</div>
		);
	}
}

HealthInsuranceTypeForm.defaultProps = {
	className: "",
	value: {},
	errorMessage: "",
};

HealthInsuranceTypeForm.contextTypes = {
	intl: PropTypes.object,
};

HealthInsuranceTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errors: PropTypes.object,
	errorMessage: PropTypes.object,
};

export default HealthInsuranceTypeForm;
