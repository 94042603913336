import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage
} from "react-intl";

import Input                 from "./input.js";
import Image                 from "qidigo-components/image.js";
import Button                from "qidigo-components/button.js";
import Event                 from "./event";

import "./imagepicker.less";

const IMAGE_MATCHER = /^image\/(jpg|jpeg|png)/;

const translations = defineMessages({
	forbiddenFiletype: {
		id: "qidigo-form.imagepicker.forbidden_filetype",
		defaultMessage: "Le type de fichier « {type} » est interdit."
	},
	choose: {
		id: "qidigo-form.imagepicker.choose",
		defaultMessage: "Sélectionner une image",
	}
});

/**
 *
 * @extends {Component}
 */
class ImagePicker extends Component {
	constructor() {
		super();
		this.state = {
			preview: "",
			drag: false,
		};
	}

	//
	// Drag events
	// -----------
	//

	/** When the drag gets on this node. */
	handleDragEnter(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({drag: true});
	}
	/** When the drag hovers on this node. */
	handleDragOver(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({drag: true});
	}
	/** When the drag leaves this node. */
	handleDragLeave(e) {
		e.stopPropagation();
		e.preventDefault();
		if (e.target === this.node) {
			this.setState({drag: false});
		}
	}
	/** When the drag drops. */
	handleDrop(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({drag: false});

		var dt = e.dataTransfer;
		var files = dt.files;

		this.handleFiles(files);
	}

	/**
	 * Handles a multiple-files event...
	 *
	 * ... by taking only the first one.
	 */
	handleFiles(files) {
		var file = files[0];
		this.handleFile(file);
	}

	/**
	 * Handles a file selection.
	 */
	handleFile(file) {
		// No errors yet...
		this.setState({error: null});

		// Pseudo-validate filetype...
		if (!IMAGE_MATCHER.test(file.type)) {
			const {formatMessage} = this.context.intl;
			let type = file.name.split(".");
			type = type[type.length-1];
			this.setState({
				error: formatMessage(translations.forbiddenFiletype, {type}),
			});

			return;
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			this.setState({preview: e.target.result});
		};
		reader.readAsDataURL(file);

		if (this.props.onChange) {
			let e = new Event(this);
			this.props.onChange(e, file);
		}
	}

	/**
	 * Demande un fichier au input.
	 */
	pickImage(e) {
		e.preventDefault();
		this.inputRef.click();
	}

	/**
	 * Quand on choisit une image.
	 */
	imagePicked(e) {
		e.preventDefault();
		const file = this.inputRef.files[0];
		if (!file) { return; }
		
		this.handleFile(file);
	}

	componentWillMount(nextProps) {
		if (this.props.value) {
			this.setState({preview: this.props.value});
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.error !== this.props.error) {
			this.setState({error: nextProps.error});
		}
		if (nextProps.value !== this.props.value) {
			// Un img src doit être une string. Quand on sélectionne un fichier avec le imagePicker, ça renvoie un File.
			// Le src va être updaté dans handleFile
			if (typeof nextProps.value === "string") {
				this.setState({preview: nextProps.value});
			}
		}
	}

	render() {
		const {
			value, // eslint-disable-line
			error, // eslint-disable-line
			autoFocus,
			ImageType,
			buttonLabel,
			label,
			className,
			...leftoverProps
		} = this.props;

		const classes = ["input-imagepicker", "imagepicker"];
		classes.push(className);
		if (this.state.drag) {
			classes.push("is-drop-target");
		}

		let buttonLabelNode =
			<FormattedMessage {...translations.choose} />
			;
		if (buttonLabel) {
			buttonLabelNode = buttonLabel;
		}

		let anyError = this.state.error;

		if (anyError) {
			classes.push("is-invalid");
			classes.push("with-error-message");
		}

		return (
			<Input
				inputRef={node => this.inputRef = node}
				label={label}
				accept="image/*"
				inputClass="imagepicker"
				className={classes.join(" ")}
				type="file"
				{...leftoverProps}
				onChange={(...e) => this.imagePicked(...e)}
				wrapperProps={{
					onDragEnter: (...e) => { this.handleDragEnter(...e); },
					onDragOver:  (...e) => { this.handleDragOver(...e); },
					onDrop:      (...e) => { this.handleDrop(...e); },
					onDragLeave: (...e) => { this.handleDragLeave(...e); },
				}}
				error={anyError}
				ref={ node => this.node = node }
			>
				<div className="imagepicker--wrapper">
					<div
						className="imagepicker--preview"
						onClick={(...e) => this.pickImage(...e)}
					>
						<ImageType
							src={this.state.preview}
						/>
					</div>
					<Button
						type="button"
						onClick={(...e) => this.pickImage(...e)}
						disabled={this.props.disabled}
						autoFocus={autoFocus}
					>
						{buttonLabelNode}
					</Button>
				</div>
			</Input>
		);
	}
}

ImagePicker.defaultProps = {
	ImageType: Image,
	autoFocus: false,
};

ImagePicker.propTypes = {
	className:          PropTypes.string,
	ImageType:          PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.func,
	]),
	value:              PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]),
	buttonLabel:        PropTypes.string,
	label:              PropTypes.string,
	required:           PropTypes.bool,
	valid:              PropTypes.bool,
	error:              PropTypes.string,
	disabled:           PropTypes.bool,
	onChange:           PropTypes.func,
	autoFocus:          PropTypes.bool,
};

ImagePicker.contextTypes = {
	intl: PropTypes.object,
};

export default ImagePicker;

