import React from "react";
import PropTypes from "prop-types";
import {defineMessages, FormattedMessage} from "react-intl";
import {translations as rl24_warnings_translations} from "@app/views/dashboard/rl24/_warnings.js";
import {path} from "@app/lib/routes";
import {Link} from "react-router/es";

// FIXME : reduce duplication with rl24/_warnings.js

const translations = Object.assign({}, rl24_warnings_translations, defineMessages({
	"me.sin.link": {id: "qidigo.dashboard.invoice.rl24.warnings.me.sin.link", defaultMessage: "Cette information peut être entrée dans la section Relevés."},
}));

const ErrorBox = ({children}) =>
	<div className="error-message">
		{children}
	</div>
;

const AddressWarning = ({who}) =>
	<ErrorBox>
		<p>
			<FormattedMessage {...translations[`${who}.address`]} />
		</p>
		{
			who === "me" &&
				<Link to={path("dashboard.address", {addressID: "new"})}>
					<FormattedMessage {...translations["me.link.address"]} />
				</Link>
		}
	</ErrorBox>
;

AddressWarning.propTypes = {
	who: PropTypes.string.isRequired,
};

const SinWarning = ({who}) =>
	<ErrorBox>
		<p>
			<FormattedMessage {...translations[`${who}.sin`]} />
		</p>
		{
			who === "me" &&
				<Link to={path("dashboard.rl24.recipients.edit", {id: "me"})}>
					<FormattedMessage {...translations["me.sin.link"]} />
				</Link>
		}
	</ErrorBox>
;

SinWarning.propTypes = {
	who: PropTypes.string.isRequired,
};

const Boxes = {
	ADDRESS: AddressWarning,
	SIN: SinWarning,
};

// Component to import for use with a specific list.
const Warnings = ({warnings, who}) =>
	warnings && warnings.length > 0 ?
		<div>
			{warnings.map((key) => {
				const Box = Boxes[key];

				return <Box key={key} who={who} />;
			})}
		</div> : null
;

Warnings.propTypes = {
	warnings: PropTypes.array.isRequired,
	who: PropTypes.string.isRequired,
};

Warnings.defaultProps = {
	who: "me",
};

export {translations};
export default Warnings;
