import * as React from "react";
import {Component} from "react";
import {IInstallment, IAddressError} from "../../types";
import PaymentInstallment from "./paymentInstallment";

interface IPaymentInstallmentListProps {
    installmentList: Array<IInstallment>,
    selectedPaymentInstallment: string|null,
    selectPaymentInstallment: (installment: string) => void
}

class PaymentInstallmentList extends Component<IPaymentInstallmentListProps> {
    componentDidMount() {
        if (
            this.props.selectedPaymentInstallment === null
            && this.props.installmentList.length > 0
        ) {
            this.props.selectPaymentInstallment(this.props.installmentList[0].future_id);
        }
    }

    render() {
        return (
            this.props.installmentList.length > 0 &&
            <ul className={"payment-installments--list"}>
                {
                    this.props.installmentList.map(
                        (installment, index) =>
                            <PaymentInstallment
                                key={installment.id}
                                index={index + 1}
                                selectedPaymentInstallment={this.props.selectedPaymentInstallment}
                                selectPaymentInstallment={() => this.props.selectPaymentInstallment(installment.future_id)}
                                installment={installment}
                            />
                    )
                }
            </ul>
        )
    }
}

export default PaymentInstallmentList;
