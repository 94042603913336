import Fetch                 from "qidigo-fetch";
import {sendError}           from "../router";

export const REQUEST_MEMBERSHIPS        = "SEARCH:REQUEST_MEMBERSHIPS";
export const RECEIVE_MEMBERSHIPS        = "SEARCH:RECEIVE_MEMBERSHIPS";
export const RECEIVE_MEMBERSHIPS_FAILED = "SEARCH:RECEIVE_MEMBERSHIP_FAILEDS";
export const INVALIDATE_MEMBERSHIPS     = "SEARCH:INVALIDATE_MEMBERSHIPS";

/**
 * Notifie qu'on effectue une requête.
 */
const requestMemberships = (organizationId) => ({
	type: REQUEST_MEMBERSHIPS,
	organizationId,
});

/**
 * Notifie de la réception des données.
 */
const receiveMembership = (organizationId, memberships) => ({
	type: RECEIVE_MEMBERSHIPS,
	organizationId,
	memberships,
	at: Date.now(),
});

/**
 * Force un refresh pour un membership.
 */
export const invalidateMemberships = () => ({
	type: INVALIDATE_MEMBERSHIPS,
});

/**
 * Charge le listing des membership_templates pour un organisme.
 *
 * ### Paramètres
 *
 *   * organizationId : ID de l'organisme pour le listing.
 *
 */
export const fetchMemberships = (organizationId) =>
	(dispatch, getState) => {
		// Convertis l'ID en string, pour stabilité entre les types.
		const id = organizationId.toString();
		const {search} = getState();

		// Court-circuite si c'est déjà en cours ou récupéré.
		if (search.memberships.organizationId && search.memberships.organizationId.toString() === id) { return null; }

		// Indique qu'on commence à récupérer les infos.
		dispatch(requestMemberships(id));

		// Dispatch
		return dispatch((dispatch, getState) => {
			return Promise.all([
				Fetch.get(`organizations/${id}/memberships`),
			])
				.then(([{
					membership_templates: memberships,
				}]) => {
					const {search} = getState();

					// N'est plus l'objet recherché?
					if (id !== search.memberships.organizationId) { return null; }

					// Réceptionne.
					dispatch(receiveMembership(id, memberships));

					return memberships;
				})
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: RECEIVE_MEMBERSHIPS_FAILED, error});
				});
		});
	};
