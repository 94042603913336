import {Component} from "react";
import PropTypes from "prop-types";
import Registry from "./registry";

class GatewayProvider extends Component {
	constructor(props) {
		super(props);

		this.registry = new Registry(this);
	}

	getChildContext() {
		return {qGatewayRegistry: this.registry};
	}

	render() {
		const {children} = this.props;

		return children;
	}
}

GatewayProvider.childContextTypes = {
	qGatewayRegistry: PropTypes.instanceOf(Registry).isRequired
};

GatewayProvider.propTypes = {
	portals: PropTypes.object,
};

GatewayProvider.defaultProps = {
	portals: {},
};

export default GatewayProvider;
