import React              from "react";
import Error              from "./error.js";

/**
 * Page 404 du site.
 */
const Qidioups = () =>
	<Error code={404} />
;

export default Qidioups;
