import * as React from "react";
import {Component} from "react";
import Alert, {IAlertMessage} from "./alert";

export interface IAlertBoxProps {
    messages: Array<IAlertMessage>,
}

class AlertBox extends Component<IAlertBoxProps> {
    render() {
        if (this.props.messages.length === 0) {
            return null;
        }

        return (
            <ul
                className="container alert-cart"
                style={{
                    paddingBottom: 0,
                }}
            >
                {this.props.messages.map((item) => <Alert
                    key={item.id}
                    messageItem={item}
                />)}
            </ul>
        )
    }
}

export default AlertBox;
