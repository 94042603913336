import * as React from "react";
import {Component} from "react";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
// @ts-ignore
import infoIcon from '../../../../../../design/assets/help.svg';
import {IBankAccount, IBillingAddress, IBank} from "../../../types";
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import PropTypes from "prop-types";
import PayButtonComponent from "../payButton"
// @ts-ignore
import Input from "qidigo-form/input";
import Select from 'react-select';
import {OptionsOrGroups, SingleValue, GroupBase} from 'react-select'

const translations = defineMessages({
    "account": {
        id: "qidigo.billing.paysafe_direct_debit_form.account",
        defaultMessage: "Compte"
    },
    "account_number": {
        id: "qidigo.billing.paysafe_direct_debit_form.account_number",
        defaultMessage: "Numéro de compte"
    },
    "account_type": {
        id: "qidigo.billing.paysafe_direct_debit_form.account_type",
        defaultMessage: "Type de compte"
    },
    "account_type_check": {
        id: "qidigo.billing.paysafe_direct_debit_form.account_type_check",
        defaultMessage: "Chèque"
    },
    "account_type_saving": {
        id: "qidigo.billing.paysafe_direct_debit_form.account_type_saving",
        defaultMessage: "Épargne"
    },
    "branch": {
        id: "qidigo.billing.paysafe_direct_debit_form.branch",
        defaultMessage: "Succursale"
    },
    "error_choose_one_option": {
        id: "qidigo.billing.paysafe_direct_debit_form.error_choose_one_option",
        defaultMessage: "Utiliser une adresse de facturation différente"
    },
    "institution_number": {
        id: "qidigo.billing.paysafe_direct_debit_form.institution_number",
        defaultMessage: "Numéro d'institution"
    },
    "no_results": {
        id: "qidigo.billing.select.no_results",
        defaultMessage: "Aucun résulat"
    },
    "payment_platform_issue": {
        id: "qidigo.billing.credit_card_form.payment_platform_issue",
        defaultMessage: "Une erreur est survenue avec la connexion à la plateforme de paiement. Veuillez réessayer plus tard.",
    },
    "unexpected_address_error": {
        id: "qidigo.billing.credit_card_form.unexpected_address_error",
        defaultMessage: "Une erreur avec l'addresse de facturation est survenue. Veuillez réessayer plus tard.",
    },
    "unexpected_installment_error": {
        id: "qidigo.billing.credit_card_form.unexpected_installment_error",
        defaultMessage: "Une erreur avec le choix du plan de paiement est survenue. Veuillez réessayer plus tard.",
    },
    "show_transit_number_info_message": {
        id: "qidigo.billing.paysafe_direct_debit_form.show_transit_number_info_message",
        defaultMessage: "Le dernier chiffre de votre chèque, le chiffre de vérification, fait partie de votre numéro de compte à 7 chiffres. Assurez-vous d'inclure ce chiffre."
    },
    "transit_number": {
        id: "qidigo.billing.paysafe_direct_debit_form.transit_number",
        defaultMessage: "Numéro de transit"
    },

    "transit_number_invalid": {
        id: "qidigo.billing.paysafe_direct_debit_form.transit_number.invalid",
        defaultMessage: "Le numéro de transit est invalide"
    },

    // Institutions names
    "acadian_fed": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.acadian_fed",
        defaultMessage: "Caisses pop. acadiennes"
    },
    "bank_of_montreal": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.bank_of_montreal",
        defaultMessage: "Banque de Montréal"
    },
    "cibc_bank": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.cibc_bank",
        defaultMessage: "Banque CIBC"
    },
    "caisse_alliance": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.cp_hearst_lted",
        defaultMessage: "Caisse populaire Alliance limitée"
    },
    "desjardins_fed": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.desjardins_fed",
        defaultMessage: "Féd. des Caisses Desj. Québec"
    },
    "hsbc_bank": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.hsbc_bank",
        defaultMessage: "Banque HSBC Canada"
    },
    "laurentian_bank": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.laurentian_bank",
        defaultMessage: "Banque Laurentienne"
    },
    "national_bank_of_canada": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.national_bank",
        defaultMessage: "Banque Nationale"
    },
    "ontario_fed": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.ontario_fed",
        defaultMessage: "Féd. des Caisses Pop. de l'Ontario"
    },
    "royal_bank": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.royal_bank",
        defaultMessage: "Banque Royale"
    },
    "scotia_bank": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.scotia_bank",
        defaultMessage: "Banque Scotia"
    },
    "tangerine": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.tangerine_bank",
        defaultMessage: "Banque Tangerine"
    },
    "the_toronto_dominion_bank": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.td_bank",
        defaultMessage: "Banque TD - Canada Trust"
    },
    "manulife": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.manulife",
        defaultMessage: "Banque Manuvie du Canada"
    },

    // Placeholders
    "placeholder_00152": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_00152",
        defaultMessage: "00152"
    },
    "placeholder_10XXX": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_10XXX",
        defaultMessage: "10XXX"
    },
    "placeholder_5": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_5",
        defaultMessage: "12345"
    },
    "placeholder_7": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_7",
        defaultMessage: "1234567"
    },
    "placeholder_9": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_9",
        defaultMessage: "123456789"
    },
    "placeholder_10": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_10",
        defaultMessage: "1234567890"
    },
    "placeholder_7_or_12": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_7_or_12",
        defaultMessage: "1234567 ou 123456789012"
    },
    "placeholder_7_to_12": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.placeholder_7_to_12",
        defaultMessage: "1234567(89012)"
    },

    // Transit errors
    "transit_error_5": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.transit_error_5",
        defaultMessage: "Le numéro de transit doit comporter 5 chiffres."
    },
    "transit_error_5_and_designation": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.transit_error_5_and_designation",
        defaultMessage: "Le numéro de transit doit comporter 5 chiffres. Assurez-vous de ne pas inclure le numéro de désignation à 4 chiffres qui précède votre numéro de compte."
    },
    "transit_error_10XXX": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.transit_error_10XXX",
        defaultMessage: "Le numéro de transit doit commencer par 10XXX et doit comporter 5 chiffres."
    },
    "transit_error_00152": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.transit_error_00152",
        defaultMessage: "Le numéro de transit doit être égal à 00152."
    },

    // Account number errors
    "account_error_7": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.account_error_7",
        defaultMessage: "Le numéro de compte doit comporter 7 chiffres."
    },
    "account_error_7_or_12": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.account_error_7_or_12",
        defaultMessage: "Le numéro de compte doit comporter 7 ou 12 chiffres."
    },
    "account_error_7_to_12": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.account_error_7_to_12",
        defaultMessage: "Le numéro de compte doit comporter de 7 à 12 chiffres."
    },
    "account_error_9": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.account_error_9",
        defaultMessage: "Le numéro de compte doit comporter 9 chiffres."
    },
    "account_error_10": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.account_error_10",
        defaultMessage: "Le numéro de compte doit comporter 10 chiffres."
    },
    "account_error_7_and_verification": {
        id: "qidigo.billing.paysafe_direct_debit_form.institutions.account_error_7_and_verification",
        defaultMessage: "Le numéro de compte doit comporter 7 chiffres. " +
            "Le dernier chiffre de votre chèque, le chiffre de vérification, fait partie de votre numéro de compte à 7 chiffres."
    },
});

interface PaySafeDirectDebitFormProps {
    userName: string,
    billing_address: IBillingAddress | null,
    isInstallmentMissing: boolean,
    handlePayment: (token: string) => void,
    handleTokenBadRequest: (code: string) => void,
    showTangerineBankInfo: (isTangerine: boolean) => void,
    organizationUuid: string,
    disabled: boolean,
    banks: null|IBank[],
    defaultBank: null|IBank
}

interface PaySafeDirectDebitFormState {
    error: boolean,
    bankAccount: IBankAccount,
    selectedInstitution: IBank|null,
    isValidAccountNumber: boolean,
    isValidTransitNumber: boolean,
    isValidInstitutionId: boolean,
    errorMessageOfAccountNumber: string,
    errorMessageOfTransitNumber: string,
    errorMessageOfInstitutionId: string,
    showTransitNumberInfo: boolean,
    unexpectedAddressError: boolean,
    unexpectedInstallmentError: boolean,
    paymentSubmitted: boolean
}


const TANGERINE_INSTITUTION_ID = '614';
const DEFAULT_ACCOUNT_TYPE = 'check';
const TOKEN_ERROR_CODE = 'PAYSAFE_BAD_REQUEST';
const FALLBACK_TRANSIT_VALIDATION_REGEX = '^\\d{5}$';
const FALLBACK_DEFAULT_INSTITUTION_NUMBER = '815';
const FALLBACK_DEFAULT_INSTITUTION_SLUG = 'desjardins_fed';
const FALLBACK_TRANSIT_ERROR_TRANSLATION_CODE = 'transit_error_5';
const FALLBACK_TRANSIT_PLACEHOLDER_TRANSLATION_CODE = 'placeholder_5';
const FALLBACK_TRANSIT_NUMBER_TEXT = 'branch';
const FALLBACK_ACCOUNT_VALIDATION_REGEX = '^[0-9]{7}$';
const FALLBACK_ACCOUNT_ERROR_TRANSLATION_CODE = 'account_error_7_and_verification';
const FALLBACK_ACCOUNT_PLACEHOLDER_TRANSLATION_CODE = 'placeholder_7';

class PaySafeDirectDebitForm extends Component<PaySafeDirectDebitFormProps, PaySafeDirectDebitFormState> {
    constructor(props: PaySafeDirectDebitFormProps) {
        super(props);
        this.state = {
            error: false,
            bankAccount: {
                holder_name: props.userName,
                number: '',
                transit_number: '',
                institution: {label: '', value: this.getDefaultInstitutionNumber()},
                type: {label: '', value: DEFAULT_ACCOUNT_TYPE},
            },
            selectedInstitution: null,
            isValidAccountNumber: false,
            isValidTransitNumber: false,
            isValidInstitutionId: true,
            errorMessageOfAccountNumber: '',
            errorMessageOfTransitNumber: '',
            errorMessageOfInstitutionId: '',
            showTransitNumberInfo: false,
            unexpectedAddressError: false,
            unexpectedInstallmentError: false,
            paymentSubmitted: false
        };
    }

    handleAccountNumber = (event) => {
        let bankAccount = Object.assign({}, this.state.bankAccount);
        bankAccount.number = event.currentTarget.value;
        this.setState({
            bankAccount: bankAccount
        });
        this.validateAccountNumber(event.currentTarget.value, false);
    }

    handleTransitNumber = (event) => {
        let bankAccount = Object.assign({}, this.state.bankAccount);
        bankAccount.transit_number = event.currentTarget.value;
        this.setState({
            bankAccount: bankAccount
        });
        this.validateTransitNumber(event.currentTarget.value, false);
    }

    handleChangeInstitutionId = (newValue: SingleValue<{label: string; value: string;}>) => {

        const {banks} = this.props;
        let isTransitNumberValid = false;
        let transitNumber = '';

        if (!newValue) {
            return;
        }

        if (!banks) {
            throw new Error('Banks not defined');
        }

        const id: string = newValue.value
        const selectedBank = banks.find((bank: IBank)  => bank.institutionNumber === id);

        if (selectedBank === undefined) {
            throw new Error('Bank not found');
        }

        if (selectedBank.transit.fixedValue) {
            transitNumber = selectedBank.transit.fixedValue;
            isTransitNumberValid = true;
        }

        const bankAccount: IBankAccount = {
            holder_name: this.state.bankAccount.holder_name,
            transit_number: transitNumber,
            institution: {label: newValue.label, value: id},
            number: '',
            type: {label: '', value: DEFAULT_ACCOUNT_TYPE}
        };

        this.props.showTangerineBankInfo(id === TANGERINE_INSTITUTION_ID);
        this.setState({
            error: false,
            bankAccount: bankAccount,
            selectedInstitution: this.getBankByInstitutionId(id),
            isValidAccountNumber: false,
            isValidTransitNumber: isTransitNumberValid,
            isValidInstitutionId: false,
            errorMessageOfAccountNumber: '',
            errorMessageOfTransitNumber: '',
            errorMessageOfInstitutionId: '',
        });
    }

    handleAccountType = (type) => {
        let bankAccount = Object.assign({}, this.state.bankAccount);
        bankAccount.type =  type

        this.setState({
            bankAccount: bankAccount
        });
    }

    getStringProperty = (defaultValue: string, baseLevelProperty: string, property: string): string => {
        const {selectedInstitution} = this.state;
        const {defaultBank} = this.props;

        let returnedProperty = defaultValue;

        if (defaultBank && defaultBank[baseLevelProperty][property]) {
            returnedProperty = defaultBank[baseLevelProperty][property];
        }

        if (selectedInstitution && selectedInstitution[baseLevelProperty][property]) {
            returnedProperty = selectedInstitution[baseLevelProperty][property];
        }

        return returnedProperty;

    }

    getSelectedInstitutionAccountRegexValidation = (): RegExp => {
        return new RegExp(this.getStringProperty(
            FALLBACK_ACCOUNT_VALIDATION_REGEX,
            'account',
            'regex'
        ));
    }

    getSelectedInstitutionAccountTranslationCode = (): string => {
        return this.getStringProperty(
            FALLBACK_ACCOUNT_ERROR_TRANSLATION_CODE,
            'account',
            'error'
        );
    }

    getSelectedInstitutionAccountPlaceholderCode = (): string => {
        return this.getStringProperty(
            FALLBACK_ACCOUNT_PLACEHOLDER_TRANSLATION_CODE,
            'account',
            'placeholder'
        );
    }

    getSelectedInstitutionTransitRegexValidation = (): RegExp => {
        return new RegExp(this.getStringProperty(
            FALLBACK_TRANSIT_VALIDATION_REGEX,
            'transit',
            'regex'
        ));
    }

    getSelectedInstitutionTransitTranslationCode = (): string => {
        return this.getStringProperty(
            FALLBACK_TRANSIT_ERROR_TRANSLATION_CODE,
            'transit',
            'error'
        );
    }

    getSelectedInstitutionTransitPlaceholderCode = (): string => {
        return this.getStringProperty(
            FALLBACK_TRANSIT_PLACEHOLDER_TRANSLATION_CODE,
            'transit',
            'placeholder'
        );
    }

    getSelectedInstitutionTransitNumberText = (): string => {
        const {selectedInstitution} = this.state;
        const selectedInstitutionNumber = selectedInstitution
            ? selectedInstitution.institutionNumber
            : FALLBACK_DEFAULT_INSTITUTION_NUMBER;

        return selectedInstitutionNumber === '815'
            ? 'transit_number'
            : FALLBACK_TRANSIT_NUMBER_TEXT;
    }

    validateAccountNumber(value: string, withMessage: boolean = true) {
        let errorMessage = '';
        let validation = true;
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const regex = this.getSelectedInstitutionAccountRegexValidation();
        if (!regex.test(value)) {
            if (withMessage) {
                errorMessage = formatMessage(translations[this.getSelectedInstitutionAccountTranslationCode()]);
            }
            validation = false;
        }

        this.setState({
            errorMessageOfAccountNumber: errorMessage,
            isValidAccountNumber: validation
        });
    }

    validateInstitutionId(value: string|null) {
        let errorMessage = '';
        let validation = true;
        if (value === null || this.getBankByInstitutionId(value) === null) {
            errorMessage = "Please choose one of option";
            validation = false;
        }

        this.setState({
            errorMessageOfInstitutionId: errorMessage,
            isValidInstitutionId: validation
        });
    }

    validateTransitNumber(value: string, withMessage: boolean = true) {
        let errorMessage = '';
        let validation = true;
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const regex = this.getSelectedInstitutionTransitRegexValidation();
        if (!regex.test(value)) {
            if (withMessage) {
                errorMessage = formatMessage(translations[this.getSelectedInstitutionTransitTranslationCode()]);
            }
            validation = false;
        }

        this.setState({
            errorMessageOfTransitNumber: errorMessage,
            isValidTransitNumber: validation
        });
    }

    isFormValid() {
        return this.state.isValidAccountNumber
            && this.state.isValidTransitNumber
            && this.state.isValidInstitutionId;
    }

    showAllErrorMessages() {

        const {bankAccount} = this.state;

        this.validateAccountNumber(bankAccount.number);
        this.validateTransitNumber(bankAccount.transit_number);
        this.validateInstitutionId(
            this.getBankInstitutionValue()
        );
    }

    fetchPaysafeToken = (bankAccount: IBankAccount , billingAddress: IBillingAddress) => {
        const data = {
            bank_account: {
                holder_name: bankAccount.holder_name,
                number: bankAccount.number,
                transit_number: bankAccount.transit_number,
                institution_id: bankAccount.institution !== null
                    ? bankAccount.institution['value']
                    : null,
                type: bankAccount.type !== null
                    ? bankAccount.type['value']
                    : null,
            },
            address: {
                street: billingAddress.civic_number_and_street,
                city: billingAddress.city,
                postal_code: billingAddress.postal_code,
                country_code: billingAddress.country,
                street2: billingAddress.civic_number_ext,
                state: billingAddress.state,
            }
        }

        const init = {
            'method': 'POST',
            'body': JSON.stringify(data),
            'headers' : {
                'Qidigo-Organization-Id': this.props.organizationUuid
            }
        };


        return qidigoFetch.fetch('paysafe-tokenize-bank-account', init);
    }

    pay = () => {
        const {formatMessage} = this.context.intl;
        const {bankAccount} = this.state;

        if (!this.isFormValid()) {
            this.showAllErrorMessages();

            return;
        }

        if (this.props.isInstallmentMissing) {
            this.setState({
                unexpectedInstallmentError: true
            });

            return;
        }

        if (!this.props.billing_address) {
            this.setState({
                unexpectedAddressError: true
            });

            return;
        }

        this.handleTransitValidation(
            bankAccount.transit_number,
            bankAccount.institution !== null
                        ? bankAccount.institution['value']
                        : ''
        ).then((response) => {
            this.setState({
                isValidTransitNumber: response['validate'],
                errorMessageOfTransitNumber: !response['validate']
                    ? formatMessage(translations['transit_number_invalid'])
                    : null
            })

            if (response['validate']) {
                this.fetchPaysafeToken(
                    bankAccount,
                    this.props.billing_address!
                ).then((response) => {
                    response.json()
                        .then((body) =>  {
                            if (!response.ok) {
                                this.props.handleTokenBadRequest(TOKEN_ERROR_CODE)
                                return;
                            }

                            this.setState({
                                paymentSubmitted: true
                            })

                            this.props.handlePayment(body.token);
                        });
                    }
                )
            }
        })
    }

    async handleTransitValidation(transit: string, institution: string) {
        return await qidigoFetch.get(
            'transit-validation' + `?transit_number=${transit}&institution_number=${institution}`
        )
    }

    getBankByInstitutionId(institutionId: string): IBank|null {
        const {banks} = this.props;

        if (banks === null) { 
            return null;
        }

        const bankFound = banks.find((bank: IBank) => bank.institutionNumber === institutionId);

        return bankFound === undefined ? null : bankFound;
    }

    toggleTransitNumberInfo() {
        this.setState({
            showTransitNumberInfo: !this.state.showTransitNumberInfo,
        })
    }

    getBankInstitutionValue = (): string|null => {

        const {bankAccount} = this.state;
        return bankAccount.institution !== null ? bankAccount.institution["value"] : null
    }

    makeOption = (value: string, label: string): {label: string, value: string} => {
        return {
            label: `${value} - ${label}`,
            value: value
        }
    }


    makeBankOptions = (): OptionsOrGroups<{
        label: string;
        value: string;
    }, GroupBase<{
        label: string;
        value: string;
    }>> | undefined => {
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const {banks} = this.props;

        if (banks === null) {
            return;
        }

        return banks.map((bank: IBank) => {

            const labelName = translations[
                bank.institutionSlug
            ] === undefined ? bank.institutionName : `${formatMessage(translations[
                bank.institutionSlug
            ])}`;

            return this.makeOption(bank.institutionNumber, labelName);
        });
    }

    getDefaultInstitutionNumber = (): string => {
        const {defaultBank} = this.props;

        return defaultBank === null ? FALLBACK_DEFAULT_INSTITUTION_NUMBER : defaultBank.institutionNumber;
    }

    getDefaultSelectValue = ():{value: string, label: string} => {

        const {defaultBank} = this.props;
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const slug = defaultBank === null ? FALLBACK_DEFAULT_INSTITUTION_SLUG : defaultBank.institutionSlug;
        const id = this.getDefaultInstitutionNumber()

        return this.makeOption(id, `${formatMessage(translations[slug])}`);
    }

    render() {
        const {selectedInstitution, bankAccount} = this.state;
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        if (
            selectedInstitution !== null
            && bankAccount !== null
            && selectedInstitution.transit.fixedValue
            && selectedInstitution.transit.fixedValue !== bankAccount.transit_number
        ) {
            bankAccount.transit_number = selectedInstitution.transit.fixedValue;
        }

        const customStyle = {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: 32,
                minHeight: 32,
                borderRadius: 3,
                border: state.isFocused ? "1px solid #27708e" : "1px solid #d2d3d5",
                boxShadow: state.isFocused ? "0 0 0.57142857rem #27708e" : "none",
                "&:hover": {
                    border: state.isFocused ? "1px solid #27708e" : "1px solid #d2d3d5",
                    boxShadow: state.isFocused ? "0 0 0.57142857rem #27708e" : "none"
                }
            }),
            valueContainer: (provided) => ({
                ...provided,
                height: 32,
                padding: '0 6px'
            }),
            input: (provided) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                height: '30px',
            }),
            menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: 9999,
            })
        };
        const customTheme = (theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
                ...theme.colors,
                primary: '#27708EFF',
            },
        })

        const accountTypeOptions = [
            {
                value: DEFAULT_ACCOUNT_TYPE,
                label: formatMessage(translations["account_type_check"])
            },
            {
                value: "saving",
                label: formatMessage(translations["account_type_saving"]),
            }
        ]
        const defaultInstitutionOption = this.getDefaultSelectValue();

        const defaultAccountTypeOption = {
                value: DEFAULT_ACCOUNT_TYPE,
                label: formatMessage(translations["account_type_check"]),
        }

        const institutionNumberLabel = formatMessage(translations['institution_number'])
        const accountTypeLabel = formatMessage(translations['account_type'])

        let institutionId = FALLBACK_DEFAULT_INSTITUTION_NUMBER;
        if (bankAccount.institution !== null) {
            institutionId = bankAccount.institution['value']
        }

        return (
                <div className={"paysafe-direct-debit-form"}>
                    {
                        this.state.error &&
                        <div>
                            <span className={"paysafe-direct-debit-form--warning-text"}>
                                <FormattedMessage {...translations["payment_platform_issue"]} />
                            </span>
                        </div>
                    }
                    {
                        this.state.unexpectedAddressError &&
                        <div>
                            <span className={"paysafe-direct-debit-form--warning-text"}>
                                <FormattedMessage {...translations["unexpected_address_error"]}/>
                            </span>
                        </div>
                    }
                    {
                        this.state.unexpectedInstallmentError &&
                        <div>
                            <span className={"paysafe-direct-debit-form--warning-text"}>
                                <FormattedMessage {...translations["unexpected_installment_error"]}/>
                            </span>
                        </div>
                    }
                    <div>
                        <div className={"paysafe-direct-debit-form--container"}>
                            <div className={"paysafe-direct-debit-form--transit-number-container"}>
                                <label htmlFor={'transit_number'} className={"paysafe-direct-debit-form--transit-number-label"}>
                                    {formatMessage(translations[this.getSelectedInstitutionTransitNumberText()])}
                                </label>
                                <Input
                                    id={'transit_number'}
                                    required
                                    type={"text"}
                                    placeholder={formatMessage(
                                        translations[this.getSelectedInstitutionTransitPlaceholderCode()]
                                        )}
                                    value={bankAccount.transit_number}
                                    disabled={
                                        selectedInstitution !== null && selectedInstitution.transit.fixedValue !== null
                                    }
                                    onChange={this.handleTransitNumber}
                                    onBlur={() => this.validateTransitNumber(
                                        bankAccount.transit_number
                                    )}
                                    errorMessage={this.state.errorMessageOfTransitNumber}
                                />
                            </div>
                            <div className={"paysafe-direct-debit-form--institution-number-container" +
                                (this.state.errorMessageOfAccountNumber
                                || this.state.errorMessageOfTransitNumber
                                ? "-with-errors" : "")
                            }>
                                <div>
                                    <label>
                                        {institutionNumberLabel}
                                    <Select
                                        styles={customStyle}
                                        theme={customTheme}
                                        menuPlacement="top"
                                        value={
                                            bankAccount.institution && 
                                            bankAccount.institution['label'] !== ''
                                            ?  bankAccount.institution
                                            : defaultInstitutionOption
                                        }
                                        onChange={this.handleChangeInstitutionId}
                                        onBlur={() => this.validateInstitutionId(this.getBankInstitutionValue())}
                                        options={this.makeBankOptions()}
                                        noOptionsMessage={() => formatMessage(translations['no_results'])}
                                    />
                                    </label>
                                </div>
                                <div className={"paysafe-direct-debit-form--institution-number-error-container"}>
                                    <span className={"paysafe-direct-debit-form--institution-number-error-text"}>
                                        {this.state.errorMessageOfInstitutionId}
                                    </span>
                                </div>
                            </div>
                            <div className={"paysafe-direct-debit-form--account-container"}>
                                <label htmlFor={'account_number'} className={"paysafe-direct-debit-form--account-number-label"}>
                                    <FormattedMessage {...translations["account_number"]} />
                                    <div className={"paysafe-direct-debit-form--account-info-image-container"}>
                                        {
                                            institutionId === defaultInstitutionOption.value &&
                                            <img
                                                className={"paysafe-direct-debit-form--account-info-image"}
                                                src={infoIcon}
                                                alt={"info-icon"}
                                                onClick={() => this.toggleTransitNumberInfo()}
                                            />
                                        }
                                    </div>
                                    {
                                        institutionId === defaultInstitutionOption.value &&
                                        this.state.showTransitNumberInfo &&
                                        <div onClick={() => this.toggleTransitNumberInfo()} 
                                             className="paysafe-direct-debit-form--account-info-popup"
                                            >
                                            <span className="paysafe-direct-debit-form--account-info-popup-text">
                                                <FormattedMessage
                                                    {...translations["show_transit_number_info_message"]}
                                                />
                                            </span>
                                        </div>
                                    }
                                </label>
                                    <Input
                                        id={'account_number'}
                                        type={"text"}
                                        placeholder={formatMessage(
                                            translations[this.getSelectedInstitutionAccountPlaceholderCode()]
                                            )}
                                        value={bankAccount.number}
                                        onChange={this.handleAccountNumber}
                                        onBlur={() => this.validateAccountNumber(bankAccount.number)}
                                        errorMessage={this.state.errorMessageOfAccountNumber}
                                    />
                            </div>
                            <div className={"paysafe-direct-debit-form--account-type-container" +
                                (this.state.errorMessageOfAccountNumber
                                || this.state.errorMessageOfTransitNumber
                                    ? "-with-errors" : "")
                            }>
                                <div>
                                    <label>
                                        {accountTypeLabel}
                                        <Select
                                            styles={customStyle}
                                            theme={customTheme}
                                            value={bankAccount.type && bankAccount.type['label'] !== ''
                                                ?  bankAccount.type
                                                : defaultAccountTypeOption}
                                            onChange={this.handleAccountType}
                                            options={accountTypeOptions}
                                            noOptionsMessage={() => formatMessage(translations['no_results'])}
                                        />
                                    </label>
                                </div>
                                <div className={"paysafe-direct-debit-form--institution-number-error-container"}>
                                    <span className={"paysafe-direct-debit-form--institution-number-error-text"}>
                                        {this.state.errorMessageOfInstitutionId}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <PayButtonComponent
                            payAction={this.pay.bind(this)}
                            disabled={
                                this.props.disabled
                                || this.state.unexpectedAddressError
                                || this.state.error
                                || !this.isFormValid()
                            }
                        />
                    </div>
                </div>
            )
    }
}

// @ts-ignore
PaySafeDirectDebitForm.contextTypes = {
    intl: PropTypes.object,
};


export default PaySafeDirectDebitForm;
