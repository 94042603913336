import * as React from "react";
import {Component} from "react";
// @ts-ignore
import mastercardIcon from '../../../../../design/assets/card/mastercard.svg';
// @ts-ignore
import Loading from 'qidigo-components/loading';
// @ts-ignore
import visaIcon from '../../../../../design/assets/card/visa.svg';
import { defineMessages, FormattedMessage } from "react-intl";
import PayButtonComponent from "./payButton"
// @ts-ignore
import infoIcon from '../../../../../design/assets/help.svg';

const translations = defineMessages({
    "card_number": {
        id: "qidigo.billing.credit_card_form.card_number",
        defaultMessage: "Numéro de la carte de crédit",
    },
    "cvv": {
        id: "qidigo.billing.credit_card_form.cvv",
        defaultMessage: "CVV",
    },
    "cvv_info_message": {
        id: "qidigo.billing.credit_card_form.cvv_info_message",
        defaultMessage: "Il s'agit du code de sécurité à 3 chiffres situé au dos de la carte (Visa/Mastercard) ou du code à 4 chiffres situé au recto de la carte (American Express)",
    },
    "expiration_date": {
        id: "qidigo.billing.credit_card_form.expiration_date",
        defaultMessage: "Date d'expiration",
    },
    "payment_platform_issue": {
        id: "qidigo.billing.credit_card_form.payment_platform_issue",
        defaultMessage: "Une erreur est survenue avec la connexion à la plateforme de paiement. Veuillez réessayer plus tard.",
    },
    "payment_platform_issue_try_again": {
        id: "qidigo.billing.credit_card_form.payment_platform_issue",
        defaultMessage: "Une erreur est survenue avec la connexion à la plateforme de paiement.",
    },
    "payment_platform_issue_click": {
        id: "qidigo.billing.credit_card_form.payment_platform_issue",
        defaultMessage: "Cliquez ici pour rafraichir.",
    },
    "unexpected_address_error": {
        id: "qidigo.billing.credit_card_form.unexpected_address_error",
        defaultMessage: "Une erreur avec l'adresse de facturation est survenue. Veuillez réessayer plus tard.",
    },
    "unexpected_installment_error": {
        id: "qidigo.billing.credit_card_form.unexpected_installment_error",
        defaultMessage: "Une erreur avec le choix du plan de paiement. Veuillez réessayer plus tard.",
    },
});

interface CreditCardInputsProps {
    unexpectedAddressError: boolean,
    paymentPlatformError: boolean,
    unexpectedInstallmentError: boolean,
    payAction: () => void,
    hasInstantiatedField: boolean,
    refreshComponent: () => void,
    amountOfTryOnRefreshing: number,
    disabled: boolean
}

interface CreditCardInputsState {
    showCVVInfo: boolean,
}

class CreditCardInputs extends Component<CreditCardInputsProps, CreditCardInputsState> {

    constructor(props: CreditCardInputsProps) {
        super(props);
        this.state = {
            showCVVInfo: false,
        };
    }

    refreshComponent () {
        this.props.refreshComponent();
    }

    toggleCVVInfo() {
        this.setState({
            showCVVInfo: !this.state.showCVVInfo,
        })
    }

    render() {
        return (
            <div className={"credit-card-inputs"}>
                <div id="card-form" className={this.props.hasInstantiatedField ? '' : 'hidden'}>
                    {
                        this.props.paymentPlatformError &&
                        <div>
                            <span className={"credit-card-inputs--warning"}>
                                {
                                    this.props.amountOfTryOnRefreshing < 4 ?
                                    <>
                                        <FormattedMessage {...translations["payment_platform_issue_try_again"]} />
                                        <button
                                            className={"button-link"}
                                            onClick={() => this.refreshComponent()}
                                        >
                                            <FormattedMessage {...translations["payment_platform_issue_click"]} />
                                        </button>
                                    </>
                                    :<FormattedMessage {...translations["payment_platform_issue"]} />
                                }
                            </span>
                        </div>
                    }
                    {
                        this.props.unexpectedAddressError &&
                        <div>
                            <span className={"credit-card-inputs--warning"}>
                                <FormattedMessage {...translations["unexpected_address_error"]}/>
                            </span> 
                        </div>
                    }
                    {
                        this.props.unexpectedInstallmentError &&
                        <div>
                            <span className={"credit-card-inputs--warning"}>
                                <FormattedMessage {...translations["unexpected_installment_error"]}/>
                            </span> 
                        </div>
                    }
                    <div>
                        <div className={"credit-card-inputs--inputs"}>
                            <div className={"credit-card-inputs--inputs-card-number-container"}>
                                <label htmlFor="card-number">
                                    <FormattedMessage {...translations["card_number"]} />
                                </label>
                                <div
                                    className={"credit-card-inputs--inputs-card-number-input"}
                                    id="card-number"
                                ></div>
                            </div>
                            <div className={"credit-card-inputs--inputs-icons-container"}>
                                <div>&nbsp;</div>
                                <div className={"credit-card-inputs--inputs-icons-container-container"}>
                                    <img
                                        className={"credit-card-inputs--inputs-icons-icon"}
                                        src={mastercardIcon}
                                        alt={"mastercard-icon"}
                                    />
                                    <img
                                        className={"credit-card-inputs--inputs-icons-icon"}
                                        src={visaIcon}
                                        alt={"visa-icon"}
                                    />
                                </div>
                            </div>
                            <div className={"credit-card-inputs--inputs-expiration-date-container"}>
                                <label htmlFor="expiration-date">
                                    <FormattedMessage {...translations["expiration_date"]} />
                                </label>
                                <div
                                    className={"credit-card-inputs--inputs-expiration-date-input"}
                                    id="expiration-date"
                                >
                                </div>
                                <p></p>
                            </div>
                            <div className={"credit-card-inputs--inputs-cvv-container"}>
                                <label htmlFor="cvv" className={"credit-card-inputs--input-cvv-info-label"}>
                                    <FormattedMessage {...translations["cvv"]} />
                                    <div className={"credit-card-inputs--input-cvv-info-image-container"}>
                                        {
                                            <img
                                                className={"credit-card-inputs--cvv-info-image"}
                                                src={infoIcon}
                                                alt={"info-icon"}
                                                onClick={() => this.toggleCVVInfo()}
                                            />
                                        }
                                    </div>
                                    {
                                        this.state.showCVVInfo &&
                                        <div onClick={() => this.toggleCVVInfo()} className="credit-card-inputs--cvv-info-popup">
                                            <span className="credit-card-inputs--cvv-info-popup-text">
                                                <FormattedMessage
                                                    {...translations["cvv_info_message"]}
                                                />
                                            </span>
                                        </div>
                                    }
                                </label>
                                <div
                                    className={"credit-card-inputs--inputs-cvv-input"}
                                    id="cvv"
                                ></div>
                            </div>
                        </div>
                        <PayButtonComponent
                            payAction={this.props.payAction}
                            disabled={this.props.disabled}
                        />
                    </div>
                </div>
                {
                    !this.props.hasInstantiatedField && <Loading/>
                }
            </div>
        );
    }
}

export default CreditCardInputs;
