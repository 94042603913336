/* eslint no-console: [0] */

const AJAX_STYLE = [
	"font-style: italic",
	"font-weight: bold",
	"width: 100%",
	"display: block",
	"background: #f8e9ff",
].join(";");

/**
 * *Logger* global de messages et d'erreurs.
 *
 * Les erreurs devraient être données à un service d'aggrégation d'erreurs.
 *
 * L'utilisation des fonctions `debug()` et de certains *trace* permet avec un bon
 * compilateur *JS/ES*, à l'aide du tree-shaking, de complètement retirer les print
 * de debug avec un build de production.
 *
 *     TODO : Configuration production avec tree shaking
 *     TODO : Variable utilisable d'environnement "DEVELOPMENT".
 *
 */
class Logger {
	constructor() {
		// Pour simplifier l'utilisation des handlers, on les bind par défaut à `this`.
		// Comme ça, on peut directement utiliser `Logger.errorMessageHandler` comme callback.
		this.errorMessageHandler = this.errorMessageHandler.bind(this);
		this.ajaxErrorHandler    = this.ajaxErrorHandler.bind(this);
		this.catcher             = this.catcher.bind(this);
	}
	/**
	 * Log des messages uniquement en développement.
	 */
	debug(...all) {
		return console.log(...all);
	}

	/**
	 * Log des message en développement, groupés.
	 */
	debugGroup(name, ...all) {
		console.groupCollapsed(name);
		for (let i of all) {
			console.log(i);
		}
		console.groupEnd();
	}
	/**
	 * Log des messages, reste hors développement.
	 *
	 * L'utilisation de cette fonction est à proscrire.
	 */
	log(...all) {
		return console.log(...all);
	}

	/**
	 * Log un message d'erreur à la console.
	 *
	 *     TODO : Aggrégation des erreurs avec un service externe.
	 */
	error(msg, ...all) {
		return console.error(`😾 ${msg}`, ...all);
	}

	/**
	 * Log un warning, utilisé uniquement en développement.
	 */
	warn(...all) {
		return console.warn(...all);
	}

	/**
	 * Permet de logger un objet d'erreur ayant la propriété
	 * `message` et optionnellement la propriété `code`.
	 */
	errorMessageHandler(err) {
		if (!err.message) {
			return this.error("Could not log: ", err);
		}
		if (err.code) {
			return this.error("Error [%s]: %s", err.code, err.message);
		}

		return this.error("Error: %s", err.message);
	}

	/**
	 * *Error handler* qui peut être attaché du côté *err* ou *catch* d'un
	 * Promise ajax.
	 *
	 * @return {Object} L'objet response donné, permet d'enchaîner à la promise.
	 */
	ajaxErrorHandler(response) {
		if (process.env.NODE_ENV === "development") {
			return response.clone().json()
			.then((body)=> {
				console.group(
					"%c😾Error while handling request (%s)",
					"color:#a00", response.status
				);
				console.info(body.exception);
				console.groupCollapsed(" → Trace");
				console.log(body.traces);
				console.groupEnd();

				console.groupCollapsed(" → Response");
				console.info(response.statusText);
				console.log(response);
				console.groupEnd();
				console.groupEnd();

				return Promise.reject(response);
			});
		}

		return Promise.reject(response);
	}

	/**
	 * *Logger* en mode développement des requêtes AJAX.
	 *
	 * @param {string} verb Verbe de la requête (GET/POST)
	 * @param {string} res  Ressource (URL) demandée.
	 */
	ajaxDebugLog(verb, res, body) {
		if (body) {
			console.groupCollapsed("%c → %s (%s);", AJAX_STYLE, verb, res);
			console.log(body);
			console.groupEnd();
		}
		else {
			console.log("%c → %s (%s);", AJAX_STYLE, verb, res);
		}
	}

	/**
	 * *Logger* pour catcher des erreurs en fin de Promise.
	 */
	catcher(err) {
		console.warn("Logging yet unhandled error in promise chain:", err);

		return Promise.reject(err);
	}
}

export default new Logger();

