import React                 from "react";
import PropTypes             from "prop-types";
import {Link}                from "react-router/es";
import {path}                from "@app/lib/routes";

/**
 * Constant, safe way to link to a group.
 */
const GroupLink = (props) => {
	const {className, group, ...leftoverProps} = props;
	const classNames = ["group-link", className];
	const {activity} = group;
	const {organization} = activity;

	return (
		<Link
			to={path("group", {orgID: organization["slug"], activityID: activity["id"], groupID: group["id"]})}
			className={classNames.join(" ")}
			{...leftoverProps}
		>
			{group.name}
		</Link>
	);
};

const idType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number,
]);

GroupLink.propTypes = {
	className:    PropTypes.string,
	group: PropTypes.shape({
		id: idType,
		name: PropTypes.string,
		activity: PropTypes.shape({
			id: idType,
			organization: PropTypes.shape({
				slug: idType,
			}),
		}),
	}).isRequired,
};

export default GroupLink;
