import {Component}           from "react";
import PropTypes             from "prop-types";
import {navigate}            from "qidigo-router";
import {path}                from "@app/lib/routes";

/**
 * Redirige vers le bon contrôleur par défaut lors de l'accès direct
 * à un organisme.
 *
 * Ce sont des options sur le modèle Organisme qui définissent quelle vue est
 * privilégiée par un organisme.
 *
 * @extends {Component}
 */
class OrganizationRedirector extends Component {
	componentDidMount() {
		const {organization} = this.props;
		const {slug} = organization;
		const view = organization["default_activities_view"];
		// TODO : isomorphic 301
		navigate(path(`organization.${view}`, {orgID: slug}), {replace: true});
	}

	render() {
		return null;
	}
}

OrganizationRedirector.propTypes = {
	route:  PropTypes.object,
	organization: PropTypes.object,
	params: PropTypes.shape({
		orgID: PropTypes.string,
	}).isRequired,
};

export default OrganizationRedirector;
