import React, {Component}    from "react";
import PropTypes             from "prop-types";
import moment                from "moment";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import capitalize            from "lodash/capitalize";
import reduce                from "lodash/reduce";

import Calendar              from "./calendar";
import {
	localizeDate,
} from "./localization";

// Utilisons des morceaux "interne" à react-big-calendar.
// Il faudra faire attention aux mises-à-jours.
import Dates                 from "react-big-calendar/lib/utils/dates";
import {navigate}            from "react-big-calendar/lib/utils/constants";
import localizer             from "react-big-calendar/lib/localizer";
import {accessor as get}     from "react-big-calendar/lib/utils/accessors";

const translations = defineMessages({
	"event.label": {id: "qidigo.calendar.agendaweek.event_label", defaultMessage: "{time} {description}"},
});

// Logique prise de react-big-calendar.
const getRange = (date, {culture}) => {
	const firstOfWeek = localizer.startOfWeek(culture);
	const start = Dates.startOf(date, "week", firstOfWeek);
	const end = Dates.endOf(date, "week", firstOfWeek);

	return {start, end};
};

/**
 * Effectue l'affichage d'une case d'une semaine.
 *
 * AFFICHAGE TRÈS BASIQUE.
 */
const SimpleWeekDay = (props, context) => {
	const {
		events,
		startAccessor,
		endAccessor,
		titleAccessor,
		day,
		onSelectEvent,
	} = props;
	const {formatMessage} = context.intl;

	const daysEvents = events.filter(
		event => Dates.inRange(day,
			get(event, startAccessor),
			get(event, endAccessor), "day")
	);

	const classNames = [
		`is-ordinal-${moment(day).format("d")}`
	];

	if (Dates.eq(day, moment().toDate(), "day")) {
		classNames.push("is-today");
	}

	return (
		<li
			className={classNames.join(" ")}
		>
			<span className="qidigo-calendar--day-date">
				{capitalize(localizeDate("short.weekday", day))}
			</span>
			{
				daysEvents.map((event) => {
					const start = localizeDate("event.time", get(event, startAccessor));
					const title = get(event, titleAccessor);

					return (
						<div key={start}
							title={
								formatMessage(translations["event.label"], {
									time: start, description: title,
								})
							}
							className="qidigo-calendar--event"
						>
							<a onClick={(e) => onSelectEvent(event, e)}>
								<FormattedMessage {...translations["event.label"]}
									values={{
										time: <small>{start}</small>,
										description: title
									}}
								/>
							</a>
						</div>
					);
				})
			}
		</li>
	);
};
SimpleWeekDay.propTypes = {
	day: PropTypes.object,
	events: PropTypes.array,
	startAccessor: PropTypes.string,
	endAccessor: PropTypes.string,
	titleAccessor: PropTypes.string,
};

SimpleWeekDay.contextTypes = {
	intl: PropTypes.object,
};

/**
 * Affichage "simple" d'une semaine.
 * À faire afficher comme 7 jours.
 */
class SimpleWeek extends Component {
	render() {
		const {
			date,
		} = this.props;
		const {start, end} = getRange(date, this.props);
		const range = Dates.range(start, end, "day");

		return (
			<div className="qidigo-calendar--simple-week">
				<ul>
					{
						range.map((day) =>
							<SimpleWeekDay key={day} {...this.props} day={day} />
						)
					}
				</ul>
			</div>
		);
	}
}

SimpleWeek.propTypes = {
	date: PropTypes.object,
};

/**
 * Fonction appelée par react-big-calendar sur navigation.
 */
SimpleWeek.navigate = (date, action) => {
	switch (action) {
		case navigate.PREVIOUS:
			return Dates.add(date, -1, "week");
		case navigate.NEXT:
			return Dates.add(date, 1, "week");
		default:
			return date;
	}
};

export {SimpleWeek};


/**
 * Présente une vue d'une seule semaine.
 *
 * (Voir: Utilisée sur la page d'un groupe)
 *
 * Le component BigCalendar cause un warning react...
 * Voir: https://github.com/intljusticemission/react-big-calendar/issues/279
 * C'est vraiment juste un warning parce que la validation est erronnée.
 */
const AgendaWeek = ({events}) => {
	// Quand est-ce qu'on commence l'affichage?
	const today = moment();

	// Tente de trouver la date la plus proche d'aujourd'hui où il
	// y a un événement.
	const date = (
		reduce(events.map((o) => o.start), (res, curr) => {
			res = moment(res);
			curr = moment(curr);
			// On ne veut pas ceux passés.
			if (curr.isBefore(today)) {
				// On garde ce qu'on avait.
				return res;
			}
			// On en a un de conservé, et il est avant celui courant?
			if (res && res.isBefore(curr)) {
				// On le garde
				return res;
			}

			// Autrement, on préfère celui courant.
			return curr;
		}, today) || today
	).toDate();

	return (
		<Calendar
			events={events}
			selectable={false}
			defaultDate={date}
			view="week"
			onView={()=>{}}
			views={{
				week: SimpleWeek,
			}}
			className="qidigo-calendar-agendaweek"
		/>
	);
};

AgendaWeek.propTypes = {
	events: PropTypes.array.isRequired,
};

AgendaWeek.defaultProps = {
	events: [],
};

export default AgendaWeek;
