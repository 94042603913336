import React, {Component}    from "react";
import PropTypes             from "prop-types";
import OrganizationLink      from "@app/components/organization_link";
import Page                  from "@app/components/page.js";
import {
	Link,
	IndexLink,
} from "react-router/es";

import {
	defineMessages,
	FormattedMessage
} from "react-intl";

const translations = defineMessages({
	//"dashboard":       {id: "qidigo.dashboard.menu.dashboard", defaultMessage: "Tableau de bord"},
});

/**
 * Présente une page dans la hiérarchie de l'organisme, avec un bandeau
 * omniprésent pour l'organisme.
 */
const OrganizationChildLayout = (props, context) => {
	const {
		children,
		organization,
		...leftOverProps
	} = props;
	const childProps = Object.assign({organization}, leftOverProps);

	return (
		<Page className="organization-child-page">
			<header>
				<h1>
					<OrganizationLink organization={organization} withLogo={true} />
				</h1>
			</header>
			<div className="organization-child">
				{children && React.cloneElement(children, childProps)}
			</div>
		</Page>
	);
};

OrganizationChildLayout.propTypes = {
	organization: PropTypes.object,
};


export default OrganizationChildLayout;
