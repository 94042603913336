import * as React from "react";
import { ReactElement } from 'react';
import {Component} from "react";
import Truncate from "react-truncate";
// @ts-ignore
import Money from "@app/components/money";
// @ts-ignore
import Image from 'qidigo-components/image.js'
// @ts-ignore
import htmlToText            from "@app/lib/html_to_text";
import {ILine, IProduct, IReservedSession} from "../types";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import Loading from "qidigo-components/loading.js";
// @ts-ignore
import moment                from "moment";
// @ts-ignore
import Date                  from "qidigo-i18n/date";

const translations = defineMessages({
    "session_reservation": {
        id: "qidigo.billing.line.session_reservation",
        defaultMessage: "Réservation pour la durée complète du groupe"
    },
    "hide": {
        id: "qidigo.billing.line.hide",
        defaultMessage: "Masquer"
    },
    "remove": {
        id: "qidigo.billing.line.remove",
        defaultMessage: "Retirer"
    },
    "see_more": {
        id: "qidigo.billing.line.see_more",
        defaultMessage: "Voir plus"
    },
    "money": {
        id: "qidigo.billing.line.money",
        defaultMessage: "{money} $"
    },
    "pass_reservation_quantity": {
        id: "qidigo.billing.line.pass_reservation_quantity",
        defaultMessage: "Réservation pour : {count, plural, one {une séance} other {{count} séances}}",
    },
    "pass_reservation_description": {
        id: "qidigo.billing.line.pass_reservation_description",
        defaultMessage: "{name} : du {start_date} au {end_date}"
    },
    "group_duration_description": {
        id: "qidigo.billing.line.group_duration_description",
        defaultMessage: "Du {start_date} au {end_date}"
    },
    "cancel": {
        id: "qidigo.billing.line.cancel",
        defaultMessage: "Annulé"
    },
    "informations.duration.years": {
        id: "qidigo.invoice.row.informations.duration.years",
        defaultMessage: "{years} {years, plural, one {année} other {années}}"
    },
    "informations.duration.months": {
        id: "qidigo.invoice.row.informations.duration.months",
        defaultMessage: "{months} {months, plural, one {mois} other {mois}}"
    },
    "informations.duration.weeks": {
        id: "qidigo.invoice.row.informations.duration.weeks",
        defaultMessage: "{weeks} {weeks, plural, one {semaine} other {semaines}}"
    },
    "informations.duration.days": {
        id: "qidigo.invoice.row.informations.duration.days",
        defaultMessage: "{days} {days, plural, one {jour} other {jours}}"
    },
    "informations.duration.hours": {
        id: "qidigo.invoice.row.informations.duration.hours",
        defaultMessage: "{hours} {hours, plural, one {heure} other {heures}}"
    },
});

interface ILineProps {
    line: ILine,
    product: IProduct,
    reload: () => void,
    removeLine: () => void
    handleItemIsBeingDeleted: (isBeingDeleted: boolean) => void,
    canRemoveLine: boolean,
    isLoading: boolean
}

interface ILineState {
    isTruncate: boolean,
    isLoading: boolean
}

class Line extends Component<ILineProps, ILineState> {
    truncate = null;

    constructor(props: ILineProps) {
        super(props);
        this.state = {
            isTruncate: true,
            isLoading: false
        };
    }

    toggleTruncate = () => {
        this.setState({
            isTruncate: !this.state.isTruncate,
        });
    }

    async handleRemoveItem() {
        if (!this.props.canRemoveLine) {
            return
        }

        this.setState({
            isLoading: true
        });
        this.props.handleItemIsBeingDeleted(true);
        await this.props.removeLine();
        this.props.reload();
    }

    truncateText(text: string) {
        const ellipsis = (
            <span>...
                <button className="line--see-more button-link" onClick={this.toggleTruncate}>
                    <FormattedMessage {...translations["see_more"]} />
                    <i className="caret down"></i>
                </button>
            </span>
        );

        return (
            <Truncate
                ref={ref => this.truncate = ref}
                lines={2}
                ellipsis={ellipsis}
            >{text}</Truncate>
        );
    }

    unTruncateText(text: string) {
        return <span className={"line--truncated-text"}>{text}
                    <button className="line--hide-button button-link" onClick={this.toggleTruncate}>
                        <FormattedMessage {...translations["hide"]} />
                        <i className="caret up"></i>
                    </button>
                </span>;
    }

    formatDuration = (durationInMinutes) => {
        const minutesInHour = 60;
        const minutesInDay = 60 * 24;
        const minutesInWeek = minutesInDay * 7;
        const minutesInMonth = minutesInDay * 30;
        const minutesInYear = minutesInDay * 365;

        let years = Math.floor(durationInMinutes / minutesInYear);
        let remainingDuration = durationInMinutes % minutesInYear;
        let months = Math.floor(remainingDuration / minutesInMonth);
        remainingDuration %= minutesInMonth;
        let weeks = Math.floor(remainingDuration / minutesInWeek);
        remainingDuration %= minutesInWeek;
        let days = Math.floor(remainingDuration / minutesInDay);
        remainingDuration %= minutesInDay;
        let hours = Math.floor(remainingDuration / minutesInHour);

        let formattedDuration : ReactElement[] = [];
        if (years > 0) {
            formattedDuration.push(<FormattedMessage {...translations['informations.duration.years']} values={{years: years}}/>);
        }
        if (months > 0) {
            formattedDuration.push(<FormattedMessage {...translations['informations.duration.months']} values={{months: months}}/>);
        }
        if (weeks > 0) {
            formattedDuration.push(<FormattedMessage {...translations['informations.duration.weeks']} values={{weeks: weeks}}/>);
        }
        if (days > 0) {
            formattedDuration.push(<FormattedMessage {...translations['informations.duration.days']} values={{days: days}}/>);
        }
        if (hours > 0) {
            formattedDuration.push(<FormattedMessage {...translations['informations.duration.hours']} values={{hours: hours}}/>);
        }

        return (
            <div className={'line--product-duration '}>
                {formattedDuration.map((element, index) => (
                        <p key={index}>{element} {' '}</p>
                    )
                )}
            </div>
        )
    }

    render() {
        const sessions = this.props.product.reserved_sessions;
        const isContract = this.props.product.type_plan === 'RENTAL' || this.props.product.type_plan === 'EXTRA';

        return (
            <li className={`line--line ${isContract ? 'line-contract-line' : ''}`} key={this.props.line.id}>
                {
                    this.props.product.type_plan !== 'RENTAL' &&
                    this.props.product.type_plan !== 'EXTRA' &&
                        <div className="line--product-image-container">
                            <Image
                                className="line--product-image"
                                src={this.props.product.image_path}
                                alt={this.props.product.image_alt}
                                withSpinner={false}
                            />
                        </div>
                }
                <div className="line--product-text-container">
                    <div>
                        <span className="line--product-name">
                            {this.props.product.name + (this.props.product.type_plan === 'EXTRA'
                                ? ' (' + this.props.product.quantity + ')'
                                : '')
                            }
                        </span>
                        {
                            this.props.canRemoveLine &&
                            <button
                                className="line--remove-line-button button-link"
                                onClick={() => this.handleRemoveItem()}
                            >
                                (<FormattedMessage {...translations["remove"]} />)
                            </button>
                        }
                    </div>
                    {
                        this.props.product.type_plan === 'SESSION' &&
                        this.props.product.group_start_date !== null &&
                        this.props.product.group_end_date !== null &&
                        <div className="line--subscriber-name">
                            <FormattedMessage {...translations['group_duration_description']}
                                              values={{
                                                  start_date: <Date date={this.props.product.group_start_date} />,
                                                  end_date: <Date date={this.props.product.group_end_date} />,
                                              }}
                            />
                        </div>
                    }
                    {
                        this.props.product.type_plan === 'RENTAL' &&
                        this.props.product.duration > 0 &&
                        this.formatDuration(this.props.product.duration)
                    }
                    <div className="line--product-description">
                        {
                            this.state.isTruncate
                                ? this.truncateText(htmlToText(this.props.product.description))
                                : this.unTruncateText(htmlToText(this.props.product.description))
                        }
                    </div>
                    {
                        this.props.product.type_plan === 'PASS' && sessions.length > 0 &&
                        <div>
                            <span className="line--subscriber-reservation">
                            <FormattedMessage {...translations['pass_reservation_quantity']}
                              values={{
                                  count: sessions.length,
                              }}
                            />
                        </span>
                            <div className="line--sessions-reservations-list-container">
                                <ul>
                                    {
                                        sessions.map((session: IReservedSession) => {
                                            return <li>
                                                <FormattedMessage {...translations['pass_reservation_description']}
                                                      values={{
                                                          name: session.name,
                                                          start_date: <Date date={session.start_date} withTime={true} />,
                                                          end_date: <Date date={session.end_date} withTime={true} />,
                                                      }}
                                                />
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                <div className="line--product-price-container">
                    <div className="line--product-price">
                        {
                            this.state.isLoading
                                ?
                                <Loading className={'removed-line-reload'} compact={true}/>
                                :
                                <FormattedMessage {...translations["money"]}
                                                  values={{
                                                      money: <Money
                                                          value={this.props.product.price_before_taxes}
                                                          withSymbol={false}
                                                      />
                                                  }}
                                />
                        }
                    </div>
                </div>
            </li>
        );
    }
}

export default Line;
