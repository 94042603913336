import router            from "./router";
import search            from "./search";
import buy               from "./buy";
import profile           from "./profile";
import cart              from "./cart";
import groups            from "./groups"

const reducers = {
	router,
	search,
	buy,
	profile,
	cart,
	groups
};

// Pour avoir une seconde opinion à propos des actions...
// if (process.env.NODE_ENV === "development") {
// 	reducers["spy"] = (state = null, action) => {
// 		console.log("→redux→", action); // eslint-disable-line
//
// 		return state;
// 	};
// }

export default reducers;
