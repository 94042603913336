import {defineMessages} from "react-intl";

const GENDERS = defineMessages({
    F: {id: "qidigo.registration.informations.woman", defaultMessage: "Femme"},
    M: {id: "qidigo.registration.informations.man", defaultMessage: "Homme"},
    O: {id: "qidigo.registration.informations.other", defaultMessage: "Autre identité de genre"},
    N: {id: "qidigo.registration.informations.no_answer", defaultMessage: "Préfère ne pas répondre"},
});

export default GENDERS;
