import React, {Component} from "react";
import PropTypes from "prop-types";
import Input from "qidigo-form/input";
import {defineMessages} from "react-intl";
import PhoneInput from "qidigo-form/phoneinput";

/**
 * Template for a question of type contact.
 */
const translations = defineMessages({
	first_name: {id: "qidigo.dashboard.form.question.first_name", defaultMessage: "Prénom"},
	last_name: {id: "qidigo.dashboard.form.question.last_name", defaultMessage: "Nom"},
	status: {id: "qidigo.dashboard.form.question.status", defaultMessage: "Lien de parenté"},
	phone: {id: "qidigo.dashboard.form.question.phone", defaultMessage: "Numéro de téléphone"},
});

class ContactTypeForm extends Component {

	static prepareValue(question, answer) {
		let {phone = {}} = answer || {};

		if (typeof phone === "string") {
			phone = phone.replace(/^1+/, "");
			phone = {
				number: phone.substr(0, 10),
				ext: phone.substr(10),
			};
		}

		return Object.assign({}, answer, {phone});
	}

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);
		returnValue[field] = value;
		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}

	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;
		const {formatMessage} = this.context.intl;
		const {first_name = "", last_name = "", status = ""} = answer || {};
		const {phone = {}} = answer || {};

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Input error={errors["first_name"]} value={first_name} onChange={(...e)=>this.handleChange("first_name", ...e)} label={formatMessage(translations["first_name"])} />
					<Input error={errors["last_name"]} value={last_name} onChange={(...e)=>this.handleChange("last_name", ...e)} label={formatMessage(translations["last_name"])} />
					<PhoneInput error={errors["phone"]} value={phone} onChange={(...e)=>this.handleChange("phone", ...e)} label={formatMessage(translations["phone"])} />
					<Input error={errors["status"]} value={status} onChange={(...e)=>this.handleChange("status", ...e)} label={formatMessage(translations["status"])} />
				</div>
			</div>
		);
	}
}

ContactTypeForm.defaultProps = {
	className: "",
};

ContactTypeForm.contextTypes = {
	intl: PropTypes.object,
};

ContactTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errors: PropTypes.object,
	errorMessage: PropTypes.object,
};

export default ContactTypeForm;
