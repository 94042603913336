import React from "react";
import PropTypes             from "prop-types";

/**
 * Présente la boîte indiquant qu'il n'y a rien à afficher.
 */
const Empty = ({children, className}) =>
	<div className={`empty-state ${className}`}>
		<div className="empty-state--nothing">
			{children}
		</div>
	</div>

Empty.defaultProps = {
	className: "",
}
Empty.propTypes = {
	className: PropTypes.string,
};

export default Empty;
