import * as React from "react";
import {Component} from "react";
// @ts-ignore
import Money from "@app/components/money";
// @ts-ignore
import Button from "qidigo-components/button.js";

import {defineMessages, FormattedMessage} from "react-intl";

const translations = defineMessages({
    "apply": {
        id: "qidigo.billing.contact_credit.apply",
        defaultMessage: "Appliquer"
    },
    "available": {
        id: "qidigo.billing.contact_credit.available",
        defaultMessage: "Vous avez un crédit disponible de\u00A0: "
    },
    "credit_display": {
        id: "qidigo.billing.contact_credit.credit_display",
        defaultMessage: "{value} $"
    },
    "maximum_usable": {
        id: "qidigo.billing.contact_credit.maximum_usable",
        defaultMessage: "Maximum utilisable sur cette facture\u00A0: {value} $"
    }
});

interface IContactCreditProps {
    credit_total: number,
    applied_credit: number,
    apply_contact_credit: (applyingCredit: number) => void,
    balance: number
}

class ContactCredit extends Component<IContactCreditProps> {
    render() {
        return (
            <div className={"contact-credit--box"}>
                    <span className={"contact-credit--text"}>
                        <FormattedMessage {...translations["available"]} />
                    </span>&nbsp;
                <span className={"contact-credit--money"}>
                        <FormattedMessage {...translations["credit_display"]}
                                          values={{value: this.props.credit_total.toFixed(2)}}/>
                    </span>&nbsp;
                {
                    (this.props.credit_total > this.props.balance) &&
                    <span className={"contact-credit--text"}>
                                (<FormattedMessage {...translations["maximum_usable"]}
                                                   values={{value: this.props.balance.toFixed(2)}}/>)
                            </span>
                }
                <Button
                    className={"contact-credit--button"}
                    onClick={() =>
                        this.props.apply_contact_credit(
                            this.props.credit_total > this.props.balance
                                ? this.props.balance
                                : this.props.credit_total
                        )}
                >
                    <FormattedMessage {...translations["apply"]} />
                </Button>
            </div>
        )
    }
}

export default ContactCredit;
