import React                 from "react";
import PropTypes             from "prop-types";
import sanitizeHTML          from "sanitize-html";

/**
 * Change a particular tag for a div with a class
 */
const substituteForDiv = (el, tag) => {
	const tree = document.createElement("div");
	tree.innerHTML = el;

	let result;
	while (result = tree.getElementsByTagName(tag)[0]) {
		const substitutionDiv = document.createElement("div");
		substitutionDiv.innerHTML = result.innerHTML;
		substitutionDiv.classList.add(`fake-${tag}`);
		result.parentNode.replaceChild(substitutionDiv, result);
	}
	
	return tree.innerHTML;
};

const addBlankTargetAttributeToLinkTag = (el) => {
	let tree = document.createElement('div');
	tree.innerHTML = el;

	tree.getElementsByTagName('a').forEach(
		(link) => {
			link.target = "_blank"
		}
	)

	return tree.innerHTML;
};

const HTML = (props, context) => {
	let {contents} = props;
	// Get all options from defaults
	let options = sanitizeHTML.defaults;

	// Only add what we need to keep it secure
	// Allow h1 to h3 for admin WYSIWYG (there is no 4 to 6)
	options["allowedTags"].push("u", "h1", "h2", "h3");
	
	// Ignore les null values.
	contents = contents || "";
	let description = <div className="is-raw-text"><p>{contents.trim()}</p></div>;

	// Looks like HTML?
	// Must be HTML.
	if (contents.match(/<[a-zA-Z]+.*>/)) {

		// Catches h1 to h3 and change them for div with classes to avoid breaking dom.
		//Catch links and add target _blank to open in new tab
		let html = ["h1", "h2", "h3"].reduce(
			(res, tag) => substituteForDiv(res, tag),
			sanitizeHTML(contents, options)
		);

		html = addBlankTargetAttributeToLinkTag(html);

		description = <div className="is-formatted-text" dangerouslySetInnerHTML={{__html: html}} />;
	}

	return description;
};

HTML.propTypes = {
	contents: PropTypes.string,
};

export default HTML;
