import {combineReducers} from "redux";
import * as PROFILE from "../actions/profile";

/**
 */
const waiting_lists = (state = {}, action) => {
	switch (action.type) {
		case PROFILE.INVALIDATE_WAITING_LISTS:
			return Object.assign({}, state, {
				loading: false,
				error: null,
				at: null,
				registrations: null,
				bundled: null,
			});

		case PROFILE.RECEIVE_WAITING_LISTS_FAILED:
			return Object.assign({}, state, {
				loading: false,
				error: action.error,
				at: null,
				registrations: null,
				bundled: null,
			});

		case PROFILE.REQUEST_WAITING_LISTS:
			return Object.assign({}, state, {
				loading: true,
				error: null,
			});

		case PROFILE.RECEIVE_WAITING_LISTS:
			return Object.assign({}, state, {
				loading: false,
				error: null,
				at: action.at,
				registrations: action.registrations,
				bundled: action.bundled,
			});

		case PROFILE.DELETE_WAITING_LIST:
			return Object.assign({}, state, {
				loading: true,
				error: null,
			});

		case PROFILE.DELETED_WAITING_LIST:
			return Object.assign({}, state, {
				loading: false,
				error: null,
			});

	}

	return state;
};

export default combineReducers({waiting_lists});
