import * as React from "react";
import {Component} from "react";
import {defineMessages, FormattedMessage} from "react-intl";

// @ts-ignore
import Input from "qidigo-form/input";
// @ts-ignore
import PropTypes from "prop-types";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Auth from "qidigo-auth";
// @ts-ignore
import Checkbox from "qidigo-form/checkbox";
import {Link} from "react-router/es";
// @ts-ignore
import Loading from 'qidigo-components/loading';
import SeparationLine from "../SeparationLine";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
// @ts-ignore
import {getApiUri} from "@app/modules/qidigo-fetch";


const translations = defineMessages({
    "forgot_password": {
        id: "qidigo.login.partial.forgot",
        defaultMessage: "Mot de passe oublié"
    },
    "remember": {
        id: "qidigo.login.partial.remember",
        defaultMessage: "Se souvenir de moi"
    },
    "email": {
        id: "qidigo.activity.pass_selection_modal.connection.email",
        defaultMessage: "Courriel"
    },
    "password": {
        id: "qidigo.activity.pass_selection_modal.connection.password",
        defaultMessage: "Mot de passe"
    },
    "login": {
        id: "qidigo.activity.pass_selection_modal.connection.login",
        defaultMessage: "Connexion"
    },
    "create_account": {
        id: "qidigo.activity.pass_selection_modal.connection.create_account",
        defaultMessage: "Créer un compte"
    },
})

const errorMessages = defineMessages({
    "other_error": {
        id: "qidigo.login.errors.other_error",
        defaultMessage: "Une erreur inattendue est survenue. Veuillez réessayer sous peu."
    },
    "invalid_user": {
        id: "qidigo.login.errors.invalid_user",
        defaultMessage: "Vos identifiants de connexion sont invalides. Veuillez valider votre adresse courriel et votre mot de passe."
    },
});

const providers = [
    {name: "facebook", url: getApiUri() + "/api/v1/authenticate/facebook"},
    {name: "google", url: getApiUri() + "/api/v1/authenticate/google"},
];

interface ILoginProps {
    handleNextStep: () => void;
    goToRegister: () => void;
    handleShowConsentPage: () => void;
    organizationId: number;
}

interface ILoginState {
    user: {
        email: string;
        password: string;
        remember: boolean;
    }
    loading: boolean;
    hasErrors: boolean;
    errors: string
}

class Login extends Component<ILoginProps, ILoginState> {
    private form: any;

    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            user: {
                email: "",
                password: "",
                remember: true,
            },
            loading: false,
            hasErrors: false,
            errors: "",
        }
    }

    onLoginSubmit = (e) => {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;
        const remember = e.target.remember.checked;

        this.setState({loading: true, hasErrors: false});

        Auth.login(email, password, {remember})
            .then(async () => {
                this.setState({loading: false});

                let isUserContact = false;
                await qidigoFetch.get("whoami").then((response) => {
                    isUserContact = response.contacts.find((contact) => contact.organization_id === this.props.organizationId)
                })

                if (!isUserContact) {
                    this.props.handleShowConsentPage();
                    return;
                }

                this.props.handleNextStep();
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    hasErrors: true,
                    errors: error,
                    user: {
                        email:email,
                        password: password,
                        remember: remember
                    }});
            })
        ;
    }

    onCheck = () => {
        this.state.user.remember = !this.state.user.remember;
        this.setState({
            user: this.state.user
        })
    }

    onEmailChange = (e) => {
        this.state.user.email = e.value

        this.setState({
            user: this.state.user
        })
    }

    onPasswordChange = (e) => {
        this.state.user.password = e.value

        this.setState({
            user: this.state.user
        })
    }

    render() {
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        let wrapperClasses = ["fragment-emailpassword"];

        return (
            this.state.loading
                ? <Loading/>
                :
                <form
                    className={wrapperClasses.join(" ")}
                    onSubmit={this.onLoginSubmit}
                    ref={(node) => this.form = node}
                >
                    <div className={"pass-selection-login--form-container"}>
                        {
                            this.state.hasErrors &&
                            <div className={"pass-selection-modal--error-box"}>
                                {formatMessage(errorMessages[this.state.errors])}
                            </div>
                        }
                        <Input
                            name="email"
                            label={formatMessage(translations['email'])}
                            type="email"
                            required={true}
                            autoFocus={true}
                            value={this.state.user.email}
                            onChange={this.onEmailChange}
                        />
                        <Input
                            name="password"
                            label={formatMessage(translations['password'])}
                            type="password"
                            required={true}
                            value={this.state.user.password}
                            onChange={this.onPasswordChange}
                        />
                        <div className="pass-selection-login--forgot-password-container">
                            <Link
                                to="/forgot/password"><FormattedMessage {...translations["forgot_password"]} /></Link>
                        </div>
                        <Checkbox
                            onClick={this.onCheck}
                            value={this.state.user.remember}
                            className={"pass-selection-login--remember-checkbox"}
                            name="remember"
                            label={formatMessage(translations["remember"])}
                        />
                    </div>
                    <div className={"pass-selection-login--buttons-container"}>
                        <Button className={"pass-selection-modal--primary-button"} type="submit">
                            <FormattedMessage {...translations['login']}/>
                        </Button>
                        <SeparationLine isVertical={false}/>
                        <Button onClick={this.props.goToRegister} className={"pass-selection-modal--secondary-button"}>
                            <FormattedMessage {...translations['create_account']}/>
                        </Button>
                    </div>
                </form>
        );
    }
}

// @ts-ignore
Login.contextTypes = {
    intl: PropTypes.object,
};

export default Login;
