import React, {Component} from "react";
import PropTypes from "prop-types";
import Radio from "qidigo-form/radio";
import {defineMessages} from "react-intl";

/**
 * Template for a question of type yes no.
 */
const translations = defineMessages({
	yes: {id: "qidigo.dashboard.form.question.yes", defaultMessage: "Oui"},
	no: {id: "qidigo.dashboard.form.question.no", defaultMessage: "Non"},
});

class YesNoTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
		} = this.props;
		const {formatMessage} = this.context.intl;
		const {bool=""} = answer || {};

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Radio label={formatMessage(translations["yes"])} name="bool" value="yes" checked={bool === "yes"} onChange={(...e)=>this.handleChange("bool", ...e)}  />
					<Radio label={formatMessage(translations["no"])} name="bool" value="no" checked={bool === "no"} onChange={(...e)=>this.handleChange("bool", ...e)}  />
				</div>
			</div>
		);
	}
}

YesNoTypeForm.defaultProps = {
	className: "",
	errorMessage: "",
};

YesNoTypeForm.contextTypes = {
	intl: PropTypes.object,
};

YesNoTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	errors: PropTypes.object,
};

export default YesNoTypeForm;

