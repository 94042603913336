import React                 from "react";
import PropTypes             from "prop-types";
import {Link}                from "react-router/es";
import Image                 from "qidigo-components/image.js";
import {
	defineMessages
} from "react-intl";

const translations = defineMessages({
	logo: {id: "qidigo.organization.logo",  defaultMessage: "Logo pour l'organisme {organization}"},
});

/**
 * Component à utiliser pour faire un lien vers la page d'un organisme.
 * Avec ce component, il est garanti que le lien va toujours être présenté de manière constante
 * (utilise la bonne formulation de nom, utilise la bonne formulation d'URL)
 *
 * De plus, il est possible d'avoir quelques extras comme en extraire le logo.
 */
const OrganizationLink = (props, context) => {
	const {className, organization, withLogo, ...leftoverProps} = props;
	const classNames = ["organization-link", className];
	const {formatMessage} = context.intl;

	// S'assure de ne pas crasher l'app.
	if (!organization) {
		// En dev, plus verbose.
		if (process.env.NODE_ENV === "development") {
			return (
				<pre
					className={classNames.join(" ")}
				>(undefined organization)</pre>
			);
		}

		// En prod, plus sane.
		return <span
			className={classNames.join(" ")}
		/>;
	}

	let logo = null;
	if (withLogo) {
		logo =
			<Image
				className="organization-link--logo organization-logo"
				src={organization.logo ? organization.logo.path : ""}
				alt={formatMessage(translations["logo"], {organization: organization.name})}
				title={formatMessage(translations["logo"], {organization: organization.name})}
			/>
			;
	}

	return (
		<Link
			to={`/u/${organization.slug}`}
			className={classNames.join(" ")}
			{...leftoverProps}
		>
			{logo}
			{organization.name}
		</Link>
	);
};

OrganizationLink.propTypes = {
	className:    PropTypes.string,
	organization: PropTypes.shape({
		slug: PropTypes.string,
		name: PropTypes.string,
		logo: PropTypes.shape({
			path: PropTypes.string.isRequired,
		})
	}).isRequired,
	withLogo: PropTypes.bool,
};

OrganizationLink.defaultProps = {
	withLogo: false,
};

OrganizationLink.contextTypes = {
	intl: PropTypes.object,
};

export default OrganizationLink;
