import React                 from "react";
import PropTypes             from "prop-types";
import Helmet                from "react-helmet";

const Title = ({organization, title, children}) =>
	<Helmet
		title={`${organization["name"]}${title ? " — " + title : ""}`}
	>
		{children}
	</Helmet>
	;

Title.propTypes = {
	title: PropTypes.string,
	organization: PropTypes.object.isRequired,
};

export default Title;
