import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {connect}             from "@app/lib/redux";
import Fetch                 from "qidigo-fetch";
import View                  from "@app/views/organization/membership/list";
import {navigate}            from "qidigo-router";
import {
	fetchMemberships,
	invalidateMemberships,
}   from "@app/store/actions/search/memberships";

/**
 * Contrôleur pour *un membership*.
 *
 * C'est-à-dire, la liste des offres du membership.
 */
class OrganizationMembershipsController extends Component {
	constructor() {
		super();
	}

	/**
	 * Charge le data pour le contrôleur.
	 * UNIQUEMENT POUR LE SSR.
	 * Utilise le slug pour trouver l'organisme, pour ensuite trouver la liste.
	 * (API publique)
	 */
	static load(dispatch, params) {
		const slug = params["orgID"]

		// Fetch de organizations pour retrouver le `id` à partir du slug.
		return Promise.all([
			Fetch.get(`organizations?slug=${slug}`).then((response) => {
				const {id} = response[0];

				return dispatch(fetchMemberships(id));
			}),
		]);
	}

	/**
	 * Charge les informations, en dynamique.
	 */
	load() {
		const {dispatch, organization: {id}} = this.props;
		dispatch(fetchMemberships(id));
	}

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		// FIXME : Comprendre pourquoi je dois invalider ici...
		const {dispatch} = this.props;
		dispatch(invalidateMemberships());
	}

	render() {
		const {
			memberships,
			organization,
		} = this.props;

		return <View {...{memberships, organization}} />;
	}
}

OrganizationMembershipsController.propTypes = {
	organization: PropTypes.object,
	dispatch: PropTypes.func,
	memberships: PropTypes.array,
};

OrganizationMembershipsController.contextTypes = {
	intl: PropTypes.object,
};

// Connecte avec le store redux.
const withProperties = ({search: {memberships: {memberships, fetching}}}) => ({memberships, fetching});
export default connect(withProperties)(OrganizationMembershipsController);
