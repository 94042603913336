import * as React from "react";
import {Component} from "react";
import {IBillingAddress} from "../../../types";
// @ts-ignore
import Loading from 'qidigo-components/loading';
import StripeCardInput from "./stripeCardInput";

let stripeInstance = null

interface StripeCreditCardFormProps {
    api_key: string,
    userName: string,
    billingAddress: IBillingAddress | null,
    isInstallmentMissing: boolean,
    handlePayment: (token: string) => void,
    processingPayment: boolean,
    disabled: boolean
}

interface StripeCreditCardFormState {
    error: boolean,
    unexpectedAddressError: boolean,
    unexpectedInstallmentError: boolean,
    cardComplete: boolean,
    expComplete: boolean,
    cvcComplete: boolean,
    hasInstantiatedField: boolean,
    amountOfTryOnRefreshing: number,
    isRefreshingComponent: boolean,
    stripeLoaded: boolean
}

class stripeCreditCardForm extends Component<StripeCreditCardFormProps, StripeCreditCardFormState> {
    // @ts-ignore
    cardNumber = null;

    constructor(props: StripeCreditCardFormProps) {
        super(props);
        this.state = {
            error: false,
            unexpectedAddressError: false,
            unexpectedInstallmentError: false,
            cardComplete: false,
            expComplete: false,
            cvcComplete: false,
            hasInstantiatedField: false,
            amountOfTryOnRefreshing: 0,
            isRefreshingComponent: false,
            // @ts-ignore
            stripeLoaded: typeof Stripe !== 'undefined',
        };
    }

    componentDidMount() {
        if (this.state.stripeLoaded) {
            this.instantiateStripeField()
        }
    }

    componentDidUpdate(prevProps: Readonly<StripeCreditCardFormProps>,
                       prevState: Readonly<StripeCreditCardFormState>): void {
        if(prevState.stripeLoaded !== this.state.stripeLoaded) {
            this.instantiateStripeField();
        }
    }

    pay = () => {
        if (!stripeInstance || !this.cardNumber) {
            this.setState({
                error: true,
            });

            return;
        }

        if (this.props.isInstallmentMissing) {
            this.setState({
                unexpectedInstallmentError: true
            });

            return;
        }

        if (this.props.billingAddress === null) {
            this.setState({
                unexpectedAddressError: true
            });

            return;
        }

        const data = {
            name: this.props.userName,
            address_country: this.props.billingAddress.country,
            address_zip: this.props.billingAddress.postal_code,
            address_state: this.props.billingAddress.state,
            address_city: this.props.billingAddress.city,
            address_line1: this.props.billingAddress.civic_number_and_street,
            address_line2: this.props.billingAddress.civic_number_ext !== null
                ? this.props.billingAddress.civic_number_ext
                : "",
        };

        // @ts-ignore stripeInstance cannot be null at this point
        stripeInstance.createToken(this.cardNumber, data).then((result) => {
            if (result.error) {
                this.setState({
                    error: true,
                })
            }

            if (result.token) {
                this.setState({
                    error: false,
                })
                this.props.handlePayment(result.token.id);
            }
        });
    }

    instantiateStripeField() {
        if (!stripeInstance) {
            // @ts-ignore
            stripeInstance = Stripe(this.props.api_key);
        }

        // @ts-ignore at this point stripeInstance cannot be null
        const elements = stripeInstance.elements();
        const cardNumber = elements.create("cardNumber");
        const cardExpiry = elements.create("cardExpiry");
        const cardCvc = elements.create("cardCvc", {
            placeholder: 'CVV'
        });
        cardCvc.placeholder = 'CVV'
        cardNumber.mount("#card-number");
        cardExpiry.mount("#expiration-date");
        cardCvc.mount("#cvv");

        this.cardNumber = cardNumber;
        cardNumber.on('change', (event) => {
            let complete = false;

            if (event.complete) {
                complete = true;
            }

            this.setState({
                cardComplete: complete
            });
        });

        cardExpiry.on('change', (event) => {
            let complete = false;

            if (event.complete) {
                complete = true;
            }
            this.setState({
                expComplete: complete
            });
        });

        cardCvc.on('change', (event) => {
            let complete = false;

            if (event.complete) {
                complete = true;
            }
            this.setState({
                cvcComplete: complete
            });
        });
        this.setState({
            isRefreshingComponent: false,
            hasInstantiatedField: true
        })

        this.stripeHasLoaded()
    }

    stripeHasLoaded = () => {
        this.setState({
            stripeLoaded: true
        })
    }

    render() {
        return (
            <StripeCardInput
                unexpectedAddressError={this.state.unexpectedAddressError}
                paymentPlatformError={this.state.error}
                unexpectedInstallmentError={this.state.unexpectedInstallmentError}
                payAction={this.pay.bind(this)}
                hasInstantiatedField={this.state.hasInstantiatedField && !this.props.processingPayment}
                refreshComponent={() => {return}}
                amountOfTryOnRefreshing={this.state.amountOfTryOnRefreshing}
                isRefreshingComponent = {this.state.isRefreshingComponent}
                stripeAlreadyLoaded={this.state.stripeLoaded}
                stripeHasLoaded={this.stripeHasLoaded.bind(this)}
                disabled={
                    !this.state.cardComplete
                    || !this.state.expComplete
                    || !this.state.cvcComplete
                    || this.props.disabled
                    || this.props.processingPayment
                    || this.state.error
                }
            />
        );
    }
}

export default stripeCreditCardForm;
