/**
 * Qidigo Session Store
 * ====================
 *
 * Stocke des données dans la *session* de l'utilisateur.
 *
 * CES DONNÉES DOIVENT ÊTRE CONSIDÉRÉES COMME NON-SÉCURITAIRE;
 * c'est-à-dire que l'utilisateur y a accès.
 *
 * Alternativement, appelés des cookies...
 */
import Cookies               from "js-cookie";

/**
 * Préfixe utilisé par les cookies du *session store*.
 */
const COOKIE_PREFIX = "com.qidigo";

class QidigoSessionStore {
	/**
	 *
	 * #### `opts`
	 *
	 *   * `expires` → Expiration.
	 */
	constructor(name, options = {}) {
		this.name = `${COOKIE_PREFIX}.${name}`;
		this.options = options;
	}

	get() {
		return Cookies.getJSON(this.name);
	}

	set(value) {
		Cookies.set(this.name, value, this.options);
	}

	remove() {
		Cookies.remove(this.name);
	}
}

export default QidigoSessionStore;
