import React                 from "react";
import PropTypes             from "prop-types";
import OfferForm             from "./_offerform";
import HTML                  from "@app/components/html"
import SubscribeToOrganizationConfirmationModal from "../../organization/_personal_information_consent";
import Auth                  from "qidigo-auth";

const OffersFragment = (props, context) => {
    const {offers, familyMembers, newStatus, onAddToCart, disabled, groupRestrictions, organization} = props;

    if (!offers) { return <div></div>; }
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const {hasContact} = context;
    const [hasConsent, setHasConsent] = React.useState(false);
    const [nextProcess, setNextProcess] = React.useState(() => {});
    const hasContactAlready = hasContact(organization.id);
    const onAddToCartAfterConsent = (planId, subscriber) => {
        setIsModalOpen(true);
        setNextProcess(() => () => onAddToCart(planId, subscriber));
    }

    const onConsent = () => {
        setIsModalOpen(false);
        setHasConsent(true);
        Auth.refreshUser();
    }

    // TODO : Ajouter le rendering des noms de groups éligibles pour multiples groupes.
    return (
        <div className="offer-fragments">
            {
                !(hasConsent || hasContactAlready)
                && <SubscribeToOrganizationConfirmationModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={nextProcess}
                    onConsent={onConsent}
                    organization={organization}
                />
            }
            <ul>
                {offers.map((offer) => {
                    const {plan} = offer;
                    const description = [];

                    description.splice(0, description.length);
                    description.push(
                        <div>
                            <HTML
                                contents={plan["description"]}
                            />
                        </div>
                    );


                    return (
                        <li key={plan.id} className="offer-fragment">
                            <h3>{plan["name"]}</h3>
                            <OfferForm
                                familyMembers={familyMembers}
                                newStatus={newStatus}
                                plan={plan}
                                groupRestrictions={groupRestrictions}
                                options={offer.options}
                                onAddToCart={
                                    (hasConsent || hasContactAlready) ? onAddToCart : onAddToCartAfterConsent
                                }
                                disabled={disabled}
                                description={(
                                    <p>
                                        {description}
                                    </p>
                                )}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

OffersFragment.propTypes = {
    groupRestrictions: PropTypes.object,
    offers: PropTypes.arrayOf(PropTypes.object),
    familyMembers: PropTypes.object,
    newStatus: PropTypes.object,
    onAddToCart: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    organization: PropTypes.object,
};

OffersFragment.contextTypes = {
    intl: PropTypes.object,
    hasContact: PropTypes.func,
};

export default OffersFragment;
