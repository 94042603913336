import React, {Component} from "react";
import PropTypes from "prop-types";
import Input from "qidigo-form/input";

/**
 * Template for a question of type email.
 */

class EmailTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;

		const {email=""} = answer || {};


		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Input error={errors["email"]} value={email} onChange={(...e)=>this.handleChange("email", ...e)} icon="email" type="email" />
				</div>
			</div>
		);
	}
}

EmailTypeForm.defaultProps = {
	className: "",
};

EmailTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errors: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
};

export default EmailTypeForm;
