import {defineMessages}      from "react-intl";

const STATES_TRANSLATIONS = defineMessages({
	'alberta': { id: "DATA.states.CA.AB", defaultMessage: "Alberta" },
	'prince_edward_island': { id: "DATA.states.CA.PE", defaultMessage: "Île-du-Prince-Édouard" },
	'quebec': { id: "DATA.states.CA.QC", defaultMessage: "Québec" },
	'ontario': { id: "DATA.states.CA.ON", defaultMessage: "Ontario" },
	'newfoundland_and_labrador': { id: "DATA.states.CA.NL", defaultMessage: "Terre-Neuve-et-Labrador" },
	'british_columbia': { id: "DATA.states.CA.BC", defaultMessage: "Colombie-Britannique" },
	'manitoba': { id: "DATA.states.CA.MB", defaultMessage: "Manitoba" },
	'new_brunswick': { id: "DATA.states.CA.NB", defaultMessage: "Nouveau-Brunswick" },
	'northwest_territories': { id: "DATA.states.CA.NT", defaultMessage: "Territoires du Nord-Ouest" },
	'nova_scotia': { id: "DATA.states.CA.NS", defaultMessage: "Nouvelle-Écosse" },
	'nunavut': { id: "DATA.states.CA.NU", defaultMessage: "Nunavut" },
	'saskatchewan': { id: "DATA.states.CA.SK", defaultMessage: "Saskatchewan" },
	'yukon': { id: "DATA.states.CA.YT", defaultMessage: "Yukon" }
});

export default STATES_TRANSLATIONS;
