import React                 from "react";
import Helmet                from "react-helmet";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import snakeCase             from "lodash/snakeCase";

import Button                from "qidigo-components/button.js";
import ButtonsBar            from "qidigo-form/buttons_bar.js";
import Address               from "@app/views/shared/user/address.js";
import EmptyList             from "@app/components/empty_list";

const translations = defineMessages({
	"title":  {id: "qidigo.dashboard.addresses.page_title", defaultMessage: "Mon carnet d'adresses"},
	"default": {id: "qidigo.dashboard.addresses.default", defaultMessage: "Principale"}
});
const actions = defineMessages({
	"new":  {id: "qidigo.dashboard.addresses.new_address", defaultMessage: "Ajouter une adresse"},
	"edit": {id: "qidigo.dashboard.addresses.edit", defaultMessage: "Modifier"},
	"set-default": {id: "qidigo.dashboard.addresses.set_default", defaultMessage: "En faire mon adresse principale"},
});

/**
 * Une carte d'adresse.
 */
const AddressCard = ({address, setDefault, editAddressLinkProperties}, {intl: {formatMessage}}) =>
	<li className={`address-card type-${snakeCase(address.type_address)} address_id-${address.id}`}>
		<nav>
			<ul>
				{
					address.type_address !== "ADDR_DEFAULT" &&
						<li>
							<Button
								className={"action-set-default"}
								title={formatMessage(actions["set-default"])}
								onClick={(...e) => setDefault(address.id)}
							>
								{formatMessage(actions["set-default"])}
							</Button>
						</li>
				}
				<li>
					<Button
						{...editAddressLinkProperties}
						className={"action-edit"}
						title={formatMessage(actions["edit"])}
					>
						{formatMessage(actions["edit"])}
					</Button>
				</li>
			</ul>
		</nav>
		<Address
			address={address}
			tag={address.type_address === "ADDR_DEFAULT" ? formatMessage(translations["default"]) : null}
		/>
	</li>
	;

AddressCard.propTypes = {
	// FIXME : Address shape
	address: PropTypes.object,
	setDefault: PropTypes.func.isRequired,
	editAddressLinkProperties: PropTypes.object.isRequired,
};

AddressCard.contextTypes = {
	intl: PropTypes.object,
};

/**
 * Page avec la liste des adresses et les actions.
 */
const AddressesPage = (props, context) => {
	const {formatMessage} = context.intl;
	const {
		addresses,
		setDefault,
		getNewAddressLinkProperties,
		getEditAddressLinkProperties,
	} = props;

	return (
		<section className="dashboard-addressbook">
			<h1><FormattedMessage {...translations.title} /></h1>
			<Helmet
				title={formatMessage(translations["title"])}
			/>
			<EmptyList>
				{addresses && addresses.map((address) =>
					<AddressCard
						key={address.id}
						address={address}
						setDefault={setDefault}
						editAddressLinkProperties={getEditAddressLinkProperties(address)}
					/>
				)}
			</EmptyList>
			<ButtonsBar withoutSubmit={true}>
				<Button
					{...getNewAddressLinkProperties()}
					className={"action-new"}
				>
					{formatMessage(actions["new"])}
				</Button>
			</ButtonsBar>
		</section>
	);
};

AddressesPage.propTypes = {
	addresses: PropTypes.arrayOf(AddressCard.propTypes.address),
	setDefault: PropTypes.func.isRequired,
	getNewAddressLinkProperties: PropTypes.func.isRequired,
	getEditAddressLinkProperties: PropTypes.func.isRequired,
};

AddressesPage.contextTypes = {
	intl: PropTypes.object,
};

export default AddressesPage;
