import * as React from "react";
import {Component} from "react";
// @ts-ignore
import TextField from "../../common/components/forms/TextField"
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Image from 'qidigo-components/image.js'
// @ts-ignore
import SeparationLine from "./SeparationLine";
// @ts-ignore
import Money from "@app/components/money";
import * as events from "events";
import Select from "react-select";
// @ts-ignore
import {IWeekDays} from "../types";
// @ts-ignore
import PropTypes from "prop-types";
// @ts-ignore
import {RestrictionsFragment, groupRestrictions} from "qidigo-data/restrictions";
import {locale} from "moment";


const translations = defineMessages({
    "0": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.sunday",
        defaultMessage: " Dim"
    },
    "1": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.monday",
        defaultMessage: " Lun"
    },
    "2": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.tuesday",
        defaultMessage: " Mar"
    },
    "3": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.wednesday",
        defaultMessage: " Mer"
    },
    "4": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.thursday",
        defaultMessage: " Jeu"
    },
    "5": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.friday",
        defaultMessage: " Ven"
    },
    "6": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.saturday",
        defaultMessage: " Sam"
    },
    "7": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description.sunday",
        defaultMessage: " Dim"
    },
})

interface ICalendarDaysProps {
    startDate: string;
}


interface ICalendarDaysState {
    weekDays: Array<IWeekDays>
    today: string
}


class CalendarDays extends Component<ICalendarDaysProps, ICalendarDaysState> {
    constructor(props: ICalendarDaysProps) {
        super(props);

        this.state = {
            weekDays: [],
            today: ''
        }
    }

    componentDidMount() {
        const {startDate}  = this.props;
        const {formatMessage} = this.context.intl;
        const weekDays: IWeekDays = [];

        const date = new Date(startDate);
        const daysUntilSunday = locale() === 'en' ? (7 + date.getDay()) % 7 : (6 + date.getDay()) % 7;
        const firstDayOfWeek = new Date(startDate);
        firstDayOfWeek.setDate(date.getDate() - daysUntilSunday);

        for (let i = 0; i < 7; i++) {
            const dateOfWeek = new Date(firstDayOfWeek);
            dateOfWeek.setDate(dateOfWeek.getDate() + +i);
            const dayIndex = dateOfWeek.getDay();
            const dayOfWeek = formatMessage(translations[dayIndex]);
            const dayOfMonth = dateOfWeek.getDate();

            weekDays.push({dayOfMonth: dayOfMonth, dayOfWeek: dayOfWeek, fullDate: dateOfWeek.toDateString()});
        }

        this.setState({
            weekDays: weekDays,
            today: new Date().toDateString()
        })
    }

    render() {
        return (
            <div className={"calendar-days" + (locale() === 'en' ? " calendar-days--en" : "")}>
                {
                    this.state.weekDays.map((weekDay, index) => {
                        return (
                            <div key={index} className={"calendar-days--day"}>
                                <div className={"calendar-days--day-of-week"}>
                                    {weekDay.dayOfWeek}
                                </div>
                                <div className={"calendar-days--day-of-month" + (this.state.today === weekDay.fullDate ? "-today" : "")}>
                                    {weekDay.dayOfMonth}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

}

// @ts-ignore
CalendarDays.contextTypes = {
    intl: PropTypes.object,
};


export default CalendarDays;
