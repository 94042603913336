import React, {Component} from "react";
import PropTypes             from "prop-types";
import "./image.less";

/**
 * Système de remplacement d'image permettant de présenter les images avec
 * des styles CSS comme un background-size, permettant d'utiliser un contain
 * ou un cover sur des images.
 *
 * Le système utilise un <img /> pour écouter pour un potentiel `onError` et
 * effectue un remplacement sur une image par défaut lorsque l'image ne peut
 * pas charger.
 *
 * IL N'Y A PAS DE SYSTÈME QUI DONNE DES DIMENSIONS PAR DÉFAUT À CE CONTENEUR.
 * IL FAUT QUE VOUS VOUS ASSURIEZ DE DONNER DES DIMENSIONS À CETTE IMAGE.
 *
 * **À noter**: La classe du component est `fakeimage` parce que `image` est
 * trop générique et à risque de causer des conflits.
 *
 * **À noter**: Pour l'impression, l'image *devrait* être réutilisée.
 * Le CSS par défaut s'en occupe. La fonction `componentDidUpdate` lit le
 * style calculé par le navigateur pour placer l'image de rempalcement dans le
 * tag `<img>`.
 *
 * @extends {Component}
 */
class Image extends Component {
	constructor() {
		super();
		this.state = {
			error: false,
			loading: true,
			src: null,
		};
	}
	componentWillMount() {
		const {src} = this.props;
		this.updateSrc(src);
	}
	componentWillReceiveProps(nextProps) {
		// Quand la source de l'image change, il faut
		// rafraîchir le state "à neuf".
		if (this.props.src !== nextProps.src) {
			this.updateSrc(nextProps.src);
		}
	}
	updateSrc(src) {
		if (!src || src === "") {
			this.setState({
				error: true,
				loading: false,
				src: null,
			});
		}
		else {
			this.setState({
				error: false,
				loading: true,
				src: src,
			});
		}
	}
	loadDefault(event) {
		if (!this.state.error) {
			this.setState({error: true, loading: false, src: ""});
		}
		event.preventDefault();
	}
	finishedLoading(event) {
		this.setState({loading: false});
	}

	/**
	 * Rend l'image par défaut imprimable.
	 * (Oui, c'est un hack, mais print CSS est hard)
	 */
	componentDidUpdate() {
		if (this.state.error) {
			const {backgroundImage} = window.getComputedStyle(this.fakeImageNode);
			if (backgroundImage !== "none") {
				const src = backgroundImage
					.replace(/^url\("?/, "")
					.replace(/"?\)/, "");
				if (this.state.src !== src) { this.setState({src}); }
			}
		}
	}

	render() {
		const {
			className,
			title,
			alt,
			withSpinner,
			// On ne veut pas *ce* src, mais on ne veut pas le passer au div.
			src: _, // eslint-disable-line
			...props
		} = this.props;

		const {src} = this.state;

		const classNames = ["fake-image", className];

		const thumbStyles = {
		};

		if (this.state.loading && withSpinner) {
			classNames.push("is-loading");
		}

		if (this.state.error) {
			classNames.push("is-error");
		}
		else {
			thumbStyles.backgroundImage = `url(${src})`;
		}

		return (
			<div
				className={classNames.join(" ")}
				title={title}
				ref={(node) => this.wrapperNode = node}
				{...props}
			>
				<div
					className="fake-image--image-element"
					style={thumbStyles}
					ref={(node) => this.fakeImageNode = node}
				/>
				{
					src && src !== "" ?
						<img
							className="fake-image--image-img"
							src={src}
							onError={(e)=>{ this.loadDefault(e); }}
							onLoad={(e)=>{ this.finishedLoading(e); }}
							title={title}
							alt={alt}
						/> : null
				}
			</div>
		);
	}
}

Image.propTypes = {
	className:    PropTypes.string,
	title:        PropTypes.string,
	alt:          PropTypes.string,
	src:          PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	withSpinner:  PropTypes.bool,
};

Image.defaultProps = {
	src: "",
	withSpinner: true,
};

export default Image;
