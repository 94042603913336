import React, {Component}    from "react";
import PropTypes             from "prop-types";
import shallowCompare        from "react-addons-shallow-compare";

// FIXME : Trouver un moyen de synchroniser les constantes entre les projets.
const NETWORKS = [
	// Pas vraiment "social", mais règle un problème épineux.
	"Website",
	"Facebook",
	"X",
	"Google +",
	"Youtube",
	"Instagram",
];

/**
 * Component spécial qui représente une liste de liens sociaux.
 *
 * Ce component a peu d'opinion sur son look, mais *filtre* un array
 * de liens tel que retourné par l'API.
 */
class SocialLinks extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this.props) && shallowCompare(this.props.links);
	}

	render() {
		const {
			className,
			links
		} = this.props;
		const classes = ["sociallinks", className];

		let socialLinks = [];

		for (const link of links) {
			const linkType = NETWORKS[NETWORKS.indexOf(link.type_url.name)];
			const typeClass = linkType.toLowerCase().replace(/\+/, "plus").replace(/ /, "").replace(/[^a-z]*/, "");
			// On utilise une classe spéciale pour l'icone.
			const iconClass = `social icon provider provider-${typeClass}`;
			socialLinks.push(
				<li key={link["id"] || linkType} className={`sociallinks--item provider-${typeClass}`}>
					<a href={link.url} target="_blank" className={iconClass}>
						<span>{linkType}</span>
					</a>
				</li>
			);
		}

		return (
			<ul className={classes.join(" ")}>
				{socialLinks}
			</ul>
		);
	}
}

SocialLinks.propTypes = {
	className: PropTypes.string,
	links:     PropTypes.arrayOf(
		PropTypes.shape({
			id:       PropTypes.numeric,
			url:      PropTypes.string,
			type_url: PropTypes.shape({ // eslint-disable-line
				name: PropTypes.string,
			}),
		})
	),
};

export default SocialLinks;
