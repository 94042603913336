import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

import OrgInformations       from "@app/views/shared/organization/organization_informations.js";
import MapColumns            from "@app/views/shared/_map_and_columns.js";

const translations = defineMessages({
	"title.organization": {id: "qidigo.group.title.organization", defaultMessage: "Informations sur l'entreprise"},
	"title.location": {id: "qidigo.group.title.location", defaultMessage: "Directions"},
});


/**
 * Affichage de la page de groupe.
 */
const GroupView = (props, context) => {
	const {
		group,
	} = props;
	const {address} = group.location;

	return (
		<div className="page-group page-contact">
			<section className="group--organization">
				<h2><FormattedMessage {...translations["title.location"]} /></h2>
				<MapColumns
					TitleTag="h3"
					address={group.location.address}
					label={group.location.full_name}
				/>
			</section>
			<section className="group--organization">
				<h2><FormattedMessage {...translations["title.organization"]} /></h2>
				<OrgInformations
					organization={group.organization}
					TitleTag="h3"
				/>
			</section>
		</div>
	);
};

GroupView.propTypes = {
	group: PropTypes.object,
};

GroupView.defaultProps = {
};

GroupView.contextTypes = {
	intl: PropTypes.object,
};

export default GroupView;
