// @ts-ignore
import React, { useEffect, useState } from 'react';
import loadStripe from './loadStripe';
import CreditCardInputs from "../creditCardInputs";
// @ts-ignore
import Loading from 'qidigo-components/loading';

interface StripeCardInputProps {
    unexpectedAddressError: boolean,
    paymentPlatformError: boolean,
    unexpectedInstallmentError: boolean,
    payAction: () => void
    stripeHasLoaded: () => void,
    stripeAlreadyLoaded: boolean,
    hasInstantiatedField: boolean,
    refreshComponent: () => void,
    isRefreshingComponent: boolean,
    amountOfTryOnRefreshing: number,
    disabled: boolean
}

const StripeCardInput = (props: StripeCardInputProps) => {
    let [loaded, setLoaded] = useState(false);
    loaded = props.stripeAlreadyLoaded;

    useEffect(() => {
        if(!props.stripeAlreadyLoaded) {
            loadStripe(() => {
                setLoaded(true);
                props.stripeHasLoaded();
            });
        }
    });

    return loaded ? 
    (
        <CreditCardInputs
            unexpectedAddressError={props.unexpectedAddressError}
            paymentPlatformError={props.paymentPlatformError}
            unexpectedInstallmentError={props.unexpectedInstallmentError}
            payAction={props.payAction}
            hasInstantiatedField={props.hasInstantiatedField && !props.isRefreshingComponent}
            refreshComponent={props.refreshComponent}
            amountOfTryOnRefreshing={props.amountOfTryOnRefreshing}
            disabled={props.disabled}
        />
    ) : <Loading />;
}

export default StripeCardInput;
