import * as React from 'react'
import {useState, useEffect, useMemo} from 'react'
// @ts-ignore
import {connect} from "@app/lib/redux";

export const setGTag = (gTag: string): void => {
    const script = document.createElement('script');
    script.text = `
        window.dataLayer = window.dataLayer || [];  
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${gTag}');
    `

    document.head.appendChild(script)
}

interface IGoogleTagManagerProps {
    organization?: {
        gtm_code?: string | null
    },
    isLogged: boolean
}

const GoogleTagManager: React.FC<IGoogleTagManagerProps> = ({organization, isLogged}) => {
    const [hasTag, setHasTag] = useState<boolean>(false)

    const gtag = useMemo(
        () => organization && organization.gtm_code,
        [organization]
    )

    useEffect(() => {
        if (gtag) {
            setGTag(gtag)
            setHasTag(true)
        }
    }, [gtag, setHasTag])

    return (
        <>
            {hasTag && isLogged &&
                <script
                    async
                    src={`https://www.googletagmanager.com/ns.html?id=${gtag}`}
                />
            }
        </>
    )
}
const withProperties = ({search: {organization: {organization}}}) => ({organization});

export default connect(withProperties)(GoogleTagManager);
