import Button                from "qidigo-components/button.js";
import Form                  from "./form.js";
import Input                 from "./input.js";
import RadioGroup            from "./radio_group.js";
import Select                from "./select.js";
import ImagePicker           from "./imagepicker.js";
import DateInput             from "./dateinput.js";
import Row                   from "./row.js";

export default Form;

//
// Syntactic sugar.
//

// On peut accéder aux contrôles à partir de Form.
Form.Button = Button;
Form.Input = Input;
Form.RadioGroup = RadioGroup;
Form.Select = Select;
Form.ImagePicker = ImagePicker;
Form.DateInput = DateInput;
Form.Row = Row;

// On peut aussi y accéder en exports de Form.
export {
	Button,
	Input,
	RadioGroup,
	Select,
	ImagePicker,
	DateInput,
	Row,
};

