/**
 * Base pour les pages.
 */
import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {loadingDispatchers}  from "@app/signals/loading.js";
import reactTimeout          from "react-timeout";

class Page extends Component {
	// Retire le load en ne bloquant pas react.
	removeLoad() {
		this.props.setTimeout(() => {
			if (this.state && this.state.loadToken) {
				loadingDispatchers.reportFinished(this.state.loadToken);
			}
		}, 10);
	}

	componentWillMount() {
		loadingDispatchers.addLoading(`pageload_${this.props.name}`, (t) => {
			this.setState({loadToken: t});
		});
	}

	componentDidMount() {
		// Chemin normal du load.
		// Retire "l'intention" de load.
		this.removeLoad();
	}

	componentWillUnmount() {
		// Si on ne le fait pas au unmount, il est possible que le component
		// n'ait jamais reçu `componentDidMount`.
		this.removeLoad();
	}

	render() {
		const {className, name} = this.props;
		const classNames = [className, "page", `page-${name}`];

		return (
			<section className={classNames.join(" ")}>
				{this.props.children}
			</section>
		);
	}
}

Page.propTypes = {
	name:      PropTypes.string,
	className: PropTypes.string,
};

Page.defaultProps = {
	name: "base",
};

export default reactTimeout(Page);
