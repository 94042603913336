import React                 from "react";
import PropTypes             from "prop-types";
import {
    defineMessages,
    FormattedMessage,
} from "react-intl";
import reduce                from "lodash/reduce";

import Loading               from "qidigo-components/loading.js";
import OffersFragment        from "@app/views/shared/offers";
import {groupRestrictions}   from "qidigo-data/restrictions";

const translations = defineMessages({
    "title.inscriptions": {id: "qidigo.group.title.inscriptions", defaultMessage: "Inscriptions"},
    "title.pass": {id: "qidigo.group.title.pass", defaultMessage: "Séances à la carte"},

    "adding": {id: "qidigo.group.offers.adding", defaultMessage: "Ajout en cours..."},
    "loading": {id: "qidigo.group.offers.loading", defaultMessage: "Recalcul en cours..."},

    "group.full": {id: "qidigo.group.offers.full", defaultMessage: "Ce groupe est complet. Merci!"},
    "group.no_offers": {id: "qidigo.group.offers.no_offers", defaultMessage: "Il n'y a pas d'offres pour ce groupe..."},
});

// Pour métaprogrammer les titres des sections.
const titles = {
    SESSION: translations["title.inscriptions"],
    PASS: translations["title.pass"],
};

// Pour ordonner les sections.
const order = ["SESSION"];

/**
 * Affichage de la page "offres"
 */
const GroupOffers = (props, context) => {
    const {
        group,
        offers,
        familyMembers,
        newStatus,
        onAddToCart,
        loading,
        adding,
        organization,
    } = props;

    // Pas encore reçu les offres.
    if (!offers) {
        return (
            <div className="page-group page-offers has-no-offers">
                <section className={`group--offers`}>
                    <h2><FormattedMessage {...titles["SESSION"]} /></h2>
                    <Loading />
                </section>
            </div>
        );
    }

    const offersDict = reduce(offers, (obj, v) => {
        const type = v["plan"]["type_plan"];
        if (!obj[type]) { obj[type] = []; }
        obj[type].push(v);

        return obj;
    }, {});

    // On n'a pas (de collections) d'offre?
    if (Object.values(offersDict).length === 0) {
        let msg = <FormattedMessage {...translations["group.no_offers"]} />;
        if (group["nbr_places_remaining"] && group["nbr_places_remaining"] <= 0) {
            msg = <FormattedMessage {...translations["group.full"]} />;
        }

        return (
            <div className="page-group page-offers has-no-offers">
                <section className={`group--offers`}>
                    <h2><FormattedMessage {...titles["SESSION"]} /></h2>
                    <div className="box-message">
                        {msg}
                    </div>
                </section>
            </div>
        );
    }

    const loading_message = adding ? "adding" : "loading"

    return (
        <div className="page-group page-offers">
            {
                order.map((type) => {
                    const offers = offersDict[type];


                    if (offers && offers.length > 0) {
                        return (
                            <section className={`group--offers ${adding ? "is-adding" : ""}`} key={type}>
                                <h2><FormattedMessage {...titles[type]} /></h2>
                                {
                                    (adding || loading) &&
                                        <Loading
                                            className="style-overlay"
                                            message={<FormattedMessage {...translations[loading_message]} />}
                                        />
                                }
                                <OffersFragment
                                    familyMembers={familyMembers}
                                    newStatus={newStatus}
                                    offers={offers}
                                    groupRestrictions={groupRestrictions(group)}
                                    onAddToCart={onAddToCart}
                                    disabled={adding || loading}
                                    organization={organization}
                                />
                            </section>
                        );
                    }

                    return null;
                })
            }
        </div>
    );
};

GroupOffers.propTypes = {
    group: PropTypes.object,
    familyMembers: PropTypes.object,
    newStatus: PropTypes.object,
    offers: OffersFragment.propTypes.offers,
    onAddToCart: PropTypes.func.isRequired,
    adding: PropTypes.bool,
    loading: PropTypes.bool,
};

GroupOffers.defaultProps = {
};

GroupOffers.contextTypes = {
    intl: PropTypes.object,
};

export default GroupOffers;
