import React, {Component}    from "react";
import {connect}             from "@app/lib/redux";
import PropTypes             from "prop-types";
import {navigate}            from "qidigo-router";
import QidigoSessionStore    from "qidigo-sessionstore";
import {path}                from "@app/lib/routes";
import {
	fetchOrganization,
	invalidateOrganization,
}   from "@app/store/actions/search/organization";

import Loading               from "qidigo-components/loading";

/**
 * Store pour les infos demandées par le dernier organisme visité.
 */
const regStore = new QidigoSessionStore("organizationRegistrationPreferences");

/**
 * Store pour tracker le dernier organisme visité.
 */
const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");

// FIX ME Replace me with array
const settlementPath = '/create-a-settlement';
const showInvoicePath = '/show'
const checkoutPath = '/pending-order/checkout';
const formsPath = '/pending-order/forms';
const formPath = '/pending-order/form/';
const dashboardPath = '/dashboard';
const messagesPath = '/messages';

/**
 * Contrôleur *wrapper* d'organisme.
 *
 * Ce contrôleur est présent au-dessus de toute la hiérarchie de pages
 * appartenant à l'organisme. Cette omniprésence permet de donner l'organisme
 * tel que chargé aux sous-pages.
 *
 * @extends {Component}
 */
class OrganizationController extends Component {
	constructor() {
		this.state = {
			share: false,
		};
	}

	/**
	 * Charge le data pour le contrôleur.
	 * (API publique)
	 */
	static load(dispatch, params) {
		return Promise.all([
			dispatch(fetchOrganization(params.orgID)),
		]);
	}

	/**
	 * Proxy pour `static load`.
	 */
	load() {
		const {dispatch, params} = this.props;
		OrganizationController.load(dispatch, params);
	}

	componentDidMount() {
		this.load();
		this.mounted = true;
	}

	componentWillUnmount() {
		const {dispatch} = this.props;
		dispatch(invalidateOrganization());
		this.mounted = false;
	}

	componentDidUpdate(prevProps) {
		const {organization} = this.props;
		if (organization) {
			const preferences = regStore.get() || {};
			regStore.set(Object.assign({}, preferences, organization["registration_settings"]));
			lastOrgStore.set(Object.assign({}, {
				name: organization["name"],
				id: organization["id"],
				slug: organization["slug"],
				uuid: organization["future_id"],
				gtm_code: organization["gtm_code"]
			}));
		}
		// Mise-à-jour sur update des props.
		// C'est possible si on navigue d'un organisme à l'autre.
		if (prevProps.params.orgID !== this.props.params.orgID) {
			this.load();
		}
	}

	handleNewMessage() {
		const {organization} = this.props;

		// TODO : Gérer au niveau du data layer les messages et organismes et passer un organisme directement comme recipient.
		const recipients_list = [];
		recipients_list.push({
			id: organization["id"],
			name: organization["name"],
			image: (organization["logo"] || {})["path"],
			type: "Organization",
		});

		return navigate(path("messages.new"), {state: {recipients_list}});
	}

	handleShare() {
		this.setState({share: !this.state.share});
	}

	render() {
		const {
			route,  //eslint-disable-line
			params, //eslint-disable-line
			children,
			organization,
			...props
		} = this.props;

		if (!organization) { return <Loading />; }

        if (organization.custom_marketplace_url
            && !(window.location.pathname.includes(checkoutPath)
				|| window.location.pathname.includes(settlementPath)
				|| window.location.pathname.includes(showInvoicePath)
				|| window.location.pathname.includes(formsPath)
				|| window.location.pathname.includes(formPath)
				|| window.location.pathname.includes(dashboardPath)
				|| window.location.pathname.includes(messagesPath)
				)
		) {
             window.location.href = organization.custom_marketplace_url; 
        }

		const childProps = Object.assign(
			{
				organization: organization,
				onNewMessage: (...e) => this.handleNewMessage(...e),
				onShare: (...e) => this.handleShare(...e),
				onSubscription: (...e) => this.handleSubscription(...e),
				showShare: this.state.share,
				subscribed: this.state.subscribed,
			}, props
		);

		return (
			<>
				{ children && React.cloneElement(children, childProps) }
			</>
		);
	}
}

OrganizationController.propTypes = {
	dispatch: PropTypes.func,
	route: PropTypes.object,
	params: PropTypes.shape({
		orgID: PropTypes.string,
	}).isRequired,
	organization: PropTypes.object, // FIXME : shape
};

OrganizationController.contextTypes = {
	loggedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

// Connecte avec le store redux.
const withProperties = ({search: {organization: {organization, fetching}}}) => ({organization, fetching});
export default connect(withProperties)(OrganizationController);
