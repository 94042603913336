import {Component} from "react";
import PropTypes from "prop-types";
import assert from "@app/lib/assert";

/**
 * Shim that acts like cloudflare's react-gateway using react 16.
 *
 * The way it works internally is an implementation detail.
 *
 * The gateways provided on the context can either be:
 *
 *   * A DOM node, into which react will `createPortal`.
 *   * A function, which will be used by the system to *save and show* the data.
 *
 */
class Gateway extends Component {
	constructor(props, context) {
		super(props, context);
		this.registry = context.qGatewayRegistry;
	}

	isPortal() {
		const into = assert(this.props.into, "<Gateway> needs prop `into`.");

		return !!this.registry.getPortals()[into];
	}

	componentWillMount() {
		this.id = this.registry.register(this.props.into, this.props.children);
		this.renderIntoGatewayNode(this.props);
	}

	componentWillReceiveProps(props) {
		this.registry.clearChild(this.props.into, this.id);
		this.renderIntoGatewayNode(props);
	}

	componentWillUnmount() {
		this.registry.unregister(this.props.into, this.id);
	}

	renderIntoGatewayNode(props) {
		this.registry.addChild(this.props.into, this.id, props.children);
	}

	render() {
		if (this.isPortal()) {
			const {children, into} = this.props;

			return this.registry.renderInto(into, children);
		}

		// Assumes use of a gateway dest if here.
		return null;
	}
}

Gateway.contextTypes = {
	qGatewayRegistry: PropTypes.object.isRequired,
};

Gateway.propTypes = {
	into: PropTypes.string.isRequired,
};

export default Gateway;
