import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage
} from "react-intl";


const translations = defineMessages({
	"share.with":     {id: "qidigo.share_menu.share.with", defaultMessage: "Partager via {service}"},
});
const serviceNames = defineMessages({
	facebook:     {id: "qidigo.share.service.facebook", defaultMessage: "Facebook"},
	x:      {id: "qidigo.share.service.x", defaultMessage: "X"},
	googleplus:      {id: "qidigo.share.service.googleplus", defaultMessage: "Google +"},
});
const serviceMethods = {
	facebook: "https://www.facebook.com/sharer/sharer.php?u={url}",
	x: "https://x.com/home?status={url}",
	googleplus: "https://plus.google.com/share?url={url}"
};

const services = [
	"facebook",
	"x",
	"googleplus",
];

const ShareMenu = ({url}) => {
	url = encodeURIComponent(url);

	return (
		<div className="share-menu">
			<ul>
				{services.map((service, key) => {
					const method = serviceMethods[service];
					const linkProps = {};
					if (typeof method === "string") {
						linkProps["href"] = method.replace("{url}", url);
						linkProps["target"] = "_blank";
					}

					return (
						<li key={key}>
							<a {...linkProps} className={`icon provider-${service}`}>
								<FormattedMessage
									{...translations["share.with"]}
									values={{
										service: <FormattedMessage {...serviceNames[service]} />
									}}
								/>
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

ShareMenu.propTypes = {
	url: PropTypes.string.isRequired,
};

export default ShareMenu;
