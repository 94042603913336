import React, {Component}    from "react";
import PropTypes             from "prop-types";
import "./row.less";

/**
 * Used to group form elements on a row. Those elements will
 * responsively react.
 *
 * @extends {Component}
 */
class FormRow extends Component {
	render() {
		const {
			className,
			children,
			...leftoverProps
		} = this.props;
		let classes = ["form--row", className];

		return (
			<div
				className={classes.join(" ")}
				type="select"
				{...leftoverProps}
			>
				{children}
			</div>
		);
	}
}

FormRow.propTypes = {
	className:          PropTypes.string,
};

export default FormRow;


