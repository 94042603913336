import PropTypes from "prop-types";

/**
 * PropType pour les erreurs.
 */
const errorMessagesType =
	PropTypes.oneOfType([
		PropTypes.shape({
			// Messages d'erreur formatté.
			// Sera affiché tel quel, les retours de lignes fonctionnels.
			message: PropTypes.oneOfType([
				PropTypes.node,
				PropTypes.string,
				PropTypes.bool,
			]),
			// Chaque entrée est au nom d'un champ...
			fields: PropTypes.objectOf(
				// ... et contient un array de messages.
				PropTypes.arrayOf(
					PropTypes.string.isRequired
				).isRequired
			),

		}),
		PropTypes.bool,
	])
;

export {
	errorMessagesType,
};
