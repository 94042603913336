import React, {Component} from "react";
import PropTypes from "prop-types";
import Radio from "qidigo-form/radio";
import Input from "qidigo-form/input";
import {defineMessages} from "react-intl";

/**
 * Template for a question of type yes no justify.
 */

const translations = defineMessages({
	yes: {id: "qidigo.dashboard.form.question.yes", defaultMessage: "Oui"},
	no: {id: "qidigo.dashboard.form.question.no", defaultMessage: "Non"},
	additional_informations: {id: "qidigo.dashboard.form.question.explanation", defaultMessage: "Informations supplémentaires"},
});

class YesNoJustifyTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;
		const {formatMessage} = this.context.intl;
		const {bool="", explanation=""} = answer || {};

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Radio
						label={formatMessage(translations["yes"])}
						name="bool"
						value="yes"
						checked={bool === "yes"}
						onChange={(...e)=>this.handleChange("bool", ...e)}
					/>
					<Radio
						label={formatMessage(translations["no"])}
						name="bool"
						value="no"
						checked={bool === "no"}
						onChange={(...e)=>this.handleChange("bool", ...e)}
					/>
					<Input
						error={errors["explanation"]}
						value={explanation}
						onChange={(...e)=>this.handleChange("explanation", ...e)}
						label={formatMessage(translations["additional_informations"])}
					/>
				</div>
			</div>
		);
	}
}

YesNoJustifyTypeForm.defaultProps = {
	className: "",
	errorMessage: "",
};

YesNoJustifyTypeForm.contextTypes = {
	intl: PropTypes.object,
};

YesNoJustifyTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	errors: PropTypes.object,
};

export default YesNoJustifyTypeForm;

