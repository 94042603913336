import React                 from "react";
import queryString           from "query-string";
import useScroll             from "react-router-scroll/lib/useScroll";
import {
	Router,
	browserHistory,
	applyRouterMiddleware
} from "react-router/es";
import {loadingDispatchers}  from "@app/signals/loading.js";
import {syncHistoryWithStore} from "react-router-redux";

import QidigoSessionStore    from "qidigo-sessionstore";
import Logger                from "qidigo-logger";

// Stockage pour permettre à des valeurs de survivre entre les reloads.
const store = new QidigoSessionStore("routerStore");

function hashLinkScroll() {
	const {hash} = window.location;
	if (hash !== '') {
		// Push onto callback queue so it runs after the DOM is updated,
		// this is required when navigating from a different page so that
		// the element is rendered on the page before trying to getElementById.
		setTimeout(() => {
			const id = hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) element.scrollIntoView();
		}, 0);
	}
}

//
// Les routes de l'application Qidigo sont toutes définies à partir du fichier
// main.js. Ce "routeur" est l'instance utilisée par l'application.
//
// Ce module définit aussi des helpers utiles.
//
export default function router(routes, store) {
	let history = browserHistory;
	if (store) {
		history = syncHistoryWithStore(history, store)
	}
	return <Router
		history={history}
		render={applyRouterMiddleware(useScroll())}
		onUpdate={hashLinkScroll}
	>
		{routes}
	</Router>
	;
}


//
// Envoie les page views à Google Analytics.
// (N'envoie pas au chargement, mais le snippet dans index.ejs s'en occupe)
//
if (browserHistory && browserHistory.listen) {
	browserHistory.listen((location) => {
		// En développement, on track les mouvements dans l'history.
		if (process.env.NODE_ENV === "development") {
			if (location.action !== "REPLACE") {
				Logger.debugGroup(`🔗 ${location.pathname}`, location);
			}
		}
		if (location.action !== "REPLACE") {
			if (window.ga) {
				window.ga("send", "pageview", location.pathname);
			}
		}
	});
}

//
// Fonction utilitaire pour implémenter la navigation.
//
// La dichotomie "replace/push" est remplacée par un paramètre
// dans la liste d'options.
//
export function navigate(pathname, {replace, ...other} = {}) {
	// SSR bypass
	if (!browserHistory) { return ;}

	if (replace) {
		return browserHistory.replace({pathname, ...other});
	}
	else {
		return browserHistory.push({pathname, ...other});
	}
}

// When used on the browser
if (typeof window !== "undefined") {
	// Hooks `navigate` globally.
	if (!window.qidigo) { window.qidigo = {}; }
	window.qidigo.navigate = navigate;
}

//
// Récupère les valeurs dans la Query String (`?k=v`)
//
export function getQueryStringValues() {
	return queryString.parse(window.location.search);
}

/**
 * Builds a className using the current *route*.
 * This includes the placeholder variables.
 * Replaces all chains of non-alphas with one underscore.
 * This acts as a "collection of routes, separated with dashes".
 *
 *   * ["/", "u/:orgID"] becomes route-_-u_orgID
 *
 * @param {array} routes Routes array from react-router.
 */
export function routeToClassName(routes) {
	const route = routes.map((curr) => {
		let {path = ""} = curr;
		if (path === "") { return null; }

		return `-${path.replace(/[^a-zA-Z]+/g, "_")}`;
	}).join("");

	return `route${route}`;
}

/**
 * Builds a className using the current *path*.
 * Replaces slashes with dashes.
 * Replaces all chains of non-alphas with one underscore.
 *
 *   * /u/swift-group-group becomes page-u-swift_group_group
 *
 * @param {string} pathname Path name part of the URL.
 */
export function pathToClassName(pathname) {
	const page = pathname.replace(/[^a-zA-Z/]+/g, "_").replace(/\//g, "-");

	return `page${page}`;
}

const errorLoading = (err) => {
	// Assume que c'est parce qu'on a pushé une nouvelle version
	// et que le module n'est plus disponible.
	// En refreshant complètement, ça devrait fonctionner.
	console.error("Dynamic routes loading failed", err); // eslint-disable-line

	// Section qui sera retirée en production (via tree-shaking).
	if (process.env.NODE_ENV === "development") {
		return;
	}

	// Vérifie si on a reloadé "récemment"
	const data = store.get() || {};
	const {lastDataReload} = data;
	const now = (new Date()).getTime();

	if (now - lastDataReload < 10000) {
		console.log("--------------------------------------------------------------------------------");
		console.info("Reloaded in the last 10s... will not reload.");
		console.info("There might have been a real issue.");
	}
	else {
		// Sauvegarde uniquement quand on recharge.
		store.set(Object.assign({}, data, {lastDataReload: now}));
		// En production, on tente de recharger.
		setTimeout(() => {
			window.location.reload(true);
		}, 1000);
	}
};

/**
 * Lazy loads a file from the routes folder.
 * To be used with plain routes.
 *
 * @param {string} file File to load, do not add the .js suffix.<
 * @param {Object} opts Optional options.
 * @param {string} [opts.path] Path to use. Defaults to value of `file`.
 */
export const lazy = (file, opts = {}) => {
	// NOTE: DO NOT put the full path in a variable.
	// The string expression NEEDS to be here, else
	// webpack will flip out.
	let {path} = opts;
	if (!path) { path = file; }

	return {
		path: path,
		getChildRoutes: (nextState, cb) => {
			const finish = loadingDispatchers.promisedDequeuer();
			import(`@app/routes/lazy/${file}`)
				.then((module) => {
					finish();

					return cb(null, module.default);
				})
				.catch((err) => {
					finish();

					return errorLoading(err);
				})
			;
		},
	};
};

/**
 * Redirects a whole prefix to a new prefix.
 * To be used with plain routes.
 *
 * @param {string} from Source prefix.
 * @param {string} to Destination prefix.
 */
export const redirectPrefix = (from, to) => {
	return {
		path: from + "(/**)",
		onEnter: (ns, replace) => {
			const splat = ns.params.splat ? ns.params.splat : "";
			replace("/" + to + "/" + splat);
		},
	};
};


export {browserHistory};
