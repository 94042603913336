import * as React from "react";
import {Component} from "react";
// @ts-ignore
import BackToLastLocation from "@app/components/back-to-last-location.js";
// @ts-ignore
import QidigoSessionStore from "qidigo-sessionstore";
import {
    IBillingViewProps, IBillingViewState, IInstallment,
    IOrder,
} from "../types";
import Summary from "../summary/summary";
import PaymentErrors from "../payment/paymentErrors";
import FinalizeBox from "../finalizeBox";
import MailingCheckbox from "../payment/mailingCheckbox";
import { defineMessages, FormattedMessage } from "react-intl";


const translations = defineMessages({
    "order_helmet": {
        id: "qidigo.billing.order_helmet",
        defaultMessage: "Commande"
    },
    "order_summary_label": {
        id: "qidigo.billing.order_summary_label",
        defaultMessage: "Sommaire de ma commande"
    },
})

export interface IPendingOrderViewProps  extends IBillingViewProps {
    order: IOrder,
    reload: (couponCode: string|null, showCouponApplication: boolean) => void
    removeLine: (orderLineId: number) => void
    handleItemIsBeingDeleted: (isBeingDeleted: boolean) => void,
    hasCouponErrorOnApplication: boolean,
    hasCouponLimitErrorOnApplication: boolean,
    itemIsBeingDeleted: boolean,
    selectedInstallmentId: string | null
    handleMailingListCheckBox: (isChecked: boolean) => void,
    mailingListCheckBoxValue: boolean,
    contactIsSubscribedToMailingList: boolean,
    installmentList: Array<IInstallment>,
}


class PendingOrderView extends Component<IPendingOrderViewProps, IBillingViewState> {
    constructor(props: IPendingOrderViewProps) {
        super(props);
        this.state = {
            credit: 0
        };
    }

    setCredit = (credit: number) => {
        this.setState({
            credit: credit,
        })
        this.props.applyCredit(credit);
    };

    showPaymentBox = (): boolean => {
        return this.props.order.lines.length > 0 && !this.props.isFree;
    }

    showPaymentErrorBox = (): boolean => {
        return this.props.paymentErrors !== undefined
    }

    handleContinueShoppingClicked = () => {
        const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");
        const lastOrg = lastOrgStore.get();
        window.location.href = `/u/${lastOrg["slug"]}`;
    }

    render() {
        const {
            order,
            contactCredit,
            paymentMethods,
            installmentList,
            addressList,
            handlePayment,
            handleTokenBadRequest
        } = this.props;

        let errorMessages = (
            <PaymentErrors
                paymentErrors={this.props.paymentErrors}
                organizationName={this.props.organizationName}
                redirectionTimer={this.props.redirectionTimer}
            />
        );

        let summaryLabel = <FormattedMessage {...translations["order_summary_label"]}/>;
        let summaryHelmet = {...translations["order_helmet"]};

        return (
            <div id={"billing-page"}>
                <div className={"billing-view--container"}>
                    {
                        !this.props.processingPayment && 
                        <div className={"billing-view--back-to-last-location-container"}>
                            <BackToLastLocation />
                        </div>
                    }
                    {
                        this.showPaymentErrorBox() &&
                            <div className={"billing-view--payment-errors-container"}>
                                {errorMessages}
                            </div>
                    }
                    <div className={"billing-view-summary-container" + (!this.showPaymentBox() ? " empty" : "")}>
                        <Summary
                            summary={order}
                            contact_credit={contactCredit}
                            setCredit={this.setCredit}
                            isFree={this.props.isFree}
                            hasCouponErrorOnApplication={this.props.hasCouponErrorOnApplication}
                            hasCouponLimitErrorOnApplication={this.props.hasCouponLimitErrorOnApplication}
                            handleItemIsBeingDeleted={this.props.handleItemIsBeingDeleted}
                            reload={this.props.reload}
                            removeLine={this.props.removeLine}
                            canRemoveLine={true}
                            isLoading={this.props.processingPayment || this.props.itemIsBeingDeleted}
                            hasPriceTotal={true}
                            hasCouponAdjustment={true}
                            hasPaymentOrCredit={false}
                            helmet={summaryHelmet}
                            label={summaryLabel}
                            invoiceLabel={''}
                            model={'BILLING'}
                        />
                    </div>
                    <FinalizeBox
                        isLoading={this.props.processingPayment || this.props.itemIsBeingDeleted}
                        shouldShowPaymentBox={this.showPaymentBox()}
                        paymentMethods={paymentMethods}
                        addressList={addressList}
                        installmentList={installmentList}
                        showPaymentErrorBox={this.showPaymentErrorBox()}
                        handleTokenBadRequest={handleTokenBadRequest}
                        handlePayment={handlePayment}
                        disabled={this.props.processingPayment || this.props.itemIsBeingDeleted}
                        paymentErrors={errorMessages}
                        processingPayment={this.props.processingPayment}
                        organizationUuid={this.props.organizationUuid}
                        billingInstallments={[]}
                        selectedInstallmentId={this.props.selectedInstallmentId}
                        selectedPaymentMethodId={this.props.selectedPaymentMethodId}
                        version={this.props.order.version}
                        lineCount={this.props.order.lines.length}
                        handleContinueShoppingClicked={this.handleContinueShoppingClicked}
                        appliedCoupon={this.props.order.applied_coupon}
                        credit={this.state.credit}
                    />
                    {
                        !this.props.processingPayment
                        && !this.props.contactIsSubscribedToMailingList
                        && !this.props.itemIsBeingDeleted
                        && <MailingCheckbox
                            isChecked={this.props.mailingListCheckBoxValue}
                            organizationName={this.props.organizationName}
                            label={this.props.order.organization_mailing_list_text}
                            handleMailingListCheckBox={this.props.handleMailingListCheckBox}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default PendingOrderView;
