import * as React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import Checkbox from "qidigo-form/checkbox";
import {Component} from "react";

const translations = defineMessages({
    "direct_mail": {
        id: "qidigo.billing.direct_mail",
        defaultMessage: "Je désire recevoir de l’information sur mes cours et du contenu promotionnel de {organization}"
    },
});

export interface IMailingCheckboxView {
    isChecked: boolean,
    label: string | null,
    organizationName: string,
    handleMailingListCheckBox: (value) => void
}


class MailingCheckbox extends Component<IMailingCheckboxView> {
    constructor(props: IMailingCheckboxView) {
        super(props);
    }

    render() {
        return (
            <div className="div--billing-contact-mailing-list">
                <Checkbox
                    value={this.props.isChecked}
                    id={"billing--contact-mailing-list"}
                    onChange={(event, value) => this.props.handleMailingListCheckBox(value)}
                />
                <label htmlFor={"billing--contact-mailing-list"}>
                    {
                        this.props.label === null ?
                            <FormattedMessage {...translations['direct_mail']}
                                              values={{
                                                  organization: this.props.organizationName,
                                              }}/> :
                            this.props.label
                    }
                </label>
            </div>
        )
    }
}

export default MailingCheckbox;
