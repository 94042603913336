import React                 from "react";
import Helmet                from "react-helmet";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import snakeCase             from "lodash/snakeCase";
import Button                from "qidigo-components/button.js";
import Form                  from "qidigo-form/form";
import ButtonsBar            from "qidigo-form/buttons_bar.js";
import PhoneInput            from "qidigo-form/phoneinput.js";
import LocalModal            from "@app/components/local_modal.js";

const actions = defineMessages({
	"delete": {id: "qidigo.dashboard.phone_numbers.delete", defaultMessage: "Supprimer"},
	"cancel": {id: "qidigo.dashboard.phone_numbers.cancel", defaultMessage: "Annuler"},
});

const translations = defineMessages({
	editPhoneNumberTitle: {id: "qidigo.dashboard.phone_numbers.edit_phone_number_title", defaultMessage: "Modifier un numéro"},
	newPhoneNumberTitle: {id: "qidigo.dashboard.phone_numbers.new_phone_number_title", defaultMessage: "Nouveau numéro"},
	deletePhoneNumberTitle: {id: "qidigo.dashboard.phone_numbers.delete_phone_number_title", defaultMessage: "Supprimer le numéro?"},
	deletePhoneNumberMessage: {id: "qidigo.dashboard.phone_numbers.delete_phone_number_message", defaultMessage: "Une fois supprimé, le numéro de téléphone devra être entré à nouveau pour être utilisé."},
	phoneNumber: {id: "qidigo.dashboard.phone_number", defaultMessage: "Numéro de téléphone"},
});

const UserPhoneNumberForm = (props, context) => {
	const {formatMessage} = context.intl;
	const {
		confirmDeletion,
		action,
		onSubmit,
		onChange,
		onValidationError,
		handleCancelAction,
		handleDeleteAction,
		errors,
		phoneNumber,
		saving,
	} = props;

	return (
		<section className="dashboard-phonebook">
			<h1><FormattedMessage {...translations[`${action}PhoneNumberTitle`]} /></h1>
			<Helmet
				title={formatMessage(translations[`${action}PhoneNumberTitle`])}
			/>
			<div
				className={`phone-card type-${snakeCase(phoneNumber.type_phone_number)} phone_number_id-${phoneNumber.id}`}
			>
				<Form
					className="dashboard-user-phone-number"
					onSubmit={(...e) => onSubmit(...e)}
					onChange={(...e) => onChange(...e)}
					onValidationError={(...e) => onValidationError(...e)}
					errors={errors}
					values={phoneNumber}
					disabled={saving}
					autoFocus={true}
				>
					<PhoneInput name="phone_number"
						required={true}
						label={formatMessage(translations.phoneNumber)}
						value={props.phoneNumber}
					/>
					<ButtonsBar loading={saving}>
						{action === "new" ? null :
							<Button onClick={(e) => handleDeleteAction(e)}><FormattedMessage {...actions["delete"]} /></Button>
						}
						<Button onClick={(e) => handleCancelAction(e)}><FormattedMessage {...actions["cancel"]} /></Button>
					</ButtonsBar>
				</Form>

				{confirmDeletion ?
					<LocalModal>
						<h2><FormattedMessage {...translations.deletePhoneNumberTitle} /></h2>
						<p>
							<FormattedMessage {...translations.deletePhoneNumberMessage} />
						</p>
						<ButtonsBar
							onSubmit={(e) => handleDeleteAction(e, "continue")}
							submitLabel={<FormattedMessage {...actions["delete"]} />}
						>
							<Button onClick={(e) => handleDeleteAction(e, "cancel")}><FormattedMessage {...actions["cancel"]} /></Button>
						</ButtonsBar>
					</LocalModal> :
					null
				}
			</div>
		</section>
	);
};

UserPhoneNumberForm.propTypes = {
	// FIXME : Shape it
	phoneNumber: PropTypes.object,

	action: PropTypes.string.isRequired,
	saving: PropTypes.bool,
	confirmDeletion: PropTypes.bool,

	errors: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),

	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	onValidationError: PropTypes.func,
	handleCancelAction: PropTypes.func,
	handleDeleteAction: PropTypes.func,
};

UserPhoneNumberForm.contextTypes = {
	intl: PropTypes.object,
};

export default UserPhoneNumberForm;
