import * as React from "react";
import {Component} from "react";
// @ts-ignore
import infoIcon from '../../../../../design/assets/help.svg';
// @ts-ignore
import tangerineInfoFr from '../../../../../design/assets/direct-debit/tangerine_account_statement_infomation_fr.svg';
// @ts-ignore
import tangerineInfoEn from '../../../../../design/assets/direct-debit/tangerine_account_statement_infomation_en.svg';
// @ts-ignore
import directDebitInfoEn from '../../../../../design/assets/direct-debit/direct_debit_information_en.svg';
// @ts-ignore
import directDebitInfoFr from '../../../../../design/assets/direct-debit/direct_debit_information_fr.svg';

import {IBillingAddress, IBillingInstallment, IPaymentMethod, IBank} from "../../types";
import PaySafeDirectDebitForm from "./PaySafe/paySafeDirectDebitForm";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";
import {lang} from "moment";
// @ts-ignore
import Loading from 'qidigo-components/loading';
// @ts-ignore
import Date    from "qidigo-i18n/date";
// @ts-ignore
import Money   from "@app/components/money";
import InstallmentWarning from "../../installmentWarning";

const translations = defineMessages({
    "direct_debit": {
        id: "qidigo.billing.direct_debit_form.direct_debit",
        defaultMessage: "Débit préautorisé"
    },
    "find_my_information": {
        id: "qidigo.billing.direct_debit_form.find_my_information",
        defaultMessage: "Où trouver mes informations?"
    },
});

interface IDirectDebitFormProps {
    paymentMethods: IPaymentMethod,
    user_name: string,
    billing_address: IBillingAddress|null,
    isInstallmentMissing: boolean,
    handleTokenBadRequest: (code: string) => void,
    handleOnlinePayment: (token: string) => void,
    hasAddressError: boolean,
    paymentErrors: object,
    showPaymentErrorBox: boolean,
    processingPayment: boolean,
    organizationUuid: string,
    disabled: boolean,
    billingInstallments: Array<IBillingInstallment>,
    banks: null|IBank[]
    defaultBank: null|IBank
}

class DirectDebitForm extends Component<IDirectDebitFormProps> {
    state: {
        isDirectDebitInformationOpen: boolean,
        isTangerineBank: boolean,
    }

    constructor(props) {
        super(props);
        this.state = {
            isDirectDebitInformationOpen: false,
            isTangerineBank: false,
        }
    }

    directDebitFormForProvider() {
        switch (this.props.paymentMethods.type) {
            case "PAYSAFE_CAD_DD":
                return !this.props.processingPayment ? (
                    <PaySafeDirectDebitForm
                        userName={this.props.user_name}
                        billing_address={this.props.billing_address}
                        isInstallmentMissing={this.props.isInstallmentMissing}
                        handleTokenBadRequest={this.props.handleTokenBadRequest}
                        handlePayment={this.props.handleOnlinePayment}
                        showTangerineBankInfo={this.showTangerineBankInfo}
                        organizationUuid={this.props.organizationUuid}
                        disabled={
                            this.props.disabled
                            || this.props.hasAddressError
                            || this.props.processingPayment
                        }
                        banks={this.props.banks}
                        defaultBank={this.props.defaultBank}
                    />
                ) : <Loading/>;
        }
    }

    toggleCreditCardInformation() {
        this.setState({
            isDirectDebitInformationOpen: !this.state.isDirectDebitInformationOpen
        })
    }

    showTangerineBankInfo = (isTangerineBank: boolean) => {
        this.setState({
            isTangerineBank: isTangerineBank,
        })
    }

    render() {
        const bankAccountInfoIcon = this.state.isTangerineBank
            ? (lang() === 'fr' ? tangerineInfoFr : tangerineInfoEn)
            : (lang() === 'fr' ? directDebitInfoFr : directDebitInfoEn)
        ;

        return (
            <div className={"direct-debit-form"}>
                <div className="direct-debit-form--title">
                    <FormattedMessage {...translations["direct_debit"]} />
                </div>
                {
                    this.props.showPaymentErrorBox &&
                    <div className={"billing-view--payment-errors-container"}>
                        {this.props.paymentErrors}
                    </div>
                }
                {
                    this.props.billingInstallments.length > 0 &&
                    <InstallmentWarning installments={this.props.billingInstallments}/>
                }
                <div className={"direct-debit-form--info-button-container"}>
                    <img
                        className={"direct-debit-form--info-button-image"}
                        src={infoIcon}
                        alt={"info-icon"}
                    />
                    <button
                        className={"direct-debit-form--info-button button-link"}
                        onClick={() => this.toggleCreditCardInformation()}
                    >
                        <FormattedMessage {...translations["find_my_information"]} />
                    </button>
                </div>
                {
                   this.state.isDirectDebitInformationOpen &&
                    <div className={"direct-debit-form--info"}>
                        <img
                            src={bankAccountInfoIcon}
                            alt={"direct-debit-info-icon"}
                        />
                    </div>
                }
                {
                    this.directDebitFormForProvider()
                }
           </div>
        );
    }
}

export default DirectDebitForm;
