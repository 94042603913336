import React                 from "react";
import PropTypes             from "prop-types";
import {path}                from "@app/lib/routes";
import {Link}                from "react-router/es";
import moment                from "moment";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import Date from "../../../../modules/qidigo-i18n/date";


const groupRestrictions = (group) => {
	const yearOrMonth = group.restriction_age_max % 12 > 0 || group.restriction_age_min % 12 > 0;
	const restriction_age_max = yearOrMonth ? group.restriction_age_max : group.restriction_age_max/12;
	const restriction_age_min = yearOrMonth ? group.restriction_age_min : group.restriction_age_min/12;

	return {
		restriction_age_min : restriction_age_min,
		restriction_age_max : restriction_age_max,
		validate_age_on : group.validate_age_on,
		yearOrMonth : yearOrMonth,
	};
};

export {groupRestrictions};

// Messages des restrictions.
const translations = defineMessages({
	"explanation": {
		id: "qidigo.group.offerform.explanation.restriction",
		defaultMessage: "Cette personne ne peut pas se procurer cette offre.",
	},

    "explanation_cant_book": {
		id: "qidigo.group.offerform.explanation_cant_book.restriction",
		defaultMessage: "Cette personne ne peut pas faire de réservation pour cette séance",
	},

	"restriction.DEFAULT": {
		id: "qidigo.group.offerform.restriction.default",
		defaultMessage: "Inadmissible pour le tarif (code: {restriction})",
		description: "Si un code est introuvable."
	},
	"restriction.GROUP_IS_FULL": {
		id: "qidigo.group.offerform.restriction.GROUP_IS_FULL",
		defaultMessage: "{groupsCount, plural, one {Le groupe est complet} other {Un des groupes du tarif est complet}}",
	},
	"restriction.NO_SESSION_IS_AVAILABLE": {
		id: "qidigo.group.offerform.restriction.NO_SESSION_IS_AVAILABLE",
		defaultMessage: "{groupsCount, plural, one {Aucune classe n'est disponible} other {Aucune classe n'est disponible}}",
	},
	"restriction.SUBSCRIBER_GENDER_NEEDS_FEMALE": {
		id: "qidigo.group.offerform.restriction.SUBSCRIBER_GENDER_NEEDS_FEMALE",
		defaultMessage: "{groupsCount, plural, one {Le participant doit être une femme} other {Un des groupes nécessite que le participant soit une femme}}",
	},
	"restriction.SUBSCRIBER_DOES_NOT_HAVE_RIGHT_GENDER": {
		id: "qidigo.group.offerform.restriction.SUBSCRIBER_DOES_NOT_HAVE_RIGHT_GENDER",
		defaultMessage: "{groupsCount, plural, one {Le participant ne respecte pas les restrictions de genre du groupe.} other {Le participant ne respecte pas les restrictions de genre de l'un des groupes.}}",
	},
	"restriction.SUBSCRIBER_GENDER_NEEDS_MALE": {
		id: "qidigo.group.offerform.restriction.SUBSCRIBER_GENDER_NEEDS_MALE",
		defaultMessage: "{groupsCount, plural, one {Le participant doit être un homme} other {Un des groupes nécessite que le participant soit un homme}}",
	},
	"restriction.SUBSCRIBER_AGE_TOO_YOUNG": {
		id: "qidigo.group.offerform.restriction.SUBSCRIBER_AGE_TOO_YOUNG",
		defaultMessage: "{groupsCount, plural, one {Le participant doit avoir au moins {restriction_age} {yearOrMonth} en date du {validate_age_on}} other {Un des groupes limite l'âge et le participant est trop jeune}}",
	},
	"restriction.SUBSCRIBER_AGE_TOO_OLD": {
		id: "qidigo.group.offerform.restriction.SUBSCRIBER_AGE_TOO_OLD",
		defaultMessage: "{groupsCount, plural, one {Le participant doit avoir au maximum {restriction_age} {yearOrMonth} en date du {validate_age_on}} other {Un des groupes limite l'âge et le participant est trop âgé}}",
	},
	"restriction.PERIOD_RESTRICTS_CITY": {
		id: "qidigo.group.offerform.restriction.PERIOD_RESTRICTS_CITY",
		defaultMessage: "Le participant n'est éligible à aucune période d'inscription",
	},
	"restriction.PERIOD_RESTRICTED_BY_GROUP": {
		id: "qidigo.group.offerform.restriction.PERIOD_RESTRICTED_BY_GROUP",
		defaultMessage: "Le participant n'est éligible à aucune période d'inscription",
	},
	"restriction.PLAN_NOT_YET_ACTIVE": {
		id: "qidigo.group.offerform.restriction.PLAN_NOT_YET_ACTIVE",
		defaultMessage: "La période d'inscription pour cette offre débutera le {start_date}",
	},
    "restriction.PLAN_NOT_YET_ACTIVE_NO_DATE": {
        id: "qidigo.group.offerform.restriction.PLAN_NOT_YET_ACTIVE_NO_DATE",
        defaultMessage: "La période d'inscription pour cette offre n'a pas encore débuté",
    },
	"restriction.PLAN_NOT_ACTIVE_ANYMORE": {
		id: "qidigo.group.offerform.restriction.PLAN_NOT_ACTIVE_ANYMORE",
		defaultMessage: "L'offre n'est plus disponible",
	},
	"restriction.ORGANIZATION_NEEDS_PHONE": {
		id: "qidigo.group.offerform.restriction.ORGANIZATION_NEEDS_PHONE",
		defaultMessage: "L'organisme demande au client d'avoir un numéro de téléphone dans son profil.",
	},
	"restriction.ORGANIZATION_NEEDS_ADDRESS": {
		id: "qidigo.group.offerform.restriction.ORGANIZATION_NEEDS_ADDRESS",
		defaultMessage: "L'organisme demande au client d'avoir une adresse dans son profil.",
	},

	// Avec des messages complexes:
	"restriction.PERIOD_RESTRICTED_BY_MEMBERSHIP": {
		id: "qidigo.group.offerform.restriction.PERIOD_RESTRICTED_BY_MEMBERSHIP",
		defaultMessage: "La période d'inscription en cours nécessite un des abonnements suivants: {membershipsList}",
		description: "Doit finir avec la ponctuation indiquant une liste à la suite. La liste comporte le nom des abonnements en liens.",
	},
	"restriction.PLAN_RESTRICTED_BY_MEMBERSHIP": {
		id: "qidigo.group.offerform.restriction.PLAN_RESTRICTED_BY_MEMBERSHIP",
		defaultMessage: "L'offre nécessite l'un des abonnements suivants : {membershipsList}",
		description: "Doit finir avec la ponctuation indiquant une liste à la suite. La liste comporte le nom des abonnements en liens.",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_NOT_SET": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_NOT_SET",
		defaultMessage: "Le titulaire du compte doit avoir {age_text} le {validate_age_on}",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_TOO_YOUNG": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_TOO_YOUNG",
		defaultMessage: "Le titulaire du compte doit avoir {age_text} le {validate_age_on}",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_TOO_OLD": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_TOO_OLD",
		defaultMessage: "Le titulaire du compte doit avoir {age_text} le {validate_age_on}",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NEEDS_FEMALE": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NEEDS_FEMALE",
		defaultMessage: "Le titulaire du compte doit être une femme",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NEEDS_MALE": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NEEDS_MALE",
		defaultMessage: "Le titulaire du compte doit être un homme",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_NOT_RIGHT_GENDER_CODE": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_NOT_RIGHT_GENDER_CODE",
		defaultMessage: "Le titulaire du compte ne respecte pas les restrictions de genre de l'abonnement.",
	},
	"restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NOT_SET": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NOT_SET",
		defaultMessage: "Le genre du titulaire du compte doit être défini",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_NOT_SET": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_NOT_SET",
		defaultMessage: "L'abonné doit avoir {age_text} le {validate_age_on}",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_TOO_YOUNG": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_TOO_YOUNG",
		defaultMessage: "L'abonné doit avoir {age_text} le {validate_age_on}",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_TOO_OLD": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_TOO_OLD",
		defaultMessage: "L'abonné doit avoir {age_text} le {validate_age_on}",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NEEDS_FEMALE": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NEEDS_FEMALE",
		defaultMessage: "L'abonné doit être une femme",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NEEDS_MALE": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NEEDS_MALE",
		defaultMessage: "L'abonné doit être un homme",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_NOT_RIGHT_GENDER_CODE": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_NOT_RIGHT_GENDER_CODE",
		defaultMessage: "L'abonné ne respecte pas les restrictions de genre de l'abonnement.",
	},
	"restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NOT_SET": {
		id: "qidigo.group.offerform.restriction.MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NOT_SET",
		defaultMessage: "Le genre de l'abonné doit être défini",
	},
	"restriction.SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS": {
		id: "qidigo.group.offerform.restriction.SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS",
		defaultMessage: "Le participant a atteint la limite d'inscription pour cette offre.",
	},
	"age.month": {
		id: "qidigo.group.offerform.date.month",
		defaultMessage: "{restriction_age, plural, one {mois} other {mois}}",
	},
	"age.year": {
		id: "qidigo.group.offerform.date.year",
		defaultMessage: "{restriction_age, plural, one {an} other {ans}}",
	},
	"age.range": {
		id: "qidigo.group.offerform.date.range",
		defaultMessage: "entre {min_age} {min_age_unit} et {max_age} {max_age_unit}",
	},
	"age.min_only": {
		id: "qidigo.group.offerform.date.min_only",
		defaultMessage: "au moins {min_age} {min_age_unit}",
	},
	"age.max_only": {
		id: "qidigo.group.offerform.date.max_only",
		defaultMessage: "au plus {max_age} {max_age_unit}",
	},
});

const LINK = {
	ORGANIZATION_NEEDS_ADDRESS: "/dashboard/addresses",
	ORGANIZATION_NEEDS_PHONE: "/dashboard/phone-numbers",
};

const MEMBERSHIP_AGE_RESTRICTIONS = [
    "MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_NOT_SET",
    "MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_TOO_YOUNG",
    "MEMBERSHIP_TEMPLATE_FAMILY_OWNER_AGE_TOO_OLD",
    "MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_NOT_SET",
    "MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_TOO_YOUNG",
    "MEMBERSHIP_TEMPLATE_SUBSCRIBER_AGE_TOO_OLD",
];

const MEMBERSHIP_GENDER_RESTRICTIONS = [
    "MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NEEDS_FEMALE",
    "MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NEEDS_MALE",
    "MEMBERSHIP_TEMPLATE_FAMILY_OWNER_GENDER_NOT_SET",
    "MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NEEDS_FEMALE",
    "MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NEEDS_MALE",
    "MEMBERSHIP_TEMPLATE_SUBSCRIBER_GENDER_NOT_SET",
];

const SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS = 'SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS';
const PLAN_NOT_YET_ACTIVE = 'PLAN_NOT_YET_ACTIVE';

const RestrictionMessage = ({restriction, values, groupRestrictions, onEditorOpen}, {showAddressEditor, showPhoneNumberEditor}) => {
    if (restriction === "ORGANIZATION_NEEDS_ADDRESS" || restriction === "ORGANIZATION_NEEDS_PHONE") {
		// This always links to the full dashboard editor to allow middle clicking and such things to work.
		let onClick = () => {};
		if (restriction === "ORGANIZATION_NEEDS_ADDRESS") {
			onClick = (e) => {
				e.preventDefault();
				showAddressEditor();
				if (onEditorOpen) {
					onEditorOpen()
				}
			};
		}
		if (restriction === "ORGANIZATION_NEEDS_PHONE") {
			onClick = (e) => {
				e.preventDefault();
				showPhoneNumberEditor();
				if (onEditorOpen) {
					onEditorOpen()
				}
			};
		}

		return (
			<Link to={LINK[restriction]} onClick={onClick}>
				<FormattedMessage
					{...translations[`restriction.${restriction}`]}
					values={{
						restriction,
						...values
					}}
				/>
			</Link>
		);
	}

	if (typeof restriction === "string") {
		if (restriction === "SUBSCRIBER_AGE_TOO_OLD" || restriction === "SUBSCRIBER_AGE_TOO_YOUNG") {
			let restriction_age;
			const groupRestrictionsObject = Array.isArray(groupRestrictions)
				? groupRestrictions[0] || {}
				: groupRestrictions

			if (restriction === "SUBSCRIBER_AGE_TOO_OLD") {
				restriction_age = groupRestrictionsObject.restriction_age_max;
			} else {
				restriction_age = groupRestrictionsObject.restriction_age_min;
			}
			const yearOrMonth = groupRestrictionsObject.yearOrMonth === true ? <FormattedMessage {...translations["age.month"]} values={{restriction_age}} /> : <FormattedMessage {...translations["age.year"]} values={{restriction_age}} />;
            const validateAgeOnDate = groupRestrictionsObject.validate_age_on ?
                moment(groupRestrictionsObject.validate_age_on) :
                new moment();
            const validate_age_on = validateAgeOnDate.format("LL");

			return (
				<FormattedMessage {...translations[`restriction.${restriction}`]}
					values={{
						restriction: restriction,
						restriction_age,
						yearOrMonth,
						validate_age_on,
						...values
					}}
				/>
			);
		}

		return (
			<FormattedMessage
				{...(
					translations[`restriction.${restriction}`] ?
					translations[`restriction.${restriction}`] :
					translations["restriction.DEFAULT"]
				)}
				values={{
					restriction,
					...values
				}}
			/>
		);
	}

	const {code} = restriction;
	const reactivateScrolling = function () {
		document.body.classList.remove('scroll-disabled');
	}

	if (code === "PERIOD_RESTRICTED_BY_MEMBERSHIP" || code === "PLAN_RESTRICTED_BY_MEMBERSHIP") {
		const membershipsList =
			<ul>
				{restriction["memberships"].map((membership) =>
					<li key={membership["id"]}>
						<Link onClick={reactivateScrolling} to={path("membership", {orgID: membership["slug"], membershipID: membership["id"]})}>
							{membership["name"]}
						</Link>
					</li>
				)}
			</ul>
		;

		return (
			<FormattedMessage {...translations[`restriction.${code}`]}
							  values={{
								  restriction: code,
								  membershipsList,
								  ...values
							  }}
			/>
		);
	}

    if (MEMBERSHIP_AGE_RESTRICTIONS.includes(code)) {
        const validateAgeOn = moment(restriction.details.validate_age_on).format("LL");
        const minAge = restriction.details.age_min;
        const maxAge = restriction.details.age_max;
        const ageUnit = restriction.details.age_display_type;
        const ageTextValue = {
            max_age: maxAge,
            min_age: minAge,
            max_age_unit: ageUnit === 'year'
                ? <FormattedMessage {...translations['age.year']} values={{restriction_age: maxAge}}/>
                : <FormattedMessage {...translations['age.month']} values={{restriction_age: maxAge}}/>,
            min_age_unit: ageUnit === 'year'
                ? <FormattedMessage {...translations['age.year']} values={{restriction_age: minAge}}/>
                : <FormattedMessage {...translations['age.month']} values={{restriction_age: minAge}}/>
        };

        const ageText = (minAge && maxAge && <FormattedMessage {...translations['age.range']} values={ageTextValue}/>)
            || (minAge && <FormattedMessage {...translations['age.min_only']} values={ageTextValue}/>)
            || (maxAge && <FormattedMessage {...translations['age.max_only']} values={ageTextValue}/>)
        ;

        return (
            <FormattedMessage {...translations[`restriction.${code}`]}
                values={{
                    age_text: ageText,
                    validate_age_on: validateAgeOn
                }}
            />
        );
    }

    if (MEMBERSHIP_GENDER_RESTRICTIONS.includes(code)) {
        return <FormattedMessage {...translations[`restriction.${code}`]}/>
    }

    if (code === PLAN_NOT_YET_ACTIVE) {
        const translation = restriction.details.start_date ? code : `${code}_NO_DATE`
        return (
            <FormattedMessage {...translations[`restriction.${translation}`]}
                  values={{
                      restriction: code,
                      start_date: <Date date={restriction.details.start_date} withTime={true}/>,
                      ...values
                  }}
            />
        );
    }

    const translation = translations[`restriction.${code}`];

    if (translation === undefined) {
        return (
            <FormattedMessage {...translations["restriction.DEFAULT"]}
                values={{
                    restriction: code,
                    ...values
                }}
            />
        );
    }

    return (
        <FormattedMessage {...translations[`restriction.${code}`]}
            values={{
                restriction: code,
                ...values
            }}
        />
    )

};

RestrictionMessage.propTypes = {
	restriction: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			code: PropTypes.string,
		}),
	]).isRequired,
	values: PropTypes.object,
	groupRestrictions: PropTypes.object,
	onEditorOpen: PropTypes.func,
    listClass: PropTypes.string
};

RestrictionMessage.contextTypes = {
	showAddressEditor: PropTypes.func,
	showPhoneNumberEditor: PropTypes.func,
};

export const RestrictionsFragment = (props, context) => {
    const {
        groupsCount,
        restrictions,
        groupRestrictions,
        onEditorOpen,
        listClass,
        explanationTranslationKey
    } = props;

    let groupRestricions = [];
    let maxSubscriptionExceptionAlreadyAdded = false;
    restrictions.groups_restrictions.map((groupRestriction) => {
        groupRestriction.restrictions.map((restriction) => {
			const restrictionCode = restriction.code ? restriction.code : restriction;
            if (restrictionCode === SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS) {

                if (maxSubscriptionExceptionAlreadyAdded) {
                    return;
                }

                maxSubscriptionExceptionAlreadyAdded = true;
            }
            groupRestricions.push(restriction);
        });
    });

	groupRestricions = Array.from(new Set(groupRestricions));

    const restrictionMerged = [].concat(
        groupRestricions,
        restrictions.membership_restrictions,
        restrictions.organization_restrictions,
        restrictions.product_restrictions,
        restrictions.registration_restrictions,
    );

    return (
        <div>
            <div>
                <FormattedMessage {...translations[explanationTranslationKey]} />
            </div>
            <ul className={listClass}>
                {restrictionMerged.map((restriction, key) =>
                    <li key={key}>
                        <RestrictionMessage
                            restriction={restriction}
                            values={{groupsCount}}
                            groupRestrictions={groupRestrictions}
                            onEditorOpen={onEditorOpen ? onEditorOpen : null}
                        />
                    </li>
                )}
            </ul>
        </div>
    );
};

RestrictionsFragment.propTypes = {
	groupRestrictions: PropTypes.object,
	groupsCount: PropTypes.number.isRequired,
	restrictions: PropTypes.arrayOf(RestrictionMessage.propTypes.restriction).isRequired,
	onEditorOpen: PropTypes.func,
    listClass: PropTypes.string,
    explanationTranslationKey: PropTypes.string
};

RestrictionsFragment.defaultProps = {
    explanationTranslationKey: 'explanation',
  };

export default translations;
