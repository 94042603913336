import React, {Component} from "react";
import PropTypes from "prop-types";
import Select from "qidigo-form/select";
import map from "lodash/map";
import {defineMessages} from "react-intl";

/**
 * Template for a question of type phone.
 */
const translations = defineMessages({
	select: {id: "qidigo.dashboard.form.question.select", defaultMessage: "Veuillez faire un choix"},
});

class SelectTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			question,
			answer,
			errorMessage,
			errors
		} = this.props;
		const {formatMessage} = this.context.intl;

		// Don't know if every item in this chain is set, so i put default values for every level of destructuration.
		const {template: {options: {choices = {}} = {}} = {}} = question;
		const {select=""} = answer || {};


		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Select
						error={errors["select"]}
						label={formatMessage(translations["select"])}
						options={question.choices_order.map((i) => ({key:i, value: choices[i]}))}
						value={select}
						onChange={(...e)=>this.handleChange("select", ...e)}
					/>
				</div>
			</div>
		);
	}
}

SelectTypeForm.defaultProps = {
	className: "",
	value: {select:""},
	errorMessage: ""
};

SelectTypeForm.contextTypes = {
	intl: PropTypes.object,
};

SelectTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	errors: PropTypes.object,
};

export default SelectTypeForm;

