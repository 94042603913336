import Fetch                 from "qidigo-fetch";
import {redirect, sendError} from "../router";

export const REQUEST_ACTIVITY = "SEARCH:REQUEST_ACTIVITY";
export const RECEIVE_ACTIVITY = "SEARCH:RECEIVE_ACTIVITY";
export const RECEIVE_ACTIVITY_FAILED = "SEARCH:RECEIVE_ACTIVITY_FAILED";
export const INVALIDATE_ACTIVITY = "SEARCH:INVALIDATE_ACTIVITY";

/**
 * Notifie qu'on effectue une requête.
 */
const requestActivity = (activityId) => ({
	type: REQUEST_ACTIVITY,
	activityId,
});

/**
 * Notifie de la réception des données pour une activité.
 */
const receiveActivity = (activityId, activity, groups) => ({
	type: RECEIVE_ACTIVITY,
	activityId,
	activity,
	groups,
	at: Date.now(),
});

/**
 * Force un refresh pour une activité.
 */
export const invalidateActivity = () => ({
	type: INVALIDATE_ACTIVITY,
});

/**
 * Récupère une activité, si nécessaire (compare l'ID)
 */
export const fetchActivity = (activityId) =>
	(dispatch, getState) => {
		// Convertis l'ID en string, pour stabilité entre les types.
		const id = activityId.toString();
		const {search: {activity: {activity}}} = getState();

		// Court-circuite si on a déjà la bonne activité.
		if (activity && activity["id"].toString() === id) { return null; }

		// Indique qu'on commence à récupérer l'activité.
		dispatch(requestActivity(id));

		// Dispatch la recherche d'activité.
		// FIXME : Autre action pour charger la liste des groupes.
		return dispatch((dispatch, getState) => {
			return Promise.all([
				Fetch.get(`activities/${id}`),
				Fetch.get(`activities/${id}/groups`),
			])
				.then(([{activity}, {groups}]) => {
					const {search} = getState();
					// N'est plus l'objet recherché?
					if (id !== search.activity.activityId) { return null; }

					// Aucun résultat correspond à un "soft-404"
					if (!activity) {
						return Promise.reject({status: 404});
					}
					// Activité chargée pour un mauvais organisme?
					if (activity.organization.id !== search.organization.organization.id) {
						return Promise.reject({status: 404});
					}

					// Réceptionne.
					dispatch(receiveActivity(id, activity, groups));

					return activity;
				})
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: RECEIVE_ACTIVITY_FAILED, error})
				});
		});
	};
