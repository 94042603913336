/* eslint camelcase: [0] */
import React from "react";
import PropTypes from "prop-types";
import {defineMessages, FormattedMessage,} from "react-intl";
import Money from "@app/components/money.js";
import ScheduleFragment from "@app/views/shared/groups/schedule";
import moment from "moment";
import capitalize from "lodash/capitalize";
import Duration from "../../shared/groups/duration";
import DateFragment from "../../../../../modules/qidigo-i18n/date";

const translations = defineMessages({
	"label.quantity": {id: "qidigo.invoice.row.label.quantity", defaultMessage: "Quantité"},
	"label.description": {id: "qidigo.invoice.row.label.description", defaultMessage: "Produit"},
	"label.subscriber": {id: "qidigo.invoice.row.label.subscriber", defaultMessage: "Personne inscrite"},
	"label.initial_price": {id: "qidigo.invoice.row.label.initial_price", defaultMessage: "Prix initial"},
	"label.adjustment": {id: "qidigo.invoice.row.label.adjustment", defaultMessage: "Ajustement"},
	"label.unit_price": {id: "qidigo.invoice.row.label.unit_price", defaultMessage: "Prix unitaire"},
	"label.subtotal": {id: "qidigo.invoice.row.label.subtotal", defaultMessage: "Sous-total"},
	"label.other_fees_subtotal": {id: "qidigo.invoice.row.label.subtotal", defaultMessage: "Sous-total"},
	"label.details": {id: "qidigo.invoice.row.informations.label.details", defaultMessage: "Détails"},
	"label.start": {id: "qidigo.invoice.row.informations.label.start", defaultMessage: "Début"},
	"label.end": {id: "qidigo.invoice.row.informations.label.end", defaultMessage: "Fin"},
	"label.location": {id: "qidigo.invoice.row.informations.label.location", defaultMessage: "Infrastructure"},
	"label.schedule": {id: "qidigo.invoice.row.informations.label.schedule", defaultMessage: "Horaire"},
	"label.duration": {id: "qidigo.invoice.row.informations.label.duration", defaultMessage: "Durée"},

	"label.note": {id: "qidigo.invoice.row.label.note", defaultMessage: "Note : {text}"},

	"header.reservation": {id: "qidigo.invoice.row.header.reservation", defaultMessage: "Réservations"},
	"header.other_fees": {id: "qidigo.invoice.row.header.other_fees", defaultMessage: "Frais divers"},

	"informations.intro.SESSION": {
		id: "qidigo.invoice.row.informations.intro.SESSION",
		defaultMessage: "L'achat de ce produit vous garantit une place pour la session, valide pour {groupsCount, plural, one {le cours ci-dessous} other {les cours ci-dessous} }."
	},
	"informations.intro.PASS": {
		id: "qidigo.invoice.row.informations.intro.PASS",
		defaultMessage: "L'achat de ce produit vous garantit un nombre de {passesCount, plural, one {une entrée, valide} other {{passesCount} entrées, valides} } pour {groupsCount, plural, one {le cours ci-dessous} other {les cours ci-dessous} }."
	},
	"informations.intro.DETAILED_SESSION": {
		id: "qidigo.invoice.row.informations.intro.DETAILED_SESSION",
		defaultMessage: "L'achat de ce produit vous garantit une place pour les séances ci-dessous."
	},

	"informations.label.details": {id: "qidigo.invoice.row.informations.label.details", defaultMessage: "Détails"},
	"informations.label.schedule": {id: "qidigo.invoice.row.informations.label.schedule", defaultMessage: "Horaire"},
	"informations.label.start": {id: "qidigo.invoice.row.informations.label.start", defaultMessage: "Début"},
	"informations.label.end": {id: "qidigo.invoice.row.informations.label.end", defaultMessage: "Fin"},
	"informations.label.number": {
		id: "qidigo.invoice.row.informations.label.number",
		defaultMessage: "Nombre de cours"
	},

	"informations.label.date": { id: "qidigo.invoice.row.informations.label.details", defaultMessage: "Date" },
	"informations.label.hours": { id: "qidigo.invoice.row.informations.label.schedule", defaultMessage: "Horaire" },
	"informations.label.location": { id: "qidigo.invoice.row.informations.label.location", defaultMessage: "Infrastructure" },
	"informations.schedule.variable": { id: "qidigo.invoice.row.informations.schedule.variable", defaultMessage: "Aucun horaire défini pour l'instant", description: "Used when there is no schedule defined for the group." },

	"informations.duration.years": {
		id: "qidigo.invoice.row.informations.duration.years",
		defaultMessage: "{years} {years, plural, one {année} other {années}}"
	},
	"informations.duration.months": {
		id: "qidigo.invoice.row.informations.duration.months",
		defaultMessage: "{months} {months, plural, one {mois} other {mois}}"
	},
	"informations.duration.weeks": {
		id: "qidigo.invoice.row.informations.duration.weeks",
		defaultMessage: "{weeks} {weeks, plural, one {semaine} other {semaines}}"
	},
	"informations.duration.days": {
		id: "qidigo.invoice.row.informations.duration.days",
		defaultMessage: "{days} {days, plural, one {jour} other {jours}}"
	},
	"informations.duration.hours": {
		id: "qidigo.invoice.row.informations.duration.hours",
		defaultMessage: "{hours} {hours, plural, one {heure} other {heures}}"
	},

});

const SESSION_DETAILS_COLUMNS = [
	"date",
	"hours",
	"location"
];

const DETAILS_COLUMNS = [
	"details",
	"schedule",
	"start",
	"end",
	"number",
];


// Available display. Defined by the billing
const MEMBER = 'MEMBER';
const PRODUCT = 'PRODUCT';
const RESERVATION = 'RESERVATION';
const BILLING = 'BILLING';
const CONTRACT = 'CONTRACT';

// Available plan display. Defined by the plan on the bill
const SESSION = 'SESSION';
const SCHEDULE = 'SCHEDULE';
const NONE = 'NONE';

const AdditionalData = (props, context) => {
	const { item, columns } = props;
	const { plan } = item;
	const type = plan["type_plan"];
	const { groups } = plan;
	let intro =
		<div className="invoice-row--informations">
			<FormattedMessage
				{...translations[`informations.intro.${type}`]}
				values={{
					passesCount: plan.quantity,
					groupsCount: groups.length,
				}}
			/>
		</div>;

	let note = null;
	if (plan["note"]) {
		note =
			<div className="invoice-row--note">
				<FormattedMessage {...translations["label.note"]} values={{ text: plan["note"] }} />
			</div>;
	}

	let groupsFragment = null;
	if (plan && groups.length > 0) {
		switch (plan["invoice_line_display_mode"]) {
			case SCHEDULE:
				groupsFragment = <PlanScheduleDisplay groups={groups} />
				break;
			case SESSION:
				intro =
					<div className="invoice-row--informations">
						<FormattedMessage
							{...translations[`informations.intro.DETAILED_SESSION`]}
						/>
					</div>
				groupsFragment = <PlanSessionDisplay groups={groups} />
				break;
			case NONE:
				intro = null;
				break;
			default:
				break;
		}
	}

	return (
		<tr className="invoice-row--additional-data">
			<td colSpan={columns.length}>
				{intro}
				{groupsFragment}
				{note}
			</td>
		</tr>
	);
};

AdditionalData.propTypes = {
	item: PropTypes.object.isRequired,
	columns: PropTypes.array.isRequired,
};


const PlanScheduleDisplay = (props, context) => {

	const { groups } = props;

	return (
		<table className="invoice-row--groups">
			<thead>
				<tr>
					{DETAILS_COLUMNS.map((column) => <th key={column} className={`invoice-row--column-${column}`}><FormattedMessage {...translations[`informations.label.${column}`]} /></th>)}
				</tr>
			</thead>
			<tbody>
				{groups.map((group, key) => {
					const { name, local, registration_count, schedule, start_date, end_date } = group;

					return (
						<tr key={key}>
							<td><h5>{name}</h5><div>{local}</div></td>
							<td>
								<ScheduleFragment
									times={schedule}
									variableLabel={<FormattedMessage {...translations["informations.schedule.variable"]} />}
								/>
							</td>
							<td className="is-date"><DateFragment date={start_date} /></td>
							<td className="is-date"><DateFragment date={end_date} /></td>
							<td className="is-number">{registration_count}</td>
						</tr>
					);
				})}
			</tbody>
		</table>)
}

PlanScheduleDisplay.propTypes = {
	groups: PropTypes.arrayOf(PropTypes.object).isRequired
};


const PlanSessionDisplay = (props, context) => {

	const { groups } = props;

	let groupRows = [];
	{
		groups.map((group, key) => {
			groupRows.push(
				<div key={key}>
					<div className="invoice-row--groupname">
						<span>
							{group['name']}
						</span>
					</div>
					<table className="invoice-row--groups">
						<thead>
							<tr>
								{SESSION_DETAILS_COLUMNS.map((column) => <th key={column} className={`invoice-row--column-${column}`}><FormattedMessage {...translations[`informations.label.${column}`]} /></th>)}
							</tr>
						</thead>
						<tbody>
							<SessionDisplay sessions={group['sessions']} />
						</tbody>
					</table>
				</div>
			)
		})
	}

	return groupRows;
}

PlanSessionDisplay.propTypes = {
	groups: PropTypes.arrayOf(PropTypes.object).isRequired
}


const SessionDisplay = (props, context) => {

	const { sessions } = props;

	let rows = [];

	let sessionsSortedByDate = (sessions || [])
		.slice()
		.sort((sessionA, sessionB) => (sessionA.start_date > sessionB.start_date) ? 1 :
			(sessionA.start_date < sessionB.start_date) ? -1 :
				0);

	rows.push(sessionsSortedByDate.map((session, key) => {

		const { name, start_date, end_date, session_local } = session;
		const momentStartDate = moment(start_date);
		const momentEndDate = moment(end_date);
		const isSameDay = momentStartDate.isoWeekday() === momentEndDate.isoWeekday();

		return (
			<tr key={key}>
				<td className="is-date invoice-table-cell">
					<SessionDateDisplay isSameDay={isSameDay} startDate={start_date} endDate={end_date} />
				</td>
				<td className="invoice-table-cell">
					<SessionHourDisplay isSameDay={isSameDay} from={momentStartDate} to={momentEndDate} />
				</td>
				<td className="invoice-table-cell">{session_local}</td>
			</tr>
		)
	}));
	return rows;

}


SessionDisplay.propTypes = {
	sessions: PropTypes.arrayOf(PropTypes.object).isRequired
}

const SessionDateDisplay = (props, context) => {
	const { isSameDay, startDate, endDate } = props;

	if (isSameDay) {
		return (<DateFragment date={startDate} />);
	} else {
		let message = [];
		message.push(
			<span>
				<DateFragment date={startDate} /> au <DateFragment date={endDate} />
			</span>
		);
		return message;
	}
};

SessionDateDisplay.propTypes = {
	isSameDay: PropTypes.bool.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired
}

const SessionHourDisplay = (props, context) => {
	const { isSameDay, from, to } = props;

	const fromHour = from.format("HH:mm");
	const toHour = to.format("HH:mm");

	if (isSameDay) {
		return (capitalize(moment.weekdays(from.isoWeekday())) + ' ' + fromHour + ' à ' + toHour);
	} else {
		return (capitalize(moment.weekdays(from.isoWeekday())) + ' ' + fromHour + ' à ' + capitalize(moment.weekdays(to.isoWeekday())) + ' ' + toHour);
	}

};

SessionHourDisplay.propTypes = {
	isSameDay: PropTypes.bool.isRequired,
	from: PropTypes.object.isRequired,
	to: PropTypes.object.isRequired,
}

const getSchedule = (startDateString, endDateString) => {
	const startDate = new Date(startDateString);
	const endDate = new Date(endDateString);

	if (!startDateString || !endDateString) {
		return [];
	}

	const startTime = startDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
	const endTime = endDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});

	return [
		{
			day: startDate.getDay(),
			hours: [
				[
					startTime,
					endTime
				]
			]
		}
	]
};

const Row = (props, context) => {
	const { item, columns } = props;

	const reservation = item.plan.reservation;

	const data = {
		quantity: { value: item.quantity, format: "number" },
		description: { value: item.description },
		subscriber: { value: item.subscriber_full_name },
		initial_price: { value: item.price_without_adjustments, format: "money" },
		adjustment: { value: item.visible_adjustments_sum, format: "money" },
		unit_price: { value: item.price, format: "money" },
		subtotal: { value: item.total, format: "money" },
		other_fees_subtotal: { value: item.total, format: "money" },
		start: { value: reservation ? reservation.start_date : null, format: "date" },
		end: { value:  reservation ? reservation.end_date : null, format: "date" },
		location: { value:  reservation ? reservation.local : null },
		schedule : {value:  reservation ? reservation.schedules : null, format: "schedules" },
		duration : {value:  reservation ? reservation.duration : null, format: 'duration' }
	};

	return (
		<tr className="invoice-row">
			{columns.map((column) =>
				<td className={`invoice-row--${column} ${data[column]["format"] ? `is-${data[column]["format"]}` : ""}`} key={column}>
					{
						data[column]["format"] === "money" ?
							<Money value={data[column]["value"]} /> :
							data[column]["format"] === "date" ?
								<DateFragment date={data[column]["value"]} /> :
								data[column]["format"] === "schedules" ?
									<ScheduleFragment times={(data['schedule']['value'].length === 0
										? getSchedule(reservation.start_date, reservation.end_date)
										: data['schedule']['value'])}
									/> :
									data[column]["format"] === "duration" ?
										formatDuration(	data[column]["value"]) :
											data[column]["value"]
					}
				</td>
			)}
		</tr>
	);
};

const formatDuration = (durationInMinutes) => {
	const minutesInHour = 60;
	const minutesInDay = 60 * 24;
	const minutesInWeek = minutesInDay * 7;
	const minutesInMonth = minutesInDay * 30;
	const minutesInYear = minutesInDay * 365;

	let years = Math.floor(durationInMinutes / minutesInYear);
	let remainingDuration = durationInMinutes % minutesInYear;
	let months = Math.floor(remainingDuration / minutesInMonth);
	remainingDuration %= minutesInMonth;
	let weeks = Math.floor(remainingDuration / minutesInWeek);
	remainingDuration %= minutesInWeek;
	let days = Math.floor(remainingDuration / minutesInDay);
	remainingDuration %= minutesInDay;
	let hours = Math.floor(remainingDuration / minutesInHour);

	let formattedDuration = [];
	if (years > 0) {
		formattedDuration.push(<FormattedMessage {...translations['informations.duration.years']} values={{years: years}}/>);
	}
	if (months > 0) {
		formattedDuration.push(<FormattedMessage {...translations['informations.duration.months']} values={{months: months}}/>);
	}
	if (weeks > 0) {
		formattedDuration.push(<FormattedMessage {...translations['informations.duration.weeks']} values={{weeks: weeks}}/>);
	}
	if (days > 0) {
		formattedDuration.push(<FormattedMessage {...translations['informations.duration.days']} values={{days: days}}/>);
	}
	if (hours > 0) {
		formattedDuration.push(<FormattedMessage {...translations['informations.duration.hours']} values={{hours: hours}}/>);
	}

	return (
		<div className={'invoice-row--duration'}>
			{formattedDuration.map((element, index) => (
					<p key={index}>{element} {' '}</p>
				)
			)}
		</div>
	)
}

Row.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.string).isRequired,
	item: PropTypes.object,
};

const Rows = (props, context) => {
	const {
		items,
		refunding,
		isContract,
		showAdjustments,
		columns
	} = props;

	return (
		<div className="table-wrapper">
			<table className={`${refunding ? "is-refunding" : ""} invoice-table`}>
				<thead>
				<tr>
					{columns.map((column) =>
						<th key={column} className={`invoice--column-${column + (isContract && column === 'description' ? '-reservation' : '')}`}>
						<FormattedMessage {...translations[`label.${column}`]} /></th>)}
				</tr>
				</thead>
				<tbody>
				{items.map((item, i) => [
					<Row item={item} key={`item-${i}`} showAdjustments={showAdjustments} columns={columns}/>,
					refunding || isContract ? null : <AdditionalData key={`desc-${i}`} item={item} columns={columns}/>
				])}
				</tbody>
			</table>
		</div>
	);
}

Rows.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	refunding: PropTypes.bool,
	isContract: PropTypes.bool,
	showAdjustments: PropTypes.bool,
	columns: PropTypes.arrayOf(PropTypes.string).isRequired
};

const GroupedByReservationRows = (props, context) => {
	const {
		items,
		refunding,
		isContract,
		showAdjustments,
	} = props;

	let reservations = [];
	let otherFees = [];

	for (const item of items) {
		if (item.plan.reservation) {
			reservations.push(item);
			continue;
		}

		otherFees.push(item);
	}

	const reservationColumns = [
		'description',
		'location',
		'start',
		'end',
		'schedule',
		'duration',
		'subtotal'
	]

	const otherFeesColumns = [
		'description',
		'quantity',
		'other_fees_subtotal'
	]

	items.sort(compareStartDates);

	return (
		<div>
			{
				reservations.length > 0 &&
				<div className={`reservation-invoice-box`}>
					<div className={`reservation-display`}>
						<h3>
							<FormattedMessage {...translations['header.reservation']} />
							<hr/>
						</h3>
					</div>
					<Rows
						items={reservations}
						refunding={refunding}
						isContract={isContract}
						showAdjustments={showAdjustments}
						columns={reservationColumns}
					/>
				</div>
			}
			{
				otherFees.length > 0 &&
				<div className={`other-fees-invoice-box`}>
					<div className={`other-fess-display`}>
						<h3>
							<FormattedMessage {...translations['header.other_fees']} />
							<hr/>
						</h3>
					</div>
					<Rows items={otherFees} refunding={refunding} isContract={isContract} showAdjustments={showAdjustments} columns={otherFeesColumns} />
				</div>
			}
		</div>
	)
}

GroupedByReservationRows.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	refunding: PropTypes.bool,
	isContract: PropTypes.bool,
	showAdjustments: PropTypes.bool,
}

const compareStartDates = (a, b) => {
	if (!a.plan.reservation && !b.plan.reservation) {
		return 0;
	}
	if (!a.plan.reservation) {
		return 1;
	}
	if (!b.plan.reservation) {
		return -1;
	}

	const dateA = new Date(a.plan.reservation.start_date);
	const dateB = new Date(b.plan.reservation.start_date);

	return dateA - dateB;
};

const GroupedByMembersRows = (props, context) => {
	const { groupedBilling, refunding, showAdjustments, columns } = props;

	let returnedDom = [];

	for (const [subscriberName, items] of Object.entries(groupedBilling)) {
		returnedDom.push(
			<div key={subscriberName} className={`subscriber-invoice-box ${open ? "is-opened" : "is-not-opened"}`}>
				<div className={`subscriber-display`}>
					<h3>
						{subscriberName}
						<hr />
					</h3>
				</div>
				<Rows items={items} refunding={refunding} showAdjustments={showAdjustments} columns={columns} />
			</div>
		)
	}
	return (
		<div className={`invoice-by-members`}>
			{returnedDom}
		</div>
	)
}

GroupedByMembersRows.propTypes = {
	groupedBilling: PropTypes.object.isRequired,
	refunding: PropTypes.bool.isRequired,
	showAdjustments: PropTypes.bool.isRequired,
	columns: PropTypes.arrayOf(PropTypes.string).isRequired
}

/**
 */
const InvoiceRows = (props, context) => {
	const {
		items,
		invoiceModel,
		refunding,
		showAdjustments,
		groupedBy
	} = props;

	// Les colonnes sont méta-programmées et dynamiques.
	// Les colonnes sont données aux childs.
	let columns = [
		"quantity",
		"description"
	];

	if (groupedBy === PRODUCT) {
		columns.push(
			"subscriber"
		);
	}

	if (showAdjustments) {
		columns.push(
			"adjustment"
		);
	}
	columns.push(
		"subtotal"
	);

	let returnedDom = null;

	switch (groupedBy) {
		case RESERVATION:
			returnedDom = (
				<GroupedByReservationRows
					items={items}
					refunding={refunding}
					isContract={groupedBy === RESERVATION}
					showAdjustments={showAdjustments}
				/>
			)
			break;
		case MEMBER:
			// Grouping items by subscribed member
			const groupedByMembersItems = items.reduce((itemsGrouped, item) => {
				if (!itemsGrouped[item.subscriber_full_name]) itemsGrouped[item.subscriber_full_name] = [];
				itemsGrouped[item.subscriber_full_name].push(item);
				return itemsGrouped;
			}, {});

			returnedDom = (
				<GroupedByMembersRows
					groupedBilling={groupedByMembersItems}
					refunding={refunding}
					showAdjustments={showAdjustments}
					columns={columns} />
			);
			break;
		default:
			returnedDom = (
				<Rows items={items} refunding={refunding} isContract={invoiceModel === CONTRACT} showAdjustments={showAdjustments} columns={columns} />
			);
			break;
	}
	return returnedDom;
};

InvoiceRows.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	invoiceModel: PropTypes.string,
	refunding: PropTypes.bool,
	showAdjustments: PropTypes.bool,
	groupedBy: PropTypes.string
};

InvoiceRows.defaultProps = {
	refunding: false,
	showAdjustments: false,
	groupedBy: PRODUCT,
	invoiceModel: BILLING
};

InvoiceRows.contextTypes = {
	intl: PropTypes.object,
};

export default InvoiceRows;
