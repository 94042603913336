/* global window */
/* eslint no-underscore-dangle: [0] */

// Reads the environment object set on window.
// It was either set by a compilation step for a static template
// or set at runtime by a server-side component.
const ENV = Object.assign(
	window.ENV || {},
	{browser: true, server: false}
);

// Not mandatory. It will be relative to the running application if not set.
// (FIXME: this is a bit of webapp leaking in every projects.)
if (ENV["CLIENT_API_HOST"]) {
	ENV["API_HOST"] = ENV["CLIENT_API_HOST"];
}

export default ENV;
