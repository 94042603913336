import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {Link}                from "react-router/es";
import {
	FormattedMessage,
	defineMessages,
} from "react-intl";
import {Collapse}            from "react-collapse";
import map                   from "lodash/map";
import some                  from "lodash/some";

const translations = defineMessages({
	"toggler.open":  {id: "qidigo.side-nav.toggler.open", defaultMessage: "Afficher le menu"},
	"toggler.close": {id: "qidigo.side-nav.toggler.close", defaultMessage: "Cacher le menu"},
	"header.menu": {id: "qidigo.side-nav.header.menu", defaultMessage: "Navigation"},
});

class Fragment extends Component {
	constructor() {
		super();
		this.state = {
			collapsed: true,
			userOpened: false,
		};
	}

	autoToggle() {
		const {opts} = this.props;
		const sub = opts.sub || [];
		const {userOpened} = this.state;

		const collapsed = !this.isActive();
		if (this.state.collapsed !== collapsed) {
			if (!userOpened || collapsed === false) {
				this.setState({collapsed});
			}
		}
	}

	activate() {
		setTimeout(() => this.props.close(), 600);
	}

	componentWillMount() {
		this.autoToggle();
	}

	componentDidUpdate(props, state, context) {
		this.autoToggle();
	}

	handleToggle(e) {
		if (this.isActive()) {
			e.preventDefault();
			this.setState({collapsed: !this.state.collapsed, userOpened: true});
		}
		else {
			this.setState({collapsed: false, userOpened: true});
		}
	}

	/**
	 * Si un des liens de soi-même ou des sous-sections est actifs.
	 */
	isActive() {
		const {opts} = this.props;
		const sub = opts.sub || [];
		const {router} = this.context;

		const links = []

		// Lien de l'item
		if (opts["link"]) {
			links.push(opts["link"]);
		}

		// Liens alternatifs de l'item
		if (opts["otherLinks"]) {
			links.push(...opts["otherLinks"]);
		}

		// Liens des enfants et liens alternatifs des enfants.
		links.push(...sub.reduce(
			(res, opts) => res.concat([opts["link"], ...opts["otherLinks"] || []])
		, []));

		return some(links, (link) => {
			if (link === '/dashboard/invoices') {
				return router.location.pathname.includes('/show')
					|| router.location.pathname.includes('/create-a-settlement')
					||  router.isActive(link, sub.length === 0)
			}

			return router.isActive(link, sub.length === 0)}
		);
	}

	render() {
		const {level, opts, close} = this.props;
		const Type = opts.type ? opts.type : Link;
		const {link} = opts;
		const {collapsed} = this.state;

		const sub = opts.sub || [];
		const active = this.isActive();

		const linkProps = {};
		if (link) {
			linkProps.to = link;
		}
		else {
			if (sub.length > 0) {
				linkProps.to = sub[0]["link"]
			}
		}

		if (sub.length > 0) {
			linkProps["onClick"] = (...e) => this.handleToggle(...e);
		}

		const subFragment = sub ?
			<Collapse isOpened={!collapsed}>
				<ul>
					{map(sub, (opts) => <Fragment level="sub" key={opts.key} opts={opts} close={close} />)}
				</ul>
			</Collapse> : null
			;

		return (
			<li key={opts.key}
				className={`side-nav--item-${level}`}
			>
				<Type
					activeClassName="active"
					className={`sidenav--${opts.key} ${active ? "active" : ""}`}
					onClick={(...e) => this.activate()}
					{...linkProps}
				>
					<span>
						<span className="side-nav--text">
							<FormattedMessage {...opts.translation} />
							{opts.children ? opts.children : null}
						</span>
					</span>
				</Type>
				{subFragment}
			</li>
		);
	}
}

Fragment.propTypes = {
	opts: PropTypes.object.isRequired,
	level: PropTypes.string.isRequired,
	close: PropTypes.func.isRequired,
};

Fragment.contextTypes = {
	router: PropTypes.object,
};

/**
 * Component de menu de navigation...
 *
 * Force l'utilisation de *translations* avec l'utilisation de `<FormattedMessage />` ;).
 *
 * Le side-menu est *collapsé par défaut*. Ce comportement affecte uniquement la navigation
 * sur mobile puisque le comportement de `react-collapse` est overridé via CSS.
 *
 * IL NE DOIT PAS Y AVOIR DE CODE QUI SPECIAL-CASE MOBILE. On doit overrider d'une manière
 * qui peut causer le moins d'effet secondaires inattendus.
 *
 * C'est pourquoi on a (maintenant) un state `initial_render` qui permet de forcer le menu
 * en tant que caché tant qu'on est dans le render initial. On doit premièrement forcer le
 * menu ouvert puisque react-collapse ne mount plus les éléments si react-collapse est
 * renderé collapsed initialement.
 */
class SideNav extends Component {
	constructor() {
		super();
		this.state = {
			collapsed: false,
			initial_render: true,
		};
	}

	componentDidMount() {
		this.setState({collapsed: true});
	}

	handleToggle(e) {
		this.handleRest();
		e.preventDefault();
		const {collapsed} = this.state;
		this.setState({collapsed: !collapsed});
	}

	handleRest() {
		if (this.state.initial_render) {
			this.setState({initial_render: false});
		}
	}

	close() {
		this.setState({collapsed: true});
	}

	render() {
		const {
			className,
			menu,
		} = this.props;
		const {
			collapsed,
			initial_render,
		} = this.state;
		const {
			formatMessage,
		} = this.context.intl;

		const classes = ["side-nav", className];
		classes.push(collapsed ? "is-collapsed" : "is-not-collapsed");

		const collapse_style = {};
		// Utilisé pour forcer un mount des children.
		if (initial_render) {
			collapse_style["display"] = "none";
		}

		return (
			<nav className={classes.join(" ")}>
				<a
					onClick={(...e) => this.handleToggle(...e)}
					className="side-nav--toggler"
					title={formatMessage(
						{...translations[`toggler.${collapsed?"open":"close"}`]}
					)}
				>
					<div className="bars" />
					<div className="bars" />
					<div className="bars" />
					<FormattedMessage
						{...translations[`toggler.${collapsed?"open":"close"}`]}
					/>
				</a>
				<Collapse
					isOpened={!collapsed}
					onRest={() => this.handleRest()}
					style={collapse_style}
					hasNestedCollapse={true}
				>
					<div className="side-nav--header" onClick={(...e) => this.close(...e)}>
						<FormattedMessage {...translations["header.menu"]} />
					</div>
					<ul className="side-nav--menu">
						{map(menu, (opts) => <Fragment level="main" key={opts.key} opts={opts} close={(...e) => this.close(...e)} />)}
					</ul>
				</Collapse>
			</nav>
		);
	}
}

SideNav.propTypes = {
	menu: PropTypes.array,
	className: PropTypes.string,
};

SideNav.contextTypes = {
	intl: PropTypes.object,
};

export default SideNav;
