import React, {Component} from "react";

/**
 * Séparateur, avec contextuellement du texte ou non.
 *
 * **Note**: On utilise des éléments DOM au lieu de ::before et ::after
 * pour styliser étant donné les problèmes liés à l'utilisation des
 * flexbox et des remplacement des flexbox pour les vieux navigateurs
 * vis-à-vis leur utilisation avec les pseudo-éléments.
 *
 * @extends {Component}
 */
class Separator extends Component {
	render() {
		if (this.props.children) {
			return (
				<div className="separator separator-with-text">
					<div className="separator--before"></div>
					<div className="separator--text">
						{this.props.children}
					</div>
					<div className="separator--after"></div>
				</div>
			);
		}

		return (
			<hr className="separator" />
		);
	}
}

export default Separator;

