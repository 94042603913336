import React, {Component}    from "react";
import {connect}             from "@app/lib/redux";
import PropTypes             from "prop-types";

import Loading               from "qidigo-components/loading.js";
import {navigate}            from "qidigo-router";

import {
	fetchActivity,
	invalidateActivity,
}   from "@app/store/actions/search/activity";

/**
 * @extends {Component}
 */
class ActivityController extends Component {
	/**
	 * Charge le data pour le contrôleur.
	 * (API publique)
	 */
	static load(dispatch, params) {
		return Promise.all([
			dispatch(fetchActivity(params.activityID)),
		]);
	}

	/**
	 * Proxy pour `static load`.
	 */
	load() {
		const {dispatch, params} = this.props;
		ActivityController.load(dispatch, params);
	}

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		// FIXME : Comprendre pourquoi je dois invalider ici...
		const {dispatch} = this.props;
		dispatch(invalidateActivity());
	}

	componentDidUpdate(prevProps) {
		if (prevProps.params.activityID !== this.props.params.activityID) {
			this.load();
		}
	}

	render() {
		const {
			route,  //eslint-disable-line
			params, //eslint-disable-line
			children,
			activity,
			groups,
			organization,
			...props
		} = this.props;

		if (!activity) { return <Loading />; }

		// Redirects to parent if there's no active group (simple) or possible active group (multiple)
		if (!params["groupID"] &&
			groups.length === 0 &&
			(
				activity["template"] === 'simple' ||
				(activity["template"] === 'multiple' && activity["is_full"])
			)
		) {
			navigate(`/u/${organization.slug}`);

			return null;
		}

		const childProps = Object.assign({
			activity,
			groups,
			organization,
		}, props);

		return (
			children && React.cloneElement(children, childProps)
		);
	}
}

ActivityController.propTypes = {
	dispatch: PropTypes.func,
	route: PropTypes.object,
	params: PropTypes.shape({
		activityID: PropTypes.string,
	}).isRequired,
	organization: PropTypes.object,
	activity: PropTypes.object,
	groups: PropTypes.arrayOf(PropTypes.object),
};

// Connecte avec le store redux.
const withProperties = ({search: {activity: {activity, groups, fetching}}}) => ({activity, groups, fetching});
export default connect(withProperties)(ActivityController);
