import React from "react";
import PropTypes from "prop-types";

/**
 * Retourne la string pour un résultat de province.
 */
const show = (v) => v.abbreviation || v.name || "";

const State = ({state, ...props}) => <span {...props}>{show(state)}</span>;

State.propTypes = {
	state: PropTypes.object,
};

export default State;
export {show as showState};
