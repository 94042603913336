import React                 from "react";
import {Route, Redirect}     from "react-router/es";

import Login                 from "@app/controllers/login/login.js";
import Logout                from "@app/controllers/login/logout.js";
import ProviderLogin         from "@app/controllers/login/provider.js";

import ForgotPassword        from "@app/controllers/forgot/index.js";
import ForgotPasswordToken   from "@app/controllers/forgot/token.js";

import QidigoAuth            from "qidigo-auth";

/**
 * Redirige au dashboard sur l'accès d'une des routes d'enregistrement ou de
 * login; ces routes ne font pas de sens pour un utilisateur connecté.
 *
 * À utiliser en `onEnter` et en `onChange`.
 */
function ensureLoggedOff(nextState, replace, next) {
	QidigoAuth.userLoggedIn().then((result) => {
		if (result) { replace({pathname: "/dashboard"}); }

		return next();
	});
}

//
// Liste de routes pour le flow de login, de registration et de reset.
//
export default
	<Route>
		{/* Toujours permettre le logout. */}
		<Route path="logout"     component={Logout} />
		{/*
			À tous les essais de navigation vers login ou register.
			On utilise `onEnter` et `onChange` étant donné qu'on veut attraper
			une navigation `/login → /login` (qui pourrait arriver) ou même une
			navigation vers une autre des pages dans ce groupe.
		 */}
		<Route
			onEnter={(nextState, replace, next)=>ensureLoggedOff(nextState, replace, next)}
			onChange={(prevState, nextState, replace, next)=>ensureLoggedOff(nextState, replace, next)}
		>
			<Route path="login"      component={Login} />
			<Route path="login/:provider/callback" component={ProviderLogin} />

			{/* Cool URIs don't change. */}
			<Redirect from="forgot/password" to="password/reset" />
			<Route path="password/reset" component={ForgotPassword} />
			<Route path="password/reset/:id/:token" component={ForgotPasswordToken} />
			<Redirect from="register" to="login" />
		</Route>
	</Route>
	;
