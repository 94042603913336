import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Fetch                 from "qidigo-fetch";
import Logger                from "qidigo-logger";

import Loading               from "qidigo-components/loading";
import UserPhoneNumbersPage  from "@app/views/dashboard/phone_numbers";

/**
 * @extends {Component}
 */
class UserPhoneNumbersController extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			errors:  false,
			saving:  false,
			phoneNumbers: null,
		};
	}

	//
	// Fetch the infos on mount.
	//
	componentWillMount() {
		// (The context is not available before mount.)
		this.fetchUserPhoneNumbers();
	}

	fetchUserPhoneNumbers() {
		Fetch.get(`users/${this.context.loggedUser.id}/phone_numbers`)
		.then((response) => {
			const phoneNumbers = response;
			this.setState({loading: false, phoneNumbers});

			return response;
		})
		.catch(Logger.catcher)
		.catch((err) => {
			this.setState({loading: false, errors: err});
		});
	}

	/**
	 * Spécifie un numéro comme numéro par défaut.
	 */
	setDefault(id) {
		this.setState({loading: true, errors: null});
		Fetch.post(`users/${this.context.loggedUser.id}/phone_numbers/default`, {
			["phone_number_id"]: id
		})
		.then((response) => {
			this.fetchUserPhoneNumbers();

			return null;
		})
		.catch(Logger.catcher)
		.catch((err) => {
			this.setState({loading: false, errors: err});
		});
	}

	render() {
		const {
			...props
		} = this.props;
		if (this.state.loading) { return <Loading />; }

		return <UserPhoneNumbersPage
			phoneNumbers={this.state.phoneNumbers}
			setDefault={(id) => this.setDefault(id)}
			{...props}
		/>;
	}
}

UserPhoneNumbersController.contextTypes = {
	loggedUser: PropTypes.object,
};

UserPhoneNumbersController.propTypes = {
	getNewPhoneNumberLinkProperties: PropTypes.func.isRequired,
	getEditPhoneNumberLinkProperties: PropTypes.func.isRequired,
};

export default UserPhoneNumbersController;
