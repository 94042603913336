import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage
}      from "react-intl";
import upperFirst from "lodash/upperFirst";

const translations = defineMessages({
	// Age restrictions
	"format.months": {id: "qidigo.groups.age.format.months", defaultMessage: "{number} mois"},
	"format.years":  {id: "qidigo.groups.age.format.years",  defaultMessage: "{number, plural, one {{number} an} other {{number} ans}}"},
	"message.equal":   {id: "qidigo.groups.age.message.equal", defaultMessage: "{min}"},
	"message.min":   {id: "qidigo.groups.age.message.min", defaultMessage: "{min} et plus"},
	"message.max":   {id: "qidigo.groups.age.message.max", defaultMessage: "{max} et moins"},
	"message.everyone": {id: "qidigo.groups.age.message.everyone", defaultMessage: "pour tous"},
	"message.between":  {id: "qidigo.groups.age.message.between",  defaultMessage: "{min} - {max}"},
});

/**
 * Formatte une restriction d'âge pour l'affichage.
 */
const AgeRestriction = (props, context) => {
	const {formatMessage} = context.intl;
	let {min, max, firstToUpper} = props;
	let unit = "years";
	let message = "everyone";

	if (min === 0) { min = null; }
	if (max === 9999) { max = null; }

	if (min % 12 > 0 || max % 12 > 0) {
		unit = "months";
	}

	if (unit === "years") {
		min = Math.floor(min / 12);
		max = Math.floor(max / 12);
	}

	if (min && max) {
		if (min === max) {
			message = "equal";
		} else {
			message = "between";
		}
	}
	else if (min) {
		message = "min";
	}
	else if (max) {
		message = "max";
	}

	const minValue = formatMessage({...translations[`format.${unit}`]}, {number: min});
	const maxValue = formatMessage({...translations[`format.${unit}`]}, {number: max});
	const text = formatMessage({...translations[`message.${message}`]}, {min: minValue, max: maxValue});

	return firstToUpper ? upperFirst(text) : text;
};
AgeRestriction.contextTypes = {
	intl: PropTypes.object,
};

AgeRestriction.propTypes = {
	firstToUpper: PropTypes.bool,
	min: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	max: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};


AgeRestriction.defaultProps = {
	firstToUpper: false,
};

export default AgeRestriction;
