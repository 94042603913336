import * as React from "react";
import {Component} from "react";
import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";

const translations = defineMessages({
    "pay": {
        id: "qidigo.billing.credit_card_form.pay",
        defaultMessage: "Payer",
    }
});

interface PayButtonComponentProps {
    payAction: () => void,
    disabled: boolean
}

class PayButtonComponent extends Component<PayButtonComponentProps> {

    constructor(props: PayButtonComponentProps) {
        super(props);
    }

    render() {
       return (
            <footer className={"pay-button--container"}>
                <Button
                    className={"pay-button--button primary"}
                    onClick={this.props.payAction}
                    disabled={this.props.disabled}
                >
                    <FormattedMessage {...translations["pay"]} />
                </Button>
            </footer>
       )
    }
}

export default PayButtonComponent;
