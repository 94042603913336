import React from "react";
import PropTypes from "prop-types";

/**
 * Retourne la string pour un résultat de ville.
 */
const show = (v) => v.name || "";

const City = ({city, ...props}) => <span {...props}>{show(city)}</span>;

City.propTypes = {
	city: PropTypes.object,
};

export default City;
export {show as showCity};
