import { defineMessages, FormattedMessage } from "react-intl";
// @ts-ignore
import React, {Component} from "react";
import { IPaymentErrorsProps } from "../types";

const translations = defineMessages({
    "INCORRECT_NUMBER": {
        id: "qidigo.billing.payment_errors.incorrect_number",
        defaultMessage:  "Le numéro n'est pas un numéro de carte de crédit valide"
    },
    "INVALID_EXPIRY_DATE": {
        id: "qidigo.billing.payment_errors.invalid_expiry_month",
        defaultMessage:  "La date d'expiration est invalide"
    },
    "EXPIRED_CARD": {
        id: "qidigo.billing.payment_errors.expired_card",
        defaultMessage:  "La carte est expirée"
    },
    "INCORRECT_CVC": {
        id: "qidigo.billing.payment_errors.incorrect_cvc",
        defaultMessage:  "Le code CVV de la carte est invalide"
    },
    "INCORRECT_ZIP": {
        id: "qidigo.billing.payment_errors.incorrect_zip",
        defaultMessage:  "Le code postal a été refusé par votre institution bancaire"
    },
    "CARD_DECLINE_UNKNOWN_REASON": {
        id: "qidigo.billing.payment_errors.card_declined",
        defaultMessage:  "La carte a été refusée. Aucun montant n'a été prélevé sur votre carte et aucune inscription n'a été effectuée. Veuillez contacter votre institution bancaire et réessayer plus tard."
    },
    "PROCESSING_ERROR": {
        id: "qidigo.billing.payment_errors.processing_error",
        defaultMessage:  "Une erreur est survenue lors du traitement de la carte."
    },
    "UNEXPECTED_ERROR": {
        id: "qidigo.billing.payment_errors.unexpected_error",
        defaultMessage:  "Une erreur inattendue est survenue."
    },
    "RATE_LIMIT_EXCEEDED": {
        id: "qidigo.billing.payment_errors.rate_limit",
        defaultMessage:  "Une erreur est survenue dû a une demande trop rapide vers l'API. Veuillez nous contacter si vous rencontrez plusieurs fois cette erreurs."
    },
    "INVOICE_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.invoice_not_found",
        defaultMessage:  "Aucune facture à ce numéro"
    },
    "PAYMENT_IS_FAILED": {
        id: "qidigo.billing.payment_errors.payment_failed",
        defaultMessage:  "Le paiement a échoué. Aucun montant n'a été prélevé sur votre carte et aucune inscription n'a été effectuée. Veuillez contacter votre institution bancaire et réessayer plus tard."
    },
    "PAYMENT_METHOD_IS_INVALID": {
        id: "qidigo.billing.payment_errors.payment_method_is_invalid",
        defaultMessage:  "Veuillez sélectionner une méthode de paiement valide"
    },
    "CREDIT_IS_INVALID": {
        id: "qidigo.billing.payment_errors.credit_is_invalid",
        defaultMessage:  "Le montant utilisé pour le crédit est invalide",
    },
    "APPLYING_CREDIT_FAILED": {
        id: "qidigo.billing.payment_errors.applying_credit_failed",
        defaultMessage:  "L'application du crédit a échoué. Veuillez essayer sans crédit ou veuillez contacter notre service à la clientèle si cette erreur persiste",
    },
    "INVALID_VERSION": {
        id: "qidigo.billing.payment_errors.invalid_version",
        defaultMessage:  "La demande a déjà été soumise. Veuillez valider votre facture dans votre profil.",
    },
    "INVALID_PARAMETERS": {
        id: "qidigo.billing.payment_errors.invalid_parameters",
        defaultMessage:  "Une erreur inattendue est survenue",
    },
    "PAYMENT_METHOD_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.payment_method_not_found",
        defaultMessage:  "La méthode de paiement fournie est introuvable",
    },
    "CREATOR_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.creator_not_found",
        defaultMessage:  "Le créateur fourni est introuvable",
    },
    "INSTALLMENT_IS_INVALID": {
        id: "qidigo.billing.payment_errors.installment_is_invalid",
        defaultMessage:  "Le plan de paiement est invalide",
    },
    "TOO_SMALL_AMOUNT": {
        id: "qidigo.billing.payment_errors.too_small_amount",
        defaultMessage:  "Le montant du paiement est insuffisant",
    },
    "CARD_OR_TOKEN_IS_REQUIRED": {
        id: "qidigo.billing.payment_errors.card_or_token_is_required",
        defaultMessage:  "La carte ou le jeton sont obligatoires afin d'utiliser cette méthode de paiement",
    },
    "TOKEN CREATION FAILED": {
        id: "qidigo.billing.payment_errors.token_creation_failed",
        defaultMessage:  "La creation du jeton a échoué",
    },
    "TOKEN_ALREADY_USED": {
        id: "qidigo.billing.payment_errors.token_already_used",
        defaultMessage:  "Le jeton est déjà utilisé",
    },
    "QIDIGO_ORGANIZATION_ID_HEADER_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.qidigo_organization_id_header_not_found",
        defaultMessage:  "Vous devez fournir le id de l'organisation dans l'entête de la requête",
    },
    "QIDIGO_ORGANIZATION_ID_HEADER_MUST_BE_A_VALID_UUID": {
        id: "qidigo.billing.payment_errors.qidigo_organization_id_header_must_be_a_valide_uuid",
        defaultMessage:  "L'entête fournie n'est pas un uuid valide",
    },
    "ISSUE_FROM_PAYSAFE": {
        id: "qidigo.billing.payment_errors.issue_from_paysafe",
        defaultMessage:  "Une erreur provenant de la plateforme de paiement est survenue.",
    },
    "DIRECT_DEBIT_DECLINE_UNKNOWN_REASON": {
        id: "qidigo.billing.payment_errors.direct_debit_decline_unknown_reason",
        defaultMessage:  "Une erreur inattendue est survenue",
    },
    "DIRECT_DEBIT_RISKY_TRANSACTION": {
        id: "qidigo.billing.payment_errors.direct_debit_risky_transaction",
        defaultMessage:  "La transaction débit direct semble risquée",
    },
    "BANK_ACCOUNT_STATUS_ISSUE": {
        id: "qidigo.billing.payment_errors.bank_account_status_issue",
        defaultMessage:  "Il y a une erreur avec le status du compte de bancaire",
    },
    "INVALID_BANK_ACCOUNT_INFORMATION": {
        id: "qidigo.billing.payment_errors.invalid_bank_account_information",
        defaultMessage:  "Les informations bancaire fournies sont invalides",
    },
    "FAILED_BY_MERCHANT_SETUP": {
        id: "qidigo.billing.payment_errors.failed_by_merchant_setup",
        defaultMessage:  "Une erreur inattendue est survenue",
    },
    "AUTHENTICATION_FAILED": {
        id: "qidigo.billing.payment_errors.authentication_failed",
        defaultMessage:  "Le processus d'authentification a échouée",
    },
    "INVALID_AMOUNT": {
        id: "qidigo.billing.payment_errors.invalid_amount",
        defaultMessage:  "Montant invalide",
    },
    "INVALID_CARD_INFORMATION": {
        id: "qidigo.billing.payment_errors.invalid_card_information",
        defaultMessage:  "Les informations de la carte sont invalides",
    },
    "NOT_ENOUGH_FUND": {
        id: "qidigo.billing.payment_errors.not_enough_fund",
        defaultMessage:  "Fonds insuffisants",
    },
    "CARD_STATUS_ISSUE": {
        id: "qidigo.billing.payment_errors.card_status_issue",
        defaultMessage:  "Une erreur avec le status de la carte est survenue",
    },
    "INVALID_CARD_NUMBER": {
        id: "qidigo.billing.payment_errors.invalid_card_number",
        defaultMessage:  "Le numéro de carte est invalide",
    },
    "UNSUPPORTED_CARD": {
        id: "qidigo.billing.payment_errors.unsupported_card",
        defaultMessage:  "Le type de carte entrée n'est pas supporté par le service choisie",
    },
    "CVV_CHECK_FAILED": {
        id: "qidigo.billing.payment_errors.cvv_check_failed",
        defaultMessage:  "Échec de la validation du CVV",
    },
    "BILLING_ADDRESS_CHECK_FAILED": {
        id: "qidigo.billing.payment_errors.billing_address_check_failed",
        defaultMessage:  "Échec de la validation de l'adresse de facturation",
    },
    "PAYER_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.payer_not_found",
        defaultMessage:  "Le payeur est introuvable",
    },
    "PAYER_NOT_ALLOW_TO_ACT_ON_BEHALF_OF_COMPANY": {
        id: "qidigo.billing.payment_errors.payer_not_allow_to_act_on_behalf_of_company",
        defaultMessage:  "Le payeur n'est pas autorisé à agir au nom de la compagnie",
    },
    "QIDIGO_ORGANIZATION_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.qidigo_organization_not_found",
        defaultMessage:  "L'organisation est introuvable",
    },
    "INVALID_SPLIT_AMOUNT": {
        id: "qidigo.billing.payment_errors.invalid_split_amount",
        defaultMessage:  "La division du montant est invalide",
    },
    "CUSTOMER_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.customer_not_found",
        defaultMessage:  "Le client est introuvable",
    },
    "COMPANY_NOT_FOUND": {
        id: "qidigo.billing.payment_errors.company_not_found",
        defaultMessage:  "La compagnie est introuvable",
    },
    "LIMIT_CARD_ISSUE": {
        id: "qidigo.billing.payment_errors.limit_card_issue",
        defaultMessage:  "La carte n'a pas suffisamment de fonds afin de compléter la transaction",
    },
    "UNSUPPORTED_CURRENCY": {
        id: "qidigo.billing.payment_errors.unsupported_currency",
        defaultMessage:  "Le type de monnaie fourni n'est pas supporté par le service",
    },
    "STOLEN_CARD": {
        id: "qidigo.billing.payment_errors.stolen_card",
        defaultMessage:  "La transaction a été refusée",
    },
    "LOST_CARD": {
        id: "qidigo.billing.payment_errors.lost_card",
        defaultMessage:  "La transaction a été refusée",
    },
    "DUPLICATE_TRANSACTION": {
        id: "qidigo.billing.payment_errors.duplicate_transaction",
        defaultMessage:  "Transaction dupliquée",
    },
    "CARD_ISSUER_NOT_AVAILABLE": {
        id: "qidigo.billing.payment_errors.card_issuer_not_available",
        defaultMessage:  "Le distributeur n'est pas disponible",
    },
    "FRAUDULENT": {
        id: "qidigo.billing.payment_errors.fraudulent",
        defaultMessage:  "La transaction a été refusée",
    },
    "CART_IS_EMPTY": {
        id: "qidigo.billing.payment_errors.cart_is_empty",
        defaultMessage:  "Votre panier est vide. Vous serez redirigé vers la page de {organization} dans {seconds} seconde{seconds, plural, one {} other {s}}"
    },
    "SETTLEMENT_FAILED_BEFORE_PAYMENT_PROCESSED": {
        id: "qidigo.billing.payment_errors.settlement_failed_before_payment_processed",
        defaultMessage:  "Le paiement a échoué. Aucun montant n'a été prélevé sur votre carte et aucune inscription n'a été effectuée. Veuillez contacter l'organisme.",
    },
    "SETTLEMENT_FAILED_AFTER_PAYMENT_PROCESSED": {
        id: "qidigo.billing.payment_errors.settlement_failed_after_payment_processed",
        defaultMessage:  "Une erreur inattendue s'est produite. Le paiement a été effectué. Veuillez contacter l'organisme pour valider votre inscription."
    },
    "PAYSAFE_BAD_REQUEST": {
        id: "qidigo.billing.payment_errors.token_error",
        defaultMessage:  "Le service de paiement a refusé les informations fournies. Veuillez vous assurer que votre adresse ainsi que vos informations de paiements sont valides"
    },
    "ADJUSTMENT_REDEMPTION_LIMIT_EXCEEDED": {
        id: "qidigo.billing.payment_errors.adjustment_redemption_limit_exceeded",
        defaultMessage:  "Ce code promotionnel ne peut plus être utilisé"
    },
});

class PaymentErrors extends Component<IPaymentErrorsProps> {
    constructor(props: IPaymentErrorsProps) {
        super(props);
    }

    render() {
        return ( this.props.paymentErrors === 'CART_IS_EMPTY'
            ? <li>
                <FormattedMessage {...translations[this.props.paymentErrors]}
                                  values={{
                                      seconds: this.props.redirectionTimer,
                                      organization: this.props.organizationName
                                  }}
                />
            </li>
            : <li>
                <FormattedMessage {...translations[this.props.paymentErrors]}/>
            </li>);
    }
}

export default PaymentErrors;
