import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Fetch                 from "qidigo-fetch";
import Logger                from "qidigo-logger";
import {navigate}            from "qidigo-router";

import Loading               from "qidigo-components/loading.js";
import View                  from "@app/views/organization/contact.js";

/**
 * @extends {Component}
 */
class OrganizationController extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
		};
	}

	/**
	 */
	fetchLocal() {
		this.setState({loading: false});
	}

	componentWillMount() {
		this.fetchLocal();
	}

	render() {
		if (this.state.loading) { return <Loading />; }

		const {organization} = this.props;

		return (
			<View organization={organization} />
		);
	}
}

OrganizationController.propTypes = {
	route:  PropTypes.object,
	params: PropTypes.shape({
		orgID: PropTypes.string,
	}).isRequired,
};

export default OrganizationController;

