import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";

const messages = defineMessages({
    restrictedItemList: {id: "qidigo.activity.groups.page.batch.restricted_item_list", defaultMessage: "{product_name} pour {subscriber_name}"},
    restrictedConjunction: {id: "qidigo.activity.groups.page.batch.restricted_conjunction", defaultMessage: " et "},
    restrictedMessageForCart: {
        id: "qidigo.activity.groups.page.batch.restricted_message_for_cart",
        defaultMessage: ` {itemCount, plural,
			one {n'est plus disponible et n'a pas été ajouté au panier.}
			other {ne sont plus disponibles et n'ont pas été ajoutés au panier.}}`
    },
    restrictedMessageForWaitingList: {
        id: "qidigo.activity.groups.page.batch.restricted_message_for_waiting_list",
        defaultMessage: ` {itemCount, plural,
			one {n'est plus disponible et n'a pas été ajouté à la liste d'attente.}
			other {ne sont plus disponibles et n'ont pas été ajoutés à la liste d'attente.}}`
    },
    SUBSCRIBERS_EXCEED_MAX_REGISTRATIONS: {
        id: "qidigo.activity.groups.page.batch.SUBSCRIBERS_EXCEED_MAX_REGISTRATIONS",
        defaultMessage: `itemCount, plural, 
            one {ne peut pas être ajouté car le participant sélectionné a atteint la limite d'inscription} 
            other {ne peuvent pas être ajoutés car les participants sélectionnés ont atteint la limite d'inscription}}`
    },

});

export const makeFormattedMessage = (items) => {
    if (items.length === 0) {
        return "";
    }

    const waitingListItems = items.filter((item) => item. isWaitingList);
    const cartItems =  items.filter((item) => !item. isWaitingList && !item.exceedsMaxRegistrations);
    const restrictionsMessage : JSX.Element[] = [];

    let cartItemsWithMaxRegistrationsError = items.filter((item) => !item.isWaitingList && item.exceedsMaxRegistrations);

    function makeMessage(itemsForMessage, type) {
        if (itemsForMessage.length === 1) {

            restrictionsMessage.push(
                <>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: itemsForMessage[0].planName,
                            subscriber_name: itemsForMessage[0].subscriberName,
                        }}
                    />
                    {
                        type === 'WAITING_LIST'
                            ? <FormattedMessage {...messages.restrictedMessageForWaitingList} values={{itemCount: itemsForMessage.length}}/>
                            : <FormattedMessage {...messages.messageKey} values={{itemCount: itemsForMessage.length}}/>
                    }
                </>
            );
        }

        if (itemsForMessage.length > 1) {
            restrictionsMessage.push(
                <>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: itemsForMessage[0].planName,
                            subscriber_name: itemsForMessage[0].subscriberName,
                        }}
                    />
                    {itemsForMessage.slice(1, -1).map(item => {
                        return <>
                                {", "}
                                <FormattedMessage
                                    {...messages.restrictedItemList}
                                    values={{
                                        product_name: item.planName,
                                        subscriber_name: item.subscriberName,
                                    }}
                                />
                            </>
                        }
                    )}
                    <FormattedMessage {...messages.restrictedConjunction}/>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: itemsForMessage.slice(-1)[0].planName,
                            subscriber_name: itemsForMessage.slice(-1)[0].subscriberName,
                        }}
                    />
                    {
                        type === 'WAITING_LIST'
                            ? <FormattedMessage {...messages.restrictedMessageForWaitingList} values={{itemCount: itemsForMessage.length}}/>
                            : <FormattedMessage {...messages.restrictedMessageForCart} values={{itemCount: itemsForMessage.length}}/>
                    }
                </>
            );
        }
    }

    function makeExceedMaxRegistrationMessage(itemsForMessage) {
        if (itemsForMessage.length === 1) {
            restrictionsMessage.push(
                <>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: itemsForMessage[0].planName,
                            subscriber_name: itemsForMessage[0].subscriberName,
                        }}
                    />
                    <FormattedMessage {...messages.SUBSCRIBERS_EXCEED_MAX_REGISTRATIONS} values={{itemCount: itemsForMessage.length}}/>
                </>
            );
        }

        if (itemsForMessage.length > 1) {
            restrictionsMessage.push(
                <>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: itemsForMessage[0].planName,
                            subscriber_name: itemsForMessage[0].subscriberName,
                        }}
                    />
                    {itemsForMessage.slice(1, -1).map(item => {
                            return <>
                                {", "}
                                <FormattedMessage
                                    {...messages.restrictedItemList}
                                    values={{
                                        product_name: item.planName,
                                        subscriber_name: item.subscriberName,
                                    }}
                                />
                            </>
                        }
                    )}
                    <FormattedMessage {...messages.restrictedConjunction}/>
                    <FormattedMessage
                        {...messages.restrictedItemList}
                        values={{
                            product_name: itemsForMessage.slice(-1)[0].planName,
                            subscriber_name: itemsForMessage.slice(-1)[0].subscriberName,
                        }}
                    />
                    <FormattedMessage {...messages.SUBSCRIBERS_EXCEED_MAX_REGISTRATIONS} values={{itemCount: itemsForMessage.length}}/>
                </>
            );
        }
    }

    makeMessage(waitingListItems, 'WAITING_LIST');
    makeMessage(cartItems, 'CART');
    makeExceedMaxRegistrationMessage(cartItemsWithMaxRegistrationsError);

    return restrictionsMessage.map((restrictionMessage, index) => (
        <>
            {index > 0 && <br/>}
            {restrictionMessage}
        </>
    ))
}
