import * as React from "react";
import {Component} from "react";
import {defineMessages, FormattedMessage} from "react-intl";

// @ts-ignore
import PropTypes, {bool} from "prop-types";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Checkbox from "qidigo-form/checkbox";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
import qidigoAuth from "../../../../modules/qidigo-auth"
import {Link} from "react-router/es";


const translations = defineMessages({
    "consent_confirmation": {
        id: "qidigo.activity.pass_selection_modal.steps.consent.consent_confirmation",
        defaultMessage: "Je consens aux {termOfUseLink} et à la {privacyPolicyLink}"
    },
    "consent_title": {
        id: "qidigo.activity.pass_selection_modal.steps.consent.consent_title",
        defaultMessage: "Consentement au partage d'informations"
    },
    "accept": {
        id: "qidigo.activity.pass_selection_modal.steps.consent.consent_confirmation.next_step",
        defaultMessage: "Accepter et poursuivre"
    },
    "title":          {id: "qidigo.organization.layout.modal.consent.title", defaultMessage: "Consentement du partage de vos informations personnelles"},
    "description":    {id: "qidigo.organization.layout.modal.consent.description", defaultMessage: "En vous abonnant à {organizationName}, vous consentez à partager vos informations de profil avec cette organisation."},
    "subdescription": {id: "qidigo.organization.layout.modal.consent.subdescription", defaultMessage: "Les informations suivantes seront partagées, si disponibles\u00A0:"},
    "consent":        {id: "qidigo.organization.layout.modal.consent.consent", defaultMessage: "Je consens"},
    "decline":        {id: "qidigo.organization.layout.modal.consent.decline", defaultMessage: "Je refuse"},
    "picture":        {id: "qidigo.organization.layout.modal.consent.picture", defaultMessage: "Photo de profil"},
    "full_name":      {id: "qidigo.organization.layout.modal.consent.full_name", defaultMessage: "Prénom et nom"},
    "date_of_birth":  {id: "qidigo.organization.layout.modal.consent.date_of_birth", defaultMessage: "Date de naissance"},
    "gender":         {id: "qidigo.organization.layout.modal.consent.gender", defaultMessage: "Genre"},
    "sin":            {id: "qidigo.organization.layout.modal.consent.sin", defaultMessage: "Numéro d'assurance social"},
    "phone_numbers":  {id: "qidigo.organization.layout.modal.consent.phone_numbers", defaultMessage: "Numéros de téléphone"},
    "addresses":      {id: "qidigo.organization.layout.modal.consent.addresses", defaultMessage: "Adresses"},
    "email":          {id: "qidigo.organization.layout.modal.consent.email", defaultMessage: "Courriel"},
    "family_members": {id: "qidigo.organization.layout.modal.consent.family_members", defaultMessage: "Membres de la famille\u00A0:"},
    "terms":          {id: "qidigo.organization.layout.modal.consent.terms", defaultMessage: "Conditions d’utilisation"},
    "privacy":        {id: "qidigo.organization.layout.modal.consent.privacy", defaultMessage: "Politique de confidentialité"},
})

const userSharedInformation = [
    'picture',
    'full_name',
    'date_of_birth',
    'gender',
    'sin',
    'phone_numbers',
    'addresses',
    'email',
];

const memberSharedInformation = [
    'picture',
    'full_name',
    'date_of_birth',
    'gender',
];

interface IConsentProps {
    handleNextStep: () => void;
    handleShowConsentPage: () => void;
    organizationName: string;
    organizationId: string;
}

interface IConsentState {
    isChecked: boolean,
    hasError: boolean,
    error: string,
    contactAdded: boolean,
    loading: boolean
}

class Consent extends Component<IConsentProps, IConsentState> {

    constructor(props: IConsentProps) {
        super(props);

        this.state = {
            isChecked: false,
            hasError: false,
            error: "",
            contactAdded: false,
            loading: false,
        };
    }

    onCheck = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    handleNextStep = () => {
        if (!this.state.isChecked) {
            return;
        }

        const url = 'contacts';
        const body = {
            'user_id': Number(qidigoAuth.getUserID()),
            'organization_id': this.props.organizationId
        }

        const init = {
            'method': 'POST',
            'body': JSON.stringify(body)
        };

        qidigoFetch.fetch(url, init)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    this.handleNextStep();
                    return;
                }
                if (response.error_code) {
                    this.setState({
                        contactAdded: false,
                        loading: false,
                        error: response.error_code,
                    })

                    return;
                }

                this.props.handleNextStep();
            })
    }

    render() {
        const {formatMessage} = this.context.intl;

        return (
            <div className={"pass-selection-consent"}>
                <div className={"pass-selection-consent--text-container"}>
                    <h2 className={"pass-selection-consent--title"}>
                        <FormattedMessage {...translations['consent_title']}/>
                    </h2>
                    <p>
                        <FormattedMessage
                            {...translations['description']}
                            values={{organizationName: this.props.organizationName}}
                        />
                    </p>
                    <p><FormattedMessage {...translations['subdescription']} /></p>
                    <ul>
                        {userSharedInformation.map((key) => (
                            <li key={key}>
                                <FormattedMessage {...translations[key]} />
                            </li>
                        ))}
                        <li>
                            <FormattedMessage {...translations['family_members']} />
                            <ul>
                                {memberSharedInformation.map((key) => (
                                    <li key={key}>
                                        <FormattedMessage {...translations[key]} />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className={"pass-selection-consent--checkbox-container"}>
                    <Checkbox
                        value={this.state.isChecked}
                        onClick={this.onCheck}
                        className={"pass-selection-consent--checkbox "}
                        label={
                            <FormattedMessage {
                                ...translations["consent_confirmation"]}
                                values={{
                                    termOfUseLink: <Link to="/terms">
                                        <FormattedMessage {...translations['terms']}/>
                                    </Link>,
                                    privacyPolicyLink: <Link to="/privacy">
                                        <FormattedMessage {...translations['privacy']}/>
                                    </Link>
                            }} />
                        }
                    />
                </div>
                <Button
                    disabled={!this.state.isChecked}
                    className="pass-selection-modal--primary-button"
                    onClick={this.handleNextStep}
                >
                    <FormattedMessage {...translations['accept']}/>
                </Button>
            </div>
        );
    }
}

// @ts-ignore
Consent.contextTypes = {
    intl: PropTypes.object,
};

export default Consent;
