import React                 from "react";
import PropTypes             from "prop-types";
import OrganizationTitle     from "../organization/title";

const Title = ({activity, title, children, ...props}) =>
	<OrganizationTitle {...props}
		title={`${activity["name"]}${title ? " — " + title : ""}`}
	>
		{children}
	</OrganizationTitle>
	;

Title.propTypes = {
	title: PropTypes.string,
	organization: PropTypes.object.isRequired,
	activity: PropTypes.object.isRequired,
};

export default Title;
