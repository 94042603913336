import Fetch                 from "qidigo-fetch";
import { trackEvent } from "../../tracker";
import {sendError}           from "./router";

export const ADDING_TO_CART = "BUY:ADDING_TO_CART";
export const ADDING_TO_CART_FAILED = "BUY:ADDING_TO_CART_FAILED";
export const ADDED_TO_CART = "BUY:ADDED_TO_CART";

const adding = () => ({
	type: ADDING_TO_CART,
});

export const addBatchToCart = (items) =>
	(dispatch, getState) => {
		const {buy: {cart}} = getState();
		if (cart.adding) {
			return null;
		}

		dispatch(adding());
		return dispatch((dispatch, getState) => {
			for (const item of items) {
				trackEvent(
					'add_to_cart',
					{
						label: item.cart_item.plan_id
					}
				)
			}

			return Fetch.post("batch_cart", items)
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: ADDING_TO_CART_FAILED, error});
				})
				.then((response) => {
					dispatch({type: ADDED_TO_CART, status: response["status"]});

					const waitingListItems = [];
					const restrictedItems = [];
					const cartItems = [];
					for (const item of response.items) {
						if (item["http_status"] === 403) {
							restrictedItems.push(item)

							continue;
						}

						if (item["in_waiting_list"]) {
							waitingListItems.push(item);

							continue;
						}

						cartItems.push(item);
					}

					return {
						cart: cartItems,
						waitingList: waitingListItems,
						restrictedItems: restrictedItems,
					};
				})
			;
		});
	};
