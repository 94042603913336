import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";

const messages = defineMessages({
	next:      {id: "qidigo.pager.next",     defaultMessage: "Page suivante"},
	previous:  {id: "qidigo.pager.previous", defaultMessage: "Page précédente"},
	first:     {id: "qidigo.pager.first",    defaultMessage: "Première page"},
	last:      {id: "qidigo.pager.last",     defaultMessage: "Dernière page"},
	page:      {id: "qidigo.pager.page",     defaultMessage: "Page {page}"},
});

/**
 * Représente un bouton dans la pagination.
 */
const PagerButton = ({
	selected,
	type,
	onClick,
	label,
	title,
}) => {
	const classes = [`pager--button-${type}`];

	if (selected) {
		classes.push("is-selected");
	}

	return (
		<li className={classes.join(" ")}>
			<a href="#" onClick={onClick} title={title}>{label}</a>
		</li>
	);
};

PagerButton.propTypes = {
	type:     PropTypes.string.isRequired,
	label:    PropTypes.string.isRequired,
	title:    PropTypes.string.isRequired,
	selected: PropTypes.bool,
	onClick:  PropTypes.func.isRequired,
};

/**
 * Représente un "…" avec la forme d'un bouton
 */
const Ellipsis = () => 
	<li className="pager--ellipsis">
		<span>&#8230;</span>
	</li>
	;

/**
 * Représente l'élément de pagination.
 *
 * #### Note:
 *
 * La liste de page du `<Pager>` est indexée par un. C'est-à-dire que
 * la première page est `1` et la dernière est `numberOfPages`.
 *
 * **Le comportement est incomplet selon l'ADN Qidigo.** Pour compléter
 * le comportement, il faudra limiter le nombre de pages affichées dans
 * le widget de pagination.
 *
 */
class Pager extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.currentPage !== nextProps.currentPage ||
			this.numberOfPages !== nextProps.numberOfPages ||
			this.className !== nextProps.className
		);
	}

	next(e) {
		if (this.props.currentPage < this.props.numberOfPages) {
			const page = this.props.currentPage + 1;
			this.handleChange(e, page);
		}
	}

	previous(e) {
		if (this.props.currentPage > 1) {
			const page = this.props.currentPage - 1;
			this.handleChange(e, page);
		}
	}

	handleChange(e, i) {
		if (e) {
			e.preventDefault();
		}
		this.props.onChange(i);
	}

	// Assure qu'on ne dépasse pas la dernière page.
	componentWillUpdate(nextProps) {
		if (nextProps.numberOfPages < nextProps.currentPage && nextProps.numberOfPages > 0) {
			this.props.onChange(nextProps.numberOfPages);

			return;
		}
		if (nextProps.currentPage < 1) {
			this.props.onChange(1);
			
			return;
		}
	}

	render() {
		const {
			className,
			currentPage,
			numberOfPages,
		} = this.props;

		if (numberOfPages < 1) {
			return <div />;
		}

		const {formatMessage} = this.context.intl;
		const classes = ["pager", className];
		const atFirstPage = currentPage === 1;
		const atLastPage  = currentPage === numberOfPages;
		
		// Nombre de page visible de chaque côté du la page courrante
		const visibleRange = 7;
		// Si la page courrante est plus petite que la visibleRange on commence
		// l'affichage des pages à la première
		const rangeStart  = currentPage - Math.ceil(visibleRange/2) + 1 < 1 ? 1: currentPage - Math.ceil(visibleRange/2) + 1;
		const rangeEnd =  rangeStart + visibleRange - 1 > numberOfPages ? numberOfPages : rangeStart + visibleRange - 1;

		const buttons = [];
		
		if (rangeStart > 1) {
			buttons.push(<Ellipsis key="ellipseStart" />);
		}
		
		for (let i=rangeStart; i <= rangeEnd; i=i+1) {
			buttons.push(
				<PagerButton
					key={i}
					type="page"
					label={`${i}`}
					title={formatMessage(messages.page, {page: i})}
					onClick={(e)=> this.handleChange(e, i)}
					selected={i===currentPage}
				/>
			);
		}

		if (rangeEnd < numberOfPages) {
			buttons.push(<Ellipsis key="ellipseEnd" />);
		}

		let prevButtons = null;
		if (!atFirstPage) {
			prevButtons = [
				<PagerButton
					key="first"
					type="first"
					label="<<"
					title={formatMessage(messages.first)}
					onClick={(e)=> this.handleChange(e, 1)}
					selected={atFirstPage}
				/>,
				<PagerButton
					key="previous"
					type="previous"
					label="<"
					title={formatMessage(messages.previous)}
					onClick={(e)=> this.previous(e)}
					selected={atFirstPage}
				/>
			];
		}
		let nextButtons = null;
		if (!atLastPage) {
			nextButtons = [
				<PagerButton
					key="next"
					type="next"
					label=">"
					title={formatMessage(messages.next)}
					onClick={(e)=> this.next(e)}
					selected={atLastPage}
				/>,
				<PagerButton
					key="last"
					type="last"
					label=">>"
					title={formatMessage(messages.last)}
					onClick={(e)=> this.handleChange(e, numberOfPages)}
					selected={atLastPage}
				/>
			];
		}

		return (
			<nav className={classes.join(" ")}>
				<ul>
					{prevButtons}
					{buttons}
					{nextButtons}
				</ul>
			</nav>
		);
	}
}

Pager.propTypes = {
	className:     PropTypes.string,
	onChange:      PropTypes.func.isRequired,
	numberOfPages: PropTypes.number.isRequired,
	currentPage:   PropTypes.number.isRequired,
};

Pager.contextTypes = {
	intl: PropTypes.object,
};

export default Pager;
