/* globals Intl,require */

// Polyfills pour les navigateurs un peu plus vieux.

// Certaines fonctions es2015
// https://github.com/facebook/react/issues/8379#issuecomment-264934168
// Importées dans la config.
// import "babel-polyfill";
import {polyfill as promisePolyfill} from "es6-promise";
import "core-js";

// API Intl.
if (!window.Intl) {
	window.Intl = require("intl");
	require("intl/locale-data/jsonp/fr.js");
}

// Installe les promises ES6 en polyfill.
promisePolyfill();

// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Date/now#Polyfill
if (!Date.now) {
	Date.now = function() { return new Date().getTime(); };
}

// Avoid `console` errors in browsers that lack a console.
// https://github.com/h5bp/html5-boilerplate/blob/a2356c1cbfc560c2b140d4ab507c2a4fdc9f58f0/dist/js/plugins.js
(function() {
	const noop = function() {};
	const methods = [
		"assert", "clear", "count", "debug", "dir", "dirxml", "error",
		"exception", "group", "groupCollapsed", "groupEnd", "info", "log",
		"markTimeline", "profile", "profileEnd", "table", "time", "timeEnd",
		"timeline", "timelineEnd", "timeStamp", "trace", "warn"
	];
	var console = window.console = window.console || {};

	for (const method of methods) {
		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();
