import React from "react";
import BackToLastLocation from "@app/components/back-to-last-location.js";
import {Link} from "react-router/es";
import PropTypes from "prop-types";
import {
    defineMessages,
    FormattedMessage,
} from "react-intl";
import {Button} from "qidigo-form";
import Helmet from "react-helmet";
import EmptyList from "@app/components/empty_list";
import Page from "../../../components/page";
import ButtonsBar from "../../../../../modules/qidigo-form/buttons_bar";
import Checkbox from "../../../../../modules/qidigo-form/checkbox";

const translations = defineMessages({
    "page.title": {id: "qidigo.cart.form.formList.title", defaultMessage: "Informations supplémentaires"},

    "label.form": {id: "qidigo.cart.form.formList.label.form", defaultMessage: "Formulaire"},
    "label.registered_user": {
        id: "qidigo.cart.form.formList.label.registered_user",
        defaultMessage: "Personne inscrite"
    },
    "label.status": {id: "qidigo.cart.form.formList.label.status", defaultMessage: "État"},

    "action.label.answer": {id: "qidigo.cart.form.formList.button.label.answer", defaultMessage: "Remplir"},
    "action.label.next_step": {
        id: "qidigo.cart.form.formList.button.label.next_step",
        defaultMessage: "Étape suivante"
    },

    "row.label.to_complete": {id: "qidigo.cart.form.formList.row.label.to_complete", defaultMessage: "À compléter"},
    "row.label.complete": {id: "qidigo.cart.form.formList.row.label.complete", defaultMessage: "Complet"},

    "warning.default": {
        id: "qidigo.cart.form.formList.warning.default",
        defaultMessage: "Les articles sélectionnés exigent que vous fournissiez des informations supplémentaires. Afin de valider votre inscription, assurez-vous de compléter chaque formulaire disponible sur cette page."
    },
    "warning.form_validation": {
        id: "qidigo.cart.form.formList.warning.form_validation",
        defaultMessage: "Je déclare que les informations présentes dans les formulaires ci-haut sont exactes et à jour."
    },
    "warning.forms_to_complete": {
        id: "qidigo.cart.form.formList.warning.forms_to_complete",
        defaultMessage: "Vous devez compléter tous les formulaires afin de poursuivre."
    },

});

const FormStatus = (props) => {
    const {status} = props;
    if (status == true) {
        return <li className="cart-formList--status-complete cart-formList--row-info">
            <FormattedMessage {...translations["row.label.complete"]} />
        </li>
    }

    return <li className="cart-formList--status-to-complete cart-formList--row-info">
        <FormattedMessage
            className="cart-formList--status-to-complete" {...translations["row.label.to_complete"]} />
    </li>
};

FormStatus.propTypes = {
    status: PropTypes.bool.isRequired,
};

const FormColumnsLabels = () => {
    return (
        <li className={"cart-formList--labels"}>
            <ul>
                <li className="cart-formList--row-info cart-formList--labels">
                    <FormattedMessage {...translations["label.form"]}/>
                </li>
                <li className="cart-formList--row-info cart-formList--labels">
                    <FormattedMessage {...translations["label.registered_user"]}/>
                </li>
                <li className="cart-formList--row-info cart-formList--labels">
                    <FormattedMessage{...translations["label.status"]}/>
                </li>
                <li className="cart-formList--row-info cart-formList--labels">
                </li>
            </ul>
        </li>
    )
};

const FormRow = (props) => {
    const {
        form,
    } = props;

    const slug = window.location.pathname.split('/', 3)[2];

    return (
        <li>
            <ul>
                <li className="cart-formList--row-info">
                    <Link
                        title={...translations["action.label.answer"]}
                        to={`/u/${slug}/pending-order/form/${form['form_user_id']}`}>{form["name"]}
                    </Link>
                </li>
                <li className="cart-formList--row-info">
                    {form["subscriber"]["full_name"]}
                </li>
                <FormStatus status={form["is_complete"]}/>
                <li className="cart-formList--row-info">
                    <Button
                        link={`/u/${slug}/pending-order/form/${form['form_user_id']}`}
                        className="cart-formList--answer-button btn">
                        <FormattedMessage {...translations["action.label.answer"]} />
                    </>
                </li>
            </ul>
        </li>
    );
};

FormRow.propTypes = {
    form: PropTypes.object.isRequired,
    handleAnswerForm: PropTypes.func,
    handleCheckout: PropTypes.func,
};

const FormWarnings = (props) => {
    const {
        forms
    } = props;

    let isOneFormNotCompleted;

    forms.forEach(form => {
        if (form["is_complete"] == false) {
            isOneFormNotCompleted = true;
        }
    });

    if (isOneFormNotCompleted) {
        return <div>
            <DefaultFormWarnings/>
            <IncompleteFormsWarning/>
        </div>
    }

    return <DefaultFormWarnings/>
};

FormWarnings.propTypes = {
    forms: PropTypes.array.isRequired,
};

const DefaultFormWarnings = () => {
    return <p className="box-message">
        <FormattedMessage {...translations["warning.default"]} />
    </p>
};

const IncompleteFormsWarning = () => {
    return <p className="_box_error">
        <FormattedMessage {...translations["warning.forms_to_complete"]} />
    </p>
};

const FormValidationBox = (props) => {
    const {
        userValidatedForms,
        handleFormValidation,
        formatMessage
    } = props;

    return (
        <div className="cart-formList--validation-box">
            <div className="box-normal">
                <Checkbox
                    value={userValidatedForms}
                    onChange={(e, value) => handleFormValidation(e, value)}
                    label={formatMessage(translations['warning.form_validation'])}
                />
            </div>
        </div>
    )
}

FormValidationBox.propTypes = {
    userValidatedForms: PropTypes.bool.isRequired,
    handleFormValidation: PropTypes.func.isRequired
};

const FormListView = (props, context) => {
    const {
        forms,
        userValidatedForms,
        allFormsAnswered,
        handleAnswerForm,
        handleCheckout,
        handleFormValidation,
    } = props;

    const {formatMessage} = context.intl;

    return (
        <Page>
            <div className="cart-formList--header">
                <BackToLastLocation/>
            </div>
            <div className="cart-formList">
                <Helmet
                    title={formatMessage(translations["page.title"])}
                />
                <h2>
                    <FormattedMessage {...translations["page.title"]} />
                </h2>
                <FormWarnings forms={forms}/>
                <EmptyList>
                    <FormColumnsLabels/>
                    {forms.map((form, i) => <FormRow form=form={form} handleAnswerForm={handleAnswerForm} key={i}/>)}
                </EmptyList>
                <FormValidationBox
                    userValidatedForms={userValidatedForms}
                    handleFormValidation={handleFormValidation}
                    formatMessage={formatMessage}
                />
                <ButtonsBar
                    className="cart-formList--next-step-button"
                    disabled={!userValidatedForms || !allFormsAnswered}
                    onSubmit={handleCheckout}
                    submitLabel={formatMessage({...translations["action.label.next_step"]})}
                />
            </div>
        </Page>
    );
};

FormListView.propTypes = {
    forms: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleAnswerForm: PropTypes.func.isRequired,
    handleCheckout: PropTypes.func.isRequired,
    handleFormValidation: PropTypes.func.isRequired,
    userValidatedForms: PropTypes.bool.isRequired,
    allFormsAnswered: PropTypes.bool.isRequired
};

FormListView.defaultProps = {};

FormListView.contextTypes = {
    intl: PropTypes.object,
};

export default FormListView;
