import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "@app/lib/redux";
import moment from "moment";
import uniqBy from "lodash/uniqBy";
import Fetch from "qidigo-fetch";
import Logger from "qidigo-logger";
import {navigate} from "qidigo-router";
import {path} from "@app/lib/routes";
import scroll from "@app/lib/scroll";
import WithEditor from "@app/components/with_editor";
import {queryStringSynchronized} from "qidigo-querystring-state";
import qidigoAuth from "../../modules/qidigo-auth"
import * as Sentry from '@sentry/react'
import {
    addToCart,
} from "@app/store/actions/buy";
import {
    openCart,
    closeCart,
} from "@app/store/actions/cart";

import View from "@app/views/activity/PassesView";
import BackLocation          from "qidigo-router/back_location";

const PARAMS = [
    "filtered",
    "filteredActivity",
    "filteredMember",
    "filteredSupervisor",
    "filteredPassPlan"

];

const getSoonest = (sessions) => {
    const now = moment();
    let soonest = now;
    sessions.map((sess) => {
        const start = moment(sess.start);
        if (start.diff(now) >= 0 && (soonest === now || start.diff(soonest) < 0)) {
            soonest = start;
        }
    });

    return soonest.toDate();
};

/**
 * Contrôleur partagé...
 *
 * @extends {Component}
 */
class PassesController extends Component {
    constructor() {
        super();
        this.state = {
            sessions: null,
            selected: null,
            selectedMember: null,
            passes: null,
            reservations: null,
            members: [],
            membersForFilter: [],
            usePassErrors: false,
            adding: false,
            activities: null,
            currentView: null,
            showModal: false,
            supervisors: [],
            userOwnedPasses: null,
            passesForFilter: [],
            organizationPassOffer: [],
            offer: null,
            showReservationConfirmation: false,
            goToSubscriptionStep: false,
            showCalendar: true,
            previousSessionSelected: null,
            date: null,
            soonestSessionDate: null,
            isLoadingOffer: true
        };
    }

    autoCatch(promise, fn = null) {
        // Enchaîne la promise...
        return promise
            .then((response) => {
                return response;
            })
            // Et le *catching* par défaut.
            .catch(error => {
                Sentry.addBreadcrumb({
                    category: 'fetch',
                    level: Sentry.Severity.Debug,
                    message: error.message
                })
                Logger.catcher(error)
            })
            ;
    }

    handlePopstate() {
        window.removeEventListener('popstate', this.handlePopstate);
        document.body.classList.remove('scroll-disabled');
    };

    componentWillMount() {
        BackLocation.saveLastLocation();
    }

    async componentDidMount() {
        this.fetchSchedule();
        await this.fetchUserPassIncludingPassesInCart();
        await this.fetchUserMembers();
        await this.fetchOrganizationOfferedPasses();
        window.addEventListener('popstate', this.handlePopstate);

        const storedSelectedSession = this.props.location.state
            ? this.props.location.state.session
            : JSON.parse(localStorage.getItem('lastSelectedSession'));

        if (storedSelectedSession) {
            this.setState({
                selected: storedSelectedSession,
                goToSubscriptionStep: true
            })

            localStorage.removeItem('lastSelectedSession');
        }

        this.props.setQueryStringDefaults({
            filtered: "",
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        // User logged-in or logged out.
        if (nextContext.loggedUser !== this.context.loggedUser) {
            this.fetchSchedule(true);
            this.fetchUserPassIncludingPassesInCart();
            this.fetchUserMembers();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.sessions) {
            // Using supplied sessions...
        } else if (this.props.activity) {
            if (prevProps.activity.id !== this.props.activity.id) {
                this.fetchSchedule();
            }
        } else if (this.props.organization) {
            if (prevProps.organization.id !== this.props.organization.id) {
                this.fetchSchedule();
            }
        }

        if (this.isFilterChanged(prevProps)) {
            this.fetchSchedule();
        }

        if (!moment(prevState.date).isSame(this.state.date, "day") && this.state.date !== null) {
            this.fetchSchedule();
        }

        if (this.state.selectedMember && prevState.selectedMember !== this.state.selectedMember) {
            this.fetchPasses();
        }
    }

    isFilterChanged(previousProps) {
        return previousProps.filteredActivity !== this.props.filteredActivity ||
            previousProps.filteredMember !== this.props.filteredMember ||
            previousProps.filteredSupervisor !== this.props.filteredSupervisor ||
            previousProps.filteredPassPlan !== this.props.filteredPassPlan;
    }

    getStartDate() {
        const date = moment(this.state.date);

        if (this.getOffset() === "month") {
            date.date(1);
        }

        return date.format("YYYY-MM-DD");
    }

    getOffset() {
        const view = this.getView();
        const offset = view === "month" ? "month" : "week";

        return offset;
    }

    /**
     * Donne l'URL à utiliser pour récupérer les sessions pour le
     * sujet visé.
     */
    getRessourceURL() {
        const URLS = [];
        const params = [];

        const offset = this.getOffset();
        const date = this.getStartDate();

        if (this.state.date) {
            params.push(`start=${date}`);
        }

        params.push(`offset=${offset}`);
        const {organization} = this.props;
        const organizationID = organization["id"];

        if (this.getSubject() === "activity") {
            const {activityID} = this.props.params;
            params.push(`activity-id=${activityID}`);
        }

        if (this.props.filteredActivity) {
            params.push(`activity-id=${this.props.filteredActivity}`);
        }

        if (this.props.filteredMember) {
            params.push(`member-id=${this.props.filteredMember}`);
        }

        if (this.props.filteredSupervisor) {
            params.push(`supervisor-id=${this.props.filteredSupervisor}`);
        }

        if (this.props.filteredPassPlan) {
            params.push(`plan-id=${this.props.filteredPassPlan}`);
        }

        if (this.props.group) {
            params.push(`group-id=${this.props.group.id}`);
        }

        URLS.push(`organizations/${organizationID}/sessions?${params.join("&")}`);

        return URLS;
    }

    fetchUserPassIncludingPassesInCart = async () => {

        const userConnected = await this.isUserConnected();
        const {organization} = this.props;
        const organizationId = organization['id'];
        if (!userConnected) {
            return;
        }

        if (this.state.userOwnedPasses !== null) {
            return;
        }

        let passes = [];
        let passesForFilter = [];
        let alreadyAddedPassPlanId = [];
        const response = Fetch.get(`users/${qidigoAuth.getUserID()}/passes?include_cart=true&family=true`)
            .then((result) => {
                result.passes.filter((pass) => pass.organization_id === organizationId).forEach(pass => {
                    if (!alreadyAddedPassPlanId.includes(pass.plan_id)) {
                        alreadyAddedPassPlanId.push(pass.plan_id);
                        passesForFilter = passesForFilter.concat({
                            id: pass.plan_id,
                            name: pass.name
                        })
                    }

                    passes.push({
                        id: pass.id,
                        plan_id: pass.plan_id,
                        member: pass.user_id,
                        name: pass.name,
                        initial_quantity: pass.initial_quantity,
                        remaining_quantity: pass.remaining_quantity,
                        groups: pass.groups
                    })

                });
                this.setState({userOwnedPasses: passes, passesForFilter: passesForFilter});
            })
    }

    async fetchOrganizationOfferedPasses() {
        let passes = [];
        const {organization} = this.props;

        await Fetch.get(`organizations/${organization.future_id ? organization.future_id : organization.id}/offered-passes`).then((offeredPasses) => {
            offeredPasses.forEach(pass => {
                passes = passes.concat({
                    id: pass.old_id,
                    name: pass.name,
                })
            });
            this.setState({organizationPassOffer: passes});
        })
    }

    async isUserConnected() {
        return await qidigoAuth.userLoggedIn();
    }

    fetchUserMembers = async () => {
        const isUserConnected = await this.isUserConnected();
        if (!isUserConnected) {
            return;
        }

        const currentUser = qidigoAuth._currentUser;
        let membersReturned = [{
            id: currentUser.id,
            name: currentUser.full_name
        }];

        Fetch.get(`users/${qidigoAuth.getUserID()}/family_members`).then((results) => {
            results.forEach(member => {
                membersReturned = membersReturned.concat({
                    id: member.id,
                    name: member.full_name
                })
            });
            this.setState({membersForFilter: this.sortArrayByName(membersReturned)});
        })
    }

    sortArrayByName(array) {
        return array.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore case
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    fetchSchedule(force) {
        // ...
        // Technical debt...
        // Une fois reduxifié ce sera pu un problème, mais *parfois*, ce controller existe
        // avant d'avoir le loggedUser? On enverrait donc une autre paire de requête.
        if (!force && !this.context.loggedUser && this.context.loggedUser !== false) {
            return;
        }

        if (this.props.sessions) {
            if (initialLoad) {
                this.setState({date: getSoonest(this.props.sessions)});
            }

            return;
        }

        // Conserve la date pour le moment recherché
        const date = this.getStartDate();
        const urls = this.getRessourceURL();
        const loadKey = urls.join(";");

        // On charge déjà ces infos?
        if (loadKey === this.state.loading) {
            return;
        }

        // Enregistre l'état de chargement.
        this.setState({loading: loadKey});
        this.autoCatch(Promise.all(
            urls.map((url) => Fetch.get(url))
        ))
            .then((allResults) => {
                // Données pour une autre plage que celle attendue?
                if (date !== this.getStartDate()) {
                    // Ne rien faire.
                    return [];
                }

                let sessions = [];
                let activities = [];
                let supervisors = [];

                allResults.map((results) => {
                    results.sessions.forEach(session => {

                        if (session.description === '<p><br></p>') {
                            session.description = session.group.description;

                            if (session.description === '<p><br></p>') {
                                session.description = session.activity.description;
                            }
                        }

                        sessions = sessions.concat({
                            id: session.id,
                            allDay: session.is_all_day,
                            title: session.name,
                            start: moment(session.start_date).toDate(),
                            end: moment(session.end_date).toDate(),
                            session: {
                                activity: session.activity,
                                description: session.description,
                                end_date: session.end_date,
                                group: {
                                    activity_id: session.group.activity_id,
                                    description: session.group.description,
                                    id: session.group.id,
                                    is_available_or_in_the_future: session.group.is_available_or_in_the_future,
                                    is_available_right_now: session.group.is_available_right_now,
                                    is_from_purchased_pass: session.group.is_from_purchased_pass,
                                    name: session.group.name,
                                    restriction_age_max: session.group.restriction_age_max,
                                    restriction_age_min: session.group.restriction_age_min,
                                    restrictions_gender: session.group.restrictions_gender,
                                    type_status_id: session.group.type_status_id,
                                    upcoming_registration_date: session.group.upcoming_registration_date,
                                    validate_age_on: session.group.validate_age_on
                                },
                                group_id: session.group_id,
                                id: session.id,
                                is_all_day: session.is_all_day,
                                location: session.location,
                                name: session.name,
                                nbr_places: session.nbr_places,
                                nbr_places_remaining: session.nbr_places_remaining,
                                start_date: session.start_date,
                                subscription_time_window: session.subscription_time_window,
                                supervisors: session.supervisors

                            },
                            isPossiblyAvailableSoon: session.group.is_available_or_in_the_future,
                            isAvailable: session.group.is_available_right_now,
                            upComingRegistrationDate: session.group.upcoming_registration_date,
                            subscriptionTimeWindow: session.subscription_time_window,
                            is_from_purchased_pass: session.is_from_purchased_pass
                        });

                        session.supervisors.forEach(supervisor => {
                            supervisors = supervisors.concat({
                                id: supervisor.id,
                                name: supervisor.full_name
                            });
                        });
                    });

                    this.setState({
                        showCalendar: (results['has_upcoming_sessions_to_show']
                            && !this.props.filteredActivity
                            && !this.props.filteredGroup)
                            || this.getSubject() === 'organization'
                    })

                    if (results["activities"]) {
                        activities = activities.concat(results["activities"])
                    }

                    return results;
                });

                sessions = uniqBy(sessions, (obj) => obj["id"]);
                supervisors = uniqBy(supervisors, (obj) => obj["id"]);

                this.setState({
                    supervisors: this.sortArrayByName(supervisors),
                    loading: false,
                    sessions: sessions,
                    soonestSessionDate: getSoonest(sessions)
                });

                if (activities && activities.length > 0) {
                    this.setState({activities});
                }

                return allResults;
            })
        ;
    }

    makeMembersFromBundled(fetchedMembers) {
        let members = [];
        for (const [key, value] of Object.entries(fetchedMembers)) {
            let age = moment().diff(value.birthday, 'years');
            let age_type = 'years';

            if (age < 2) {
                age = moment().diff(value.birthday, 'months');
                age_type = 'months'
            }

            members.push({
                id: parseInt(key),
                name: value.full_name,
                age: age,
                age_type: age_type,
                gender: value.gender,
                birthday: value.birthday
            })
        }
        return this.sortArrayByName(members);
    }


    async fetchOffers() {

        if (!this.isUserConnected()) {
            return;
        }

        const {selected} = this.state;
        const id = selected["group_id"];
        this.setState({isLoadingOffer: true})

        await this.autoCatch(Fetch.get(`groups/${id}/offers?sessionId=${this.state.selected['id']}`))
            .then((response) => {
                const bundled = response["_bundled"] || {};
                const members = bundled["family_members"] || {};
                const membersFromBundled = this.makeMembersFromBundled(members);

                this.setState({
                    offer: {
                        offers: response.offers,
                        members: membersFromBundled,
                    },
                    isLoadingOffer: false,
                    members: membersFromBundled
                })
            });
    }

    fetchPasses() {
        const {selected} = this.state;
        const sessionID = selected["id"];
        const groupID = selected["group_id"];
        const memberID = this.state.selectedMember["id"];

        this.setState({passes: null, reservations: null});
        const passesPromise = this.autoCatch(Fetch.get(`users/${memberID}/passes?group=${groupID}&include_cart=true`))
            .then((response) => {
                return response.passes || [];
            });
        const reservationsPromise = this.autoCatch(
            Fetch.get(`users/${memberID}/reservations?session=${sessionID}&include_cart=true`))
            .then((response) => {
                return response.reservations || [];
            });

        // Attend les deux promises...
        Promise.all([passesPromise, reservationsPromise]).then((values) => {
            const passes = values[0];
            const reservations = values[1];
            this.setState({passes, reservations});
        });
    }

    handleNavigate(date, view) {
        if (view && view !== this.getView()) {
            const {params} = this.props;
            let type = params["groupID"];
            if (!type) {
                type = params["activityID"] ? "activity" : "organization";
                navigate(path(`${type}.${view === "month" ? "calendar" : "schedule"}`, params));
            }
        }

        if (this.getOffset() === 'week') {
            const previousMonday = moment(date).startOf('week');
            date = previousMonday.toDate();
        }

        const isSmallScreen = window.matchMedia('(max-width: 360px)').matches;

        this.setState({date: date, view: isSmallScreen ? "agenda" : view});
    }

    handleSelection(event, e) {
        // FIXME : hack pour la transition vers redux.
        // L'ancienne méthode avait un object `session` dans le event.
        let {session} = event;
        // La nouvelle méthode utilise directement la session
        if (!session) {
            session = event;
        }

        if (new Date(session.start) < new Date()) {
            return;
        }

        if (!this.state.selected) {
            this.props.dispatch(closeCart());
        }

        this.state.selected = session

        // adding à false parce que c'est impossible (pour l'instant) de stopper le changement de sélection
        // Nécessite un ajout dans react-big-calendar.
        this.setState({
            selected: this.state.selected,
            selectedMember: null,
            passes: null,
            reservations: null,
            adding: false
        });

        document.body.classList.add('scroll-disabled');
    }

    handleSelectMember(event, id) {
        const member = this.state.members[id];
        this.setState({selectedMember: member});
        this.fetchPasses();
    }

    handleAddToCart(planId, subscriberId) {
        const {dispatch} = this.props;
        const session = this.state.selected;

        dispatch(addToCart({
            cart_item: {
                quantity: 1,
                subscriber_user_id: subscriberId,
                plan_id: planId,
            },
            // Ajoute la réservation en même temps.
            session_id: session.id,
        }))
            .then(() => dispatch(openCart()))
            .catch((err) => {
                this.fetchPasses();
            })
        ;
    }

    handleUsePass(subscriberId, userPass) {
        const session = this.state.selected;
        const subscriberID = subscriberId

        this.setState({adding: true});
        const item = {
            session: session["id"],
        };
        this.autoCatch(Fetch.post(`users/${subscriberID}/reservations`, item))
            .then((response) => {
                if (response && (response["status"] === "OK" || response['success'])) {
                    if (userPass.isFromCart) {
                        this.props.dispatch(openCart());
                        this.handleCloseModal();
                    } else {
                        this.setState({
                            showReservationConfirmation: true
                        })
                    }
                } else {
                    this.setState({
                        adding: false,
                        usePassError: response.code
                    });
                }

                return response;
            })
            .catch((response) => {
                this.setState({adding: false});
                this.setState({usePassError: response});
            })
        ;
    }

    handleFilterActivity(e, activity) {
        this.props.setQueryStringState({filteredActivity: activity});
    }

    handleFilterMember(e, member) {
        this.props.setQueryStringState({filteredMember: member});
    }

    handleFilterSupervisor(e, supervisor) {
        this.props.setQueryStringState({filteredSupervisor: supervisor});
    }

    handleFilterPasses(e, pass) {
        this.props.setQueryStringState({filteredPassPlan: pass});
    }

    getView() {
        if (this.state.currentView !== null) {
            return this.state.currentView;
        }

        const {routes} = this.props;
        const newView = routes[routes.length - 1]["path"] === "calendar" ? "agenda" : "week";

        this.setCurrentView(newView);

        return newView;
    }

    setCurrentView(newView) {
        const isSmallScreen = window.matchMedia('(max-width: 360px)').matches;

        this.setState({
            currentView: isSmallScreen ? 'agenda' : newView
        })
    }

    /**
     * Pour quel type de ressource demande-t-on une liste de passes?
     */
    getSubject() {
        if (this.props.group) {
            return "group";
        }

        if (this.props.activity) {
            return "activity";
        }

        return "organization";
    }

    /**
     * Scroll to a node, by the name of the ref to the node.
     * Handles gracefully missing a name.
     */
    scrollTo(node) {
        if (!node || !this[node]) {
            return false;
        }
        scroll(this[node]);

        return true;
    }

    handleCloseModal() {
        document.body.classList.remove('scroll-disabled');

        this.setState({
            selected: null,
            offer: null,
            userOwnedPasses: null,
            showReservationConfirmation: false,
            previousSessionSelected: this.state.selected,
            goToSubscriptionStep: false,
        });
    }

    handleCloseModalWithSessionReload = () => {
        this.handleCloseModal();
        this.setState({sessions: null});
        this.fetchSchedule();
        this.fetchUserPassIncludingPassesInCart();
    }

    makePassesFilter() {
        let ownedOrganizationPasses = [];
        let {passesForFilter, organizationPassOffer} = this.state;
        passesForFilter.forEach((value) => {
            ownedOrganizationPasses.push(value);
            organizationPassOffer = organizationPassOffer.filter((plan) => value.id !== plan.id)
        });

        return {
            owned_passes: ownedOrganizationPasses,
            offered_passes: organizationPassOffer
        }
    }

    reopenModal() {
        this.setState({
            selected: this.state.previousSessionSelected,
            goToSubscriptionStep: true,
            previousSessionSelected: null,
        })
    }

    render() {
        const {
            /* eslint-disable */
            route,
            params,
            routes,
            /* eslint-enable */
            adding,
            activity,
            filtered,
            filteredActivity,
            filteredMember,
            filteredPassPlan,
            filteredSupervisor,
            ...props
        } = this.props;

        const view = this.getView();
        const {
            sessions,
            activities,
            offer,
            userOwnedPasses,
            members,
            membersForFilter,
            supervisors,
            passes,
            reservations,
            selected,
            selectedMember,
            date,
            soonestSessionDate,
            adding: stateAdding,
            loading,
            showModal,
            showReservationConfirmation,
            goToSubscriptionStep,
            showCalendar,
            isLoadingOffer
        } = this.state;

        const isSmallScreen = window.matchMedia('(max-width: 360px)').matches;

        return <WithEditor then={() => this.reopenModal()}>
            <View
                activity={activity}
                view={isSmallScreen ? 'agenda' : view}
                date={date ? date : soonestSessionDate}
                sessions={sessions}
                activities={activities}
                filteredActivity={filteredActivity}
                filteredMember={filteredMember}
                filteredPassPlan={filteredPassPlan}
                filteredSupervisor={filteredSupervisor}
                offer={offer}
                userOwnedPasses={userOwnedPasses}
                fetchOffers={this.fetchOffers.bind(this)}
                fetchUserPasses={this.fetchUserPassIncludingPassesInCart.bind(this)}
                members={members}
                membersForFilter={membersForFilter}
                passes={passes}
                supervisors={supervisors}
                passFilter={this.makePassesFilter()}
                reservations={reservations}
                handleNavigate={(...e) => this.handleNavigate(...e)}
                handleSelection={(...e) => this.handleSelection(...e)}
                handleSelectMember={(...e) => this.handleSelectMember(...e)}
                handleAddToCart={(...e) => this.handleAddToCart(...e)}
                handleUsePass={(...e) => this.handleUsePass(...e)}
                handleFilterActivity={(...e) => this.handleFilterActivity(...e)}
                handleFilterMember={(...e) => this.handleFilterMember(...e)}
                handleFilterSupervisor={(...e) => this.handleFilterSupervisor(...e)}
                handleFilterPasses={(...e) => this.handleFilterPasses(...e)}
                selected={selected}
                selectedMember={selectedMember}
                for={this.getSubject()}
                reservationRef={(node) => this.reservationNode = node}
                adding={adding || stateAdding}
                subject={this.getSubject()}
                loading={!!loading}
                setCurrentView={this.setCurrentView.bind(this)}
                handleCloseModal={this.handleCloseModal.bind(this)}
                handleCloseModalWithSessionReload={this.handleCloseModalWithSessionReload.bind(this)}
                showModal={showModal}
                showReservationConfirmation={showReservationConfirmation}
                goToSubscriptionStep={goToSubscriptionStep}
                showCalendar={showCalendar}
                isLoadingOffer={isLoadingOffer}
                usePassError={this.state.usePassError}
                {...props}
            />
        </WithEditor>;
    }
}

PassesController.propTypes = {
    dispatch: PropTypes.func,
    route: PropTypes.object,
    routes: PropTypes.array,
    params: PropTypes.object,
    location: PropTypes.object,
    activity: PropTypes.object,
    organization: PropTypes.object,
    adding: PropTypes.bool,
    sessions: PropTypes.array,
    cart: PropTypes.shape({
        items: PropTypes.array,
    }).isRequired,
};

PassesController.contextTypes = {
    loggedUser: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
    ]),
};

// Connecte avec le store redux.
const withProperties = ({
                            buy: {cart: {adding, error}},
                            cart,
                        }) => ({adding, error, cart});

export default queryStringSynchronized(connect(withProperties)(PassesController), PARAMS);
