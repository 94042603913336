import React, {Component}  from "react";
import PropTypes             from "prop-types";
import {
	FormattedMessage,
	defineMessages,
} from "react-intl";

const messages = defineMessages({
	loading: {
		id:"qidigo-components.loading.loading",
		defaultMessage:"Chargement en cours...",
	}
});

/**
 * Component générique de widget indiquant un chargement.
 *
 * Il est attendu que le comportement du widget vienne des fichiers de style
 * du projet.
 *
 * ### `props`
 *
 *   * `compact` présenté à la hauteur d'une ligne.
 *   * `withMessage` affiche un message de chargement.
 *      Incompatible avec `compact`.
 *   * `className` *implicite*.
 *
 */
class Loading extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}

	render() {
		const {
			compact,
			withMessage,
			className,
			message,
		} = this.props;

		const classNames = [className, "loading"];
		if (compact) {
			classNames.push("is-compact");
		}
		if (!(message || withMessage)) {
			classNames.push("is-without-message");
		}

		let messageFragment = null;
		if ((message || withMessage) && !compact) {
			messageFragment =
				<div className="loading--message">
					{
						message ? message :
						<FormattedMessage {...messages.loading} />
					}
				</div>
			;
		}

		return (
			<div className={classNames.join(" ")}>
				<div className="loading--wrapper">
					{messageFragment}
					<div className="loading--spinner" />
				</div>
			</div>
		);
	}
}

Loading.propTypes = {
	className: PropTypes.string,
	withMessage: PropTypes.bool,
	compact:     PropTypes.bool,
	message:     PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
};

export default Loading;
