import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CustomToolbar from "../../views/activity/NewCustomToolbar";
import {defineMessages, FormattedMessage,} from "react-intl";
import BigCalendar from "react-big-calendar";
import {getLocale} from "qidigo-i18n";
import {localizedPatterns} from "./localization";
import 'react-big-calendar/lib/css/react-big-calendar.css';

const translations = defineMessages({
    "label.previous": {id: "qidigo.calendar.labels.previous", defaultMessage: "Précédent"},
    "label.next": {id: "qidigo.calendar.labels.next", defaultMessage: "Suivant"},
    "label.today": {id: "qidigo.calendar.labels.today", defaultMessage: "Aujourd'hui"},
    "label.month": {id: "qidigo.calendar.labels.month", defaultMessage: "Mois"},
    "label.agenda": {id: "qidigo.calendar.labels.agenda", defaultMessage: "Liste"},
    "label.more": {id: "qidigo.calendar.labels.more", defaultMessage: "[+] {count} en plus"},
    "label.all_day": {id: "qidigo.calendar.labels.all_day", defaultMessage: "Toute la journée"},
});

//
// Fix un peu janky fix pour temporairement taire les erreurs de du validateur
// de la propType `views` de `react-big-calendar`. On copie les propTypes du
// component en remplaçant `views` par un NOP.
//
// Autrement, on a un warning/erreur de React:
//
//     Warning: Failed prop type: validator.apply is not a function
//
// C'est peut-être causé par une mauvaise utilisation de `react-big-calendar`
// et de sa capacité à ajouter des `views` spéciales.
//
// Voir #121
//
BigCalendar.ControlledComponent.propTypes = Object.assign(
    {}, BigCalendar.ControlledComponent.propTypes, {views: () => null}
);

// Configure la librairie utiliser pour gérer les dates.
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

/**
 * Pass-through vers BigCalendar avec des *defaults* Qidigo.
 *
 * Ces *defaults* sont majoritairement en raison de la traduction.
 */

const eventStyleGetter = (event, start, nb_places_remaining) => {
    const today = new Date();
    let timeWindow = null;
    let hasPlacesRemaining = false;
    let isAvailable = false;
    let isAvailableSoon = false;

    if (event.session) {
        timeWindow = event.session.subscription_time_window;
        hasPlacesRemaining = event.session.nbr_places_remaining > 0 || event.session.nbr_places_remaining === null;
        isAvailable = event.session.group.is_available_right_now || event.session.group.is_from_purchased_pass;
        isAvailableSoon = event.session.group.is_available_or_in_the_future;
    }

    if (timeWindow && timeWindow.type === 'time_window' && hasPlacesRemaining) {
        if (timeWindow.until) {
            let respectsUntil = new Date(timeWindow.until) > today;
            isAvailable = respectsUntil;
            isAvailableSoon = respectsUntil;
        }

        if (timeWindow.before) {
            isAvailableSoon = new Date(timeWindow.before) > today;
            isAvailable = !isAvailableSoon;
        }

    }

    if (new Date(start) < today) {
        isAvailable = false;
        isAvailableSoon = false;
    }

    let color = '#777777'
    if (isAvailable) {
        color = '#27708E';
    }
    if (!hasPlacesRemaining && (isAvailable || isAvailableSoon)) {
        color = '#9D4949FF'
    }
    if (isAvailableSoon && !isAvailable && hasPlacesRemaining) {
        color = '#85a4c4';
    }

    const style = {
        backgroundColor: color,
        borderColor: 'white',
        opacity: start < today ? 0.80 : 1,
        pointerEvents: !isAvailable && !isAvailableSoon ? 'none' : 'auto',
        fontWeight: 600,
        zIndex: isAvailable ? 2 : 1,
        fontSize: hasPlacesRemaining ? 'smaller' : 'small',
        backgroundImage: !hasPlacesRemaining && (isAvailable || isAvailableSoon)
            ? 'repeating-linear-gradient(45deg, transparent, transparent 2px, #BE7D7DFF 2px, #BE7D7DFF 7px)' : ''
    };


    return {
        style: style,
    };
};

const setTimeIndicator = () => {
    const timeIndicator = document.getElementsByClassName('rbc-current-time-indicator');
    if (timeIndicator.length > 0) {
        let styleTag = document.getElementById('custom-styles');
        // If the style tag doesn't exist, create a new one
        if (!styleTag) {
            styleTag = document.createElement('style');
            styleTag.id = 'custom-styles';
            document.head.appendChild(styleTag);
        }

        styleTag.innerHTML = `
            .rbc-current-time-indicator {
                  position: absolute;
                  z-index: 1;
                  left: 0;
                  height: 1px;
                  background-color: #ED3F3AFF;
                  pointer-events: none;
                  z-index: 3;
            }
                
            .rbc-current-time-indicator::before {
                  display: block;
                  position: absolute;
                  left: -3px;
                  top: -3px;
                  content: ' ';
                  background-color: #ED3F3AFF;
                  border-radius: 50%;
                  width: 8px;
                  height: 8px;
                  z-index: 3;
              }`;
    }
}

const customEvent = ({event}) => (
    <div>
        {event.title}
    </div>
);

const Calendar = (props, context) => {
    const {
        className,
        visibleToolbar,
        setCurrentView,
        view,
        onView,
        defaultView,
        fromSchedule
    } = props;

    const classes = [className ? className : "", "qidigo-calendar"];
    const {formatMessage} = context.intl;

    let header = localizedPatterns["header"][getLocale()];
    if (!header) {
        header = localizedPatterns["header"]["default"];
    }
    const formats = {
        dayRangeHeaderFormat: header,
    };

    setTimeIndicator()

    return (
        <BigCalendar
            eventPropGetter={eventStyleGetter}
            selectable={false}
            onSelecting={slot => false}
            toolbar={visibleToolbar}
            formats={formats}
            components={{
                event: customEvent,
                toolbar: ({...props}) => (
                    <CustomToolbar
                        {...props}
                        defaultView={defaultView}
                        setCurrentView={setCurrentView}
                        onView={() => {}}
                        onNavigate={props.onNavigate}
                        fromSchedule={fromSchedule}
                    />
                )
            }}
            view={view}
            onView={onView}
            defaultView={"week"}
            {...props}
            messages={{
                previous: <FormattedMessage {...translations["label.previous"]} />,
                next: <FormattedMessage {...translations["label.next"]} />,
                today: <FormattedMessage {...translations["label.today"]} />,
                month: <FormattedMessage {...translations["label.month"]} />,
                agenda: <FormattedMessage {...translations["label.agenda"]} />,
                showMore: (count) => formatMessage({...translations["label.more"]}, {count}),
                allDay: <FormattedMessage {...translations["label.all_day"]} />,

            }}
            className={classes.join(" ")}
            // min={new Date(2024, 1, 0, 5, 0, 0)}
            // max={new Date(2024, 1, 0, 23, 0, 0)}
        />
    );
};

Calendar.propTypes = {
    className: PropTypes.string,
};

Calendar.contextTypes = {
    intl: PropTypes.object,
};

export default Calendar;
