import React, {Component}    from "react";
import PropTypes             from "prop-types";
import moment                from "moment";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import capitalize            from "lodash/capitalize";
import map                   from "lodash/map";
import {localizeDate}        from "./localization";

import Dates                 from "react-big-calendar/lib/utils/dates";
import {navigate}            from "react-big-calendar/lib/utils/constants";
import {accessor as get}     from "react-big-calendar/lib/utils/accessors";

const translations = defineMessages({
	"event.label": {id: "qidigo.calendar.agendaweek.event_label", defaultMessage: "{time} {description}"},
});

/**
 */
const AgendaViewDay = (props, context) => {
	const {
		events,
		startAccessor,
		endAccessor,
		titleAccessor,
		day,
		// Pour gérer la sélection
		selectable,
		selected,
		onSelectEvent,
	} = props;
	const {formatMessage} = context.intl;

	const daysEvents = events.filter(
		event => Dates.inRange(day,
			get(event, startAccessor),
			get(event, endAccessor), "day")
	);

	const classNames = [
		`is-ordinal-${moment(day).format("d")}`
	];

	const schedule = {};

	if (Dates.eq(day, moment().toDate(), "day")) {
		classNames.push("is-today");
	}

	for (let i in daysEvents) {
		const event = daysEvents[i];
		const start = moment(get(event, startAccessor));
		const hour = start.hour();
		if (!schedule[hour]) { schedule[hour] = []; }
		schedule[hour].push(event);
	}


	let contents = null;

	if (daysEvents.length === 0) {
		classNames.push("is-empty");
		contents =
			<div className="qidigo-calendar--empty"></div>
		;
	}
	else {
		classNames.push("is-not-empty");
		contents =
			<ul className="qidigo-calendar--hours">
				{
					map(schedule, (hourEvents, hour) =>
						<li className="qidigo-calendar--hour" key={hour}>
							<h3>{moment(get(hourEvents[0], startAccessor)).minutes(0).format("LT")}</h3>
							{
								hourEvents.map((event, index) => {
									const start = localizeDate("event.time", get(event, startAccessor));
									const title = get(event, titleAccessor);
									const children = event["agenda"] || null;

									// The original event, when overriding the agenda view.
									if (event["event"]) { event = event["event"]; }

									const eventClasses = ["qidigo-calendar--event"];
									if (selectable && selected === event) {
										eventClasses.push("is-selected");
										// Restons compatible avec react-big-calendar.
										eventClasses.push("rbc-selected");
									}

									// Déterminons si c'est dans le passé
									const eventStart = moment(event.start);
									if (eventStart.diff(moment()) < 0) {
										eventClasses.push("is-past");
									}

									if (children) {
										const childProps = {
											onClick: (e) => onSelectEvent(event, e),
											className: [children.props.className ? children.props.className : "", eventClasses.join(" ")].join(" "),
										};

										return React.cloneElement(children, childProps);
									}

									const Tag = selectable ? "a" : "div"
									return (
										<Tag key={index}
											title={
												formatMessage(translations["event.label"], {
													time: start, description: title,
												})
											}
											className={eventClasses.join(" ")}
											onClick={(e) => onSelectEvent(event, e)}
										>
											<FormattedMessage {...translations["event.label"]}
												values={{
													time: <small>{start}</small>,
													description: title
												}}
											/>
										</Tag>
									);
								})
							}
						</li>
					)
				}
			</ul>
			;
	}

	return (
		<li
			className={classNames.join(" ")}
		>
			<h2 className="qidigo-calendar--day-date">
				{capitalize(localizeDate("weekday", day))}
			</h2>
			{contents}
		</li>
	);
};
AgendaViewDay.propTypes = {
	day: PropTypes.object,
	events: PropTypes.array,
	startAccessor: PropTypes.string,
	endAccessor: PropTypes.string,
	titleAccessor: PropTypes.string,
};

AgendaViewDay.contextTypes = {
	intl: PropTypes.object,
};

/**
 * Affichage d'une date X à Y, de durée Z.
 * N'est pas lié à une journée de semaine (pas forcément du dimanche au samedi)
 */
class AgendaView extends Component {
	render() {
		const {
			date,
		} = this.props;
		const {start, end} = AgendaView.range(date, this.props);
		const range = Dates.range(start, end, "day");

		return (
			<div className="qidigo-calendar--agenda-view">
				<ul>
					{
						range.map((day) =>
							<AgendaViewDay key={day} {...this.props} day={day} />
						)
					}
				</ul>
			</div>
		);
	}
}

AgendaView.range = (date, {culture}) => {
	const start = moment(date).toDate();
	const end = moment(date).add(6, "days").toDate();

	return {start, end};
}

AgendaView.propTypes = {
	date: PropTypes.object,
};

/**
 * Fonction appelée par react-big-calendar sur navigation.
 */
AgendaView.navigate = (date, action) => {
	switch (action) {
		case navigate.PREVIOUS:
			return Dates.add(date, -1, "week");
		case navigate.NEXT:
			return Dates.add(date, 1, "week");
		default:
			return date;
	}
};

// Monkeypatch la view dans react-big-calendar.
// Autrement, le `View.range` ne sera pas appelé sur AgendaView.
import VIEWS from "react-big-calendar/lib/Views";
VIEWS["agenda"] = AgendaView;

export default AgendaView;
