import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import moment                from "moment";
import upperFirst from "lodash/upperFirst";

const translations = defineMessages({
	timespan: {id: "qidigo.groups.sessions.timespan", defaultMessage: "{from} à {to}", description: "9:00 à 11:00"},
	variable: {id: "qidigo.groups.sessions.variable", defaultMessage: "{sessions_count} séances, horaire variable"},
	none: {id: "qidigo.groups.sessions.none", defaultMessage: "Aucune séance définie"},
});

/**
 * Display time range for element without schedule (sessions only). 
*/
const SessionsTimesFragment = (props, context) => {
	const {
		firstToUpper,
		className,
		sessions,
	} = props;
	const classes = [className, "sessionstimesfragment"];
	const {formatMessage} = context.intl;

	if (sessions.length === 0) {
		const text = formatMessage(translations["none"]);
		return (
			<ul className={classes.join(" ")}>
				<li>
					{firstToUpper ? upperFirst(text) : text}
				</li>
			</ul>
		);
	}

	if (sessions.length > 1) {
		const text = formatMessage({...translations["variable"]}, {sessions_count: sessions.length});
		return (
			<ul className={classes.join(" ")}>
				<li>
					{firstToUpper ? upperFirst(text) : text}
				</li>
			</ul>
		);
	}

	const from = moment(sessions[0].start_date).format("LT");
	const to   = moment(sessions[0].end_date).format("LT");
	const text = formatMessage({...translations["timespan"]}, {from: from, to: to});
	return (
		<ul className={classes.join(" ")}>
			<li>
				{firstToUpper ? upperFirst(text) : text}
			</li>
		</ul>
	);
};

SessionsTimesFragment.propTypes = {
	firstToUpper: PropTypes.bool,
	className: PropTypes.string,
	sessions: PropTypes.array,
};

SessionsTimesFragment.defaultProps = {
	firstToUpper: false,
};

SessionsTimesFragment.contextTypes = {
	intl: PropTypes.object,
};
export default SessionsTimesFragment;
