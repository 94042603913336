import Fetch                 from "qidigo-fetch";
import Auth                  from "qidigo-auth";
import {sendError}           from "../router";

export const REQUEST_WAITING_LISTS = "PROFILE:REQUEST_WAITING_LISTS";
export const RECEIVE_WAITING_LISTS = "PROFILE:RECEIVE_WAITING_LISTS";
export const RECEIVE_WAITING_LISTS_FAILED = "PROFILE:RECEIVE_WAITING_LISTS_FAILED";
export const INVALIDATE_WAITING_LISTS = "PROFILE:INVALIDATE_WAITING_LISTS";
export const DELETE_WAITING_LISTS = "PROFILE:DELETE_WAITING_LISTS";
export const DELETED_WAITING_LISTS = "PROFILE:DELETED_WAITING_LISTS";

/**
 * Notifie qu'on effectue une requête.
 */
const requestWaitingLists = () => ({
	type: REQUEST_WAITING_LISTS,
});

/**
 * Notifie de la réception des données.
 */
const receiveWaitingLists = (registrations, bundled) => ({
	type: RECEIVE_WAITING_LISTS,
	registrations,
	bundled,
	at: Date.now(),
});

const deleteWaitingLists = () => ({
	type: DELETE_WAITING_LISTS,
});

const deletedWaitingLists = () => ({
	type: DELETED_WAITING_LISTS,
});

/**
 * Force un refresh.
 */
export const invalidateWaitingLists = () => ({
	type: INVALIDATE_WAITING_LISTS,
});

/**
 * Récupère la liste des inscriptions aux listes d'attente.
 */
export const fetchWaitingLists = () =>
	(dispatch, getState) => {
		const userID = Auth.getUserID();

		// Indique qu'on commence à récupérer.
		dispatch(requestWaitingLists());

		// Dispatch
		return dispatch((dispatch, getState) => {
			return Fetch.get(`users/${userID}/waiting_lists?bundled=true&family=true`)
				.then((response) => {
					const {
						_bundled: bundled,
						registrations
					} = response;

					// Réceptionne.
					// FIXME : Gérer le bundled data dans un store commun.
					dispatch(receiveWaitingLists(registrations, bundled));

					return response;
				})
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: RECEIVE_WAITING_LISTS_FAILED, error});
				});
		});
	};

/**
 * Retire l'enregistrement demandé.
 * Les paramètres passés sont les ID.
 */
export const removeFromWaitingList = ({id, subscriber}) =>
	(dispatch, getState) => {
		dispatch(deleteWaitingLists());

		return dispatch((dispatch, getState) => {
			return Fetch["delete"](`users/${subscriber}/waiting_lists/${id}`).then((response) => {
				dispatch(deletedWaitingLists());

				return response;
			});
		});
	};
