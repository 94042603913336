import {Component} from "react";
import {navigate} from "qidigo-router";

/**
 * Index du site.
 * @extends {Component}
 */
class Index extends Component {
	componentWillMount() {
		window.location = "https://www2.qidigo.com";
	}

	render() {
		return null;
	}
}

export default Index;
