import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";

import T                     from "qidigo-i18n/messages";
import Form                  from "qidigo-form";
import ButtonsBar            from "qidigo-form/buttons_bar.js";

const messages = defineMessages({
	email:     {id: "qidigo.email_password.email", defaultMessage: "Courriel"},
	email_confirmation:     {id: "qidigo.email_password.email_confirmation", defaultMessage: "Confirmation du courriel"},
	password:  {id: "qidigo.email_password.password", defaultMessage: "Mot de passe"},
	password_confirmation:  {id: "qidigo.email_password.password_confirmation", defaultMessage: "Confirmation du mot de passe"},
	submit:    {id: "qidigo.email_password.submit", defaultMessage: "Go"},
});

/**
 * Fragment implémentant un champ courriel et un champ mot de passe.
 *
 * C'est principalement utilisé pour les champs de connexion ou
 * d'enregistrement des utilisateurs.
 *
 * @extends {Component}
 */
class EmailPassword extends Component {

	handleValidate(errors) {
		const {formatMessage} = this.context.intl;
		
		const {email, email_confirmation, password, password_confirmation} = this.props.values;
		
		const {withConfirmation} = this.props;
		
		if (withConfirmation) {
			if (email!==email_confirmation) {
				errors.fields.email_confirmation.push(formatMessage(T["errors.messages.confirmation"], {attribute: formatMessage(messages.email)}));
			}
			
			if (password!==password_confirmation) {
				errors.fields.password_confirmation.push(formatMessage(T["errors.messages.confirmation"], {attribute: formatMessage(messages.password)}));
			}
		}
		
		return errors;
	}

	render() {
		const {formatMessage} = this.context.intl;

		const {
			className,
			withConfirmation,
			...leftoverProps
		} = this.props;

		// Classes appliquées au wrapper.
		let wrapperClasses = ["fragment-emailpassword", className];

		return (
			<Form
				className={wrapperClasses.join(" ")}
				{...leftoverProps}
				onValidate={(errors) => this.handleValidate(errors)}
				presentation="simple"
				ref={(node) => this.form = node}
			>
				<Form.Input
					name="email"
					label={formatMessage(messages.email)}
					type="email"
					icon="email"
					required={true}
					autoFocus={true}
				/>
				{
					withConfirmation ? <Form.Input
					name="email_confirmation"
					label={formatMessage(messages.email_confirmation)}
					type="email"
					icon="email"
					required={true}
				/>: null
				}
				<Form.Input
					name="password"
					label={formatMessage(messages.password)}
					type="password"
					icon="password"
					required={true}
				/>
				{
					withConfirmation ? <Form.Input
					name="password_confirmation"
					label={formatMessage(messages.password_confirmation)}
					type="password"
					icon="password"
					required={true}
				/>: null
				}
				{this.props.children}
				<ButtonsBar loading={this.props.disabled}
					submitLabel={formatMessage(messages.submit)}
				/>
			</Form>
		);
	}
}

EmailPassword.propTypes = {
	onChange:           PropTypes.func.isRequired,
	onSubmit:           PropTypes.func.isRequired,
	className:          PropTypes.string,
	values:          	PropTypes.object,
	disabled:           PropTypes.bool,
	withConfirmation:   PropTypes.bool,
};

EmailPassword.defaultProps = {
};

EmailPassword.contextTypes = {
	intl: PropTypes.object,
};

export default EmailPassword;
