import * as CART from "../actions/cart";

/**
 * Reducer pour les actions relative au panier.
 */
const cart = (state = {}, action) => {
	switch (action.type) {
		case "@@router/LOCATION_CHANGE":
			return Object.assign({}, state, {
				open: action.payload.hash === "#cart",
			});
		case CART.REFRESH_CART:
			return Object.assign({}, state, {
				loading: true,
			});
		case CART.RECEIVE_CART:
			return Object.assign({}, state, {
				items: action.response.items,
				count: action.response.count,
				cart_expiration: action.response.cart_expiration,
				loading: false,
			});
	}

	return state;
};

export default cart;
