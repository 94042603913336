import * as React from "react";
import {Component} from "react";
import {IAddress, IBillingAddress, IAddressError} from "../../types";
import PaymentAddress from "./paymentAddress";
import PaymentCustomAddressForm from "./paymentCustomAddressForm";
import { defineMessages, FormattedMessage } from "react-intl";

const translations = defineMessages({
    "credit_card_related_address": {
        id: "qidigo.billing.payment_addresses_list.credit_card_related_address",
        defaultMessage: "Adresse reliée à la carte de crédit"
    },
    "use_another_address": {
        id: "qidigo.billing.payment_addresses_list.use_another_address",
        defaultMessage: "Utiliser une adresse de facturation différente"
    },
    "use_existing_address": {
        id: "qidigo.billing.payment_addresses_list.use_existing_address",
        defaultMessage: "Utiliser une adresse existante"
    },
});

interface IPaymentAddressListProps {
    addressList: Array<IAddress>,
    selectedBillingAddress: IBillingAddress|null,
    selectBillingAddress: (billingAddress: IBillingAddress|null) => void,
    setErrorOnAddress: (errorList: IAddressError[]) => void
    isPaysafeSelected: boolean
}

interface IPaymentAddressListState {
    isCustomAddress: boolean,
    previouslySelectedAddress: number|null,
    hasNonCustomAddress: boolean
}

class PaymentAddressList extends Component<IPaymentAddressListProps, IPaymentAddressListState> {
    constructor(props: IPaymentAddressListProps) {
        super(props);
        this.state = {
            isCustomAddress: this.isCustomAddress(),
            previouslySelectedAddress: null,
            hasNonCustomAddress: true
        };
        
    }

    componentDidMount() { 
        if(this.props.addressList.length < 1) {
            this.openCustomAddressForm();
            this.setState({
                hasNonCustomAddress: false
            });
        }
    }

    isCustomAddress(): boolean {
        return this.props.selectedBillingAddress !== null
            && this.props.selectedBillingAddress.id === null;

    }

    openCustomAddressForm() {
        this.props.selectBillingAddress(null);
        this.setState({
            isCustomAddress: true
        });
    }

    closeCustomAddressForm() {
        
        this.props.setErrorOnAddress([]);

        const firstAddress = this.props.addressList[0];
        let addressToSelectBack: IBillingAddress = {
            id: firstAddress.id,
            civic_number_ext: firstAddress.civic_number_ext,
            civic_number_and_street: firstAddress.civic_number_and_street,
            city: firstAddress.city.name,
            city_id: firstAddress.city.id,
            willBeSaved: false,
            state: firstAddress.state.abbreviation,
            state_id: firstAddress.state.id,
            postal_code: firstAddress.postal_code,
            country: firstAddress.country.id,
        }

        if(this.state.previouslySelectedAddress !== null) {
            const foundAddress = this.props.addressList.find(address => address.id === this.state.previouslySelectedAddress);
            if(foundAddress) {
                addressToSelectBack = {
                    id: foundAddress.id,
                    civic_number_ext: foundAddress.civic_number_ext,
                    civic_number_and_street: foundAddress.civic_number_and_street,
                    city: foundAddress.city.name,
                    city_id: foundAddress.city.id,
                    willBeSaved: false,
                    state: foundAddress.state.abbreviation,
                    state_id: foundAddress.state.id,
                    postal_code: foundAddress.postal_code,
                    country: foundAddress.country.id,
                }
            }
        }


        this.props.selectBillingAddress(addressToSelectBack);
        this.setState({
            isCustomAddress: false
        });
    }

    setPreviouslySelectedAddress(id: number) {
        this.setState({
            previouslySelectedAddress: id
        });
    }

    getIfSelected(addressId: number, currentIndex: number): boolean {
        if (this.props.selectedBillingAddress === null) {
            if (this.state.previouslySelectedAddress !== null) {
                return this.state.previouslySelectedAddress === addressId;
            }
            return currentIndex === 0;
        }
        return this.props.selectedBillingAddress.id === addressId;
    }

    render() {
        return (
            <div className={"payment-addresses-list"}>
                {
                    !this.state.isCustomAddress &&
                    this.props.addressList.length > 0 &&
                    <>
                        <div className="payment-addresses-list--title">
                            <FormattedMessage {...translations["credit_card_related_address"]} />
                        </div>
                        <ul className={"payment-addresses-list--list"}>
                            {
                                this.props.addressList.map(
                                    (address, index) =>
                                        <PaymentAddress
                                            key={address.id}
                                            index={index + 1}
                                            address={address}
                                            isSelected={this.getIfSelected(address.id, index)}
                                            selectBillingAddress={this.props.selectBillingAddress}
                                            setSelectedAddress={this.setPreviouslySelectedAddress.bind(this)}
                                        />
                                )
                            }
                        </ul>
                        <a
                            className={"payment-addresses-list--other-address-button"}
                            onClick={() => this.openCustomAddressForm()}
                        >
                            <FormattedMessage {...translations["use_another_address"]} />
                        </a>
                    </>
                }
                {
                    this.state.isCustomAddress &&
                    <>
                        <PaymentCustomAddressForm
                            selectBillingAddress={this.props.selectBillingAddress}
                            setErrorOnAddress={this.props.setErrorOnAddress}
                            isPaysafeSelected={this.props.isPaysafeSelected}
                        />
                        {
                            this.state.hasNonCustomAddress && 
                            <div>
                                <a
                                    className={"payment-addresses-list--existing-address-button"}
                                    onClick={() => this.closeCustomAddressForm()}
                                >
                                    <FormattedMessage {...translations["use_existing_address"]} />
                                </a>
                            </div>
                        }
                    </>
                }
            </div>
        )
    }
}

export default PaymentAddressList;
