import React from "react";
import PropTypes from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

const translations = defineMessages({
	"title.pending": {id: "qidigo.dashboard.billing.confirmation.title.pending", defaultMessage: "{count, plural, one {Inscription complétée} other {Inscriptions complétées} } avec succès"},
	"title.settlement": {id: "qidigo.dashboard.billing.confirmation.title.settlement", defaultMessage: "Paiement confirmé"},
	
	"blurb.pending": {id: "qidigo.dashboard.billing.confirmation.blurb.pending", defaultMessage: "Nous avons bien enregistré votre transaction avec « {organization} »."},
	"blurb.settlement": {id: "qidigo.dashboard.billing.confirmation.blurb.settlement", defaultMessage: "Le paiement a été enregistré sur la facture #{id} de « {organization} »."},
});

const ConfirmationHeader = ({invoice, isSettlement = true}) =>
	<div className="checkout-confirmation">
		<div className="checkout-confirmation--blurb">
			<h3>
				<FormattedMessage
					{...translations[isSettlement ? "title.settlement" : "title.pending"]}
					values={{
						count: invoice.items.length,
					}}
				/>
			</h3>
			<p>
				<FormattedMessage
					{...translations[isSettlement ? "blurb.settlement" : "blurb.pending"]}
					values={{
						id: invoice["id"],
						organization: invoice["organization"]["name"],
					}}
				/>
			</p>
		</div>
	</div>
	;

ConfirmationHeader.propTypes = {
	payload: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
};

export default ConfirmationHeader;
