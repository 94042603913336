import ReactDOM from "react-dom";
import ENV from "qidigo-env";

class GatewayRegistry {
	constructor(provider) {
		this._provider = provider;
		this._containers = {};
		this._children = {};
		this._currentID = 0;
		this._toRender = {};
		GatewayRegistry.instance = this;
	}

	getPortal(into) {
		return window.document.querySelector(this.getPortals()[into]);
	}

	getPortals() {
		return this._provider.props.portals;
	}


	// Registers a `<Gateway>` use, one child.
	register(name, child) {
		this._children[name] = this._children[name] || {};

		const gatewayId = `${name}_${this._currentID}`;
		this._children[name][gatewayId] = child;
		this._currentID += 1;

		return gatewayId;
	}

	// Registers a `<Gateway>` use.
	unregister(name, id) {
		this.clearChild(name, id);
		this._renderContainer(name);
	}

	// Adds a child to be rendered.
	addChild(name, gatewayId, child) {
		this._children[name][gatewayId] = child;
		this._renderContainer(name);
	}

	// Clears what's registered for a `<Gateway>` when a new
	// render cycle happens, or when it's not needed anymore.
	clearChild(name, id) {
		delete this._children[name][id];
	}

	renderInto(name, children) {
		if (ENV["server"]) {
			const portal = this.getPortals()[name] || "body";
			const toRender = this._toRender[portal] || [];
			toRender.push(children);
			this._toRender[portal] = toRender;

			return null;
		}
		else {
			const gateway = this.getPortal(name);

			return ReactDOM.createPortal(children, gateway);
		}
	}

	_renderContainer(name) {
		if (!this._containers[name] || !this._children[name]) {
			return;
		}

		this._containers[name].setState({
			children: Object.keys(this._children[name]).sort().map(id => this._children[name][id])
		});
	}

	addContainer(name, container) {
		this._containers[name] = container;
		this._renderContainer(name);
	}

	removeContainer(name) {
		this._containers[name] = null;
	}
}

export default GatewayRegistry;
