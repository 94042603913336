import * as React from 'react';
import {defineMessages} from 'react-intl';
import {Component} from "react";
// @ts-ignore
import PropTypes from "prop-types";
// @ts-ignore
import "../../design/pages/gender_restrictions.less";
import upperFirst from "lodash/upperFirst";

const ALL_GENDERS = ['woman', 'man', 'other', 'not_answered'];

const translations = defineMessages({
    man: {
        id: "qidigo.gender_restrictions.men",
        defaultMessage: "hommes",
    },
    woman: {
        id: "qidigo.gender_restrictions.women",
        defaultMessage: "femmes",
    },
    other: {
        id: "qidigo.gender_restrictions.others",
        defaultMessage: "autres identités de genre",
    },
    not_answered: {
        id: "qidigo.gender_restrictions.not_answered",
        defaultMessage: "préfèrent ne pas répondre",
    },
    all: {
        id: "qidigo.gender_restrictions.all",
        defaultMessage: "tous",
    },
    and: {
        id: "qidigo.gender_restrictions.and",
        defaultMessage: " et ",
    },
    for: {
        id: "qidigo.gender_restrictions.for",
        defaultMessage: "pour ",
    },
    inclusive_text: {
        id: "qidigo.gender_restrictions.inclusive_text",
        defaultMessage: "Est aussi accessible ",
    },
    inclusivity_birth_sex_text: {
        id: "qidigo.gender_restrictions.inclusivity_birth_sex_text",
        defaultMessage: "pour les personnes ne s'identifiant pas à leur sexe de naissance"
    },
    inclusivity_no_gender_text: {
        id: "qidigo.gender_restrictions.inclusivity_no_gender_text",
        defaultMessage: "pour les personnes n'ayant pas identifié leur genre"
    }

});

interface IGenderRestrictionsProps {
    firstToUpper: boolean,
    genders: string[],
    targetMemberMessage: string,
    forPassModal: boolean,
}

interface IGenderRestrictionsState {
    showInclusivityButton: boolean,
    showInclusivityPopover: boolean
}

class GenderRestrictions extends Component<IGenderRestrictionsProps, IGenderRestrictionsState> {
    static defaultProps: Partial<IGenderRestrictionsProps> = {
        firstToUpper: false,
    };

    private readonly buttonRef: React.RefObject<HTMLAnchorElement>;
    private readonly popoverRef: React.RefObject<HTMLSpanElement>;

    constructor(props: IGenderRestrictionsProps) {
        super(props);
        this.state = {
            showInclusivityButton: false,
            showInclusivityPopover: false
        }

        this.buttonRef = React.createRef();
        this.popoverRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        if (this.isAllGenders()) {
            return;
        }

        const hasMenOrWomenGenders = this.props.genders.find((gender) => {
            return gender === 'woman' || gender === 'man'
        }) !== undefined;
        const hasInclusiveGenders = this.props.genders.find((gender) => {
            return gender === 'other' || gender === 'not_answered '
        }) !== undefined;

        if (hasMenOrWomenGenders && hasInclusiveGenders) {
            this.setState({
                showInclusivityButton: true
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    isAllGenders(): boolean {
        const {genders} = this.props;

        if (genders.length === 0 ) {
            return true;
        }

        if (genders.length !== ALL_GENDERS.length) {
            return false;
        }

        return genders.sort().every((value, index) => value === ALL_GENDERS.sort()[index]);
    }


    getGendersRestrictionText = (): string => {
        const {formatMessage} = this.context.intl;
        const {genders, firstToUpper} = this.props;

        let gendersText = firstToUpper ? upperFirst(formatMessage(translations['for'])) : formatMessage(translations['for']);

        if (this.isAllGenders()) {
            return gendersText + formatMessage(translations['all']);
        }

        let filteredGenders = genders.filter((gender) => gender === 'woman' || gender === 'man');

        if (filteredGenders.length === 0) {
            gendersText += formatMessage(translations[genders[0]]);
            gendersText += genders[1] ? formatMessage(translations['and']) + formatMessage(translations[genders[1]]) : "";
        }

        if (filteredGenders.length === 1) {
            return gendersText + formatMessage(translations[genders[0]]);
        }

        for (let [key, gender] of Object.entries(filteredGenders)) {
            gendersText += formatMessage(translations[gender]);

            if (Number(key) === filteredGenders.length - 2) {
                gendersText += formatMessage(translations['and']);
            }
        }

        return gendersText;
    };

    toggleInclusivityPopover = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            showInclusivityPopover: !this.state.showInclusivityPopover
        })
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.popoverRef.current && !this.popoverRef.current.contains(event.target as Node) &&
            this.buttonRef.current && !this.buttonRef.current.contains(event.target as Node)) {
            if (this.state.showInclusivityPopover) {
                this.setState({showInclusivityPopover: false});
            }
        }
    };

    getInclusivityText = () => {
        const {formatMessage} = this.context.intl;

        const containsOther = this.props.genders.find((gender) => gender === 'other') !== undefined;
        const containsNoAnswer = this.props.genders.find((gender) => gender === 'not_answered') !== undefined;

        let text = formatMessage(translations['inclusive_text']);

        if (containsOther && containsNoAnswer) {
            return text + formatMessage(translations['inclusivity_birth_sex_text'])
                + formatMessage(translations['and'])
                + formatMessage(translations['inclusivity_no_gender_text']);
        }

        if (containsOther) {
            return text + formatMessage(translations['inclusivity_birth_sex_text']);
        }

        if (containsNoAnswer) {
            return text + formatMessage(translations['inclusivity_no_gender_text']);
        }

        return "";
    }

    render() {
        return (
            !this.props.genders
                ? <p></p>
                : <div className={'gender-restrictions'}
                       onClick={this.state.showInclusivityButton ? this.toggleInclusivityPopover : () => null}>
                    {
                        this.getGendersRestrictionText()
                    }
                    {
                        this.props.targetMemberMessage
                    }
                    {
                        this.state.showInclusivityButton &&
                        <a className={"gender-restrictions--inclusivity-button"} ref={this.buttonRef}
                           onClick={this.toggleInclusivityPopover}> * </a>
                    }
                    {
                        this.state.showInclusivityPopover &&
                        <span ref={this.popoverRef} onClick={this.toggleInclusivityPopover}
                              className={"gender-restrictions--tooltip-text" + (this.props.forPassModal ? " for-pass-modal" : "")}>{this.getInclusivityText()}</span>
                    }
                </div>
        );
    }
}

// @ts-ignore
GenderRestrictions.contextTypes = {
    intl: PropTypes.object,
};

export default GenderRestrictions;
