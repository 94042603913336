/**
 * A development-time assertion helper.
 *
 * Use this to provide a message for the developer when either a value is
 * missing or a runtime check is failing.
 *
 * It will pass-through the value when test is passing.
 * Default test is value truthiness.
 *
 * In production environment, no check is done, is directly passes values.
 *
 * Do not use in tight and performance dependent sections, since even with
 * tree-shaking I think it will add a function call on the stack.
 */
const assert = (value, message, test) => {
	if (process.env.NODE_ENV === "development") {
		if (test === undefined) {
			test = value;
		}

		if (!test) {
			const msg = `Assertion failed!${message ? ` → "${message}"`:""}`;
			console.error(msg); // eslint-disable-line
			throw new Error(msg); // eslint-disable-line
		}
	}

	return value;
};

export default assert;
