import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import Address               from "qidigo-components/address.js";
import Telephone             from "qidigo-components/telephone.js";

const translations = defineMessages({
	schedule: {id: "qidigo.map-columns.schedule", defaultMessage: "Heures d'ouverture administratives"},
	telephone: {id: "qidigo.map-columns.telephone", defaultMessage: "Téléphone\u00A0:"},
});

/**
*/
const MapColumnsFragment = (props, context) => {
	const {
		className,
		label,
		extra,
		address,
		phone,
		schedule,
		TitleTag,
	} = props;
	const classes = [className, "map-columns", "with-map"];

	return (
		<div className={classes.join(" ")}>
			<div className="map-columns--wrapper">
				<div className="map-columns--infos">
					<TitleTag>
						{label}
					</TitleTag>
					<div>
						{address ? <Address address={address} /> : null}

						{phone ?
							<span className="map-columns--phone">
								<FormattedMessage {...translations["telephone"]} />{" "}
								<Telephone phoneNumber={phone} />
							</span> : null}
					</div>
				</div>
				{schedule ? <div className="map-columns--schedule"></div> : null}
				{extra}
			</div>
		</div>
	);
};

MapColumnsFragment.contextTypes = {
	intl: PropTypes.object,
};

MapColumnsFragment.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	address: PropTypes.object,
	phone: PropTypes.object,
	schedule: PropTypes.object,
	TitleTag: PropTypes.node,
	extra: PropTypes.node,
};

MapColumnsFragment.defaultProps = {
	TitleTag: "h2",
};

export default MapColumnsFragment;
