import React                 from "react";
import PropTypes             from "prop-types";

/**
 * Component générique de "button".
 *
 * Ce button peut se présenter autant comme un `<a>` qu'un réel `<button>`.
 *
 * Cette présentation dans le DOM vient des propriétés utilisées.
 * La présence d'un `link=` sur `<Button>` vient créer un `<a>`
 *
 * Il est attendu que les styles de l'application vont rendre la présentation
 * du bouton équivalente au lien.
 *
 * ### `props`
 *
 *   * `linkType`: Utilisé pour forcer un lien sur une URL locale.
 *
 * ### `context`
 *
 *   * `componentsConfiguration.linkType`: A component (or tag name) to use by default instead of `a`.
 */
const Button = (props, {componentsConfiguration}) => {
	let classes = ["button"];
	let isButton = !props.link;

	let {
		type,
		link, linkType, action, className,
		...leftoverProps
	} = props;

	classes.push(className);

	classes.push(`button-${type}`);
	if (isButton) {
		return (
			<button
				type={type}
				{...leftoverProps}
				action={action}
				className={classes.join(" ")}
				role="button"
			>
				{props.children}
			</button>
		);
	}

	let Type ="a";
	if (componentsConfiguration && componentsConfiguration.linkType) {
		Type = componentsConfiguration.linkType;
	}

	if (link.match(/^https?:\/\//)) {
		Type = "a";
	}
	if (linkType) {
		Type = linkType;
	}

	return (
		<Type
			{...leftoverProps}
			/* hack */
			to={link} href={link}
			className={classes.join(" ")}
			role="button"
		>
			{props.children}
		</Type>
	);
};

Button.defaultProps = {
	type: "button",
};

Button.propTypes = {
	children:    PropTypes.any,
	className:   PropTypes.string,
	link:        PropTypes.string,
	linkType:    PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	action:      PropTypes.string,
	type:        PropTypes.string,
	onClick:     PropTypes.func,
	disabled:    PropTypes.bool
};

Button.contextTypes = {
	componentsConfiguration: PropTypes.shape({
		linkType: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	})
};

export default Button;
