import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {
	FormattedMessage,
	defineMessages,
} from "react-intl";
import Select                from "qidigo-form/select.js";
import env                   from "qidigo-env";
import {LANGS}               from "qidigo-i18n";
import {withRouter} from "react-router/es";
import SocialLinks        from "@app/components/sociallinks.js";
import Fetch from "../../modules/qidigo-fetch";
import Auth                  from "qidigo-auth";

const messages = defineMessages({
	// Languages Section
	sectionLocalization:      {id: "qidigo.footer.localization.title", defaultMessage: "Langue", description: "Language selector title."},

	// Menu Section
	sectionMenu: {id: "qidigo.footer.menu.title", defaultMessage: "Menu"},
	menuLogin:       {id: "qidigo.footer.menu.login", defaultMessage: "Me connecter"},
	menuNewAccount:  {id: "qidigo.footer.menu.new_account", defaultMessage: "Nouveau compte"},

	menuMyAccount:   {id: "qidigo.footer.menu.my_account", defaultMessage: "Mon compte"},
	menuMyBusiness:  {id: "qidigo.footer.menu.my_organization", defaultMessage: "Mon entreprise", description: "Link to admin panel."},

	// About section
	sectionAbout:   {id: "qidigo.footer.about.title", defaultMessage: "À propos de Qidigo"},
	aboutTerms:     {id: "qidigo.footer.about.terms", defaultMessage: "Conditions d'utilisation"},
	aboutPrivacy:   {id: "qidigo.footer.about.privacy", defaultMessage: "Politique de confidentialité"},
	aboutSolution:  {id: "qidigo.footer.about.solution", defaultMessage: "Solution pour entreprises"},
	aboutContactUs: {id: "qidigo.footer.about.contact_us", defaultMessage: "Nous joindre"},

	// Social section
	sectionSocial: {id: "qidigo.footer.social.title", defaultMessage: "On est sociable!"},
	socialBlurb:   {id: "qidigo.footer.social.blurb", defaultMessage: "Venez nous voir sur les réseaux sociaux"},

	// Powered by section
	poweredBy :  {id: "qidigo.footer.powered_by", defaultMessage: "Propulsé par"},

	copyright:                {id: "qidigo.footer.copyright", defaultMessage: "© Solutions Nexarts inc. Tous droits réservés."},
});

const LoggedUserMenu = ({loggedUser, Link}) => {
	if (loggedUser["business_owner"]) {
		// Temporairement (forever) pour la transition.
		return (
			<ul>
				<li><a href="/admin/org"><FormattedMessage {...messages.menuMyAccount} /></a></li>
				<li><a href="/admin/org"><FormattedMessage {...messages.menuMyBusiness} /></a></li>
			</ul>
		)
	}
	else {
		return (
			<ul>
				<li><Link to="/dashboard"><FormattedMessage {...messages.menuMyAccount} /></Link></li>
			</ul>
		)
	}
};

LoggedUserMenu.propTypes = {
	loggedUser: PropTypes.object,
	Link: PropTypes.func.isRequired,
};

/**
 * Footer du site.
 * @extends {Component}
 */
class Footer extends Component {

    /**
     * @param {string} value 
     */
    handleLocaleChange = (value) => {
        const { setLocale, loggedUser } = this.context;
        if (loggedUser) {
            Fetch.patch(`users/${loggedUser.id}`, { lang_id: value.toUpperCase() })
                .then(() => {
                    Auth.refreshUser();
                    setLocale(value);
                })
                .catch(Fetch.handleErrors(this));

            return;
        }
        setLocale(value);
    };

	render() {
		const { currentLocale, loggedUser} = this.context;
		const {Link} = this.props;
		let debug = null;

		const socialLinks = [
			/* eslint-disable camelcase */
			{type_url: {name: "Facebook"},  url: "https://www.facebook.com/Qidigo"},
			{type_url: {name: "Instagram"}, url: "https://www.instagram.com/qidigo/"},
			{type_url: {name: "X"},   url: "https://x.com/qidigo_com"},
			{type_url: {name: "Google +"},  url: "https://plus.google.com/b/108956022836478240724/108956022836478240724/"},
			{type_url: {name: "Youtube"},   url: "https://www.youtube.com/channel/UCxDKHh0YYcvtKT6doEdCLvQ"},
			/* eslint-enable camelcase */
		];

		if (process.env.NODE_ENV === "development") {
			debug = <div className="footer--debug">
				<Link to="/debug">[D]</Link>
			</div>;
		}

		const langSwitcher =
			<nav className="footer--section-localization">
				<h1><FormattedMessage {...messages.sectionLocalization} /></h1>
				<Select
					className="footer--locale-select"
					onChange={(e, value) => { this.handleLocaleChange(value); }}
					value={currentLocale}
					options={LANGS.map((pair) => ({
						key: pair[0].toLowerCase(),
						value: pair[1],
					}))}
				/>
			</nav>
		;

		return (
			<footer id="footer"  style={{zIndex: 19}}>
				<div className="container">
					{langSwitcher}
					<nav className="footer--section-menu">
						<h1><FormattedMessage {...messages.sectionMenu} /></h1>
						{
							loggedUser ?
								<LoggedUserMenu loggedUser={loggedUser} Link={Link} /> :
								<ul>
									<li><Link to="/login"><FormattedMessage {...messages.menuLogin} /></Link></li>
									<li><Link to="/register"><FormattedMessage {...messages.menuNewAccount} /></Link></li>
								</ul>
						}
					</nav>
					{langSwitcher}
					<nav className="footer--section-social">
						<h1><FormattedMessage {...messages.sectionSocial} /></h1>
						<p><FormattedMessage {...messages.socialBlurb} /></p>
						<SocialLinks className="footer--social" links={socialLinks} />
					</nav>

					<nav className="footer--section-about">
						<h1><FormattedMessage {...messages.sectionAbout} /></h1>
						<ul>
							<li><Link to="/terms"><FormattedMessage {...messages.aboutTerms} /></Link></li>
							<li><Link to="/privacy"><FormattedMessage {...messages.aboutPrivacy} /></Link></li>
							<li><Link to="https://www2.qidigo.com" target="_blank"><FormattedMessage {...messages.aboutSolution} /></Link></li>
						</ul>
					</nav>
					<nav className="footer--section-powered-by">
						<h1><FormattedMessage {...messages.poweredBy} /></h1>
						<div className="logo-container">
							<Link to="/" className="logo" activeClassName="active">Qidigo</Link>
						</div>
					</nav>
				</div>
				<section className="footer--copyright">
					<p title={`${env.GIT_COMMIT} / ${env.GIT_REVCOUNT}`}>
						<FormattedMessage {...messages.copyright} />
					</p>
				</section>
				{debug}
			</footer>
		);
	}
}
Footer.propTypes = {
	// Pour changer le type des liens quand on plante.
	Link: PropTypes.func.isRequired,
	onChange : PropTypes.func,
};
Footer.contextTypes = {
	currentLocale: PropTypes.string,
	setLocale: PropTypes.func,
	loggedUser: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),
};

export default withRouter(Footer);
