/* global module, require */
/* eslint no-console: [0] */
import boot        from "qidigo-boot";
import React       from "react";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import ENV         from "qidigo-env";
import {Provider}  from "react-redux";
import router      from "qidigo-router";
import ScrollSpy   from "qidigo-scrollspy";
import Fetch       from "qidigo-fetch";

import Routes     from "./routes";
import configureStore from "./store";

import {loadingDispatchers}  from "./signals/loading.js";
import ErrorsController      from "@app/controllers/errors_controller";

// Always import the styles.
// Needed for the extract plugin.
// Needed later on fot the hot reload.
import "../design/index.less";

console.log("→ app/index.js");
function ready(fn) {
	console.log("→ function ready()");
	if (document.readyState === "loading") { document.addEventListener("DOMContentLoaded", fn); }
	else { fn(); }
	console.log("← function ready()");
}

//
// Utilise le wrapper qidigo-boot pour démarrer l'application.
//
ready(() => {
	console.log("→ onReady");
	if (ENV["SENTRY_WEBAPPS_PUBLIC_DSN"]) {
		Sentry.init({
			dsn: ENV["SENTRY_WEBAPPS_PUBLIC_DSN"],
			integrations: [new Integrations.BrowserTracing()],
			environment: process.env.NODE_ENV,
		});

	}
	const store = configureStore();

	boot({
		element: <Provider store={store}>{router(Routes, store)}</Provider>,
		container: document.getElementById("content"),
		ErrorsController,
	});
	Fetch.setLoadingPromise(loadingDispatchers.promisedDequeuer);
	ScrollSpy.start("#header > .container");

	// Disable le zoom sur iOS parce que iOS zoom sur un contrôle
	// qui a le texte plus petit que le default size du body.
	// TODO : Trouver un fix qui désactive pas globalement le zoom sur iOS.
	// (16px par défaut, initial en CSS)
	if (navigator) {
		const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if (iOS) {
			const metas = document.querySelectorAll("head meta[name=viewport]");
			Array.prototype.map.call(metas, (el) => el.parentNode.removeChild(el));
			const meta = document.createElement("meta");
			meta.setAttribute("name", "viewport");
			meta.setAttribute("content", "width=device-width, user-scalable=0, initial-scale=1");
			const head = document.querySelectorAll("head")[0];
			head.insertBefore(meta, head.firstChild);
		}
	}
	console.log("← onReady");
});

//
// Re-chargement des fichiers de style
// ===================================
//
//   * https://github.com/webpack/extract-text-webpack-plugin/issues/30#issuecomment-242451634
//
// Utilisation irrégulière de *webpack*, pour que le bundle CSS soit
// hot-deloadé, il semble y avoir des défauts à ExtracTextPlugin et son
// utilisation avec des images qui ne sont pas embeddées dans le CSS;
// c'est-à-dire que les URLs sont absolues, mais le CSS est chargé via le
// pseudo-protocole `blob:http`, qui empêche le bon chargement des images.
//
if (process.env.NODE_ENV === "development") {
	require("../design/debug.less");
	if (module.hot) {
		console.log("[Qidigo] Refreshing CSS"); // eslint-disable-line
		document.getElementById("css-bundle").href = `/design/styles.css?t=${Date.now()}`;
		module.hot.accept();
	}
}
