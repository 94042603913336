import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Fetch                 from "qidigo-fetch";

import View                  from "@app/views/dashboard/invoice/show.js";
import Loading               from "qidigo-components/loading.js";
import Auth                  from "qidigo-auth";
import QidigoFetch from "../../modules/qidigo-fetch";

/**
 */
class InvoiceController extends Component {
	constructor() {
		super();
		this.state = {
			errors:  false,
			loading: true,
		};
	}

	/**
	 * Charge les groupes au chargement.
	 */
	componentWillMount() {
		this.fetchInvoice();

		// Saves the payload to the state.
		const {location: {query: {payload}}} = this.props;
		this.setState({payload});
		// We could (and will?) also remove the payload from the URL.
		// Otherwise, we'll see this weird bug with the cart, where
		// opening it will make the confirmation header vanish!
	}

	/**
	 * Effectue une promise, en gérant automatiquement les erreurs.
	 */
	autoCatch(promise, fn = null) {
		// Enchaîne la promise...
		return promise
		.then((response) => {
			return response;
		})
	}

	/**
	 */
	fetchInvoice(startDate, endDate) {
		const id = parseInt(this.props.params.invoiceId);
		const userId = Auth.getUserID();

		return Fetch.get(`users/${userId}/invoices/${id}`)
		.then((response) => {
			const invoice = response['invoice'];

			this.setState({
				invoice,
				loading: false,
			});

			return response;
		});
	}

	handlePay() {
		const {id, organization} = this.state.invoice;
		window.location = `/u/${organization.slug}/invoices/${id}/create-a-settlement`;
	}

	handlePrint() {
		window.print();
	}

	get payload() {
		// The location of the payload is an implementation detail.
		// The same data could be placed anywhere stateful.
		const {payload} = this.state;
		if (payload) {
			return JSON.parse(atob(payload));
		}

		return null;
	}

	render() {
		const {
			/* eslint-disable */
			route,
			/* eslint-enable */
			...props
		} = this.props;

		const {
			invoice,
		} = this.state;

		if (this.state.loading) { return <Loading />; }

		return <View
			invoice={invoice}
			handlePay={() => this.handlePay()}
			handlePrint={() => this.handlePrint()}
			payload={this.payload}
			{...props}
		/>;
	}
}

InvoiceController.propTypes = {
	location: PropTypes.object,
	route:  PropTypes.object,
	params: PropTypes.shape({
		id: PropTypes.string,
	}).isRequired,
};

export default InvoiceController;
