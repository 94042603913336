import React, {Component} from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Page from "@app/components/page";
import {defineMessages, FormattedMessage} from "react-intl";
import {navigate} from "qidigo-router";
import {Button} from "../../../../modules/qidigo-form";

const translations = defineMessages({
    "CONTACT_ALREADY_EXISTS": {
        id: "qidigo.contact_request.errors.contact_already_exists",
        defaultMessage: "Vous avez déjà accepté une invitation provenant de {organization_name}."
    },
    "INTERNAL_SERVER_ERROR": {
        id: "qidigo.contact_request.error",
        defaultMessage: "Une erreur est survenue. Veuillez réessayer plus tard."
    },
    "INVALID_CONTACT_TOKEN": {
        id: "qidigo.contact_request.errors.invalid_contact_token",
        defaultMessage: "L'invitation est invalide."
    },
    "CONTACT_TOKEN_EXPIRED": {
        id: "qidigo.contact_request.errors.contact_token_expired",
        defaultMessage: "L'invitation est expirée. Veuillez contacter {organization_name} afin d'en recevoir une autre."
    },
    "CONTACT_TOKEN_NOT_FOUND": {
        id: "qidigo.contact_request.errors.contact_token_not_found",
        defaultMessage: "L'invitation n'existe pas."
    },
    "organization_page": {
        id: "qidigo.contact_request.organization_page",
        defaultMessage: "Voir {organization_name}"
    },
    "page_title": {
        id: "qidigo.contact_request.page_title",
        defaultMessage: "Confirmation de l'invitation"
    },
    "SUCCESS": {
        id: "qidigo.contact_request.success",
        defaultMessage: "Invitation acceptée avec succès. Vous partagez maintenant les informations de votre compte Qidigo avec {organization_name}."
    },
});

class ContactConfirmationPage extends Component {
    goToOrganizationPage() {
        navigate('/u/' + this.props.organizationSlug)
    }

    render() {
        let message = '';
        if (this.props.message) {
            message = <FormattedMessage
                {...translations[this.props.message]}
            />
        }

        if (this.props.organizationName) {
            message = <FormattedMessage
                {...translations[this.props.message]} values={{organization_name: this.props.organizationName}}
            />
        }

        return (
            <Page className={'page-contact-confirmation'}>
                <Helmet>
                    <FormattedMessage {...translations["page_title"]}/>
                </Helmet>
                <div className="contact-confirmation-box">
                    <h1 className="box-title"><FormattedMessage {...translations["page_title"]}/></h1>
                    <p className={this.props.contactAdded ? "box-success" : "box-error"}>
                        {message}
                    </p>
                    {
                        this.props.organizationName &&
                        <Button
                            className="button"
                            onClick={(e) => this.goToOrganizationPage()}
                        >
                            <FormattedMessage
                                {...translations["organization_page"]}
                                values={{organization_name: this.props.organizationName}}
                            />
                        </Button>
                    }
                </div>
            </Page>
        );
    }
}

ContactConfirmationPage.propTypes = {
    token: PropTypes.string,
    contactAdded: PropTypes.bool,
    message: PropTypes.string,
    loading: PropTypes.bool,
    organizationName: PropTypes.string,
    organizationSlug: PropTypes.string
};

export default ContactConfirmationPage;
