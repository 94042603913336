import {Component}        from "react";
import QidigoSessionStore from "qidigo-sessionstore";
import Auth               from "qidigo-auth";
import {navigate}         from "qidigo-router";

const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");

/**
 * Page de déconnexion, permettant surtout de tricher et recharger
 * l'état de l'application à la déconnexion.
 *
 * @extends {Component}
 */
class LogoutPage extends Component {
	componentDidMount() {
		let url = "/";
		const lastOrg = lastOrgStore.get();
		if (lastOrg && lastOrg["slug"]) {
			url = `/u/${lastOrg["slug"]}`;
		}

		Auth.logout()
			.then(()=>navigate(url));
	}
	render() {
		return null;
	}
}

export default LogoutPage;

