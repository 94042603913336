import React from "react";
import PropTypes from "prop-types";
import currency from "@app/lib/currency";
import {getLocale} from "qidigo-i18n";

const ENGLISH = 'en';

const internalFormatMoney = (value, locale) =>
	(// Using the right locale...
		currency[locale ? locale : getLocale()] || currency["_default"]
	)(parseFloat(value))      // Use the given number~ish.
		.format()             // Format it.
		.replace(" ", "\xa0") // Ensures it won't be wrapped hapazardly.
;

const formatMoney = (...args) => internalFormatMoney(...args) + "\xa0$";

const Money = ({value: in_value, withSymbol, accountingFormat}, {intl: {locale}}) => {

    const option = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    if (withSymbol) {
        option.style = 'currency';
        option.currency = 'CAD';
    }

    const lang = (locale ? locale : getLocale());
    const langCountry = lang + '-CA';
    let formatDisplay = Intl.NumberFormat(langCountry, option).format(in_value)

    if (accountingFormat && amount < 0) {
        const numberFormattedToLang = Intl.NumberFormat(langCountry, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(Math.abs(amount));

        formatDisplay = '(' + numberFormattedToLang + ')';

        if (withSymbol) {
            formatDisplay = lang === ENGLISH ? ('$' + formatDisplay) : (formatDisplay + '$')
        }
    }

    return (
        <span className="money-amount">
            {formatDisplay}
        </span>
    );
};

Money.defaultProps = {
    children: null,
    withSymbol: true,
    accountingFormat: false
};

Money.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // Required if not given in children.
    withSymbol: PropTypes.bool,
};
Money.contextTypes = {
    intl: PropTypes.object,
};

export default Money;
export {formatMoney};
