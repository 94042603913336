import React, {Component}    from "react";
import PropTypes             from "prop-types";
import
	ActivityFragment,
	{activityShape}
from "./activityfragment.js";

/**
 * Listing d'activités
 *
 * La gestion de la recherche et des paramètres *doit être faite à l'extérieur*,
 * c'est seulement le rendu visuel du listing.
 *
 * @extends {Component}
 */
class ActivitiesList extends Component {
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		const {currentLocale} = this.context;

		if (currentLocale !== nextContext.currentLocale) {
			return true;
		}
		if (this.props.activities !== nextProps.activities) {
			return true;
		}
		if (this.props.activities.length !== nextProps.activities.length) {
			return true;
		}

		return false;
	}

	render() {
		const activities = this.props.activities;
		const list = activities.map((activity) =>
			<ActivityFragment
				key={activity.id}
				activity={activity}
			/>
		);

		return (
			<div className="activitieslist">
				{this.props.children}
				<ul className="activitieslist--list">
					{list}
				</ul>
			</div>
		);
	}
}

ActivitiesList.propTypes = {
	activities: PropTypes.arrayOf(activityShape).isRequired,
	without:    PropTypes.object,
};

ActivitiesList.contextTypes = {
	currentLocale: PropTypes.string,
};

export default ActivitiesList;
