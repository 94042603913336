import React, {Component} from "react";
import PropTypes from "prop-types";
import DateInput from "qidigo-form/dateinput";

/**
 * Template for a question of type date.
 */

class DateTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;

		let {date=""} = answer || {};
		date = date.replace(/\//g, "-");

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<DateInput error={errors["date"]} value={date} onChange={(...e)=>this.handleChange("date", ...e)}  />
				</div>
			</div>
		);
	}
}

DateTypeForm.defaultProps = {
	className: "",
	errorMessage: "",
};

DateTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	errors: PropTypes.object,
};

export default DateTypeForm;
