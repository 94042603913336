import React, {Component} from "react";
import PropTypes             from "prop-types";

/**
 * Component générique de radio.
 *
 * C'est attendu que le bouton radio soit utilisé avec un <RadioGroup> et sur
 * celui-ci on récupère la valeur.
 *
 * Il faut faire attention à bien synchroniser la propriété `checked`,
 * autrement ça semble brisé!
 *
 * @extends {Component}
 */
class Radio extends Component {
	constructor() {
		super();
		this.state = {
			checked: false,
		};
	}
	handleChange(event) {
		let originalTarget = event.target;
		let value = event.target.value;

		// Temporairement, pour le handler.
		// Pourrait être un Event custom aussi.
		event.target = this;
		if (this.props.onChange) {
			this.props.onChange(event, value);
		}

		// On remet le bon target.
		event.target = originalTarget;
		// Il n'y a pas de comportement par défut pour le Radio
		// C'est trop complexe à gérer autrement.
		// if (!event.defaultPrevented) {
		// }
	}

	componentWillReceiveProps(newProps) {
		if (this.props.checked !== newProps.checked) {
			this.setState({checked: newProps.checked});
		}
	}

	componentWillMount() {
		this.setState({checked: this.props.checked || this.state.checked || false});
	}

	render() {
		let {
			children,
			// On utilise onChange dans notre API
			// Mais il ne faut pas l'attacher!!
			onChange, // eslint-disable-line
			className, label, role, ...leftoverProps
		} = this.props;

		// Voir: https://facebook.github.io/react/docs/forms.html#potential-issues-with-checkboxes-and-radio-buttons

		// Classes appliquées au wrapper.
		let wrapperClasses = ["radio", className];
		// Classes pour le widget du formulaire.

		if (this.state.checked) {
			wrapperClasses.push("is-checked");
		}
		else {
			wrapperClasses.push("is-not-checked");
		}

		if (this.props.disabled) {
			wrapperClasses.push("is-disabled");
		}

		return (
			<div className={wrapperClasses.join(" ")}>
				<label>
					<input
						placeholder=""
						{...leftoverProps}
						ref="radio"
						type="radio"
						role={role}
						className="radio--input"
						checked={this.state.checked}
						onClick={(e)=>this.handleChange(e)}
						onChange={(e)=>{}}
					/>
					<span className="radio--radio radio--fake" />
					<span className="radio--label-text">{label}</span>
					{children}
				</label>
			</div>
		);
	}
}

Radio.propTypes = {
	onChange:           PropTypes.func.isRequired,
	className:          PropTypes.string,
	checked:            PropTypes.bool,
	label:              PropTypes.string,
	value:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	role:               PropTypes.string,
	disabled:           PropTypes.bool,
};

Radio.defaultProps = {
	role:               "radio",
};

export default Radio;

