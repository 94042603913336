import React, {Component}    from "react";
import Fetch                 from "qidigo-fetch";
import View                  from "@app/views/forgot/forgot.js";

/**
 * @extends {Component}
 */
class ForgotController extends Component {
	constructor() {
		super();
		this.state = {
			// True whilst the request is pending.
			saving: false,
			// True when a request was sent entirely.
			success: false,
			errors: null,
			// Form
			infos: {
				email: "",
			},
		};
	}

	onValidationError(errors) {
		this.setState({errors});
	}

	onChange(field, e, value) {
		const infos = this.state.infos;
		infos[field] = value;
		this.setState({infos});
	}

	onSubmit(e) {
		this.setState({success: false});
		this.setState({saving: true, errors: false});

		const email = this.state.infos.email;
		const user = {email};

		Fetch.post("authenticate/password_reset", {user})
		.then((result) => {
			this.setState({saving: false});
			this.setState({success: true});

			return result;
		})
		.catch((errors) => {
			this.setState({saving: false});
			this.setState({errors: {
				message: errors.message,
				fields: errors.fields,
			}});

			return null;
		})
		;
	}

	render() {
		const {
			errors,
			saving,
			infos,
			success,
		} = this.state;

		return <View
			onSubmit={(...e)=>this.onSubmit(...e)}
			onChange={(...e)=>this.onChange(...e)}
			onValidationError={(...e)=>this.onValidationError(...e)}
			values={infos}
			saving={saving}
			errors={errors}
			success={success}
		/>;
	}
}

export default ForgotController;
