import * as React from "react";
import {Component} from "react";
import {defineMessages, FormattedMessage} from "react-intl";

// @ts-ignore
import Input from "qidigo-form/input";
// @ts-ignore
import PropTypes from "prop-types";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Auth from "qidigo-auth";
// @ts-ignore
import {navigate} from "qidigo-router";
// @ts-ignore
import Loading from 'qidigo-components/loading';
import SeparationLine from "../SeparationLine";
import {ISession} from "../../types";
// @ts-ignore
import Separator             from "qidigo-components/separator";
import {Link}                from "react-router/es";


const PASS_WORD_MIN_LENGTH = 8;

const translations = defineMessages({
    "with": {
        id: "qidigo.social_login.with",
        defaultMessage: "À partir de mon compte {name}"
    },
    "forgot_password": {
        id: "qidigo.login.partial.forgot",
        defaultMessage: "Mot de passe oublié"
    },
    "remember": {
        id: "qidigo.login.partial.remember",
        defaultMessage: "Se souvenir de moi"
    },
    "email": {
        id: "qidigo.activity.pass_selection_modal.connection.email",
        defaultMessage: "Courriel"
    },
    "email_confirmation": {
        id: "qidigo.activity.pass_selection_modal.connection.email_confirmation",
        defaultMessage: "Confirmation du courriel"
    },
    "password": {
        id: "qidigo.activity.pass_selection_modal.connection.password",
        defaultMessage: "Mot de passe"
    },
    "password_confirmation": {
        id: "qidigo.activity.pass_selection_modal.connection.password_confirmation",
        defaultMessage: "Confirmation du mot de passe"
    },
    "create": {
        id: "qidigo.activity.pass_selection_modal.connection.create",
        defaultMessage: "Créer"
    },
    "login": {
        id: "qidigo.activity.pass_selection_modal.connection.login",
        defaultMessage: "Connexion"
    },
    "privacy":   {
        id: "qidigo.register.privacy",
        defaultMessage: "Politique de confidentialité"
    },
    "terms":     {
        id: "qidigo.register.terms",
        defaultMessage: "Conditions d'utilisation"
    },
    "legaleses": {
        id: "qidigo.register.legaleses",
        defaultMessage: "En vous inscrivant, vous acceptez les {terms} et la {privacy}."
    },
})

const errorMessages = defineMessages({
    "other_error": {
        id: "qidigo.pass_selection_modal.connection.errors.other_error",
        defaultMessage: "Une erreur inattendue est survenue. Veuillez réessayer sous peu."
    },
    "email_confirmation_error": {
        id: "qidigo.pass_selection_modal.connection.errors.email_confirmation_error",
        defaultMessage: "Les courriels entrés doivent être identiques."
    },
    "password_confirmation_error": {
        id: "qidigo.pass_selection_modal.connection.errors.password_confirmation_error",
        defaultMessage: "Les mots de passes entrés doivent être identiques."
    },
    "password_length_error" : {
        id: "qidigo.pass_selection_modal.connection.errors.password_length_error",
        defaultMessage: "Le mot de passe doit comporter au minimum 8 caractères."
    }
});


interface IRegisterProps {
    handleNextStep: () => void;
    goToLogin: () => void;
    session: ISession;
}

interface IRegisterState {
    loading: boolean;
    errors: Array<string>;
    hasErrors: boolean;
    session: ISession;
    user: {
        email: string,
        password: string,
        emailConfirmation: string,
        passwordConfirmation: string
    }
}

class Register extends Component<IRegisterProps, IRegisterState> {
    private form: any;

    constructor(props: IRegisterProps) {
        super(props);

        this.state = {
            loading: false,
            hasErrors: false,
            errors: [],
            user: {
                email: '',
                password: '',
                emailConfirmation: '',
                passwordConfirmation: '',
            },
            session: this.props.session
        }
    }

    onRegisterSubmit = (e) => {
        e.preventDefault();

        this.setState({loading: true, hasErrors: false});

        const email = e.target.email.value;
        const password = e.target.password.value;
        const emailConfirmation = e.target.email_confirmation.value;
        const passwordConfirmation = e.target.password_confirmation.value;

        Auth.login(email, password, {remember: true})
            .then((token) => {
                this.setState({loading: false});
                this.props.handleNextStep();
                return token;
            })
            .catch((id) => {
                if (id === "invalid_user") {
                    if (this.validateForm(email, password, emailConfirmation, passwordConfirmation)) {
                        this.state.user.email = email;
                        this.state.user.password = password;
                        const {state} = this;
                        document.body.classList.remove('scroll-disabled')

                        navigate("/registration/info", {
                            // @ts-ignore
                            state
                        });
                    }

                    return false;
                }

                return Promise.reject(id);
            })
            .catch((error) => {
                this.setState({loading: false, hasErrors: true, errors: [error]});
            })
        ;
    }

    validateForm = (email, password, emailConfirmation, passwordConfirmation) => {
        let errors : Array<string> = [];
        if (email !== emailConfirmation) {
            errors.push("email_confirmation_error");
        }

        if (password !== passwordConfirmation) {
            errors.push("password_confirmation_error");
        }

        if (password.length < PASS_WORD_MIN_LENGTH) {
            errors.push("password_length_error");
        }

        this.setState({
            errors: errors,
            hasErrors: errors.length > 0,
            loading: false,
            user: {
                email: email,
                password: password,
                emailConfirmation: emailConfirmation,
                passwordConfirmation: passwordConfirmation
            }
        })

        return errors.length === 0;
    }

    onEmailChange = (e) => {
        this.state.user.email = e.value

        this.setState({
            user: this.state.user
        })
    }

    onPasswordChange = (e) => {
        this.state.user.password = e.value

        this.setState({
            user: this.state.user
        })
    }

    onEmailConfirmationChange = (e) => {
        this.state.user.emailConfirmation = e.value

        this.setState({
            user: this.state.user
        })
    }

    onPasswordConfirmationChange = (e) => {
        this.state.user.passwordConfirmation = e.value

        this.setState({
            user: this.state.user
        })
    }


    render() {
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        let wrapperClasses = ["fragment-emailpassword"];

        return (
            this.state.loading
                ? <Loading/>
                :
                <form
                    className={wrapperClasses.join(" ")}
                    onSubmit={this.onRegisterSubmit}
                    ref={(node) => this.form = node}
                >
                    <div className={"pass-selection-login--form-container"}>
                        {
                            this.state.hasErrors &&
                            <div className={"pass-selection-modal--error-box"}>
                                {this.state.errors.map((error, key) =>
                                    <li>
                                        {formatMessage(errorMessages[error])}
                                    </li>
                                )}
                            </div>
                        }
                        <Input
                            name="email"
                            label={formatMessage(translations["email"])}
                            type="email"
                            required={true}
                            autoFocus={true}
                            value={this.state.user.email}
                            onChange={this.onEmailChange}
                        />
                        <Input
                            name="email_confirmation"
                            label={formatMessage(translations["email_confirmation"])}
                            type="email"
                            required={true}
                            autoFocus={true}
                            value={this.state.user.emailConfirmation}
                            onChange={this.onEmailConfirmationChange}
                        />
                        <Input
                            name="password"
                            label={formatMessage(translations["password"])}
                            type="password"
                            required={true}
                            value={this.state.user.password}
                            onChange={this.onPasswordChange}
                        />
                        <Input
                            name="password_confirmation"
                            label={formatMessage(translations["password_confirmation"])}
                            type="password"
                            required={true}
                            value={this.state.user.passwordConfirmation}
                            onChange={this.onPasswordConfirmationChange}

                        />
                    </div>
                    <div className={"pass-selection-login--buttons-container"}>
                        <Button className={"pass-selection-modal--primary-button"} type="submit">
                            <FormattedMessage {...translations['create']}/>
                        </Button>
                        <SeparationLine isVertical={false}/>
                        <Button onClick={this.props.goToLogin} className={"pass-selection-modal--secondary-button"}>
                            <FormattedMessage {...translations['login']}/>
                        </Button>
                    </div>
                    <footer>
                        <Separator />
                        <FormattedMessage {...translations['legaleses']}
                                          values={{
                                              terms: <Link target='_blank' to="/terms"><FormattedMessage {...translations["terms"]} /></Link>,
                                              privacy: <Link target='_blank' to="/privacy"><FormattedMessage {...translations["privacy"]} /></Link>,
                                          }}
                        />
                    </footer>
                </form>
        );
    }
}

// @ts-ignore
Register.contextTypes = {
    intl: PropTypes.object,
};

export default Register;
