import React                 from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";
import Form                  from "qidigo-form/form";
import {mapOptionsTranslations} from "../../../../../modules/qidigo-i18n";
import GENDERS from "../../../modules/qidigo-data/genders";

const fields = defineMessages({
	firstName: {id: "qidigo.dashboard.family_member.first_name", defaultMessage: "Prénom"},
	lastName: {id: "qidigo.dashboard.family_member.last_name", defaultMessage: "Nom de famille"},
	birthday: {id: "qidigo.dashboard.family_member.birthday", defaultMessage: "Date de naissance"},
	female: {id: "qidigo.dashboard.family_member.female", defaultMessage: "Femme"},
	male: {id: "qidigo.dashboard.family_member.male", defaultMessage: "Homme"},
	gender: {id: "qidigo.dashboard.family_member.gender", defaultMessage: "Genre"},
});


const FamilyMemberPartial = (
	{
		onSubmit,
		onChange,
		onValidationError,
		errors,
		familyMember,
		disabled,
		children,
	},
	{intl: {formatMessage}}
) => {
	familyMember['sex'] = familyMember['sex'] ? familyMember['sex'] : 'N';

	return (
		<Form
			onSubmit={(...e) => onSubmit(...e)}
			onChange={(...e) => onChange(...e)}
			onValidationError={(...e) => onValidationError(...e)}
			errors={errors}
			values={familyMember}
			disabled={disabled}
			autoFocus={true}
		>
			<Form.Row>
				<Form.Input name="first_name"
					required={true}
					label={formatMessage(fields.firstName)}
				/>
				<Form.Input name="last_name"
					required={true}
					label={formatMessage(fields.lastName)}
				/>
			</Form.Row>
			<Form.DateInput name="birthday"
				required={true}
				label={formatMessage(fields.birthday)}
			/>
			<Form.Select name="sex"
				 label={formatMessage(fields.gender)}
				 externalLabel={formatMessage(fields.gender)}
				 className={"gender-select"}
				 required={true}
				 options={mapOptionsTranslations(formatMessage, GENDERS)}
			/>
			{children}
		</Form>
	);
}

FamilyMemberPartial.propTypes = {
	// FIXME : Shape it
	familyMember: PropTypes.object,

	disabled: PropTypes.bool,

	errors: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),

	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	onValidationError: PropTypes.func,
};

FamilyMemberPartial.contextTypes = {
	intl: PropTypes.object,
};

export default FamilyMemberPartial;
