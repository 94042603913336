import moment                from "moment";
import Dates                 from "react-big-calendar/lib/utils/dates";
import formats               from "react-big-calendar/lib/formats";
import {getLocale}           from "qidigo-i18n"; 

/**
 * Formats de dates sélectionnés.
 *
 * Format des noms:
 *  [short.]type[.spécifique]
 */
const localizedPatterns = {
	"weekday": {
		"default": "ll",
	},
	"short.weekday": {
		"default": "ll",
		"fr": "dddd Do",
		"en": "ddd Do",
	},
	"event.time": {
		"default": "LT",
	},
	"header": {
		"default": formats()["dayRangeHeaderFormat"],
		"fr": ({start, end}, culture, local) => {
			return [
				local.format(start, Dates.eq(start, end, "month") ? "Do" : "Do MMMM", culture),
				local.format(end, "Do MMMM YYYY", culture)
			].join(" au ");
		},
	}
};

const localizeDate = (patternName, date) => {
	let patt = localizedPatterns[patternName][getLocale()];
	if (!patt) { patt = localizedPatterns[patternName]["default"]; }

	return moment(date).format(patt);
};

export {localizeDate};
export {localizedPatterns};
