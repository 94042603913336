import EmptyList from "../../../app/components/empty_list";
import {path} from "../../../app/lib/routes";
import * as PropTypes from "prop-types";
import Button from "../../../../modules/qidigo-components/button";
// @ts-ignore
import Loading from "../../../../modules/qidigo-components/loading.js";
// @ts-ignore
// @ts-ignore
import ButtonsBar from "../../../../modules/qidigo-form/buttons_bar";
// @ts-ignore
import {navigate} from "qidigo-router";
import * as React from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import Title from "./Title";
import SubscribeToOrganizationConfirmationModal from "../organization/_personal_information_consent";

import {Group, IOffer, FamilyMember, IActivity, IOrganization} from '../../models'
import {useEffect, useMemo} from "react";
import {connect} from "react-redux";
// @ts-ignore
import {openCart} from "@app/store/actions/cart";
import {GroupFragment} from './GroupFragment';
import {getRestrictions} from "./utils";
import {AlertContext} from "../AlertContextProvider";
import Collapse from 'react-collapse'

const translations = defineMessages({
    "all_items_added": {
        id: "qidigo.activity.groups.page.all_items_added",
        defaultMessage: "Tous les articles ont été ajoutés avec succès à votre panier"
    },
    "already_on_waiting_list": {
        id: "qidigo.activity.groups.page.title.is.in.pending.list",
        defaultMessage: "Déjà dans la liste d'attente"
    },
    "register": {
        id: "qidigo.activity.groups.page.register",
        defaultMessage: "Inscrire"
    },
    "page.title.pending.list": {
        id: "qidigo.activity.groups.page.title.pending.list",
        defaultMessage: "Le membre sera inscrit sur les listes d'attente suivantes"
    },
    "page.title.plan.list": {
        id: "qidigo.activity.groups.page.title.liste.offers",
        defaultMessage: "Choix des offres pour "
    },
    "page.sammary": {
        id: "qidigo.activity.groups.page.sammary",
        defaultMessage: "Récapitulatif pour "
    },
    view_more: {
        id: "qidigo.activity.groups.view_more",
        defaultMessage: "En savoir plus"
    },
    view: {
        id: "qidigo.activity.groups.view",
        defaultMessage: "Voir le groupe",
        description: "Message pour l'accessibilité du lien"
    },
    "selectionner.offer": {
        id: "qidigo.activity.groups.selectionner.offer",
        defaultMessage: "Veuillez sélectionner une offre"
    },
    "informations.activity": {
        id: "qidigo.activity.groups.informations.activity",
        defaultMessage: "Activité"
    },
    "informations.schedule": {
        id: "qidigo.activity.groups.informations.schedule",
        defaultMessage: "Plage horaire"
    },
    "informations.duration": {
        id: "qidigo.activity.groups.informations.duration",
        defaultMessage: "Durée"
    },
    adding: {
        id: "qidigo.activity.groups.offers.adding",
        defaultMessage: "Ajout en cours..."
    },
    "informations.remaining_places": {
        id: "qidigo.activity.groups.informations.remaining_places",
        defaultMessage: "Places restantes"
    },
    "restriction.gender": {
        id: "qidigo.activity.groups.restriction.gender",
        defaultMessage: "Genre"
    },
    "restriction.age": {
        id: "qidigo.activity.groups.restriction.age",
        defaultMessage: "Âge"
    },
    "btn.addToWaitingList": {
        id: "qidigo.group.offerform.addToWaitingList",
        defaultMessage: "Ajouter à la liste d'attente"
    },
    remove: {
        id: "qidigo.activity.groups.remove",
        defaultMessage: "Retirer l'article"
    },
    "select.plan": {
        id: "qidigo.activity.select.plan",
        defaultMessage: "Choisir une offre..."
    },
    "now_on_waiting_list": {
        id: "qidigo.activity.groups.page.now_on_waiting_list",
        defaultMessage: "Vous êtes inscrit sur la liste!"
    },
    "error": {
        id: "qidigo.activity.groups.page.error",
        defaultMessage: "Something went wrong and we cannot add this item to the cart at this moment"
    },
});

interface IActivityGroupsListProps {
    activity: IActivity
    organization: IOrganization
    groups: Group[]
    offers: IOffer[][]
    members: Record<string, FamilyMember>
    selectedOffer: Record<string, number>
    handleSelectPlan: (id: string, shouldUpdatePrice: boolean) => void,
    handleAddToCartForAll: () => void
    disabled: boolean
    onDelete: (groupId: number, memberId: number) => void
    newStatus: Record<number, string>
    handlePreviousStep: () => void
    groupsOffers: unknown
    member: number
    refreshOptions: () => void
    selectedGroups: string[]
    isLoading: boolean
    hasRestrictedSelection: boolean
    onAddToWaitingList: (planId: number, memberId: number) => void
    onAddToWaitingListWithoutConsent: (planId: number, memberId: number) => void
    openCart: () => void
    cartItems: { plan: { id: number }, subscriber: { id: number } }[] | undefined
    changeAlertMessage: (message: string) => void
}

const ActivityGroupsList: React.FC<IActivityGroupsListProps> = (props, context) => {
    const {
        activity,
        organization,
        groups,
        offers,
        members,
        selectedOffer,
        handleSelectPlan,
        onDelete,
        groupsOffers,
        member,
        refreshOptions,
        onAddToWaitingList,
        onAddToWaitingListWithoutConsent,
        openCart,
        cartItems,
        selectedGroups,
        hasRestrictedSelection
    } = props;
    const [shouldOpenCart, setShouldOpenCart] = React.useState(true);
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const {hasContact} = context;
    const [hasConsent, setHasConsent] = React.useState(hasContact(organization.id));

    if (offers.length == 0) {
        return <Loading />;
    }

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);
    };

    const {formatMessage} = context.intl;
    const memberAffiche = members[member]
        ? members[member].full_name
        : '';

    if (activity.registration_template == "simple") {
        navigate(path("activity.session", {orgID: activity.organization.slug, activityID: activity.id}));
    }

    const groupsWithWaitingList = groups
        .filter(getFilterFunction(selectedGroups))
        .filter(group => {
            const restrictions = getRestrictions({
                group: group,
                offers: offers,
                members: members,
                member: member,
                selectedOffer: selectedOffer[group.id]
            })

            const restrictionGroupFull = restrictions ? restrictions.product_restrictions.find((restriction) => restriction.code === "GROUP_IS_FULL") : undefined;
            return (group.nbr_places_remaining != null && group.nbr_places_remaining <= 0) || restrictionGroupFull;
        });

    const groupsWithFreePlaces = groups
        .filter(getFilterFunction(selectedGroups))
        .filter(group => {
            const restrictions = getRestrictions({
                group: group,
                offers: offers,
                members: members,
                member: member,
                selectedOffer: selectedOffer[group.id]
            })

            const restrictionGroupFull = restrictions ? restrictions.product_restrictions.find((restriction) => restriction.code === "GROUP_IS_FULL") : undefined;
            return !restrictionGroupFull && (group.nbr_places_remaining === null || group.nbr_places_remaining > 0)
        });

    const collapsedClass = isCollapsed
        ? "is-collapsed" : "is-not-collapsed";

    return (
        <div>
            <div className="activity-groups-list">
                <div className={"activity-groups-list--subscriber-header"} onClick={toggleCollapse}>
                    <div
                        className={`activity-groups-list--subscriber-header-triangle ${collapsedClass}`}
                    />
                    <h2>
                        {memberAffiche}
                    </h2>
                </div>
                {
                    <Collapse isOpened={!isCollapsed}>
                        <div className="activity-groups-list--container">
                            <EmptyList className={"activity-groups-list--group-list"}
                                       text={<FormattedMessage {...translations["all_items_added"]} />}>
                                {
                                    groupsWithWaitingList.length > 0 &&
                                    <>
                                        <EmptyList className={"activity-groups-list--group-list"}>
                                            {
                                                groupsWithWaitingList.map((group, key) => {
                                                    const theKey = key + groups.length

                                                    return (
                                                        <GroupFragment
                                                            {...props}
                                                            key={theKey}
                                                            onAddToWaitingList={hasConsent ? onAddToWaitingList : onAddToWaitingListWithoutConsent}
                                                            type={"pendingList"}
                                                            group={group}
                                                            offers={offers}
                                                            members={members}
                                                            handleSelectPlan={handleSelectPlan}
                                                            selectedOffer={selectedOffer}
                                                            onDelete={onDelete}
                                                            refreshOptions={refreshOptions}
                                                        />
                                                    )
                                                })
                                            }
                                        </EmptyList>
                                    </>
                                }
                                {
                                    groupsWithFreePlaces.length > 0 &&
                                    <>
                                        <EmptyList className={"activity-groups-list--group-list"}>
                                            {
                                                groupsWithFreePlaces.map((group, key) => {
                                                    const theKey = key + groups.length

                                                    return (
                                                        <GroupFragment
                                                            {...props}
                                                            key={theKey}
                                                            onAddToWaitingList={hasConsent ? onAddToWaitingList : onAddToWaitingListWithoutConsent}
                                                            type={"offerList"}
                                                            group={group}
                                                            offers={offers}
                                                            members={members}
                                                            handleSelectPlan={handleSelectPlan}
                                                            selectedOffer={selectedOffer}
                                                            onDelete={onDelete}
                                                            refreshOptions={refreshOptions}
                                                        />
                                                    )
                                                })
                                            }
                                        </EmptyList>
                                    </>
                                }
                            </EmptyList>
                        </div>
                    </Collapse>
                }
            </div>
        </div>
    );
};


ActivityGroupsList.propTypes = {
    member: PropTypes.number.isRequired,
};

ActivityGroupsList.contextTypes = {
    intl: PropTypes.object,
    hasContact: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        cartItems: state.cart.items,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        openCart: () => dispatch(openCart()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityGroupsList);


const getFilterFunction = (selected: string[]) => {
    return group => {
        let verifySelectedGroup = false;
        selected.forEach(element => {
            if (group.id === Number(element)) {
                verifySelectedGroup = true;
            }
        });

        return verifySelectedGroup
    }
}
