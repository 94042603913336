import * as React from "react";
import {Component} from "react";
import Line from "./line";
import {ILine, ISubscriber, ISummary} from "../types";
import {defineMessages, FormattedMessage} from "react-intl";
import EmptyList from "../../../components/empty_list";
// @ts-ignore
import Money from "@app/components/money";
// @ts-ignore
import PropTypes from "prop-types";
import Collapse from 'react-collapse'
// @ts-ignore
import Loading from "qidigo-components/loading.js";

const translations = defineMessages({
    "money": {
        id: "qidigo.billing.line.money",
        defaultMessage: "{money} $"
    }
});

const MAX_ITEMS_DEFAULT_COLLAPSE = 3

interface IUserLineProps {
    summary: ISummary,
    reload: (couponCode: string | null, showCouponApplication: boolean) => void,
    removeLine: (lineId: number) => void,
    handleItemIsBeingDeleted: (isBeingDeleted: boolean) => void
    subscriber: ISubscriber,
    isLoading: boolean,
    canRemoveLine: boolean,
    invoiceLabel: string
    isContract: boolean
}

interface IUserLineState {
    lineCollapsed
        : boolean,
    userLines: ILine[],
    userTotal: number
}

class UserLine extends Component<IUserLineProps, IUserLineState> {
    constructor(props: IUserLineProps) {
        super(props);
        const userLines = this.getUserLines();

        this.state = {
            lineCollapsed: userLines.length >= MAX_ITEMS_DEFAULT_COLLAPSE && !this.props.isContract,
            userLines: userLines,
            userTotal: this.getUserSubTotal(userLines)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.summary !== this.props.summary) {
            this.recomputeUserLines();
        }
    }

    getUserLines() {
        return this.props.summary.lines.filter(
            line => line.subscriber.id === this.props.subscriber.id
        )
    }

    getUserSubTotal(userLines) {
        return userLines.reduce((total, line) => {
            return total + line.product.price_before_taxes;
        }, 0)
    }

    recomputeUserLines() {
        const userLines = this.getUserLines();

        this.setState({
            userLines: userLines,
            userTotal: this.getUserSubTotal(userLines)
        });
    }

    handleToggle = (e) => {
        this.setState({
            lineCollapsed: !this.state.lineCollapsed
        });
    }

    render() {
        const couponCode = this.props.summary.applied_coupon
            ? this.props.summary.applied_coupon.code
            : null;

        const collapsedClass = this.state.lineCollapsed
            ? "is-collapsed" : "is-not-collapsed";

        return (
            <div className={"user-line"}>
                <div className="user-line--header" onClick={this.handleToggle}>
                    <div
                        className={`user-line--triangle  ${collapsedClass}` }
                    />
                    <h3>
                        {
                            this.props.isContract
                                ? this.props.invoiceLabel
                                : this.props.subscriber.full_name
                        } ({this.state.userLines.length})
                    </h3>
                    <div className="user-line--product-price">
                        {
                            this.state.lineCollapsed
                            &&
                                <FormattedMessage
                                    {...translations["money"]}
                                    values={{
                                        money: <Money
                                            value={this.state.userTotal}
                                            withSymbol={false}
                                        />
                                    }}
                                />
                        }
                    </div>
                </div>
                <Collapse isOpened={!this.state.lineCollapsed
                }>
                    <div className="user-line--lines-container">
                        <EmptyList>
                        {
                            this.state.userLines.map((line) =>
                                <Line
                                    key={line.id}
                                    line={line}
                                    product={line.product}
                                    reload={() => this.props.reload(couponCode, false)}
                                    removeLine={() => this.props.removeLine(line.id)}
                                    handleItemIsBeingDeleted={this.props.handleItemIsBeingDeleted}
                                    canRemoveLine={this.props.canRemoveLine && !this.props.isLoading}
                                    isLoading={this.props.isLoading}
                                />
                            )
                        }
                        </EmptyList>
                    </div>
                </Collapse>
            </div>
        );
    }
}

// @ts-ignore
UserLine.contextTypes = {
    intl: PropTypes.object,
};

export default UserLine;
