import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import reject                from "lodash/reject";

import Empty                 from "./empty";

const translations = defineMessages({
	nothing:  {id: "qidigo.empty.list.nothing", defaultMessage: "Il n'y a rien à afficher"},
});

/**
 * Présente un <ul> qui sera automagiquement remplacé par un texte
 * lorsque la liste est vide.
 *
 * S'il n'y a pas de texte donné, un *default tout usage* sera utilisé.
 */
const EmptyList = (originalProps) => {
	const {children, className, baseClass, text, tag: Tag, ...props} = originalProps
	if (!children || React.Children.count(children) === 0 || React.Children.toArray(children).length === 0) {
		if (text) {
			return (
				<Empty className={`${className} is-empty ${baseClass}`} {...props}>
					{text}
				</Empty>
			);
		}
		return (
			<Empty className={`${className} is-empty ${baseClass}`} {...props}>
				<FormattedMessage {...translations["nothing"]} />
			</Empty>
		);
	}

	if (Tag) {
		return (
			<Tag className={`${className} ${baseClass}`} {...props}>
				{children}
			</Tag>
		);
	}

	return children;
};

EmptyList.defaultProps = {
	className: "",
	baseClass : "empty-list",
	tag : "ul",
	text: ''
};

EmptyList.propTypes = {
	className: PropTypes.string,
	text: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.string,
	]),
	baseClass: PropTypes.string,
	tag: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	children: PropTypes.children
};

export default EmptyList;
