import * as React from "react";
import {Component} from "react";

import {defineMessages, FormattedMessage} from "react-intl";
import {IBillingInstallment} from "./types";
// @ts-ignore
import Date    from "qidigo-i18n/date";
// @ts-ignore
import Money   from "@app/components/money";

const translations = defineMessages({
    "installment_warning": {
        id: "qidigo.billing.installment_warning",
        defaultMessage: "La facture est actuellement configurée pour être payée par versements. Vous pouvez en tout temps l'acquitter entièrement."
    },

    "remaining_installments": {
        id: "qidigo.billing.remaining_installments",
        defaultMessage: "Versements restants\u00A0: "
    },

    "installment_due_for": {
        id: "qidigo.billing.installment_due_for",
        defaultMessage: " dû le "
    },
})

export interface IInstallmentWarningProps {
    installments: Array<IBillingInstallment>
}


class InstallmentWarning extends Component<IInstallmentWarningProps> {
    constructor(props: IInstallmentWarningProps) {
        super(props);
    }

    render() {
        return (
            <div className={"billing-view--installment-warning-container"}>
                <FormattedMessage {...translations['installment_warning']}/>
                <div className={"billing-view--remaining-installments"}>
                    <FormattedMessage {...translations['remaining_installments']}/>
                </div>
                {
                    this.props.installments.map((installment) =>
                        <li>
                            <Money
                                value={installment.amount}
                                withSymbol={true}
                            />
                            <FormattedMessage {...translations['installment_due_for']}/>
                            <Date
                                date={installment.due_date} withTime={false}
                            />
                        </li>
                    )
                }
            </div>
        )
    }
}

export default InstallmentWarning

