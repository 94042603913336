import React from "react";
import QidigoSessionStore from "qidigo-sessionstore";
import {Link} from "react-router/es";
import {
	defineMessages,
	FormattedMessage
} from "react-intl";

const messages = defineMessages({
	goback: {
		id: "qidigo.dashboard.goback",
		defaultMessage: "Retourner à « {name} »"
	},
});

/**
 * Store tracking last visited organization.
 */
const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");

const BackToLastLocation = () => {
	const data = window.location.pathname.split('/', 3);
	let slug = data[2]
	let isOrgPath = data[1] === 'u'
	let lastOrg = lastOrgStore.get()

	if (!(slug && isOrgPath) && lastOrg) {
		slug = lastOrg['slug'];
	}

	if (!(lastOrg && lastOrg["slug"])) {
		return null;
	}

	return (
		<nav className="return-fragment">
			<Link onClick={() => {window.location.href=`/u/${slug}`}} >
				<FormattedMessage
					{...messages.goback}
					values={{name: lastOrg["name"]}}
				/>
			</Link>
		</nav>
	);
};

export default BackToLastLocation;
