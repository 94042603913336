import React, {Component} from "react";
import PropTypes             from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import reactTimeout       from "react-timeout";

/**
 * Effectue un carousel sur les éléments passés en `child`.
 *
 * ### Améliorations possibles.
 *
 * Changements permettant une meilleure utilisation du carousel.
 *
 *   * Ré-initialiser le setTimeout au changement (manuel?) d'image.
 *   * Désactiver le setTimeout au survol.
 *
 * @extends {Component}
 */
class Carousel extends Component {
	constructor() {
		super();
		this.state = {
			selected: 0,
		};
	}
	componentDidMount() {
		this.props.setInterval(()=>{ this.nextImage(); }, 5000);
	}
	setSelected(i) {
		this.setState({selected: i});
	}
	nextImage() {
		let selected = this.state.selected + 1;
		if (this.state.selected >= this.props.children.length - 1) {
			this.setSelected(0);
		}
		else {
			this.setSelected(selected);
		}
	}
	render() {
		const {
			className,
			children
		} = this.props;
		const classNames = ["carousel", className];
		const selectorItems = [];

		// Construit le sélecteur
		for (let i=0; i<children.length; i++) {
			let cls = ["carousel--selector-dot"];
			if (i === this.state.selected) {
				cls.push("selected");
			}
			selectorItems.push(
				<a
					key={i}
					className={cls.join(" ")}
					onClick={(e)=>{ e.preventDefault(); this.setSelected(i); }}
				>
					{i === this.state.selected ? "●" : "○"}
				</a>
			);
		}

		return (
			<div
				className={classNames.join(" ")}
			>
				<ReactCSSTransitionGroup
					component="div"
					className="carousel--containers"
					transitionName="carousel"
					transitionEnterTimeout={500}
					transitionLeaveTimeout={500}
				>
					{children[this.state.selected]}
				</ReactCSSTransitionGroup>
				<div className="carousel--selector">
					{selectorItems}
				</div>
			</div>
		);
	}
}

Carousel.propTypes = {
	className:    PropTypes.string,
	setInterval:  PropTypes.func,
};

export default reactTimeout(Carousel);

