import React, {Component} from "react";
import PropTypes from "prop-types";
import Checkbox from "qidigo-form/checkbox";
import {defineMessages, FormattedMessage} from "react-intl";

const translations = defineMessages({
	agree: {id: "qidigo.dashboard.form.agree", defaultMessage: "J'accepte"},
});

/**
 * Template for a question of type checkbox.
 */

class CheckboxTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);
		
		returnValue[field] = value;
	
		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			question,
			answer,
			errorMessage
		} = this.props;

		let check = false;

		if (answer && answer.check) {
			check  = answer.check;
		}

		const {template = {}, type_template = {}} = question;
		let question_options = {};
		let type_options = {};
		// TODO: Find a way to make a better model in the database
		if (template) {
			const {options: {with_agreement} = {}} = template;
			question_options=with_agreement;
		}
		if (type_template) {
			const {options: {with_agreement} = {}} = type_template;
			type_options=with_agreement;
		}

		const mergedTemplate = Object.assign(question_options, type_options);
		const {agreement_text} = mergedTemplate;
		let {default_text} = mergedTemplate;

		if (default_text === 'i_agree') {
			default_text = <FormattedMessage
				{...translations['agree']}
			/>;
		}
		let label = agreement_text ? agreement_text : default_text;

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Checkbox label={label} value={check} onChange={(...e)=>this.handleChange("check", ...e)}  />
				</div>
			</div>
		);
	}
}

CheckboxTypeForm.defaultProps = {
	className: "",
	value : {check: false}
};

CheckboxTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
};

export default CheckboxTypeForm;
