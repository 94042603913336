import React, {Component}    from "react";
import PropTypes             from "prop-types";

import Auth                  from "qidigo-auth";
import Fetch                 from "qidigo-fetch";
import {navigate}            from "qidigo-router";
import BackLocation          from "qidigo-router/back_location";
import QidigoSessionStore    from "qidigo-sessionstore";
import Loading               from "qidigo-components/loading.js";
import AppError              from "@app/components/applicationerror.js";
import Page                  from "@app/components/page.js";
import QidigoBackLocation from "../../modules/qidigo-router/back_location";


/**
 * Store tracking last visited organization.
 */
const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");

/**
 * Page d'inscription du site.
 * @extends {Component}
 */
class ProviderLoginController extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			errors: false,
		};
	}
	componentDidMount() {
		const {provider} = this.props.params;
		let {search} = this.props.location;
		const lastOrg = lastOrgStore.get();
		if (lastOrg && lastOrg["id"]) {
			search += '&state={"lastVisitedOrganizationId":' + lastOrg["id"] +'}';
		}

		Auth.logout()
		.then(() => Fetch.get(`authenticate/${provider}/callback${search}`))
		.then((result) => {
			const {jwt, next} = result;
			Auth.setToken(jwt, true);

			// S'assure que la connexion est complète avant de continuer
			// (/whoami finished)
			return Auth.userLoggedIn()
				.then((result) => {
					if (next === "register") {
						navigate("/registration/info", {
							state: {backLocation: BackLocation.getLastLocation()},
						});
					}
					else {
						QidigoBackLocation.goBack();
					}

					return null;
				})
				.then(() => Auth.socialLogin());
		})
		.catch((errors) => {
			this.setState({loading: false, errors});

			return Promise.reject(errors);
		})
		;
	}

	render() {
		const {loading, errors} = this.state;

		if (loading) {
			return <Loading />;
		}

		return (
			<Page name="login-provider">
				<AppError message={errors.message} />
			</Page>
		);
	}
}

ProviderLoginController.propTypes = {
	location: PropTypes.object,
	params: PropTypes.object,
};

export default ProviderLoginController;
