import * as React from "react";
import {Component} from "react";
import {PAY_INSTALLMENTS, PAY_NOW} from "../types";
import { defineMessages, FormattedMessage } from "react-intl";

const translations = defineMessages({
    "pay_now": {
        id: "qidigo.billing.payment_time_list.pay_now",
        defaultMessage: "Payer immédiatement"
    },
    "installments": {
        id: "qidigo.billing.payment_time_list.installments",
        defaultMessage: "Plan de paiement"
    },
});

interface IPaymentTimeListProps {
    canPayNow: boolean,
    hasInstallment: boolean,
    selectedPaymentTime: number|null,
    selectPaymentTime: (paymentTime: number) => void
}

class PaymentTimeList extends Component<IPaymentTimeListProps> {
    render() {
        return (
            <ul className="payment-time-list">
                {
                    this.props.canPayNow &&
                    <li key={PAY_NOW} className="payment-time-list--items">
                        <input
                            className="payment-time--radio-button"
                            name="payment-time-radio"
                            type="radio"
                            id="pay-now"
                            value={PAY_NOW}
                            checked={this.props.selectedPaymentTime === PAY_NOW}
                            onChange={() => this.props.selectPaymentTime(PAY_NOW)}
                        />
                        <label htmlFor="pay-now">
                            <FormattedMessage {...translations["pay_now"]} />
                        </label>
                    </li>
                }
                {
                    this.props.hasInstallment &&
                    <li key={PAY_INSTALLMENTS} className="payment-time-list--items">
                        <input
                            className="payment-time--radio-button"
                            name="payment-time-radio"
                            type="radio"
                            id="pay-installments"
                            value={PAY_INSTALLMENTS}
                            checked={this.props.selectedPaymentTime === PAY_INSTALLMENTS}
                            onChange={() => this.props.selectPaymentTime(PAY_INSTALLMENTS)}
                        />
                        <label htmlFor="pay-installments">
                            <FormattedMessage {...translations["installments"]} />
                        </label>
                    </li>
                }
            </ul>
        );
    }
}

export default PaymentTimeList;
