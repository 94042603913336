function addClass(el, className) {
	if (el.classList) {
		el.classList.add(className);
	}
	else {
		el.className += " " + className;
	}
}
function removeClass(el, className) {
	if (el.classList) {
		el.classList.remove(className);
	}
	else {
		el.className = el.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
	}
}

/**
 * Comportement imitant un peu le scrollspy.
 */
class ScrollSpy {
	constructor() {
	}
	/**
	 * Selector, to always get a fresh element. Page might change, the
	 * scrollspy might be long-lived.
	 */
	start(selector) {
		window.addEventListener("scroll", (e) => {
			const el = document.querySelectorAll(selector)[0];
			if (!el) { return; }

			const offset = el.getBoundingClientRect().bottom;
			const {body} = document;
			if (offset < 0) {
				addClass(body, "header-is-not-showing");
				removeClass(body, "header-is-showing");
			}
			else {
				removeClass(body, "header-is-not-showing");
				addClass(body, "header-is-showing");
			}
		});
	}
}

export default new ScrollSpy();
