import React, {Component}    from "react";
import PropTypes             from "prop-types";
import City                  from "./city";
import State                 from "./state";

/**
 * Implémente le microformat d'une adresse selon les propriétés données.
 *
 * Voir:
 *
 *   * http://microformats.org/wiki/h-card#p-adr
 *
 */
class Address extends Component {
	render() {
		if (!this.props.address) {
			return <address />;
		}
		let address = null;
		if (this.props.address.civic_number_and_street) {
			address = <span className="p-street-address">{this.props.address.civic_number_and_street}</span>;
		}
		let locality = null;
		if (this.props.address.city) {
			locality = <City className="p-locality" city={this.props.address.city} />;
		}
		let region = null;
		if (this.props.address.state) {
			region = <span>{" "}(<State className="p-region" state={this.props.address.state} />)</span>;
		}
		let postalCode = null;
		if (this.props.address.postal_code) {
			postalCode = <span className="p-postal-code">{this.props.address.postal_code}</span>;
		}

		return (
			<address className="p-adr h-adr">
				<div className="address--line1">
					{address}
				</div>
				<div className="address--line2">
					{locality}
					{region}
				</div>
				<div className="address--postcode">
					{postalCode}
				</div>
			</address>
		);
	}
}

Address.propTypes = {
	address: PropTypes.object,
};

export default Address;
