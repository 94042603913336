import * as React from "react";
import {Component} from "react";
import PriceBreakDown from "./priceBreakDown";
import CouponAdjustment from "./couponAdjustment";
import ContactCredit from "./contactCredit";
import ContactCreditPopup from "./contactCreditPopup";
import {IContactCredit, ISubscriber, ISummary} from "../types";
import EmptyList from "../../../components/empty_list";
// @ts-ignore
import Money from "@app/components/money";
import Helmet from "react-helmet";
// @ts-ignore
import PropTypes from "prop-types";
import UserLine from "./userLine";

interface ISummaryProps {
    summary: ISummary,
    contact_credit: IContactCredit,
    reload: (couponCode: string|null, showCouponApplication: boolean) => void | null,
    removeLine: (lineId: number) => void | null,
    setCredit: (credit: number) => void,
    isFree: boolean,
    hasCouponErrorOnApplication: boolean,

    hasCouponLimitErrorOnApplication: boolean,
    handleItemIsBeingDeleted: (isBeingDeleted: boolean) => void | null,
    canRemoveLine: boolean,
    isLoading: boolean,
    hasPriceTotal: boolean,
    hasCouponAdjustment: boolean,
    hasPaymentOrCredit: boolean,
    helmet: string,
    label: JSX.Element,
    invoiceLabel: string,
    model: string
}

interface ISummaryState {
    appliedCredit: number,
    isCreditPopupOpen: boolean
    userLineCollapsed: boolean
}

class Summary extends Component<ISummaryProps, ISummaryState> {
    constructor(props: ISummaryProps) {
        super(props);

        this.state = {
            appliedCredit: 0.0,
            isCreditPopupOpen: false,
            userLineCollapsed: true
        };
    }

    componentDidUpdate() {
        if (this.state.appliedCredit > 0) {
            if (this.props.summary.price_detail === null) {
                return;
            }

            if (this.props.summary.price_detail.total - this.state.appliedCredit < 0) {
                let newCredit = this.props.summary.price_detail.total;

                if (newCredit > this.props.contact_credit.credit) {
                    newCredit = this.props.contact_credit.credit;
                }
                this.applyContactCredit(newCredit);
            }
        }
    }

    applyContactCredit =  async (applyingCredit) => {
        this.setState({
            appliedCredit: applyingCredit,
            isCreditPopupOpen: false
        });

        await this.props.setCredit(applyingCredit);
    }

    openContactCreditPopup = () => {
        this.setState({
            isCreditPopupOpen: true
        });
    }

    closeContactCreditPopup = () => {
        this.setState({
            isCreditPopupOpen: false
        });
    }

    showContactCreditBox = (): boolean => {
        return this.props.contact_credit.can_use_credit &&
                this.props.contact_credit.credit > 0 &&
                (!this.props.isFree || this.state.appliedCredit > 0)
    }

    render() {
        const couponCode = this.props.summary.applied_coupon
            ? this.props.summary.applied_coupon.code
            : null;
        let balance = 0;
        let creditTotal = 0;
        let subscribers: ISubscriber[] = [];

        if (this.props.summary.lines.length > 0) {
            balance = (this.props.hasPriceTotal ? this.props.summary.price_detail.total : this.props.summary.price_detail.balance) - this.state.appliedCredit;
            creditTotal =
                this.props.summary.price_detail.total === this.props.summary.price_detail.balance
                    ? this.props.summary.price_detail.total
                    : this.props.summary.price_detail.balance

            subscribers = this.props.summary.lines.reduce((acc: ISubscriber[], line) => {
                const subscriber = line.subscriber;

                if (!acc.some(sub => sub.id === subscriber.id)) {
                    acc.push(subscriber);
                }

                return acc;
            }, []);
        }

        const {formatMessage} = this.context.intl;
        return (
            <div className={"summary"}>
                <Helmet
                    title={formatMessage(this.props.helmet)}
                />
                <div className="billing-summary">
                   {this.props.label}
                </div>
                <div className={this.props.summary.lines.length > 0 ? 'summary-lines' : ''}>
                    <EmptyList>
                        {
                            subscribers.map((subscriber) =>
                                <UserLine
                                    key={subscriber.id}
                                    summary={this.props.summary}
                                    reload={() => this.props.reload(couponCode, false)}
                                    removeLine={this.props.removeLine}
                                    handleItemIsBeingDeleted={this.props.handleItemIsBeingDeleted}
                                    subscriber={subscriber}
                                    canRemoveLine={this.props.canRemoveLine}
                                    isLoading={this.props.isLoading}
                                    invoiceLabel={this.props.invoiceLabel}
                                    isContract={this.props.model === 'CONTRACT'}
                                >
                                </UserLine>
                            )
                        }
                    </EmptyList>
                </div>
                {
                    this.props.summary.lines.length > 0 &&
                    <>
                        <PriceBreakDown
                            priceDetail={this.props.summary.price_detail}
                            open_contact_credit_popup={this.openContactCreditPopup}
                            applied_credit={this.state.appliedCredit}
                            appliedCoupon={this.props.summary.applied_coupon}
                            refreshCodeWithoutCoupon={() => this.props.reload(null, false)}
                            balance={balance}
                        />
                        {
                            this.props.hasCouponAdjustment &&
                            <div>
                                <CouponAdjustment
                                    reload={this.props.reload}
                                    appliedCoupon={couponCode}
                                    hasCouponErrorOnApplication={this.props.hasCouponErrorOnApplication}
                                    hasCouponLimitErrorOnApplication={this.props.hasCouponLimitErrorOnApplication}
                                />
                            </div>
                        }
                        {
                            this.showContactCreditBox() && !this.props.isLoading &&
                            <div>
                                {
                                    !(this.state.appliedCredit > 0) &&
                                    <ContactCredit
                                        credit_total={this.props.contact_credit.credit}
                                        applied_credit={this.state.appliedCredit}
                                        apply_contact_credit={this.applyContactCredit}
                                        balance={balance}
                                    />
                                }
                                {
                                    this.state.isCreditPopupOpen &&
                                    <ContactCreditPopup
                                        creditTotal={creditTotal}
                                        appliedCredit={this.state.appliedCredit}
                                        closePopup={this.closeContactCreditPopup}
                                        applyCredit={this.applyContactCredit}
                                    />
                                }
                            </div>
                        }
                    </>
                }
            </div>
        );
    }
}

// @ts-ignore
Summary.contextTypes = {
    intl: PropTypes.object,
};

export default Summary;
