import Fetch                 from "qidigo-fetch";
import {sendError}           from "../router";
import moment                from "moment";

export const REQUEST_GROUP = "SEARCH:REQUEST_GROUP";
export const RECEIVE_GROUP = "SEARCH:RECEIVE_GROUP";
export const RECEIVE_GROUP_FAILED = "SEARCH:RECEIVE_GROUP_FAILED";
export const INVALIDATE_GROUP = "SEARCH:INVALIDATE_GROUP";

/**
 * Notifie qu'on effectue une requête.
 */
const requestGroup = (groupId) => ({
	type: REQUEST_GROUP,
	groupId,
});

/**
 * Notifie de la réception des données.
 */
const receiveGroup = (groupId, group, sessions) => ({
	type: RECEIVE_GROUP,
	groupId,
	group,
	sessions,
	at: Date.now(),
});

/**
 * Force un refresh pour une activité.
 */
export const invalidateGroup = () => ({
	type: INVALIDATE_GROUP,
});

/**
 * Récupère une activité, si nécessaire (compare l'ID)
 *
 * ### Paramètres
 *
 *   * groupId : ID du groupe à charger
 *   * options:
 *       * withoutOrganization : Ne compare pas l'organisme pour une rejection 404... (legacy redirection)
 *
 */
export const fetchGroup = (groupId, {withoutOrganization} = {withoutOrganization: false}) =>
	(dispatch, getState) => {
		// Convertis l'ID en string, pour stabilité entre les types.
		const id = groupId.toString();
		const index = id.indexOf('?');

		const filteredGroupId = index === -1 ? id : id.substring(0, index);
		const {search: {group: {group}}} = getState();

		// Court-circuite si on a déjà la bonne activité.
		if (group && group["id"].toString() === filteredGroupId) { return null; }

		// Indique qu'on commence à récupérer l'activité.
		dispatch(requestGroup(filteredGroupId));

		// Dispatch
		return dispatch((dispatch, getState) => {
			return Promise.all([
				Fetch.get(`groups/${filteredGroupId}`)
			])
				.then(([{group}]) => {
					const {search} = getState();
					const sessions = group.sessions;
					// N'est plus l'objet recherché?
					if (filteredGroupId !== search.group.groupId) { return null; }

					// Aucun résultat correspond à un "soft-404"
					if (!group) { return Promise.reject({status: 404}); }

					// Activité chargée pour un mauvais organisme?
					if (!withoutOrganization && group.organization.id !== search.organization.organization.id) {
						return Promise.reject({status: 404});
					}

					// Réceptionne.
					dispatch(receiveGroup(filteredGroupId, group, sessions));

					return group;
				})
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: RECEIVE_GROUP_FAILED, error});
				});
		});
	};

