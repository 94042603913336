import React                 from "react";
import PropTypes             from "prop-types";
import {
    defineMessages,
    FormattedMessage,
} from "react-intl";
import reduce                from "lodash/reduce";

import Loading               from "qidigo-components/loading.js";
import Image                 from "qidigo-components/image.js";
import OffersFragment        from "@app/views/shared/offers";

// @ts-ignore
import { groupRestrictions } from "qidigo-data/restrictions";

const translations = defineMessages({
    "adding": {id: "qidigo.membership.offers.adding", defaultMessage: "Ajout en cours..."},
    "membership.no_offers": {id: "qidigo.membership.offers.no_offers", defaultMessage: "Il n'y a pas d'offres pour cet abonnement..."},
});

// Pour ordonner les sections.
const order = ["SESSION", "PASS"];

/**
 * Affichage de la page "offres"
 */
const MembershipOffers = (props, context) => {
    const {
        membership,
        offers,
        familyMembers,
        newStatus,
        organization,
        onAddToCart,
    } = props;

    const offersDict = reduce(offers, (obj, v) => {
        const type = v["plan"]["type_plan"];
        if (!obj[type]) { obj[type] = []; }
        obj[type].push(v);

        return obj;
    }, {});

    // On n'a pas (de collections) d'offre?
    if (Object.values(offersDict).length === 0) {
        let msg = <FormattedMessage {...translations["membership.no_offers"]} />;

        return (
            <div className="page-membership page-offers has-no-offers">
                <section className={`membership--offers`}>
                    <h2>{membership["name"]}</h2>
                    <div className="box-message">
                        {msg}
                    </div>
                </section>
            </div>
        );
    }

    return (
        <div className="page-membership page-offers">
            <section className={`membership--offers ${props.adding ? "is-adding" : ""}`}>
                <h2>{membership["name"]}</h2>
                {
                    props.adding ?
                        <Loading
                            className="style-overlay"
                            message={<FormattedMessage {...translations["adding"]} />}
                        /> :
                        null
                }
                {
                    offers.length > 0 ?
                        order.map((type) => {
                            const offers = offersDict[type];
                            const groups = offers && offers.plan && offers.plan.groups || []

                            return (
                                <div>
                                    <Image className="membership__image" src={membership["media"]["path"]} alt={membership["media"]["text_alt"]} />
                                    <OffersFragment
                                        familyMembers={familyMembers}
                                        newStatus={newStatus}
                                        offers={offers}
                                        onAddToCart={onAddToCart}
                                        disabled={props.adding}
                                        groupRestrictions={groups.map(groupRestrictions)}
                                        organization={organization}
                                    />
                                </div>
                            );
                        }) :
                        null
                }
            </section>
        </div>
    );
};

MembershipOffers.propTypes = {
    membership: PropTypes.object,
    familyMembers: PropTypes.object,
    newStatus: PropTypes.object,
    offers: OffersFragment.propTypes.offers,
    onAddToCart: PropTypes.func.isRequired,
    adding: PropTypes.bool,
};

MembershipOffers.defaultProps = {
};

MembershipOffers.contextTypes = {
    intl: PropTypes.object,
};

export default MembershipOffers;
