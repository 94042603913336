import * as React from "react";
import {Component} from "react";
// @ts-ignore
import Money from "@app/components/money";
import {IInstallment, IInstallmentPayment} from "../../types";
import InstallmentPaymentsPopup from "./installmentPaymentsPopup";
// @ts-ignore
import Button from "qidigo-components/button.js";
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import PropTypes from "prop-types";
// @ts-ignore
import DateLabel from "qidigo-i18n/date";

const translations = defineMessages({
    installment: {
        id: "qidigo.billing.installments.installment",
        defaultMessage: "Plan"
    },
    choose: {
        id: "qidigo.billing.installments.choose",
        defaultMessage: "Choisir"
    },
    selected: {
        id: "qidigo.billing.installments.selected",
        defaultMessage: "Sélectionnée"
    },
    initialPayment: {
        id: "qidigo.billing.installments.initial_payment",
        defaultMessage: "Paiement initial"
    },
    comingPayments: {
        id: "qidigo.billing.installments.coming_payments",
        defaultMessage: "Paiements à venir"
    },
    plus_installments: {
        id: "qidigo.billing.installments.plus_installments",
        defaultMessage: "+ {count} plan{count, plural, one {} other {s}} de versements"
    },
    "installment_price_display": {
        id: "qidigo.billing.installment_payments_popup.installment_price_display",
        defaultMessage: "{value} $"
    },
});

interface IPaymentInstallmentProps {
    index: number,
    installment: IInstallment,
    selectedPaymentInstallment: string | null,
    selectPaymentInstallment: () => void
}

interface IPaymentInstallmentState {
    isInstallmentPaymentsPopupOpen: boolean
}

class PaymentInstallment extends Component<IPaymentInstallmentProps, IPaymentInstallmentState> {
    DISPLAY_INSTALLMENT_PAYMENTS_LIMIT = 4;

    constructor(props: IPaymentInstallmentProps) {
        super(props);
        this.state = {
            isInstallmentPaymentsPopupOpen: false
        };
    }

    openInstallmentPaymentsPopup = () => {
        this.setState({
            isInstallmentPaymentsPopupOpen: true
        });
    }

    closeInstallmentPaymentsPopup = () => {
        this.setState({
            isInstallmentPaymentsPopupOpen: false
        });
    }

    formatIsoDateString(localDate: Date) {
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const date = (localDate.getDate()).toString().padStart(2, '0');

        return `${year}-${month}-${date}`;
    }

    getInitialPayment(payments: Array<IInstallmentPayment>): IInstallmentPayment | null {
        if (payments.length === 0) {
            return null;
        }

        const today = this.formatIsoDateString(new Date());
        const firstPayment = payments[0];
        if (firstPayment.date !== today) {
            return null;
        }

        return firstPayment;
    }

    getRestPayments(payments: Array<IInstallmentPayment>, excludedPayment: IInstallmentPayment | null): Array<IInstallmentPayment> {
        return payments.filter((payment: IInstallmentPayment) => !excludedPayment || payment.date !== excludedPayment.date);
    }

    initialInstallmentPayment(payment: IInstallmentPayment, hasRestPayments: boolean) {
        return (
            <li
                className={"payment-installment--initial-payment " + (hasRestPayments ? " payment-installment--initial-payment-underline" : "")}
                key={"initial-installment-payment"}
            >
                <div className={"payment-installments--initial-payment-text"}>
                    <FormattedMessage {...translations["initialPayment"]} />
                </div>
                <div className={"payment-installments--initial-payment-amount"}>
                    <FormattedMessage {...translations["installment_price_display"]} 
                                values={{value: payment.amount.toFixed(2)}}/>
                </div>
            </li>
        )
    }

    restInstallmentPayments(payments: Array<IInstallmentPayment>) {
        return (
            <li
                className={"payment-installment--rest-payment"}
                key={"rest-installment-payments"}
            >
                <div className={"payment-installments--rest-payment-text"}>
                    <FormattedMessage {...translations["comingPayments"]} /></div>
                <ul className={"payment-installment-option--list"}>
                    {payments.map((payment) => this.installmentPayment(payment))}
                </ul>
            </li>
        )
    }

    installmentPayment(payment: IInstallmentPayment) {
        return (
            <li
                className={"payment-installment--instalment-payment"}
                key={payment.date}
            >
                <div>
                    <DateLabel date={payment.date} withTime={false} />
                </div>
                <div>
                    <FormattedMessage {...translations["installment_price_display"]} 
                                values={{value: payment.amount.toFixed(2)}}/>
                </div>
            </li>
        )
    }

    render() {
        const isSelected = this.props.selectedPaymentInstallment === this.props.installment.future_id;
        const payments = this.props.installment.payments;
        const isOverLimit = payments.length > this.DISPLAY_INSTALLMENT_PAYMENTS_LIMIT;
        const displayPayments = isOverLimit
            ? payments.slice(0, this.DISPLAY_INSTALLMENT_PAYMENTS_LIMIT)
            : payments;

        const initialPayment = this.getInitialPayment(displayPayments);
        const restPayments = this.getRestPayments(displayPayments, initialPayment);

        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const name = formatMessage(translations["installment"]) + ' #' + this.props.index;

        return (
            <li
                className={"payment-installments"}
                key={this.props.installment.id}
                onClick={this.props.selectPaymentInstallment}
            >
                <div className={"payment-installments--box"}>
                    <div className={
                        (isSelected ? "payment-installments--selected-container" : "payment-installments--not-selected-container")
                    }>
                        <div className={
                            (isSelected ? "payment-installments--selected-text" : "payment-installments--not-selected-text")
                        }>
                            {name}
                        </div>
                        <ul className={"payment-installment--option-list"}>
                            {initialPayment && this.initialInstallmentPayment(initialPayment!, restPayments.length > 0)}
                            {restPayments && restPayments.length > 0 && this.restInstallmentPayments(restPayments)}
                        </ul>
                        {
                            isOverLimit &&
                            <div className={"payment-installments--over-limit-container"}>
                                <div className={"payment-installments--over-limit-button-container"}>
                                    <a
                                        className={"payment-installments--over-limit-button"}
                                        onClick={() => this.openInstallmentPaymentsPopup()}
                                    >
                                        <FormattedMessage
                                            {...translations["plus_installments"]}
                                            values={{count: payments.length - displayPayments.length}}
                                        />
                                    </a>
                                </div>
                                {
                                    this.state.isInstallmentPaymentsPopupOpen &&
                                    <InstallmentPaymentsPopup
                                        name={name}
                                        initialPayment={initialPayment}
                                        restPayments={this.getRestPayments(payments, initialPayment)}
                                        closePopup={this.closeInstallmentPaymentsPopup}
                                    />
                                }
                            </div>
                        }
                        <div className={
                            "payment-installments--radio-button-container"
                            + (isSelected ? " payment-installments--radio-button-selected" : " payment-installments--radio-button-not-selected")
                        }>
                            <input
                                className={"payment-installments--radio-button"}
                                type="radio"
                                checked={isSelected}
                                readOnly
                            />
                            <span>
                                {isSelected
                                    ? <FormattedMessage {...translations["selected"]} />
                                    : <FormattedMessage {...translations["choose"]} />
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

// @ts-ignore
PaymentInstallment.contextTypes = {
    intl: PropTypes.object,
};

export default PaymentInstallment;
