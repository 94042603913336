import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {connect}             from "@app/lib/redux";
import Fetch                 from "qidigo-fetch";
import {navigate}            from "qidigo-router";
import QidigoSessionStore    from "qidigo-sessionstore";
import {simpleSerializeCollection} from "@app/lib/serialize";

import WithEditor            from "@app/components/with_editor";
import Loading               from "qidigo-components/loading";
import moment                from "moment";

/**
 * À moins de 16 ans, on force l'écran de familly à l'enregistrement.
 */
const FORCE_FAMILY_AT = 16*12;

/**
 * Store pour les infos demandées par le dernier organisme visité.
 */
const regStore = new QidigoSessionStore("organizationRegistrationPreferences");

import {
	fetchGroup,
	invalidateGroup,
}   from "@app/store/actions/search/group";

import {
	addToCart,
}   from "@app/store/actions/buy";

import {
	openCart,
}   from "@app/store/actions/cart";

/**
 * Contrôleur pour la page d'un groupe.
 *
 * @extends {Component}
 */
class GroupController extends Component {
	constructor() {
		super();
		this.state = {
			offers: null,
			newStatus: {},
		};
	}

	/**
	 * Charge le data pour le contrôleur.
	 * (API publique)
	 */
	static load(dispatch, params) {
		return Promise.all([
			dispatch(fetchGroup(params.groupID)),
		]);
	}

	/**
	 * Proxy pour `static load`.
	 */
	load() {
		const {dispatch, params} = this.props;
		GroupController.load(dispatch, params);
	}

	componentDidMount() {
		this.load();
		this.fetchOffers();
	}

	componentWillUnmount() {
		// FIXME : Comprendre pourquoi je dois invalider ici...
		const {dispatch} = this.props;
		dispatch(invalidateGroup());
	}

	componentDidUpdate(prevProps) {
		if (prevProps.params.groupID !== this.props.params.groupID) {
			this.load();
		}
		if (simpleSerializeCollection(prevProps.cart.items) !== simpleSerializeCollection(this.props.cart.items)) {
			this.fetchOffers();
		}
		const {group} = this.props;
		if (group && (!prevProps.group || group !== prevProps.group)) {
			this.fetchOffers();
			const thisGroupSettings = {
				force_family: !!(group["restriction_age_max"] && group["restriction_age_max"] < FORCE_FAMILY_AT),
			};
			const preferences = regStore.get() || {};
			regStore.set(Object.assign({}, preferences, thisGroupSettings));
		}
	}

	/**
	 * Gère l'ajout au panier.
	 *
	 * La page sera en état de chargement jusqu'à ce que la requête complète.
	 *
	 * Une complétion avec réussite dirigera l'utilisateur au panier.
	 */
	handleAddToCart({plan, subscriber}) {
		const {dispatch} = this.props;

		dispatch(addToCart({
			cart_item: {
				quantity: 1,
				subscriber_user_id: subscriber,
				plan_id: plan,
			}
		}))
		.then((response) => {
			if (response) {
				if (response["status"] === "OK") {
					dispatch(openCart());
				}
				else if (response["status"] === "WAITING_LIST") {
					const newStatus = Object.assign({}, this.state["newStatus"], {
						[subscriber]: "WAITING_LIST",
					});
					this.setState({newStatus});
				}
			}

			return response;
		})
		.then((response) => this.fetchOffers())
		.catch((err) => { console.error(err); return this.fetchOffers(); })
		;
	}

	//
	// Suivent, les fonctions non-reduxifiées
	//

	/**
	 * Récupère les offres pour le groupe.
	 */
	fetchOffers() {
		if (this.state.loading) {
			return;
		}
		this.setState({loading: true});
		let {groupID} = this.props.params;
		groupID = this.removeQueryParams(groupID);

		Fetch.get(`groups/${groupID}/offers`)
			.then((response) => {
				const {offers} = response;
				const _bundled = response["_bundled"] || {};
				const familyMembers = _bundled["family_members"] || {};
				this.setState({offers, familyMembers});
				this.setState({loading: false});

				return response;
			})
			.catch(Fetch.handleErrors(this))
	}

	removeQueryParams(param) {
		const index = param.indexOf('?');
		return index === -1 ? param : param.substring(0, index);
	}

	render() {
		const {
			/* eslint-disable */
			route,
			params,
			/* eslint-enable */
			organization,
			activity,
			group,
			sessions,
			children,
			adding,
			...props
		} = this.props;
		if (!group) { return <Loading />; }

		 // Redirects to parent if ended.
		 const now = moment();
		 if (now.isAfter(group["end_date"])) {
		   navigate(`/u/${organization.slug}/activity/${activity.id}`);

		   return null;
		 }
		const {offers, familyMembers, newStatus, loading} = this.state;

		return (
			<WithEditor then={() => this.fetchOffers()}>{
				children && React.cloneElement(children, Object.assign({
					organization,
					activity,
					group,
					sessions,
					offers,
					familyMembers,
					newStatus,
					adding,
					loading,
					onAddToCart: (...args) => this.handleAddToCart(...args),
				}, props))
			}</WithEditor>
		);
	}
}

GroupController.propTypes = {
	dispatch: PropTypes.func,
	route: PropTypes.object,
	location: PropTypes.object,
	params: PropTypes.shape({
		groupID: PropTypes.string,
	}).isRequired,
	activity: PropTypes.object,
	group: PropTypes.object,
	organization: PropTypes.object,
	sessions: PropTypes.arrayOf(PropTypes.object),
	adding: PropTypes.bool,
	cart: PropTypes.shape({
		items: PropTypes.array,
	}).isRequired,
};

// Connecte avec le store redux.
const withProperties = ({
	search: {group: {group, sessions, fetching}},
	buy: {cart: {adding}},
	cart,
}) => ({group, sessions, fetching, cart, adding});
export default connect(withProperties)(GroupController);
