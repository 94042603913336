import reduce from "lodash/reduce";
import ENV    from "qidigo-env";

/**
 * Liste (duplication) des routes.
 * Il faudra trouver un moyen (propre) d'utiliser uniquement cette liste
 * comme source de vérité, donc à les utiliser dans react-router.
 *
 * C'est un ajout tard en développement pour pallier au fait que react-router
 * n'a pas de moyen de faire une référence à une route. C'est "normal" puisque
 * les routes peuvent être/sont *lazy-loadées*.
 *
 * (React-router disent d'utiliser les routes comme si c'étaient des keys,
 *  mais ça rend difficile l'utilisation de routes hiérarchiques avec des
 *  variables comme orgID/activityID/groupID où il faut faire un beau tapon
 *  de concaténation ou d'interpolation.)
 */
const ROUTES = {};
ROUTES["home"]                  = "/";
ROUTES["img"]                   = "/:img";
ROUTES["organization"]          = "/u/:orgID";
ROUTES["organization.session"]  = `${ROUTES["organization"]}/activities/session`;
ROUTES["organization.schedule"] = `${ROUTES["organization"]}/activities/schedule`;
ROUTES["organization.calendar"] = `${ROUTES["organization"]}/activities/calendar`;
ROUTES["organization.contact"]  = `${ROUTES["organization"]}/contact`;
ROUTES["organization.memberships"] = `${ROUTES["organization"]}/memberships`;
ROUTES["membership"]            = `${ROUTES["organization.memberships"]}/:membershipID`;
ROUTES["activity"]              = `${ROUTES["organization"]}/activity/:activityID`;
ROUTES["activity.contact"]      = `${ROUTES["activity"]}/contact`;
ROUTES["activity.session"]      = `${ROUTES["activity"]}/session`;
ROUTES["activity.schedule"]     = `${ROUTES["activity"]}/schedule`;
ROUTES["activity.calendar"]     = `${ROUTES["activity"]}/calendar`;
ROUTES["activity.groups"]       = `${ROUTES["activity"]}/groups`;
ROUTES["group"]                 = `${ROUTES["activity"]}/group/:groupID`;
ROUTES["groups.selected"]       = `${ROUTES["activity"]}/groups/selected`;
ROUTES["group.schedule"]        = `${ROUTES["group"]}/schedule`;
ROUTES["group.contact"]         = `${ROUTES["group"]}/contact`;
ROUTES["dashboard"]             = "/dashboard";
ROUTES["dashboard.addresses"]   = `${ROUTES["dashboard"]}/addresses`;
ROUTES["dashboard.address"]     = `${ROUTES["dashboard.addresses"]}/:addressID`;
ROUTES["dashboard.passes"]      = `${ROUTES["dashboard"]}/passes`;
ROUTES["dashboard.passes.book"] = `${ROUTES["dashboard.passes"]}/:passID/sessions/schedule`;
ROUTES["dashboard.passes.book.schedule"] = `${ROUTES["dashboard.passes"]}/:passID/sessions/schedule`;
ROUTES["dashboard.passes.book.calendar"] = `${ROUTES["dashboard.passes"]}/:passID/sessions/calendar`;
ROUTES["dashboard.forms"]       = `${ROUTES["dashboard"]}/forms`;
ROUTES["dashboard.form"]        = `${ROUTES["dashboard"]}/forms/:formID`;
ROUTES["dashboard.invoices"] = `${ROUTES["dashboard"]}/invoices`;
ROUTES["dashboard.rl24"] = `${ROUTES["dashboard"]}/invoices/rl24`;
ROUTES["dashboard.rl24.recipients.edit"] = `${ROUTES["dashboard.rl24"]}/recipients/:id/edit`;
ROUTES["dashboard.rl24.recipients.new"]  = `${ROUTES["dashboard.rl24"]}/recipients/new`;
ROUTES["dashboard.invoice"]  = `${ROUTES["organization"]}/invoices/:invoiceId/show`;

// Route name kept for ease of use from API data.
// This defines the default view for passes.
ROUTES["organization.pass"] = ROUTES["organization.schedule"];
ROUTES["activity.pass"]     = ROUTES["activity.schedule"];

// Messaging
ROUTES["messages.new"]          = "/messages/new";

/**
 * Returns the absolute pathname for a named route.
 *
 * This is because there is no support for that in react-router.
 */
export const path = (name, variables = {}, options = {}) => {
	const route = ROUTES[name];
	const {full} = options;
	let prefix = "";
	if (full) {
		let location;
		if (ENV["browser"]) {
			location = window.location;
		}
		else {
			location = {
				protocol: ENV["FRONTEND_HTTPS"] ? "https:" : "http:",
				host: ENV["FRONTEND_HOSTNAME"],
			};
		}
		prefix = location.protocol + "//" + location.host;
	}

	if (!route) {
		if (process.env.NODE_ENV === "development") {
			throw new Error(`Trying to use path() with route '${name}' which does not exist.`); // eslint-disable-line
		}
		else {
			console.error(`Trying to use path() with route '${name}' which does not exist.`); // eslint-disable-line
		}
	}

	// Il est probable qu'il faudra penser à travailler avec un regex pour ne pas
	// rempalcer une key partielle (:org sur /:org/:organe/ deviendrait /[bob]/[bob]ane)
	return prefix + reduce(variables, (route, value, key) => route.replace(`:${key}`, value), route);
};