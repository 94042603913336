import {Component}           from "react";
import PropTypes             from "prop-types";
import {navigate}            from "qidigo-router";
import {path}                from "@app/lib/routes";

/**
 * Redirige vers le bon contrôleur par défaut lors de l'accès direct
 * à une activité.
 *
 * La vue à utiliser dépend de ce que les groupes permettent, ensuite
 * dépend de ce que l'organisme privilégie.
 *
 * @extends {Component}
 */
class ActivityRedirector extends Component {
	componentDidMount() {
		const {activity} = this.props;
		const {organization} = activity;
		const view = organization["default_activities_view"];
		// TODO : isomorphic 301
		// TODO : get view to use from API.
		navigate(path(`activity.${view}`, {orgID: organization["slug"], activityID: activity["id"]}), {replace: true});
	}

	render() {
		return null;
	}
}

ActivityRedirector.propTypes = {
	route:  PropTypes.object,
	organization: PropTypes.object,
	activity: PropTypes.object,
};

export default ActivityRedirector;
