import React, {useCallback} from "react";
import PropTypes, {element} from "prop-types";
import {connect}             from "@app/lib/redux";
import Helmet                from "react-helmet";
import Cart                  from "../views/cart/index.js";
import {defineMessages}      from "react-intl";
import {Link as RRLink}      from "react-router/es";
import {GatewayDest}         from "qidigo-components/gateway";
import Header                from "./layout/header";
import Footer                from "./layout/footer";
import * as Sentry from '@sentry/react';
import AlertContextProvider from "./AlertContextProvider";

// Messages locaux pour ce component.
const messages = defineMessages({
	title: {id: "qidigo.default_title", defaultMessage: "Qidigo"}
});

/**
 * Component du layout de l'application.
 */
const QidigoLayout = (props, context) => {
	const {currentLocale} = context;
	const {formatMessage} = context.intl;
	const {loggedUser, className, Link, cartOpen, children} = props;
	const [ alertMessage, setAlertMessage ] = React.useState({});

	return (
		<Sentry.ErrorBoundary fallback={<p>This is a fallback</p>}>
			<div
				id="app"
				className={`${className} locale-${currentLocale} ${cartOpen ? "with" : "without"}-cart`}
			>
				<Helmet
					titleTemplate="Qidigo — %s"
					defaultTitle={formatMessage(messages.title)}
				/>
				<Header loggedUser={loggedUser} Link={Link} />
				{loggedUser && <Cart loggedUser={loggedUser} alertMessages={[alertMessage]} />}
				<div className="app--view">
					<GatewayDest name="page">
						<AlertContextProvider setAlertMessage={setAlertMessage}>
							{children}
						</AlertContextProvider>
					</GatewayDest>
				</div>
				<Footer Link={Link} onChange={(...e)=> {
					Sentry.addBreadcrumb({
						category: 'event_handler',
						level: Sentry.Severity.Debug,
						message: JSON.stringify(e)
					})
					props.onChange(...e)
				}} />
			</div>
		</Sentry.ErrorBoundary>
	);
};

QidigoLayout.propTypes = {
	Link: PropTypes.func,
	className: PropTypes.string,
	cartOpen: PropTypes.bool,
	loggedUser: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),
	onChange: PropTypes.func,
};

QidigoLayout.defaultProps = {
	Link: RRLink,
};

QidigoLayout.contextTypes = {
	intl: PropTypes.object,
	currentLocale: PropTypes.string,
};

const withProperties = ({cart:{open:cartOpen}}) => ({cartOpen});
export default connect(withProperties)(QidigoLayout);
