import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Input                 from "./input.js";
import find                  from "lodash/find";
import isNil                  from "lodash/isNil";
import T                     from "qidigo-i18n/messages";


const Options = ({options}) => {
    return options.map(({key, value, label, group}, i) => {
        if (group) {
            return <optgroup key={i} label={label}><Options options={group} /></optgroup>;
        }
        if (label) {
            return <option value="" disabled={true} key={i}>{label}</option>;
        }
        else {
            return <option value={key} key={i}>{value}</option>;
        }
    });
};

/**
 *
 * @extends {Component}
 */
class Select extends Component {

    MAX_LENGTH = 25;

    isValid(softValidate = false) {
        const {formatMessage} = this.context.intl;
        if (softValidate && this.isEmpty()) {
            return [];
        }

        if (this.props.required && this.isEmpty()) {
            return [formatMessage(T["errors.messages.blank"])];
        }

        return [];
    }

    validate(softValidate = false) {
        const valid = this.isValid(softValidate);

        return valid;
    }

    isEmpty() {
        const {value, options} = this.props;
        if (value === undefined || value === "") { return true; }
        if (!this.findOption(options, value)) { return true; }

        return false;
    }

    getOptionLabel() {
        const {options, value, label} = this.props;
        if (this.isEmpty()) {
            if (label && label.trim() !== "") {
                return label;
            }
            else {
                return "\xa0";
            }
        }
        
        const opt = this.findOption(options, value);

        if (opt.value === undefined && opt.group) {
            for (let it = 0; it < opt.group.length; it++) {
                if(opt.group[it].key.toString() === value.toString()) {
                    return this.truncateValue(opt.group[it].value);
                }
            }
        }

        return this.truncateValue(opt.value);
    }

    truncateValue(value) {
        return value.length > this.MAX_LENGTH ? value.substring(0, this.MAX_LENGTH - 3) + '...' : value
    }

    findOption(options, value) {
        return find(options, (opt)=> {
            
            if (opt["group"]) {
                return this.findOption(opt["group"], value);
            }
            return !isNil(opt["key"]) && opt["key"].toString() === value.toString();
        });
    }

    render() {
        const {
            /* eslint-disable */
            defaultValue,
            /* eslint-enable */
            options,
            externalLabel,
            label,
            className,
            value,
            ...leftoverProps
        } = this.props;

        const selectedValue = this.isEmpty() ? "" : value.toString();

        const classes = ["input-select", "select"];
        classes.push(className);

        let children = [];
        if (label) {
            children.push(
                <option className="select--label" value="" disabled={true} key={"__label"}>{label}</option>
            );
        }

        // Use `i` for key, to allow re-using keys, allowing a list to present
        // shortcuts at the top, then all options INCLUDING short-cutted options.
        // Thinks of a country list, with (CA,US,--,AA,BB,CA,DD,...,TT,US,VV,WW).
        // This allows finding through the second list, the same value initially given.
        children = children.concat(
            <Options key="options" options={options} />
        );

        const labelChildren =
            <div className="input-select--fake">
                <div className="input-select--value">
                    {this.getOptionLabel()}
                </div>
                <div className="input-select--button"></div>
            </div>
            ;

        return (
            <div
                className={classes.join(" ")}
            >
                <Input
                    label={externalLabel}
                    type="select"
                    labelChildren={labelChildren}
                    internalChildren={children}
                    value={selectedValue}
                    {...leftoverProps} />
            </div>
        );
    }
}

Select.propTypes = {
    className:          PropTypes.string,
    defaultValue:       PropTypes.string,
    value:              PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label:              PropTypes.string,
    externalLabel:      PropTypes.string,
    required:           PropTypes.bool,
    valid:              PropTypes.bool,
    error:              PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    disabled:           PropTypes.bool,
    options:            PropTypes.arrayOf(
        PropTypes.object
    )
};

Select.contextTypes = {
    intl: PropTypes.object,
};

export default Select;
