import React from "react";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import Money from "../../../components/money";
import Date from "qidigo-i18n/date";

const translations = defineMessages({
	"warning.title": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.title",
		defaultMessage: "Transactions échouées"
	},
	"warning.text": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.text",
		defaultMessage: "Les paiements suivants ont échoués. Leur montant n'a donc pas été soustrait du solde de la facture"
	},
	"warning.description": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.description",
		defaultMessage: "{date} - Un paiement de {amount} a échoué pour la raison suivante: "
	},
	"warning.error_code.cannot_trace": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.cannot_trace",
		defaultMessage: "Aucun compte bancaire n'a été trouvé pour les informations fournies"
	},
	"warning.error_code.not_sufficient_funds": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.not_sufficient_funds",
		defaultMessage: "Le compte bancaire ne dispose pas de fonds suffisants"
	},
	"warning.error_code.payment_stopped_or_recalled": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.payment_stopped_or_recalled",
		defaultMessage: "Le paiement a été arrêté ou rappelé"
	},
	"warning.error_code.post_dated_or_stale_dated": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.post_dated_or_stale_dated",
		defaultMessage: "Le paiement est postdaté ou a été fixé à une date périmée"
	},
	"warning.error_code.closed_or_transferred_account": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.closed_or_transferred_account",
		defaultMessage: "Le compte bancaire a été clôturé ou transféré"
	},
	"warning.error_code.currency_mismatch": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.currency_mismatch",
		defaultMessage: "La devise de paiement ne correspond pas à la devise du compte bancaire"
	},
	"warning.error_code.payor_deceased": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.payor_deceased",
		defaultMessage: "Le débiteur du compte bancaire est décédé"
	},
	"warning.error_code.restricted_or_frozen": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.restricted_or_frozen",
		defaultMessage: "Le compte bancaire est gelé ou restreint"
	},
	"warning.error_code.rejected_by_payor": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.rejected_by_payor",
		defaultMessage: "Le paiement a été rejeté par le débiteur du compte bancaire"
	},
	"warning.error_code.other": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.other",
		defaultMessage: "Le paiement a été rejeté pour une raison inconnue"
	},
	"warning.error_code.unsupported_currency": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.unsupported_currency",
		defaultMessage: "La devise n'est pas supportée par la plateforme de paiement"
	},
	"warning.error_code.unsupported_card_or_bank_account": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.unsupported_card_or_bank_account",
		defaultMessage: "Cette carte ou ce compte bancaire n'est pas supporté par la plateforme de paiement"
	},
	"warning.error_code.lost_card": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.lost_card",
		defaultMessage: "Le paiement a été rejeté pour une raison inconnue" // We know the reason, but we do not want to disclose it
	},
	"warning.error_code.stolen_card": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.stolen_card",
		defaultMessage: "Le paiement a été rejeté pour une raison inconnue" // We know the reason, but we do not want to disclose it
	},
	"warning.error_code.expired_card": {
		id: "qidigo.dashboard.invoice.failed_receipts.warning.error_code.expired_card",
		defaultMessage: "La carte est expirée"
	},
});

const FailedReceiptsWarning = (props) => {
	const {receipts} = props;
	return (
		<div className="checkout-failed-receipts-warning">
			<div className="checkout-failed-receipts-warning--blurb">
				<h3>
					<FormattedMessage
						{...translations["warning.title"]}
					/>
				</h3>
				<p>
					<FormattedMessage
						{...translations["warning.text"]}
					/>
				</p>
				<ul>
					{
						receipts.map((receipt, key) => {
							return (
								<li key={key}>
								<span>
									<FormattedMessage
										{...translations["warning.description"]}
										values={{
											date: <Date date={receipt["created_date"]}/>,
											amount: <Money value={receipt["total"]}/>,
										}}
									/>
									<FormattedMessage {...translations["warning.error_code." + receipt["error_code"]]} />
								</span>
								</li>
							)
						})
					}
				</ul>
			</div>
		</div>
	);
}

export default FailedReceiptsWarning;
