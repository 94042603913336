import React                 from "react";
import Helmet                from "react-helmet";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import snakeCase             from "lodash/snakeCase";

import Button                from "qidigo-components/button.js";
import ButtonsBar            from "qidigo-form/buttons_bar.js";
import Telephone             from "qidigo-components/telephone.js";
import EmptyList             from "@app/components/empty_list";

const translations = defineMessages({
	"title":  {id: "qidigo.dashboard.phone_numbers.page_title", defaultMessage: "Mon carnet téléphonique"},
	"default": {id: "qidigo.dashboard.phone_numbers.default", defaultMessage: "Principal"}
});
const actions = defineMessages({
	"new":  {id: "qidigo.dashboard.phone_numbers.new_phone_number", defaultMessage: "Ajouter un numéro de téléphone"},
	"edit": {id: "qidigo.dashboard.phone_numbers.edit", defaultMessage: "Modifier"},
	"set-default": {id: "qidigo.dashboard.phone_numbers.set_default", defaultMessage: "En faire mon numéro principal"},
});

const PhoneNumberItem = ({
	phoneNumber,
	setDefault,
	editPhoneNumberLinkProperties,
}, {intl: {formatMessage}}) =>
	<li className={`phone_number-card type-${snakeCase(phoneNumber.type_phone_number)} phone_number_id-${phoneNumber.id}`}>
		<nav>
			<ul>
				{
					phoneNumber.type_phone_number !== "NO_DEFAULT" &&
						<li>
							<Button
								className={"action-set-default"}
								title={formatMessage(actions["set-default"])}
								onClick={(...e) => setDefault(phoneNumber.id)}
							>
								{formatMessage(actions["set-default"])}
							</Button>
						</li>
				}
				<li>
					<Button
						{...editPhoneNumberLinkProperties}
						className={"action-edit"}
						title={formatMessage(actions["edit"])}
					>
						{formatMessage(actions["edit"])}
					</Button>
				</li>
			</ul>
		</nav>
		<Telephone phoneNumber={phoneNumber} />
		{" "}
		{
			phoneNumber.type_phone_number === "NO_DEFAULT" &&
				<span className="phone--tag">
					{formatMessage(translations["default"])}
				</span>
		}
	</li>
	;

PhoneNumberItem.propTypes = {
	// FIXME : PhoneNumber shape
	phoneNumber: PropTypes.object,
	setDefault: PropTypes.func.isRequired,
	editPhoneNumberLinkProperties: PropTypes.object.isRequired,
};

PhoneNumberItem.contextTypes = {
	intl: PropTypes.object,
};

/**
 * La liste des numéros
 */
const PhoneNumbersPage = (props, context) => {
	const {formatMessage} = context.intl;
	const {
		phoneNumbers,
		setDefault,
		getNewPhoneNumberLinkProperties,
		getEditPhoneNumberLinkProperties,
	} = props;

	return (
		<section className="dashboard-phonebook">
			<h1><FormattedMessage {...translations.title} /></h1>
			<Helmet
				title={formatMessage(translations["title"])}
			/>
			<EmptyList>
				{phoneNumbers.map((phoneNumber) =>
					<PhoneNumberItem
						editPhoneNumberLinkProperties={getEditPhoneNumberLinkProperties(phoneNumber)}
						key={phoneNumber.id}
						setDefault={setDefault}
						phoneNumber={phoneNumber}
					/>
				)}
			</EmptyList>
			<ButtonsBar withoutSubmit={true}>
				<Button
					{...getNewPhoneNumberLinkProperties()}
					className={"action-new"}
				>
					{formatMessage(actions["new"])}
				</Button>
			</ButtonsBar>
		</section>
	);
};

PhoneNumbersPage.propTypes = {
	phoneNumbers: PropTypes.arrayOf(PhoneNumberItem.propTypes.phoneNumber),
	setDefault: PropTypes.func.isRequired,
	getNewPhoneNumberLinkProperties: PropTypes.func.isRequired,
	getEditPhoneNumberLinkProperties: PropTypes.func.isRequired,
};

PhoneNumbersPage.contextTypes = {
	intl: PropTypes.object,
};

export default PhoneNumbersPage;
