import React, {Component} from "react";
import PropTypes from "prop-types";
import Input from "qidigo-form/input";

/**
 * Template for a question of type text.
 */

class TextTypeForm extends Component {

	handleChange(field, event, value) {
		const returnValue = Object.assign({}, this.props.answer);

		returnValue[field] = value;

		if (this.props.onChange) {
			this.props.onChange(event, returnValue);
		}
	}
	render() {
		const {
			className,
			questionName,
			answer,
			errorMessage,
			errors
		} = this.props;

		const {text=""} = answer || {};

		return (
			<div className={className}>
				{questionName}
				{errorMessage}
				<div>
					<Input error={errors["text"]} value={text} onChange={(...e)=>this.handleChange("text", ...e)} />
				</div>
			</div>
		);
	}
}

TextTypeForm.defaultProps = {
	className: "",
	errorMessage: "",
};

TextTypeForm.propTypes = {
	className: PropTypes.string,
	question: PropTypes.object,
	questionName: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	onChange: PropTypes.func,
	answer: PropTypes.object,
	errorMessage: PropTypes.oneOfType([
	  PropTypes.string,
	  PropTypes.object
	]),
	errors: PropTypes.object,
};

export default TextTypeForm;
