import {connect as _connect}             from "react-redux";
/**
 * Hacks divers pour `redux`.
 */

/**
 * `connect()` avec `pure: false` par défaut.
 *
 * Permet de garder le `context` fonctionnel à 100%.
 */
export const connect = (mapStateToProps, mapDispatchToProps, mergeProps, options) =>
	_connect(mapStateToProps, mapDispatchToProps, mergeProps, Object.assign(
		{pure: false},
		options
	));
