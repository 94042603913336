// This component is long, but hopefully, straightforward.
/* eslint max-statements: ["warn", 40] */
import React, {Component}    from "react";
import PropTypes             from "prop-types";
import T                     from "qidigo-i18n/messages";
import {FormattedMessage}    from "react-intl";

// http://www.regular-expressions.info/email.html
// http://stackoverflow.com/a/1373724/134494
const MAIL_VALIDATOR = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

/**
 * Component générique d'"input".
 *
 * Permet surtout d'assurer la présence des labels de manière consistante.
 * Assure aussi une utilisation normalisée de propriétées.
 *
 * `props.inputRef`
 * ----------------
 *
 * Quand un component parent veut avoir accès à l'élément *DOM* `<input>` ou
 * autre utilisé dans ce component, il est possible de le demander avec la
 * propriété `inputRef`, qui fonctionne comme `ref`, en y passant une fonction
 * qui a la signature `fn(node)`, la référence à l'input sera passée en
 * paramètre.
 *
 * ex:
 *
 *     <Input
 *       inputRef={node => this.inputNode = node }
 *       [...]
 *     />
 *
 * `props.onChange`
 * ----------------
 *
 *     function (event, value) {}
 *
 *   * Où `value` est la valeur du champ, nouvellement assignée.
 *   * Où `event` est un *Event* où `target` est `this` (cette instance).
 *
 * Le handler peut `preventDefault`.
 *
 * @extends {Component}
 */
class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasFocus: false,
		};
	}
	/**
	 * Implémentation de la validation.
	 *
	 * Retourne un array textuel des erreurs.
	 *
	 * @param {Boolean} softValidate Si c'est une validation molle.
	 */
	isValid(softValidate = false) {
		const {formatMessage} = this.context.intl;
		const value = this.props.value;

		if (softValidate && this.isEmpty()) {
			return [];
		}

		if (this.props.required && this.isEmpty()) {
			return [formatMessage(T["errors.messages.blank"])];
		}

		if (this.props.type === "email") {
			if (!MAIL_VALIDATOR.test(value)) {
				return [formatMessage(T["errors.messages.invalid"])];
			}
		}

		return [];
	}

	/**
	 * Valide le champ, mets l'était `valid` à `false` si invalide.
	 *
	 * @param {Boolean} softValidate Si c'est une validation molle.
	 */
	validate(softValidate = false) {
		const valid = this.isValid(softValidate);

		return valid;
	}


	/**
	 * Effectue une validation minimale en fonction du type de champ.
	 *
	 * Appelle le handler du parent.
	 */
	handleChange(event) {
		let value = event.target.value;

		if(this.props.type === "file") {
			value = event.target.files[0];
		}

		// Temporairement, pour le handler.
		// Pourrait être un Event custom aussi.
		event.target = this;
		if (this.props.onChange) {
			this.props.onChange(event, value);
		}
	}

	/**
	 * Effectue un validation, si le champ était valide, la validation est plus
	 * minimale.
	 */
	handleBlur(event, ...misc) {
		this.setState({hasFocus: false});
		// "softvalidate" seulement si c'est pas déjà déterminé comme invalide.
		// Le UX est plus agréable si on peut quitter un champ sans se faire harceler.
		this.validate(this.props.valid);
		if (this.props.onBlur) {
			this.props.onBlur(event, ...misc);
		}
	}

	/**
	 *
	 */
	handleFocus(event, ...misc) {
		this.setState({hasFocus: true});
		if (this.props.onFocus) {
			this.props.onFocus(event, ...misc);
		}
	}

	/**
	 * Est-ce que le champ est vide?
	 */
	isEmpty() {
		if (this.props.value === "") { return true; }

		return false;
	}

	render() {
		let {
			children,
			onFocus, // eslint-disable-line
			onBlur, // eslint-disable-line
			wrapperProps,
			wrapperOnMouseDown,
			disabled,
			error,
			internalChildren,
			inputClass,
			inputRef,
			value,
			className,
			label,
			labelChildren,
			errorMessage,
			role, type, icon, presentation, ...leftoverProps
		} = this.props;

		// Classes appliquées au wrapper.
		let wrapperClasses = ["input", className];
		// Classes pour le widget du formulaire.
		let inputClasses = [inputClass];

		let TagType = "input";
		if (type === "textarea") {
			TagType = "textarea";
		}
		if (type === "select") {
			TagType = "select";
		}

		// Sans présentation spécifique.
		// (Pas un defaultProps pour hériter de `<Form>`.
		if (!presentation) {
			presentation = "complex";
		}

		// Failsafe, on ne veut pas les styles "simples" sur un textarea.
		if (TagType !== "input" && presentation === "simple") {
			presentation = "complex";
		}

		// Classes BEM-like
		wrapperClasses.push(`input-${presentation}`);
		inputClasses.push(`input--input-${TagType}`);
		inputClasses.push(`input--input-${type}`);

		// :not(:empty) en CSS ne fonctionne pas bien.
		// C'est connu et est "non-fixable"
		if (this.isEmpty()) { wrapperClasses.push("is-empty"); }
		else                { wrapperClasses.push("is-not-empty"); }

		// Gestion des icones
		// Default sur "unset" au lieu de "none".
		if (presentation === "simple" && icon === "none") {
			icon = "unset";
		}
		// Au cas où.
		if (presentation === "complex") {
			icon = "none";
		}
		// Ensuite, les classes.
		if (icon !== "none") {
			wrapperClasses.push("with-icon");
			wrapperClasses.push(`icon-${icon}`);
		}

		if (this.props.valid === false || this.props.error || this.props.errorMessage) {
			wrapperClasses.push("is-invalid");
		}

		if (disabled) {
			wrapperClasses.push("is-disabled");
		}

		if (this.state.hasFocus) {
			wrapperClasses.push("has-focus");
		}
		else {
			wrapperClasses.push("has-not-focus");
		}

		if (error) {
			wrapperClasses.push("with-error-message");
			errorMessage =
					<FormattedMessage {...T["errors.format"]} values={{
						message: error.join?error.join(", "):error,
						attribute: label,
					}} />
			;
		}

		if (errorMessage) {
			errorMessage =
				<div className="input--error-message">
					{errorMessage}
				</div>
			;
		}

		if (label) {
			label =
				<span className="input--label-text">{label}</span>
			;
		}

		return (
			<div
				className={wrapperClasses.join(" ")}
				onMouseDown={(e) => { return wrapperOnMouseDown(e); }}
				{...wrapperProps}
			>
				<label>
					{label}
					{labelChildren}
					<TagType
						placeholder=""
						{...leftoverProps}
						ref={inputRef}
						type={type}
						role={role}
						className={inputClasses.join(" ")}
						disabled={disabled}
						value={value}
						onChange={(e)=>this.handleChange(e)}
						onFocus={(...e) => this.handleFocus(...e)}
						onBlur={(...e) => this.handleBlur(...e)}
					>
						{internalChildren}
					</TagType>
				</label>
				{children}
				{errorMessage}
			</div>
		);
	}
}

Input.propTypes = {
	wrapperProps:       PropTypes.object,
	wrapperOnMouseDown: PropTypes.func,
	onChange:           PropTypes.func,
	onBlur:             PropTypes.func,
	onFocus:            PropTypes.func,
	value:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className:          PropTypes.string,
	inputClass:         PropTypes.string,
	label:              PropTypes.string,
	labelChildren:      PropTypes.node,
	role:               PropTypes.string,
	type:               PropTypes.string,
	icon:               PropTypes.string,
	presentation:       PropTypes.string,
	required:           PropTypes.bool,
	valid:              PropTypes.bool,
	error:              PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]),
	errorMessage:       PropTypes.string,
	disabled:           PropTypes.bool,
	internalChildren:   PropTypes.node,
	inputRef:           PropTypes.func,
};

Input.defaultProps = {
	role:               "textbox",
	type:               "text",
	icon:               "none",
	inputClass:         "input--input",
	wrapperProps:       {},
	wrapperOnMouseDown: function() {},
};

Input.contextTypes = {
	intl: PropTypes.object,
};

export default Input;
