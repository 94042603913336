import React                 from "react";
import PropTypes             from "prop-types";
import Title                 from "./title";
import Gateway               from "qidigo-components/gateway";
import {
	defineMessages,
	FormattedMessage
} from "react-intl";
import {IndexLink, Link}     from "react-router/es";

import {path}                from "@app/lib/routes";

import Page                  from "@app/components/page.js";
import HTML                  from "@app/components/html";
import SideNav               from "@app/components/side-nav.js";
import GroupLink             from "@app/components/group_link";
import ShareMenu             from "@app/components/share_menu";

import AgeRestriction        from "@app/views/shared/age_restriction";
import ScheduleFragment      from "@app/views/shared/groups/schedule";
import SessionTimesFragment  from "@app/views/shared/groups/sessions_times";
import DurationFragment      from "@app/views/shared/groups/duration";
import RemainingPlacesWidget from "@app/views/shared/groups/remaining_places";
import GenderRestrictions from "../GenderRestrictions";

const translations = defineMessages({
	"submenu.share":     {id: "qidigo.group.layout.submenu.share", defaultMessage: "Partager"},

	"informations.duration": {id: "qidigo.group.informations.duration", defaultMessage: "Durée"},
	"restriction.gender": {id: "qidigo.group.restriction.gender", defaultMessage: "Genre"},
	"informations.remaining_places": {id: "qidigo.group.informations.remaining_places", defaultMessage: "Places restantes"},
	"restriction.age": {id: "qidigo.group.restriction.age", defaultMessage: "Âge"},
	"informations.schedule": {id: "qidigo.group.informations.schedule", defaultMessage: "Plage horaire"},
	"informations.location": {id: "qidigo.group.informations.location", defaultMessage: "Emplacement"},
	"informations.supervisors": {id: "qidigo.group.informations.supervisors", defaultMessage: "Responsable{count, plural, =0 {} one {} other {s}}"},
});
const menuTranslations = defineMessages({
	"groups":          {id: "qidigo.group.layout.menu.groups", defaultMessage: "Groupes"},
	"calendar": {id: "qidigo.group.layout.menu.groups.pass", defaultMessage: "À la carte"},
	"schedule": {id: "qidigo.group.layout.menu.groups.schedule", defaultMessage: "Horaire"},
	"contact": {id: "qidigo.group.layout.menu.contact", defaultMessage: "Emplacement"},
});

/**
 * Présente une page dans la hiérarchie du groupe, avec un bandeau
 * omniprésent pour le groupe.
 */
const GroupLayout = (props, context) => {
	const {
		children,
		group,
		organization,
		activity,
		onShare,
		showShare,
		sessions,
		offers,
		...leftOverProps
	} = props;

	const childProps = Object.assign({group, organization, offers}, leftOverProps);

	// Params for URL building.
	const params = {orgID: organization.slug, activityID: activity.id, groupID: group.id};
	const nav =
		<SideNav className="group-navigation"
			menu={[
				{key: "groups", link: path("group", params), translation: menuTranslations["groups"], type: IndexLink},
				{key: "schedule", link: path("group.schedule", params), translation: menuTranslations["calendar"]},
				{key: "contact", link: path("group.contact", params), translation: menuTranslations["contact"]},
			]}
		/>
		;

	const url = path("group", params, {full: true});
	const shouldDisplayWholeSessionRemainingPlaces = offers
		&& offers.filter(x => x.plan.type_plan === 'SESSION').length > 0;

	return (
		<Page name="group">
			<Title
				organization={organization}
				activity={activity}
				group={group}
			/>
			<header>
				<div className="group-header--description">
					<Gateway into="activity-header">
						<div className="group-header--projection">
							{ showShare ? <ShareMenu url={url} /> : null }
							<ul className="group-header--links">
								<li className="group-header--link-share">
									<a onClick={onShare} className={`${showShare ? "active" : ""}`}>
										<FormattedMessage {...translations["submenu.share"]} />
									</a>
								</li>
							</ul>
						</div>
					</Gateway>
					<h1>
						<GroupLink group={{...group, activity: {...activity, organization}}} />
					</h1>
					<HTML contents={group.description} />
					<div className="group--informations">
						<ul className="group--details">
							<li>
								<h5><FormattedMessage {...translations["informations.duration"]} /></h5>
								<DurationFragment
									duration={group.duration}
								/>
							</li>
							<li>
								<h5><FormattedMessage {...translations["restriction.gender"]} /></h5>
								<GenderRestrictions
									firstToUpper={true}
									genders={group.restrictions_gender}
									targetMemberMessage={""}
									forPassModal={false}
								/>
							</li>

							<li>
								<h5><FormattedMessage {...translations["informations.schedule"]} /></h5>
								<span>
									{ Object.keys(group.schedule).length === 0 ?
										<SessionTimesFragment
											firstToUpper={true}
											sessions={sessions}
										/> :
										<ScheduleFragment
											firstToUpper={true}
											times={group.schedule}
										/>
									}
								</span>
							</li>
							<li>
								<h5><FormattedMessage {...translations["restriction.age"]} /></h5>
								<span>
									<AgeRestriction
										firstToUpper={true}
										min={group.restriction_age_min}
										max={group.restriction_age_max}
									/>
								</span>
							</li>
							{
								shouldDisplayWholeSessionRemainingPlaces &&
								<li>
									<h5><FormattedMessage {...translations["informations.remaining_places"]} /></h5>
									<RemainingPlacesWidget
										firstToUpper={true}
										num={group.nbr_places_remaining}
									/>
								</li>
							}
							<li>
								<h5><FormattedMessage {...translations["informations.location"]} /></h5>
								<Link to={path("group.contact", params)}>
									{group.location.full_name}
								</Link>
							</li>
						</ul>
						{
							group["supervisors"] && group["supervisors"].length > 0 ?
								<div className="group--supervisors">
									<h5><FormattedMessage {...translations["informations.supervisors"]} values={{count: group["supervisors"].length}} /></h5>
									<ul>
										{
											(group["supervisors"]||[]).map((supervisor) => <li>
												{supervisor["full_name"]}
											</li>
											)
										}
									</ul>
								</div> : null
						}
					</div>
				</div>
			</header>
			<div className="group-page--layout">
				{nav}
				<section>
					<div className="group-page-child">
						{children && React.cloneElement(children, childProps)}
					</div>
				</section>
			</div>
		</Page>
	);
};

GroupLayout.propTypes = {
	organization: PropTypes.object,
	group: PropTypes.object,
	activity: PropTypes.object,
	sessions: PropTypes.array,
	offers: PropTypes.array,
	onShare: PropTypes.func.isRequired,
	showShare: PropTypes.bool.isRequired,
};


export default GroupLayout;
