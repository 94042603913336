import React                 from "react";
import PropTypes             from "prop-types";
import ActivityTitle         from "../activity/Title";

const Title = ({group, title, children, ...props}) =>
	<ActivityTitle {...props}
		title={`${group["name"]}${title ? " — " + title : ""}`}
	>
		{children}
	</ActivityTitle>
	;

Title.propTypes = {
	title: PropTypes.string,
	organization: PropTypes.object.isRequired,
	activity: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
};

export default Title;
