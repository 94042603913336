import React                 from "react";
import PropTypes             from "prop-types";
import Image                 from "qidigo-components/image.js";

/**
 * Wrap une <Image> afin de spécifier à un endroit certaines règles.
 */
const Avatar = (props) => {
	const {className, src, ...leftoverProps} = props;
	const classNames = ["avatar-image", className];

	return (
		<Image
			className={classNames.join(" ")}
			src={src}
			{...leftoverProps}
		/>
	);
};

Avatar.propTypes = {
	className:    PropTypes.string,
	src:          PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
};

Avatar.defaultProps = {
	src: "",
};

export default Avatar;

