export function trackEvent(eventName, params) {
  if (window.ga) {
    window.ga(
      'event',
      eventName,
      params
    );
    window.ga(
      `customer.event`,
      eventName,
      params
    );
  }

  if (window.gtag) {
    window.gtag(
      'event',
      eventName,
      params
    );
  }
}
