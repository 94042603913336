import * as React from "react";
import {Component} from "react";

// @ts-ignore
import PropTypes from "prop-types";
import Login from "./Login";
import Register from "./Register";
import SocialLogin from "./SocialLogin";
import SeparationLine from "../SeparationLine";
import {ISession} from "../../types";

interface IConnectionProps {
    handleNextStep: () => void;
    handleShowConsentPage: () => void;
    organizationId: number;
    session: ISession,
}

interface IConnectionState {
    loading: boolean;
    hasErrors: boolean;
    errors: string,
    showCreateAccountPage: boolean
}

class Connection extends Component<IConnectionProps, IConnectionState> {

    constructor(props: IConnectionProps) {
        super(props);

        this.state = {
            loading: false,
            hasErrors: false,
            errors: "",
            showCreateAccountPage: false,
        }
    }

    changeConnectionPage = () => {
        this.setState({
            showCreateAccountPage: !this.state.showCreateAccountPage
        })
    }

    render() {
        return (
            <div className={"pass-selection-login"}>
                <SocialLogin
                    session={this.props.session}
                />
                <SeparationLine isVertical={false}/>
                {
                    !this.state.showCreateAccountPage
                        ? <Login
                            handleNextStep={this.props.handleNextStep}
                            goToRegister={this.changeConnectionPage}
                            handleShowConsentPage={this.props.handleShowConsentPage}
                            organizationId={this.props.organizationId}
                        />
                        : <Register
                            handleNextStep={this.props.handleNextStep}
                            session={this.props.session}
                            goToLogin={this.changeConnectionPage}
                        />
                }
            </div>
        );
    }
}

// @ts-ignore
Login.contextTypes = {
    intl: PropTypes.object,
};

export default Connection;
