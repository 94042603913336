import React                 from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";
import moment                from "moment";

const translations = defineMessages({
	year:  {id: "qidigo.shared.birthday.age.year", defaultMessage: "{years} {years, plural, one {an} other {ans}}"},
	month: {id: "qidigo.shared.birthday.age.month",   defaultMessage: "{months} mois"},
});

/**
 * Effectue le formattage d'âge pour une string.
 *
 * Doit se faire donner le formatteur de react-intl.
 *
 * (Utilise une string YYYY-MM-DD)
 */
export const age = (formatMessage, birthday) => {
	birthday = moment(birthday, "YYYY-MM-DD");

	// Le nombre de mois et d'années complètes.
	const months = Math.floor(moment().diff(birthday, "month", true)).toString();
	const years =  Math.floor(moment().diff(birthday, "year", true)).toString();

	const values = {months, years};

	if (years >= 1) {
		return formatMessage(translations["year"], values);
	}
	else {
		return formatMessage(translations["month"], values);
	}
};

/**
 * Implémente le formattage de l'âge en component React.
 *
 * (Utilise une string YYYY-MM-DD)
 */
export const UserAge = (props, context) => {
	const {birthday} = props;
	const {formatMessage} = context.intl;

	return (
		<span className="user--age">
			<span className="user-birthday--age">
				{age(formatMessage, birthday)}
			</span>
		</span>
	);
};

UserAge.propTypes = {
	birthday: PropTypes.string,
};

UserAge.contextTypes = {
	intl: PropTypes.object,
};

export default UserAge;
