/* eslint no-console: [0] */
import "isomorphic-fetch";
import { defineMessages } from "react-intl";
import Logger from "qidigo-logger";
import * as Sentry from "@sentry/browser";
import Auth from "qidigo-auth";
import { getLocale } from "qidigo-i18n";
import env from "qidigo-env";

const errorMessages = defineMessages({
    genericError: { id: "qidigo.fetch.errors.generic_error", defaultMessage: "Une erreur inattendue est survenue. Veuillez réessayer sous peu." },
});

const delay = (fn, ms) => new Promise((resolve) => setTimeout(() => resolve(fn()), ms))

const retry = async (fn, maxAttempts) => {
    const execute = async (attempt) => {
        try {
            return await fn()
        } catch (err) {
            if (attempt <= maxAttempts) {
                const nextAttempt = attempt + 1
                // NOTE: incrementing delay in 2 times on each iteration
                const delayMs = 1000 * Math.pow(2, nextAttempt)
                return delay(() => execute(nextAttempt), delayMs)
            } else {
                throw err
            }
        }
    }
    return execute(1)
}

const localDomain = 'http://localhost:8081';
const prodDomain = 'https://api.qidigo.com';
const devEnv = 'development';

const getApiUri = () => process.env.NODE_ENV === devEnv ? localDomain : prodDomain;

function getHost(input, newInit) {

    const uuidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

    const passesSessionsRegex = new RegExp('^passes/[0-9]*/sessions*');
    if (passesSessionsRegex.test(input)) {
        return getApiUri();
    }

    const authenticateRegex = new RegExp('^authenticate*');
    if (authenticateRegex.test(input)) {
        return getApiUri();
    }

    const countriesRegex = new RegExp('^countries*');
    if (countriesRegex.test(input)) {
        return getApiUri();
    }

    const organizationRegex = new RegExp('^organizations\\?(slug|id).*');
    if (organizationRegex.test(input)) {
        return getApiUri();
    }

    const emailSubscriptionRegex = new RegExp('^users/[0-9]*/email_subscriptions*');
    if (emailSubscriptionRegex.test(input)) {
        return getApiUri();
    }

    const userWaitingListRegex = new RegExp('^users/[0-9]*/waiting_lists*');
    if (userWaitingListRegex.test(input)) {
        return getApiUri();
    }

    const groupOffersRegex = new RegExp('^groups/[0-9]*/offers*');
    if (groupOffersRegex.test(input)) {
        return getApiUri();
    }

    const groupRegex = new RegExp('^groups/[0-9]*$');
    if (groupRegex.test(input)) {
        return getApiUri();
    }

    const activityRegex = new RegExp('^activities/[0-9]*')
    if (activityRegex.test(input)) {
        return getApiUri();
    }

    const activitySessionsRegex = new RegExp('^activities/[0-9]*/sessions*');
    if (activitySessionsRegex.test(input)) {
        return getApiUri();
    }

    const activityGroupsOffersRegex = new RegExp('^activities/[0-9]*/groups_offers*');
    if (activityGroupsOffersRegex.test(input)) {
        return getApiUri();
    }

    const membershipTemplateOffersRegex = new RegExp('^membership_templates/[0-9]*/offers*');
    if (membershipTemplateOffersRegex.test(input)) {
        return getApiUri();
    }

    const profilReservationRegex = new RegExp('^users/[0-9]*/reservations*');
    if (profilReservationRegex.test(input)) {
        return getApiUri();
    }

    const profilSlipItemsRegex = new RegExp('^users/[0-9]*/rl24/items*');
    if (profilSlipItemsRegex.test(input)) {
        return getApiUri();
    }

    const profileSlipsRegex = new RegExp('^users/[0-9]*/rl24$');
    if (profileSlipsRegex.test(input)) {
        return getApiUri();
    }

    const profileSlipPDFRegex = new RegExp('^users/[0-9]*/rl24/[0-9]*$');
    if (profileSlipPDFRegex.test(input)) {
        return getApiUri();
    }

    const profileSlipUserRecipientsRegex = new RegExp('^users/[0-9]*/rl24/recipients$');
    if (profileSlipUserRecipientsRegex.test(input)) {
        return getApiUri();
    }

    const profileSlipRecipientsRegex = new RegExp('^rl24/recipients*');
    if (profileSlipRecipientsRegex.test(input)) {
        return getApiUri();
    }

    const profileSlipUserSinRegex = new RegExp('^users/[0-9]*/family_nas$');
    if (profileSlipUserSinRegex.test(input)) {
        return getApiUri();
    }

    if (input === 'legacy_auth' || input === 'whoami') {
        return getApiUri();
    }

    const invoiceListRegex = new RegExp('^users/[0-9]*/invoices$');
    if (invoiceListRegex.test(input)) {
        return getApiUri();
    }

    const invoiceShowRegex = new RegExp('^users/[0-9]*/invoices/[0-9]+$');
    if (invoiceShowRegex.test(input)) {
        return getApiUri();
    }

    const cartRegex = new RegExp('^cart/?[0-9]*');
    if (cartRegex.test(input)) {
        return getApiUri();
    }

    const batchCartRegex = new RegExp('^batch_cart$');
    if (batchCartRegex.test(input)) {
        return getApiUri();
    }

    const simulationOrderWithCartRegex = new RegExp('^simulation/order_with_cart*');
    if (simulationOrderWithCartRegex.test(input)) {
        return getApiUri();
    }

    const organizationSessionsRegex = new RegExp('^organizations/[0-9]*/sessions*');
    if (organizationSessionsRegex.test(input)) {
        return getApiUri();
    }
    
    const familyMemberRegex = new RegExp('^users/[0-9]*/family_members*');
    if (familyMemberRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const deleteFamilyMemberRegex = new RegExp('^users/[0-9]*/family_members/[0-9]+');
    if (deleteFamilyMemberRegex.test(input) && newInit.method === 'DELETE') {
        return getApiUri();
    }

    const activityGroupsRegex = new RegExp('^activities/[0-9]*/groups*');
    if (activityGroupsRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const userActivitiesRegex = new RegExp('^users/[0-9]*/activities*');
    if (userActivitiesRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const userReservationRegex = new RegExp('^reservations/[0-9]+');
    if (userReservationRegex.test(input) && newInit.method === 'DELETE') {
        return getApiUri();
    }

    const userAbsentRegex = new RegExp('^reservations/[0-9]+/absent');
    if (userAbsentRegex.test(input) && newInit.method === 'POST') {
        return getApiUri();
    }

    const organizationActivitiesRegex = new RegExp('^organizations/[0-9]*/activities*');
    if (organizationActivitiesRegex.test(input)) {
        return getApiUri();
    }

    const citiesRegex = new RegExp('^cities*');
    if (citiesRegex.test(input)) {
        return getApiUri();
    }

    const customersFormRegex = new RegExp('^order/customers/[0-9]*/pending-order/forms');
    if (customersFormRegex.test(input)) {
    return getApiUri();
    }

    const userPhoneNumbersRegex = new RegExp('^users/[0-9]+/phone_numbers*');
    if (userPhoneNumbersRegex.test(input)) {
        return getApiUri();
    }

    const phoneNumbersRegex = new RegExp('^phone_numbers*');
    if (phoneNumbersRegex.test(input)) {
        return getApiUri();
    }

    const orderContactRegex = new RegExp('^order/customers*');
    if (orderContactRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }


    const orderRegex = new RegExp(`^order/customers/${uuidPattern}/pending-order*`);
    if (orderRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const paymentOptionsRegex = new RegExp(`^order/customers/${uuidPattern}/pending-order/payment-options$`);
    if (paymentOptionsRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const installmentSettlementOptionsRegex = new RegExp(`^settlement/customers/${uuidPattern}/invoices/${uuidPattern}/installments*`);
    if (installmentSettlementOptionsRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const settlementPaymentOptionsRegex = new RegExp(`^settlement/customers/${uuidPattern}/invoices/${uuidPattern}/payment-options$`);
    if (settlementPaymentOptionsRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const contactCreditRegex = new RegExp(`^customers/${uuidPattern}/credit$`);
    if (contactCreditRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const companyCreditRegex = new RegExp(`^companies/${uuidPattern}/credit*`);
    if (companyCreditRegex.test(input) && newInit.method === 'GET') {
        return getApiUri();
    }

    const userAddressesRegex = new RegExp('^users/[0-9]+/addresses*');
    if (userAddressesRegex.test(input)) {
        return getApiUri();
    }

    const addressesNumbersRegex = new RegExp('^addresses*');
    if (addressesNumbersRegex.test(input)) {
        return getApiUri();
    }

    const paysafeBankAccountTokenRegex = new RegExp('^paysafe-tokenize-bank-account*');
    if (paysafeBankAccountTokenRegex.test(input)) {
        return getApiUri();
    }

    const paysafeBankAccountTransitRegex = new RegExp('^transit-validation*');
    if (paysafeBankAccountTransitRegex.test(input)) {
        return getApiUri();
    }

    const settlementRegex = new RegExp(`^contacts/${uuidPattern}/pending-order/settlements$`);
    if (settlementRegex.test(input)) {
        return getApiUri();
    }

    const delayedSettlementRegex = new RegExp(`^contacts/${uuidPattern}/invoices/${uuidPattern}/settlements$`);
    if (delayedSettlementRegex.test(input)) {
        return getApiUri();
    }

    const contactConfirmationRegex = new RegExp(`^contacts/confirm-contact-request/${uuidPattern}$`);
    if (contactConfirmationRegex.test(input)) {
        return getApiUri();
    }
    const uuidOrIntegerIdPattern  = '(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})|([0-9]+))'
    const organizationPassesRegex = new RegExp(`^organizations/${uuidOrIntegerIdPattern}/offered-passes$`);
    if (organizationPassesRegex.test(input)) {
        return getApiUri();
    }

    const contactRegex = new RegExp(`^contacts$`);
    if (contactRegex.test(input)) {
        return getApiUri();
    }

    const bankAccountTransitRestrictionRegex = new RegExp(`^banks$`);
    if (bankAccountTransitRestrictionRegex.test(input)) {
        return getApiUri();
    }

    const messagesRegex = new RegExp(`^messages$`);
    if (messagesRegex.test(input) && newInit.method === 'POST') {
        return getApiUri();
    }
    const updateMessagesRegex = new RegExp('^messages/[0-9]+');
    if (updateMessagesRegex.test(input) && newInit.method === 'PATCH') {
        return getApiUri();
    }

    const sendMessagesRegex = new RegExp(`^messages/[0-9]+/send$`);
    if (sendMessagesRegex.test(input) && newInit.method === 'POST') {
        return getApiUri();
    }

    const userPasswordRegex = new RegExp(`^users/[0-9]+/password$`);
    if (userPasswordRegex.test(input)) {
        return getApiUri();
    }

    return process.env.NODE_ENV === devEnv ? "http://localhost:3030" : "https://www.qidigo.com";

}

/**
 * Wrapper autour de isomorphic fetch et fetch, faisant un peu
 * de magie permettant d'automatiser un *status* de load global
 * et permettant de faire du logging bien mérité.
 *
 * N'oublions pas la gestion du header `Authorization` automatisée.
 *
 * ### Note à propos d'upload
 *
 * Il faut envoyer le formulaire de manière "classique" et non en JSON.
 * Il faut donc passer un FormData comme body.
 *
 */
class QidigoFetch {
    /**
     * Wrapper autour de `fetch` avec des options par défaut.
     */
    static fetch(input, init = {}) {
        const defaults = {
            headers: {
                "Accept": "application/json",
                "Accept-Language": getLocale(),
                "Content-Type": "application/json",
            },
            mode: "cors",
            redirect: "follow",
        };
        const token = Auth.getToken();
        if (token) {
            defaults.headers["Authorization"] = `Bearer ${token}`;
            // Side-steps issues with Fetch polyfills where there would be
            // two Authorization headers, one Basic and one Bearer on
            // staging environment.
            defaults.headers["Bearer-Authorization"] = `Bearer ${token}`;
        }

        // Copie les options par défaut.
        const newInit = Object.assign({}, defaults, init);
        // Et faire de même sur les headers.
        newInit.headers = Object.assign({}, defaults.headers, init.headers);

        //
        // Reset content-type when posting FormData instead of json.
        // (Inverse detection of json is harder)
        //
        if (newInit.body && newInit.body instanceof FormData) {
            delete newInit.headers["Content-Type"];
        }
        const host = getHost(input, newInit)
        Sentry.addBreadcrumb({
            category: 'fetch',
            message: JSON.stringify({
                host,
                input,
                requestData: newInit,
                body: newInit.body && newInit.body instanceof FormData
                    ? (newInit.body.entries && newInit.body.entries())
                    : newInit.body
            }),
            level: Sentry.Severity.Debug
        })

        return retry(() => fetch(`${host}/api/v1/${input}`, newInit), 3)
    }

    /**
     * Permet d'utiliser le *handler* de load qui fait *quelque chose*
     * d'indéfini. Sans le *handler*, donne un *passthrough*.
     */
    static load() {
        if (QidigoFetch.loadingPromise) {
            return QidigoFetch.loadingPromise();
        }

        return (res) => res;
    }

    /**
     * Default logging handled for Fetch requests.
     * This mostly handles logging 500 errors.
     */
    static defaultErrorHandler(response, options) {
        options = options || {};
        const requestBody = options["body"] || null;

        // Default error
        const errors = { message: "", fields: {} };
        // FIXME : Do translation here
        errors.message = errorMessages.genericError.defaultMessage;

        if (process.env.NODE_ENV === "development" || env["server"]) {
            console.error("QidigoFetch.defaultErrorHandler:");
            console.error(response);
        }

        console.info("From: qidigo-fetch/index.js, static defaultErrorHandler");
        if (response instanceof Response) {
            errors.response = Object.assign({}, response);

            return response.json()
                .then((body) => {
                    errors.response.body = body;
                    const msg = `[Fetch] Error ${response["status"]}: ${response["statusText"]}`;
                    const extractedResponse = {
                        type: response["type"],
                        url: response["url"],
                        status: response["status"],
                        statusText: response["statusText"],
                    };

                    Sentry.withScope(scope => {
                        scope.setExtra('response', extractedResponse);
                        scope.setExtra('request', {
                            body: requestBody,
                        })
                        Sentry.captureMessage(msg, 'error');
                    });

                    return Promise.reject(errors);
                });
        }
        else {
            console.warn("Unexpected type of error in defaultErrorHandler");
            Sentry.withScope(scope => {
                scope.setExtra('request', {
                    body: requestBody,
                });
                Sentry.captureException(response);
            });
        }

        return Promise.reject(errors);
    }

    /**
     * Effectue une requête, avec un comportement par défaut particulier:
     *
     *   * Attache une promise configurable.
     *   * Attache des error handlers.
     *   * Attache un logger.
     *
     */
    static doRequest(method, resource, body = undefined) {
        // Promise à enchaîner.
        const finish = QidigoFetch.load();
        Logger.ajaxDebugLog(method, resource, body);

        // Do not stringify FormData.
        if (body && !(body instanceof FormData)) {
            body = JSON.stringify(body);
        }
        const init = { method, body };

        return QidigoFetch.fetch(resource, init)
            // Log error...
            .then((response) => {
                if (response.status >= 500 && response.status < 600) {
                    return Logger.ajaxErrorHandler(response);
                }

                return response;
            })
            //
            // Default error.
            // 4xx and 5xx are not caught here.
            //
            .catch((r) => QidigoFetch.defaultErrorHandler(r, { body }))
            // Either use the response or do error stuff.
            .then((response) => {
                // Is everything fine?
                if (response.ok) {
                    if (response.status === 204 || response.status === 202) {
                        return {};
                    }

                    return response.json();
                }

                //
                // Something happened...
                //

                // 422 mostly means invalid rails record.
                if (response.status === 422) {
                    return response.json()
                        .then((body) => Promise.reject(body));
                }

                // Unexpected errors means default error.
                if (response.status >= 500 && response.status < 600) {
                    return QidigoFetch.defaultErrorHandler(response, { body });
                }

                // Otherwise, return the response. The receiving end should know best.
                return Promise.reject(response);
            })
            // Always pop a load state...
            .then(finish, (...r) => {
                finish();

                return Promise.reject(...r);
            })
            ;
    }

    /**
     * Effectue un GET.
     */
    static get(resource) {
        return QidigoFetch.doRequest("GET", resource);
    }

    /**
     * Effectue un DELETE.
     */
    static delete(resource, body) {
        return QidigoFetch.doRequest("DELETE", resource, body);
    }

    /**
     * Effectue un POST.
     */
    static post(resource, body) {
        return QidigoFetch.doRequest("POST", resource, body);
    }

    /**
     * Effectue un PUT.
     */
    static put(resource, body) {
        return QidigoFetch.doRequest("PUT", resource, body);
    }

    /**
     * Effectue un PATCH.
     */
    static patch(resource, body) {
        return QidigoFetch.doRequest("PATCH", resource, body);
    }

    /**
     * Affecte une promise à utiliser comme *passthrough* à toutes les requêtes.
     *
     * Il est attendu que le
     * *handler* retourne une fonction apte à être utilisée dans un *Promise*
     * et cause un *side-effect*, qui est de présenter un état de chargement.
     */
    static setLoadingPromise(signal) {
        QidigoFetch.loadingPromise = signal;
    }

    /**
     * Fonction à utiliser pour `setState(errors)` pour la combinaison fetch/form
     *
     * Cette fonction s'utilise comme suit:
     *
     * ```
     *     Fetch.post(...)
     *     // [...]
     *     .catch(Fetch.handleErrors(this))
     *     ;
     * ```
     *
     * Cette fonction `reject` les erreurs, mais consomme/catch correctement les
     * *messages d'erreurs* de l'API.
     *
     * C'est-à-dire, gère correctement l'affichage des messages d'erreurs de l'API
     * et envoie l'erreur au handler générique d'erreurs du site.
     *
     * ### Paramètres
     *
     *   * context : `this` pour la fonction, le component react.
     *   * options : (facultatif) *aucune option pour l'instant*.
     */
    static handleErrors(context, options) {
        options = options || {};

        // Renvoie la fonction qui handle la promise.
        return function (errors) {
            if (errors instanceof Error) { return Promise.reject(errors); }
            const { message, fields } = errors;
            this && this.setState && this.setState({
                saving: false,
                errors: { message, fields }
            });

            return null;
        }.bind(context);
    }
}

// Static variable.
QidigoFetch.loadingPromise = null;


if (process.env.NODE_ENV === "development") {
    if (typeof window !== "undefined") {
        if (!window.qidigo) { window.qidigo = {}; }
        if (!window.qidigo.fetch) {
            window.qidigo.fetch = {};
            const f = window.qidigo.fetch;
            window.qidigo.fetch.unwrapped = QidigoFetch;
            ["get", "delete", "post", "put", "patch"].map((k) => {
                f[k] = (...args) => QidigoFetch[k](...args)
                    .then((response) => {
                        console.info(response)

                        return response;
                    })
                    .catch((err) => {
                        console.error(err)

                        return err;
                    })
            });
        }
    }
}

export default QidigoFetch;
export {getApiUri};
