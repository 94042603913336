import React, {Component} from "react";
import PropTypes from "prop-types";
import OverlayLayout from "@app/views/overlay/_layout";
import PhoneNumbers from "./phone_numbers";
import PhoneNumber from "./phone_number";

/**
 * Provides an internally-consistent "pseudo-router" to use the phone numbers editor.
 */
class OverlayPhoneNumberEditor extends Component {
	constructor() {
		super();
		this.state = {
			id: null,
		};
	}
	render() {
		const {close} = this.props;
		const Editor = this.state.id ? PhoneNumber : PhoneNumbers;
		const {id} = this.state;

		return (
			<OverlayLayout close={close}>
				<div className="overlay-phone-number-editor">
					<Editor
						id={id && id !== "new" ? id : null}
						getNewPhoneNumberLinkProperties={() => ({onClick: () => this.setState({id: "new"})})}
						getEditPhoneNumberLinkProperties={(phone_number) => ({onClick: () => this.setState({id: phone_number.id})})}
						goToListing={() => this.setState({id: null})}
					/>
				</div>
			</OverlayLayout>
		);
	}
}

OverlayPhoneNumberEditor.propTypes = {
	close: PropTypes.func.isRequired,
};

export default OverlayPhoneNumberEditor;
