import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Fetch                 from "qidigo-fetch";
import Logger                from "qidigo-logger";

import Loading               from "qidigo-components/loading";
import UserAddressesPage     from "@app/views/dashboard/addresses";

/**
 * "Contrôleur" pour le carnet d'adresses.
 *
 * UNIQUEMENT LA LISTE DES ADRESSES.
 *
 * @extends {Component}
 */
class UserAddressesController extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			errors:  false,
			saving:  false,
			addresses: null,
		};
	}

	//
	// Fetch the infos on mount.
	//
	componentWillMount() {
		// (The context is not available before mount.)
		this.fetchUserAddresses();
	}

	fetchUserAddresses() {
		Fetch.get(`users/${this.context.loggedUser.id}/addresses`)
		.then((response) => {
			const addresses = response;
			this.setState({loading: false, addresses});

			return response;
		})
		.catch(Logger.catcher)
		.catch((err) => {
			this.setState({loading: false, errors: err});
		});
	}

	/**
	 * Spécifie une adresse comme adresse par défaut.
	 */
	setDefault(id) {
		this.setState({loading: true, errors: null});
		Fetch.post(`users/${this.context.loggedUser.id}/addresses/default`, {
			["address_id"]: id
		})
		.then((response) => {
			this.fetchUserAddresses();

			return null;
		})
		.catch(Logger.catcher)
		.catch((err) => {
			this.setState({loading: false, errors: err});
		});
	}

	render() {
		const {
			...props
		} = this.props;
		if (this.state.loading) { return <Loading />; }

		return <UserAddressesPage
			addresses={this.state.addresses}
			setDefault={(id) => this.setDefault(id)}
			{...props}
		/>;
	}
}

UserAddressesController.contextTypes = {
	loggedUser: PropTypes.object,
};

UserAddressesController.propTypes = {
	getNewAddressLinkProperties: PropTypes.func.isRequired,
	getEditAddressLinkProperties: PropTypes.func.isRequired,
};

export default UserAddressesController;
