import last from "lodash/last";
import {
	defineMessages,
} from "react-intl";

const translations = defineMessages({
	and: {id: "qidigo.enumerate.and", defaultMessage: "et"},
});

const enumerate = (items, formatMessage) => {
	const firstParts = items.slice(0, -1).join(", ");
	if (firstParts) {
		return [firstParts, last(items)].join(` ${formatMessage(translations["and"])} `);
	}

	return last(items);
};

export default enumerate;