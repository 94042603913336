import * as React from "react";
import {Component} from "react";
// @ts-ignore
import BackToLastLocation from "@app/components/back-to-last-location.js";
// @ts-ignore
import QidigoSessionStore from "qidigo-sessionstore";
import InstallmentWarning from "../installmentWarning";
import {
    IBillingViewProps, IBillingViewState, IInstallment,
    ISettlement,
    ISummary,
} from "../types";

// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Loading from 'qidigo-components/loading';
// @ts-ignore
import Checkbox from "qidigo-form/checkbox";
// @ts-ignore
import Date    from "qidigo-i18n/date";
// @ts-ignore
import Money   from "@app/components/money";
import Summary from "../summary/summary";
import PaymentErrors from "../payment/paymentErrors";
import FinalizeBox from "../finalizeBox";
import { defineMessages, FormattedMessage } from "react-intl";


const translations = defineMessages({
    "settlement_helmet": {
        id: "qidigo.billing.settlement_helmet",
        defaultMessage: "Paiement"
    },

    "settlement_summary_label": {
        id: "qidigo.billing.settlement_summary_label",
        defaultMessage: "Sommaire de la facture #{id}"
    },
})

export interface ISettlementViewProps extends IBillingViewProps {
    settlement: ISettlement,
    invoiceId: string,
    installmentList: Array<IInstallment>,
    selectedInstallmentId: string | null
}

class SettlementView extends Component<ISettlementViewProps, IBillingViewState> {
    constructor(props: ISettlementViewProps) {
        super(props);
        this.state = {
            credit: 0
        };
    }

    setCredit = (credit: number) => {
        this.setState({
            credit: credit,
        })
        this.props.applyCredit(credit);
    };

    showPaymentBox = (): boolean => {
        return this.props.settlement.lines.length > 0 && !this.props.isFree;
    }

    showPaymentErrorBox = (): boolean => {
        return this.props.paymentErrors !== undefined
    }

    handleContinueShoppingClicked = () => {
        const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");
        const lastOrg = lastOrgStore.get();
        window.location.href = `/u/${lastOrg["slug"]}`;
    }

    render() {
        const {
            settlement,
            contactCredit,
            paymentMethods,
            installmentList,
            addressList,
            handlePayment,
        } = this.props;

        let errorMessages = (
            <PaymentErrors
                paymentErrors={this.props.paymentErrors}
                organizationName={this.props.organizationName}
                redirectionTimer={this.props.redirectionTimer}
            />
        );

        const summary : ISummary = {
            applied_coupon: null,
            price_detail: settlement.price_detail,
            lines: settlement.lines,
        }

        let hasPaymentOrCredit = false
        if (summary.price_detail) {
            hasPaymentOrCredit =
                summary.price_detail.balance !== summary.price_detail.total
        }

        let summaryLabel = <FormattedMessage {...translations["settlement_summary_label"]} values={{id: this.props.invoiceId,}}/>
        let summaryHelmet = {...translations["settlement_helmet"]};

        return (
            <div id={"billing-page"}>
                <div className={"billing-view--container"}>
                    {
                        this.showPaymentErrorBox() &&
                            <div className={"billing-view--payment-errors-container"}>
                                {errorMessages}
                            </div>
                    }
                    {
                        settlement.installments.length > 0 &&
                        <InstallmentWarning
                            installments={settlement.installments}
                        />
                    }
                    <div className={"billing-view-summary-container" + (!this.showPaymentBox() ? " empty" : "")}>
                        <h1 className={'billing-view--title'}>
                            <FormattedMessage {...summaryHelmet}/>
                        </h1>
                        <Summary
                            summary={summary}
                            invoiceLabel={settlement.label}
                            model={settlement.model}
                            contact_credit={contactCredit}
                            setCredit={this.setCredit}
                            isFree={this.props.isFree}
                            hasCouponErrorOnApplication={false}
                            hasCouponLimitErrorOnApplication={false}
                            handleItemIsBeingDeleted={() => {}}
                            reload={ () => {}}
                            removeLine={() => {}}
                            canRemoveLine={false}
                            isLoading={this.props.processingPayment}
                            hasPriceTotal={false}
                            hasCouponAdjustment={false}
                            hasPaymentOrCredit={hasPaymentOrCredit}
                            helmet={summaryHelmet}
                            label={summaryLabel}
                        />
                    </div>
                    <FinalizeBox
                        isLoading={this.props.processingPayment}
                        shouldShowPaymentBox={this.showPaymentBox()}
                        paymentMethods={paymentMethods}
                        addressList={addressList}
                        installmentList={installmentList}
                        showPaymentErrorBox={this.showPaymentErrorBox()}
                        handleTokenBadRequest={this.props.handleTokenBadRequest}
                        handlePayment={handlePayment}
                        disabled={this.props.processingPayment}
                        paymentErrors={errorMessages}
                        processingPayment={this.props.processingPayment}
                        organizationUuid={this.props.organizationUuid}
                        billingInstallments={this.props.settlement.installments}
                        selectedPaymentMethodId={this.props.selectedPaymentMethodId}
                        version={settlement.version}
                        lineCount={this.props.settlement.lines.length}
                        handleContinueShoppingClicked={this.handleContinueShoppingClicked}
                        credit={this.state.credit}
                        appliedCoupon={null}
                        selectedInstallmentId={this.props.selectedInstallmentId}
                    />
                </div>
            </div>
        )
    }
}

export default SettlementView;
