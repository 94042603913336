import {navigate}            from "qidigo-router";

/**
 * Actions génériques relatives au routeur / routing.
 */
export const REDIRECT = "ROUTER:REDIRECT";
export const ERROR = "ROUTER:ERROR";

/**
 * Effectue une redirection vers une autre ressource.
 *
 * ### Side-effect
 *
 *   * Effectue une navigation dans l'history.
 *
 */
export const redirect = (path, options) => {
	// FIXME : Voir les bonnes pratiques...
	// Pas certain que le side-effect ici soit bon.
	navigate(path, options);

	// L'action permettra d'enregistrer la redirection dans le store.
	// Voir le reducer.
	return {
		type: REDIRECT,
		path,
		options,
	};
};

/**
 * Notifie la présence d'erreur pour le SSR.
 *
 * ### Note
 *
 * Appeler plusieurs fois en un render-cycle est undefined behaviour.
 * Il faut espérer que l'erreur la plus adaptée au SSR sera utilisée.
 *
 * FIXME : Définir le comportement des multiples appels.
 *
 */
export const sendError = (upstreamError) => {
	const error = {status: 500, message: null, stack: null};
	if (upstreamError.status) {
		error.status = upstreamError.status;
	}
	if (upstreamError.message) {
		error.message = upstreamError.message;
	}
	if (upstreamError.stack) {
		error.stack = upstreamError.stack;
	}
	if (upstreamError.response) {
		error.response = upstreamError.response;
	}

	console.error(upstreamError); // eslint-disable-line

	// L'action permettra d'enregistrer la redirection dans le store.
	// Voir le reducer.
	return {
		type: ERROR,
		error,
	};
};
