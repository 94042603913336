import React                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";

import RemainingPlacesWidget from "@app/views/shared/groups/remaining_places";
import Button                from "qidigo-components/button.js";
import Calendar              from "qidigo-calendar/calendar";
import AgendaView            from "qidigo-calendar/agenda";
import MonthView             from "qidigo-calendar/month";
import DateSpan              from "qidigo-i18n/datespan";
import AgeRestriction        from "@app/views/shared/age_restriction";
import GenderRestrictions from "../../GenderRestrictions";

const translations = defineMessages({
	"title.supervisor": {id: "qidigo.sessions.calendar.title.supervisor", defaultMessage: "Responsable{count, plural, =0 {} one {} other {s}}"},
	"no.supervisor": {id: "qidigo.sessions.calendar.no.supervisor", defaultMessage: "Aucun"},
	"title.remaining": {id: "qidigo.sessions.calendar.title.remaining", defaultMessage: "Places restantes"},
	"title.restrictions": {id: "qidigo.sessions.calendar.title.restrictions", defaultMessage: "Restrictions"},
	"button.choose": {id: "qidigo.sessions.calendar.button.choose", defaultMessage: "Choisir"},
	"no.restrictions": {id: "qidigo.sessions.calendar.no.restrictions", defaultMessage: "Aucune restriction"},
});

const EventView = ({event: {title, session}, onClick, className, ...leftOver}) => {
	const {group, location, supervisors} = session;
	const restrictions = [];
	const full = session["nbr_places"] !== null && session["nbr_places_remaining"] <= 0;
	if (group.restrictions_gender !== ['woman', 'man', 'other', 'not_answered']) {
		restrictions.push(
			<div className="session-row__restriction-sex" key="gender">
				<span className="groupfragment--value">
					<GenderRestrictions
						genders={group.restrictions_gender}
						targetMemberMessage={""}
						forPassModal={false}
					/>
				</span>
			</div>
		);
	}
	if (group.restriction_age_min || group.restriction_age_max) {
		restrictions.push(
			<div className="session-row__restriction-age" key="age">
				<AgeRestriction
					min={group.restriction_age_min}
					max={group.restriction_age_max}
				/>
			</div>
		);
	}

	if (restrictions.length === 0) {
		restrictions.push(
			<div className="session-row__restriction-none" key="none">
				<span className="groupfragment--value">
					<FormattedMessage {...translations["no.restrictions"]} />
				</span>
			</div>
		);
	}

	const classes = [className, "session-row"];

	if (full) {
		classes.push("is-full")
	}

	if (group["type_status_id"] !== "OK") {
		classes.push("is-preview");
	}

	return (
		<div onClick={onClick} {...leftOver} className={classes.join(" ")}>
			{/* FIXME : Add media here */}
			<h4>{title}</h4>
			<ul>
				<li className="session-row__main">
					<div>
						<DateSpan
							className=""
							withoutDate={true}
							startDate={session["start_date"]}
							endDate={session["end_date"]}
							allDay={session["is_all_day"]}
						/>
					</div>
					<div>
						{location["label"]}
					</div>
					<div className="session-row__supervisor">
						<ul>
							{
								supervisors.length > 0 ?
									supervisors.map(({full_name}, key) => <li key={key}>{full_name}</li>) :
									null
							}
						</ul>
					</div>
				</li>
				<li className="session-row__restrictions">
					<h5><FormattedMessage {...translations["title.restrictions"]} /></h5>
					{restrictions}
				</li>
				<li className="session-row__places">
					<h5><FormattedMessage {...translations["title.remaining"]} values={{count: supervisors.length}} /></h5>
					<RemainingPlacesWidget
						num={session.nbr_places_remaining}
					/>
				</li>
				<li className="session-row__actions">
					{
						full ? null :
							<Button onClick={onClick}>
								<FormattedMessage {...translations["button.choose"]} />
							</Button>
					}
				</li>
			</ul>
		</div>
	);
};

EventView.propTypes = {
	className: PropTypes.string,
	event: PropTypes.object,
	onClick: PropTypes.func,
};

/**
 */
class CalendarFragment extends React.Component {

	componentDidMount() {
		this.hideDefaultDateTimeHeader();
	}


	componentDidUpdate() {
		this.hideDefaultDateTimeHeader();
	}

	hideDefaultDateTimeHeader() {
		const bigCalendarTimeHeader = document.querySelector('div.rbc-time-header div.rbc-row');

		if (bigCalendarTimeHeader) {
			bigCalendarTimeHeader.style.display = 'none';
		}
	}

	render() {
		const {
			events,
			handleNavigate,
			handleSelection,
			date,
			view,
            visibleToolbar,
            setCurrentView
		} = this.props;

		return (
			<Calendar
				selectable={true}
				events={events.map((event, key) => Object.assign({}, event, {event, agenda: <EventView key={key} event={event} />}) )}
				date={date}
				defaultView="agenda"
				views={{
					agenda: AgendaView,
					week: true,
				}}
				className="qidigo-sessions-calendar"
				visibleToolbar={visibleToolbar}
				popup={true}
				onSelectEvent={(infos, e) => handleSelection(infos, e)}
				onNavigate={handleNavigate}
				onView={(view) => handleNavigate(date, view)}
				view={view}
                setCurrentView={setCurrentView}
			/>
		);
	}
}
CalendarFragment.propTypes = {
	events: PropTypes.array,
	handleNavigate: PropTypes.func.isRequired,
	handleSelection: PropTypes.func.isRequired,
	date: PropTypes.object,
	view: PropTypes.string.isRequired,
	views: PropTypes.object,
    visibleToolbar: PropTypes.bool
};

CalendarFragment.defaultProps = {
	views: {
		month: MonthView,
		agenda: AgendaView,
        week: MonthView
	},
    visibleToolbar: true
};

/**
 * Cette vue est autant la vue *calendrier* que la vue *agenda*
 * de la liste des sessions utilisée pour sélectionner une session
 * à réserver pour les activités à la carte.
 *
 * De cette vue, il est possible de *sélectionner* une session passée.
 * Cette sélection est gérée par le contrôleur.
 */
const CalendarView = (props, context) => {
	const {sessions, ...leftOverProps} = props;

	return (
		<div>
			<CalendarFragment
				{...leftOverProps}
				events={sessions}
			/>
		</div>
	);
};

CalendarView.propTypes = {
	sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CalendarView.defaultProps = {
	sessions: [],
};

export default CalendarView;
