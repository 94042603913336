import React                 from "react";
import PropTypes             from "prop-types";
import {Link}                from "react-router/es";
import {path}                from "@app/lib/routes";

/**
 */
const ActivityLink = (props, context) => {
	const {className, activity, ...leftoverProps} = props;
	const classNames = ["activity-link", className];
	const {formatMessage} = context.intl;
	const {organization} = activity;

	return (
		<Link
			to={path("activity", {orgID: organization["slug"], activityID: activity["id"]})}
			className={classNames.join(" ")}
			{...leftoverProps}
		>
			{activity.name}
		</Link>
	);
};

const idType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number,
]);

ActivityLink.propTypes = {
	className:    PropTypes.string,
	activity: PropTypes.shape({
		id: idType,
		name: PropTypes.string,
		organization: PropTypes.shape({
			slug: PropTypes.string,
		}).isRequired,
	}),
};

ActivityLink.defaultProps = {
};

ActivityLink.contextTypes = {
	intl: PropTypes.object,
};

export default ActivityLink;
