import { defineMessages } from 'react-intl';

const taxTranslations = defineMessages({
    '061f15fa-9cee-47f8-97ba-e1587af2dfab': {
        id: 'qidigo.taxes.name.061f15fa-9cee-47f8-97ba-e1587af2dfab',
        defaultMessage: 'TVH (Nouveau-Brunswick)',
    },
    'b98e73d0-01c6-4b40-be71-f023d8d46ae7': {
        id: 'qidigo.taxes.name.b98e73d0-01c6-4b40-be71-f023d8d46ae7',
        defaultMessage: 'TVH (Nouveau-Brunswick)',
    },
    '480e6425-cb80-4c5c-8b42-1d2fd3c3a327': {
        id: 'qidigo.taxes.name.480e6425-cb80-4c5c-8b42-1d2fd3c3a327',
        defaultMessage: 'TVH (Terre-Neuve-et-Labrador)',
    },
    '59fc3361-4d4e-4a75-90d7-5fc4a3158423': {
        id: 'qidigo.taxes.name.59fc3361-4d4e-4a75-90d7-5fc4a3158423',
        defaultMessage: 'TVH (Terre-Neuve-et-Labrador)',
    },
    '2ff466ca-4a32-4dbb-bc22-1e053c4e1fef': {
        id: 'qidigo.taxes.name.2ff466ca-4a32-4dbb-bc22-1e053c4e1fef',
        defaultMessage: 'TVH (Nouvelle-Écosse)',
    },
    '5a6604c1-0b52-4be8-bab7-ed108cdaab55': {
        id: 'qidigo.taxes.name.5a6604c1-0b52-4be8-bab7-ed108cdaab55',
        defaultMessage: 'TVH (Nouvelle-Écosse)',
    },
    'c233094c-45ec-4bac-9d39-0a460e3d36c0': {
        id: 'qidigo.taxes.name.c233094c-45ec-4bac-9d39-0a460e3d36c0',
        defaultMessage: 'TVH (Ontario)',
    },
    'f331f392-7532-49c4-a020-59950734e8a2': {
        id: 'qidigo.taxes.name.f331f392-7532-49c4-a020-59950734e8a2',
        defaultMessage: 'TVH (Ontario)',
    },
    '1dfb219e-d276-4a27-9709-cf80301d1cfd': {
        id: 'qidigo.taxes.name.1dfb219e-d276-4a27-9709-cf80301d1cfd',
        defaultMessage: 'TVH (Île-du-Prince-Édouard)',
    },
    'b88a687e-c3b9-482c-a8f4-2d120889ceba': {
        id: 'qidigo.taxes.name.b88a687e-c3b9-482c-a8f4-2d120889ceba',
        defaultMessage: 'TVH (Île-du-Prince-Édouard)',
    },
    '9239fe36-9325-49a9-bfb8-c0320ffda758': {
        id: 'qidigo.taxes.name.9239fe36-9325-49a9-bfb8-c0320ffda758',
        defaultMessage: 'TVP (Colombie-Britannique)',
    },
    '057614b2-331d-4cba-b861-70570b0c2639': {
        id: 'qidigo.taxes.name.057614b2-331d-4cba-b861-70570b0c2639',
        defaultMessage: 'TVP (Manitoba)',
    },
    '5375ae35-6931-47a7-8670-0ce53c24aa81': {
        id: 'qidigo.taxes.name.5375ae35-6931-47a7-8670-0ce53c24aa81',
        defaultMessage: 'TVP (Saskatchewan)',
    },
    '7af0a505-8eb5-4370-94d0-a4ecbde0981f': {
        id: 'qidigo.taxes.name.7af0a505-8eb5-4370-94d0-a4ecbde0981f',
        defaultMessage: 'TPS',
    },
    '3420c57d-7b67-4b22-86a3-f58addc33bd1': {
        id: 'qidigo.taxes.name.3420c57d-7b67-4b22-86a3-f58addc33bd1',
        defaultMessage: 'TPS',
    },
    '781ff8a0-38f2-4453-871c-0d37cc2f680a': {
        id: 'qidigo.taxes.name.781ff8a0-38f2-4453-871c-0d37cc2f680a',
        defaultMessage: 'TVQ',
    },
    'eceaf555-3073-46f3-9be4-9664186ddc81': {
        id: 'qidigo.taxes.name.eceaf555-3073-46f3-9be4-9664186ddc81',
        defaultMessage: 'Taxe à taux zéro',
    },
    '23d5d182-8c3b-4ddd-96dd-bdbedcde90f7': {
        id: 'qidigo.taxes.name.23d5d182-8c3b-4ddd-96dd-bdbedcde90f7',
        defaultMessage: 'Taxe à taux zéro',
    },
    'd2807ade-6442-4920-ae1d-122afb708e23': {
        id: 'qidigo.taxes.name.d2807ade-6442-4920-ae1d-122afb708e23',
        defaultMessage: 'Exonérée de taxe',
    },
    'f1929d82-bec5-42cc-b3d4-a7ea785457c8': {
        id: 'qidigo.taxes.name.f1929d82-bec5-42cc-b3d4-a7ea785457c8',
        defaultMessage: 'Exonérée de taxe',
    },
});

export default taxTranslations;