import MiniSignal         from "mini-signals"; // eslint-disable-line

// FIXME : queue/dequeue loadings in here and dispatch signals to the consumers.
// The tokens system for queuing and dequeuing loads should be handled here.
// The loadingBar should only use the queue from here, using signals too.

/**
 * Les *MiniSignals*.
 *
 * Voir les API dans les loadingDispatchers.
 */
export const loadingSignals = {
	addLoading:     new MiniSignal(),
	reportFinished: new MiniSignal(),
};

/**
 * Dispatchers. Pour simplifier l'utilisation.
 */
export class loadingDispatchers {
	/**
	 * Signale qu'un chargement est commencé.
	 *
	 * @param {string}   baseToken  Préfixe à utiliser pour le token. Aide au debug.
	 * @param {function(token)} fn  Callback avec la valeur finale du token.
	 */
	static addLoading(baseToken, fn) {
		// FIXME : Once the queue/dequeue function are in this file, find a way to *return* the token value for a cleaner API.
		loadingSignals.addLoading.dispatch(baseToken, fn);
	}

	/**
	 * Signale qu'un chargement spécifique est complété.
	 *
	 * @param {string} token  Token reçu dans `addLoading()`.
	 */
	static reportFinished(token) {
		loadingSignals.reportFinished.dispatch(token);
	}

	/**
	 * Queue un chargement et le dequeue via une promise *passthrough*.
	 *
	 * À l'appel de la fonction, un load est ajouté, le token conservé et une
	 * fonction apte à être utilisée comme *passthrough* dans une *Promise* est
	 * retournée; fonction qui retire le load précédemment ajouté.
	 */
	static promisedDequeuer() {
		let token = null;

		// Avec les dispatchers, on queue...
		loadingDispatchers.addLoading("promised_load", (t) => token = t);

		// Et on ajoute une fonction qui dequeue.
		return (res) => {
			loadingDispatchers.reportFinished(token);

			return res;
		};
	}
}
