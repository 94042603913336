import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {
	Link,
	IndexLink,
} from "react-router/es";
import {
	defineMessages,
	FormattedMessage
} from "react-intl";

import Page                  from "@app/components/page.js";
import SideNav               from "@app/components/side-nav.js";

import Avatar                from "@app/components/avatar.js";
import BackToLastLocation    from "@app/components/back-to-last-location.js";

const messages = defineMessages({
	headerLikes: {
		id: "qidigo.dashboard.header.likes",
		defaultMessage: "Favoris"
	},
	headerEditInfos: {
		id: "qidigo.dashboard.header.edit_informations",
		defaultMessage: "Mes informations"
	},
	headerAddresses: {
		id: "qidigo.dashboard.header.edit_addresses",
		defaultMessage: "Mes adresses"
	},
	headerFamilyMembers: {
		id: "qidigo.dashboard.header.family_members",
		defaultMessage: "Ma famille"
	},
	headerPhoneNumbers: {
		id: "qidigo.dashboard.header.phone_numbers",
		defaultMessage: "Mes numéros"
	},
	title: {
		id: "qidigo.dashboard.title.profile",
		defaultMessage: "Profil"
	},
});

const menuTranslations = defineMessages({
	"dashboard":       {id: "qidigo.dashboard.menu.dashboard", defaultMessage: "Tableau de bord"},
	"informations":    {id: "qidigo.dashboard.menu.informations", defaultMessage: "Profil"},
	"informations.mine":    {id: "qidigo.dashboard.menu.informations.mine", defaultMessage: "Mes informations"},
	"informations.family":    {id: "qidigo.dashboard.menu.informations.family", defaultMessage: "Famille"},
	"informations.addresses": {id: "qidigo.dashboard.menu.informations.addresses", defaultMessage: "Adresses"},
	"informations.phone":     {id: "qidigo.dashboard.menu.informations.phone", defaultMessage: "Téléphone"},
	"activities":      {id: "qidigo.dashboard.menu.activities", defaultMessage: "Activités"},
	"activities.schedule":   {id: "qidigo.dashboard.menu.activities.schedule", defaultMessage: "Horaire"},
	"activities.activities": {id: "qidigo.dashboard.menu.activities.activities", defaultMessage: "Activités"},
	"activities.pass":       {id: "qidigo.dashboard.menu.activities.pass", defaultMessage: "Passes"},
	"activities.reservations": {id: "qidigo.dashboard.menu.activities.reservations", defaultMessage: "Réservations"},
	"activities.waiting_list": {id: "qidigo.dashboard.menu.activities.waiting_list", defaultMessage: "Listes d'attente"},
	"likes":           {id: "qidigo.dashboard.menu.likes", defaultMessage: "Favoris"},
	"forms":           {id: "qidigo.dashboard.menu.forms", defaultMessage: "Formulaires"},
	"memberships":     {id: "qidigo.dashboard.menu.memberships", defaultMessage: "Abonnements"},
	"invoices":          {id: "qidigo.dashboard.menu.invoices", defaultMessage: "Factures"},
	"invoices.invoices": {id: "qidigo.dashboard.menu.invoices.invoices", defaultMessage: "Factures"},
	"invoices.rl24":     {id: "qidigo.dashboard.menu.invoices.rl24", defaultMessage: "Relevés fiscaux"},
	"documents":       {id: "qidigo.dashboard.menu.documents", defaultMessage: "Documents"},
	"reviews":         {id: "qidigo.dashboard.menu.reviews", defaultMessage: "Évaluations"},
	"settings":        {id: "qidigo.dashboard.menu.settings", defaultMessage: "Paramètres"},
});

/**
 * @extends {Component}
 */
class DashboardBase extends Component {
	render() {
		const {
			children,
			...leftOverProps
		} = this.props;
		const childProps = Object.assign({}, leftOverProps);

		const {
			loggedUser,
		} = this.context;

		if (!loggedUser) { return <div />; }

		const unpaid = loggedUser.unpaid === 0 ? null : <span className="badge">{loggedUser.unpaid}</span>;

		return (
			<Page name="dashboard">
				<BackToLastLocation />
				<header>
					<div className="dashboard--user-fragment">
						<Link to="/dashboard">
							<Avatar
								className="dashboard--user-avatar"
								src={loggedUser.avatar}
								withSpinner={false}
							/>
							<div className="dashboard--user-name">
								<h1><FormattedMessage {...messages.title} /></h1>
								<span className="dashboard--name">
									{loggedUser.full_name}
								</span>
							</div>
						</Link>
					</div>
				</header>
				<div className="dashboard--contents">
					<SideNav className="dashboard-navigation"
						menu={[
							{
								key: "dashboard",    link: "/dashboard",              translation: menuTranslations["dashboard"], type: IndexLink,
							},
							{
								key: "informations", translation: menuTranslations["informations"],
								// Temporary
								sub: [
									{key: "informations.mine", link: "/dashboard/informations",   translation: menuTranslations["informations.mine"]},
									{key: "informations.family", link: "/dashboard/family",       translation: menuTranslations["informations.family"]},
									{key: "informations.addresses", link: "/dashboard/addresses", translation: menuTranslations["informations.addresses"]},
									{key: "informations.phone", link: "/dashboard/phone-numbers", translation: menuTranslations["informations.phone"]},
								]
							},
							{
								key: "activities", translation: menuTranslations["activities"],
								sub: [
									{key: "activities.schedule", link: "/dashboard/schedule", translation: menuTranslations["activities.schedule"]},
									{key: "activities.activities", link: "/dashboard/activities", translation: menuTranslations["activities.activities"]},
									{key: "activities.pass", link: "/dashboard/passes", translation: menuTranslations["activities.pass"]},
									{key: "activities.reservations", link: "/dashboard/reservations", translation: menuTranslations["activities.reservations"]},
									{key: "activities.waiting_list", link: "/dashboard/waiting-lists", translation: menuTranslations["activities.waiting_list"]},
								]
							},
							{key: "forms", link: "/dashboard/forms", translation: menuTranslations["forms"]},
							{key: "memberships", link: "/dashboard/memberships", translation: menuTranslations["memberships"]},
							{
								key: "invoices",     link: "/dashboard/invoices",     translation: menuTranslations["invoices"], children: unpaid,
								sub: [
									{key: "invoices.invoices", link: "/dashboard/invoices", translation: menuTranslations["invoices.invoices"], type: IndexLink},
									{key: "invoices.rl24", link: "/dashboard/invoices/rl24", translation: menuTranslations["invoices.rl24"]},
								]
							},
							//{key: "documents",    link: "/dashboard/documents",    translation: menuTranslations["documents"]},
							//{key: "likes",        link: "/dashboard/likes",        translation: menuTranslations["likes"]},
							{key: "settings",     link: "/dashboard/settings",     translation: menuTranslations["settings"]},
						]}
					/>
					<section className="dashboard"><div className="dashboard--dashboard-box">
						<div className="dashboard--child">
							{children && React.cloneElement(children, childProps)}
						</div>
					</div></section>
				</div>
			</Page>
		);
	}
}

DashboardBase.propTypes = {
};

DashboardBase.contextTypes = {
	loggedUser: PropTypes.object,
};

export default DashboardBase;
