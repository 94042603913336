import {combineReducers} from "redux";
import * as BUY from "../actions/buy";

/**
 * Reducer pour les actions relative au cart.
 */
const cart = (state = {}, action) => {
	// TODO : Synchroniser le state du cart ici.
	// (Même si on piggyback encore /whoami pour transmettre le data)
	switch (action.type) {
		case BUY.ADDING_TO_CART:
			return Object.assign({}, state, {
				adding: true,
			});

		case BUY.ADDED_TO_CART:
			return Object.assign({}, state, {
				adding: false,
			});
	}

	return state;
};

export default combineReducers({cart});
