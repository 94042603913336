import React, {Component} from "react";
import PropTypes from "prop-types";
import OverlayLayout from "@app/views/overlay/_layout";
import Addresses from "./addresses";
import Address from "./address";

/**
 * Provides an internally-consistent "pseudo-router" to use the address editor.
 */
class OverlayAddressEditor extends Component {
	constructor() {
		super();
		this.state = {
			id: null,
		};
	}
	render() {
		const {close} = this.props;
		const Editor = this.state.id ? Address : Addresses;
		const {id} = this.state;

		return (
			<OverlayLayout close={close}>
				<div className="overlay-address-editor">
					<Editor
						id={id && id !== "new" ? id : null}
						getNewAddressLinkProperties={() => ({onClick: () => this.setState({id: "new"})})}
						getEditAddressLinkProperties={(address) => ({onClick: () => this.setState({id: address.id})})}
						goToListing={() => this.setState({id: null})}
					/>
				</div>
			</OverlayLayout>
		);
	}
}

OverlayAddressEditor.propTypes = {
	close: PropTypes.func.isRequired,
};

export default OverlayAddressEditor;
