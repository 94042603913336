import * as React from "react";
import {Component} from "react";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
import {IBillingAddress, IAddressError, ILoadedStatesCities, ICountry} from "../../types";
import Select from 'react-select';
import {defineMessages, FormattedMessage} from "react-intl";
import * as PropTypes from "prop-types";
// @ts-ignore
import Input from "qidigo-form/input";
// @ts-ignore
import Checkbox from "qidigo-form/checkbox";
// @ts-ignore
import STATES_TRANSLATIONS from "qidigo-data/states.js";
// @ts-ignore
import COUNTRIES_TRANSLATIONS from "qidigo-data/countries.js";
// @ts-ignore
import Loading from "qidigo-components/loading.js";

const translations = defineMessages({
    "no_results": {id: "qidigo.billing.select.no_results", defaultMessage: "Aucun résulat"},
    "tooltip": {id: "qidigo.billing.country.display.us", defaultMessage: "TODO"},
    "new_address": {id: "qidigo.billing.new.address.title", defaultMessage: "Nouvelle adresse de facturation"},
    "street_label": {id: "qidigo.billing.new.address.street.label", defaultMessage: "Numéro civique et rue"},
    "country_label": {id: "qidigo.billing.new.address.country.label", defaultMessage: "Pays"},
    "state_label": {id: "qidigo.billing.new.address.state.label", defaultMessage: "État"},
    "province_label": {id: "qidigo.billing.new.address.province.label", defaultMessage: "Province"},
    "city_label": {id: "qidigo.billing.new.address.city.label", defaultMessage: "Ville"},
    "app_label": {id: "qidigo.billing.new.address.apartment.label", defaultMessage: "#app."},
    "postal_label": {id: "qidigo.billing.new.address.postal_code.label", defaultMessage: "Code postal"},
    "zip_label": {id: "qidigo.billing.new.address.zip_code.label", defaultMessage: "Code ZIP"},
    "save_address": {
        id: "qidigo.billing.new.address.save",
        defaultMessage: "Enregistrer l'adresse pour une utilisation future"
    },
    "required": {id: "qidigo.billing.new.address.required", defaultMessage: "Valeur requise"},
    "max_length": {
        id: "qidigo.billing.new.address.max_length",
        defaultMessage: "La valeur ne peux dépasser {characters} caractères"
    },
});

const CANADA_ID = 'CA';
const CANADA_SLUG = 'canada';
const QUEBEC_ID = 5;
const QUEBEC_ABBREVIATION = 'QC';
const QUEBEC_SLUG = 'quebec';
const LIMIT = 1500;
const STREET_MAX_LENGTH = 255
const PAYSAFE_STREET_MAX_LENGTH = 50;

interface IPaymentCustomAddressFormProps {
    selectBillingAddress: (billingAddress: IBillingAddress | null) => void,
    setErrorOnAddress: (errorList: IAddressError[]) => void
    isPaysafeSelected: boolean
}

interface IPaymentCustomAddressFormState {
    customAddress: IBillingAddress,
    isSaveAddressChecked: boolean,
    isSubmitted: boolean,
    isValidCivicNumberExt: boolean,
    isValidCivicNumberAndStreet: boolean,
    isValidCountry: boolean,
    isValidState: boolean,
    isValidCity: boolean,
    isValidPostalCode: boolean,
    errorMessageOfCivicNumberExt: string | JSX.Element,
    errorMessageOfCivicNumberAndStreet: string | JSX.Element,
    errorMessageOfCountry: string | JSX.Element,
    errorMessageOfState: string | JSX.Element,
    errorMessageOfCity: string | JSX.Element,
    errorMessageOfPostalCode: string | JSX.Element,
    selectedCountry: { label: string, value: string } | null,
    selectedState: { label: string, value: number } | null,
    selectedCity: object,
    cityOptions: any,
    isLoading: boolean,
    loadedStatesCities: ILoadedStatesCities[],
    errorList: IAddressError[],
    countries: ICountry[],
}

const requiredErrorMessage = <FormattedMessage {...translations["required"]} />;

class PaymentCustomAddressForm extends Component<IPaymentCustomAddressFormProps, IPaymentCustomAddressFormState> {
    constructor(props: IPaymentCustomAddressFormProps) {
        super(props);
        this.state = {
            customAddress: {
                id: null,
                civic_number_ext: '',
                civic_number_and_street: '',
                country: CANADA_ID,
                state: QUEBEC_ABBREVIATION,
                state_id: QUEBEC_ID,
                city: '',
                city_id: null,
                willBeSaved: true,
                postal_code: '',
            },
            isSaveAddressChecked: true,
            isSubmitted: false,
            isValidCivicNumberExt: false,
            isValidCivicNumberAndStreet: false,
            isValidCountry: false,
            isValidState: false,
            isValidCity: false,
            isValidPostalCode: false,
            isLoading: true,
            errorMessageOfCivicNumberExt: '',
            errorMessageOfCivicNumberAndStreet: '',
            errorMessageOfCountry: '',
            errorMessageOfState: '',
            errorMessageOfCity: '',
            errorMessageOfPostalCode: '',
            selectedCountry: null,
            selectedState: null,
            selectedCity: [],
            cityOptions: [],
            loadedStatesCities: [],
            errorList: [],
            countries: [],
        };
    }

    async componentDidMount() {
        await this.fetchCountriesAndStates();
        this.loadDefaultCityOptions();
        this.onMountErrorHandling();
    }

    componentDidUpdate(prevProps, prevState: IPaymentCustomAddressFormState) {
        if (prevState.customAddress.state !== this.state.customAddress.state) {
            this.loadDefaultCityOptions();
            this.setState({
                errorMessageOfCity: '',
                isValidCity: true
            });
        }
    }

    updateProps(billingAddress: IBillingAddress) {
        this.props.selectBillingAddress(billingAddress);
    }

    searchCities = (keyword: string, state: number, country: string, limit: number) => {
        const loadedCityOptions = this.state.loadedStatesCities.find(
            loadedStateCity => loadedStateCity.state === state
        );

        if (loadedCityOptions) {
            this.setState({
                cityOptions: loadedCityOptions.cities
            });

            return;
        }

        const query = `?country=${country}&state=${state}&query=${keyword}&limit=${limit}`;

        return qidigoFetch.get(`cities${query}`)
            .then(response => {
                let values: { value: number, label: string }[] = [];
                response.forEach(element => {
                    values.push({value: element.id, label: element.name})
                });

                const newLoadedStates = this.state.loadedStatesCities;

                let loadedStateCities: ILoadedStatesCities = {
                    'state': state,
                    'cities': values
                };
                newLoadedStates.push(loadedStateCities);

                this.setState({
                    cityOptions: values,
                    loadedStatesCities: newLoadedStates
                });

            });
    }

    handleChangeAddress = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.civic_number_and_street = event.currentTarget.value;
        this.validateCivicNumberAndStreet(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    handleChangeCivicNumberExt = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.civic_number_ext = event.currentTarget.value;
        this.validateCivicNumberExt(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    handleChangeCity = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.city = event.currentTarget.value;
        this.validateCity(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    handleChangeCitySelect = (city) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.city = city['label'];
        customAddress.city_id = city['value'];
        this.validateCity(city['label']);
        this.setState({
            customAddress: customAddress,
            selectedCity: city
        });
        this.updateProps(customAddress);
    };

    handleChangeCountry = (country) => {
        const {formatMessage} = this.context.intl;
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.country = country['value'];
        const isCanadaSelected = customAddress.country == CANADA_ID;
        const selectedCountryFromResponse = this.state.countries.find((countryFromState) => {
            return countryFromState.id === country['value']
        });

        if (!selectedCountryFromResponse) {
            this.setState({
                errorMessageOfCountry: 'Selected country was not found',
                isValidCivicNumberAndStreet: false,
            });

            return;
        }

        const countryStates = selectedCountryFromResponse.states ? selectedCountryFromResponse.states : [];

        const firstStateOrDefaultOfSelectedCountry =
            isCanadaSelected
                ? countryStates.find((state) => {
                    return state.id === QUEBEC_ID
                })
                : countryStates[0];

        if (!firstStateOrDefaultOfSelectedCountry) {
            this.setState({
                errorMessageOfState: 'Selected state was not found',
                isValidCivicNumberAndStreet: false,
            });

            return;
        }

        customAddress.state = firstStateOrDefaultOfSelectedCountry.abbreviation;
        customAddress.state_id = firstStateOrDefaultOfSelectedCountry.id;

        customAddress.city = '';
        customAddress.city_id = null;
        const selectedState = {
            label: STATES_TRANSLATIONS[firstStateOrDefaultOfSelectedCountry.slug]
                ? formatMessage(STATES_TRANSLATIONS[firstStateOrDefaultOfSelectedCountry.slug])
                : firstStateOrDefaultOfSelectedCountry.name,
            value: firstStateOrDefaultOfSelectedCountry.id
        };

        this.validateCountry(country);
        this.setState({
            customAddress: customAddress,
            selectedCountry: country,
            selectedState: selectedState,
            selectedCity: [],
            isSaveAddressChecked: true,
        });
        this.updateProps(customAddress);
    };

    handleSaveAddressChecked = (event, value: boolean) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.willBeSaved = value;
        this.setState({
                isSaveAddressChecked: value,
                customAddress: customAddress
            }
        )

        this.updateProps(customAddress);
    }

    handleChangeState = (state) => {
        if (!this.state.selectedCountry) {
            this.setState({
                errorMessageOfCountry: 'Selected country was not found',
                isValidCivicNumberAndStreet: false,
            });

            return;
        }

        const selectedCountryFromResponse = this.state.countries.find((countryFromState) => {
            return countryFromState.id === this.state.selectedCountry!.value
        });

        if (!selectedCountryFromResponse || !selectedCountryFromResponse.states) {
            this.setState({
                errorMessageOfCountry: 'Selected country was not found',
                isValidCivicNumberAndStreet: false,
            });

            return;
        }
        
        const selectedState = selectedCountryFromResponse.states.find((stateFromSelectedCountryFromResponse) => {
            return stateFromSelectedCountryFromResponse.id === state['value']
        });

        if (!selectedState) {
            this.setState({
                errorMessageOfState: 'Selected state was not found',
                isValidCivicNumberAndStreet: false,
            });

            return;
        }

        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.state = selectedState['abbreviation'];
        customAddress.state_id = state['value'];
        customAddress.city = '';
        customAddress.city_id = null;

        this.validateState(state['value']);
        this.setState({
            selectedState: state,
            customAddress: customAddress,
            selectedCity: [],
        });
        this.updateProps(customAddress);
        this.validateCity(customAddress.city, false);
    };

    handleChangePostalCode = (event) => {
        let customAddress = Object.assign({}, this.state.customAddress);
        customAddress.postal_code = event.currentTarget.value;
        this.validatePostalCode(event.currentTarget.value);
        this.setState({
            customAddress: customAddress
        });
        this.updateProps(customAddress);
    };

    isFormValid() {
        return this.state.isValidCivicNumberAndStreet
            && this.state.isValidCivicNumberExt
            && this.state.isValidCountry
            && this.state.isValidState
            && this.state.isValidCity
            && this.state.isValidPostalCode;
    }

    validateCivicNumberAndStreet(value: string, show: boolean = true) {
        let errorMessage: string | JSX.Element = '';
        let validation = true;

        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        const maxAddressLength = this.props.isPaysafeSelected ? PAYSAFE_STREET_MAX_LENGTH : STREET_MAX_LENGTH

        if (value.length > maxAddressLength) {
            errorMessage = <FormattedMessage {...translations["max_length"]} values={{characters: maxAddressLength}}/>;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('address_line');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('address_line');
        }

        if (show) {
            this.setState({
                errorMessageOfCivicNumberAndStreet: errorMessage,
                isValidCivicNumberAndStreet: validation,
            });
        }
    }

    addErrorFromAddressValidation(errorTag: string) {
        let errors: IAddressError[] = this.state.errorList;
        let alreadyContainsErrorTag: boolean = false;

        errors.forEach(element => {
            if (element.field === errorTag) {
                alreadyContainsErrorTag = true;
                return;
            }
        });

        if (alreadyContainsErrorTag) {
            return;
        }

        errors.push({field: errorTag});
        this.props.setErrorOnAddress(errors);
        this.setState({
            errorList: errors
        });
    }

    removeErrorFromAddressValidation(errorTag: string) {
        const currentErrors: IAddressError[] = this.state.errorList;
        let newErrorList: IAddressError[] = [];

        currentErrors.forEach((element) => {
            if (element.field !== errorTag) {
                newErrorList.push(element);
                return;
            }
        });

        this.props.setErrorOnAddress(newErrorList);
        this.setState({
            errorList: newErrorList
        });
    }

    validateCivicNumberExt = (value: string | null) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value !== null && value.length > 10) {
            errorMessage = <FormattedMessage {...translations["max_length"]} values={{characters: 10}}/>;
            validation = false;
        }

        this.setState({
            errorMessageOfCivicNumberExt: errorMessage,
            isValidCivicNumberExt: validation
        });
    }

    validateCity = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('city');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('city');
        }

        if (show) {
            this.setState({
                errorMessageOfCity: errorMessage,
                isValidCity: validation
            });
        }
    }

    validateCountry = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (!validation) {
            this.addErrorFromAddressValidation('country');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('country');
        }

        if (show) {
            this.setState({
                errorMessageOfCountry: errorMessage,
                isValidCountry: validation
            });
        }
    }

    validateState = (value: number, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;

        if (!validation) {
            this.addErrorFromAddressValidation('state');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('state');
        }
        if (show) {
            this.setState({
                errorMessageOfState: errorMessage,
                isValidState: validation
            });
        }
    }

    validatePostalCode = (value: string, show: boolean = true) => {
        let errorMessage: string | JSX.Element = '';
        let validation = true;
        if (value.length === 0) {
            errorMessage = requiredErrorMessage;
            validation = false;
        }

        if (value.length > 10) {
            errorMessage = <FormattedMessage {...translations["max_length"]} values={{characters: 10}}/>;
            validation = false;
        }
        if (!validation) {
            this.addErrorFromAddressValidation('postal_code');
        }

        if (validation) {
            this.removeErrorFromAddressValidation('postal_code');
        }

        if (show) {
            this.setState({
                errorMessageOfPostalCode: errorMessage,
                isValidPostalCode: validation
            });
        }
    }

    showAllErrorMessages() {
        this.validateCivicNumberExt(this.state.customAddress.civic_number_ext);
        this.validateCivicNumberAndStreet(this.state.customAddress.civic_number_and_street);
        this.validateCity(this.state.customAddress.city);
        this.validateState(this.state.customAddress.state_id);
        this.validateCountry(this.state.customAddress.country);
        this.validatePostalCode(this.state.customAddress.postal_code);
    }

    onMountErrorHandling() {
        this.validateCivicNumberAndStreet(this.state.customAddress.civic_number_and_street, false);
        this.validateCity(this.state.customAddress.city, false);
        this.validatePostalCode(this.state.customAddress.postal_code, false);
    }

    handleSubmit() {
        if (!this.isFormValid()) {
            this.showAllErrorMessages();

            return;
        }

        this.setState({
            isSubmitted: true,
        });

        this.props.selectBillingAddress(this.state.customAddress);
    }

    async fetchCountriesAndStates() {
        const {formatMessage} = this.context.intl;
        const response = await qidigoFetch.get(`countries`);
        this.setState({
            countries: response,
            selectedCountry: {label: formatMessage(COUNTRIES_TRANSLATIONS[CANADA_SLUG]), value: CANADA_ID},
            selectedState: {label: formatMessage(STATES_TRANSLATIONS[QUEBEC_SLUG]), value: QUEBEC_ID},
            isLoading: false,
        });

        return response;
    }

    getCountriesOptions = (jsonArray: ICountry[]) => {
        const {formatMessage} = this.context.intl;
        return jsonArray.map(({ id, name, slug }) => ({
            value: id,
            label: COUNTRIES_TRANSLATIONS[slug]
                ? formatMessage(COUNTRIES_TRANSLATIONS[slug])
                : name,
        })).sort((countryA, countryB) => countryA.label.localeCompare(countryB.label));
    };

    getStatesOptions = (countryKey: string) => {
        const country = this.state.countries.find((countryFromState) => {
            return countryFromState.id === countryKey
        });

        const {formatMessage} = this.context.intl;
        if (!country || !country.states) {
            return [];
        }

        return country.states.map(({ name, slug, id }) => ({
            value: id,
            label: STATES_TRANSLATIONS[slug]
                ? formatMessage(STATES_TRANSLATIONS[slug])
                : name,
        })).sort((stateA, stateB) => stateA.label.localeCompare(stateB.label));
    };

    loadDefaultCityOptions() {
        this.searchCities(
            '',
            this.state.customAddress.state_id,
            this.state.customAddress.country,
            LIMIT)
    }

    render() {
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const civicNumberAndStreetLabel = formatMessage(translations['street_label']);
        const civicNumberExtLabel = formatMessage(translations['app_label']);
        const cityLabel = formatMessage(translations['city_label']);
        const countryLabel = formatMessage(translations['country_label']);
        const stateLabel = this.state.customAddress.country === 'US'
            ? formatMessage(translations['state_label'])
            : formatMessage(translations['province_label'])
        const postalLabel = this.state.customAddress.country === 'US'
            ? formatMessage(translations['zip_label'])
            : formatMessage(translations['postal_label'])

        const customStyle = {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: 32,
                minHeight: 32,
                borderRadius: 3,
                border: state.isFocused ? "1px solid #27708e" : "1px solid #d2d3d5",
                boxShadow: state.isFocused ? "0 0 0.57142857rem #27708e" : "none",
                "&:hover": {
                    border: state.isFocused ? "1px solid #27708e" : "1px solid #d2d3d5",
                    boxShadow: state.isFocused ? "0 0 0.57142857rem #27708e" : "none"
                }
            }),
            valueContainer: (provided) => ({
                ...provided,
                height: 32,
                padding: '0 6px'
            }),
            input: (provided) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                height: '30px',
            }),
            menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: 9999,
            })
        };

        // @ts-ignore
        const customTheme = (theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
                ...theme.colors,
                primary: '#27708EFF',
            },
        })

        if (this.state.isLoading) {
            return <Loading/>
        }

        const countriesOptions = this.getCountriesOptions(this.state.countries);
        const statesOptions = this.getStatesOptions(this.state.selectedCountry ? this.state.selectedCountry.value : CANADA_ID);

        return (
            <form className={"payment-address--custom-form"} onSubmit={(e) => e.preventDefault()}>
                <div className={"payment-address--custom-form-container"}>
                    <div className="payment-address--custom-form-title">
                        <FormattedMessage {...translations["new_address"]} />
                    </div>
                    <div className={"payment-address--custom-form-address-input-container"}>
                        <div>
                            <Input
                                className={"payment-address--custom-form-address-input"}
                                type={"text"}
                                label={civicNumberAndStreetLabel}
                                value={this.state.customAddress.civic_number_and_street}
                                disabled={this.state.isSubmitted}
                                onChange={this.handleChangeAddress}
                                onBlur={this.handleChangeAddress}
                                errorMessage={this.state.errorMessageOfCivicNumberAndStreet}
                            />
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-app-input-container"}>
                        <div>
                            <Input
                                className={"payment-address--custom-form-app-input"}
                                type={"text"}
                                name={"civic_number_ext"}
                                label={civicNumberExtLabel}
                                value={this.state.customAddress.civic_number_ext
                                !== null ? this.state.customAddress.civic_number_ext
                                    : ''}
                                disabled={this.state.isSubmitted}
                                onChange={this.handleChangeCivicNumberExt}
                                errorMessage={this.state.errorMessageOfCivicNumberExt}
                                maxLength={10} //Paysafe max
                            />
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-country-input-container"}>
                        <div>
                            <Select
                                placeholder={countryLabel}
                                required
                                styles={customStyle}
                                theme={customTheme}
                                value={this.state.selectedCountry}
                                onChange={this.handleChangeCountry}
                                isDisabled={this.state.isSubmitted}
                                options={countriesOptions}
                                menuPortalTarget={document.body}
                                noOptionsMessage={() => formatMessage(translations['no_results'])}
                            ></Select>
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-state-input-container"}>
                        <div>
                            <Select
                                placeholder={stateLabel}
                                required
                                styles={customStyle}
                                theme={customTheme}
                                value={this.state.selectedState}
                                onChange={this.handleChangeState}
                                isDisabled={this.state.isSubmitted}
                                options={statesOptions}
                                menuPortalTarget={document.body}
                                noOptionsMessage={() => formatMessage(translations['no_results'])}
                            />
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-city-input-container"}>
                        <div>
                            {
                                <Select
                                    styles={customStyle}
                                    theme={customTheme}
                                    placeholder={cityLabel}
                                    required
                                    key={"city-select-list"}
                                    value={this.state.selectedCity}
                                    options={this.state.cityOptions}
                                    onChange={this.handleChangeCitySelect}
                                    menuPortalTarget={document.body}
                                    noOptionsMessage={() => formatMessage(translations['no_results'])}
                                />
                            }
                        </div>
                    </div>
                    <div className={"payment-address--custom-form-postal-input-container"}>
                        <div>
                            <Input
                                className={"payment-address--custom-form-postal-input"}
                                type={"text"}
                                name={"postal-code"}
                                label={postalLabel}
                                value={this.state.customAddress.postal_code}
                                disabled={this.state.isSubmitted}
                                onChange={this.handleChangePostalCode}
                                maxLength={10}
                            />
                        </div>
                    </div>
                </div>
                {
                    <div className={"payment-address--custom-form-save-address-container"}>
                        <Checkbox
                            value={this.state.isSaveAddressChecked}
                            id={"save-custom-address"}
                            onChange={(e, v) => this.handleSaveAddressChecked(e, v)}
                        />
                        <label htmlFor={"save-custom-address"}>
                            <FormattedMessage {...translations["save_address"]} />
                        </label>
                    </div>
                }
            </form>
        )
    }
}

// @ts-ignore
PaymentCustomAddressForm.contextTypes = {
    intl: PropTypes.object,
};

export default PaymentCustomAddressForm;
