/**
 * Scrolls the page to a node.
 */
const scroll = (node) => {
	// Ensures it *can* scroll.
	if (node && node.getBoundingClientRect) {
		const box = node.getBoundingClientRect();
		const {body, documentElement} = document;
		[body, documentElement].map((el) => el.scrollTop += box.top);
	}
};

export default scroll;
