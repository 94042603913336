import React, {Component} from "react";
import PropTypes from "prop-types";
import Gateway from "qidigo-components/gateway";

import OverlayAddressEditor from "@app/controllers/shared/addresses/_overlay";
import OverlayPhoneNumberEditor from "@app/controllers/shared/phone_numbers/_overlay";

const EDITORS = {
	phone_number: OverlayPhoneNumberEditor,
	address: OverlayAddressEditor,
};

/**
 * Utility component to provide overlay editors in the application.
 *
 * This assumes presence of *overlay* `GatewayDest`.
 *
 * This is implemented using `context`.
 *
 */
class WithEditor extends Component {
	constructor() {
		super();
		this.state = {
			editor: null,
			resolve: null,
		};
	}

	getChildContext() {
		return {
			showAddressEditor: () => this.showAddressEditor(),
			showPhoneNumberEditor: () => this.showPhoneNumberEditor(),
		};
	}

	/**
	 * Returns a promise used to delay action after the overlay is closed.
	 */
	showAddressEditor() {
		return new Promise((resolve, reject) => {
			this.setState({resolve, editor: "address"});
		})
		.then((val) => {
			this.setState({editor: null});

			return val;
		})
		.then(this.props.then)
		;
	}

	/**
	 * Returns a promise used to delay action after the overlay is closed.
	 */
	showPhoneNumberEditor() {
		return new Promise((resolve, reject) => {
			this.setState({resolve, editor: "phone_number"});
		})
		.then((val) => {
			this.setState({editor: null});

			return val;
		})
		.then(this.props.then)
		;
	}

	render() {
		const {children} = this.props;
		const {editor} = this.state;

		if (!editor) {
			return <div>{children}</div>;
		}
		const {resolve} = this.state;
		const Editor = EDITORS[editor];

		return (
			<div>
				<Gateway into="overlay">
					<Editor {...this.props} close={() => resolve(true)} />
				</Gateway>
				{children}
			</div>
		);
	}
}

WithEditor.propTypes = {
	// A function to be executed once an editor is closed.
	// Usually, refreshes the view.
	then: PropTypes.func.isRequired,
};

WithEditor.childContextTypes = {
	showAddressEditor: PropTypes.func,
	showPhoneNumberEditor: PropTypes.func,
};

export default WithEditor;
