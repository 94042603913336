// @ts-ignore
import React, { useEffect, useState } from 'react';
import loadPaysafe from './loadPaysafe';
import CreditCardInputs from "../creditCardInputs";
// @ts-ignore
import Loading from 'qidigo-components/loading';

interface PaysafeCardInputProps {
    paymentPlatformError: boolean,
    unexpectedInstallmentError: boolean,
    unexpectedAddressError: boolean,
    payAction: () => void
    paysafeHasLoaded: () => void,
    paysafeAlreadyLoaded: boolean,
    hasInstantiatedField: boolean,
    refreshComponent: () => void,
    isRefreshingComponent: boolean,
    amountOfTryOnRefreshing: number
    processingPayment: boolean,
    disabled: boolean,
}

const PaysafeCardInput = (props: PaysafeCardInputProps) => {
    let [loaded, setLoaded] = useState(false);
    loaded = props.paysafeAlreadyLoaded;

    useEffect(() => {
        if(!props.paysafeAlreadyLoaded) {
            loadPaysafe(() => {
                setLoaded(true);
                props.paysafeHasLoaded();
            });
        }
    });

    return loaded ? 
    (
        <CreditCardInputs
            unexpectedAddressError={props.unexpectedAddressError}
            paymentPlatformError={props.paymentPlatformError}
            unexpectedInstallmentError={props.unexpectedInstallmentError}
            payAction={props.payAction}
            hasInstantiatedField={props.hasInstantiatedField && !props.isRefreshingComponent}
            refreshComponent={props.refreshComponent}
            amountOfTryOnRefreshing={props.amountOfTryOnRefreshing}
            disabled={props.disabled}
        />
    ) : <Loading />;
}

export default PaysafeCardInput;
