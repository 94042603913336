/* eslint no-console: [0] */
import "isomorphic-fetch";
import {defineMessages}      from "react-intl";
import Logger                from "qidigo-logger";
import * as Sentry           from "@sentry/browser";
import Auth                  from "qidigo-auth";
import env                   from "qidigo-env";

const errorMessages = defineMessages({
	genericError: {id: "qidigo.fetch.errors.generic_error", defaultMessage: "Une erreur inattendue est survenue. Veuillez réessayer sous peu."},
});

/**
 * Wrapper autour de isomorphic fetch et fetch, faisant un peu
 * de magie permettant d'automatiser un *status* de load global
 * et permettant de faire du logging bien mérité.
 *
 * N'oublions pas la gestion du header `Authorization` automatisée.
 *
 * ### Note à propos d'upload
 *
 * Il faut envoyer le formulaire de manière "classique" et non en JSON.
 * Il faut donc passer un FormData comme body.
 *
 */
class QidigoFetchFile {
	/**
	 * Wrapper autour de `fetch` avec des options par défaut.
	 */
	static fetch(input, init = {}) {
		const defaults = {
			headers:  {},
		};
		const token = Auth.getToken();
		if (token) {
			defaults.headers["Authorization"] = `Bearer ${token}`;
			// Side-steps issues with Fetch polyfills where there would be
			// two Authorization headers, one Basic and one Bearer on
			// staging environment.
			defaults.headers["Bearer-Authorization"] = `Bearer ${token}`;
		}

		// Copie les options par défaut.
		const newInit = Object.assign({}, defaults, init);
		// Et faire de même sur les headers.
		newInit.headers = Object.assign({}, defaults.headers, init.headers);

		//
		// Reset content-type when posting FormData instead of json.
		// (Inverse detection of json is harder)
		//
		// if (newInit.body && newInit.body instanceof FormData) {
		// 	delete newInit.headers["Content-Type"];
		// }

		const host = env["API_HOST"] || "";
		return fetch(`${host}/api/v1/${input}`, newInit);
	}

	/**
	 * Permet d'utiliser le *handler* de load qui fait *quelque chose*
	 * d'indéfini. Sans le *handler*, donne un *passthrough*.
	 */
	static load() {
		if (QidigoFetchFile.loadingPromise) {
			return QidigoFetchFile.loadingPromise();
		}

		return (res) => res;
	}

	/**
	 * Default logging handled for Fetch requests.
	 * This mostly handles logging 500 errors.
	 */
	static defaultErrorHandler(response, options) {
		options = options || {};
		const requestBody = options["body"] || null;

		// Default error
		const errors = {message: "", fields: {}};
		// FIXME : Do translation here
		errors.message = errorMessages.genericError.defaultMessage;

		if (process.env.NODE_ENV === "development") {
			console.error("QidigoFetchFile.defaultErrorHandler:");
			console.error(response);
		}
		if (env["server"]) {
			console.error("QidigoFetchFile.defaultErrorHandler:");
			console.error(response);
		}

		console.info("From: qidigo-fetch/index.js, static defaultErrorHandler");
		if (response instanceof Response) {
			errors.response = Object.assign({}, response);

			return response.json()
				.then((body) => {
					errors.response.body = body;
					const msg = `[Fetch] Error ${response["status"]}: ${response["statusText"]}`;
					const extractedResponse = {
						type: response["type"],
						url: response["url"],
						status: response["status"],
						statusText: response["statusText"],
					};

					Sentry.withScope(scope => {
						scope.setExtra('response', extractedResponse);
						scope.setExtra('request', {
							body: requestBody,
						})
						Sentry.captureMessage(msg, 'error');
					});

					return Promise.reject(errors);
				});
		}
		else {
			console.warn("Unexpected type of error in defaultErrorHandler");

			Sentry.withScope(scope => {
				scope.setExtra('request', {
					body: requestBody,
				});
				Sentry.captureException(response);
			});
		}

		return Promise.reject(errors);
	}

	/**
	 * Effectue une requête, avec un comportement par défaut particulier:
	 *
	 *   * Attache une promise configurable.
	 *   * Attache des error handlers.
	 *   * Attache un logger.
	 *
	 */
	static doRequest(method, resource, body = undefined) {
		// Promise à enchaîner.
		const finish = QidigoFetchFile.load();
		Logger.ajaxDebugLog(method, resource, body);

		// Do not stringify FormData.
		if (body && !(body instanceof FormData)) {
			body = JSON.stringify(body);
		}
		let init = {method, body};

		return QidigoFetchFile.fetch(resource, init)
			// Log error...
			.then((response) => {
				if (response.status >= 500 && response.status < 600) {
					return Logger.ajaxErrorHandler(response);
				}

				return response;
			})
			//
			// Default error.
			// 4xx and 5xx are not caught here.
			//
			.catch((r) => QidigoFetchFile.defaultErrorHandler(r, {body}))
			// Either use the response or do error stuff.
			.then((response) => {
				// Is everything fine?
				if (response.ok) {
					if (response.status === 204 || response.status === 202) {
						return {};
					}

					return response;
				}

				//
				// Something happened...
				//

				// 422 mostly means invalid rails record.
				if (response.status === 422) {
					return response.json()
						.then((body) =>  Promise.reject(body));
				}

				// Unexpected errors means default error.
				if (response.status >= 500 && response.status < 600) {
					return QidigoFetchFile.defaultErrorHandler(response, {body});
				}

				// Otherwise, return the response. The receiving end should know best.
				return Promise.reject(response);
			})
			// Always pop a load state...
			.then(finish, (...r)=>{
				finish();

				return Promise.reject(...r);
			})
		;
	}

	/**
	 * Effectue un GET.
	 */
	static get(resource) {
		return QidigoFetchFile.doRequest("GET", resource);
	}

	/**
	 * Affecte une promise à utiliser comme *passthrough* à toutes les requêtes.
	 *
	 * Il est attendu que le
	 * *handler* retourne une fonction apte à être utilisée dans un *Promise*
	 * et cause un *side-effect*, qui est de présenter un état de chargement.
	 */
	static setLoadingPromise(signal) {
		QidigoFetchFile.loadingPromise = signal;
	}

	/**
	 * Fonction à utiliser pour `setState(errors)` pour la combinaison fetch/form
	 *
	 * Cette fonction s'utilise comme suit:
	 *
	 * ```
	 *     Fetch.post(...)
	 *     // [...]
	 *     .catch(Fetch.handleErrors(this))
	 *     ;
	 * ```
	 *
	 * Cette fonction `reject` les erreurs, mais consomme/catch correctement les
	 * *messages d'erreurs* de l'API.
	 *
	 * C'est-à-dire, gère correctement l'affichage des messages d'erreurs de l'API
	 * et envoie l'erreur au handler générique d'erreurs du site.
	 *
	 * ### Paramètres
	 *
	 *   * context : `this` pour la fonction, le component react.
	 *   * options : (facultatif) *aucune option pour l'instant*.
	 */
	static handleErrors(context, options) {
		options = options || {};

		// Renvoie la fonction qui handle la promise.
		return function(errors) {
			if (errors instanceof Error) { return Promise.reject(errors); }
			const {message, fields} = errors;
			this.setState({
				saving: false,
				errors: {message, fields}
			});

			return null;
		}.bind(context);
	}
}

// Static variable.
QidigoFetchFile.loadingPromise = null;


if (process.env.NODE_ENV === "development") {
	if (typeof window !== "undefined") {
		if (!window.qidigo) { window.qidigo = {}; }
		if (!window.qidigo.fetch) {
			window.qidigo.fetch = {};
			const f = window.qidigo.fetch;
			window.qidigo.fetch.unwrapped = QidigoFetchFile;
			["get"].map((k) => {
				f[k] = (...args) => QidigoFetchFile[k](...args)
					.then((response) => {
						console.info(response)

						return response;
					})
					.catch((err) => {
						console.error(err)

						return err;
					})
			});
		}
	}
}

export default QidigoFetchFile;
