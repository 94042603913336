import * as React from "react";
import {Component} from "react";

import {defineMessages, FormattedMessage} from "react-intl";
import {IModalSteps} from "../types";

const translations = defineMessages({
    "group_description": {
        id: "qidigo.activity.pass_selection_modal.steps.group_description",
        defaultMessage: "Présentation du cours"
    },
    "connection": {
        id: "qidigo.activity.pass_selection_modal.steps.group_connection",
        defaultMessage: "Connexion"
    },
    "subscription": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription",
        defaultMessage: "Inscription"
    },
    "consent": {
        id: "qidigo.activity.pass_selection_modal.steps.consent",
        defaultMessage: "Consentement"
    },
})

interface IModalStepsHeaderProps {
    stepsArray: Array<IModalSteps>,
}

interface IModalStepsHeaderState {
    currentStep,
    nextStep
}

class ModalStepsHeader extends Component<IModalStepsHeaderProps, IModalStepsHeaderState> {
    constructor(props: IModalStepsHeaderProps) {
        super(props);
    }

    private static getStepClass(step: IModalSteps): string {
        if (step.isCurrent) {
            return 'current-step'
        }

        if (step.isCompleted) {
            return 'completed-step'
        }

        return 'none-completed-step'
    }

    render() {
        return (
            <div className="modal-steps-header">
                {
                    this.props.stepsArray.map((step, key) =>
                        <div key={key} className={"modal-steps-header--step-container"}>
                            <div className={"modal-steps-header--step"}>
                                <div className={"modal-steps-header--" + ModalStepsHeader.getStepClass(step)}>
                                    {step.step}
                                </div>
                                <div className={"modal-steps-header--step-text"}>
                                    <FormattedMessage {...translations[step.stepName]} />
                                </div>
                            </div>
                            {
                                !step.isLast &&
                                <div
                                    className={"modal-steps-header--progress-line" + (step.isCompleted ? "" : "-disabled")}/>
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

export default ModalStepsHeader;
