import Fetch                 from "qidigo-fetch";
import {path}                from "@app/lib/routes";
import {redirect, sendError} from "../router";

export const REQUEST_ORGANIZATION = "SEARCH:REQUEST_ORGANIZATION";
export const RECEIVE_ORGANIZATION = "SEARCH:RECEIVE_ORGANIZATION";
export const RECEIVE_ORGANIZATION_FAILED = "SEARCH:RECEIVE_ORGANIZATION_FAILED";
export const INVALIDATE_ORGANIZATION = "SEARCH:INVALIDATE_ORGANIZATION";

/**
 * Notifie qu'on effectue une requête.
 */
const requestOrganization = (organizationId) => ({
	type: REQUEST_ORGANIZATION,
	organizationId,
});

/**
 * Notifie de la réception des données pour un organisme.
 */
const receiveOrganization = (organizationId, organization) => ({
	type: RECEIVE_ORGANIZATION,
	organizationId,
	organization,
	at: Date.now(),
});

/**
 * Force un refresh pour un organisme.
 */
export const invalidateOrganization = () => ({
	type: INVALIDATE_ORGANIZATION,
});

/**
 * Récupère un organisme, si nécessaire (compare slug/ID)
 */
export const fetchOrganization = (organizationId) =>
	(dispatch, getState) => {
		// Il est possible que ce soit un ID numérique. C'est dû à un manque de vigilance dans
		// l'ancienne application.
		const id = organizationId.toString();
		// Prend pour acquis que numérique === id, autre == slug
		const param = id.match(/^[0-9]+$/) ? "id" : "slug";
		const {search: {organization: {organization}}} = getState();

		// Court-circuite si on a déjà le bon organisme.
		if (organization && organization[param].toString() === id) { return null; }

		// Indique qu'on commence à récupérer l'organisme.
		dispatch(requestOrganization(id));

		// Dispatch la recherche d'organisme.
		return dispatch((dispatch, getState) => {
			return Fetch.get(`organizations?${param}=${id}`)
				.then(([organization]) => {
					// N'est plus l'organisme qu'on charge?
					if (id !== getState().search.organization.organizationId) { return null; }

					// Aucun résultat correspond à un "soft-404"
					if (!organization) { return Promise.reject({status: 404}); }

					dispatch(receiveOrganization(id, organization));

					// Redirige vers le bon slug.
					if (id !== organization["slug"]) {
						// TODO : Conserver ce qui est après le slug de l'organisme (activity/groups/page)
						return dispatch(redirect(path("organization", {orgID: organization["slug"]}), {replace: true}));
					}

					return organization;
				})
				.catch((error) => {
					dispatch(sendError(error));

					return dispatch({type: RECEIVE_ORGANIZATION_FAILED, error})
				});
		});
	};
