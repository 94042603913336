import React, {Component}  from "react";
import PropTypes             from "prop-types";
import {PhoneNumberUtil, PhoneNumberFormat}
	from "google-libphonenumber";

export function formatPhone(p) {
	const phoneUtil = PhoneNumberUtil.getInstance();
	const phoneNumber = phoneUtil.parse(p.number, p.country_id);

	const phonePart = phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
	// fix eventually : i18n; works fr/en though.
	const extPart = p.ext ? ` ext. ${p.ext}` : "";

	return `${phonePart}${extPart}`;
}

/**
 * Implémente le microformat de numéro de téléphone selon les propriétés
 * données.
 *
 * Voir:
 *
 *   * http://microformats.org/wiki/h-card#p-tel
 *
 */
class Telephone extends Component {
	render() {
		const {
			phoneNumber,
			className,
			...props
		} = this.props;
		const classes = ["telephone", "p-tel", className];

		if (!phoneNumber) {
			return null;
		}
		let number = "";
		try {
			number = formatPhone(phoneNumber);
		}
		catch (e) {
			console.warn(e);
		}

		return (
			<span {...props} className={classes.join(" ")}>{number}</span>
		);
	}
}

Telephone.propTypes = {
	phoneNumber: PropTypes.object,
	className:   PropTypes.string,
};

export default Telephone;
