import React                 from "react";
import PropTypes             from "prop-types";
import Helmet                from "react-helmet";
import {defineMessages}      from "react-intl";
import {Link}                from "react-router/es";

import {errorMessagesType}   from "qidigo-form/proptypes";
import Page                  from "@app/components/page";
import LoginPartial          from "./_login";
import RegisterPartial       from "../register/_register";

const messages = defineMessages({
	title:     {id: "qidigo.login.page.title", defaultMessage: "Se connecter ou s'inscrire"},
});

const LoginView = (props, context) => {
	const {formatMessage} = context.intl;
	const {shown, onRegisterSubmit, onLoginSubmit} = props;

	return (
		<Page name="login">
			<Helmet title={formatMessage(messages.title)} />
			<div>
				<RegisterPartial
					{...props}
					onSubmit={onRegisterSubmit}
					shown={shown === "register"}
				/>
				<LoginPartial
					{...props}
					onSubmit={onLoginSubmit}
					shown={shown === "login"}
				/>
			</div>
		</Page>
	);
};

LoginView.propTypes = {
	loading:   PropTypes.bool,
	errors:    errorMessagesType,
	values:    PropTypes.object,
	onChange:  PropTypes.func,
	onRegisterSubmit: PropTypes.func.isRequired,
	onLoginSubmit: PropTypes.func.isRequired,
	onValidationError: PropTypes.func,
	// Which panel is open.
	shown: PropTypes.string,
	unfold: PropTypes.func,
};

LoginView.contextTypes = {
	intl: PropTypes.object,
};

export default LoginView;
