import Payment from "./payment/payment";
import {IFinalizeBoxProps, IPaymentMethod} from "./types";
import * as React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {Component} from "react";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import Loading from 'qidigo-components/loading';

const translations = defineMessages({
    "confirm": {
        id: "qidigo.billing.button.confirm",
        defaultMessage: "Confirmer"
    },
    "continue_shopping": {
        id: "qidigo.billing.button.continue_shopping",
        defaultMessage: "Continuer mes achats"
    }
});

class FinalizeBox extends Component<IFinalizeBoxProps> {
    constructor(props: IFinalizeBoxProps) {
        super(props);
    }

    render() {
        return (
            this.props.isLoading
                ? <Loading />
                : (
                    this.props.shouldShowPaymentBox
                        ? <div>
                    <Payment
                        paymentMethods={this.props.paymentMethods}
                        addressList={this.props.addressList}
                        installmentList={this.props.installmentList}
                        showPaymentErrorBox={this.props.showPaymentErrorBox}
                        handleTokenBadRequest={this.props.handleTokenBadRequest}
                        handlePayment={(
                            paymentMethod: IPaymentMethod,
                            installmentId: string | null,
                            token: string | null) => this.props.handlePayment(
                            this.props.credit,
                            this.props.version,
                            paymentMethod.id,
                            token,
                            installmentId,
                            this.props.appliedCoupon,
                        )}
                        paymentErrors={this.props.paymentErrors}
                        processingPayment={this.props.processingPayment}
                        organizationUuid={this.props.organizationUuid}
                        disabled={this.props.disabled}
                        billingInstallments={[]}
                        selectedInstallmentId={this.props.selectedInstallmentId}
                        selectedPaymentMethodId={this.props.selectedPaymentMethodId}
                    />
                </div>
                        : (
                            this.props.lineCount > 0 ?
                                <div>
                                    <footer className={"billing-view--confirm-button-container"}>
                                        {
                                            this.props.processingPayment
                                                ? <Loading />
                                                : (
                                                    <Button
                                                        className={'billing-view--confirm-button primary'}
                                                        disabled={this.props.disabled}
                                                        onClick={() => this.props.handlePayment(
                                                            this.props.credit,
                                                            this.props.version,
                                                            null,
                                                            null,
                                                            null,
                                                            this.props.appliedCoupon,
                                                        )}
                                                    >
                                                        <FormattedMessage {...translations['confirm']} />
                                                    </Button>
                                                )
                                        }
                                    </footer>
                                </div> :
                                <div>
                                    <footer className={"billing-view--continue-shopping-button-container"}>
                                        <Button
                                            className={"billing-view--continue-shopping-button"}
                                            onClick={() => this.props.handleContinueShoppingClicked()}
                                        >
                                            <FormattedMessage {...translations['continue_shopping']} />
                                        </Button>
                                    </footer>
                                </div>
                        )
                )
        );
    }
}

export default FinalizeBox;
