import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {connect}             from "@app/lib/redux";
import View                  from "@app/views/organization/membership/offers";
import Loading               from "qidigo-components/loading.js";
import WithEditor            from "@app/components/with_editor";
import {
    fetchMembership,
    invalidateMembership,
}   from "@app/store/actions/search/membership";
import {
    addToCart,
}   from "@app/store/actions/buy";

import {
    openCart
}   from "@app/store/actions/cart";

/**
 * Contrôleur pour *un membership*.
 *
 * C'est-à-dire, la liste des offres du membership.
 */
class OrganizationMembershipController extends Component {
    constructor() {
        super();
    }

    /**
     * Charge le data pour le contrôleur.
     * (API publique)
     */
    static load(dispatch, params) {
        return Promise.all([
            dispatch(fetchMembership(params.membershipID)),
        ]);
    }

    /**
     * Proxy pour `static load`.
     */
    load() {
        const {dispatch, params} = this.props;
        OrganizationMembershipController.load(dispatch, params);
    }

    reload() {
        const {dispatch} = this.props;
        dispatch(invalidateMembership());
        this.load();
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        // FIXME : Comprendre pourquoi je dois invalider ici...
        const {dispatch} = this.props;
        dispatch(invalidateMembership());
    }

    /**
     * Gère l'ajout au panier.
     */
    handleAddToCart({plan, subscriber}) {
        const {dispatch} = this.props;

        dispatch(addToCart({
            cart_item: {
                quantity: 1,
                subscriber_user_id: subscriber,
                plan_id: plan,
            }
        }))
        .then((response) => {
            if (response) {
                if (response["status"] === "OK") {
                    dispatch(openCart());
                }
                // FIXME : Flash message pattern.
                else { this.load(); }
            }

            return response;
        })
        .catch((err) => { console.error(err); this.load(); })
        ;
    }
    render() {
        const {
            membership,
            offers,
            adding,
            familyMembers,
            organization,
        } = this.props;


        if (!membership) {
            return (
                <section className={"membership--offers"}>
                    <h2>{" "}</h2>
                    <Loading />
                </section>
            );
        }

        // HACK : Re-wraps using `organization-page-child` since DOM is modified by `WithEditor`.
        // Once react 16 lands, this can be fixed (returning an array).
        return (
            <WithEditor then={() => this.reload()}>
                <div className="organization-page-child">
                    <View
                        {...{
                            membership,
                            offers,
                            adding,
                            familyMembers,
                            organization,
                            onAddToCart: (...args) => this.handleAddToCart(...args),
                        }}
                    />
                </div>
            </WithEditor>
        );
    }
}

OrganizationMembershipController.propTypes = {
    dispatch: PropTypes.func,
    membership: PropTypes.object,
    offers: PropTypes.array,
    adding: PropTypes.bool,
    params: PropTypes.shape({
        membershipID: PropTypes.string,
    }).isRequired,
    familyMembers: PropTypes.object,
};

OrganizationMembershipController.contextTypes = {
    intl: PropTypes.object,
};

// Connecte avec le store redux.
// FIXME : familyMembers being given in search.membership is bad.
const withProperties = ({search: {membership: {membership, offers, familyMembers}}, buy: {cart: {adding}}}) => ({membership, familyMembers, offers, adding});
export default connect(withProperties)(OrganizationMembershipController);
