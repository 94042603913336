import * as React from "react";
import {Component} from "react";
// @ts-ignore
import {defineMessages, FormattedMessage} from "react-intl";
// @ts-ignore
import Button from "qidigo-components/button.js";
// @ts-ignore
import SeparationLine from "./SeparationLine";
// @ts-ignore
import Money from "@app/components/money";
// @ts-ignore
import {
    IFamilyMember,
    IProposedOffer,
    ISession,
    IUserPass,
    IMemberPassRestriction,
    IGroupDetailled,
    IGroupRestriction
} from "../types";
// @ts-ignore
import { groupRestrictions, RestrictionsFragment, RestrictionMessage } from "qidigo-data/restrictions";
// @ts-ignore
import PropTypes from "prop-types";
// @ts-ignore
import {groupRestrictions, RestrictionsFragment} from "qidigo-data/restrictions";
import {lang} from "moment";
import Select, {ActionMeta, SingleValue} from 'react-select';
// @ts-ignore
import Fetch from "qidigo-fetch";
import * as Sentry from "@sentry/react";
// @ts-ignore
import Logger from "qidigo-logger";
// @ts-ignore
import Loading from "qidigo-components/loading.js";
import genderRestrictions from "../../GenderRestrictions";

interface OptionType {
    value: string;
    label: string;
}

const translations = defineMessages({
    "your_passes": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.your_passes",
        defaultMessage: "Vos passes"
    },
    "purchase": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.purchase",
        defaultMessage: "Achat"
    },
    "cost": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.cost",
        defaultMessage: "Coût\u00A0: "
    },
    "from_cost": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.from_cost",
        defaultMessage: "À partir de\u00A0: "
    },
    "years_old": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.years_old",
        defaultMessage: "ans"
    },
    "months_old": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.months_old",
        defaultMessage: "{count, plural, one {mois} other {mois}}"
    },
    "add_to_cart": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.add_to_cart",
        defaultMessage: "Ajouter au panier"
    },
    "choose_member": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.choose_member",
        defaultMessage: "Choisir un participant"
    },
    "member": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.member",
        defaultMessage: "Membre : {member}"
    },
    "remaining_quantity": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.remaining_quantity",
        defaultMessage: "Quantité restante : {quantity}"
    },
    "reservation_confirmation": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.reservation_confirmation",
        defaultMessage: "Vous avez bien réservé {sessionName} avec votre passe {passName} pour le {date} à {time}"
    },
    "back_to_schedule": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.back_to_schedule",
        defaultMessage: "Retour à l'horaire"
    },
    "already_present": {
        id: "qidigo.pass.reservation.sorry.already_present",
        defaultMessage: "Cette personne est inscrite à la séance demandée"
    },
    "age_not_defined": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.age_not_defined",
        defaultMessage: "Âge indéfini"
    },
    "book": {
        id: "qidigo.activity.pass_selection_modal.steps.subscription.book",
        defaultMessage: "Réserver"
    }

})

interface ISubscriptionProps {
    userPassList: Array<IUserPass>
    organizationPassList: Array<IProposedOffer>
    userFamilyMembers: IFamilyMember[],
    handleUsePass: (subscriberId: number, userPass: IUserPass) => void
    handleAddToCart: (planId: number, subscriberId: number) => void
    closeModal: (withReload: boolean) => void,
    session: ISession,
    showReservationConfirmation: boolean
    filteredMemberId: string,
    filteredPassPlanId: string,
    isLoadingOffer: boolean,
    usePassError: string
}

interface ISubscriptionState {
    selectedPassOffer: IProposedOffer | undefined;
    selectedUserPass: IUserPass | undefined;
    showUserSelection: boolean,
    selectedFamilyMember: IFamilyMember | undefined,
    canAddToCart: boolean,
    reasonCantAddToCart: IMemberPassRestriction|null,
    selectedPassMemberPrice: number | undefined,
    memberIsAlreadySubscribed: boolean,
}

interface IRestrictionViewProps {
    restrictions: IMemberPassRestriction,
    session: ISession,
    closeModal: () => void,
}

interface IBookButtonFragmentProps {
    canBookWithOwnedPass: ICanBookWithOwnedPass,
    userPass: IUserPass,
    session: ISession,
    handleUsePass: (subscriberId: number, userPass: IUserPass) => void
}

interface ICanBookWithOwnedPass {
    canBook: boolean,
    restrictions: string[]
}

const GENDER_NOT_SPECIFIED = 'A';
const MONTHS_IN_A_YEAR = 12;

const RestrictionView = (props: IRestrictionViewProps) => {

    const {
        restrictions,
        session,
        closeModal
    } = props;

    const currentGroupRestrictions = restrictions.groups_restriction !== null ?
     [restrictions.groups_restriction] :
     [];

    return (
        <div className="restrictions-container div--subscription-restrictions-container">
            <RestrictionsFragment
                restrictions={{
                    groups_restrictions: currentGroupRestrictions,
                    membership_restrictions: restrictions.membership_restrictions,
                    organization_restrictions: restrictions.organization_restrictions,
                    product_restrictions: restrictions.product_restrictions,
                    registration_restrictions: restrictions.registration_restrictions,
                }}
                groupsCount={1}
                groupRestrictions={groupRestrictions(session.group)}
                onEditorOpen={closeModal}
                listClass={"ul--restrictions-list"}
            />
        </div>
    )
}

const BookButtonFragment = (props: IBookButtonFragmentProps) => {

    const {
        canBookWithOwnedPass,
        userPass,
        session,
        handleUsePass
    } = props;

    return (
        canBookWithOwnedPass.canBook ?
        <Button onClick={() => handleUsePass(userPass.userId, userPass)}
                className="pass-selection-subscription--user-pass-reservation-button">
            <FormattedMessage  {...translations['book']} />
        </Button> :
        <div className="restrictions-container div--subscription-restrictions-container">
            <RestrictionsFragment
                restrictions={{
                    groups_restrictions: [{
                        id: session.group_id,
                        restrictions: canBookWithOwnedPass.restrictions
                    }],
                    organization_restrictions: [],
                    product_restrictions: [],
                    registration_restrictions: [],
                    membership_restrictions: []
                }}
                groupsCount={1}
                groupRestrictions={groupRestrictions(session.group)}
                listClass={"ul--restrictions-list"}
                explanationTranslationKey={"explanation_cant_book"}
            />
        </div>
    )
}


class Subscription extends Component<ISubscriptionProps, ISubscriptionState> {
    constructor(props: ISubscriptionProps) {
        super(props);

        this.state = {
            selectedPassOffer: undefined,
            showUserSelection: false,
            selectedFamilyMember: undefined,
            canAddToCart: false,
            reasonCantAddToCart: null,
            selectedUserPass: undefined,
            selectedPassMemberPrice: undefined,
            memberIsAlreadySubscribed: false,
        }
    }

    RESTRICTION_TO_GENDER = {
        woman: 'F',
        man: 'M',
        other: 'O',
        not_answered: 'N'
    };

    componentWillMount() {
        const {filteredMemberId, filteredPassPlanId} = this.props;
        const filteredMember = this.props.userFamilyMembers.find((member) => {
            return member.id.toString() === filteredMemberId
        })

        let selectedPassOffer = this.state.selectedPassOffer;

        if (this.props.organizationPassList.length === 1) {
            selectedPassOffer = this.props.organizationPassList[0]
        }

        if (this.props.organizationPassList.length > 0 && this.props.filteredPassPlanId) {
            selectedPassOffer = this.props.organizationPassList.find((pass) => {
                return pass.id.toString() === this.props.filteredPassPlanId
            })

            selectedPassOffer = selectedPassOffer ? selectedPassOffer : undefined
        }

        if (filteredMember) {
            this.setSelectedFamilyMember(filteredMember, selectedPassOffer)
        }

        if (selectedPassOffer) {
            this.selectOrganizationPass(selectedPassOffer, filteredMember);
        }
    }

    async fetchReservations(selectedFamilyMember: IFamilyMember) {
        const {session} = this.props;
        const sessionID = session.id;
        const memberID = selectedFamilyMember.id;

        const reservationsPromise =
            await Fetch.get(`users/${memberID}/reservations?session=${sessionID}&include_cart=true`)
                .then((response) => {
                    return response.reservations || [];
                }).catch(error => {
                    Sentry.addBreadcrumb({
                        category: 'fetch',
                        level: Sentry.Severity.Debug,
                        message: error.message
                    })
                    Logger.catcher(error)
                });

        this.setState({
            memberIsAlreadySubscribed: reservationsPromise.length > 0
        })
    }

    selectOrganizationPass = (pass: IProposedOffer, selectedFamilyMember: IFamilyMember | undefined) => {
        let passMemberPrice = pass.price;
        let reasonCantAddToCart:IMemberPassRestriction|null = null;

        this.scrollToTarget(pass.id)

        if (this.state.selectedPassOffer && pass.id === this.state.selectedPassOffer.id) {
            return;
        }

        if (selectedFamilyMember && pass) {
            this.validateCanAddToCart(Number(selectedFamilyMember.id), pass);

            const memberOption = pass.memberOptions
                .find((memberOption) => memberOption.userId == Number(selectedFamilyMember.id));

            if (memberOption !== undefined) {
                passMemberPrice = memberOption.price
                reasonCantAddToCart = memberOption.restrictions
            }
        }

        this.setState({
            selectedPassOffer: pass,
            showUserSelection: true,
            selectedPassMemberPrice: passMemberPrice,
            reasonCantAddToCart: reasonCantAddToCart,
        });
    }

    setSelectedFamilyMember = (selectedMember: IFamilyMember | undefined, selectedPassOffer: IProposedOffer | undefined) => {
        if (selectedMember !== undefined && selectedPassOffer) {
            const memberOption = selectedPassOffer.memberOptions
                .find((memberOption) => memberOption.userId == Number(selectedMember.id));

            if (memberOption !== undefined) {
                this.setState({
                    selectedPassMemberPrice: memberOption.price,
                })
            }
        }

        this.setState({
            selectedFamilyMember: selectedMember,
            selectedPassOffer: this.state.selectedPassOffer,
        })

        if (selectedMember !== undefined) {
            this.validateCanAddToCart(Number(selectedMember.id), selectedPassOffer)
            this.fetchReservations(selectedMember);
        }
    }

    selectFamilyMember = (option: SingleValue<OptionType>, action: ActionMeta<OptionType>) => {
        const {selectedPassOffer} = this.state;

        if (option === null) {
            return;
        }

        if (option.value === '') {
            this.setState({
                selectedFamilyMember: undefined,
                canAddToCart: false,
                reasonCantAddToCart: null,
                selectedPassMemberPrice: selectedPassOffer ? selectedPassOffer.price : undefined
            });
            return;
        }

        const selectedMember = this.props.userFamilyMembers
            .find((member) => Number(option.value) == member.id);

        this.setSelectedFamilyMember(selectedMember, selectedPassOffer);
    }

    validateCanAddToCart = (memberId: number, pass: IProposedOffer | undefined) => {

        let {selectedPassOffer} = this.state;

        selectedPassOffer = pass ? pass : selectedPassOffer;

        if (selectedPassOffer === undefined) {
            return;
        }

        selectedPassOffer.memberOptions.forEach((memberOption) => {
            if (memberOption.userId === memberId) {
                if (this.hasRestriction(memberOption.restrictions)) {
                    this.setState({
                        canAddToCart: false,
                        reasonCantAddToCart: memberOption.restrictions
                    });
                    return;
                }

                this.setState({
                    canAddToCart: true,
                    reasonCantAddToCart: null
                });

            }
        });
    }

    scrollToTarget = (id) => {
        let targetElement = document.getElementById(id);

        if (targetElement) {
            targetElement.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'start',        // Align to the start of the target element
                    inline: 'nearest'
                },
            );
        }
    }

    addToCart = () => {

        const {handleAddToCart, closeModal} = this.props;

        if (this.state.selectedPassOffer === undefined || this.state.selectedFamilyMember === undefined) {
            return;
        }

        handleAddToCart(this.state.selectedPassOffer.id, Number(this.state.selectedFamilyMember.id));
        closeModal(false);
    }

    makeFamilySelect = () => {

        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const {userFamilyMembers} = this.props;

        let options = [
            {
                key: "",
                value: formatMessage(translations["choose_member"]),
            }
        ]

        userFamilyMembers.forEach((member) => {
            options.push({
                key: member.id.toString(),
                value: this.getFamilyMemberSelectText(member)
            });
        })

        return options;

    }

    getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(lang(), {month: 'long', day: 'numeric', year: 'numeric'}).format(date);
    }

    getFormattedTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
    }

    handleUsePass = (userId, userPass: IUserPass) => {
        this.setState({
            selectedUserPass: userPass
        });

        this.props.handleUsePass(userId, userPass);
    }

    private getFamilyMemberSelectText(familyMember : IFamilyMember) {
        const {formatMessage} = this.context.intl;
        let familyMemberAgeText = familyMember.name
            + " ("
            + familyMember.age
            + " "
            + (familyMember.age_type === 'years'
                ? formatMessage(translations['years_old'])
                : formatMessage(translations['months_old'], {count: familyMember.age}))
                + ")";

        if (isNaN(familyMember.age)) {
            familyMemberAgeText = familyMember.name + " (" + formatMessage(translations['age_not_defined']) + ")";
        }

        return familyMemberAgeText;
    }

    private memberCanUseOwnedPassOnSession = (memberId: number): boolean => {
        const {organizationPassList, session} = this.props;

        for (let index = 0; index < organizationPassList.length; index++) {
            const offer = organizationPassList[index];
            for (let index = 0; index < offer.memberOptions.length; index++) {
                const userRestriction = offer.memberOptions[index];

                if (userRestriction.userId !== memberId) {
                    continue;
                }

                if (userRestriction.restrictions.groups_restriction === null) {
                    continue;
                }

                if (userRestriction.restrictions.groups_restriction.id !== session.group_id) {
                    continue;
                }

                if (userRestriction.restrictions.groups_restriction.restrictions.includes('SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS')) {
                    continue;
                }

                return false;
            }
        }

        return true;
    }

    private validateMaxRegistrations = (memberId: number): ICanBookWithOwnedPass => {
        const { organizationPassList, session } = this.props;

        for (const offer of organizationPassList) {
            const userRestriction = offer.memberOptions.find(option => option.userId === memberId);

            if (!userRestriction) continue;

            const groupRestriction = userRestriction.restrictions.groups_restriction;

            if (!groupRestriction || groupRestriction.id !== session.group_id) {
                continue;
            }

            if (groupRestriction.restrictions.includes('SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS')) {
                return {
                    canBook: false,
                    restrictions: ['SUBSCRIBER_EXCEEDS_MAX_REGISTRATIONS']
                };
            }
        }

        return {
            canBook: true,
            restrictions: []
        };
    }


    private canUseAtLeastOneOwnedPass = (): boolean => {
        const {userPassList} = this.props;

        for (let index = 0; index < userPassList.length; index++) {
            const userPass = userPassList[index];
            if (this.memberCanUseOwnedPassOnSession(userPass.userId)) {
                return true;
            }
        }

        return false;
    }

    private hasRestriction = (restrictions: IMemberPassRestriction): boolean => {
        return restrictions.groups_restriction !== null ||
        restrictions.membership_restrictions.length > 0 ||
        restrictions.organization_restrictions.length > 0 ||
        restrictions.product_restrictions.length > 0 ||
        restrictions.registration_restrictions.length > 0;
     }

     private userCanBookThisSession = (userId: number): ICanBookWithOwnedPass => {
        const {userFamilyMembers, session} = this.props;
        const memberFromPass = userFamilyMembers.find((member) => member.id === userId);
        if (memberFromPass === undefined) {
            return {canBook: false, restrictions: []};
        }

        return this.validateGroupRestriction(memberFromPass, session.group);
     }

     private validateGroupRestriction = (member: IFamilyMember, group: IGroupDetailled): ICanBookWithOwnedPass => {

        const userValidateGenderRestriction = this.validateGender(member.gender, group.restrictions_gender);
        const userValidateAgeRestriction = this.validateAge(
            member.birthday,
            group.restriction_age_min, 
            group.restriction_age_max, 
            group.validate_age_on
        );
        const userValidateMaxRegistrations = this.validateMaxRegistrations(member.id);

        if (
            userValidateGenderRestriction.canBook
            && userValidateAgeRestriction.canBook
            && userValidateMaxRegistrations.canBook
            && !this.props.usePassError) {
            return {canBook: true, restrictions: []};
        }

        const restrictions = userValidateGenderRestriction.restrictions
            .concat(userValidateAgeRestriction.restrictions)
            .concat(userValidateMaxRegistrations.restrictions)
            .concat(this.props.usePassError ? this.props.usePassError : [])

        return {
            canBook: false, 
            restrictions: restrictions
        };
     }

     private validateGender = (memberGender: string, groupGenderRestrictions: string[]): ICanBookWithOwnedPass => {
        const canBook = groupGenderRestrictions.find((genderRestriction) =>
            memberGender === this.RESTRICTION_TO_GENDER[genderRestriction]
        ) !== undefined;

        if (!canBook) {
            return {canBook: false, restrictions: ['SUBSCRIBER_DOES_NOT_HAVE_RIGHT_GENDER']};
        }

        return {canBook: true, restrictions: []};
     }

     private validateAge = (
        birthday: string|null, 
        restrictionAgeMin: number|null, 
        restrictionAgeMax: number|null, 
        validateAgeOn: string|null
    ): ICanBookWithOwnedPass => {

        const now = new Date();

        if (restrictionAgeMin === null && restrictionAgeMax === null) {
            return {canBook: true, restrictions: []};;
        }

        if (birthday === null) {
            return {canBook: true, restrictions: ['NO_AGE']};
        }

        const dateToValidateAgeOn = validateAgeOn === null ? now : new Date(validateAgeOn);
        const memberAgeInMonthOn = this.getAgeInMonthOn(
            this.makeDateFromBirthday(birthday), 
            dateToValidateAgeOn
        );

        if (restrictionAgeMin && memberAgeInMonthOn < restrictionAgeMin) {
            return {canBook: false, restrictions: ['SUBSCRIBER_AGE_TOO_YOUNG']};
        }

        if (restrictionAgeMax && !this.validateRestrictionAgeMax(memberAgeInMonthOn, restrictionAgeMax)) {
            return {canBook: false, restrictions: ['SUBSCRIBER_AGE_TOO_OLD']};
        }


        return {canBook: true, restrictions: []};
     }

     private validateRestrictionAgeMax = (memberAgeInMonthOn:number, restrictionAgeMax:number): boolean => {
        if (
            memberAgeInMonthOn < MONTHS_IN_A_YEAR ||
            restrictionAgeMax < MONTHS_IN_A_YEAR ||
            restrictionAgeMax % MONTHS_IN_A_YEAR !== 0
        ) {
            return memberAgeInMonthOn <= restrictionAgeMax;
        }

        return Math.floor(memberAgeInMonthOn / MONTHS_IN_A_YEAR) <= (restrictionAgeMax / MONTHS_IN_A_YEAR);
     }

     /**
      * Ensure the created Date object from string is the correct day.
      * Providing the birthday (YYYY-MM-dd) directly to Date constructor result in a date with one day off.
      */
     private makeDateFromBirthday = (birthday: string): Date => {
        return new Date((birthday + 'T00:00:00').replace(/-/g, '\/').replace(/T.+/, ''));
     }

     private getAgeInMonthOn(birthday: Date, validateOnDate: Date): number {
        const birthYear = birthday.getFullYear();
        const birthMonth = birthday.getMonth();
        const birthDay = birthday.getDay();
        const validateOnYear = validateOnDate.getFullYear();
        const validateOnMonth = validateOnDate.getMonth();
        const validateOnDay = validateOnDate.getDay();

        let months = (validateOnYear * MONTHS_IN_A_YEAR + validateOnMonth) -
         (birthYear * MONTHS_IN_A_YEAR + birthMonth);
        if (birthMonth >= validateOnMonth && birthDay > validateOnDay) {
            months -= 1;
        }

        return months;
     }

    render() {
        // @ts-ignore
        const {formatMessage} = this.context.intl;
        const {selectedUserPass, reasonCantAddToCart} = this.state;
        const {
            session,
            showReservationConfirmation,
            isLoadingOffer,
            organizationPassList,
            closeModal
        } = this.props;

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                border: '0.1rem solid #ccc',
                borderRadius: '4px',
                boxShadow: state.isFocused ? '0 0 0 2px #3498db' : 'none',
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#27708E' : 'white',
                color: state.isFocused ? 'white' : '#27708E',
            }),
            menuPortal: (base) => ({...base, zIndex: 9999}),
        };

        const isSmallScreen = window.matchMedia('(max-width: 650px)').matches;

        return isLoadingOffer ? <Loading/> : (
            <div>
                {
                    showReservationConfirmation && selectedUserPass
                        ? <div className={"pass-selection-subscription--reservation-confirmation"}>
                            <div>
                                <FormattedMessage
                                    {...translations['reservation_confirmation']}
                                    values={{
                                        sessionName: (<strong>{session.name}</strong>),
                                        passName: <strong>{selectedUserPass.name}</strong>,
                                        date: <strong>{this.getFormattedDate(this.props.session.start_date)}</strong>,
                                        time: <strong>{this.getFormattedTime(this.props.session.start_date)}</strong>
                                    }}
                                    injectHTML={true}
                                />
                            </div>
                            <Button
                                className="pass-selection-modal--primary-button"
                                onClick={() => closeModal(true)}
                            >
                                <FormattedMessage {...translations['back_to_schedule']}/>
                            </Button>
                        </div>
                        : <div className="pass-selection-subscription">
                            {
                                this.props.userPassList.length > 0 && this.canUseAtLeastOneOwnedPass() &&
                                <div className={"pass-selection-subscription--user-pass-list-container"}>
                                    <p className={"pass-selection-subscription--list-title"}>
                                        <FormattedMessage {...translations["your_passes"]}/>
                                    </p>
                                    <div className={"pass-selection-subscription--user-pass-boxes-container"}>
                                        {
                                            this.props.userPassList.map((userPass, key) =>
                                                this.memberCanUseOwnedPassOnSession(userPass.userId) &&
                                                <div key={key} className={"pass-selection-subscription--user-pass-box"}>
                                                    <div className={"pass-selection-subscription--user-pass-name"}>
                                                        {userPass.name}
                                                    </div>
                                                    <div className={"pass-selection-subscription--user-pass-quantity "}>
                                                        <FormattedMessage  {...translations['remaining_quantity']}
                                                                           values={{quantity: userPass.quantity}}/>
                                                    </div>
                                                    <div>
                                                        <FormattedMessage  {...translations['member']}
                                                                           values={{member: userPass.userName}}/>
                                                    </div>
                                                    <BookButtonFragment 
                                                        canBookWithOwnedPass={this.userCanBookThisSession(userPass.userId)}
                                                        userPass={userPass}
                                                        session={session}
                                                        handleUsePass={this.handleUsePass}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.props.userPassList.length > 0 &&
                                this.canUseAtLeastOneOwnedPass() &&
                                organizationPassList.length > 0 &&
                                <SeparationLine isVertical={!isSmallScreen}/>
                            }
                            {organizationPassList.length > 0 &&
                                <div className={"pass-selection-subscription--organization-pass-list-container"}>
                                    <p className={"pass-selection-subscription--list-title"}>
                                        <FormattedMessage {...translations["purchase"]}/>
                                    </p>
                                    <div className={"pass-selection-subscription--organization-pass-boxes-container"}>
                                        {
                                            organizationPassList.map((organizationPass, key) =>
                                                <div key={organizationPass.id} id={organizationPass.id.toString()}
                                                    className={"pass-selection-subscription--organization-pass-box"}
                                                    onClick={() => this.selectOrganizationPass(organizationPass, this.state.selectedFamilyMember)}>
                                                    <div
                                                        className="pass-selection-subscription--organization-pass-checkbox-wrapper">
                                                        <div
                                                            className={"pass-selection-subscription--organization-pass-checkbox"}>
                                                            {
                                                                this.state.selectedPassOffer && this.state.selectedPassOffer.id === organizationPass.id &&
                                                                <div
                                                                    className={'pass-selection-subscription--organization-pass-checkbox-checked'}/>
                                                            }
                                                        </div>
                                                        <div className="checkbox-text">
                                                            {organizationPass.name}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={"pass-selection-subscription--organization-pass-price"}>
                                                        <FormattedMessage {...translations[this.state.selectedFamilyMember !== null && this.state.selectedPassOffer && this.state.selectedPassOffer.id === organizationPass.id ? "cost" : "from_cost"]}/><Money
                                                        value={this.state.selectedPassOffer && this.state.selectedPassOffer.id === organizationPass.id
                                                            ? this.state.selectedPassMemberPrice
                                                            : organizationPass.price}
                                                        withSymbol={true}
                                                    />
                                                    </div>
                                                    {
                                                        this.state.showUserSelection
                                                        && this.state.selectedPassOffer !== undefined
                                                        && this.state.selectedPassOffer.id
                                                        && this.state.selectedPassOffer.id === organizationPass.id
                                                        && <Select
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'}
                                                            styles={customStyles}
                                                            value={{
                                                                value: this.state.selectedFamilyMember !== undefined ? this.state.selectedFamilyMember.id : '',
                                                                label: this.state.selectedFamilyMember !== undefined
                                                                    ? this.getFamilyMemberSelectText(this.state.selectedFamilyMember)
                                                                    : formatMessage(translations['choose_member'])
                                                            } as OptionType}
                                                            onChange={this.selectFamilyMember}
                                                            options={this.makeFamilySelect().map((item, key) => {
                                                                    return {
                                                                        value: item.key,
                                                                        label: item.value
                                                                    }
                                                                }
                                                            )}
                                                        />
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        this.state.memberIsAlreadySubscribed &&
                                        <div className={"box-message pass-selection-subscription--already-present-container"}>
                                            <p>
                                                <FormattedMessage
                                                    {...translations["already_present"]}
                                                />
                                            </p>
                                        </div>
                                    }
                                    {
                                        this.state.selectedPassOffer && this.state.canAddToCart &&
                                        <Button onClick={this.addToCart}
                                                className={"pass-selection-subscription--add-to-cart-button"}>
                                            <FormattedMessage {...translations['add_to_cart']}/>
                                        </Button>
                                    }
                                    {
                                        this.state.selectedPassOffer &&
                                        !this.state.canAddToCart &&
                                        reasonCantAddToCart !== null &&
                                        this.hasRestriction(reasonCantAddToCart) &&
                                        this.state.selectedFamilyMember &&
                                        <RestrictionView
                                            restrictions={reasonCantAddToCart}
                                            session={this.props.session}
                                            closeModal={() => this.props.closeModal(false)}
                                        />
                                    }
                                </div>
                            }
                        </div>
                }

            </div>
        );
    }
}

// @ts-ignore
Subscription.contextTypes = {
    intl: PropTypes.object,
};


export default Subscription;
