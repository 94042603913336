import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "@app/lib/redux";
import ErrorsController from "@app/controllers/errors_controller";

class FatalError extends Component {
	constructor() {
		super();

		this.state = {
			error: null,
			info: null,
		};
	}

	componentWillUpdate(nextProps) {
		const {error} = nextProps;
		// Catch errors from redux.
		if (error && this.state.error !== error) {
			const info = error["info"] || {};
			this.setState({error, info});
		}
	}

	componentDidCatch(error, info) {
		this.setState({error, info});
	}

	render() {
		const {children} = this.props;

		if (this.state.error || this.props.error) {
			const error = this.state.error || this.props.error;
			const {info} = this.state;

			return <ErrorsController error={error} reactInfos={info} />;
		}

		return children;
	}
}

FatalError.contextTypes = {
	error: PropTypes.object,
};

const withProperties = ({router: {error}}) => ({error});
export default connect(withProperties)(FatalError);
