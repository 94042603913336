import React                 from "react";
import {
	Route,
	IndexRoute,
}   from "react-router/es";

// Organizations
import OrgWrapper            from "@app/controllers/organization";
import OrgLayout             from "@app/views/organization/_layout";
import OrgContact            from "@app/controllers/organization/contact";
import OrgRedirector         from "@app/controllers/organization/redirector";
import OrgActivitiesSession  from "@app/controllers/organization/session_activities";
import OrgChildLayout        from "@app/views/organization/_child";

import OrganizationMembershipsController from "@app/controllers/organization/memberships"
import OrganizationMembershipController from "@app/controllers/organization/membership"

// Shared
import PassesListing         from "@app/controllers/activity/pass";

// Activities
import ActivityWrapper            from "@app/controllers/activity";
import ActivityLayout             from "@app/views/activity/ActivityLayout";
import ActivityRedirector         from "@app/controllers/activity/redirector";
import ActivitySessionListing     from "@app/controllers/activity/session";
import ActivityContact            from "@app/controllers/activity/contact";
import ActivityChildLayout        from "@app/views/activity/ActivityChildLayout";
import ActivityGroups             from "@app/controllers/activity/groups";
import ActivityGroupsSelected     from "@app/controllers/activity/groupsSelected";
// Groups
import GroupWrapper          from "@app/controllers/group";
import GroupLayout           from "@app/views/groups/_layout";
import GroupOffers           from "@app/views/groups/offers";
import GroupContact          from "@app/views/groups/contact";
import FormCartListController        from "@app/controllers/cart/formList";
import PendingOrderController        from "@app/controllers/billing/pendingOrder";
import SettlementController 		 from "@app/controllers/billing/settlement";


import FormCartController        from "@app/controllers/cart/form";
import PageWrapper        from "@app/components/page_wrapper";

// `/class/:id` -> nouvelle structure.
import GroupRedirector       from "@app/controllers/group/redirector";
import QidigoAuth            from "qidigo-auth";

import ContactConfirmation   from "@app/controllers/contact/contact_confirmation";
import InvoiceController from "../controllers/dashboard/invoice";
import DashboardBase from "../views/dashboard/_layout";

export default
	<Route>
		<Route component={OrgWrapper}>
			<Route path="u/:orgID">
				<Route component={OrgLayout}>
					<IndexRoute component={OrgRedirector} />
					<Route path="contact" component={OrgContact} />
					<Route path="memberships">
						<Route path=":membershipID" component={OrganizationMembershipController} />
						<IndexRoute component={OrganizationMembershipsController} />
					</Route>
					<Route path="activities">
						<Route path="session" component={OrgActivitiesSession} />
						<Route path="schedule" component={PassesListing} />
						<Route path="calendar" component={PassesListing} />
					</Route>
				</Route>

				{/* Ressources enfants de l'organisme */}
				<Route component={OrgChildLayout}>
					<Route path="activity/:activityID" component={ActivityWrapper}>
						<Route component={ActivityLayout}>
							<IndexRoute component={ActivityRedirector} />
							<Route path="session" component={ActivitySessionListing} />
							<Route path="schedule" component={PassesListing} />
							<Route path="calendar" component={PassesListing} />
							<Route path="contact" component={ActivityContact} />
							<Route path="groups" component={ActivityGroups} />

							<Route path="groups/selected" component={ActivityGroupsSelected} />
						</Route>
						<Route component={ActivityChildLayout}>
							<Route path="group/:groupID" component={GroupWrapper}>
								<Route component={GroupLayout}>
									<IndexRoute /* offers */ component={GroupOffers} />
									<Route path="contact" component={GroupContact} />
									<Route path="schedule" component={PassesListing} />
								</Route>
							</Route>
						</Route>
					</Route>
				</Route>
				<Route path="pending-order">
					<Route path="forms" component={FormCartListController} />
					<Route component={PageWrapper} >
						<Route path="form/:id" component={FormCartController}/>
					</Route>
					<Route path="checkout" component={PendingOrderController} render={props => this.props} />
				</Route>
				<Route path="invoices/:invoiceId" component={DashboardBase} onEnter={QidigoAuth.handleAuth}>
					<Route path="show" component={InvoiceController}  render={props => this.props} />
					<Route path="create-a-settlement" component={SettlementController} render={props => this.props} />
				</Route>
				<Route path="contact-confirmation/:token" component={ContactConfirmation} />
			</Route>
		</Route>
		{/* Redirection des URL "nues" */}
		<Route path="class/:groupID" component={GroupRedirector} />
	</Route>
	;
