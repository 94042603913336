import React from "react";
import PropTypes from "prop-types";
import {
    defineMessages,
    FormattedMessage,
} from "react-intl";
import Helmet from "react-helmet";

import Select from "qidigo-form/select.js";
import CalendarView from "@app/views/shared/sessions/calendar";
import {SimpleWeek} from "qidigo-calendar/agendaweek";
import AgendaView from "qidigo-calendar/agenda";
import Loading from "qidigo-components/loading.js";
import PassSelectionModal from "./modal/PassSelectionModal.tsx";

const translations = defineMessages({
    "page.title": {id: "qidigo.pass.chooser.title", defaultMessage: "Séances à la carte"},
    "filter.activity": {id: "qidigo.pass.chooser.filter.activity", defaultMessage: "Recherche par activité"},
    "filter.member": {id: "qidigo.pass.chooser.filter.members", defaultMessage: "Recherche par membre"},
    "filter.supervisor": {id: "qidigo.pass.chooser.filter.supervisor", defaultMessage: "Recherche par superviseur"},
    "filter.pass": {id: "qidigo.pass.chooser.filter.pass", defaultMessage: "Recherche par passe"},
    "filter.all_activity": {id: "qidigo.pass.chooser.filter.all", defaultMessage: "Toutes les activités"},
    "filter.all_member": {id: "qidigo.pass.chooser.filter.all_member", defaultMessage: "Tous les membres"},
    "filter.all_supervisor": {
        id: "qidigo.pass.chooser.filter.all_supervisors",
        defaultMessage: "Tous les superviseurs"
    },
    "filter.all_pass": {id: "qidigo.pass.chooser.filter.all_pass", defaultMessage: "Toutes les passes"},
    "filter.owned_passes": {id: "qidigo.pass.chooser.filter.owned_passes", defaultMessage: "Vos passes"},
    "filter.offered_passes": {
        id: "qidigo.pass.chooser.filter.offered_passes",
        defaultMessage: "Passes offertes"
    },
    "no_sessions_available": {
        id: "qidigo.pass.chooser.no_sessions_available",
        defaultMessage: "Aucune séance à venir n'est disponible"
    },

});

const Filter = ({
                    items, handler, filteredItem, translationKeyAllItems, translationKeyTitle,
                }, {
                    intl,
                }) => {
    const {formatMessage} = intl;
    if (!items) {
        return null;
    }

    let options = [
        {
            key: "",
            value: formatMessage(translations[translationKeyAllItems]),
        }
    ];

    const isArray = Array.isArray(items);

    if (isArray) {
        options = options.concat(items.map((item) => ({
            key: item.id,
            value: item.name,
        })));
    } else {
        for (let [key, value] of Object.entries(items)) {
            if (value.length === 0) {
                continue;
            }

            options = options.concat({
                label: formatMessage(translations['filter.' + key]),
                group: value.map((item) => ({
                    key: item.id,
                    value: item.name,
                }))
            })
        }
    }

    return (
        <div
            className="activity-filters--filter"
        >
            <Select
                onChange={handler}
                value={filteredItem}
                label={formatMessage(translations[translationKeyTitle])}
                options={options}
            />
        </div>
    );
};

Filter.defaultProps = {
    items: [],
};

Filter.propTypes = {
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    handler: PropTypes.func,
    filteredItem: PropTypes.string,
    translationKeyAllItems: PropTypes.string.isRequired,
    translationKeyTitle: PropTypes.string.isRequired,
};

Filter.contextTypes = {
    intl: PropTypes.object,
};

const PassesView = (props, context) => {
    let {
        organization, activity, group, subtitle
    } = props;

    let title = [];
    if (organization) {
        title.push(organization["name"]);
    }
    if (activity) {
        title.push(activity["name"]);
    }
    if (group) {
        title.push(group["name"]);
    }
    if (subtitle) {
        title.push(subtitle);
    }
    if (title.length > 0) {
        title = title.join(" — ") + " — ";
    }


    const {
        sessions,
        activities,
        membersForFilter,
        passes,
        passFilter,
        supervisors,
        filteredActivity,
        filteredMember,
        filteredSupervisor,
        filteredPassPlan,
        selected,
        subject,
        view,
        date,
        handleNavigate,
        handleSelection,
        handleFilterActivity,
        handleFilterMember,
        handleFilterSupervisor,
        handleFilterPasses,
        reservationFragment,
        handleCloseModal,
        handleCloseModalWithSessionReload,
        handleUsePass,
        handleAddToCart,
        offer,
        fetchOffers,
        fetchUserPasses,
        userOwnedPasses,
        passesForFilter,
        loading,
        setCurrentView,
        showReservationConfirmation,
        goToSubscriptionStep,
        showCalendar,
        isLoadingOffer,
        members,
        usePassError,
        ...leftOverProps
    } = props;
    const {formatMessage} = context.intl;

    let offersFragment = null;

    // Props aditionnelles.
    const additionalProps = {};
    if (subject === "group") {
        additionalProps["views"] = {agenda: SimpleWeek};
    }

    if (subject === 'passes') {
        additionalProps["views"] =
            {
                agenda: AgendaView
            };
    }

    return (
        <div className="page-activity">
            <Helmet
                title={
                    title + formatMessage(translations["page.title"])
                }
            />
            <section>
                <h2><FormattedMessage {...translations["page.title"]} /></h2>
                {
                    showCalendar || showCalendar === null ?
                        <div>
                            <div className={"activity-filters-container"}>
                                {
                                    subject === "organization" && activities ?
                                        <Filter
                                            items={activities}
                                            handler={handleFilterActivity}
                                            filteredItem={filteredActivity}
                                            translationKeyAllItems={"filter.all_activity"}
                                            translationKeyTitle={"filter.activity"}
                                        /> :
                                        null
                                }
                                {
                                    (subject === "organization" || subject === "activity") && membersForFilter.length > 1 ?
                                        <Filter
                                            items={membersForFilter}
                                            handler={handleFilterMember}
                                            filteredItem={filteredMember}
                                            translationKeyAllItems={"filter.all_member"}
                                            translationKeyTitle={"filter.member"}
                                        /> : null
                                }
                                {
                                    supervisors ?
                                        <Filter
                                            items={supervisors}
                                            handler={handleFilterSupervisor}
                                            filteredItem={filteredSupervisor}
                                            translationKeyAllItems={"filter.all_supervisor"}
                                            translationKeyTitle={"filter.supervisor"}
                                        /> : null
                                }
                                {
                                    subject === "organization" || subject === "activity" && passFilter ?
                                        <Filter
                                            items={passFilter}
                                            handler={handleFilterPasses}
                                            filteredItem={filteredPassPlan}
                                            translationKeyAllItems={"filter.all_pass"}
                                            translationKeyTitle={"filter.pass"}
                                        /> : null
                                }
                            </div>
                            <div className="page-activity__calendar">
                                {loading ? <Loading className="style-overlay"/> : null}
                                {
                                    selected &&
                                    <PassSelectionModal
                                        closeModal={() => {
                                            handleCloseModal()
                                        }}
                                        closeModalWithSessionReload={() => {
                                            handleCloseModalWithSessionReload()
                                        }}
                                        organization={props.organization}
                                        session={selected}
                                        fetchOffers={fetchOffers}
                                        sessionOffers={offer}
                                        userPasses={userOwnedPasses}
                                        fetchUserPasses={fetchUserPasses}
                                        handleUsePass={handleUsePass}
                                        handleAddToCart={handleAddToCart}
                                        showReservationConfirmation={showReservationConfirmation}
                                        goToSubscriptionStep={goToSubscriptionStep}
                                        filteredMemberId={filteredMember}
                                        filteredPassPlanId={filteredPassPlan}
                                        isLoadingOffer={isLoadingOffer}
                                        members={members}
                                        usePassError={usePassError}
                                    />
                                }
                                {
                                    sessions && date ?
                                        <CalendarView
                                            sessions={sessions}
                                            view={view}
                                            date={date}
                                            visibleToolbar={true}
                                            handleSelection={handleSelection}
                                            handleNavigate={handleNavigate}
                                            setCurrentView={setCurrentView}
                                            views={{
                                                agenda: AgendaView,
                                                week: true,
                                            }}
                                            {...additionalProps}
                                        />
                                        : <Loading/>
                                }
                            </div>
                        </div>
                        :
                        <div className={'box-message'}>
                            <FormattedMessage {...translations['no_sessions_available']}/>
                        </div>
                }
            </section>
        </div>
    );
};

PassesView.propTypes = {
    selected: PropTypes.object,
    loading: PropTypes.bool,

    subject: PropTypes.string,
    view: PropTypes.string,
    sessions: PropTypes.arrayOf(PropTypes.object),
    handleSelection: PropTypes.func.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    subtitle: PropTypes.string,

    // Permet de passer un fragment alternatif pour la réservation.
    // Le fragment par défaut implique un flow d'achat/réservation combiné.
    // Le fragment reçoit tout ce qui n'est pas utilisé, plus
    //   * session sélectionnée
    reservationFragment: PropTypes.func,

    organization: PropTypes.object,
    activity: PropTypes.object,
    group: PropTypes.object,
    showModal: PropTypes.bool,
    handleCloseModal: PropTypes.func.isRequired,
    handleCloseModalWithSessionReload: PropTypes.func.isRequired,
    passFilter: PropTypes.object,
    members: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    membersForFilter: PropTypes.array,
    offer: PropTypes.shape({
        offers: PropTypes.array,
        members: PropTypes.array,
    }),
    isLoadingOffer: PropTypes.bool.isRequired
};

PassesView.defaultProps = {
    showModal: false
};

PassesView.contextTypes = {
    intl: PropTypes.object,
};

export default PassesView;
