import PropTypes from "prop-types";
import Form from "qidigo-form";
import React from "react";
import { defineMessages } from "react-intl";


const translations = defineMessages({
  choose: {
    id: "qidigo.activity.groups.liste.offer",
    defaultMessage: "Choisir une offre..."
  }
});

/**
 * Formatte le <Select> selon un array de `Plan`.
 */

const OfferSelector = (props, context) => {
  const { formatMessage } = context.intl;
  const { selected, options, disabled, group, handleChange } = props;
  
  return (
    <Form.Select
      label={formatMessage(translations["choose"])}
      className="family-selector input-input-select input-select-plan"
      disabled={disabled}
      value={selected}
      onChange={handleChange}
      group={group}
      options={options.map(option => ({
        key: group.id + ":" + option.key,
        value: option.value
      }))}
    />
  );
};
OfferSelector.contextTypes = {
  intl: PropTypes.object
};

export default OfferSelector;
