import * as React from "react";
import {Component} from "react";
// @ts-ignore
import infoIcon from '../../../../../design/assets/help.svg';
// @ts-ignore
import creditCardInfoEn from '../../../../../design/assets/credit-card/credit_card_information_en.png';
// @ts-ignore
import creditCardInfoFr from '../../../../../design/assets/credit-card/credit_card_information_fr.png';
// @ts-ignore
import Date             from "qidigo-i18n/date";
// @ts-ignore
import Money from "@app/components/money";

// @ts-ignore
import env                from "qidigo-env";
import {IBillingAddress, IBillingInstallment, IPaymentMethod} from "../../types";
import StripeCreditCardForm from "./Stripe/stripeCreditCardForm";
import PaySafeCreditCardForm from "./PaySafe/paySafeCreditCardForm";

import { defineMessages, FormattedMessage } from "react-intl";
import {lang} from "moment";
import InstallmentWarning from "../../installmentWarning";

const translations = defineMessages({
    "credit_card": {
        id: "qidigo.billing.credit_card_form.credit_card",
        defaultMessage: "Carte de crédit"
    },
    "find_my_information": {
        id: "qidigo.billing.credit_card_form.find_my_information",
        defaultMessage: "Où trouver mes informations?"
    },
});

interface ICreditCardFormProps {
    paymentMethods: IPaymentMethod,
    user_name: string,
    isInstallmentMissing: boolean,
    billing_address: IBillingAddress|null,
    handleTokenBadRequest: (code: string) => void,
    handleOnlinePayment: (token: string) => void,
    hasAddressError: boolean,
    paymentErrors: object,
    showPaymentErrorBox: boolean,
    processingPayment: boolean,
    disabled: boolean,
    billingInstallments: Array<IBillingInstallment>
}

interface ICreditCardFormState {
    isCreditCardInformationOpen: boolean
}

class CreditCardForm extends Component<ICreditCardFormProps, ICreditCardFormState> {

    constructor(props: ICreditCardFormProps) {
        super(props);

        this.state = {
            isCreditCardInformationOpen: false
        }
    }

    toggleCreditCardInformation() {
        this.setState({
            isCreditCardInformationOpen: !this.state.isCreditCardInformationOpen
        })
    }

    creditCardFormForProvider() {
        switch (this.props.paymentMethods.type) {
            case "PAYSAFE_CAD_CC":
                return <PaySafeCreditCardForm
                    apiKey={env.PAYSAFE_API_KEY}
                    userName={this.props.user_name}
                    billingAddress={this.props.billing_address}
                    isInstallmentMissing={this.props.isInstallmentMissing}
                    handleTokenBadRequest={this.props.handleTokenBadRequest}
                    handlePayment={this.props.handleOnlinePayment}
                    hasAddressError={this.props.hasAddressError}
                    processingPayment= {this.props.processingPayment}
                    disabled={this.props.disabled}
                />;
            case "STRIPE":
                return <StripeCreditCardForm
                    api_key={env.STRIPE_API_KEY}
                    userName={this.props.user_name}
                    billingAddress={this.props.billing_address}
                    isInstallmentMissing={this.props.isInstallmentMissing}
                    handlePayment={this.props.handleOnlinePayment}
                    processingPayment={this.props.processingPayment}
                    disabled={this.props.disabled || this.props.hasAddressError }
                />;
        }
    }

    render() {
        const creditCardInfo = lang() === 'fr' ? creditCardInfoFr : creditCardInfoEn

        return (
            <div className={"credit-card-form"}>
                <div className="credit-card-form--title">
                    <FormattedMessage {...translations["credit_card"]} />
                </div>
                {
                    this.props.showPaymentErrorBox &&
                    <div className={"billing-view--payment-errors-container"}>
                        {this.props.paymentErrors}
                    </div>
                }
                {
                    this.props.billingInstallments &&
                    this.props.billingInstallments.length > 0 &&
                    <InstallmentWarning installments={this.props.billingInstallments}/>
                }
                <div className={"credit-card-form--info-button-container"}>
                    <img
                        className={"credit-card-form--info-button-image"}
                        src={infoIcon}
                        alt={"info-icon"}
                    />
                    <button
                        className={"credit-card-form--info-button button-link"}
                        onClick={() => this.toggleCreditCardInformation()}
                    >
                        <FormattedMessage {...translations["find_my_information"]} />
                    </button>
                </div>
                {
                    this.state.isCreditCardInformationOpen &&
                    <div className={"credit-card-form--info"}>
                        <img
                            src={creditCardInfo}
                            alt={"credit-card-info-icon"}
                        />
                    </div>
                }
                {
                    this.creditCardFormForProvider()
                }
           </div>
        );
    }
}

export default CreditCardForm;
