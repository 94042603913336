import {Component}           from "react";
import PropTypes             from "prop-types";
import {connect}             from "@app/lib/redux";
import {navigate}            from "qidigo-router";
import {path}                from "@app/lib/routes";

import {
	fetchGroup,
} from "@app/store/actions/search/group";

/**
 * Redirige une URL "nue" (/class/:id) d'un groupe vers la nouvelle structure.
 *
 * Sert pour gérer les adresses de l'app legacy.
 *
 * @extends {Component}
 */
class GroupRedirector extends Component {
	/**
	 * Charge le data pour le contrôleur.
	 * (API publique)
	 */
	static load(dispatch, params) {
		return Promise.all([
			dispatch(fetchGroup(params.groupID, {withoutOrganization: true})),
		]);
	}

	/**
	 * Proxy pour `static load`.
	 */
	load() {
		const {dispatch, params} = this.props;
		GroupRedirector.load(dispatch, params);
	}

	componentDidMount() {
		// Pour avertir si *dans l'app* on navigue de /class/:id vers la nouvelle adresse.
		// À conserver, vu le besoin de redirect pour l'app legacy.
		if (process.env.NODE_ENV === "development") {
			console.error("!! Use of GroupRedirector"); // eslint-disable-line
		}
		this.load();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.params.groupID !== this.props.params.groupID) {
			this.load();
		}
		else if (this.props.group) {
			const {group} = this.props;
			const {activity} = group;
			const {organization} = group;
			navigate(
				path("group", {groupID: group["id"], activityID: activity["id"], orgID: organization["slug"]}),
				{replace: true}
			);
		}
	}

	render() {
		return null;
	}
}

GroupRedirector.propTypes = {
	dispatch: PropTypes.func,
	params: PropTypes.object,
	route:  PropTypes.object,
	group: PropTypes.object,
};

// Connecte avec le store redux.
const withProperties = ({search: {group: {group, fetching}}}) => ({group, fetching});
export default connect(withProperties)(GroupRedirector);
