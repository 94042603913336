const loadPaysafe = (callback: () => void) => {
        const paysafeScriptId = 'src--paysafe';
        const paysafeScriptTag = document.getElementById(paysafeScriptId);
        if (paysafeScriptTag !== null) {
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://hosted.paysafe.com/js/v1/latest/paysafe.min.js';
        script.id = paysafeScriptId;
        document.body.appendChild(script);

        script.onload = () => { 
            if (callback) callback();
        };
};

export default loadPaysafe;
