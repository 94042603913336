/* eslint react/display-name: [0] */
/* global require */
/**
 * @module translated_app
 */
import React, {Component} from "react";
import PropTypes             from "prop-types";
import {addLocaleData, IntlProvider} from "react-intl";
import moment                from "moment";
import "moment/locale/en-ca";
import "moment/locale/fr";
import "moment/locale/fr-ca";

import QidigoSessionStore    from "qidigo-sessionstore";

import map                   from "lodash/map";
import orderBy               from "lodash/orderBy";
import deburr                from "lodash/deburr";

// Import des spécificités anglophones
import enLocaleData from "react-intl/locale-data/en";
addLocaleData(enLocaleData);

// Import des spécificités francophones
import frLocaleData from "react-intl/locale-data/fr";
addLocaleData(frLocaleData);

// Charge les langues dans moment.js
moment.locale("en");
moment.locale("en-ca");
moment.locale("fr");
moment.locale("fr-ca");

// Sélectionne la locale.
moment.locale(getLocale());

// "Singleton" d'une référence vers l'instance d'Internationalizable
let APP_SINGLETON = null;

// Les fichiers de langue.
const MESSAGES = {
	// TODO : Charger au besoin uniquement la langue.
	fr: {},
	en: require("@app/locales/en.json"),
};

/** Langue par défaut (français) */
const DEFAULT_LOCALE = "fr";

/**
 * Donne la langue utilisée présentement
 *
 * @return {string} Code de langue utilisé.
 */
export function getLocale() {
	const store = new QidigoSessionStore("locale");
	const data = store.get();

	// FIXME: Currently the language used is the one saved in the session.
	//  You must empty your session and reconnect for the change of language to be functional.
	//  This must be modified to use the language of the connected user from the database.
	if (data && data.locale) {
		return data.locale;
	}

	const navigatorLanguage = getNavigatorLang();

	if (navigatorLanguage) {
		if (navigatorLanguage.startsWith('fr')) {
			return 'fr';
		}

		if (navigatorLanguage.startsWith('en')) {
			return 'en';
		}
	}

	return DEFAULT_LOCALE;
}

function getNavigatorLang() {
	if (navigator.languages != undefined)
		return navigator.languages[0];
	return navigator.language;
}

function saveLocale(locale) {
	locale = locale.toLowerCase();
	const store = new QidigoSessionStore("locale");
	store.set({locale});
}

/**
 * Change la langue en notifiant l'app.
 */
export function setLocale(locale) {
	locale = locale.toLowerCase();
	saveLocale(locale);
	if (APP_SINGLETON) {
		APP_SINGLETON.setLocale(locale);
	}
}

/**
 * Rend un *component* du plus haut niveau *translatable*.
 * Permet d'utiliser, ensuite, `intl` dans le contexte.
 * Voir `react-intl`.
 */
class Internationalizable extends Component {
	constructor() {
		super();
		this.state = {
			defaultLocale: DEFAULT_LOCALE,
		};
	}
	componentWillMount() {
		this.setLocale(getLocale());
		APP_SINGLETON = this; // eslint-disable-line
	}
	setLocale(locale) {
		locale = locale.toLowerCase();
		saveLocale(locale);
		this.setState({locale});
		// Sélectionne la locale.
		moment.locale(getLocale());
	}
	getChildContext() {
		return {
			currentLocale: this.state.locale,
			setLocale: (locale) => this.setLocale(locale),
		};
	}
	render() {
		return (
			<IntlProvider
				locale={this.state.locale}
				defaultLocale={this.state.defaultLocale}
				messages={MESSAGES[this.state.locale]}
			>
				{this.props.children}
			</IntlProvider>
		);
	}
}

Internationalizable.childContextTypes = {
	currentLocale: PropTypes.string,
	setLocale: PropTypes.func,
};

export default Internationalizable;

/**
 * Utilitaire pour utiliser des données traduisibles dans un <Select>.
 *
 * Autrement, c'est difficile de les *mapper*, parce que defineMessage() donne
 * un objet, donc aucun ordre garanti. De plus, c'est généralement nécessaire
 * d'ordonner les valeurs.
 *
 * @param formatMessage {function} Fonction formatMessage de react-intl.
 * @parma list {object} Liste retournée par defineMessage à organiser.
 * @param orderKey {string} Clé à utiliser pour ordonner.
 */
export function mapOptionsTranslations(formatMessage, list, orderKey="value") {
	return orderBy(map(list, (value, key) => {
		return Object.assign({key, value: formatMessage(value)}, value);
	}), (o) => deburr(o[orderKey]).toUpperCase());
}

export const LANGS = [
	["FR", "Français"],
	["EN", "English"],
];
