import React                 from "react";
import PropTypes             from "prop-types";
import moment                from "moment";

const formatDate = (date, {withDate = true, withTime = false}) => {
	const timeFormat = moment.locale() === 'fr' ? "HH[h]mm" : "LT";

	let formatString = '';

	if (withDate) {
		formatString += "LL";
	}

	if (withTime) {
		if (withDate) {
			formatString += ", ";
		}
		formatString += timeFormat;
	}

	return moment(date).format(formatString).trim();
};

/**
 * Component pour afficher une date formattée.
 *
 * Props:
 *   * `date` : "momentable".
 */
const QidigoLocalizedDate = (props, context) =>
	<span>
		{formatDate(props.date, {...props})}
	</span>
	;

QidigoLocalizedDate.propTypes = {
	date: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
	withTime: PropTypes.bool,
	withDate: PropTypes.bool,
};

QidigoLocalizedDate.defaultProps = {
	withTime: false,
	withDate: true,
};

export default QidigoLocalizedDate;
export {formatDate};
