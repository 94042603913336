import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {
	FormattedMessage,
	defineMessages,
} from "react-intl";
import {connect}             from "@app/lib/redux";
import LoadingBar            from "@app/components/loadingbar.js";
import Timer                 from "@app/views/cart/_timer";
import Avatar                from "@app/components/avatar.js";
import Loading               from "qidigo-components/loading.js";
import QidigoSessionStore    from "qidigo-sessionstore";
import {toggleCart}          from "@app/store/actions/cart";
import {navigate}            from "qidigo-router";
import QidigoBackLocation from "../../modules/qidigo-router/back_location";

const messages = defineMessages({
	messages: {id: "qidigo.header.messages", defaultMessage: "Messagerie"},
	cart:      {id: "qidigo.header.cart", defaultMessage: "Panier"},
	profile:   {id: "qidigo.header.profile", defaultMessage: "Profil"},
	login:     {id: "qidigo.header.login", defaultMessage: "Mon compte"},
	help:      {id: "qidigo.header.help", defaultMessage: "Aide"},
});

// Pages where we want to display Qidigo's logo in header
const LOGO_REGEXES = [
	/^\/terms/,
	/^\/privacy/,
];

/**
 * Header du site.
 * @extends {Component}
 */
class Header extends Component {

	handleCartOpen(event) {
		event.preventDefault();
		this.props.dispatch(toggleCart());
	}

	goToLogin() {
		QidigoBackLocation.saveLastLocation();
		navigate('/login');
	}

	renderCart() {
		const {loggedUser, cartOpen, count, cart_expiration} = this.props;
		const canOpenCart = !window.location.pathname.endsWith('/checkout');

		return (
			<li className={`header--menu-cart ${canOpenCart ? "" : "disabled"}`}>
				<a href="#cart" className={cartOpen?"active":""} onClick={(event)=>this.handleCartOpen(event)}>
					<FormattedMessage {...messages.cart} />
					{
						count > 0 &&
						<span className="badge">{count}</span>
					}
					{
						cart_expiration &&
						<Timer time={cart_expiration} delta={loggedUser.server_delta} />
					}
				</a>
			</li>
		);
	}
	render() {
		const {Link, loggedUser} = this.props;

		const classes = ["qidigoheader"];
		if (loggedUser === null) {
			classes.push("is-loading");
		}
		if (loggedUser) {
			classes.push("is-logged-in");
		}

		let userMenu = null;
		let userFragment = null;

		// Get lastOrg to redirect help page wisely
		const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");
		const lastOrg = lastOrgStore.get();

		let showLogo = false;
		if (this.context.intl && this.context.router && this.context.router.location) {
			showLogo = LOGO_REGEXES.find(function(regex) {
				return pathname.match(regex);
			});
		}

		// Au chargement, rien dans le userMenu.
		if (loggedUser === null) {
			userMenu = <Loading />;
			userFragment = null;
		}
		// Connecté
		else if (loggedUser) {
			userFragment = <li className="header--menu-user">
				<Link to="/dashboard" activeClassName="active">
					<Avatar
						className="header--user-avatar"
						src={loggedUser.avatar}
						withSpinner={false}
						alt={loggedUser.full_name}
						title={loggedUser.full_name}
					/>
					<FormattedMessage {...messages.profile} />
					{
						loggedUser.unpaid !== undefined &&
						loggedUser.unpaid > 0 &&
						<span className="badge">{loggedUser.unpaid}</span>
					}
				</Link>
			</li>;

			userMenu =
				<ul>
					{userFragment}
					{
						loggedUser.can_see_messaging_feature
						&& <li className="header--menu-messages">
							<Link to="/messages" activeClassName="active">
								<FormattedMessage {...messages.messages} />
								{
									loggedUser.unread !== undefined &&
									loggedUser.unread > 0 &&
									<span className="badge">{loggedUser.unread}</span>
								}
							</Link>
						</li>
					}

					{this.renderCart()}
					{
						lastOrg &&
						lastOrg["slug"] &&
						<li className="header--menu-help"><Link to={`/u/${lastOrg["slug"]}/contact`} activeClassName="active">
							<FormattedMessage {...messages.help} />
						</Link></li>
					}
				</ul>
			;
		}
		// Déconnecté c'est `false`.
		else {
			userFragment = null;
			userMenu =
				<ul>
					<li className="header--menu-login" key="login"><Link onClick={this.goToLogin} activeClassName="active">
						<FormattedMessage {...messages.login} />
					</Link></li>
					{
						lastOrg &&
						lastOrg["slug"] &&
						<li className="header--menu-help"><Link to={`/u/${lastOrg["slug"]}/contact`} activeClassName="active">
							<FormattedMessage {...messages.help} />
						</Link></li>
					}
				</ul>
			;
		}

		return (
			<header id="header" className={classes.join(" ")}>
				<div className="container">
					<nav className="header--main-menu">
					</nav>
					{
						showLogo &&
						<h1><Link to="/" activeClassName="active">Qidigo</Link></h1>
					}
					<nav className="header--user-menu">{userMenu}</nav>
				</div>
				<LoadingBar id="loading" />
			</header>
		);
	}
}

Header.propTypes = {
	// Pour changer le type des liens quand on plante.
	cartOpen: PropTypes.bool,
	count: PropTypes.number,
	cart_expiration: PropTypes.any,
	dispatch: PropTypes.func,
	toggleCart: PropTypes.func,
	Link: PropTypes.func.isRequired,
	loggedUser: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),
};

Header.contextTypes = {
	router: PropTypes.object,
};
const withProperties = ({ cart: { open: cartOpen, count, cart_expiration }})=>({ cartOpen, count, cart_expiration });
export default connect(withProperties)(Header);
