import React, {Component}    from "react";
import PropTypes             from "prop-types";
import GatewayProvider       from "qidigo-components/gateway/provider";
import {RedBoxError}         from "redbox-react";
import Auth                  from "qidigo-auth";
import QidigoLayout          from "@app/views/layout";
import ErrorPage             from "@app/pages/error.js";
import {browserHistory}      from "qidigo-router";
import {defineMessages, FormattedMessage} from "react-intl";

// Re-crée l'API "Link" de react-router, mais fait un lien classique.
const RegularLink = ({to, children}) => <a href={to}>{children}</a>;
const STYLE = {position: "relative", color: "#fff", background: "#aa0000", padding: "1em", overflowX: "scroll", fontFamily: "monospace", fontSize: "1rem"};

const translations = defineMessages({
	"title": {id: "qidigo.fatal-error.title", defaultMessage: "Une erreur inattendue s'est produite..."},
	"explanation.default": {id: "qidigo.fatal-error.explanation.default", defaultMessage: "Il semblerait que cette section du site éprouve des difficultés. Veuillez réessayer sous peu. Si le problème persiste, n'hésitez pas à contacter notre support technique."},
	"explanation.details": {id: "qidigo.fatal-error.explanation.details", defaultMessage: "Vous pouvez transmettre le message qui suit à notre service à la clientèle."},
});


/**
 * Présente une page 500 lorsque c'est une erreur critique.
 *
 * Empêche react de présenter une application brisée.
 */
class ErrorsController extends Component {
	constructor() {
		super();
		this.state = {
			loggedUser: null,
			error: null,
		};
		this.historyHook = this.historyHook.bind(this);
	}

	historyHook(location, action) {
		// Sur un mouvement d'history, on refresh, parce que l'app est crashée!
		window.location.reload(true);
	}

	componentDidMount() {
		Auth.userLoggedIn()
			.then((loggedUser) => this.setState({loggedUser}))
		;
		this.unlisten = browserHistory.listen(this.historyHook);

		const {error} = this.props;
		const info = this.props.reactInfos;

		if (error) {
			this.setState({error, info});
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextProps.error !== this.state.error) {
			this.setState({error: nextProps.error});
		}

		const {error} = nextState;
		if (error.status && error.bodyUsed === false) {
			error.json().then((body) => {
				this.setState({error: {body, status: error.status}});

				return error;
			});
		}
	}

	componentWillUnmount() {
		if (this.unlisten) { this.unlisten(); }
	}

	render() {
		const {formatMessage} = this.context.intl;
		const {info, loggedUser} = this.state;
		const error = this.state.error || this.props.error;

		let status = 500;
		// Allows any other kind of error to pass through.
		if (error && error.status) { status = error.status; }

		const details = [];
		if (error instanceof Error) {
			details.push(
				<pre key="error-string">{error.toString()}</pre>
			);
		}

		const child = [];

		if (details.length > 0) {
			child.push(
				<div className="qidigo-error" key>
					<p><FormattedMessage {...translations["explanation.details"]} /></p>
					{details}
				</div>
			);
		}
		const title = formatMessage(translations["title"]);
		const explanation = formatMessage(translations["explanation.default"]);

		const additionalProps = {};
		if (status >= 500 && status < 600) {
			additionalProps["title"] = title;
			additionalProps["explanation"] = explanation;
		}

		if (process.env.NODE_ENV === "development") {
			child.push(<hr key="sep" />);
			child.push(<h1 key="dev-title">Development informations...</h1>);

			if (error && error.stack) {
				child.push(
					<div style={{position: "relative"}} key="js-error">
						<RedBoxError
							error={error}
							style={{redbox: STYLE}}
						/>
					</div>
				);
			}

			if (error && error.response) {
				child.push(<RailsStack response={error.response} key="rails-error" />);
			}

			if (error && error.status && error.body) {
				child.push(
					<div style={{position: "relative"}} key="uknown-error">
						<pre
							style={STYLE}
						>
							{JSON.stringify(error.body, null, "  ")}
						</pre>
					</div>
				);
			}

			if (info && info["componentStack"]) {
				child.push(
					<pre
						style={STYLE}
						key="component-stack"
					>
						{info["componentStack"]}
					</pre>
				);
			}
		}

		return (
			<GatewayProvider>
				<QidigoLayout
					loggedUser={loggedUser}
					Link={RegularLink}
				>
					<ErrorPage code={status} {...additionalProps}>
						{child}
					</ErrorPage>
				</QidigoLayout>
			</GatewayProvider>
		);
	}
}

ErrorsController.propTypes = {
	error: PropTypes.oneOfType([RedBoxError.propTypes.error, PropTypes.object]),
	reactInfos: PropTypes.object,
};

ErrorsController.contextTypes = {
	intl: PropTypes.object,
};

export default ErrorsController;

/**
 * Parses lightly the rails error response.
 */
const RailsStack = ({response}) => {
	if (!response.body) { return null; }
	const {body} = response;
	const {exception, traces} = body;
	if (!exception || !traces) { return null; }

	return (
		<div>
			<h2>Sever-side error</h2>
			<p style={STYLE}>
				{exception}
			</p>
			{Object.keys(traces).map((type, i) =>
				<div key={i}>
					<h3>{type}</h3>
					<pre style={STYLE}>
						{traces[type].map((o)=>o["trace"]).map((trace_line, j)=><span key={j}>{trace_line}{"\n"}</span>)}
					</pre>
				</div>
			)}
		</div>
	);
};
RailsStack.propTypes = {
	response: PropTypes.oneOfType([PropTypes.object]),
};

export {RailsStack};

