import React, {Component}    from "react";
import PropTypes             from "prop-types";
import reactTimeout          from "react-timeout";
import moment                from "moment";
/**
 * Who needs leftpad?
 */
const pad = (x) => parseInt(x) < 10 ? `0${x}` : `${x}`;

class Timer extends Component {
	constructor() {
		super();
		this.state = {
			time: null,
			delta: null,
		};
	}

	componentWillMount() {
		const time = moment(this.props.time);
		const {delta} = this.props;
		this.updateTime(time, delta);

		this.props.setInterval(() => this.visualUpdate(), 1000);
	}

	componentWillUpdate(nextProps) {
		if (nextProps.time !== this.props.time || nextProps.delta !== this.props.delta) {
			this.updateTime(nextProps.time, nextProps.delta);
		}
	}

	/**
	 * Met à jour le moment à compter.
	 */
	updateTime(newTime, delta) {
		const time = moment(newTime);
		this.setState({time, delta: moment.duration(delta)});
	}

	/**
	 * Force un update visuel en changeant la référence du présent.
	 */
	visualUpdate() {
		const {delta} = this.state;
		this.setState({now: moment().subtract(delta)});
	}

	render() {
		const {time, now} = this.state;
		if (!time || !now) { return null; }
		const left = moment.duration(time.diff(now));
		if (left.asMilliseconds() < 0) { return null; }

		return (
			<div className="header--cart-timer">
				<span className="cart-timer--minutes">
					{pad(left.minutes())}
				</span>
				<span className="cart-timer--separator">
					{left.seconds() % 2 === 0 ? ":" : " "}
				</span>
				<span className="cart-timer--seconds">
					{pad(left.seconds())}
				</span>
			</div>
		);
	}
}

Timer.propTypes = {
	time: PropTypes.string,
	delta: PropTypes.number,
	setInterval: PropTypes.func,
};

export default reactTimeout(Timer);

