import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {defineMessages}      from "react-intl";
import Auth                  from "qidigo-auth";
import {navigate}            from "qidigo-router";
import BackLocation          from "qidigo-router/back_location";
import LoginView             from "@app/views/login/login.js";
import QidigoBackLocation from "../../modules/qidigo-router/back_location";

export const errorMessages = defineMessages({
	"other_error":      {id: "qidigo.login.errors.other_error", defaultMessage: "Une erreur inattendue est survenue. Veuillez réessayer sous peu."},
	"invalid_password": {id: "qidigo.login.errors.invalid_password", defaultMessage: "Le mot de passe est invalide pour le compte."},
	"invalid_user":     {id: "qidigo.login.errors.invalid_user", defaultMessage: "Vos identifiants de connexion sont invalides. Veuillez valider votre adresse courriel et votre mot de passe."},
	"register.invalid_password": {id: "qidigo.register.errors.invalid_password", defaultMessage: "Cette adresse possède déjà un compte chez Qidigo, mais le mot de passe donné n'est pas le bon."},
	"register.too_short": {id: "qidigo.register.errors.too_short", defaultMessage: "Le mot de passe doit comporter au minimum {length} caractères."},
});

const PASSWORD_LENGTH = 8;

/**
 * Page de connexion du site.
 * @extends {Component}
 */
class LoginPage extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			errors: null,
			shown: null,
			user: {
				email: "",
				email_confirmation: "",
				password: "",
				password_confirmation: "",
				remember: true,
			},
		};
	}

	onValidationError(errors) {
		this.setState({errors});
	}

	onChange(field, e, value) {
		const user = this.state.user;
		user[field] = value;
		this.setState({user});
	}

	onLoginSubmit(e) {
		const {user: {email, password, remember}} = this.state;
		this.setState({loading: true, errors: false});

		Auth.login(email, password, {remember})
		.then((token) => {
			this.setState({loading: false});
			QidigoBackLocation.goBack();

			return token;
		})
		.catch((id) => {
			const {formatMessage} = this.context.intl;
			let message = errorMessages[id];
			if (errorMessages[`login.${id}`]) {
				message = errorMessages[`login.${id}`];
			}
			const errors = {message: formatMessage(message)};
			this.setState({loading: false, errors});

			return errors;
		})
		;
	}

	validateRegistration() {
		const {user: {password}} = this.state;
		const errors = {};

		// FIXME : Collect error messages and format.
		if (password.length < PASSWORD_LENGTH) {
			const {formatMessage} = this.context.intl;
			errors["message"] = formatMessage(errorMessages["register.too_short"], {length: PASSWORD_LENGTH});
			this.setState({loading: false, errors});
			return false
		}

		return true;
	}

	/**
	 * Tente une connexion avec les informations. Si le compte n'existe pas,
	 * le processus d'enregistrement de compte est enclanché. Autrement, on
	 * affiche les erreurs de connexion.
	 */
	onRegisterSubmit(e) {
		const {user: {email, password}} = this.state;
		
		this.setState({loading: true, errors: null});
		// Tente la connexion...
		Auth.login(email, password, {remember: true})
		.then((token) => {
			this.setState({loading: false});
			QidigoBackLocation.goBack();

			return token;
		})
		.catch((id) => {
			// Assume que `invalid_user` permet de continuer en créant l'utilisateur.
			// (Fait partie de l'API.)
			if (id === "invalid_user") {
				const {state} = this;
				if (this.validateRegistration()) {
					navigate("/registration/info", {
						state,
					});
				}

				return false;
			}

			if (id === "invalid_password") {
				this.setState({shown: "login"});
			}
		
			// Passthrough
			return Promise.reject(id);
		})
		.catch((id) => {
			const {formatMessage} = this.context.intl;
			let message = errorMessages[id];
			if (errorMessages[`register.${id}`]) {
				message = errorMessages[`register.${id}`];
			}
			const errors = {message: formatMessage(message)};
			this.setState({loading: false, errors});

			return errors;
		})
		;
	}

	handleUnfold(which) {
		this.setState({shown: which});
	}

	render() {
		const {
			loading,
			user,
			errors,
			shown,
		} = this.state;

		return <LoginView
			onLoginSubmit={(...e)=>this.onLoginSubmit(...e)}
			onRegisterSubmit={(...e)=>this.onRegisterSubmit(...e)}
			onChange={(...e)=>this.onChange(...e)}
			onValidationError={(...e)=>this.onValidationError(...e)}
			values={user}
			loading={loading}
			errors={errors}
			shown={shown}
			unfold={(which) => this.handleUnfold(which)}
		/>;
	}
}

LoginPage.propTypes = {
	location: PropTypes.object,
};

LoginPage.contextTypes = {
	lastLocation: PropTypes.object.isRequired,
	intl: PropTypes.object,
};

export default LoginPage;
