import * as React from "react";
import {Component} from "react";

export const ALERT_TYPE = {
    ERROR: 'error',
    WARNING: 'warning',
    SUCCEED: 'succeed'
}

export interface IAlertMessage {
    type: string,
    message: object,
    id: string,
}

export interface IAlertProps {
    messageItem: IAlertMessage,
}

class Alert extends Component<IAlertProps> {
    formatMessage(alertType) {
        switch (alertType) {
            case ALERT_TYPE.SUCCEED:
                return "success-message";
            case ALERT_TYPE.WARNING:
                return "warning-message";
            case ALERT_TYPE.ERROR:
                return "error-message";
            default:
                return "";
        }
    }

    render() {
        return (
            <li
                key={this.props.messageItem.id}
                className="message-box"
            >
                <div className={this.formatMessage(this.props.messageItem.type)}>
                    <p>{this.props.messageItem.message}</p>
                </div>
            </li>
        );
    }
}

export default Alert;
