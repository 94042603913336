import React, {useState} from "react";
import PropTypes             from "prop-types";
import OverlayLayout from "@app/views/overlay/_layout";
import Button                from "qidigo-components/button.js";
import Fetch                 from "qidigo-fetch";

import {
    defineMessages,
    FormattedMessage
} from "react-intl";
import Helmet from "react-helmet";

const modalTranslations = defineMessages({
    "title":          {id: "qidigo.organization.layout.modal.consent.title", defaultMessage: "Consentement du partage de vos informations personnelles"},
    "description":    {id: "qidigo.organization.layout.modal.consent.description", defaultMessage: "Vos informations sont nécessaires pour continuer. Les informations de votre profil seront partagées avec cette organisation."},
    "subdescription": {id: "qidigo.organization.layout.modal.consent.subdescription", defaultMessage: "Les informations suivantes seront partagées, si disponibles\u00A0:"},
    "consent":        {id: "qidigo.organization.layout.modal.consent.consent", defaultMessage: "Je consens"},
    "decline":        {id: "qidigo.organization.layout.modal.consent.decline", defaultMessage: "Je refuse"},
    "picture":        {id: "qidigo.organization.layout.modal.consent.picture", defaultMessage: "Photo de profil"},
    "full_name":      {id: "qidigo.organization.layout.modal.consent.full_name", defaultMessage: "Prénom et nom"},
    "date_of_birth":  {id: "qidigo.organization.layout.modal.consent.date_of_birth", defaultMessage: "Date de naissance"},
    "gender":         {id: "qidigo.organization.layout.modal.consent.gender", defaultMessage: "Genre"},
    "sin":            {id: "qidigo.organization.layout.modal.consent.sin", defaultMessage: "Numéro d'assurance social"},
    "phone_numbers":  {id: "qidigo.organization.layout.modal.consent.phone_numbers", defaultMessage: "Numéros de téléphone"},
    "addresses":      {id: "qidigo.organization.layout.modal.consent.addresses", defaultMessage: "Adresses"},
    "email":          {id: "qidigo.organization.layout.modal.consent.email", defaultMessage: "Courriel"},
    "family_members": {id: "qidigo.organization.layout.modal.consent.family_members", defaultMessage: "Membres de la famille\u00A0:"},
    "CONTACT_ALREADY_EXISTS": {
        id: "qidigo.organization.layout.modal.consent.error.contact_already_exists",
        defaultMessage: "Vous avez déjà accepté de partager vos informations personnelles. Essayez de rafraîchir votre page."
    },
    "ORGANIZATION_DOES_NOT_EXIST": {
        id: "qidigo.organization.layout.modal.consent.error.organization_does_note_exist",
        defaultMessage: "Il est impossible de partager vos informations personnelles avec cette organisation."
    },
    "UNKNOWN": {
        id: "qidigo.organization.layout.modal.consent.error.unknown",
        defaultMessage: "Il est actuellement impossible de partager vos informations personnelles avec cette organisation. Veuillez réessayer plus tard."
    },
})

const SubscribeToOrganizationConfirmationModal = ({isOpen, onClose, onConfirm, onConsent, organization}, context) => {
    if (!isOpen) return null;
    const [errors, setErrors] = useState({});

    const handleConsent = () => {
        const {loggedUser} = context;
        Fetch.post(`contacts`, {
            user_id: loggedUser.id,
            organization_id: organization.id,
        }).then(() => {
            onConfirm();
            onConsent ? onConsent() : onClose;
        })
        .catch((error) => {
            if (error.status === 422) {
                setErrors(error["invalid-params"]);

                return;
            }

            setErrors({
                contact: "UNKNOWN",
            });
        });
    }

    const {formatMessage} = context.intl;
    const userSharedInformation = [
        'picture',
        'full_name',
        'date_of_birth',
        'gender',
        'sin',
        'phone_numbers',
        'addresses',
        'email',
    ];

    const memberSharedInformation = [
        'picture',
        'full_name',
        'date_of_birth',
        'gender',
    ];

    return (
        <OverlayLayout close={onClose}>
            <div className={'subscription-confirmation-modal-body'}>
                <section>
                    <h1><FormattedMessage {...modalTranslations['title']} /></h1>
                    <Helmet
                        title={formatMessage(modalTranslations['title'])}
                    />
                    <p>
                        <FormattedMessage
                            {...modalTranslations['description']}
                            values={{organizationName: organization.name}}
                        />
                    </p>
                    <p><FormattedMessage {...modalTranslations['subdescription']} /></p>
                    <ul>
                        {userSharedInformation.map((key) => (
                            <li key={key}>
                                <FormattedMessage {...modalTranslations[key]} />
                            </li>
                        ))}
                        <li>
                            <FormattedMessage {...modalTranslations['family_members']} />
                            <ul>
                                {memberSharedInformation.map((key) => (
                                    <li key={key}>
                                        <FormattedMessage {...modalTranslations[key]} />
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </section>
            </div>
            <div className='subscription-confirmation-modal-buttons-bar'>
                <Button onClick={onClose}>
                    <FormattedMessage {...modalTranslations['decline']} />
                </Button>
                <Button onClick={handleConsent}>
                    <FormattedMessage {...modalTranslations['consent']} />
                </Button>
            </div>
            {
                errors
                &&
                <div>
                    {
                        Object.keys(errors).map((key) => (
                            <FormattedMessage {...modalTranslations[errors[key]]} />
                        ))
                    }
                </div>
            }
        </OverlayLayout>
    );
};

SubscribeToOrganizationConfirmationModal.contextTypes = {
    intl: PropTypes.object,
    loggedUser: PropTypes.object,
};

SubscribeToOrganizationConfirmationModal.propTypes = {
    organization: PropTypes.object,
};

export default SubscribeToOrganizationConfirmationModal;
