import React, { useCallback, useState, useEffect }                 from "react";
import PropTypes             from "prop-types";
import {
	defineMessages,
} from "react-intl";

import Form                  from "qidigo-form";
import {age}                 from "@app/views/shared/user/age";
import AddMemberModal from './add_member_modal'
import noop from 'lodash/noop'

const translations = defineMessages({
	"template.select": {
		id: "qidigo.shared.family.selector.template.select",
		defaultMessage: "{name} {age}",
		description: "S'affiche comme suit: `Samuel Dionne-Riel (27 ans)`. L'âge est dans le bon format pour la langue."
	},
	"choose": {
		id: "qidigo.shared.family.selector.choose",
		defaultMessage: "Choisir un participant",
	},
	"new_family_member_title": {
		id: "qidigo.dashboard.family_members.new_family_member_title",
		defaultMessage: "Ajouter un nouveau membre",
	},
});

/**
 * Formatte le <Select> selon un array de `familyMembers`.
 */
const FamilySelector = (props, context) => {
	const {formatMessage} = context.intl;
	const {
		selected,
		options,
		disabled,
		handleChange,
		shouldAllowAddMember = false,
		onNewMemberAdded = noop
	} = props;

	const [hasAddMemberModalOpened, setHasAddMemberModalOpened] = useState(false)
	const closeAddMemberModal = useCallback(
		() => {
			setHasAddMemberModalOpened(false)
		},
		[setHasAddMemberModalOpened],
	)

	const selectOptions = options.map((option) => ({
		key: option["id"],
		value: formatMessage(translations["template.select"], {
			name: option["full_name"],
			age: option["birthday"] ? `(${age(formatMessage, option["birthday"])})` : '',
		}),
	}))

	const handleSelectChange = useCallback(
		(event, optionId) => {
			if (optionId === 'add') {
				setHasAddMemberModalOpened(true)
				handleChange(null, null)
			} else {
				handleChange(event, optionId)
			}
		},
		[handleChange],
	)

	const handleNewMemberAdded = useCallback((data) => {
		onNewMemberAdded(data)
		handleChange({
			target: {
				value: data.id
			}
		}, data.id)
		closeAddMemberModal()
	}, [onNewMemberAdded, closeAddMemberModal])

	if (hasAddMemberModalOpened) {
		return (
			<AddMemberModal
				onNewMemberAdded={handleNewMemberAdded}
				onClose={closeAddMemberModal}
			/>
		)
	}

	return (
		<Form.Select
			label={formatMessage(translations["choose"])}
			className="family-selector"
			disabled={disabled}
			value={selected}
			onChange={handleSelectChange}
			options={
				shouldAllowAddMember
					? [
						...selectOptions,
						{
							key: 'add',
							value: formatMessage(translations["new_family_member_title"])
						}
					]
					: selectOptions
			}
		/>
	);
};

const WEAK_DATE = PropTypes.oneOfType([PropTypes.object, PropTypes.string]);
const ID_SHAPE = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
const MEMBER_SHAPE = PropTypes.shape({
	id: ID_SHAPE.isRequired,
	full_name: PropTypes.string.isRequired,
	birthday: WEAK_DATE.isRequired,
});

FamilySelector.propTypes = {
	options: PropTypes.arrayOf(MEMBER_SHAPE).isRequired,
	handleChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	shouldAllowAddMember: PropTypes.bool,
	onNewMemberAdded: PropTypes.func,
	selected: ID_SHAPE,
};

FamilySelector.contextTypes = {
	intl: PropTypes.object,
};

export default FamilySelector;
