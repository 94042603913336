import React, {Component}    from "react";
import PropTypes             from "prop-types";
import Input                 from "./input.js";
import T                     from "qidigo-i18n/messages";
import filter                from "lodash/filter";
/* eslint max-statements: ["warn", 40] */

// To filter out non-numbers items when storing the number.
const ANYTHING_BUT_NUMBERS = /[^0-9]*/g;

// FIXME : This will either need to go away or be implemented.
const DEFAULT_COUNTRY = "CA";

/**
 * Gives an array of tokens for a phone number.
 *
 * THIS ASSUMES NORTH AMERICAN NUMBERING PLAN
 * https://en.wikipedia.org/wiki/North_American_Numbering_Plan
 *
 * Google libphonenumber has issues for as-you-type uses.
 *
 * When groing worldwide, we will revisit.
 *
 * Format: http://gdt.oqlf.gouv.qc.ca/ficheOqlf.aspx?Id_Fiche=8870658
 */
function formatPhone(str, country) {
	// Do not assume it is numeric-only.
	const number = str.replace(ANYTHING_BUT_NUMBERS, "");
	const numbers = number.split("");

	const tokens = [];

	if (numbers.length > 1 && numbers[0] === "1") {
		let part = "";
		part += `${numbers.shift()}`;
		tokens.push(
			{value: part, type: "data", field: "number"},
			{value: " ",  type: "formatting"}
		);
	}
	if (numbers.length > 3) {
		let part = "";
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		tokens.push(
			// {value: "(",  type: "formatting"},
			{value: part, type: "data", field: "number"},
			{value: " ", type: "formatting"}
			// {value: ") ", type: "formatting"}
		);
	}
	if (numbers.length > 3) {
		let part = "";
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		tokens.push(
			{value: part, type: "data", field: "number"},
			{value: "-",  type: "formatting"}
		);
	}
	if (numbers.length > 4) {
		let part = "";
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		part += `${numbers.shift()}`;
		tokens.push(
			{value: part,     type: "data", field: "number"},
			{value: " ext. ", type: "formatting"}
		);
		tokens.push({value: numbers.join(""), type: "data", field: "ext"});
	}
	else {
		tokens.push({value: numbers.join(""), type: "data", field: "number"});
	}

	return tokens;
}

/**
 * Really dumb phone format validation.
 *
 * Only checks length of "number", taking country code
 * in consideration.
 *
 * THIS ASSUMES NORTH AMERICAN NUMBERING PLAN
 */
function numberValid(phoneValue) {
	let {number} = phoneValue;
	const firstChar = number.substring(0, 1);

	// Strip country code (1, +1)
	if (firstChar === "1") {
		number = number.substring(1);
	}

	return number.length === 10;
}

/**
 * Permet l'entrée, formattée, d'un numéro de téléphone.
 *
 * C'est présentement, à cause de la fonction de formattage ci-haut, limité
 * aux numéros nord-américains.
 *
 * Il faudrait déterminer l'importance des autres formats pour le premier jet.
 *
 * (Le numéro est tout de même entrable, il va être séparé...)
 *
 * FIXME : Ajouter les fonctions de validation (eg: erreur si incomplet)
 */
class PhoneInput extends Component {
	/**
	 * Propriétés du point d'insertion.
	 */
	getCaretProperties(newValue) {
		const node = this.inputNode;
		const caret = {};

		caret.start = node.selectionStart;
		caret.end = node.selectionEnd;

		// If removing characters, the caret is surely going backwards.
		// This is in relation to our added tokens, we want to skip them.
		// FIXME : THIS IS FALSE FOR DELETE KEY
		caret.goingForward = true;
		if (this.props.value && this.props.value.formatted &&
			this.props.value.formatted.length > newValue.length
		) {
			caret.goingForward = false;
		}
		
		return caret;
	}

	/**
	 * Gère le déplacement au clavier.
	 * C'est pour passer "à travers" les tokens.
	 */
	handleKey(event) {
		// FIXME : Implémenter
		switch (event.key) {
			case "ArrowLeft":
			// event.preventDefault();
				break;
			case "ArrowRight":
			// event.preventDefault();
				break;
		}
	}

	/**
	 *
	 */
	formatValue() {
		const {value} = this.props;
		const str = [];
		if (value.number) { str.push(value.number); }
		if (value.ext) { str.push(value.ext); }
		const tokens = formatPhone(str.join(""), DEFAULT_COUNTRY);

		return tokens.map((v) => v.value).join("");
	}

	/**
	 * Gère le changement de l'<Input> et appelle le handler attaché.
	 */
	handleChange(event, str) {
		// Conserve les informations du curseur.
		const caret = this.getCaretProperties(str);

		// Récupère les tokens.
		const tokens = formatPhone(str, DEFAULT_COUNTRY);
		// Formatte une string.
		const formatted = tokens.map((v) => v.value).join("");

		// Récupère les parties.
		const number = filter(tokens, (v)=>v.field === "number").map((v) => v.value).join("");
		const ext    = filter(tokens, (v)=>v.field === "ext").map((v) => v.value).join("");

		// ALGO
		// 1. Resolve position through old tokens.
		//    (We have the old tokens in props, which would allow getting where we are
		//     in relation to those old tokens)
		// 2. Find what to do from there.
		//    a. Adding ?
		//       To the end? Stick to the end
		//       Would be moving inside or over a token? Move after the token + 1
		//    b. Removing ?
		//       From the end? stick to the end
		//       Going through a token? Do nothing, it seems to be right.
		// FIXME : MOVE CARET THROUGH TOKENS

		// Copies the previous value in here too.
		const value = Object.assign({}, this.props.value, {
			number,
			ext,
			formatted,
			tokens,
		});
		if (this.props.onChange) { this.props.onChange(event, value); }
		if (!event.defaultPrevented) { this.setState({value}); }

		// Mutating the field directly allows...
		this.inputNode.value = value.formatted;
		// ... this change to stick since the new computed DOM matches.
		if (this.props.value && this.props.value.formatted) {
			if (caret.start >= this.props.value.formatted.length) {
				caret.start = value.formatted.length;
				caret.end = value.formatted.length;
			}
		}
		this.inputNode.setSelectionRange(caret.start, caret.end);
	}

	/**
	 * Implémentation de la validation.
	 *
	 * Retourne un array textuel des erreurs.
	 *
	 * @param {Boolean} softValidate [...].
	 */
	isValid(softValidate = false) {
		const {formatMessage} = this.context.intl;
		const value = this.props.value;

		if (softValidate && this.isEmpty()) {
			return [];
		}

		if (this.props.required && this.isEmpty()) {
			return [formatMessage(T["errors.messages.blank"])];
		}

		// Vérifie un nombre "régulier" de nombres.
		if (value && value.number && !numberValid(value)) {
			return [formatMessage(T["errors.messages.invalid"])];
		}

		return [];
	}

	/**
	 * Valide le champ, mets l'était `valid` à `false` si invalide.
	 *
	 * @param {Boolean} softValidate [...].
	 */
	validate(softValidate = false) {
		const valid = this.isValid(softValidate);

		return valid;
	}

	/**
	 *
	 */
	isEmpty() {
		return !(this.props.value &&
			this.props.value.number &&
			this.props.value.number !== "");
	}


	render() {
		const {
			/* eslint-disable */
			defaultValue,
			/* eslint-enable */
			value,
			className,
			...leftOverProps
		} = this.props;
		const classes = ["phone-input", className];

		const val = value && value.formatted ? value.formatted : this.formatValue(value);

		return <Input
			inputRef={node => this.inputNode = node }
			{...leftOverProps}
			type="tel"
			value={val}
			onChange={(...e) => this.handleChange(...e)}
			onKeyDown={(...e) => this.handleKey(...e)}
			className={classes.join(" ")}
		/>;
	}
}

PhoneInput.propTypes = {
	className:   PropTypes.string,
	onChange:    PropTypes.func,
	// FIXME : Shape it.
	value:       PropTypes.object,
	defaultValue: PropTypes.object,
	required:    PropTypes.bool,
};

PhoneInput.defaultProps = {
	defaultValue: {["country_id"]: DEFAULT_COUNTRY},
};

PhoneInput.contextTypes = {
	intl: PropTypes.object,
};

export default PhoneInput;
