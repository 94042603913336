import QidigoSessionStore    from "qidigo-sessionstore";
import {navigate}            from "qidigo-router";

class QidigoBackLocation {
    static saveLastLocation() {
        let backLocation = window.location.pathname + window.location.search;
        const lastOrgStore = new QidigoSessionStore("lastVisitedOrganization");
        let lastOrg = lastOrgStore.get();

        if (!backLocation && lastOrg) {
            backLocation  = `/u/${lastOrg['slug']}`

        }

        if (!lastOrg) {
            backLocation = '/dashboard';
        }

        localStorage.setItem('previousPage', backLocation);
    }

    static goBack() {
        const previousPage = localStorage.getItem('previousPage')
            ?  localStorage.getItem('previousPage')
            : '/dashboard';

        window.location.href = previousPage
    }

    static getLastLocation() {
        return localStorage.getItem('previousPage')
            ?  localStorage.getItem('previousPage')
            : '/dashboard';
    }
}

export default QidigoBackLocation;
