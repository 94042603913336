import currency from "currency.js";

/**
 * Pre-configured currency.
 *
 * The formatting is numerically done here (space, comma), but the symbol
 * for the currency is handled in the component <Money> since currency.js
 * does not handle internationalization that well. (It can't postfix symbols.)
 *
 * Currency is to be used for currency maths, parsing and for formatting the numbers.
 */
const FORMATS = {
	fr: (value) => currency(value, {symbol: "", precision: 2, separator: " ", decimal: ",", negativePattern: "(!#)"}),
	en: (value) => currency(value, {symbol: "", precision: 2, separator: ",", decimal: ".", negativePattern: "(#!)"}),
};

// Fallback
FORMATS["_default"] = FORMATS["fr"];

export default FORMATS;
