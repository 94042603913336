import * as React from "react";
import {Component} from "react";

import {defineMessages, FormattedMessage} from "react-intl";

// @ts-ignore
import Button from "qidigo-components/button.js";
import ModalStepsHeader from "./ModalStepsHeader";
// @ts-ignore
import CourseDescription from "./CourseDescription";
import qidigoAuth from "../../../modules/qidigo-auth";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
// @ts-ignore
import Subscription from "./Subscription";
import Connection from "./Conection/Connection";
// @ts-ignore
import Consent from "./Conection/Consent";
// @ts-ignore
import {
    IModalSteps,
    IFamilyMember,
    IUserPass as IUserPassForSubscription,
    IProposedOffer,
    IProposedOfferUserRestriction,
    ISession,
    ISelectionOffer,
    IUserOwnedPass, 
    IUserPass,
    IOption,
    IGroupRestriction,
    IRestriction,
    IMemberPassRestriction
} from "../types";
// @ts-ignore
import Loading from "qidigo-components/loading.js";

const GROUP_DESCRIPTION_STEP = 'group_description';
const CONNECTION_STEP = 'connection';
const SUBSCRIPTION_STEP = 'subscription';
const CONSENT_STEP = 'consent';
const GROUP_IS_FULL_RESTRICTION = 'GROUP_IS_FULL';


const translations = defineMessages({
    "next_step": {
        id: "qidigo.activity.pass_selection_modal.next_step",
        defaultMessage: "Prochaine étape"
    },
    "previous_step": {
        id: "qidigo.activity.pass_selection_modal.previous_step",
        defaultMessage: "Retour"
    },
    "close": {
        id: "qidigo.activity.pass_selection_modal.close",
        defaultMessage: "Fermer"
    },
})

const stepsWithConnection = [
    {
        step: '1',
        stepName: GROUP_DESCRIPTION_STEP,
        isCurrent: true,
        isCompleted: false,
        isLast: false,
    },
    {
        step: '2',
        stepName: CONNECTION_STEP,
        isCurrent: false,
        isCompleted: false,
        isLast: false
    },
    {
        step: '3',
        stepName: SUBSCRIPTION_STEP,
        isCurrent: false,
        isCompleted: false,
        isLast: true
    },
];

const stepsWithoutConnection = [
    {
        step: '1',
        stepName: GROUP_DESCRIPTION_STEP,
        isCurrent: true,
        isCompleted: false,
        isLast: false,
    },
    {
        step: '2',
        stepName: SUBSCRIPTION_STEP,
        isCurrent: false,
        isCompleted: false,
        isLast: true
    },
];


interface IPassSelectionModalProps {
    closeModal: () => void,
    closeModalWithSessionReload: () => void,
    organization: any,
    session: ISession,
    fetchOffers: () => void,
    sessionOffers: ISelectionOffer | null,
    userPasses: Array<IUserOwnedPass> | null,
    fetchUserPasses: () => void,
    handleUsePass: (subscriberId: number, userPass: IUserPass) => void
    handleAddToCart: (planId: number, subscriberId: number) => void,
    showReservationConfirmation: boolean,
    goToSubscriptionStep: boolean,
    filteredMemberId: string,
    filteredPassPlanId: string,
    isLoadingOffer: boolean,
    members: IFamilyMember[],
    usePassError: string
}

interface IPassSelectionModalState {
    stepArray: Array<IModalSteps>,
    currentStep: IModalSteps,
    isUserLoggedIn: boolean,
    isUserContact: boolean,
    isFetchingOffer: boolean,
    ownedPasses: Array<IUserPassForSubscription>,
    ownedPassesBuilt: boolean,
    proposedOffers: IProposedOffer[] | null,
    loading: boolean,
    isPassUsed: boolean,
    canGoToNextStep: boolean
}


class PassSelectionModal extends Component<IPassSelectionModalProps, IPassSelectionModalState> {
    constructor(props: IPassSelectionModalProps) {
        super(props);
        this.state = {
            currentStep: stepsWithConnection[0],
            stepArray: stepsWithConnection,
            isUserLoggedIn: false,
            isUserContact: false,
            isFetchingOffer: false,
            ownedPasses: [],
            ownedPassesBuilt: false,
            proposedOffers: [],
            loading: true,
            isPassUsed: false,
            canGoToNextStep: true,
        }
    }

    async componentDidMount() {
        const isUserLoggedIn = await qidigoAuth.userLoggedIn();
        let stepsArray = stepsWithConnection
        let isUserContact = false;

        if (!isUserLoggedIn) {
            stepsArray[1].stepName = CONNECTION_STEP;
        }

        if (isUserLoggedIn) {
            await qidigoFetch.get("whoami").then((response) => {
                isUserContact = response.contacts.find((contact) => contact.organization_id === this.props.organization.id)
            })

            let stepToChange = stepsArray.find((step) => step.stepName === CONNECTION_STEP);
            if (!isUserContact && stepToChange) {
                stepToChange.stepName = CONSENT_STEP;
            }

            if (isUserContact) {
                stepsArray = stepsWithoutConnection
            }
        }
        const today = new Date();

        let canGoToNextStep = this.state.currentStep.stepName === GROUP_DESCRIPTION_STEP
            && Number(this.state.currentStep) !== this.state.stepArray.length
            && (this.props.session.nbr_places_remaining === null || this.props.session.nbr_places_remaining > 0)

        if (this.props.session.subscription_time_window && 
            this.props.session.subscription_time_window.type === 'time_window' &&
            canGoToNextStep) {
            canGoToNextStep = new Date(this.props.session.subscription_time_window.before) < today
                && new Date(this.props.session.subscription_time_window.until) > today
        }

        this.setState({
            currentStep: this.props.goToSubscriptionStep ? stepsArray[stepsArray.length - 1] : stepsArray[0],
            isUserLoggedIn: isUserLoggedIn,
            isUserContact: isUserContact,
            stepArray: stepsArray,
            loading: false,
            canGoToNextStep: canGoToNextStep,
        })
    }

    closeModal = (withReaload: boolean = false) => {

        const {closeModal, closeModalWithSessionReload} = this.props;

        this.setState({
            isFetchingOffer: false,
            ownedPasses: [],
            ownedPassesBuilt: false,
            proposedOffers: []
        });

        if (withReaload) {
            closeModalWithSessionReload();
            return;
        }
        closeModal();
    }

    async componentDidUpdate(prevProps: Readonly<IPassSelectionModalProps>,
                             prevState: Readonly<IPassSelectionModalState>) {

        const {sessionOffers, userPasses} = this.props;

        if (this.state.currentStep.stepName === SUBSCRIPTION_STEP && !sessionOffers && !this.state.isFetchingOffer) {
            this.setState({isFetchingOffer: true})
            this.props.fetchOffers();

            if (userPasses === null) {
                this.props.fetchUserPasses();
            }
        }

        if (userPasses !== null && sessionOffers !== null && !this.state.ownedPassesBuilt) {

            this.setState({
                ownedPasses: this.makeMembersPasses(userPasses, sessionOffers),
                ownedPassesBuilt: true
            })
        }

        if (sessionOffers !== null && prevState.isFetchingOffer) {
            this.makeProposedOffers();
            this.setState({
                isFetchingOffer: false,
                canGoToNextStep: this.state.currentStep.stepName === GROUP_DESCRIPTION_STEP
            });
        }
    }

    makeMembersPasses = (passes: Array<IUserOwnedPass>, offers: ISelectionOffer) => {

        let returnedPasses: Array<IUserPassForSubscription> = [];
        let passMember: IFamilyMember | undefined;
        let isFromCart = false;
        const {session} = this.props;

        passes.forEach((pass) => {

            passMember = this.findMemberById(pass.member);
            let canBeUsed = false;

            if (passMember === undefined) {
                console.warn('Member not found: ' + pass.member);
                return;
            }

            pass.groups.forEach((group) => {
                if (group.id === session.group_id) {
                    canBeUsed = true;
                    return;
                }
            })

            offers.offers.forEach((offer) => {
                if (!pass.name) {
                    isFromCart = true;
                }

                if (offer.plan.id === pass.plan_id) {
                    pass.name = pass.name ? pass.name : offer.plan.name + ' (dans le panier)';
                }

                if (offer.plan.id === pass.plan_id) {
                    canBeUsed = true;
                    return;
                }
            });

            if (canBeUsed) {
                returnedPasses.push({
                    name: pass.name,
                    quantity: pass.remaining_quantity,
                    userId: Number(passMember.id),
                    userName: passMember.name,
                    isFromCart: isFromCart,
                    planId: pass.plan_id
                })
            }
        });

        returnedPasses.sort(function (a) {
            if (a.isFromCart) {
                return -1
            }
            if (!a.isFromCart) {
                return 1;
            }
            return 0;
        })

        return returnedPasses;
    }

    findMemberById = (id: number) => {
        if (this.props.sessionOffers === null) {
            return;
        }

        if (!this.props.sessionOffers.members) {
            return;
        }

        return this.props.sessionOffers.members.find((member) => Number(member.id) === id);
    }

    makeProposedOffers = () => {
        const {sessionOffers, session} = this.props;

        if (sessionOffers === null) {
            return;
        }

        let proposedOffers: Array<IProposedOffer> = [];


        sessionOffers.offers.forEach(offer => {
            if (offer.plan.type_plan === 'SESSION') {
                return;
            }

            let lowestPrice: number = -1;
            let memberRestrictions: Array<IProposedOfferUserRestriction> = [];

            offer.options.forEach((option) => {
                if (lowestPrice === -1 || option.price < lowestPrice) {
                    lowestPrice = option.price;
                }

                memberRestrictions.push({
                    userId: option.subscriber,
                    isAlreadyOnWaitingList: option.on_waiting_list,
                    restrictions: this.makeMemberRestrictions(option.restrictions),
                    price: option.price,
                } as IProposedOfferUserRestriction);
            });

            proposedOffers.push({
                id: offer.plan.id,
                name: offer.plan.name,
                quantity: offer.plan.quantity,
                price: lowestPrice,
                memberOptions: memberRestrictions,
            });
        });

        this.setState({
            proposedOffers: proposedOffers
        })
    }

    makeMemberRestrictions = (option: IRestriction): IMemberPassRestriction => {
        return {
            groups_restriction: this.makeGroupRestriction(option.groups_restrictions),
            organization_restrictions: option.organization_restrictions,
            product_restrictions: option.product_restrictions,
            registration_restrictions: option.registration_restrictions,
            membership_restrictions: option.membership_restrictions
        }
    }

    makeGroupRestriction = (originalRestrictions: IGroupRestriction[]): IGroupRestriction|null => {
        const {session} = this.props;
        let groupRestriction = originalRestrictions.find((restriction) => {
            return restriction.id === session.group.id;
        });

        if (groupRestriction === undefined) {
            return null;
        }

        if (session.nbr_places_remaining !== 0 && groupRestriction !== undefined) {
            groupRestriction.restrictions = groupRestriction
                .restrictions
                .filter(restriction => restriction !== GROUP_IS_FULL_RESTRICTION);
        }

        return groupRestriction;
    }


    handleNextStep = async () => {
        let currentStepNumber = Number(this.state.currentStep.step);

        if (currentStepNumber >= this.state.stepArray.length) {
            return;
        }

        let index = currentStepNumber - 1;
        let nextStep = this.state.stepArray[index + 1];

        this.state.stepArray[index].isCompleted = true;
        this.state.stepArray[index].isCurrent = false;

        if (this.state.stepArray[index + 1].stepName === CONNECTION_STEP || this.state.stepArray[index + 1].stepName === CONSENT_STEP) {
            const isUserLoggedIn = await qidigoAuth.userLoggedIn();
            let isUserContact = false;
            if (isUserLoggedIn) {
                await qidigoFetch.get("whoami").then((response) => {
                    isUserContact = response.contacts.find((contact) => contact.organization_id === this.props.organization.id)
                })

                if (isUserContact) {
                    index++;
                    nextStep = this.state.stepArray[index + 1];
                    this.state.stepArray[index].isCompleted = true;
                    this.state.stepArray[index].isCurrent = false;
                }
            }
        }

        this.state.stepArray[index + 1].isCurrent = true;


        this.setState({
            currentStep: nextStep,
            stepArray: this.state.stepArray,
            canGoToNextStep: nextStep.stepName === GROUP_DESCRIPTION_STEP
        })
    }

    handlePreviousStep = async () => {
        let currentStepNumber = Number(this.state.currentStep.step);
        let newStepArray = this.state.stepArray;
        if (currentStepNumber <= 1) {
            return;
        }

        let index = currentStepNumber - 1;
        let previousStep = newStepArray[index - 1];

        newStepArray[index].isCurrent = false;

        if (previousStep.stepName === CONNECTION_STEP || previousStep.stepName === CONSENT_STEP) {
            index--;
            newStepArray = stepsWithoutConnection;
            previousStep = newStepArray[index - 1]
            newStepArray[index].isCurrent = false;
        }

        newStepArray[index - 1].isCurrent = true;


        this.setState({
            currentStep: previousStep,
            stepArray: newStepArray,
            canGoToNextStep: previousStep.stepName === GROUP_DESCRIPTION_STEP
        })
    }

    handleShowConsentPage = () => {
        if (this.state.currentStep.step !== "2") {
            return;
        }

        this.state.stepArray[1].stepName = CONSENT_STEP;

        this.setState({
            stepArray: this.state.stepArray
        })
    }

    handleUsePass = (subscriberUserId, userPass) => {
        this.setState({
            isPassUsed: true
        })

        this.props.handleUsePass(subscriberUserId, userPass);
    }

    render() {
        const {session, sessionOffers, handleAddToCart, members} = this.props;
        const {ownedPasses, proposedOffers, isPassUsed} = this.state;

        const showSubscription = ((proposedOffers && proposedOffers.length > 0)
                             && sessionOffers !== null)
                             || session.group.is_from_purchased_pass;

        return (
            <div className="pass-selection-modal">
                <div className={"pass-selection-modal--content " +
                    (this.state.currentStep.stepName === SUBSCRIPTION_STEP
                            && (ownedPasses && ownedPasses.length > 0)
                            && !this.props.showReservationConfirmation
                            && proposedOffers
                            && proposedOffers.length > 0
                        ? "large-modal"
                        : "")}>
                    {
                        this.state.loading
                            ? <Loading/>
                            :
                            <div>
                                <div className={"pass-selection-modal--header"}>
                                    <div className={"pass-selection-modal--activity-name"}>
                                        {session.activity.name}
                                    </div>
                                    <a
                                        className={"pass-selection-modal--x-button"}
                                        onClick={() => this.closeModal(isPassUsed)}
                                    ></a>
                                </div>
                                <ModalStepsHeader
                                    stepsArray={this.state.stepArray}
                                />
                                <div className={"pass-selection-modal--body-container"}>
                                    {
                                        this.state.currentStep.stepName === GROUP_DESCRIPTION_STEP &&
                                        <CourseDescription
                                            courseDescription={{
                                                name: session.name,
                                                description: session.description,
                                                media: session.activity.media,
                                                location: session.location,
                                                isAllDay: session.is_all_day,
                                                start_date: session.start_date,
                                                end_date: session.end_date,
                                                places: session.nbr_places,
                                                places_remaining: session.nbr_places_remaining,
                                                gender_restrictions: session.group.restrictions_gender,
                                                age_restriction: {
                                                    min: session.group.restriction_age_min,
                                                    max: session.group.restriction_age_max
                                                },
                                                timeWindow: session.subscription_time_window,
                                                upComingRegistrationDate: session.group.upcoming_registration_date,
                                            }}
                                        />
                                    }
                                    {
                                        this.state.stepArray !== stepsWithoutConnection &&
                                        <div>
                                            {
                                                this.state.currentStep.stepName === CONNECTION_STEP &&
                                                <Connection
                                                    handleNextStep={this.handleNextStep}
                                                    handleShowConsentPage={this.handleShowConsentPage}
                                                    organizationId={this.props.organization.id}
                                                    session={this.props.session}
                                                />
                                            }
                                            {
                                                this.state.currentStep.stepName === CONSENT_STEP &&
                                                <Consent
                                                    handleNextStep={this.handleNextStep}
                                                    handleShowConsentPage={this.handleShowConsentPage}
                                                    organizationName={this.props.organization.name}
                                                    organizationId={this.props.organization.id}
                                                />
                                            }
                                        </div>

                                    }
                                    {
                                        this.state.currentStep.stepName === SUBSCRIPTION_STEP &&
                                        showSubscription &&
                                            <Subscription
                                                userPassList={ownedPasses}
                                                userFamilyMembers={members}
                                                organizationPassList={proposedOffers === null ? [] : proposedOffers}
                                                handleUsePass={this.handleUsePass}
                                                handleAddToCart={handleAddToCart}
                                                closeModal={this.closeModal.bind(this)}
                                                session={session}
                                                showReservationConfirmation={this.props.showReservationConfirmation}
                                                filteredMemberId={this.props.filteredMemberId}
                                                filteredPassPlanId={this.props.filteredPassPlanId}
                                                isLoadingOffer={this.props.isLoadingOffer}
                                                usePassError={this.props.usePassError}
                                            />
                                    }
                                </div>
                                {
                                    !this.props.showReservationConfirmation &&
                                    <div className={"pass-selection-modal--submit-row"}>
                                        {
                                            this.state.currentStep.stepName === GROUP_DESCRIPTION_STEP || this.state.isPassUsed ?
                                                <Button
                                                    className="pass-selection-modal--secondary-button"
                                                    onClick={() => this.closeModal()}
                                                >
                                                    <FormattedMessage {...translations['close']}/>
                                                </Button>
                                                :
                                                <Button
                                                    className="pass-selection-modal--secondary-button"
                                                    onClick={this.handlePreviousStep}
                                                >
                                                    <FormattedMessage {...translations['previous_step']}/>
                                                </Button>
                                        }
                                        {
                                            this.state.canGoToNextStep &&
                                            <Button
                                                className="pass-selection-modal--primary-button"
                                                onClick={this.handleNextStep}
                                            >
                                                <FormattedMessage {...translations['next_step']}/>
                                            </Button>
                                        }
                                    </div>
                                }
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default PassSelectionModal;
