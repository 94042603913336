/* eslint no-underscore-dangle: ["error", { "allow": ["__html", "_highlightResult"] }] */
/* eslint react/no-danger: [0] */
import React, {Component}    from "react";
import PropTypes             from "prop-types";
import {Link}                from "react-router/es";
import Truncate              from "react-truncate";
import {
	defineMessages,
	FormattedMessage,
}      from "react-intl";
import {path}                from "@app/lib/routes";

import Image                 from "qidigo-components/image.js";
import htmlToText            from "@app/lib/html_to_text";

const translations = defineMessages({
	na:        {id: "qidigo.activities.priceNA",    defaultMessage: "N/D"},
	view:      {id: "qidigo.activities.view",       defaultMessage: "Voir l'activité", description: "Message pour l'accessibilité du lien"},
});

/**
 * Une activité devrait ressembler à ça:
 */
const activityShape = PropTypes.shape({
	/* eslint-disable camelcase */
	id: PropTypes.number.isRequired,
	image: PropTypes.string.isRequired,
	image_alt: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	organization: PropTypes.shape({
		slug: PropTypes.string.isRequired,
	}),
	/* eslint-enable camelcase */
});

/**
 * Un élément dans la liste des activités.
 */
const ActivityFragment = class ActivityFragment extends Component {
	constructor() {
		super();
	}

	render() {
		const {
			activity,
			tag: Tag,
			className,
			...props
		} = this.props;
		const {organization} = activity;
		const url = path("activity", {orgID: organization["slug"], activityID: activity["id"]});
		const classes = ["activityfragment", className];
		return (
			<Tag className={classes.join(" ")} {...props}>
				<Link to={url}
					className="activityfragment--infos"
				>
					<ul>
						<li className="activityfragment--image">
							<Image src={activity.image} alt={activity.image_alt} />
						</li>
						<li className="activityfragment--description">
							<h3 className="activityfragment--title"
								dangerouslySetInnerHTML={{
									__html: activity.name
								}}
							/>
							<Truncate lines={2}>
								{htmlToText(activity.description)}
							</Truncate>
						</li>
						<li className="activityfragment--view">
							<span><FormattedMessage {...translations["view"]} /></span>
						</li>
					</ul>
				</Link>
			</Tag>
		);
	}
};

ActivityFragment.propTypes = {
	className: PropTypes.string,
	activity: activityShape.isRequired,
	tag: PropTypes.node,
};

ActivityFragment.defaultProps = {
	tag: "li",
};

ActivityFragment.contextTypes = {
	intl: PropTypes.object,
	currentLocale: PropTypes.string,
};

export default ActivityFragment;

export {activityShape};
